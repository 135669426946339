<template>
  <v-row no-gutters align="center">
    <v-col cols="12" class="pa-0 ma-0">
      <span>{{ title }}</span>
      <v-autocomplete
        v-model="localVModel"
        :items="items"
        :loading="externalLoading || isLoading"
        :disabled="externalLoading || externalDisabled"
        :item-text="itemText"
        :placeholder="placeholder"
        :label="personalizedLabel"
        @blur="onBlur"
        @focus="onFocus"
        @change="onChange"
        @input="$emit('onInput', localVModel)"
        @click:clear="onClearable"
        append-icon="mdi-chevron-down"
        single-line
        hide-details
        multiple
        return-object
        outlined
        dense
        deletable-chips
        small-chips
        class="mt-2"
      >
        <template v-slot:selection="{ item }">
          <v-chip
            close
            small-chips
            dark
            color="#E0E0E0"
            @click:close="removeProduct(item)"
            class="my-1"
          >
            <span style="color: black">
              {{ item[itemText] ? item[itemText] : item }}
            </span>
          </v-chip>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    originalValue: { type: Array, default: () => [], required: true },
    items: { type: Array, default: () => [], required: true },
    title: { type: String, default: "" },
    label: { type: String, default: "" },
    externalLoading: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    externalDisabled: { type: Boolean, default: false },
    itemText: { type: String, default: "" },
    placeholder: { type: String, default: "" },
  },
  data() {
    return {
      localVModel: [],
      hasSelections: false,
      previousSelections: null,
    };
  },
  computed: {
    personalizedLabel() {
      return this.items?.length == 0
        ? this.$i18n.t("TXT_NO_HAS_DATA")
        : this.label;
    },
  },
  watch: {
    originalValue: {
      handler(value) {
        this.localVModel = value;
      },
      immediate: true,
      deep: true,
    },

    localVModel: {
      handler(value) {
        this.$emit("updates", value);
      },
      deep: true,
    },
  },
  methods: {
    removeProduct(item) {
      const index = this.localVModel.findIndex((selection) =>
        Object.keys(item).every((key) => selection[key] === item[key])
      );

      if (index >= 0) {
        this.localVModel.splice(index, 1);
        this.hasSelections = true;
      }
    },
    onChange() {
      this.$emit("onChange", this.localVModel);
      this.hasSelections = true;
    },
    onFocus() {
      this.$emit("onFocus", this.$attrs.value);

      this.previousSelections = this.$attrs?.value ?? null;
    },
    onBlur() {
      this.$emit("onBlur", this.$attrs.value);
      if (
        this.hasSelections &&
        (JSON.stringify(this.$attrs.value) !==
          JSON.stringify(this.previousSelections) ||
          (this.$attrs.value.length == 0 &&
            this.previousSelections.length == 0))
      )
        this.$emit("hasSelections");
      this.hasSelections = false;
    },
    onClearable() {
      this.hasSelections = true;
      this.previousSelections = null;
    },
  },
};
</script>