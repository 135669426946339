<template>
  <v-progress-circular
    v-if="isLoadingRevenueGraphic"
    size="150"
    color="#3c5ca7"
    indeterminate
  />
  <highcharts v-else :options="chartOptions" />
</template>

<script>
import Highcharts from "highcharts";

// Exporta dados em PNG, JPEG, PDF, SVG, imprime e visualiza tela cheia
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);

// Exporta dados CSV, XLS, e muda visualização por tabela
import exportData from "highcharts/modules/export-data";
exportData(Highcharts);

import { formatMonetary } from "@/utils/format-toMonetary";

export default {
  props: ["listRevenueGraphic", "isLoadingRevenueGraphic"],
  data() {
    return {
      chartOptions: {
        lang: {
          // variáveis para renomear as ações de exportação de dados
          viewFullscreen: this.$i18n.t("TXT_VISUALIZE_FULLSCREEN"),
          printChart: this.$i18n.t("TXT_PRINT_CHART"),
          downloadCSV: this.$i18n.t("TXT_DOWNLOAD_CSV_FILE"),
          downloadJPEG: this.$i18n.t("TXT_DOWNLOAD_JPEG_IMAGE"),
          downloadPDF: this.$i18n.t("TXT_DOWNLOAD_PDF"),
          downloadPNG: this.$i18n.t("TXT_DOWNLOAD_PNG_IMAGE"),
          downloadSVG: this.$i18n.t("TXT_DOWNLOAD_SVG_IMAGE"),
          downloadXLS: this.$i18n.t("TXT_DOWNLOAD_XLS_FILE"),
          exitFullscreen: this.$i18n.t("TXT_LEAVE_FULLSCREEN"),
          viewData: this.$i18n.t("TXT_DISPLAY_DATA_IN_TABLE"),
          noData: this.$i18n.t("TXT_NO_DATA_AVAILABLE"),
        },
        chart: {
          type: "column",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
        },
        tooltip: {
          borderRadius: 15,
          formatter: function () {
            const date = this.point.category.split("-");
            const newDate = `${date[2]}/${date[1]}/${date[0]}`;
            return (
              `<p><strong>${newDate}</strong></p><br>` + formatMonetary(this.y)
            );
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: [
          {
            name: `${this.$i18n.t("TXT_TOTAL_REVENUE_IN")} ${this.$i18n.t(
              "TXT_DAYS"
            )}`,
            data: [],
            showInLegend: false,
            color: "#3c5ca7",
          },
        ],
      },
    };
  },

  methods: {
    formatMonetary,
    prepareteDataForGraphic(list) {
      const listData = list.map((data) => {
        return data.value;
      });
      this.chartOptions.series[0].data = listData;
    },
    prepareteXAxisForGraphic(list) {
      const listData = list.map((data) => {
        return data.date;
      });
      this.chartOptions.xAxis.categories = listData;
    },
  },

  watch: {
    listRevenueGraphic: {
      handler(value) {
        const newList = value.map((data) => {
          return { ...data, date: data.date.split("T")[0] };
        });
        this.prepareteDataForGraphic(newList);
        this.prepareteXAxisForGraphic(newList);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
</style>
