<template>
  <v-card
    class="pa-4 pt-0"
    :disabled="isLoading"
    :loading="isLoading"
    min-height="145"
  >
    <h4 class="pt-4">{{ $t("TXT_FILTERS") }}</h4>
    <v-row no-gutters class="mt-2" align="center">
      <prd-combo-box
        v-show="filter.isShow"
        v-for="(filter, index) in filters"
        :key="index"
        style="width: 35% !important"
        v-model="filter.selected"
        :title="filter.label"
        :items="filter.items"
        :class="filter.margin"
        :disabled="filterLoading || filter.items.length == 0"
        :clearable="true"
        :loading="filterLoading"
        @input="
          filter.identification === 'affiliate' ? setAffiliate($event) : null
        "
        :placeholder="$t('TXT_SELECT')"
      />
      <prd-btn
        :disabled="!hasFilters"
        :title="$t('TXT_APPLY_FILTERS')"
        @click="setFilters"
        class="mr-4 mt-6"
        style="height: 39px"
      />
    </v-row>
  </v-card>
</template>

<script>
import prdComboBox from "@/Design_System/common/prd-comboBox.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import ServiceHome20 from "@/service/home20/index";
export default {
  props: ["isLoading"],
  components: { prdComboBox, PrdBtn },
  data() {
    return {
      service: new ServiceHome20(),
      filters: [
        {
          label: this.$i18n.t("TXT_SELECT_A"),
          identification: "affiliate",
          items: [],
          selected: null,
          margin: "mr-4",
          isShow: false,
        },
        {
          label: this.$i18n.t("TXT_PRECIFICATIONS"),
          identification: "precification",
          items: [],
          selected: null,
          margin: "mr-4",
          isShow: true,
        },
      ],
      filterLoading: false,
      lastMonthProjection: null,
      stateByIdPriceGroup: null,
    };
  },

  computed: {
    hasFilters() {
      return this.filters[1].selected != null;
    },
    hasPreFilters() {
      return this.filters[0].isShow;
    },

    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    VEM() {
      return this.idCompany == 3026;
    },
  },

  watch: {
    filters: {
      handler(value) {
        this.$emit("selectedAffiliate", value[0].selected);
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    setAffiliate(data) {
      if (!data) {
        this.cleanFilters();
        return;
      }
      this.getListPriceGroupByAffiliate(data);
      this.$emit("idStore", data.value);
      this.$emit("nameStore", data.text);
    },
    init(filters) {
      this.$emit("setLoading", true);

      Promise.all([
        this.getPromotionalCalendar(filters.idCompany),
        this.getGeralVision(filters),
        this.getOportunities(filters),
        this.getAlerts(filters),
        this.getDataGraphicRevenue(filters),
      ])
        .then(() => {
          this.$emit("setLoading", false);
        })
        .catch((error) => {
          console.error(error);
          this.$emit("setLoading", false);
        });
    },

    getGeralVision(filters) {
      if (!this.VEM) return;
      this.$emit("chartIALoading", true);
      let response = {
        dayAdoption: [],
      };

      this.service
        .getGraficos(
          filters?.loja ?? null,
          filters?.curva ?? null,
          filters?.categoria ?? null
        )
        .then((res) => {
          response.dayAdoption = res.data;
          this.$emit("chartIALoading", false);
        })
        .catch((error) => {
          console.log("Erro no getGeralVision", error);
          this.$emit("chartIALoading", false);
        });

      this.$emit("geralVisionDataEvent", response);
    },

    getPromotionalCalendar(idCompany) {
      this.$emit("promotionalCalendarLoading", true);
      this.service
        .getAcaoPromocional(idCompany)
        .then((res) => {
          this.$emit("promotionalCalendarEvent", res.data);
          this.$emit("promotionalCalendarLoading", false);
        })
        .catch((error) => {
          console.log("Error no getPromotionalCalendar", error);
          this.$emit("promotionalCalendarLoading", false);
        });
    },

    async getTotalProjection(filters) {
      this.$emit("loadingProjection", true);
      let response = {
        totalRecipe: {},
        totalMargin: {},
        lastMonthProjection: null,
      };

      if (filters.idPriceGroup) {
        if (this.VEM) await this.getLastMonthProjection(filters);
        try {
          const res = await this.service.getRecipeByPricing(
            filters.idCompany,
            filters.idPriceGroup
          );

          let resData = res?.data?.result ?? null;

          if (resData) {
            response.lastMonthProjection = this.lastMonthProjection;

            response.totalRecipe = {
              lastWeek: resData.saleVariation_SaleCurrentPrice,
              weekProjection: resData.saleVariation_SaleCurrentPrice * 7,
              monthProjection: resData.saleVariation_SaleCurrentPrice * 30,

              lastWeekIA: resData.saleVariation_SaleApprovedPrice,
              weekProjectionIA: resData.saleVariation_SaleApprovedPrice * 7,
              monthProjectionIA: resData.saleVariation_SaleApprovedPrice * 30,
            };

            response.totalMargin = {
              lastWeek: resData.marginVariation_SaleMargin,
              weekProjection: resData.marginVariation_SaleMarginApproved,
              monthProjection: resData.marginVariation_SaleMarginApproved,
            };
          }

          this.$emit("loadingProjection", false);
          this.$emit("totalProjection", response);
        } catch (error) {
          console.log("buscar total projection", error);
          this.$emit("loadingProjection", false);
        }
      }
      this.$emit("totalProjection", response);
    },

    getOportunities(filters) {
      if (this.VEM) return;
      let response = {
        increaseProfit: {},
        increaseMargin: {},
      };

      this.service
        .getOportunities(
          filters?.idCompany ?? null,
          filters?.idPriceGroup ?? null
        )
        .then((res) => {
          response.increaseProfit = res.data.increaseProfit;
          response.increaseMargin = res.data.increaseMargin;
        });

      this.$emit("oportunitiesDataEvent", response);
    },

    getAlerts(filters) {
      this.$emit("setLoadingAlerts", true);
      let response = {
        marginCard: null,
        icCard: null,
        psicologyPriceCard: null,
        competitorPriceCard: null,
        profitVariationCard: null,
      };

      this.service
        .getAlert(filters?.idCompany ?? null, filters?.idPriceGroup ?? null)
        .then((res) => {
          response.marginCard = res.data.marginCard;
          response.icCard = res.data.icCard;
          response.psicologyPriceCard = res.data.psicologyPriceCard;
          response.competitorPriceCard = res.data.competitorPriceCard;
          response.profitVariationCard = res.data.profitVariation;
        })
        .finally(() => {
          this.$emit("setLoadingAlerts", false);
        });

      this.$emit("alertsDataEvent", response);
    },

    async getLastMonthProjection(filters) {
      try {
        const res = await this.service.getLastMonthProjection(
          filters?.loja ?? null,
          filters?.curva ?? null,
          filters?.categoria ?? null
        );

        this.lastMonthProjection = res.data;
      } catch (error) {
        console.log("Erro ao pegar projeção do ultimo mes", error);
      }
    },

    getAllFilters() {
      this.filterLoading = true;
      Promise.all([this.getAffiliateFilter()])
        .then(() => {
          this.filterLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.filterLoading = false;
        });
    },

    getAffiliateFilter() {
      this.service
        .getAffiliateFilter(this.idCompany)
        .then((res) => {
          let response = res.data.result[0]?.values ?? [];
          if (response.length > 0) {
            const fieldName = res.data.result[0].fieldName;
            this.filters[0].label += fieldName;
            const idEnterprisePriceGroupDefaultFilter =
              res.data.result[0].idEnterprisePriceGroupDefaultFilter;
            let filtervalues = [];
            response.forEach((el) => {
              let filterObj = {
                text: el.description,
                value: el.value,
                idEnterprisePriceGroupDefaultFilter,
              };

              filtervalues.push(filterObj);
            });
            this.filters[0].isShow = true;
            this.filters[0].items = filtervalues;
          } else {
            this.getListPriceGroupByAffiliate(null);
          }
        })
        .catch((error) => {
          console.log("Erro ao filtro de affiliate", error);
        });
    },

    getListPriceGroupByAffiliate(filters) {
      this.filters[1].items = [];
      this.filters[1].selected = null;
      this.filterLoading = true;
      this.service
        .getListPriceGroups(this.idCompany, filters ? [filters] : null)
        .then((res) => {
          this.filters[1].items = res.data.result.map((el) => {
            return { ...el, text: el.name };
          });
          this.filters[1].selected = this.filters[1].items[0];
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.filterLoading = false;
        });
    },

    async setFilters() {
      this.$emit("getChartAdoptionData");
      let request = {
        loja: "",
        curva: "",
        categoria: "",
        idCompany: this.idCompany,
        idPriceGroup: "",
      };

      this.filters.forEach((el) => {
        if (el.selected) {
          let filterObj = {
            key: el.selected ? el.identification : null,
            value:
              el.selected.value || el.selected.idEnterprisePriceGroups || null,
          };

          if (filterObj.key != null && filterObj.key == "affiliate") {
            request.loja = filterObj.value;
          }

          if (filterObj.key != null && filterObj.key == "precification") {
            request.idPriceGroup = filterObj.value;
          }
        }
      });
      await this.getStateByIdPriceGroup(request.idPriceGroup);
      this.getListedProducts(request.loja);
      this.getTotalProjection(request);
      this.init(request);
      this.$emit("unlockIcGraphicVEM");
    },

    cleanFilters() {
      this.filters.forEach((filter) => {
        filter.selected = null;
        if (
          filter.identification === "precification" &&
          this.filters[0].isShow
        ) {
          filter.items = [];
        }
      });
    },
    async getStateByIdPriceGroup(idPriceGroup) {
      await this.service
        .GetStateByIdPriceGroup(idPriceGroup)
        .then((res) => {
          if (res.status == 200) {
            this.stateByIdPriceGroup = res.data.estado;
            this.$emit("stateByIdPriceGroup", res.data.estado);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getListedProducts(idStore) {
      this.$emit("listedProducts", []);
      this.$emit("setLoadingListedProducts", true);
      if (!this.VEM) return;
      this.service
        .GetListedPrice(this.idCompany, this.stateByIdPriceGroup, idStore)
        .then((res) => {
          this.$emit("listedProducts", res.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$emit("setLoadingListedProducts", false);
        });
    },
    getDataGraphicRevenue(filters) {
      if (this.VEM) return;
      this.$emit("setLoadingRevenueGraphic", true);
      this.service
        .getDataGraphicRevenueV2(this.idCompany, filters.loja)
        .then((res) => {
          this.$emit("listRevenueGraphic", res.data.reverse());
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$emit("setLoadingRevenueGraphic", false);
        });
    },
  },
  created() {
    this.getAllFilters();
  },
};
</script>