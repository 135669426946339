import { render, staticRenderFns } from "./agroupment-micro-list.vue?vue&type=template&id=6af36c27&scoped=true"
import script from "./agroupment-micro-list.vue?vue&type=script&lang=js"
export * from "./agroupment-micro-list.vue?vue&type=script&lang=js"
import style0 from "./agroupment-micro-list.vue?vue&type=style&index=0&id=6af36c27&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6af36c27",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VIcon,VRow})
