<template>
  <v-data-table
    :headers="headers"
    :items="localItems"
    :hide-default-footer="localItems == [] || localItems.length == 0"
    :footer-props="footerProps"
    :no-data-text="$t('TXT_NO_DATA_AVAILABLE')"
  >
    <template v-slot:[`item.priceBook`]="{ item, index }">
      <div v-if="!item.isEditPriceBook">
        {{ item.priceBook }}
        <v-icon
          v-if="isAdminUpl"
          :color="$prdStyles('color-primary')"
          @click="item.isEditPriceBook = true"
          right
        >
          mdi-pencil-box</v-icon
        >
      </div>

      <div v-else class="my-flex">
        <v-text-field
          :loading="item.isLoading"
          :disabled="item.isLoading"
          v-model="item.priceBook"
          outlined
          dense
          hide-details
          style="max-width: 120px"
        />
        <v-icon
          :disabled="item.isLoading"
          @click="editUnitPrice('priceBook', item, index)"
          :color="$prdStyles('color-success')"
        >
          mdi-checkbox-outline
        </v-icon>
        <v-icon
          :disabled="item.isLoading"
          @click="closeEditMode('priceBook', item, index)"
          :color="$prdStyles('color-error')"
        >
          mdi-close-box
        </v-icon>
      </div>
    </template>

    <template v-slot:[`item.keepOnList`]="{ item, index }">
      <div v-if="!item.isEditKeepOnList">
        <span v-if="item.keepOnList">{{ $t("TXT_YES") }}</span>
        <span v-else>{{ $t("TXT_NO") }}</span>
        <v-icon
          v-if="isAdminUpl"
          :color="$prdStyles('color-primary')"
          @click="item.isEditKeepOnList = true"
          right
        >
          mdi-pencil-box</v-icon
        >
      </div>

      <div v-else class="my-flex">
        <v-select
          :loading="item.isLoading"
          :disabled="item.isLoading"
          v-model="item.keepOnList"
          :items="boolItens"
          outlined
          dense
          hide-details
          style="max-width: 120px"
        />
        <v-icon
          :disabled="item.isLoading"
          @click="editUnitPrice('keepOnList', item, index)"
          :color="$prdStyles('color-success')"
        >
          mdi-checkbox-outline
        </v-icon>
        <v-icon
          :disabled="item.isLoading"
          @click="closeEditMode('keepOnList', item, index)"
          :color="$prdStyles('color-error')"
        >
          mdi-close-box
        </v-icon>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import UPLService from "@/service/upl";

export default {
  props: {
    localItems: { type: Array, default: () => [], required: true },
    items: { type: Array, default: () => [], required: true },
    headers: { type: Array, default: () => [], required: true },
    setCurrencyUPL: { type: Function, default: () => "-", required: true },
    isAdminUpl: { type: Boolean, default: false, required: true },
  },
  data() {
    return {
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
      service: new UPLService(),
      boolItens: [
        { text: this.$i18n.t("TXT_YES"), value: true },
        { text: this.$i18n.t("TXT_NO"), value: false },
      ],
    };
  },
  methods: {
    async editUnitPrice(type, item, index) {
      const request = {
        id: item.id,
        keepOnList: item.keepOnList,
        priceBook: item.priceBook,
      };
      const edit = this.getEditName(type);
      item.isLoading = true;
      await this.service
        .updateKeepOnListOrPriceBook(request)
        .then((res) => {
          if (res.status == 200) {
            this.$emit("updateOriginalData", index, res.data);
            item[edit] = false;
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_UPDATED_DATA_SUCCESS"),
              type: "success",
            });
          } else {
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_PROBLEM_UPDATE_DATAS"),
              type: "info",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          item[type] = this.items[index][type];
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_ERROR_UPDATE_DATAS"),
            type: "error",
          });
        })
        .finally(() => {
          item.isLoading = false;
        });
    },
    getEditName(name) {
      return `isEdit${name.charAt(0).toUpperCase()}${name.slice(1)}`;
    },
    closeEditMode(type, item, index) {
      const edit = this.getEditName(type);
      item[edit] = false;
      item[type] = this.items[index][type];
    },
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}
</style>