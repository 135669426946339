<template>
  <v-col>
    <v-row no-gutters align="center">
      <v-col cols="auto">
        <h4>{{ name }}</h4>
        <label>{{ subtitle }}</label>
      </v-col>
      <v-spacer></v-spacer>

      <Prd-btn
        :title="$t('TXT_EXTRACT_FILE')"
        :class="isAdminUpl ? 'mr-4' : ''"
        :disabled="inEditMode"
        :loading="isLoadingReport"
        @click="extractMatrix"
      />
      <v-col cols="auto" v-if="isAdminUpl">
        <Prd-btn
          v-if="!inEditMode"
          :title="$t('TXT_EDIT_DATA')"
          :disabled="isLoadingReport"
          @click="inEditMode = !inEditMode"
        />

        <v-row v-else no-gutters class="gap-4" align="center">
          <span v-if="isLoading">{{ $t("TXT_SAVING_DATA") }}</span>
          <prd-loading-circular v-if="isLoading" />
          <Prd-btn
            v-if="inEditMode"
            :title="$t('TXT_SAVE')"
            :disabled="isLoading || !unlockSaveButton"
            @click="saveEditions"
          />
          <Prd-btn
            v-if="inEditMode"
            :title="$t('TXT_CANCEL')"
            :outlined="true"
            :disabled="isLoading"
            @click="resetTable"
          />
        </v-row>
      </v-col>
    </v-row>

    <v-data-table
      class="mt-4"
      :headers="headers"
      :items="localItems"
      :hide-default-footer="localItems == [] || localItems.length == 0"
      :footer-props="footerProps"
      :no-data-text="$t('TXT_NO_DATA_AVAILABLE')"
    >
      <template v-slot:[`item.committee`]="{ item }">
        <span v-if="!inEditMode">{{ item.committee + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.committee"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.commercialDirector`]="{ item }">
        <span v-if="!inEditMode">{{ item.commercialDirector + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.commercialDirector"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.businessUnit`]="{ item }">
        <span v-if="!inEditMode">{{ item.businessUnit + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.businessUnit"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.regional`]="{ item }">
        <span v-if="!inEditMode">{{ item.regional + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.regional"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.ctc`]="{ item }">
        <span v-if="!inEditMode">{{ item.ctc + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.ctc"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.total`]="{ item }">
        <span>{{ setTotal(item) }}</span>
      </template>
      <template v-slot:[`item.portifolioStatus`]="{ item }">
        <span v-if="!inEditMode">{{
          setPortifolioStatus(item.portifolioStatus)
        }}</span>
        <Prd-combo-box
          v-else
          v-model="item.portifolioStatus"
          :items="portifolioStatusItems"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.check`]="{ item }">
        <span v-if="!inEditMode">{{ item.check + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.check"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import PrdTextField from "@/components/common/prd-text-field.vue";
import UPLService from "@/service/upl";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
export default {
  components: { PrdBtn, PrdTextField, PrdLoadingCircular, PrdComboBox },
  props: {
    name: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
      localItems: [],
      inEditMode: false,
      service: new UPLService(),
      isLoading: false,
      isLoadingReport: false,
      portifolioStatusItems: [
        { text: "Phase Out", value: 0 },
        { text: "Active", value: 1 },
        { text: "Pendente", value: 2 },
      ],
    };
  },
  computed: {
    isAdminUpl() {
      return (
        this.$store.getters.Acesso_E_Edicao_UPL_Geral ||
        this.$store.getters.isPredifyAdmin
      );
    },
    unlockSaveButton() {
      const updatedItems = this.getUpdatedData();
      return updatedItems.length > 0;
    },
  },

  watch: {
    items: {
      handler(value) {
        this.localItems = JSON.parse(
          JSON.stringify(
            value.map((item) => {
              return {
                ...item,
                portifolioStatus: this.portifolioStatusItems.find(
                  (status) => status.value == item.portifolioStatus
                ),
              };
            })
          )
        );
      },
      immediate: true,
      deep: true,
    },
    inEditMode: {
      handler(value) {
        this.$emit("setBadgeDiscountMatrix", value);
      },
      immediate: true,
    },
  },

  methods: {
    async extractMatrix() {
      this.isLoadingReport = true;

      const res = await this.service.getDiscountMatrixReport();

      const blob = new Blob([res.data], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "matrix_descontos");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      this.isLoadingReport = false;
    },
    setTotal(item) {
      return (
        (item.committee ? parseFloat(item.committee) : 0) +
        (item.commercialDirector ? parseFloat(item.commercialDirector) : 0) +
        (item.businessUnit ? parseFloat(item.businessUnit) : 0) +
        (item.regional ? parseFloat(item.regional) : 0) +
        (item.ctc ? parseFloat(item.ctc) : 0) +
        "%"
      );
    },
    setPortifolioStatus(status) {
      if (!status) return;
      let number = status.value;
      return this.portifolioStatusItems.find((status) => status.value == number)
        .text;
    },
    resetTable() {
      const remapedItems = this.items.map((item) => {
        return {
          ...item,
          portifolioStatus: this.portifolioStatusItems.find(
            (status) => status.value == item.portifolioStatus
          ),
        };
      });
      this.localItems = JSON.parse(JSON.stringify(remapedItems));
      this.inEditMode = !this.inEditMode;
    },
    async saveEditions() {
      this.isLoading = true;
      try {
        const updatedItems = this.getUpdatedData();
        if (updatedItems.length > 0) {
          await this.service.updateDeliberationDiscountMatrix(updatedItems);
          this.$emit("updateOriginalItemsModified", updatedItems);
          this.$emit("updateMarketingVariationMatrix", updatedItems);
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_UPDATED_DATA_SUCCESS"),
            type: "success",
          });
        }
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_UPDATE_DATAS"),
          type: "error",
        });
      } finally {
        this.inEditMode = false;
        this.isLoading = false;
      }
    },
    getUpdatedData() {
      let updatedItems = [];
      this.items.forEach((originalItem) => {
        const foundeditem = this.localItems.find(
          (localItem) => localItem.id == originalItem.id
        );
        if (foundeditem) {
          const stringifyFoundedItem = JSON.stringify({
            ...foundeditem,
            portifolioStatus: foundeditem.portifolioStatus.value,
          });
          const stringifyOriginalItem = JSON.stringify(originalItem);
          if (stringifyFoundedItem != stringifyOriginalItem)
            updatedItems.push({
              ...foundeditem,
              portifolioStatus: foundeditem.portifolioStatus?.value,
            });
        }
      });
      return updatedItems;
    },
  },
};
</script>
