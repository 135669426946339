var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-6 main",attrs:{"id":"informative-table"}},[(false)?_c('progress-component'):_vm._e(),_c('div',[_c('v-data-table',{attrs:{"loading":_vm.isLoading,"search":_vm.search,"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.vermelho_superior",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.vermelho_superior),callback:function ($$v) {_vm.$set(item, "vermelho_superior", $$v)},expression:"item.vermelho_superior"}})],1)]}},{key:"item.amarelo_superior",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.amarelo_superior),callback:function ($$v) {_vm.$set(item, "amarelo_superior", $$v)},expression:"item.amarelo_superior"}})],1)]}},{key:"item.laranja_superior",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.laranja_superior),callback:function ($$v) {_vm.$set(item, "laranja_superior", $$v)},expression:"item.laranja_superior"}})],1)]}},{key:"item.roxo_superior",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.roxo_superior),callback:function ($$v) {_vm.$set(item, "roxo_superior", $$v)},expression:"item.roxo_superior"}})],1)]}},{key:"item.vermelho_inferior",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.vermelho_inferior),callback:function ($$v) {_vm.$set(item, "vermelho_inferior", $$v)},expression:"item.vermelho_inferior"}})],1)]}},{key:"item.amarelo_inferior",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.amarelo_inferior),callback:function ($$v) {_vm.$set(item, "amarelo_inferior", $$v)},expression:"item.amarelo_inferior"}})],1)]}},{key:"item.laranja_inferior",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.laranja_inferior),callback:function ($$v) {_vm.$set(item, "laranja_inferior", $$v)},expression:"item.laranja_inferior"}})],1)]}},{key:"item.roxo_inferior",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.roxo_inferior),callback:function ($$v) {_vm.$set(item, "roxo_inferior", $$v)},expression:"item.roxo_inferior"}})],1)]}},{key:"item.roxo_diamante",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.roxo_diamante),callback:function ($$v) {_vm.$set(item, "roxo_diamante", $$v)},expression:"item.roxo_diamante"}})],1)]}},{key:"item.especial_pitangui",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.especial_pitangui),callback:function ($$v) {_vm.$set(item, "especial_pitangui", $$v)},expression:"item.especial_pitangui"}})],1)]}},{key:"item.especial_ismênia",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.especial_ismênia),callback:function ($$v) {_vm.$set(item, "especial_ismênia", $$v)},expression:"item.especial_ismênia"}})],1)]}},{key:"item.especial_minas",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.especial_minas),callback:function ($$v) {_vm.$set(item, "especial_minas", $$v)},expression:"item.especial_minas"}})],1)]}},{key:"item.vip_pitangui",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.vip_pitangui),callback:function ($$v) {_vm.$set(item, "vip_pitangui", $$v)},expression:"item.vip_pitangui"}})],1)]}},{key:"item.vip_minas",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.vip_minas),callback:function ($$v) {_vm.$set(item, "vip_minas", $$v)},expression:"item.vip_minas"}})],1)]}},{key:"item.cadeira_ismênia",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.cadeira_ismênia),callback:function ($$v) {_vm.$set(item, "cadeira_ismênia", $$v)},expression:"item.cadeira_ismênia"}})],1)]}},{key:"item.cadeira_pitangui",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.cadeira_pitangui),callback:function ($$v) {_vm.$set(item, "cadeira_pitangui", $$v)},expression:"item.cadeira_pitangui"}})],1)]}},{key:"item.cadeira_minas",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-checkbox',{attrs:{"disabled":""},model:{value:(item.cadeira_minas),callback:function ($$v) {_vm.$set(item, "cadeira_minas", $$v)},expression:"item.cadeira_minas"}})],1)]}},{key:"item.expectedPublic",fn:function(ref){
var item = ref.item;
return [(!item.isEdit)?_c('div',[_vm._v(" "+_vm._s(item.expectedPublic)+"% "),_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary')},on:{"click":function($event){item.isEdit = true}}},[_vm._v("mdi-pencil-box ")])],1):_c('div',{staticStyle:{"display":"flex","align-items":"center","gap":"8px"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","type":"number","hide-spin-buttons":""},model:{value:(item.expectedPublic),callback:function ($$v) {_vm.$set(item, "expectedPublic", $$v)},expression:"item.expectedPublic"}}),_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('v-icon',{attrs:{"color":"#1D8527","small":""},on:{"click":function($event){return _vm.editExpectedPublic(item)}}},[_vm._v(" mdi-checkbox-marked ")]),_c('v-icon',{attrs:{"color":"#B00020","small":""},on:{"click":function($event){item.isEdit = false}}},[_vm._v(" mdi-close-box ")])],1)],1)]}},{key:"item.membersExpected",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.membersExpected ? item.membersExpected.toFixed(0) : 0)+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }