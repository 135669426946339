<template>
  <v-dialog v-model="isModalOpen" persistent max-width="450">
    <v-card class="pa-4">
      <h3 class="text-center mb-2">
        {{ $i18n.t("TXT_INFORMATIONS_PRICING_SELECTED") }}
      </h3>
      <div class="pricing-info mb-2 px-4">
        <div class="flex-itens">
          <h4>ID:</h4>
          <span>{{ idEnterprise }}</span>
        </div>
        <div class="flex-itens">
          <h4>{{ this.$i18n.t("TXT_NAME") }}:</h4>
          <span>{{ name }}</span>
        </div>
        <!-- <div class="flex-itens">
          <h4>{{ this.$i18n.t("TXT_LAST_UPDATE") }}:</h4>
          <span>{{ formatDate(calcDate) }}</span>
        </div> -->
      </div>
      <p
        v-t="'TXT_REALLY_USE_PRECIFICATION'"
        class="text-center font-weight-bold mb-0 mx-4"
      ></p>
      <div class="text-center mx-4">
        <span>
          <span style="color: red">* </span>
          <span v-t="'TXT_NOT_POSSIBLE_CHANGES'"></span>
          <span class="font-weight-bold" style="color: #3c5ca7"> PREDIFY</span>
        </span>
      </div>

      <v-row no-gutters align="center" justify="end" class="mt-4">
        <Prd-btn
          :title="$i18n.t('TXT_CANCEL')"
          :outlined="true"
          :disabled="isLoading"
          @click="$emit('closeModal')"
          class="mr-4"
        />
        <Prd-btn
          :title="$i18n.t('TXT_CONFIRM')"
          :loading="isLoading"
          :disabled="isLoading"
          @click="sendItensToErp"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import { formatDate } from "@/utils/prd-format-date";
import service from "@/service/pricer/filters.js";
const Service = new service();
export default {
  components: { PrdBtn },
  props: {
    modalControl: {
      type: Boolean,
      default: false,
    },
    pricing: {
      default: null,
    },
  },

  data() {
    return {
      isModalOpen: false,
      isLoading: false,
    };
  },

  computed: {
    idEnterprise() {
      return this.pricing?.idEnterprisePriceGroups ?? null;
    },
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    name() {
      return this.pricing?.name ?? "-";
    },
    calcDate() {
      return this.pricing?.calcDate ?? null;
    },
  },

  watch: {
    modalControl: {
      handler(value) {
        this.isModalOpen = value;
      },
      immediate: true,
    },
  },

  methods: {
    formatDate,

    async sendItensToErp() {
      this.isLoading = true;

      let request = {
        idCompany: this.idCompany,
        idEnterprisePriceGroups: this.idEnterprise,
        isSimulation: false,
      };

      try {
        await Service.sendToERP(request);

        this.$emit("refreshList");
        this.$emit("closeModal");

        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_SUCESS_ERP"),
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_"),
          type: "error",
        });
      }
      this.$store.commit("setFirstSearch", true);

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-itens {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.pricing-info {
  padding: 8px;
}
</style>