import { render, staticRenderFns } from "./crawler-config-table.vue?vue&type=template&id=3df0afd4&scoped=true"
import script from "./crawler-config-table.vue?vue&type=script&lang=js"
export * from "./crawler-config-table.vue?vue&type=script&lang=js"
import style0 from "./crawler-config-table.vue?vue&type=style&index=0&id=3df0afd4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3df0afd4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCard,VCol,VDataTable,VIcon,VRow,VSwitch,VTextarea})
