<template>
  <modal-action
    :useExternalActivatorButton="true"
    :externalIsOpen="isOpen"
    @handlerIsOpen="isOpen = $event"
    :icon="'mdi-close-circle-outline'"
    :iconColor="'red'"
    :modalTitle="$t('TXT_CANCEL_EDIT_USER')"
    :question="$t('TXT_MODAL_CANCEL_EDIT_USER_QUESTION')"
    :alertMessage="$t('TXT_MODAL_CANCEL_EDIT_USER_ALERT_MESSAGE')"
    :confirmButtonText="$t('TXT_CONFIRM')"
    @handleConfirmAction="$emit('resetUpdatedDatas', user.idUsuario)"
  >
    <template slot="externalActivatorButton">
      <v-icon
        :disabled="user.generalIsLoading"
        @click="isOpen = true"
        :color="$prdStyles('color-warning')"
        >mdi-alpha-x-box</v-icon
      >
    </template>
    <template slot="content">
      <user-infos :user="user" />
    </template>
  </modal-action>
</template>

<script>
import ModalAction from "@/components/common/modal-action.vue";
import userInfos from "../../register-new-user/components/user-infos.vue";
export default {
  components: { userInfos, ModalAction },
  props: {
    user: { type: Object },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.borderIcon {
  border: 1px solid;
  border-radius: 4px;
}
</style>