<template>
  <div>
    <Prd-loading-circular v-if="isLoading" />

    <div v-else>
      <v-row v-if="false" no-gutters justify="space-between" align="center">
        <h4>{{ $t('TXT_TICKETS') }}</h4>
        <Prd-combo-box :title="$t('TXT_SORT_BY')" :placeholder="$t('TXT_SELECT')" />
      </v-row>
      <div class="ticket-list mt-6">
        <Ticket-card
          v-for="ticket in ticketList"
          :key="ticket.idSupport_Ticket"
          :ticket="ticket"
          @showTicketDetails="showTicketDetails"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import TicketCard from "./ticket-card.vue";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import service from "@/service/support/index.js";
const Service = new service();
export default {
  props: ["isLoading"],
  components: { PrdComboBox, TicketCard, PrdLoadingCircular },
  data() {
    return {
      ticketList: [],
    };
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },
  methods: {
    async getTicketList() {
      this.$emit("setLoading", true);

      try {
        let response = await Service.getTickets(this.idCompany, 1, 10);
        this.ticketList = response?.data?.answer ?? [];
      } catch (error) {
        console.log(error);
      }

      this.$emit("setLoading", false);
    },

    showTicketDetails(ticket) {
      this.$emit("showTicketDetails", ticket);
    },
  },
  created() {
    this.getTicketList();
  },
};
</script>

<style lang="scss" scoped>
</style>