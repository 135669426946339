const predify_icons = {
  eyeOn: "far fa-eye",
  eyeOff: "far fa-eye-slash",
  menu: "fas fa-ellipsis-vertical",
  teste: "fas fa-user-gear",
  calculator: "fa-solid fa-calculator",
  copy: "fa-regular fa-copy",
  bookmarkEmpty: "fa-regular fa-bookmark",
  bookmarkFilled: "fa-solid fa-bookmark",
  downloadIcon: "fa-solid fa-file-arrow-down",
  addFilePlusCircle: "fa-file-circle-plus",
  save: "fa-solid fa-save",
  paste: "fa-paste",
  leftArrow: "mdi-arrow-left",
  edit: "mdi-pencil-outline",
  chevronRight: "mdi-chevron-right",
  info: "mdi-information-outline",
  search: "mdi-magnify",
  upload: "mdi-upload-outline",
  arrowDown: "mdi-chevron-down",
  chevronLeft: "mdi-chevron-left",
  arrowUp: "mdi-chevron-up",
  triangleExclamation: " mdi-alert-outline",
  bell: "mdi-bell-outline",
  accountCircle: "mdi-account-circle-outline",
  calendar: "mdi-calendar",
  cancel: "mdi-close",
  cached: "mdi-cached",
  trash: "mdi-trash-can-outline",
  check: "mdi-check",
  send: "mdi-send",
  image: "mdi-image",
  tag: "mdi-tag-outline",
  plus: "mdi-plus",
  arrow2: " mdi-swap-horizontal",
  dolar: "mdi-currency-usd",
  percent: "mdi-percent-outline",
  timeSand: "mdi-timer-sand-empty",
  minus: "mdi-minus",
};

export default predify_icons;
