<template>
  <v-card elevation="4">
    <highcharts class="hc" :options="chartData"></highcharts>
  </v-card>
</template>

<script>
import ServiceUPL from "@/service/upl/index";
import { formatMonetary } from "@/utils/format-toMonetary";
export default {
  props: ["product"],
  data() {
    return {
      service: new ServiceUPL(),
      dados: [],
      series: [],
      chartData: {
        chart: {
          type: "column",
        },
        title: {
          text: `<b>${this.$i18n.t("TXT_PRICING_COST_CHART")}</b>`,
          align: "left",
        },
        subtitle: {
          text: `${this.$i18n.t("TXT_SOURCE")}: SCM/Supply`,
          align: "left",
        },
        xAxis: {
          title: {
            text: this.$i18n.t("TXT_DATE"),
          },
          categories: [],
          crosshair: true,
        },
        yAxis: {
          title: {
            text: this.$i18n.t("TXT_COST"),
          },
        },

        tooltip: {
          enabled: false,
        },

        series: [
          {
            name: "",
            data: [],
            color: "#6792F8",
          },
        ],
      },
    };
  },
  beforeMount() {
    if (this.product != null) {
      this.service
        .getCustoReposicaoCompletoFiles(this.product.nome)
        .then((res) => {
          this.dados = res.data;
          this.series.push({
            name: this.product.nome,
            data: this.dados.values,
            color: "#3C5CA7",
            dataLabels: {
              enabled: true,
              formatter: function () {
                return formatMonetary(this.y);
              },
            },
          });
          this.chartData.series = this.series;
          this.chartData.xAxis.categories = this.dados.meses;
          this.chartData.subtitle.text = this.$i18n.t(
            "TXT_SUBTITLE_CHART_UPL",
            {
              font: "SCM/Supply",
              date: this.dados.dataInsert,
            }
          );
        });
    }
  },
};
</script>