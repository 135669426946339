<template>
  <div>
    <span class="font-weight-bold">{{ $t("TXT_WATCH_COMPANY_SELECT") }}</span>
    <h4 class="my-4">{{ $t("TXT_FILTERS") }}</h4>
    <v-row no-gutters class="my-4" align="end" justify="space-between">
      <v-col cols="12" md="3">
        <prd-search
          v-model="search"
          :searchPlaceholder="$t('TXT_SEARCH')"
          :searchLabel="$t('TXT_SEARCH_NAME_COD_COMPANY')"
        />
      </v-col>

      <router-link to="/main/create-company" v-if="isPredifyAdmin">
        <prd-btn :title="$t('TXT_CREATE_COMPANY')" class="mt-6" />
      </router-link>
    </v-row>
  </div>
</template>

<script>
import PrdSearch from "@/Design_System/common/prd-search.vue";

import service from "@/service/company-select/index.js";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
const Service = new service();

export default {
  components: { PrdSearch, PrdBtn },
  data() {
    return {
      search: "",
      filters: [
        {
          label: "Status da empresa",
          items: ["Ativa", "Inativa", "Bloqueada", "Free"],
          selected: null,
          margin: "mr-4",
        },
        {
          label: "País",
          items: ["Brasil", "EUA", "Canadá"],
          selected: null,
          margin: "mr-4",
        },
        {
          label: "Status da empresa",
          items: ["Mais antigas", "Mais recentes"],
          selected: null,
          margin: "mr-4",
        },
        {
          label: "Status da empresa",
          items: ["Ativa", "Inativa", "Bloqueada", "Free"],
          selected: null,
          margin: "mr-4",
        },
        {
          label: "Status da empresa",
          items: ["Ativa", "Inativa", "Bloqueada", "Free"],
          selected: null,
          margin: "mr-0",
        },
      ],
      companyList: [],
    };
  },

  computed: {
    isPredifyAdmin() {
      return this.$store.getters.isPredifyAdmin;
    },
  },

  watch: {
    search(newValue) {
      let filteredList = [];
      filteredList = this.companyList.filter((el) => {
        return (
          el.nomeRazaoSocial.toLowerCase().includes(newValue.toLowerCase()) ||
          el.idEmpresa.toString().includes(newValue)
        );
      });
      this.$emit("companyList", filteredList);
    },
  },

  methods: {
    getCompanyList() {
      this.$emit("setLoading", true);
      Service.getCompanyList()
        .then((res) => {
          this.companyList = res.data;
          this.$emit("companyList", this.companyList);
          this.$emit("setLoading", false);
        })
        .catch((error) => {
          console.log("Erro ao buscar empresas", error);
          this.$emit("setLoading", false);
        });
    },
  },

  created() {
    this.getCompanyList();
  },
};
</script>
