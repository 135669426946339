<template>
  <Prd-combo-box v-bind="$attrs" :title="$t('TXT_SAVED_FILTERS')" :items="items" :itemText="'name'" :loading="isLoading"
    :disabled="isLoading || externalDisabled" @change="$emit('change', $event)" />
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import Service from "@/service/predimonitor/filtros-predmonitor-service";
const service = new Service();
export default {
  components: { PrdComboBox },
  props: {
    externalDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      items: [],
    };
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    isCost() {
      return this.$route.query.type?.includes("cost") ?? false
    }
  },
  watch: {
    isCost: { handler() { this.getSavedFilters() }, immediate: true }
  },
  methods: {
    async getSavedFilters() {
      this.isLoading = true;
      this.$attrs.value = null
      try {
        const response = await service.ListSavedFilters({
          idCompany: this.idCompany,
          isCost: this.isCost
        });
        this.items = response?.data?.answer ?? [];
      } catch (error) {
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_LOADING_SAVE_FILTERS"),
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    reorganize(id) {
      this.items = this.items.filter(
        (filter) => filter.idMonitoringPredimonitorFilter != id
      );
    },

    addNewSavedFilter(data) {
      if (!data) return;
      this.items.push(data);
      this.$attrs.value = data
    },
  },
};
</script>