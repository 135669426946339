<template>
  <div>
    <prd-btn
      :title="$t('TXT_NEW_CRAWLER')"
      :icon="'mdi-plus'"
      @click="toggleDialog"
      :leftIcon="true"
    />
    <v-dialog v-model="showDialog" persistent max-width="600px">
      <v-card class="pa-4">
        <h3 class="mb-4">{{ $t("TXT_NEW_CRAWLER") }}</h3>
        <v-row no-gutters>
          <span class="font-weight-bold mr-2"
            >{{ $t("TXT_LAST_ID_REGISTERED") }}:</span
          >
          <span>{{ lastId }}</span>
        </v-row>
        <v-row class="mt-4" no-gutters justify="space-between" align="end">
          <div>
            <label for="id">ID</label>
            <v-text-field
              single-line
              outlined
              v-model="idCrawler"
              style="width: 80px"
              :color="existingID ? 'red' : ''"
              :hint="existingID ? $t('TXT_ID_UTILIZED') : ''"
              persistent-hint
              hide-details="auto"
              dense
              type="number"
              min="1"
            />
          </div>

          <div>
            <label for="description">{{ $t("TXT_DESCRIPTION") }}</label>
            <v-text-field
              single-line
              outlined
              v-model="description"
              style="width: 450px"
              hide-details
              dense
            ></v-text-field>
          </div>
        </v-row>

        <v-row no-gutters justify="space-between" class="mt-4 gap-4">
          <v-col>
            <prd-combo-box
              :title="$t('TXT_PLAIN_TYPE')"
              v-model="selectedType"
              :items="typeList"
              :placeholder="`${$t('TXT_SELECT')}...`"
            />
          </v-col>
          <v-col>
            <prd-combo-box
              :title="$t('TXT_SOURCE')"
              v-model="selectedSource"
              :items="sourceList"
              :placeholder="`${$t('TXT_SELECT')}...`"
            />
          </v-col>

          <!-- <prd-combo-box
            :title="$t('TXT_COUNTRY')"
            v-model="selectedCountry"
            :items="countryList"
            :placeholder="`${$t('TXT_SELECT')}...`"
            style="width: 23%"
          /> -->
          <v-col>
            <prd-combo-box
              :title="$t('TXT_LANGUAGE')"
              v-model="selectedLanguage"
              :items="languageList"
              :placeholder="`${$t('TXT_SELECT')}...`"
            />
          </v-col>
        </v-row>

        <v-row no-gutters align="center" class="mt-4 pl-1 pr-1">
          <label for="isOnline" class="mb-1 me-4"
            >{{ $t("TXT_OFFLINE") }}:</label
          >
          <v-switch v-model="isOffline" inset></v-switch>
        </v-row>

        <v-row no-gutters justify="end">
          <prd-btn
            class="me-4"
            :title="$t('TXT_CANCEL')"
            :outlined="true"
            @click="toggleDialog"
          />
          <prd-btn
            :title="$t('TXT_ADD')"
            @click="addCrawler"
            :disabled="!description || !idCrawler || existingID"
          />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import CrawlerConfigService from "@/service/crawlers/crawler-config.js";

export default {
  components: {
    PrdBtn,
    PrdComboBox,
  },
  props: {
    countryList: [],
    sourceList: [],
    languageList: [],
    typeList: [],
    lastId: Number,
    crawlerList: [],
  },
  data() {
    return {
      service: new CrawlerConfigService(),
      showDialog: false,
      description: null,
      selectedType: null,
      selectedSource: null,
      selectedCountry: null,
      selectedLanguage: null,
      isOffline: null,
      idCrawler: null,
    };
  },
  computed: {
    existingID() {
      const existingId = this.crawlerList.findIndex(
        (crawler) => crawler.idCrawler == this.idCrawler
      );
      return existingId === -1 ? false : true;
    },
  },
  methods: {
    buildRequest() {
      return {
        idCrawler: parseInt(this.idCrawler),
        description: this.description,
        crawlerType: this.selectedType?.value || null,
        idCountry: this.selectedCountry?.value || null,
        isOffline: this.isOffline,
        language: this.selectedLanguage,
        source: this.selectedSource?.value || null,
        isDeleted: false,
        isInsert: true,
      };
    },
    async addCrawler() {
      try {
        let request = this.buildRequest();
        let response = await this.service.InsertUpdateDeleteCrawler(request);
        if (response.status != 500) {
          this.$emit("ActualizarTable");
          this.$store.commit("snackbarV2/set", {
            message: `${this.$i18n.t("TXT_SUCCESS_ADD")} Crawler !`,
            type: "success",
          });
          this.toggleDialog();
        } else {
          this.$store.commit("snackbarV2/set", {
            message: `${this.$i18n.t("TXT_ERROR_ADD")} Crawler !`,
            type: "error",
          });
          this.toggleDialog();
        }
      } catch (error) {
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_METHOD") + "addCrawler",
          type: "error",
        });
      }
    },

    toggleDialog() {
      //cleaning modal
      this.description = null;
      this.selectedType = null;
      this.selectedSource = null;
      this.selectedCountry = null;
      this.selectedLanguage = null;
      this.isOnline = false;
      this.idCrawler = null;

      //open or close modal
      this.showDialog = !this.showDialog;
    },
  },
};
</script>