<template>
  <div>
    <v-row no-gutters>
      <label v-t="'TXT_SELECT_PRICING'" class="label"> </label>
      <Prd-tooltip :text="'TXT_COMBOBOX_PRICING'"
    /></v-row>
    <v-combobox
      v-model="pricing"
      :items="pricingList"
      :loading="isLoading"
      :disabled="isLoading || disablePricingList || disableList"
      :itemText="'name'"
      :placeholder="$t('TXT_SELECT')"
      @change="checkPricing($event, true)"
      @selectDate="selectDate = $event"
      v-bind="$attrs"
      append-icon="mdi-chevron-down"
      outlined
      dense
      hide-details
      return-object
      class="pricing-combobox mt-1"
    >
      <template v-slot:item="{ item }">
        <v-row no-gutters justify="space-between" align="center">
          <span>
            {{ item.name }}
          </span>
          <div class="pricing-actions">
            <v-icon
              v-if="item.published && !UPL"
              @click.stop.prevent="checkPricing(item)"
              size="28"
              class="checkbox-modal"
              >mdi-checkbox-marked</v-icon
            >
            <v-icon
              v-if="!item.published && !UPL"
              :color="$prdStyles('color-primary')"
              @click.stop.prevent="checkPricing(item)"
              size="28"
              class="checkbox-modal"
              >mdi-checkbox-blank-outline</v-icon
            >
            <Delete-precification
              v-if="UPL"
              :data="item"
              :idCompany="idCompany"
              @refreshList="$emit('refreshList')"
            />
          </div>
        </v-row>
      </template>
    </v-combobox>

    <Send-to-erp-modal
      :modalControl="erpModalControl"
      :pricing="pricing"
      @closeModal="closeErpModal"
      @refreshList="$emit('refreshList')"
    />

    <Approved-pricing-modal
      :modalControl="approvedPricingModalControl"
      :pricing="pricing"
      @listPricing="verifyPricing"
      @closeModal="closeErpModal"
    />
  </div>
</template>

<script>
import PrdTooltip from "@/Design_System/common/prd-tooltip.vue";
import DeletePrecification from "./delete-precification.vue";
import SendToErpModal from "./sendTo-erp-modal.vue";
import ApprovedPricingModal from "./approvedPricing-modal.vue";

export default {
  props: {
    preFilter: {
      default: null,
    },
    pricingList: {
      default: [],
    },
    isLoading: {
      type: Boolean,
      deafult: false,
    },
    disablePricingList: {
      type: Boolean,
      deafult: false,
    },
    disableList: {
      type: Boolean,
      deafult: false,
    },
  },

  components: {
    DeletePrecification,
    SendToErpModal,
    PrdTooltip,
    ApprovedPricingModal,
  },

  data() {
    return {
      pricing: null,
      erpModalControl: false,
      approvedPricingModalControl: false,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    UPL() {
      return this.idCompany == 2858 ? true : false;
    },
  },

  watch: {
    preFilter() {
      this.pricing = null;
      this.verifyPricing();
    },
  },

  methods: {
    checkPricing(pricing, listImmediate) {
      if (pricing.isCalculating || !pricing.calcDate) {
        this.$emit("verifyPricing", pricing);
        return;
      }

      if (!pricing.published && listImmediate) {
        this.$emit("verifyPricing", pricing);
        return;
      }

      if (pricing.published && listImmediate)
        this.approvedPricingModalControl = true;

      if (!pricing.published) this.erpModalControl = true;
      if (pricing.published) this.approvedPricingModalControl = true;

      // if (pricing.published && listImmediate)
      //   this.approvedPricingModalControl = true;

      // if (!pricing.published && listImmediate)
      //   this.approvedPricingModalControl = true;
      // else this.erpModalControl = true;

      this.pricing = pricing;
    },

    verifyPricing() {
      this.approvedPricingModalControl = false;
      this.erpModalControl = false;
      this.$emit("verifyPricing", this.pricing);
    },

    closeErpModal() {
      this.erpModalControl = false;
      this.approvedPricingModalControl = false;
      this.pricing = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.pricing-combobox {
  @media screen and (min-width: 1250px) {
    width: 24rem;
  }

  @media screen and (max-width: 1400px) {
    width: 14rem;
  }
}
.pricing-actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .checkbox-modal:hover {
    transform: scale(1.1);
  }
}
</style>