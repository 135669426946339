<template>
  <v-card class="pa-4">
    <v-expansion-panels
      v-model="panel"
      accordion
      flat
      multiple
      :disabled="isLoading || isDisabled"
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          class="ma-0 pa-0"
          expand-icon="mdi-chevron-down"
        >
          <v-row class="ma-0 pa-0" justify="space-between">
            <div style="display: flex; align-items: center">
              <label
                class="card-subtitle text-uppercase"
                v-t="'TXT_PRICE_MAP'"
              ></label>
              <v-tooltip
                max-width="550px"
                color="#F5F5F5"
                style="pointer-events: all"
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="cursor: pointer; pointer-events: all"
                    v-bind="attrs"
                    v-on="on"
                    color="#6792F8"
                    right
                    small
                  >
                    mdi-information-outline</v-icon
                  >
                </template>
                <span
                  style="color: #000"
                  v-html="$t('TXT_PRICE_TOOLTIP')"
                ></span>
              </v-tooltip>
            </div>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <!--  SELECIONAR PRODUTOS  -->
            <v-col cols="12" sm="12" md="4" class="ma-0 pa-0 mb-0 mt-0">
              <div v-if="isLoading">
                <v-skeleton-loader
                  class="my-6 px-6"
                  type="text, divider, divider, paragraph, paragraph, paragraph, paragraph"
                ></v-skeleton-loader>
              </div>

              <!-- <v-btn disabled>Valor sem TAXA: $ {{ parseFloat(selectedProductValue).toLocaleString() }}</v-btn> -->
              <label
                v-if="!isLoading"
                for=""
                v-t="'TXT_SELECT_A_PRODUCT'"
              ></label>
              <v-radio-group
                v-if="!isLoading"
                v-model="selectedProductValue"
                @change="usaMap()"
              >
                <v-radio
                  active-class="active-button"
                  v-for="(item, index) in predimonitorResults"
                  :key="index"
                  :value="item.avgPrice"
                >
                  <template v-slot:label>
                    <span>{{ item.productName }}</span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>

            <!--  MAPA  -->
            <v-col cols="12" sm="12" md="8">
              <div v-if="isLoading">
                <v-skeleton-loader
                  class="my-6 px-6"
                  type="image, divider, table-row"
                ></v-skeleton-loader>
              </div>
              <highcharts
                v-else
                :constructor-type="'mapChart'"
                :options="mapOptions"
                class="map"
              ></highcharts>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>



<script>
import axios from "axios";

export default {
  name: "priceUsaMapChart",
  data() {
    return {
      panel: [],
      ming: null,
      maxg: null,
      selectedProductValue: null,
      graphDataFiltered: null,
      // default data to empty map
      graphData: [
        ["us-ma", null],
        ["us-wa", null],
        ["us-ca", null],
        ["us-or", null],
        ["us-wi", null],
        ["us-me", null],
        ["us-mi", null],
        ["us-nv", null],
        ["us-nm", null],
        ["us-co", null],
        ["us-wy", null],
        ["us-ks", null],
        ["us-ne", null],
        ["us-ok", null],
        ["us-mo", null],
        ["us-il", null],
        ["us-in", null],
        ["us-vt", null],
        ["us-ar", null],
        ["us-tx", null],
        ["us-ri", null],
        ["us-al", null],
        ["us-ms", null],
        ["us-nc", null],
        ["us-va", null],
        ["us-ia", null],
        ["us-md", null],
        ["us-de", null],
        ["us-pa", null],
        ["us-nj", null],
        ["us-ny", null],
        ["us-id", null],
        ["us-sd", null],
        ["us-ct", null],
        ["us-nh", null],
        ["us-ky", null],
        ["us-oh", null],
        ["us-tn", null],
        ["us-wv", null],
        ["us-dc", null],
        ["us-la", null],
        ["us-fl", null],
        ["us-ga", null],
        ["us-sc", null],
        ["us-mn", null],
        ["us-mt", null],
        ["us-nd", null],
        ["us-az", null],
        ["us-ut", null],
        ["us-hi", null],
        ["us-ak", null],
      ],
      templateMap: null,
      mapTitle: null,
      rotateIcon: false,
      tab: null,
      disableDropdown: false,
      disablePanel: false,
      // State, idState, tax, divider
      taxByState: [
        { state: "Alabama", idState: "al", tax: 0.04, divider: 0.96 },
        { state: "Alaska", idState: "ak", tax: 0.0, divider: 1.0 },
        { state: "Arizona", idState: "az", tax: 0.056, divider: 0.944 },
        { state: "Arkansas", idState: "ar", tax: 0.065, divider: 0.935 },
        { state: "California", idState: "ca", tax: 0.0725, divider: 0.9275 },
        { state: "Colorado", idState: "co", tax: 0.029, divider: 0.971 },
        { state: "Connecticut", idState: "ct", tax: 0.0635, divider: 0.9365 },
        { state: "Delaware", idState: "de", tax: 0.0, divider: 1.0 },
        { state: "Florida", idState: "fl", tax: 0.06, divider: 0.94 },
        { state: "Georgia", idState: "ga", tax: 0.04, divider: 0.96 },
        { state: "Hawaii", idState: "hi", tax: 0.04, divider: 0.96 },
        { state: "Idaho", idState: "id", tax: 0.06, divider: 0.94 },
        { state: "Illinois", idState: "il", tax: 0.0625, divider: 0.9375 },
        { state: "Indiana", idState: "in", tax: 0.07, divider: 0.93 },
        { state: "Iowa", idState: "ia", tax: 0.06, divider: 0.94 },
        { state: "Kansas", idState: "ks", tax: 0.065, divider: 0.935 },
        { state: "Kentucky", idState: "ky", tax: 0.06, divider: 0.94 },
        { state: "Louisiana", idState: "la", tax: 0.0445, divider: 0.9555 },
        { state: "Maine", idState: "me", tax: 0.055, divider: 0.945 },
        { state: "Maryland", idState: "md", tax: 0.06, divider: 0.94 },
        { state: "Massachusetts", idState: "ma", tax: 0.0625, divider: 0.9375 },
        { state: "Michigan", idState: "mi", tax: 0.06, divider: 0.94 },
        { state: "Minnesota", idState: "mn", tax: 0.06875, divider: 0.93125 },
        { state: "Mississippi", idState: "ms", tax: 0.07, divider: 0.93 },
        { state: "Missouri", idState: "mo", tax: 0.04225, divider: 0.95775 },
        { state: "Montana", idState: "mt", tax: 0.0, divider: 1.0 },
        { state: "Nebraska", idState: "ne", tax: 0.055, divider: 0.945 },
        { state: "Nevada", idState: "nv", tax: 0.0685, divider: 0.9315 },
        { state: "New Hampshire", idState: "nh", tax: 0.0, divider: 1.0 },
        { state: "New Jersey", idState: "nj", tax: 0.06625, divider: 0.93375 },
        { state: "New Mexico", idState: "nm", tax: 0.05, divider: 0.95 },
        { state: "New York", idState: "ny", tax: 0.04, divider: 0.96 },
        {
          state: "North Carolina",
          idState: "nc",
          tax: 0.0475,
          divider: 0.9525,
        },
        { state: "North Dakota", idState: "nd", tax: 0.05, divider: 0.95 },
        { state: "Ohio", idState: "oh", tax: 0.0575, divider: 0.9425 },
        { state: "Oklahoma", idState: "ok", tax: 0.045, divider: 0.955 },
        { state: "Oregon", idState: "or", tax: 0.0, divider: 1.0 },
        { state: "Pennsylvania", idState: "pa", tax: 0.06, divider: 0.94 },
        { state: "Rhode Island", idState: "ri", tax: 0.07, divider: 0.93 },
        { state: "South Carolina", idState: "sc", tax: 0.06, divider: 0.94 },
        { state: "South Dakota", idState: "sd", tax: 0.045, divider: 0.955 },
        { state: "Tennessee", idState: "tn", tax: 0.07, divider: 0.93 },
        { state: "Texas", idState: "tx", tax: 0.0625, divider: 0.9375 },
        { state: "Utah", idState: "ut", tax: 0.0485, divider: 0.9515 },
        { state: "Vermont", idState: "vt", tax: 0.06, divider: 0.94 },
        { state: "Virginia", idState: "va", tax: 0.043, divider: 0.957 },
        { state: "Washington", idState: "wa", tax: 0.065, divider: 0.935 },
        { state: "West Virginia", idState: "vw", tax: 0.06, divider: 0.94 },
        { state: "Wisconsin", idState: "wi", tax: 0.05, divider: 0.95 },
        { state: "Wyoming", idState: "wy", tax: 0.04, divider: 0.96 },
      ],
    };
  },

  methods: {
    // load map
    usaMap() {
      axios
        .get("https://code.highcharts.com/mapdata/countries/us/us-all.geo.json")
        .then((res) => {
          this.templateMap = res.data;
          this.graphDataFiltered = this.taxByState.map((serie) => [
            "us-" + serie.idState,
            this.selectedProductValue / serie.divider,
          ]);
          this.ming = this.taxByState.map((serie) => [
            this.selectedProductValue / serie.divider,
          ]);
          this.maxg = this.taxByState.map((serie) => [
            this.selectedProductValue / serie.divider,
          ]);
        });
    },
  },
  created() {
    this.usaMap();
  },

  watch: {
    isLoading: {
      handler(value) {
        if (value) {
          this.panel = [];
        }
      },
      immediate: true,
    },
  },
  computed: {
    predimonitorResults() {
      return this.$store.state.PredimonitorStore.results;
    },
    isLoading() {
      return this.$store.state.PredimonitorStore.isLoading;
    },
    isDisabled() {
      return this.$store.getters.getIsDisabled;
    },

    currency() {
      return localStorage.getItem("currency") || "R$";
    },

    mapOptions() {
      return {
        plotOptions: {
          series: {
            point: {
              events: {
                // click: function (args) {
                //   alert(`
                //   INDEX: ${args.point.index}
                //   CÓDIGO: ${args.point["hc-key"]}
                //   ESTADO: ${args.point.name}`);
                // },
              },
            },
            cursor: "pointer",
          },
        },
        chart: {
          map: this.templateMap, // template map
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false, // hidden export buttons
            },
          },
        },
        title: {
          text: null, // country name
        },
        subtitle: {
          text: null,
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            align: "right",
            alignTo: "plotBox",
            verticalAlign: "bottom",
          },
        },
        colorAxis: {
          min: Math.min(...this.ming),
          max: Math.max(...this.maxg),
        },
        series: [
          {
            name: this.$i18n.t("TXT_AVERAGE_PRICE"),
            states: {
              hover: {
                color: "#EC4C37",
              },
            },
            dataLabels: {
              enabled: true,
              format: "{point.name}",
            },
            allAreas: false,
            data: this.graphDataFiltered, // states and average price source
            tooltip: {
              valueDecimals: 2,
              valuePrefix: this.currency,
            },
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.card-subtitle {
  font-size: 16px;
  font-weight: 500;
}

.active-button {
  font-weight: bold;
}

.rotateIcon {
  transform: rotate(180deg);
}
</style>


