import axiosInstance, { API_URL_RULEMANAGER } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class RuleManagerService extends ServiceBase {
  constructor() {
    super();
    axiosInstance.defaults.baseURL = API_URL_RULEMANAGER;
    this.axios = axiosInstance;
  }

  async getAvailablePrompts() {
    return await this.axios.get(
      'api/Prompt/GetAvailable'
    );
  }
}
