<template>
  <div>
    <span @click="isOpen = true">{{ $t("TXT_PRIVACY_POLICY") }} </span>
    <terms-modal
      :id="'privacyPolicyTerms'"
      :title="$t('TXT_PRIVACY_POLICY_TERMS')"
      :isOpen="isOpen"
      @closeModal="isOpen = false"
    />
  </div>
</template>

<script>
import termsModal from "@/components/common/header/components/terms-modal.vue";
export default {
  components: { termsModal },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>
<style lang="scss" scoped>
span {
  color: $brand-color-primary-pure;
  cursor: pointer;
}
</style>