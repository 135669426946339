<template>
  <v-card class="pa-4">
    <v-row no-gutters justify="space-between" align="center">
      <h4>{{ $t("TXT_OPEN_TICKET") }}</h4>
      <Prd-btn
        :title="$t('BTN_FREQUENT_QUESTIONS')"
        :outlined="true"
        :icon="'mdi-help-circle-outline'"
        :leftIcon="true"
        class="mr-4"
        @click="
          $router.push({
            name: 'faqs',
            params: {
              data: {
                previousPagePath: '/main/support',
              },
            },
          })
        "
      />
    </v-row>

    <v-row no-gutters class="mt-6 mb-4">
      <v-col cols="12" md="6">
        <Prd-text-field
          v-model="ticketTitle"
          :title="$t('LBL_TICKET_TITLE')"
          :placeholder="$t('PLH_ENTER_TICKET_TITLE')"
          class="mr-4"
        />
      </v-col>
      <v-col>
        <Prd-combo-box
          v-model="selectedSubject"
          :items="subjectItems"
          :disabled="subjectItems.length == 0 || subjectLoading"
          :loading="subjectLoading"
          :title="$t('LBL_SUBJECT')"
          :placeholder="$t('PLH_SELECT')"
          :itemText="'translatedDescription'"
          :itemValue="'idSupport_TicketSubject'"
          class="mr-4"
        />
      </v-col>
      <v-col>
        <Prd-combo-box
          v-model="selectedReason"
          :items="reasonItems"
          :disabled="
            !selectedSubject ||
            selectedSubject.value == 2 ||
            subjectLoading ||
            reasonItems.length == 0
          "
          :title="$t('LBL_REASON')"
          :placeholder="$t('PLH_SELECT')"
          :itemText="'translatedDescription'"
          :itemValue="'idSupport_TicketSubject'"
        />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <label>{{ $t("LBL_NEW_MESSAGE") }}</label>
        <v-textarea
          v-model="message"
          :placeholder="$t('PLH_WRITE_YOUR_MESSAGE')"
          outlined
          no-resize
          hide-details
          class="mt-2"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row no-gutters justify="end" class="mt-6">
      <Prd-btn
        :title="$t('BTN_CANCEL')"
        :outlined="true"
        @click="cleanInputs"
        class="mr-4"
      />
      <Confirm-ticket-modal
        :ticketTitle="ticketTitle"
        :selectedSubject="selectedSubject"
        :selectedReason="selectedReason"
        :message="message"
        @showTicketList="$emit('showTicketList')"
      />
    </v-row>
  </v-card>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import PrdTextField from "@/components/common/prd-text-field.vue";
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import ConfirmTicketModal from "./confirm-ticket-modal.vue";
import service from "@/service/support/index.js";
const Service = new service();
export default {
  components: { PrdBtn, PrdTextField, PrdComboBox, ConfirmTicketModal },
  data() {
    return {
      subjectItems: [],
      reasonItems: [],

      ticketTitle: null,
      selectedSubject: null,
      selectedReason: null,
      message: null,

      subjectLoading: false,
    };
  },

  watch: {
    selectedSubject() {
      let translatedArray = this.translateReasonItems(
        this.selectedSubject?.children ?? []
      );
      this.reasonItems = translatedArray;
      if (this.selectedSubject.value == 2) this.selectedReason = null;
    },
  },

  methods: {
    async getSubjects() {
      this.subjectLoading = true;
      try {
        let response = await Service.getSubjects();

        let data = response?.data?.answer ?? [];
        data = this.translateReasonItems(data);

        this.subjectItems = data;
      } catch (error) {
        console.log(error);
      }
      this.subjectLoading = false;
    },

    translateReasonItems(array) {
      let translatedArray = [];
      translatedArray = array.map((item, index) => {
        return {
          ...item,
          value: index,
          translatedDescription: this.$t(item.description),
        };
      });
      return translatedArray;
    },

    cleanInputs() {
      this.ticketTitle = null;
      this.selectedSubject = null;
      this.selectedReason = null;
      this.message = null;
    },
  },

  created() {
    this.getSubjects();
  },
};
</script>

<style>
</style>