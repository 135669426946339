var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4",attrs:{"disabled":_vm.isLoading,"loading":_vm.isLoading}},[_c('h3',{staticClass:"mb-6"},[_vm._v(_vm._s(_vm.$t("TXT_REGISTRED_USERS").toUpperCase()))]),_c('Prd-datatable',{attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"useSearchField":true,"titleSearchField":_vm.$t('TXT_SEARCH_USER_BY_NAME_EMAIL'),"useItemsPerPageField":true,"titleItemsPerPageField":_vm.$t('TXT_USERS_PER_PAGE'),"fieldsForSearch":_vm.fieldsForSearch,"userOthersFields":true,"indicators":_vm.indicators},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"px-0 text-center"},[_c('div',{class:("px-1 " + (_vm.getBorderByIndicators(item)))},[(item.inEditMode)?_c('Prd-text-field',{staticStyle:{"width":"100%"},attrs:{"disabled":item.generalIsLoading},model:{value:(item.nome),callback:function ($$v) {_vm.$set(item, "nome", $$v)},expression:"item.nome"}}):_c('span',[_vm._v(_vm._s(item.nome)+" ")])],1)])]}},{key:"item.telefone",fn:function(ref){
var item = ref.item;
return [(item.inEditMode)?_c('div',[_c('Prd-text-field',{attrs:{"disabled":item.generalIsLoading,"type":'number',"hide-spin-buttons":""},model:{value:(item.telefone),callback:function ($$v) {_vm.$set(item, "telefone", $$v)},expression:"item.telefone"}})],1):_c('div',[_c('span',[_vm._v(_vm._s(_vm.setMaskPhone(item.telefone)))])])]}},{key:"item.isProprietario",fn:function(ref){
var item = ref.item;
return [(item.inEditMode || item.isProprietario)?_c('Admin-access-user-modal',{attrs:{"user":item,"idCompany":_vm.selectedCompany.idEmpresa},on:{"isLoading":function($event){item.generalIsLoading = $event},"invertIsProprietario":_vm.invertIsProprietario}}):_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary')}},[_vm._v("mdi-minus")])]}},{key:"item.adminRoles",fn:function(ref){
var item = ref.item;
return [_c('Handler-regional-access',{attrs:{"item":item},on:{"handlerRegionalAccess":function($event){return _vm.handlerRegionalAccess($event, item)}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[(!item.inEditMode)?_c('div',[_c('v-icon',{staticClass:"mr-1",attrs:{"disabled":item.generalIsLoading,"color":_vm.$prdStyles('color-primary')},on:{"click":function($event){item.inEditMode = !item.inEditMode}}},[_vm._v("mdi-pencil-box")]),_c('Delete-user-modal',{attrs:{"user":item,"idCompany":_vm.selectedCompany.idEmpresa},on:{"isLoading":function($event){item.generalIsLoading = $event},"resetOriginalList":function($event){return _vm.getUsersByCompany(_vm.selectedCompany.idEmpresa)}}})],1):_c('div',[_c('Confirm-edit-user-modal',{staticClass:"mr-1",attrs:{"user":item,"originalList":_vm.items,"idCompany":_vm.selectedCompany.idEmpresa},on:{"isLoading":function($event){item.generalIsLoading = $event},"resetOriginalList":function($event){return _vm.getUsersByCompany(_vm.selectedCompany.idEmpresa)}}}),_c('Cancel-edit-user-modal',{attrs:{"user":item},on:{"resetUpdatedDatas":_vm.resetUpdatedDatas}})],1)])]}}],null,true)},[_c('template',{slot:"othersFields"},[_c('Prd-combo-box',{attrs:{"title":_vm.$t('TXT_ACCESS_PLURAL'),"placeholder":_vm.$t('TXT_SELECT'),"items":_vm.accessList,"clearable":true,"disabled":_vm.items.length <= 0},model:{value:(_vm.selectedAccess),callback:function ($$v) {_vm.selectedAccess=$$v},expression:"selectedAccess"}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }