import {
  calculateBoxPlotValuesWithOutliers
} from "@/views/main/predi-monitor/components/product-comparision/utils/methods-to-calculate"

const ProductComparisionStore = {
  state: {
    predimonitorResults: [],
    groupBy: 'productName',
    visualization: 'list',
    preparedDatasForBoxPlot: []
  },
  getters: {
  },
  mutations: {
    setResultsForProductComparision(state, list) {
      if (!list) return
      state.predimonitorResults = list
        .sort((a, b) => a.productName.localeCompare(b.productName))
        .flatMap(product =>
          product.priceResults
            .filter(result => !result.disregarded && !result.on_request)
            .map(result => ({
              ...result,
              productName: product.productName
            }))
        ) ?? []
    },

    setGroupBy(state, index) {
      const items = ['productName', 'marketplace', 'sellerName']
      state.groupBy = items[index] ?? items[0]
    },

    setVisualization(state, index) {
      const items = ['list', 'chart']
      state.visualization = items[index] ?? items[0]
    },

    setPreparedDatasForBoxPlot(state, preparedDatasForBoxPlot) {
      state.preparedDatasForBoxPlot = preparedDatasForBoxPlot ?? []
    },
  },
  actions: {
    setGroupBy({ commit, dispatch }, index) {
      commit('setGroupBy', index)
      dispatch('prepareDatas')
    },

    setVisualization({ commit }, index) {
      commit('setVisualization', index)
    },

    setResultsForProductComparision({ commit, dispatch }, list) {
      commit('setResultsForProductComparision', null)
      if (list) {
        commit('setResultsForProductComparision', list)
        dispatch('prepareDatas')
      }
    },

    async prepareDatas({ dispatch, commit }) {

      commit('setPreparedDatasForBoxPlot', null)

      const groupedResult = await dispatch('groupResults')
      if (!groupedResult) return

      let list = []

      groupedResult?.forEach((result, index) => {
        const body = {
          description: result.description,
          data: null,
          outliers: null,
        }

        const { values, outliers } = calculateBoxPlotValuesWithOutliers(index, result.prices)

        body.data = values
        body.outliers = outliers

        list.push(body)
      });

      commit('setPreparedDatasForBoxPlot', list)
    },

    async groupResults({ state }) {
      const groupedPrices = state.predimonitorResults.reduce((acc, currentObj) => {

        const ordenator = currentObj[state.groupBy]


        const accItem = acc.find(item =>
          item.description == ordenator)


        if (!accItem) {
          acc.push({
            description: ordenator,
            prices: [currentObj.price],
            items: [currentObj]
          })
        }
        else {
          accItem.items.push(currentObj)
          accItem.prices.push(currentObj.price)
        }

        return acc

      }, [])

      return groupedPrices
    }
  }
}

export default ProductComparisionStore