<template>
  <v-card flat class="pa-4" id="state-data">
    <h4 class="mb-4">COMPARATIVO DE MARGENS</h4>
    <v-row
      v-if="tableComparisonLoading"
      class="mt-10"
      no-gutters
      justify="center"
    >
      <v-progress-circular
        indeterminate
        :color="$prdStyles('color-primary')"
      ></v-progress-circular>
    </v-row>

    <v-data-table
      v-else
      :headers="headers"
      :items="tableComparison"
      hide-default-footer
      :items-per-page="27"
    >
      <template v-slot:[`header.destiny`]="{ header }">
        <div class="header-box w-30">
          <div>{{ header.text }}</div>
        </div>
      </template>
      <template v-slot:[`header.price`]="{ header }">
        <div class="header-box w-30">
          <div>{{ header.text }}</div>
        </div>
      </template>
      <template v-slot:[`header.marginOriginGO`]="{ header }">
        <div class="header-box w-30">
          <div>{{ header.text }}</div>
        </div>
      </template>
      <template v-slot:[`header.marginOriginDF`]="{ header }">
        <div class="header-box w-30">
          <div>{{ header.text }}</div>
        </div>
      </template>

      <!-- PRICE -->
      <template v-slot:[`item.price`]="{ item }">
        <div>
          <div>{{ setCurrencyFujioka(item.price) }}</div>
        </div>
      </template>

      <!-- marginOriginGO -->
      <template v-slot:[`item.marginOriginGO`]="{ item }">
        <div>
          <div>{{ formatPercentage(item.marginOriginGO) }}</div>
        </div>
      </template>

      <!-- marginOriginDF -->
      <template v-slot:[`item.marginOriginDF`]="{ item }">
        <div>
          <div>{{ formatPercentage(item.marginOriginDF) }}</div>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { setCurrencyFujioka } from "@/utils/format-currency-fujioka.js";

import { formatPercentage } from "@/utils/format-percentage.js";
export default {
  props: ["tableComparison", "tableComparisonLoading"],
  data() {
    return {
      headers: [
        {
          text: "Destino",
          align: "center",
          sortable: false,
          value: "destiny",
          width: "50px",
        },
        {
          text: "Preço cons",
          align: "center",
          sortable: false,
          value: "price",
        },
        {
          text: "RO OR.GO",
          align: "center",
          sortable: false,
          value: "marginOriginGO",
        },
        {
          text: "RO OR.DF",
          align: "center",
          sortable: false,
          value: "marginOriginDF",
        },
      ],
    };
  },

  methods: {
    setCurrencyFujioka,
    formatPercentage,
  },
};
</script>

<style lang="scss">
#state-data {
  tbody {
    tr {
      td {
        font-size: 12px !important;
      }
    }
  }
  .header-box {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: bold;

    div {
      text-align: center;
    }
  }

  th {
    font-weight: 600 !important;
    color: #000 !important;
    font-size: 11px !important;
    height: 40px !important;
  }
}
</style>