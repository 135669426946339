<template>
  <v-container fluid>
    <PageTitle
      :title="$t('TXT_COMPANY_CONFIGS').toUpperCase()"
      :breadCrumbs="breadCrumbs"
      :showTooltip="false"
      :hasTooltip="false"
    />

    <v-tabs
      v-model="tab"
      color="black"
      :slider-color="$prdStyles('color-error')"
      background-color="transparent"
      grow
      height="25px"
      class="pa-0 tab-line"
    >
      <v-tab>
        <span class="font-weight-bold">{{ $t("TXT_USER_MANAGEMENT") }}</span>
      </v-tab>
      <v-tab>
        <span class="font-weight-bold">{{
          $t("TXT_PERMISSIONS_MANAGEMENT")
        }}</span>
      </v-tab>
      <v-tab>
        <span class="font-weight-bold">{{
          $t("TXT_RESOURCE_GROUP_MANAGEMENT")
        }}</span>
      </v-tab>
      <v-tab>
        <span class="font-weight-bold">{{ $t("TXT_MANAGEMENT_STORE") }}</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="mt-6 light-background">
      <v-tab-item><User-management /></v-tab-item>
      <v-tab-item><Permission-management :tab="tab" /></v-tab-item>
      <v-tab-item><Resource-groups-main /></v-tab-item>
      <v-tab-item><Store-management-main /></v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import PageTitle from "@/Design_System/common/title.vue";
import UserManagement from "./user-management/user-management.vue";
import PermissionManagement from "./permissions-management/permission-management.vue";
import ResourceGroupsMain from "./resource-groups/resource-groups-main.vue";
import StoreManagementMain from "./store-management/store-management-main.vue";

export default {
  name: "company-config-main",
  components: {
    PageTitle,
    UserManagement,
    PermissionManagement,
    ResourceGroupsMain,
    StoreManagementMain,
  },
  data() {
    return {
      breadCrumbsItems: [
        [
          {
            text: this.$i18n.t("TXT_COMPANY_CONFIGS"),
          },
          {
            text: this.$i18n.t("TXT_USER_MANAGEMENT"),
          },
        ],
        [
          {
            text: this.$i18n.t("TXT_COMPANY_CONFIGS"),
          },
          {
            text: this.$i18n.t("TXT_PERMISSIONS_MANAGEMENT"),
          },
        ],
        [
          {
            text: this.$i18n.t("TXT_COMPANY_CONFIGS"),
          },
          {
            text: this.$i18n.t("TXT_RESOURCE_GROUP_MANAGEMENT"),
          },
        ],
        [
          {
            text: this.$i18n.t("TXT_COMPANY_CONFIGS"),
          },
          {
            text: this.$i18n.t("TXT_MANAGEMENT_STORE"),
          },
        ],
      ],
      breadCrumbs: [],
      tab: 0,
    };
  },

  watch: {
    tab: {
      handler(value) {
        this.breadCrumbs = this.breadCrumbsItems[value];
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-line {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.281);
  padding-top: -10px;
}
</style>
