<template>
  <v-container fluid>
    <pageTitle
      :title="`${$t('TXT_HELLO')}, ${userName}!`"
      :hasTooltip="false"
    />
    <div>
      <v-card class="pa-4 pt-0" :disabled="isLoading" :loading="isLoading">
        <Company-filters
          @companyList="setCompanyList"
          @setLoading="isLoading = $event"
          class="pt-4"
        />
        <Company-list :companyList="companyCurrentPage" />
      </v-card>
      <Prd-pagination
        v-model="currentPage"
        v-if="companyCurrentPage.length != 0"
        :totalRegisters="totalRecords"
        :length="totalPages"
        :showingRegistersLen="numberOfRecords"
        :resultLabel="$t('TXT_PAGINATION')"
        :pageLabel="$t('TXT_PAGE_LABEL')"
        class="mt-4"
      />
      <v-overlay v-if="selectCompanyLoading" color="#fff" z-index="100000">
        <v-progress-circular
          color="#3c5ca7"
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </div>
  </v-container>
</template>

<script>
import pageTitle from "@/Design_System/common/title.vue";
import CompanyFilters from "./components/company-filters.vue";
import CompanyList from "./components/company-list.vue";
import PrdPagination from "@/Design_System/common/prd-pagination.vue";

export default {
  name: "company-select",
  components: {
    pageTitle,
    CompanyFilters,
    CompanyList,
    PrdPagination,
  },
  data() {
    return {
      isLoading: false,
      companyListPaginated: [],
      companyCurrentPage: [],

      // Pagination vars
      currentPage: 1,
      totalRecords: null,
      totalPages: null,
      numberOfRecords: null,
    };
  },

  watch: {
    currentPage() {
      this.companyCurrentPage = this.companyListPaginated.find((el) => {
        return el.index == this.currentPage;
      });

      this.numberOfRecords = this.companyCurrentPage?.list.length ?? [];

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },

  computed: {
    userName() {
      return this.$store.getters?.userData?.email ?? null;
    },

    selectCompanyLoading() {
      return this.$store.getters?.selectCompanyLoading ?? false;
    },
  },

  methods: {
    setCompanyList(data) {
      let list = this.setPaginatedList(data, 10);
      this.companyListPaginated = list;

      this.companyCurrentPage = this.companyListPaginated[0];

      this.totalRecords = data.length;
      this.totalPages = this.companyListPaginated.length;
      this.numberOfRecords = this.companyCurrentPage?.list?.length ?? [];
    },

    setPaginatedList(array, groupSize) {
      const groupedArray = [];

      for (let i = 0; i < array.length; i += groupSize) {
        const group = {
          index: Math.floor(i / groupSize) + 1,
          list: array.slice(i, i + groupSize),
        };
        groupedArray.push(group);
      }
      return groupedArray;
    },
  },
};
</script>
