<template>
  <div>
    <prd-btn
      :title="$t('TXT_VARIATION_VARIABLES')"
      :disabled="readOnly"
      @click="isDialogOpen = true"
    />
    <v-dialog
      v-model="isDialogOpen"
      max-width="290"
      persistent
      v-if="variaveisTributacaoEdited != null"
    >
      <v-card
        class="pa-4 pt-0"
        :loading="isLoading"
        :disabled="isLoading"
        flat
        id="new-action-dialog"
      >
        <h4 class="pt-4">{{ $t("TXT_VARIATION_VARIABLES") }}</h4>

        <v-row no-gutters class="my-4">
          <v-col cols="12" class="mr-6">
            <label>{{ $t("TXT_FREIGHT") }} (%)</label>
            <v-text-field
              v-model="variaveisTributacaoEdited.frete"
              dense
              outlined
              hide-details
              type="number"
              class="mt-1"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters class="my-4">
          <v-col cols="12" class="mr-6">
            <label>{{ $t("TXT_DOLLAR") }}</label>
            <v-text-field
              v-model="variaveisTributacaoEdited.dolar"
              dense
              outlined
              hide-details
              type="number"
              class="mt-1"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters class="my-4">
          <v-col cols="12" class="mr-6">
            <label>{{ $t("TXT_INTEREST") }} BRL (%)</label>
            <v-text-field
              v-model="variaveisTributacaoEdited.jurosBRL"
              dense
              outlined
              hide-details
              type="number"
              class="mt-1"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters class="my-4">
          <v-col cols="12" class="mr-6">
            <label>{{ $t("TXT_INTEREST") }} USD (%)</label>
            <v-text-field
              v-model="variaveisTributacaoEdited.jurosUSD"
              dense
              outlined
              hide-details
              type="number"
              class="mt-1"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters class="my-4">
          <v-col cols="12" class="mr-6">
            <label>{{ $t("TXT_TAX") }} (%)</label>
            <v-text-field
              v-model="variaveisTributacaoEdited.imposto"
              dense
              outlined
              hide-details
              type="number"
              class="mt-1"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters class="my-4">
          <v-col cols="12" class="mr-6">
            <label>{{
              `${$t("TXT_AVERAGE_PERIOD")} (${$t("TXT_MONTH").toLowerCase()})`
            }}</label>
            <v-text-field
              v-model="variaveisTributacaoEdited.periodoMedio"
              dense
              outlined
              hide-details
              type="number"
              class="mt-1"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters class="mb-4">
          <prd-btn
            @click="cancelChanges"
            :title="$t('TXT_CANCEL')"
            :outlined="true"
            class="mr-4"
          />
          <prd-btn :title="$t('TXT_SAVE')" @click="saveProducts" />
        </v-row>
        <prd-btn
          :title="$t('TXT_RECALCULATE_ALL_PRODUCTS')"
          @click="aplicarMudancas"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import ServiceUPL from "@/service/upl/index";

export default {
  components: { prdBtn },
  props: ["variaveisTributacao", "readOnly"],
  data() {
    return {
      isDialogOpen: false,
      actionName: null,
      isLoading: false,
      variaveisTributacaoEdited: null,
      service: new ServiceUPL(),
    };
  },
  methods: {
    async aplicarMudancas() {
      if (confirm(this.$i18n.t("TXT_RECALCULATE_ALL_PRODUCTS_ALERT"))) {
        this.isLoading = true;
        await this.service.recalculateProduct();
        this.isLoading = false;
        this.isDialogOpen = false;
        this.$router.go(0);
      }
    },
    async saveProducts() {
      this.isLoading = true;
      await this.service.updatetVariaveisTributacao(
        this.variaveisTributacaoEdited
      );
      this.$emit("variaveisTributacao", this.variaveisTributacaoEdited);
      this.isLoading = false;
      this.isDialogOpen = false;
    },

    cancelChanges() {
      this.isDialogOpen = false;
    },
  },
  beforeMount() {
    if (this.variaveisTributacao != null) {
      this.variaveisTributacaoEdited = this.variaveisTributacao;
    }
  },
};
</script>
