<template>
  <v-expansion-panel v-model="closePanel">
    <product-header
      :product="consideredProducts"
      :productName="product"
      :dateRange="dateRange"
      :disablePanel="disablePanel"
      :closePanel="closePanel"
      @refreshProduct="$emit('refreshProduct', $event)"
    />

    <product-body
      :consideredProducts="consideredProducts"
      :disregardedProducts="disregardedProducts"
      :disablePanel="disablePanel"
      @avgPrices="avgPrices = $event"
    />
  </v-expansion-panel>
</template>

<script>
import ProductBody from "./product-body.vue";
import ProductHeader from "./product-header.vue";
export default {
  props: ["product", "dateRange"],
  components: { ProductHeader, ProductBody },
  data() {
    return {
      avgPrices: null,
    };
  },

  computed: {
    consideredProducts() {
      let consideredProducts = [];
      this.product.tableData.forEach((el) => {
        if (!el.disregarded) {
          if (!el.isManual) {
            consideredProducts.push(el);
          } else {
            if (el.quantity) {
              consideredProducts.push(el);
            }
          }
        }
        if(el.price_payment_type == null)
          el.price_payment_type = "N/A"

      });
      return consideredProducts;
    },

    disregardedProducts() {
      let disregardedProducts = [];
      this.product.tableData.forEach((el) => {
        if (el.disregarded) {
          disregardedProducts.push(el);
        }
      });

      return disregardedProducts;
    },

    disablePanel() {
      if (this.consideredProducts.length == 0 && this.disregardedProducts == 0)
        return true;
      else return false;
    },

    closePanel() {
      if (this.disablePanel) return 0;
      else return 1;
    },
  },
};
</script>


