<template>
  <div class="product-info">
    <div
      v-for="(light, index) in lightColors"
      :key="index"
      :class="{ blockButton: isLoading }"
      class="icon-flex"
    >
      <div
        @click="filterItems(index)"
        class="icon-button"
        :class="light.selected ? light.activeClass : ''"
      >
        <span v-t="light.text" class="ma-0" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      lightColors: [
        {
          key: 1,
          selected: false,
          text: "TXT_SEMAPHORE_ALL_APPROVAL",
          activeClass: "approvalitemsButton",
        },
        {
          key: 2,
          selected: false,
          text: "TXT_MANUAL_PRICE",
          activeClass: "manualitemsButton",
        },
        {
          key: 3,
          selected: false,
          text: "TXT_SEMAPHORE_ALL_REPROVAL",
          activeClass: "repprovalitemsButton",
        },
        {
          key: 0,
          selected: false,
          text: "TXT_SEMAPHORE_APPROVAL_REPROVAL",
          activeClass: "allitemsButton",
        },
        {
          key: 4,
          selected: false,
          text: "TXT_SEMAPHORE_NOT_APPLIED_RULES",
          activeClass: "notAppliedRulesButton",
        },
      ],
    };
  },
  computed: {
    cleanSemaphoreFilters() {
      return this.$store?.getters?.cleanSemaphoreFilters;
    },
  },
  watch: {
    cleanSemaphoreFilters() {
      this.lightColors.forEach((light) => {
        light.selected = false;
      });
    },
  },
  methods: {
    filterItems(index) {
      this.lightColors[index].selected = !this.lightColors[index].selected;

      const itemsToFilter = this.lightColors
        .filter((light) => light.selected)
        .map((light) => light.key);

      this.$store.commit("setSemaphoreFilter", itemsToFilter);
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-flex {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 4px;
}
.blockButton {
  pointer-events: none;
  opacity: 0.5;
}

.icon-button {
  padding: 0.2rem;
  margin-left: 0.5rem;
  min-width: 250px;
  cursor: pointer;
  transition: 0.4s;
  border-radius: 4px;
}

.icon-button:hover {
  transform: scale(1.015);
}
.product-info {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  span {
    padding-left: 4px;
  }

  .icon-flex:nth-child(1) {
    border-left: 8px solid $feedback-color-success-pure;
    .icon-button {
      border: 1px solid $feedback-color-success-pure;
    }
  }

  .icon-flex:nth-child(2) {
    border-left: 8px solid $feedback-color-helper-pure;
    .icon-button {
      border: 1px solid $feedback-color-helper-pure;
    }
  }

  .icon-flex:nth-child(3) {
    border-left: 8px solid $feedback-color-warning-pure;
    .icon-button {
      border: 1px solid $feedback-color-warning-pure;
    }
  }

  .icon-flex:nth-child(4) {
    border-left: 8px solid $brand-color-primary-pure;
    .icon-button {
      border: 1px solid $brand-color-primary-pure;
    }
  }

  .icon-flex:nth-child(5) {
    border-left: 8px solid #9510ac;
    .icon-button {
      border: 1px solid #9510ac;
    }
  }

  .approvalitemsButton {
    background-color: $feedback-color-success-pure;
    color: #fff;
  }
  .manualitemsButton {
    background-color: $feedback-color-helper-pure;
    color: #fff;
  }
  .repprovalitemsButton {
    background-color: $feedback-color-warning-pure;
    color: #fff;
  }
  .allitemsButton {
    background-color: $brand-color-primary-pure;
    color: #fff;
  }
  .notAppliedRulesButton {
    background-color: #9510ac;
    color: #fff;
  }
}
</style>