<template>
  <div>
    <v-row no-gutters class="gap-6" align="end">
      <Prd-text-field
        v-model="user.name"
        :title="$t('TXT_NAME')"
        :placeholder="$t('TXT_INSERT_NAME')"
        :loading="isLoading"
        :disabled="isLoading"
      />
      <Prd-text-field
        v-model="user.phone"
        :title="$t('TXT_PHONE')"
        :type="'number'"
        hide-spin-buttons
        :placeholder="'(xx) xxxxx-xxxx'"
        :loading="isLoading"
        :disabled="isLoading"
      />
      <Prd-combo-box
        v-model="user.language"
        :title="$t('TXT_LANGUAGE')"
        :placeholder="$t('TXT_SELECT')"
        :items="languageOptions"
        :loading="isLoading"
        :disabled="isLoading"
      />
      <Prd-radio-butons
        v-model="user.adminAccess"
        @getValue="user.adminAccess = $event"
        :row="true"
        :title="$t('TXT_ADMIN_ACCESS')"
        :options="adminOptions"
        :disabled="isLoading"
        class="mb-3"
      />
      <Prd-combo-box
        v-model="user.regionalAccess"
        :title="$t('TXT_REGIONAL_ACCESS')"
        :placeholder="$t('TXT_SELECT')"
        :items="regionalOptions"
        :multiple="true"
        :loading="isLoading"
        :disabled="isLoading"
      />
    </v-row>
    <v-row no-gutters justify="end" class="mt-6 gap-6">
      <Modal-action
        :disabledActivatorButton="
          isLoading || !user.name || !user.phone || !user.language
        "
        :titleActivatorButton="$t('TXT_CANCEL')"
        :useActivatorButtonMargin="false"
        :outlinedActivatorButton="true"
        :icon="'mdi-close-circle-outline'"
        :iconColor="'red'"
        :modalTitle="$t('TXT_CANCEL_REGISTER_USER')"
        :question="$t('TXT_MODAL_CANCEL_REGISTER_USER_QUESTION')"
        :alertMessage="$t('TXT_MODAL_CANCEL_REGISTER_USER_ALERT_MESSAGE')"
        :confirmButtonText="$t('TXT_CONFIRM')"
        @handleConfirmAction="resetFields"
      >
        <template slot="content">
          <User-infos :user="user" />
        </template>
      </Modal-action>

      <modal-action
        :disabledActivatorButton="
          isLoading || !user.name || !user.phone || !user.language
        "
        :titleActivatorButton="$t('TXT_REGISTER')"
        :useActivatorButtonMargin="false"
        :icon="'mdi-check-circle-outline'"
        :iconColor="'green'"
        :modalTitle="$t('TXT_CONFIRM_REGISTER_USER')"
        :question="$t('TXT_MODAL_CONFIRM_REGISTER_USER_QUESTION')"
        :alertMessage="$t('TXT_MODAL_CONFIRM_REGISTER_USER_ALERT_MESSAGE')"
        :confirmButtonText="$t('TXT_CONFIRM')"
        @handleConfirmAction="$emit('linkUserCompany')"
      >
        <template slot="content">
          <User-infos :user="user" />
        </template>
      </modal-action>
    </v-row>
  </div>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import PrdRadioButons from "@/components/common/prd-radio-butons.vue";
import ModalAction from "@/components/common/modal-action.vue";
import UserInfos from "./user-infos.vue";

export default {
  components: {
    PrdTextField,
    PrdComboBox,
    PrdRadioButons,
    ModalAction,
    UserInfos,
  },
  props: {
    email: { type: String },
    isLoading: { type: Boolean },
  },
  data() {
    return {
      user: {
        email: null,
        name: null,
        phone: null,
        language: null,
        adminAccess: false,
        regionalAccess: null,
      },
      languageOptions: [
        { text: this.$i18n.t("TXT_PORTUGUESE"), value: "pt-br" },
        { text: this.$i18n.t("TXT_ENGLISH"), value: "en-us" },
      ],
      adminOptions: [
        { label: this.$i18n.t("TXT_YES"), value: true },
        { label: this.$i18n.t("TXT_NO"), value: false },
      ],
      regionalOptions: [
        { text: this.$i18n.t("TXT_BRAZIL"), value: "1000" },
        { text: this.$i18n.t("TXT_UNITED_STATES"), value: "1001" },
        { text: this.$i18n.t("TXT_EUROPE"), value: "1002" },
      ],
    };
  },
  watch: {
    user: {
      handler(value) {
        this.$emit("user", value);
      },
      deep: true,
      immediate: true,
    },
    email: {
      handler(value) {
        this.user.email = value;
      },
      immediate: true,
    },
  },
  methods: {
    resetFields() {
      this.user = {
        email: null,
        name: null,
        phone: null,
        language: null,
        adminAccess: null,
        regionalAccess: null,
      };
      this.$emit("resetFields");
    },
  },
};
</script>

<style lang="scss" scoped>
.redText {
  color: $feedback-color-warning-pure;
}
</style>