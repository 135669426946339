<template>
  <v-card class="pa-2" :disabled="disableElasticity">
    <v-row no-gutters align="center" class="mb-2">
      <v-radio v-if="haveRadio"></v-radio>
      <span class="blue-text">{{ title }}</span>
      <tooltipInformations :title="tooltipText" :rightIcon="true" />
    </v-row>
    <div style="display: flex; flex-direction: column">
      <span class="font-weight-bold mb-2">{{ formatMonetary(cardPrice) }}</span>
      <span class="font-size-12"
        >{{ demandText }}:
        <br />
        <span class="font-weight-bold">{{
          formatDecimals(cardDemand, 2)
        }}</span></span
      >
      <span class="font-size-12 mt-2"
        >{{ renevueText }}:
        <br />
        <span class="font-weight-bold">{{
          formatMonetary(cardRenevue)
        }}</span></span
      >
      <span v-if="marketPrice && idCompany == 2857" class="font-size-12 mt-2"
        >{{ $t("TXT_PI_AI") }}:
        <br />
        <span class="font-weight-bold">{{ piIA }}%</span>
      </span>
    </div>
  </v-card>
</template>

<script>
import tooltipInformations from "../../../../../../../../Design_System/common/tooltip-informations.vue";
import {formatMonetary} from "@/utils/format-toMonetary.js"
import { formatDecimals } from "@/utils/format-decimals";

export default {
  props: [
    "title",
    "price",
    "demand",
    "renevue",
    "haveRadio",
    "tooltipText",
    "disableElasticity",
    "cardType",
    "marketPrice",
  ],
  components: { tooltipInformations },
  data() {
    return {};
  },

  computed: {
    demandText() {
      if (this.cardType == "elasticity")
        return this.$i18n.t("TXT_DEMAND_PROJECTION");
      else return this.$i18n.t("TXT_ELASTICITY_MEAN_QUANTITY");
    },

    renevueText() {
      if (this.cardType == "elasticity")
        return this.$i18n.t("TXT_REVENUE_PROJECTION");
      else return this.$i18n.t("TXT_REVENUE_AVG");
    },

    cardPrice() {
      if (!this.price) return "-";

      return this.price;
    },

    cardDemand() {
      if (
        this.demand === null ||
        this.demand === undefined ||
        isNaN(this.demand)
      )
        return "-";

      let rounded = Math.trunc(this.demand * 100) / 100;
      return rounded;
    },

    piIA() {
      if (!this.marketPrice || !this.price) return "-";
      let piIA = ((this.price / this.marketPrice) * 100).toFixed(2);
      return piIA;
    },

    cardRenevue() {
      if (
        this.renevue === null ||
        this.renevue === undefined ||
        isNaN(this.renevue)
      )
        return "-";

      return this.renevue;
    },

    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    hasResource(value) {
      return this.$store.getters.hasResource(value);
    },
    formatMonetary,
    formatDecimals,
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  gap: 0.5rem;
}
.blue-text {
  color: #6792f8;
  font-size: 13px;
}
</style>