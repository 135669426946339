var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4",attrs:{"flat":"","id":"state-data"}},[_c('h4',{staticClass:"mb-4"},[_vm._v("COMPARATIVO DE MARGENS")]),(_vm.tableComparisonLoading)?_c('v-row',{staticClass:"mt-10",attrs:{"no-gutters":"","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm.$prdStyles('color-primary')}})],1):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tableComparison,"hide-default-footer":"","items-per-page":27},scopedSlots:_vm._u([{key:"header.destiny",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-box w-30"},[_c('div',[_vm._v(_vm._s(header.text))])])]}},{key:"header.price",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-box w-30"},[_c('div',[_vm._v(_vm._s(header.text))])])]}},{key:"header.marginOriginGO",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-box w-30"},[_c('div',[_vm._v(_vm._s(header.text))])])]}},{key:"header.marginOriginDF",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-box w-30"},[_c('div',[_vm._v(_vm._s(header.text))])])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',[_vm._v(_vm._s(_vm.setCurrencyFujioka(item.price)))])])]}},{key:"item.marginOriginGO",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',[_vm._v(_vm._s(_vm.formatPercentage(item.marginOriginGO)))])])]}},{key:"item.marginOriginDF",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',[_vm._v(_vm._s(_vm.formatPercentage(item.marginOriginDF)))])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }