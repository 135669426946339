var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4",attrs:{"disabled":_vm.isDisabled}},[_c('h3',[_vm._v(_vm._s(_vm.$t("TXT_COMPETITORS_2").toUpperCase()))]),_vm._l((_vm.captures),function(cap,index){return _c('Product-inputs',{key:index,attrs:{"isUPL":_vm.isUPL,"index":index + 1,"cap":cap,"productsFiltered":_vm.productsFiltered,"productsUPL":_vm.productsUPL,"disabledDeleteCap":_vm.productForEdition &&
      _vm.productForEdition.tipoPrecificacao &&
      _vm.productForEdition.tipoPrecificacao != 'Mistura Externa' &&
      _vm.productForEdition.tipoPrecificacao != 'Custo'},on:{"deleteCapture":function($event){return _vm.deleteCapture(index)},"setIntExtCapData":function($event){return _vm.setIntExtCapData(index, $event)},"setUplCapData":function($event){return _vm.setUplCapData(index, $event)}}})}),(_vm.alertText ? true : false)?_c('p',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.alertText))]):_vm._e(),(
      _vm.productForEdition &&
      _vm.productForEdition.tipoPrecificacao &&
      (_vm.productForEdition.tipoPrecificacao == 'Mistura Externa' ||
        _vm.productForEdition.tipoPrecificacao == 'Custo')
    )?_c('Add-especification',{staticClass:"mt-4",attrs:{"disabled":_vm.disableAddEspecification},on:{"addCapture":_vm.addCapture}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }