<template>
  <v-dialog v-model="localIsOpen" max-width="800">
    <v-card class="pa-4">
      <v-row no-gutters>
        <v-col>
          <h2 class="text-center">{{ title }}</h2>
        </v-col>
        <v-col cols="auto">
          <v-icon @click="$emit('closeModal')" color="black"
            >mdi-close</v-icon
          >
        </v-col>
      </v-row>
      <Terms-predify v-if="id == 'useTerms'" />
      <Privacy-policy-predify v-else />
    </v-card>
  </v-dialog>
</template>

<script>
import PrivacyPolicyPredify from "./privacyPolicyPredify.vue";
import TermsPredify from "./termsPredify.vue";
export default {
  components: { TermsPredify, PrivacyPolicyPredify },
  props: ["title", "id", "isOpen"],
  data() {
    return {
      localIsOpen: true,
    };
  },
  watch: {
    isOpen: {
      handler(value) {
        this.localIsOpen = value;
      },
      immediate: true,
    },
    localIsOpen: {
      handler(value) {
        if (!value) this.$emit("closeModal");
      },
    },
  },
};
</script>