<template>
  <div class="mt-6">
    <div class="my-flex">
      <v-tabs
        v-model="tab"
        color="black"
        slider-color="#B00020"
        background-color="transparent"
        class="group-filters mb-4 mr-4"
        height="25px"
      >
        <v-tab>
          <v-row no-gutters>
            <h4>{{ $t("TXT_ALERTS").toUpperCase() }}</h4>
          </v-row>
        </v-tab>
      </v-tabs>
    </div>
    <v-row no-gutters>
      <v-col cols="12">
        <reproved-card
          v-if="(marginCard && marginCard.cardValue > 0) || isLoadingAlerts"
          class="mb-4"
          :data="marginCard"
          :isLoadingAlerts="isLoadingAlerts"
        />
        <reproved-card
          v-if="
            (competitorPriceCard && competitorPriceCard.cardValue > 0) ||
            isLoadingAlerts
          "
          class="mb-4"
          :data="competitorPriceCard"
          :isLoadingAlerts="isLoadingAlerts"
        />
        <reproved-card
          v-if="
            (profitVariationCard && profitVariationCard.cardValue > 0) ||
            isLoadingAlerts
          "
          :data="profitVariationCard"
          :isLoadingAlerts="isLoadingAlerts"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ReprovedCard from "./components/reproved-card.vue";

export default {
  props: ["alertsData", "isLoadingAlerts"],
  components: { ReprovedCard },
  data() {
    return {
      tab: 0,
    };
  },

  computed: {
    marginCard() {
      return this.alertsData?.marginCard ?? null;
    },
    psicologyPriceCard() {
      return this.alertsData?.psicologyPriceCard ?? null;
    },
    icCard() {
      return this.alertsData?.icCard ?? null;
    },
    competitorPriceCard() {
      return this.alertsData?.competitorPriceCard ?? null;
    },
    profitVariationCard() {
      return this.alertsData?.profitVariationCard ?? null;
    },
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.group-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.281);
  display: flex;
}
</style>