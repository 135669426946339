import { render, staticRenderFns } from "./tooltip-informations.vue?vue&type=template&id=84834af2&scoped=true"
import script from "./tooltip-informations.vue?vue&type=script&lang=js"
export * from "./tooltip-informations.vue?vue&type=script&lang=js"
import style0 from "./tooltip-informations.vue?vue&type=style&index=0&id=84834af2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84834af2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VTooltip})
