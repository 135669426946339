import "@fortawesome/fontawesome-free/css/all.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import predify_icons from "@/Design_System/modulos/icons";
import pt from "vuetify/src/locale/pt.ts"
import en from "vuetify/src/locale/en.ts"
import es from "vuetify/src/locale/es.ts"

const langItem = localStorage.getItem('lang');
const currentLang = langItem ? langItem.split('-')[0].toLowerCase() : 'en-US';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa",
    values: predify_icons,
  },
  theme: {
    themes: {
      light: {
        primary: "#3c5ca7",
        error: "#EC4C37",
      },
    },
  },
  lang: {
    locales: { pt, en, es },
    current: currentLang,
  },
});
