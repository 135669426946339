<template>
  <div>
    <div class="main-card">
      <Resource-group
        :isEditing="isEditing"
        @addGroup="isEditing = true"
        @editGroup="groupToEdit = $event"
        class="half-width"
      />
      <Group-description
        :isEditing="isEditing"
        :groupToEdit="groupToEdit"
        @cancelChanges="cancelChanges"
        class="half-width"
      />
    </div>
    <Resources-table :isEditing="isEditing" />
  </div>
</template>

<script>
import GroupDescription from "./components/group-description.vue";
import ResourceGroup from "./components/resource-group.vue";
import ResourcesTable from "./components/resources-table.vue";

export default {
  components: { ResourceGroup, GroupDescription, ResourcesTable },
  data() {
    return {
      isEditing: false,
      groupToEdit: null,
    };
  },

  methods: {
    cancelChanges() {
      this.isEditing = false;
      this.groupToEdit = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-card {
  display: flex;
  gap: 1rem;
  background-color: $neutral-color-high-light;

  .half-width {
    width: 50%;
  }
}
</style> 