<template>
  <div>
    <Prd-btn
      v-if="isPredifyAdmin && ticket.status != 3"
      :title="$t('BTN_CLOSE_TICKET')"
      @click="isModalOpen = true"
      color="#EC4C37"
    />
    <v-dialog v-model="isModalOpen" :max-width="600" persistent>
      <v-card class="pa-4">
        <div>
          <div class="my-flex">
            <v-icon :color="$prdStyles('color-warning')" size="50"
              >mdi-close-circle-outline</v-icon
            >
            <h4 class="my-4">{{ $t("TITLE_CONFIRM_TICKET_CLOSURE") }}</h4>
            <span>
              {{ $t("MSG_ARE_YOU_SURE_CLOSE_TICKET") }}
            </span>
          </div>

          <div class="ticket-info pa-2">
            <span class="ticket-id">Ticket#65</span>
            <span class="font-weight-bold">{{ ticket.title }}.</span>
          </div>

          <div class="text-center">
            <span class="span-destaq">
              {{ $t("INFO_TICKET_CLOSURE") }}
            </span>
          </div>

          <Prd-card-actions
            :outlinedTitle="$t('BTN_CANCEL')"
            :title="$t('BTN_CONFIRM')"
            :isDisable="isLoading"
            :loading="isLoading"
            @clickOutlined="isModalOpen = false"
            @click="closeTicket"
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import PrdCardActions from "@/components/common/prd-card-actions.vue";
import service from "@/service/support/index.js";
const Service = new service();
export default {
  props: ["ticket"],
  components: { PrdBtn, PrdCardActions },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
    };
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    isPredifyAdmin() {
      return this.$store.getters.isPredifyAdmin;
    },
  },
  methods: {
    async closeTicket() {
      this.isLoading = true;

      let request = {
        idCompany: this.idCompany,
        idSupport_Ticket: this.ticket.idSupport_Ticket,
        note: null,
      };

      try {
        await Service.closeTicket(request);
        this.$emit("showTicketList");
        this.isModalOpen = false;
        this.$store.commit("snackbarV2/set", {
          message: this.$t("MSG_SUCCESS_CLOSE_TICKET"),
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$t("MSG_ERROR_CLOSE_TICKET"),
          type: "error",
        });
      }

      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.my-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 16px;
}

.ticket-info {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.align-center {
  text-align: center;
}

.span-destaq {
  color: #b00020;
}
.ticket-id {
  color: $brand-color-primary-pure;
}
</style>