<template>
  <div>
    <p class="espaceLine mb-8">
      {{ $t("TXT_INSERT_EMAIL_TO_RECOVERY_PASSWORD") }}
    </p>
    <v-form v-model="valid" ref="myForm">
      <!--E-MAIL-->
      <p class="fieldTitle">{{ $t("TXT_EMAIL") }}</p>
      <v-text-field
        :rules="[rules.required, rules.email]"
        :placeholder="$t('TXT_EMAIL_EXAMPLE')"
        outlined
        dense
        v-model="email"
        :disabled="isLoading"
        hide-details="auto"
      ></v-text-field>
    </v-form>
    <v-col cols="12" class="pa-0 mt-8">
      <Prd-btn
        :title="$t('TXT_RECOVER')"
        style="width: 100%"
        class="mb-4"
        :disabled="!valid"
        :loading="isLoading"
        @click="recovery"
      />
      <Prd-btn
        :title="$t('TXT_CANCEL')"
        :outlined="true"
        style="width: 100%"
        :disabled="isLoading"
        @click="returnToLoginView"
      />
    </v-col>
  </div>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import LoginService from "@/service/login-service";
export default {
  components: { PrdBtn },
  data() {
    return {
      email: null,
      valid: false,
      rules: {
        required: (value) => !!value || this.$i18n.t("TXT_REQUIRED_2."),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$i18n.t("TXT_INVALID_EMAIL");
        },
      },
      isLoading: false,
      service: new LoginService(),
    };
  },
  methods: {
    returnToLoginView() {
      this.cleanVariables();
      this.$router.push({ name: "sign-in" });
    },
    async recovery() {
      this.isLoading = true;
      const request = this.createRequestForRecovery();
      try {
        await this.service.passwordRecovery(request);
        this.cleanVariables();
        this.$emit("isRecoverySuccess");
      } catch (error) {
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_RECOVER_PASSWORD"),
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
    createRequestForRecovery() {
      return {
        email: this.email.toString().toLowerCase(),
        requestUrl: process.env.VUE_APP_API,
      };
    },
    cleanVariables() {
      this.email = null;
      this.valid = false;
      this.$refs.myForm.resetValidation();
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  font-size: $font-size-xxs;
  font-weight: $font-weight-bold;
}
.espaceLine {
  line-height: 1.2 !important;
}
.fieldTitle {
  font-size: 14px !important;
  font-weight: bold !important;
  margin-bottom: 8px !important;
}
</style>