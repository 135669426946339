<template>
  <v-dialog v-model="itsOpen" persistent width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" :color="$prdStyles('color-error')">
        mdi-trash-can-outline
      </v-icon>
    </template>

    <v-card class="pa-4">
      <prd-loading-msg v-if="itsLoadingGetAgroupment" />
      <div v-else>
        <h3 class="mb-6">
          {{ $t("TXT_EXCLUDE_AGROUPMENT") }}:
          {{ agroupment.nomeAgrupamentoMacro }}
        </h3>

        <agroupment-micro-list
          class="mb-6"
          :list="agroupment.agrupamentosMicro"
        />

        <delete-alert class="mb-6" />

        <v-row no-gutters class="gap-4" align="center" justify="end">
          <prd-btn
            :title="$t('TXT_CANCEL')"
            :outlined="true"
            :disabled="itsLoadingDeleteAgroupment"
            @click="itsOpen = false"
          />
          <prd-btn
            :title="$t('TXT_EXCLUDE_CONFIRM')"
            :color="$prdStyles('color-warning')"
            :loading="itsLoadingDeleteAgroupment"
            @click="deleteAgroupment"
          />
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import UPLService from "@/service/upl";
import PrdLoadingMsg from "@/components/common/prd-loading-msg.vue";
import AgroupmentMicroList from "./components/agroupment-micro-list.vue";
import DeleteAlert from "./components/delete-alert.vue";
export default {
  components: {
    PrdBtn,
    PrdLoadingMsg,
    AgroupmentMicroList,
    DeleteAlert,
  },

  props: { id: { type: String, default: "" } },

  data() {
    return {
      itsOpen: false,
      itsLoadingGetAgroupment: false,
      itsLoadingDeleteAgroupment: false,
      agroupment: {},
      service: new UPLService(),
    };
  },

  watch: {
    itsOpen: {
      handler(value) {
        if (value) this.getAgroupment();
      },
    },
  },

  methods: {
    async getAgroupment() {
      this.agroupment = {};
      this.itsLoadingGetAgroupment = true;
      try {
        const response = await this.service.getAgroupmentById(this.id);
        this.agroupment = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.itsLoadingGetAgroupment = false;
      }
    },

    async deleteAgroupment() {
      this.itsLoadingDeleteAgroupment = true;
      try {
        await this.service.deleteAgroupmentById(this.id);
        this.agroupment = {};
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_SUCCESS_EXCLUDE_GROUPING"),
          type: "success",
        });
        this.$emit("refreshTargetMarketSegmentationTable");
        this.itsOpen = false;
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_EXCLUDE_GROUPING"),
          type: "error",
        });
      } finally {
        this.itsLoadingDeleteAgroupment = false;
      }
    },
  },
};
</script>
