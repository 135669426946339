<template>
  <v-row no-gutters justify="space-between" align="center">
    <v-col>
      <v-row no-gutters style="gap: 16px">
        <prd-combo-box
          :title="$t('TXT_CATEGORY')"
          :placeholder="`${
            $t('TXT_SELECT_A') + $t('TXT_CATEGORY').toLowerCase()
          }`"
          v-model="search.categories"
          :items="categories"
        />
        <prd-combo-box
          :title="`Sub${$t('TXT_CATEGORY').toLowerCase()}`"
          :placeholder="`${
            $t('TXT_SELECT_A') + 'Sub' + $t('TXT_CATEGORY').toLowerCase()
          }`"
          v-model="search.subCategories"
          :items="subCategories"
          :disabled="subCategories.length <= 1"
        />
      </v-row>
    </v-col>
    <v-col>
      <v-row no-gutters justify="end" align="end">
        <span class="font-weight-bold mr-2">{{ $t("TXT_VISUALIZE") }}:</span>
        <v-tabs
          style="max-width: 191px"
          color="black"
          slider-color="#EC4C37"
          background-color="transparent"
          height="25px"
        >
          <v-tab @click="$emit('tab', 0)">{{ $t("TXT_GRAPHIC") }}</v-tab>
          <v-tab @click="$emit('tab', 1)">{{ $t("TXT_TABLE") }}</v-tab>
        </v-tabs>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import prdComboBox from "@/Design_System/common/prd-comboBox.vue";

export default {
  components: { prdComboBox },
  props: ["allData", "allMargins"],
  data() {
    return {
      categories: [],
      subCategories: [],
      search: {
        categories: null,
        subCategories: null,
      },
      oldCategory: null,
    };
  },
  methods: {
    resetCategories() {
      this.categories = [];
      this.search.categories = null;
    },
    prepareCategories(list) {
      const categories = list.map((data) => {
        return data.category;
      });
      const uniqueCategories = categories.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      this.categories.push(...uniqueCategories);
      this.search.categories = this.categories[0];
    },
    resetSubCategories() {
      this.subCategories = [];
      this.search.subCategories = null;
    },
    prepareSubCategories(list) {
      const categories = list.map((data) => {
        return data.subCategory;
      });
      const uniqueCategories = categories.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      this.subCategories.push(...uniqueCategories);
      if (!this.search.subCategories)
        this.search.subCategories = this.subCategories[0];
    },
    setMargins(subCategory) {
      const selectedMargin =
        this.allMargins.find(
          (item) => item.valueType.toLowerCase() === subCategory.toLowerCase()
        ) ??
        this.allMargins.find(
          (item) => item.valueType.toLowerCase() === "outros"
        );
      this.$emit("superiorLimit", selectedMargin.max);
      this.$emit("inferiorLimit", selectedMargin.min);
    },
    filterList(search) {
      let newFilteredItems = this.allData;

      if (search.categories != this.oldCategory) {
        this.resetSubCategories();
        this.oldCategory = search.categories;
      }

      if (search.categories != null) {
        newFilteredItems = newFilteredItems.filter(
          (item) =>
            item.category.toLowerCase() === search.categories.toLowerCase()
        );
        this.prepareSubCategories(newFilteredItems);
      }

      if (search.subCategories != null) {
        newFilteredItems = newFilteredItems.filter(
          (item) =>
            item.subCategory.toLowerCase() ===
            search.subCategories.toLowerCase()
        );
        this.setMargins(search.subCategories);
      }
      this.$emit("filteredItems", newFilteredItems);
    },
  },
  watch: {
    allData: {
      handler(value) {
        if (value) {
          this.resetCategories();
          this.resetSubCategories();
          this.prepareCategories(value);
        }
      },
      immediate: true,
    },
    search: {
      handler(value) {
        this.filterList(value);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style></style>