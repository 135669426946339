<template>
  <v-data-table
    :headers="headers"
    :items="localItems"
    :hide-default-footer="localItems == [] || localItems.length == 0"
    :footer-props="footerProps"
    :no-data-text="$t('TXT_NO_DATA_AVAILABLE')"
  >
    <template v-slot:[`item.unitPriceListUSD`]="{ item }">
      {{ setCurrencyUPL(item.unitPriceListUSD, true) }}
    </template>
    <template v-slot:[`item.ordersPrice`]="{ item }">
      {{ setCurrencyUPL(item.ordersPrice, true) }}
    </template>
    <template v-slot:[`item.percertAdhesion`]="{ item }">
      <span :class="setColor(item.percertAdhesion)">{{
        item.percertAdhesion
      }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { extractNumber } from "@/utils/extract-number";

export default {
  props: {
    localItems: { type: Array, default: () => [], required: true },
    headers: { type: Array, default: () => [], required: true },
    setCurrencyUPL: { type: Function, default: () => "-", required: true },
  },
  data() {
    return {
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
    };
  },
  methods: {
    setColor(value) {
      const realValue = extractNumber(value);

      let color = "yellow";

      if (realValue >= 95) color = "green";
      else if (realValue < 90) color = "red";

      return `${color}-text`;
    },
  },
};
</script>

<style lang="scss" scoped>
.red-text {
  color: $feedback-color-warning-pure !important;
}
.yellow-text {
  color: $feedback-color-helper-pure !important;
}
.green-text {
  color: $feedback-color-success-pure !important;
}
</style>