<template>
  <div>
    <direcionadorPrecosConcorrente
      v-if="isCompetitor || (isCost && hasCaptures)"
      v-bind:product="this.product"
    />
    <direcionadorPrecosCusto v-else-if="isCost" v-bind:product="this.product" />
    <direcionadorPrecosMisturaInterna
      v-else-if="isInternalMix"
      v-bind:product="this.product"
    />
    <direcionadorPrecosMisturaExterna
      v-else-if="isExternalMix"
      v-bind:product="this.product"
    />
  </div>
</template>

<script>
import DirecionadorPrecosConcorrente from "./components/direcionador-precos-concorrente.vue";
import DirecionadorPrecosCusto from "./components/direcionador-precos-custo.vue";
import DirecionadorPrecosMisturaInterna from "./components/direcionador-precos-mistura-interna.vue";
import DirecionadorPrecosMisturaExterna from "./components/direcionador-precos-mistura-externa.vue";

export default {
  components: {
    DirecionadorPrecosConcorrente,
    DirecionadorPrecosCusto,
    DirecionadorPrecosMisturaInterna,
    DirecionadorPrecosMisturaExterna,
  },
  props: ["product"],
  computed: {
    isCompetitor() {
      return this.product?.tipoPrecificacao == "Concorrente" ?? false;
    },
    isCost() {
      return this.product?.tipoPrecificacao == "Custo" ?? false;
    },
    hasCaptures() {
      return this.product?.capturas?.length > 0;
    },
    isInternalMix() {
      return this.product?.tipoPrecificacao == "Mistura Interna" ?? false;
    },
    isExternalMix() {
      return this.product?.tipoPrecificacao == "Mistura Externa" ?? false;
    },
  },
};
</script>