<template>
  <v-row no-gutters class="gap-4" align="end">
    <v-col cols="4">
      <Prd-combo-box
        v-model="search.product"
        :title="$t('TXT_PRODUCTS')"
        :items="productItems"
      />
    </v-col>
    <v-col cols="4">
      <Prd-combo-box
        v-model="search.competitor"
        :title="$t('TXT_COMPETITORS')"
        :items="competitorItems"
      />
    </v-col>
    <v-spacer />
    <Prd-btn
      v-if="selectedState"
      :title="$t('TXT_BACK')"
      @click="resetSelectedState"
    />
  </v-row>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";

export default {
  components: { PrdComboBox, PrdBtn },
  props: {
    externalLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      allProducts: this.$i18n.t("TXT_ALL_PRODUCTS").toUpperCase(),
      allCompetitors: this.$i18n.t("TXT_ALL_COMPETITOR").toUpperCase(),
      search: {
        product: this.$i18n.t("TXT_ALL_PRODUCTS").toUpperCase(),
        competitor: this.$i18n.t("TXT_ALL_COMPETITOR").toUpperCase(),
      },
    };
  },

  computed: {
    productItems() {
      return this.$store?.getters?.getProductList ?? [];
    },
    competitorItems() {
      return this.$store?.getters?.getCompetitorList ?? [];
    },
    selectedState() {
      return this.$store?.getters?.getSelectedState ?? null;
    },
  },

  watch: {
    search: {
      handler(value) {
        this.$store.dispatch("setProductAndCompetitor", value);
      },
      deep: true,
      immediate: true,
    },

    externalLoading: {
      handler(value) {
        if (value) {
          this.search.product = this.allProducts;
          this.search.competitor = this.allCompetitors;
          this.$store.dispatch("setProductAndCompetitor", this.search);
        }
      },
    },
  },

  methods: {
    async resetSelectedState() {
      await this.$store.dispatch("setState", null);
    },
  },
};
</script>