<template>
  <div class="header">
    <span>{{ $t("TXT_CATEGORY") }}:</span>
    <span class="fontBold">{{ categoryText }}</span>
  </div>
</template>

<script>
export default {
  props: ["category"],
  data() {
    return {
      categories: [
        { text: this.$i18n.t("TXT_ALL_2"), value: "TODAS" },
        { text: this.$i18n.t("TXT_ALCOHOL"), value: "BEBIDAS ALCOÓLICAS" },
        {
          text: this.$i18n.t("TXT_NON_ALCOHOLIC_BEVARAGES"),
          value: "BEBIDAS NÃO ALCOÓLICAS",
        },
        {
          text: this.$i18n.t("TXT_BISCUIT_SNACKS"),
          value: "BISCOITOS E SNACKS",
        },
        { text: "Bomboniere", value: "BOMBONIERE" },
        { text: this.$i18n.t("TXT_FROZEN"), value: "CONGELADOS" },
      ],
    };
  },
  computed: {
    categoryText() {
      return this.categories.find((category) => category.value == this.category)
        .text;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: $neutral-color-high-light;
  padding: 16px 0px;
  margin: 24px 0px;
  gap: 4px;
}
.fontBold {
  font-weight: bold;
}
</style>