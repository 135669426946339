import i18n from "@/i18n";

const AnnouncementMapV2Store = {
  state: {
    allProducts: i18n.t("TXT_ALL_PRODUCTS")?.toUpperCase(),
    allCompetitors: i18n.t("TXT_ALL_COMPETITOR")?.toUpperCase(),
    search: {
      product: i18n.t("TXT_ALL_PRODUCTS")?.toUpperCase(),
      competitor: i18n.t("TXT_ALL_COMPETITOR")?.toUpperCase(),
      state: null,
    },
    affiliateList: [],
    predimonitorResults: [],
    listForTable: [],
    mapPoints: [],
    itsProcessingData: false,
    isCostPredimonitor: false,
  },
  getters: {
    getAffiliateList: (state) => state.affiliateList,
    getPredimonitorResults: (state) => state.predimonitorResults,
    getMapPoints: (state) => state.mapPoints,
    getCompetitorList: (state) => {
      const competitorNames = [].concat(
        ...state.predimonitorResults.map((item) => {
          return item.priceResults
            .filter((result) => result.coordinates != null)
            .map((result) => result.sellerName?.toUpperCase());
        })
      );

      const uniqueCompetitorsName = new Set(competitorNames);

      return [state.allCompetitors, ...uniqueCompetitorsName];
    },
    getProductList: (state) => {
      const productsNames = state.predimonitorResults.map(
        (item) => item.productName
      );
      return [state.allProducts, ...productsNames];
    },
    getSelectedProduct: (state) => state.search.product,
    getSelectedCompetitor: (state) => state.search.competitor,
    getSelectedState: (state) => state.search.state,
    getAllProducts: (state) => state.allProducts,
    getAllCompetitors: (state) => state.allCompetitors,
    getListForTable: (state) => state.listForTable,
    getItsProcessingData: (state) => state.itsProcessingData,
    getIsCostPredimonitor: (state) => state.isCostPredimonitor,
  },
  mutations: {
    setProductAndCompetitor(state, payload) {
      state.search.product = payload.product;
      state.search.competitor = payload.competitor;
    },
    setMapPoints(state, list) {
      state.mapPoints = [];
      state.mapPoints = list;
    },
    setAffiliateList(state, list) {
      state.affiliateList = [];
      state.affiliateList = list;
    },
    setPredimonitorResults(state, list) {
      state.predimonitorResults = [];
      state.predimonitorResults = list;
    },
    setListForTable(state, list) {
      state.listForTable = [];
      state.listForTable = list;
    },
    setSearchState(state, selectedState) {
      state.search.state = null;
      state.search.state = selectedState;
    },
    setItsProcessingData(state) {
      state.itsProcessingData = !state.itsProcessingData;
    },
    setIsCostPredimonitor(state, payload) {
      state.isCostPredimonitor = payload;
    },
  },
  actions: {
    setProductAndCompetitor(context, payload) {
      context.commit("setProductAndCompetitor", payload);
      context.dispatch("preparePointsList");
    },

    setState(context, selectedState) {
      context.commit("setSearchState", selectedState);
      context.dispatch("preparePointsList");
    },

    setIsCostPredimonitor(context, payload) {
      context.commit("setIsCostPredimonitor", payload);
    },

    setAffiliateList(context, list) {
      context.commit("setAffiliateList", list);
      context.dispatch("preparePointsList");
    },

    setResultsForAnnouncementMapV2(context, list) {
      list.forEach((extResult) => {
        extResult.priceResults.forEach((intResult) => {
          intResult.productName = extResult.productName;
        });
      });
      context.commit("setPredimonitorResults", list);
      context.dispatch("preparePointsList");
    },

    setItsProcessingData(context) {
      context.commit("setItsProcessingData");
    },

    async preparePointsList(context) {
      await context.dispatch("setItsProcessingData");

      let list = [];

      await context.dispatch("prepareFillials", list);

      await context.dispatch("prepareCompetitors", list);

      await context.commit("setMapPoints", list);

      await context.dispatch("setItsProcessingData");
    },

    async prepareFillials(context, list) {
      const allFillials = context.getters.getAffiliateList;
      allFillials?.forEach(async (fillial) => {
        const filteredFillial = await context.dispatch(
          "filterFillialResults",
          fillial
        );
        if (!filteredFillial) return;
        if (filteredFillial.mapProducts.length > 0) {
          const body = await context.dispatch(
            "prepareBodyToFillial",
            filteredFillial
          );
          list.push(body);
        }
      });
    },

    async filterFillialResults(context, fillial) {
      let deepCopy = JSON.parse(JSON.stringify(fillial));

      const selectedState = context.getters.getSelectedState;
      if (
        selectedState &&
        deepCopy.state?.toUpperCase() !== selectedState?.toUpperCase()
      ) {
        return null;
      }

      const selectedProduct = context.getters.getSelectedProduct;
      const allProducts = context.getters.getAllProducts;
      if (selectedProduct != allProducts) {
        const filteredProducts = deepCopy.mapProducts.filter((product) => {
          return (
            product.productName?.toUpperCase() == selectedProduct?.toUpperCase()
          );
        });
        deepCopy.mapProducts = filteredProducts;
      }

      return deepCopy;
    },

    async prepareBodyToFillial(context, fillial) {
      const lat = fillial.coordinates.latitude;
      const lng = fillial.coordinates.longitude;
      const name = fillial.description;
      const type = "fillial";
      const cityStateAddress =
        fillial.city && fillial.state
          ? `${fillial.city?.toUpperCase()}-${fillial.state?.toUpperCase()}`
          : "-";
      const address = fillial.address ?? cityStateAddress;
      const amountAnnouncement = fillial.amountAnnouncement ?? null;
      const price =
        fillial.mapProducts.length > 0
          ? await context.dispatch("calcAveragePrice", fillial.mapProducts)
          : 0;
      return { lat, lng, name, type, price, address, amountAnnouncement };
    },

    async calcAveragePrice(context, list) {
      const sum = list.reduce((acc, nextObj) => {
        const IsCostPredimonitor = context.getters.getIsCostPredimonitor;
        const priceKey = IsCostPredimonitor ? "cost" : "lastPrice";
        return acc + (nextObj[priceKey] ?? nextObj);
      }, 0);
      const average = sum / list.length;
      return Number(average.toFixed(2));
    },

    async prepareCompetitors(context, list) {
      let listToTable = [];
      const predimonitorResults = context.getters.getPredimonitorResults;

      await predimonitorResults?.forEach(async (competitor) => {
        const filteredProduct = await context.dispatch(
          "filterPriceResults",
          competitor
        );
        const bodies = await Promise.all(
          await context.dispatch(
            "prepareBodyToCompetitor",
            filteredProduct.priceResults
          )
        );
        list.push(...bodies);
        listToTable.push({
          ...competitor,
          priceResults: filteredProduct.priceResults,
        });
      });

      await context.commit("setListForTable", listToTable);
    },

    async filterPriceResults(context, product) {
      let deepCopy = JSON.parse(JSON.stringify(product));

      const selectedProduct = context.getters.getSelectedProduct;
      const allProducts = context.getters.getAllProducts;
      if (selectedProduct != allProducts) {
        const filteredResults = deepCopy.priceResults.filter(
          (result) =>
            result.productName?.toUpperCase() == selectedProduct?.toUpperCase()
        );
        deepCopy.priceResults = filteredResults;
      }

      const selectedCompetitor = context.getters.getSelectedCompetitor;
      const allCompetitors = context.getters.getAllCompetitors;
      if (selectedCompetitor != allCompetitors) {
        const filteredResults = deepCopy.priceResults.filter(
          (result) =>
            result.sellerName?.toUpperCase() ==
            selectedCompetitor?.toUpperCase()
        );

        deepCopy.priceResults = filteredResults;
      }

      const selectedState = context.getters.getSelectedState;
      if (selectedState) {
        const filteredResults = deepCopy.priceResults.filter(
          (result) =>
            result.state?.toUpperCase() == selectedState?.toUpperCase()
        );

        deepCopy.priceResults = filteredResults;
      }

      return deepCopy;
    },

    async prepareBodyToCompetitor(context, priceResults) {
      let list = [];

      priceResults?.forEach((result) => {
        if (result.disregarded || result.on_request || !result.coordinates)
          return;

        const lat = result.coordinates.latitude;
        const lng = result.coordinates.longitude;
        const price = result.price;
        const cityStateAddress =
          result.city && result.state
            ? `${result.city?.toUpperCase()}-${result.state?.toUpperCase()}`
            : "-";
        const address = result.address ?? cityStateAddress;

        const foundedIndex = list.findIndex(
          (item) => item.lat == lat && item.lng == lng
        );
        if (foundedIndex == -1) {
          const name = result.sellerName;
          const type = result.format_market ?? i18n.t("TXT_UNKNOW");
          const body = {
            lat,
            lng,
            name,
            type,
            prices: [price],
            amountAnnouncement: 1,
            address,
          };
          list.push(body);
        } else {
          list[foundedIndex].prices.push(price);
          list[foundedIndex].amountAnnouncement += 1;
        }
      });

      const mappedList = list.map(async (item) => {
        const average = await context.dispatch("calcAveragePrice", item.prices);
        const body = {
          lat: item.lat,
          lng: item.lng,
          name: item.name,
          type: item.type,
          price: average,
          address: item.address,
          amountAnnouncement: item.amountAnnouncement,
        };
        return body;
      });

      return Promise.all(mappedList);
    },
  },
};

export default AnnouncementMapV2Store;
