<template>
  <div class="my-6">
    <h4 class="mb-4">{{ $t('TXT_FILTERS') }}</h4>
    <v-row no-gutters>
      <v-col>
        <Prd-text-field
          v-model="ticketName"
          :title="$t('TXT_TICKET_NUMBER_OR_TITLE')"
          :placeholder="$t('TXT_ENTER_CODE_OR_TITLE')"
          :isDisabled="isLoading"
          class="mr-4"
        />
      </v-col>
      <v-col>
        <Prd-text-field
          v-model="companyName"
          :title="$t('TXT_COMPANY')"
          :placeholder="$t('TXT_ENTER_COMPANY_NAME_OR_CODE')"
          :isDisabled="isLoading"
          class="mr-4"
        />
      </v-col>
      <v-col>
        <Prd-combo-box
          v-model="subject"
          :title="$t('TXT_SUBJECT')"
          :placeholder="$t('TXT_SELECT')"
          :disabled="isLoading"
          class="mr-4"
        />
      </v-col>
      <v-col>
        <Prd-combo-box
          v-model="reason"
          :title="$t('TXT_REASON')"
          :placeholder="$t('TXT_SELECT')"
          :disabled="isLoading"
          class="mr-4"
        />
      </v-col>
      <v-col>
        <Prd-combo-box
          :title="$t('TXT_STATUS')"
          :placeholder="$t('TXT_SELECT')"
          :disabled="isLoading"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
export default {
  props: ["isLoading"],
  components: { PrdTextField, PrdComboBox },
  data() {
    return {
      ticketName: null,
      companyName: null,
      subject: null,
      reason: null
    };
  },
};
</script>

<style>
</style>