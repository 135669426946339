<template>
  <v-container fluid>
    <v-row no-gutters align="center" justify="space-between">
      <titleBreadCrumbsComponent
        :title="$t('TXT_EXECUTE_MANUAL_CRAWLER').toUpperCase()"
        :hasTooltip="false"
        :showTooltip="false"
      />
      <router-link to="/main/predimonitor-V2">
        <prd-btn
          :title="$t('TXT_BACK')"
          :icon="'mdi-arrow-left'"
          :leftIcon="true"
        />
      </router-link>
    </v-row>

    <div class="px-4" style="background-color: #fff">
      <v-tabs
        v-model="selectedTab"
        color="black"
        :slider-color="$prdStyles('color-secundary')"
        height="40px"
        class="border-destaq"
      >
        <v-tab class="pa-0"> {{ $t("TXT_CREATE_CRAWLER") }} </v-tab>
        <v-tab> {{ $t("TXT_HISTORIC") }} </v-tab>
      </v-tabs>
    </div>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item>
        <v-card class="pa-4 pt-0" :disabled="executeLoading">
          <h4 class="mb-4 pt-4">{{ $t("TXT_SELECT") }}</h4>

          <v-radio-group v-model="selection" hide-details>
            <div class="product-row">
              <!-- PRODUCT -->
              <div class="flex-grow-1">
                <v-radio value="product" :label="$t('TXT_PRODUCT')"></v-radio>
                <v-combobox
                  v-model="selectedProduct"
                  :items="productList"
                  :loading="productListLoading"
                  :disabled="
                    productListLoading ||
                    productList.length == 0 ||
                    selection == 'group'
                  "
                  outlined
                  dense
                  hide-details
                  append-icon="mdi-chevron-down"
                  item-text="description"
                  item-value="idMonitoringItem"
                  class="mt-2"
                >
                  <template v-slot:append-outer>
                    <v-row>
                      <v-icon
                        :disabled="!selectedProduct"
                        :color="$prdStyles('color-success')"
                        @click.stop="selectProduct"
                        large
                        class="mt-2 ml-2 mr-2"
                      >
                        mdi-plus-box
                      </v-icon>
                    </v-row>
                  </template>
                </v-combobox>
              </div>

              <!-- GROUP -->
              <div class="flex-grow-1">
                <v-radio
                  value="group"
                  :label="`${$t('TXT_PRODUCT_GROUP')}s`"
                ></v-radio>
                <v-combobox
                  v-model="selectedGroup"
                  :items="groupList"
                  :loading="groupListLoading"
                  :disabled="
                    groupListLoading ||
                    groupList.length == 0 ||
                    selection == 'product'
                  "
                  @change="selectGroup"
                  outlined
                  dense
                  hide-details
                  append-icon="mdi-chevron-down"
                  class="mt-2"
                  item-text="description"
                  item-value="idMonitoringGroup"
                >
                </v-combobox>
              </div>

              <!-- CRAWLER -->
              <div class="flex-grow-1 opacity-color">
                <label>Crawler</label>
                <v-combobox
                  v-model="crawlerSelected"
                  :items="crawlerItems"
                  :loading="loadingCrawlers"
                  :disabled="disableCrawlerInput || allCrawlerSelected"
                  :label="
                    allCrawlerSelected ? $t('TXT_ALL_CRAWLERS_SELECTED') : ''
                  "
                  single-line
                  multiple
                  small-chips
                  deletable-chips
                  item-text="description"
                  item-value="idCompanyMonitoringCrawler"
                  dense
                  outlined
                  hide-details
                  append-icon="mdi-chevron-down"
                  class="mt-2"
                />
                <v-checkbox
                  v-model="allCrawlerSelected"
                  :disabled="crawlerSelected.length > 0"
                  @click="selectAllCrawlers"
                  :label="$t('TXT_SELECT_CRAWLERS')"
                  class="ma-0 pa-0 mt-2"
                  hide-details
                ></v-checkbox>
              </div>
            </div>
          </v-radio-group>

          <Prd-loading-circular v-if="groupLoading" class="mt-6" />

          <div v-else class="list-item-box mt-6">
            <div
              v-for="(product, index) in products"
              :key="index"
              class="list-item"
            >
              <span class="font-weight-bold font-size-16">{{
                product.description
              }}</span>
              <v-icon
                :color="$prdStyles('color-warning')"
                @click="deleteItem(index)"
              >
                mdi-delete-outline
              </v-icon>
            </div>
          </div>

          <v-icon
            v-if="!showAddProduct && selection == 'group'"
            :disabled="groupLoading || !selectedGroup"
            :color="$prdStyles('color-success')"
            @click="showAddProduct = !showAddProduct"
            large
          >
            mdi-plus-box
          </v-icon>

          <v-row v-if="showAddProduct" no-gutters>
            <v-col cols="12" md="5" class="mr-6">
              <label>{{ $t("TXT_PRODUCT") }}</label>
              <v-combobox
                v-model="selectedProduct"
                :items="productList"
                :loading="productListLoading"
                :disabled="productListLoading || productList.length == 0"
                outlined
                dense
                hide-details
                append-icon="mdi-chevron-down"
                item-text="description"
                item-value="idMonitoringItem"
                class="mt-2"
              >
              </v-combobox>
            </v-col>

            <prd-btn
              :title="$t('TXT_CANCEL')"
              :outlined="true"
              class="mr-4 mt-8"
              @click="showAddProduct = !showAddProduct"
            />

            <prd-btn
              @click="selectProduct"
              :title="$t('TXT_INCLUDE')"
              class="mt-8"
            />
          </v-row>

          <v-row no-gutters justify="end">
            <prd-btn
              :loading="executeLoading"
              :disabled="disableExecuteButton"
              :title="$t('TXT_EXECUTE')"
              @click="execute"
            />
          </v-row>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <Crawler-history :idCompany="idCompany" :selectedTab="selectedTab" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";

import service from "@/service/crawlers/crawler-execute.js";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import CrawlerHistory from "./crawler-history.vue";

const Service = new service();

export default {
  components: {
    titleBreadCrumbsComponent,
    PrdBtn,
    PrdLoadingCircular,
    CrawlerHistory,
  },
  name: "crawler-execute-main",
  data() {
    return {
      breadCrumbs: [
        {
          text: this.$i18n.t("TXT_DASHBOARD_PRICING"),
        },
        {
          text: "Predimonitor",
        },
        {
          text: this.$i18n.t("TXT_EXECUTE_MANUAL_CRAWLER"),
        },
      ],
      products: [],
      crawlerItems: [],
      selection: "product",
      selectedProduct: null,
      selectedGroup: null,
      crawlerSelected: [],

      allCrawlerSelected: false,
      crawlerSelectedItems: [],

      productList: [],
      groupList: [],
      selectedTab: 0,

      showAddProduct: false,
      productListLoading: false,
      groupListLoading: false,
      groupLoading: false,
      executeLoading: false,
      loadingCrawlers: false,
    };
  },

  watch: {
    selection() {
      if (this.selection == "group") {
        this.selectedProduct = null;
        this.crawlerSelected = [];
      } else {
        this.selectedGroup = null;
        this.crawlerSelected = [];
      }
      this.products = [];
    },
    products() {
      if (this.products.length <= 0) this.crawlerSelected = [];
    },
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    disableCrawlerInput() {
      if (this.loadingCrawlers) return true;
      if (this.products.length > 0) return false;
      else if (!this.selectedGroup && !this.selectedProduct) {
        return true;
      }

      return false;
    },

    disableExecuteButton() {
      if (this.products.length == 0 || this.executeLoading) return true;

      if (this.crawlerSelected.length <= 0 && !this.allCrawlerSelected)
        return true;

      return false;
    },
  },

  methods: {
    selectAllCrawlers() {
      this.crawlerSelectedItems = [];
      if (this.allCrawlerSelected) {
        this.crawlerItems.forEach((el) => {
          this.crawlerSelectedItems.push(el);
        });
      }
    },

    getProductList() {
      this.productListLoading = true;
      let request = {
        IncludeLeadInfo: false,
        MonitoringItemCreateEndDate: null,
        MonitoringItemCreateStartDate: null,
        OnlyForSearch: false,
        OnlyWithLead: false,
        idCompany: this.idCompany,
      };

      Service.getProductList(request)
        .then((res) => {
          this.productList = res.data.result;
          this.productListLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao gerar product list", error);
          this.productListLoading = false;
        });
    },

    getProductGroupList() {
      this.groupListLoading = true;
      Service.getProductGroupList(this.idCompany)
        .then((res) => {
          this.groupList = res.data.result;
          this.groupListLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao gerar getProductGroupList", error);
          this.groupListLoading = false;
        });
    },

    getCrawlers() {
      this.loadingCrawlers = true;
      Service.getCrawlers(this.idCompany)
        .then((res) => {
          this.crawlerItems = res?.data?.result?.crawlers ?? [];
          this.loadingCrawlers = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingCrawlers = false;
        });
    },

    selectProduct() {
      if (!this.selectedProduct) return;

      let productObj = {
        description: this.selectedProduct.description,
        idMonitoringItem: this.selectedProduct.idMonitoringItem,
      };

      this.products.push(productObj);
      this.selectedProduct = null;
      this.showAddProduct = false;
    },

    selectGroup() {
      this.groupLoading = true;
      this.products = [];

      if (!this.selectedGroup) return;

      Service.setProductGroup(
        this.idCompany,
        this.selectedGroup.idMonitoringGroup
      )
        .then((res) => {
          this.products = res?.data?.result?.items ?? [];
          this.groupLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao buscar grupo de monitoramento", error);
          this.products = [];
          this.groupLoading = false;
        });
    },

    deleteItem(index) {
      this.products.splice(index, 1);
    },

    async execute() {
      this.executeLoading = true;

      const idList = this.products.map((el) => ({
        idMonitoringItem: el.idMonitoringItem,
      }));

      let request = {
        idCompany_MonitoringCrawler: [],
        listMonitoringItem: idList,
        idCompany: this.idCompany,
      };

      if (!this.allCrawlerSelected) {
        const selectedArray = this.crawlerSelected;
        request.idCompany_MonitoringCrawler.push(
          ...selectedArray.map((el) => el.idCompanyMonitoringCrawler)
        );
      } else {
        const selectedArray = this.crawlerSelectedItems;
        request.idCompany_MonitoringCrawler.push(
          ...selectedArray.map((el) => el.idCompanyMonitoringCrawler)
        );
      }

      try {
        let response = await Service.executeCrawler(request);
        this.executeCrawlerById(response?.data?.result ?? null);
      } catch (error) {
        console.log(error);
        this.executeLoading = false;
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_INSERT_CRAWLERS"),
          type: "error",
        });
      }
    },

    async executeCrawlerById(idMonitoringBatch) {
      if (!idMonitoringBatch) {
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_TO_EXECUTE_CRAWLERS"),
          type: "error",
        });
        return;
      }

      try {
        await Service.executeCrawlerById(idMonitoringBatch);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_SUCCESS_INSERT_CRAWLERS"),
          type: "success",
        });
        this.cleanData();
      } catch (error) {
        console.error(error);
        this.executeLoading = false;
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_TO_EXECUTE_CRAWLERS"),
          type: "error",
        });
      }
    },

    cleanData() {
      this.executeLoading = false;
      this.products = [];
      this.selectedProduct = null;
      this.selectedGroup = null;
      this.allCrawlerSelected = false;
    },
  },

  created() {
    document.title = "PREDIFY | Executar crawler manual";
    this.getProductList();
    this.getProductGroupList();
    this.getCrawlers();
  },
};
</script>

<style lang="scss" scoped>
.product-row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.flex-grow-1 {
  flex-grow: 1;
}

.opacity-color {
  color: #00000099;
}

.border-destaq {
  border-bottom: 0.5px solid rgba(59, 58, 58, 0.185);
}

.list-item-box {
  background-color: $neutral-color-high-light;
  margin-bottom: 8px;

  .list-item {
    padding: 10px;
    border-bottom: 1px solid #ccc;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.v-tab {
  font-size: 14px !important;
  text-transform: capitalize;
  font-weight: 600;
}
</style>