<template>
  <v-dialog v-model="isOpen" max-width="500" :persistent="isLoading">
    <template v-slot:activator="{ on, attrs }">
      <Prd-depressed-button
        v-on="on"
        v-bind="attrs"
        :title="$t('TXT_EXCLUDE_FILTER')"
        :color="$prdStyles('color-error')"
        :leftIcon="'mdi-delete-outline'"
        :leftIconColor="$prdStyles('color-error')"
      />
    </template>
    <v-card class="pa-4" :disabled="isLoading">
      <h4 class="mb-4">{{ $t("TXT_EXCLUDE_FILTER").toUpperCase() }}</h4>
      <p class="mb-4">
        {{ $t("TXT_DELETE_CONFIRM") }}
        <span class="font-weight-bold">"{{ savedFilter.name }}"</span>?
      </p>
      <p class="danger-color">{{ $t("TXT_ACTION_CANNOT_UNDONE") }}</p>

      <v-row no-gutters justify="end" class="mt-6">
        <Prd-btn
          :outlined="true"
          :title="$t('TXT_CANCEL')"
          @click="isOpen = false"
          class="mr-4"
        />
        <Prd-btn
          :loading="isLoading"
          :title="$t('TXT_EXCLUDE')"
          :color="$prdStyles('color-error')"
          @click="deleteSavedFilter"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdDepressedButton from "@/components/common/prd-depressed-button.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import Service from "@/service/predimonitor/filtros-predmonitor-service";
export default {
  components: { PrdDepressedButton, PrdBtn },
  props: {
    savedFilter: { type: Object },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      service: new Service(),
    };
  },

  methods: {
    async deleteSavedFilter() {
      this.isLoading = true;
      const request = this.getRequest();
      try {
        await this.service.CUDFilters(request);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_EXCLUDE_FILTERS_SUCCESS"),
          type: "success",
        });
        this.$emit("cleanFilters");
        this.$emit(
          "reorganizeSavedFilters",
          request.idMonitoringPredimonitorFilter
        );
        this.isOpen = false;
      } catch (error) {
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_EXCLUDE_FILTERS_ERROR"),
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
    getRequest() {
      const request = structuredClone(this.savedFilter);
      request.delete = true;
      request.values?.forEach((el) => {
        el.delete = true;
      });

      request.monitoringItems?.forEach((el) => {
        el.isDeleted = true;
      });

      request.isCost = this.$route.query.type?.includes("cost") ?? false;
      
      return request;
    },
  },
};
</script>

<style lang="scss" scoped>
.danger-color {
  color: $brand-color-secondary-pure;
}
</style>