<template>
  <v-card class="pa-4 mt-6" :disabled="isLoadingRevenueGraphic">
    <v-col cols="12">
      <v-row no-gutters justify="space-between">
        <h4>{{ $t("TXT_TOTAL_REVENUE_2").toUpperCase() }}</h4>
        <div class="d-flex align-center justify-center">
          {{ $t("TXT_LAST") }}:
          <v-tabs
            v-model="tab"
            color="black"
            slider-color="#EC4C37"
            background-color="transparent"
            class="ml-2"
            height="25px"
          >
            <v-tab>
              <h4>7 {{ $t("TXT_DAYS") }}</h4>
            </v-tab>
            <v-tab>
              <h4>30 {{ $t("TXT_DAYS") }}</h4>
            </v-tab>
          </v-tabs>
        </div>
      </v-row>
      <v-row no-gutters justify="center" class="mt-4">
        <week-graphic
          v-show="tab == 0"
          style="width: 100%"
          :listRevenueGraphic="listRevenueGraphic"
          :isLoadingRevenueGraphic="isLoadingRevenueGraphic"
        />
        <day-graphic
          v-show="tab == 1"
          style="width: 100%"
          :listRevenueGraphic="listRevenueGraphic"
          :isLoadingRevenueGraphic="isLoadingRevenueGraphic"
        />
      </v-row>
    </v-col>
  </v-card>
</template>

<script>
import WeekGraphic from "./components/week-graphic.vue";
import DayGraphic from "./components/day-graphic.vue";
export default {
  components: {
    WeekGraphic,
    DayGraphic,
  },
  props: ["listRevenueGraphic", "isLoadingRevenueGraphic"],

  data() {
    return { tab: 0 };
  },
};
</script>

<style>
</style>