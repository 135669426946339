import { render, staticRenderFns } from "./upl-sso.vue?vue&type=template&id=37d31382&scoped=true"
import script from "./upl-sso.vue?vue&type=script&lang=js"
export * from "./upl-sso.vue?vue&type=script&lang=js"
import style0 from "./upl-sso.vue?vue&type=style&index=0&id=37d31382&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37d31382",
  null
  
)

export default component.exports