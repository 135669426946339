<template>
  <v-row no-gutters>
    <v-col
      v-for="(filter, index) in filters"
      :key="index"
      :cols="filter.colSize"
      :class="filter.classess"
    >
      <Prd-combo-box
        v-if="filter.type == 'combobox'"
        v-model="filter.selected"
        :items="filter.items"
        :title="filter.title"
        :disabled="externalLoading"
        :loading="externalLoading"
        :label="$t('TXT_SELECT')"
        @hasSelections="$emit('handleChange')"
        @onFocus="$emit('toggleUnlockSeeResults', true)"
        @onBlur="$emit('toggleUnlockSeeResults', false)"
        multiple
        deletable-chips
        small-chips
        clearable
      />

      <div v-else>
        <span>{{ filter.title }}</span>
        <v-row no-gutters align="center" justify="space-between">
          <v-col cols="5">
            <Prd-text-field
              v-model="filter.min.value"
              :label="filter.min.label"
              :disabled="externalLoading"
              :loading="externalLoading"
              type="number"
              min="0"
              clearable
              @focus="$emit('toggleUnlockSeeResults', true)"
              @blur="$emit('toggleUnlockSeeResults', false)"
            />
          </v-col>
          <v-col cols="auto">
            <v-icon size="15" color="primary">mdi-minus</v-icon>
          </v-col>
          <v-col cols="5">
            <Prd-text-field
              v-model="filter.max.value"
              :label="filter.max.label"
              :disabled="externalLoading"
              :loading="externalLoading"
              type="number"
              min="1"
              clearable
              @focus="$emit('toggleUnlockSeeResults', true)"
              @blur="$emit('toggleUnlockSeeResults', false)"
            />
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import PrdTextField from "@/components/common/prd-text-field.vue";
export default {
  components: { PrdComboBox, PrdTextField },
  props: {
    externalLoading: {
      type: Boolean,
      default: false,
    },
    savedFilter: {
      type: Object,
    },
  },
  data() {
    return {
      filters: [
        {
          type: "combobox",
          value: "years",
          title: this.$i18n.t("TXT_YEARS"),
          valueType: 8,
          selected: [],
          items: [],
          classess: "pr-4",
          colSize: 3,
        },
        {
          type: "combobox",
          value: "models",
          title: this.$i18n.t("TXT_MODEL"),
          valueType: 17,
          selected: [],
          items: [],
          classess: "pr-4",
          colSize: 3,
        },
        {
          type: "combobox",
          value: "states",
          title: this.$i18n.t("TXT_REGION"),
          valueType: 7,
          selected: [],
          items: [],
          classess: "pr-4",
          colSize: 3,
        },
        {
          type: "hourMeter",
          value: "hourMeter",
          title: this.$i18n.t("TXT_WORKED_HOURS"),
          classess: "",
          colSize: 3,
          min: {
            label: this.$i18n.t("TXT_MIN"),
            value: null,
            valueType: 13,
          },
          max: {
            label: this.$i18n.t("TXT_MAX"),
            value: null,
            valueType: 14,
          },
        },
        {
          type: "kilometers",
          value: "kilometers",
          title: this.$i18n.t("TXT_KILOMETERS"),
          classess: "mt-4",
          colSize: 3,
          min: {
            label: this.$i18n.t("TXT_MIN"),
            value: null,
            valueType: 15,
          },
          max: {
            label: this.$i18n.t("TXT_MAX"),
            value: null,
            valueType: 16,
          },
        },
      ],
    };
  },
  watch: {
    savedFilter: {
      handler(value) {
        this.populateFiltersBySavedFilter(value);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    cleanSelection() {
      this.filters.forEach((filter) => {
        if (filter.type == "combobox") filter.selected = [];
        else {
          filter.min.value = null;
          filter.max.value = null;
        }
      });
    },
    getSelections() {
      let selections = {};
      this.filters.forEach((filter) => {
        if (filter.type == "combobox")
          selections[filter.value] = filter.selected;
        else
          selections[filter.value] = {
            min: filter.min.value,
            max: filter.max.value,
          };
      });
      return selections;
    },
    populateFilters(items) {
      this.filters.forEach((filter) => {
        if (filter.type == "combobox") filter.items = items[filter.value] ?? [];
      });
    },

    removeNonExistentSelectedItem(availableItems) {
      const keys = Object.keys(availableItems);

      keys.forEach((key) => {
        const set = new Set(availableItems[key]);
        const filter = this.filters.find((filter) => filter.value == key);
        if (filter) {
          filter.selected = filter.selected.filter((item) => set.has(item));
        }
      });
    },

    populateFiltersBySavedFilter(data) {
      if (!data) return;
      this.cleanSelection();

      this.filters.forEach((currentFilter) => {
        if (currentFilter.value != "hourMeter") {
          const saved = data.values.filter(
            (save) => save.valueType == currentFilter.valueType
          );
          if (saved.length > 0) {
            currentFilter.selected = currentFilter.items.filter((item) =>
              saved.find((save) => save.value == item)
            );
          }
        } else {
          const savedMin = data.values.find((item) => item.valueType == 13);
          if (savedMin) currentFilter.min.value = savedMin.value;

          const savedMax = data.values.find((item) => item.valueType == 14);
          if (savedMax) currentFilter.max.value = savedMax.value;
        }
      });
    },
  },
};
</script>
