<template>
  <modal-action
    :useExternalActivatorButton="true"
    :externalIsOpen="isOpen"
    @handlerIsOpen="isOpen = $event"
    :icon="'mdi-check-circle-outline'"
    :iconColor="'green'"
    :modalTitle="$t('TXT_CONFIRM_EDIT_USER')"
    :question="$t('TXT_MODAL_CONFIRM_EDIT_USER_QUESTION')"
    :alertMessage="$t('TXT_MODAL_CONFIRM_EDIT_USER_ALERT_MESSAGE')"
    :confirmButtonText="$t('TXT_CONFIRM')"
    @handleConfirmAction="handlerUpdateUserData"
  >
    <template slot="externalActivatorButton">
      <v-progress-circular
        indeterminate
        :color="$prdStyles('color-primary')"
        v-show="isLoading"
      />
      <v-icon
        v-show="!isLoading"
        :disabled="user.generalIsLoading"
        :color="$prdStyles('color-success')"
        @click="isOpen = true"
        >mdi-checkbox-marked</v-icon
      >
    </template>
    <template slot="content">
      <user-infos :user="user" />
    </template>
  </modal-action>
</template>

<script>
import ModalAction from "@/components/common/modal-action.vue";
import userInfos from "../../register-new-user/components/user-infos.vue";
import GeneralSystemConfigsService from "@/service/general-system-configs";

export default {
  components: { userInfos, ModalAction },
  props: {
    user: { type: Object },
    idCompany: { type: Number },
    originalList: { type: Array },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      service: new GeneralSystemConfigsService(),
    };
  },
  watch: {
    isLoading: {
      handler(value) {
        this.$emit("isLoading", value);
      },
    },
  },
  methods: {
    async handlerUpdateUserData() {
      this.isLoading = true;
      try {
        await this.updateUserData();
        await this.handlerAdminRoles();
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
      this.$emit("resetOriginalList");
    },
    async updateUserData() {
      const request = this.createRequestForUpdateUserData();
      let message, type;
      try {
        await this.service.updateUserData(request);
        message = this.$i18n.t("TXT_UPDATE_USER_SUCCESS");
        type = "success";
      } catch (error) {
        console.log(error);
        message = this.$i18n.t("TXT_UPDATE_USER_ERROR");
        type = "error";
      }
      this.$store.commit("snackbarV2/set", { message, type });
    },
    createRequestForUpdateUserData() {
      return {
        email: this.user.email,
        name: this.user.nome,
        phone: this.user.telefone,
        id: this.user.idUsuario,
        idCompany: this.idCompany,
        idioma: this.user.lang,
      };
    },

    async handlerAdminRoles() {
      const { outsideItems, insideItems } = this.arrayComparator();
      if (outsideItems.length == 0 && insideItems.length == 0) return;
      let message, type;
      try {
        if (insideItems.length > 0) await this.setAdminRoles(insideItems);
        if (outsideItems.length > 0) await this.removeAdminRoles(outsideItems);
        message = this.$i18n.t("TXT_HANDLER_REGIONAL_ACCESS_SUCCESS");
        type = "success";
      } catch (error) {
        console.log(error);
        message = this.$i18n.t("TXT_HANDLER_REGIONAL_ACCESS_ERROR");
        type = "error";
      }
      this.$store.commit("snackbarV2/set", { message, type });
    },
    arrayComparator() {
      const originalUserAdminRoles = this.originalList.find(
        (originalUser) => originalUser.idUsuario == this.user.idUsuario
      ).adminRoles;
      const modifiedUserAdminRoles = this.user.adminRoles;

      const outsideItems = originalUserAdminRoles.filter(
        (item) => !modifiedUserAdminRoles.includes(item)
      );
      const insideItems = modifiedUserAdminRoles.filter(
        (item) => !originalUserAdminRoles.includes(item)
      );

      return { outsideItems, insideItems };
    },
    async setAdminRoles(list) {
      try {
        list.forEach(async (level) => {
          const request = this.createRequestForAdminRoles(level);
          await this.service.setAdminRole(request);
        });
      } catch (error) {
        console.log(error);
      }
    },
    createRequestForAdminRoles(level) {
      return {
        AccessLevel: level,
        UserEmail: this.user.email,
      };
    },
    async removeAdminRoles(list) {
      try {
        list.forEach(async (level) => {
          const request = this.createRequestForAdminRoles(level);
          await this.service.removeAdminRole(request);
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.borderIcon {
  border: 1px solid;
  border-radius: 4px;
}
</style>