<template>
  <v-dialog v-model="localItsOpen" persistent width="820">
    <v-card class="pa-4">
      <v-row no-gutters justify="space-between" align="center" class="mb-6">
        <h3>{{ `${action} ${$t("TXT_AGROUPMENT")}` }}</h3>
        <v-icon
          @click="resetModal"
          :disabled="itsLoading || itsLoadingAgroupment"
          >mdi-close</v-icon
        >
      </v-row>

      <prd-loading-msg v-if="itsLoadingAgroupment" />

      <div v-else>
        <Prd-text-field
          v-model="agroupment.nomeAgrupamentoMacro"
          :title="$t('TXT_NAME_AGROUPMENT_MACRO')"
          class="mb-3"
          :rules="[notHasDuplicatedNamesRule]"
          :disabled="itsLoading"
        />
        <Agroupment-micro-list
          :list="agroupment.agrupamentosMicro"
          :itsDisabled="itsLoading"
          :allAgroupmentsFiltered="allAgroupmentsDifferentThisId"
          class="mb-4"
          @addAgroupmentMicro="addAgroupmentMicro"
          @removeAgroupmentMicro="removeAgroupmentMicro"
          @updateAgroupmentMicro="updateAgroupmentMicro"
          @hasDuplicatedNamesMicroFlag="hasDuplicatedNamesMicro = $event"
          @hasDuplicatedGrupoClientesFlag="hasDuplicatedGrupoClientes = $event"
        />
        <v-row no-gutters align="center" justify="end" class="gap-4">
          <Prd-btn
            :title="$t('TXT_CANCEL')"
            :outlined="true"
            @click="resetModal"
            :disabled="itsLoading"
          />
          <Prd-btn
            :title="action"
            :disabled="lockSaveButton"
            :loading="itsLoading"
            @click="handlerAction"
          />
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
import AgroupmentMicroList from "./components/agroupment-micro-list.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import UPLService from "@/service/upl";
import PrdLoadingMsg from "@/components/common/prd-loading-msg.vue";
export default {
  components: { AgroupmentMicroList, PrdBtn, PrdTextField, PrdLoadingMsg },
  props: {
    itsOpen: { type: Boolean, default: false },
    allAgroupments: { type: Array, default: () => [] },
    selectedAgroupmentId: { type: String, default: null },
  },

  data() {
    return {
      localItsOpen: false,
      agroupment: {
        nomeAgrupamentoMacro: null,
        agrupamentosMicro: [
          {
            id: null,
            nome: null,
            grupoClientes: null,
            variacao: null,
          },
        ],
      },
      cleanAgroupmentMicro: {
        id: null,
        nome: null,
        grupoClientes: null,
        variacao: null,
      },
      originalAgroupment: null,
      service: new UPLService(),
      itsLoading: false,
      itsLoadingAgroupment: false,
      listOfIdsToRemoveMicroAgroupment: [],
      listOfUpdateMicroAgroupment: [],
      hasDuplicatedNamesMicro: false,
      hasDuplicatedGrupoClientes: false,
    };
  },

  computed: {
    action() {
      return this.selectedAgroupmentId != null
        ? this.$i18n.t("TXT_EDIT")
        : this.$i18n.t("TXT_ADD");
    },
    lockSaveButton() {
      return (
        this.agroupment.agrupamentosMicro.length == 0 ||
        !this.agroupment.nomeAgrupamentoMacro ||
        !this.agroupment.agrupamentosMicro[0]?.nome ||
        this.hasDuplicatedNames ||
        this.hasDuplicatedNamesMicro ||
        this.hasDuplicatedGrupoClientes ||
        !this.HasNewChanges
      );
    },

    allAgroupmentsDifferentThisId() {
      return (
        this.allAgroupments.filter(
          (ag) => ag.idAgroupment != this.selectedAgroupmentId
        ) ?? []
      );
    },

    hasDuplicatedNames() {
      const agroupmentMacroNames = new Set(
        this.allAgroupmentsDifferentThisId
          .map((ag) => ag.agMacro)
          .filter((name) => name)
      );
      const targetName = this.agroupment?.nomeAgrupamentoMacro ?? "";
      return agroupmentMacroNames.has(targetName) ?? false;
    },

    HasNewChanges() {
      return this.selectedAgroupmentId != null
        ? JSON.stringify(this.agroupment) !=
            JSON.stringify(this.originalAgroupment)
        : true;
    },
  },

  watch: {
    itsOpen: {
      handler(value) {
        this.localItsOpen = value;
        if (value && this.selectedAgroupmentId != null) this.getAgroupment();
        else
          this.agroupment = {
            nomeAgrupamentoMacro: null,
            agrupamentosMicro: [
              {
                id: null,
                nome: null,
                grupoClientes: null,
                variacao: null,
              },
            ],
          };
      },
      immediate: true,
    },
  },

  methods: {
    addAgroupmentMicro() {
      this.agroupment.agrupamentosMicro.push(
        structuredClone(this.cleanAgroupmentMicro)
      );
    },
    removeAgroupmentMicro(indexToRemove) {
      this.insertIntoRemoveList(indexToRemove);
      this.agroupment.agrupamentosMicro.splice(indexToRemove, 1);
    },
    insertIntoRemoveList(indexToRemove) {
      const foundedMicro = this.agroupment.agrupamentosMicro.find(
        (obj, index) => index == indexToRemove
      );
      if (foundedMicro?.id)
        this.listOfIdsToRemoveMicroAgroupment.push(foundedMicro.id);
    },
    resetModal() {
      this.agroupment.nomeAgrupamentoMacro = null;
      this.agroupment.agrupamentosMicro = [
        structuredClone(this.cleanAgroupmentMicro),
      ];
      this.listOfIdsToRemoveMicroAgroupment = [];
      this.listOfUpdateMicroAgroupment = [];
      this.$emit("closeModal");
    },
    updateAgroupmentMicro(index, agroupmentMicro) {
      if (agroupmentMicro.id != null)
        this.insertIntoUpdateList(
          agroupmentMicro,
          this.agroupment.agrupamentosMicro[index]
        );
      this.agroupment.agrupamentosMicro[index].id = agroupmentMicro.id;
      this.agroupment.agrupamentosMicro[index].nome = agroupmentMicro.nome;
      this.agroupment.agrupamentosMicro[index].grupoClientes =
        agroupmentMicro.grupoClientes;
      this.agroupment.agrupamentosMicro[index].variacao =
        agroupmentMicro.variacao;
    },

    insertIntoUpdateList(updatedMicro, original) {
      if (
        updatedMicro.nome != original.nome ||
        updatedMicro.variacao != original.variacao ||
        updatedMicro.grupoClientes != original.grupoClientes
      ) {
        const foundedIndex = this.listOfUpdateMicroAgroupment.findIndex(
          (micro) => micro.id == updatedMicro.id
        );
        if (foundedIndex == -1)
          this.listOfUpdateMicroAgroupment.push(updatedMicro);
        else {
          this.listOfUpdateMicroAgroupment[foundedIndex].nome =
            updatedMicro.nome;
          this.listOfUpdateMicroAgroupment[foundedIndex].grupoClientes =
            updatedMicro.grupoClientes;
          this.listOfUpdateMicroAgroupment[foundedIndex].variacao =
            updatedMicro.variacao;
        }
      }
    },
    notHasDuplicatedNamesRule() {
      return !this.hasDuplicatedNames || this.$i18n.t("TXT_UTILIZED_NAME");
    },

    handlerAction() {
      if (this.action == this.$i18n.t("TXT_ADD")) this.addAgroupment();
      else this.updateAgroupment();
    },

    async addAgroupment() {
      this.itsLoading = true;
      const request = this.prepareRequestforNewAgroupment();
      try {
        await this.service.addAgroupment(request);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_SUCCESS_ADD_GROUPING"),
          type: "success",
        });
        this.resetModal();
        this.$emit("refreshTargetMarketSegmentationTable");
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_ADD_GROUPING"),
          type: "error",
        });
      } finally {
        this.itsLoading = false;
      }
    },

    prepareRequestforNewAgroupment() {
      let request = JSON.parse(JSON.stringify(this.agroupment));

      request.agrupamentosMicro = request.agrupamentosMicro.map((item) => {
        return {
          nome: item.nome,
          grupoClientes: item.grupoClientes,
          variacao: Number(item.variacao) ?? 0,
        };
      });

      return request;
    },

    async getAgroupment() {
      this.itsLoadingAgroupment = true;
      try {
        const response = await this.service.getAgroupmentById(
          this.selectedAgroupmentId
        );
        this.agroupment = JSON.parse(JSON.stringify(response.data));
        this.originalAgroupment = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.log(error);
      } finally {
        this.itsLoadingAgroupment = false;
      }
    },

    prepareRequestForUpdateAgroupment() {
      let request = {};
      if (
        this.agroupment?.nomeAgrupamentoMacro?.toString() !=
        this.originalAgroupment?.nomeAgrupamentoMacro?.toString()
      )
        request.nomeAgrupamentoMacro =
          this.agroupment.nomeAgrupamentoMacro?.toString();

      const listOfNewMicroAgroupment = this.agroupment.agrupamentosMicro.filter(
        (micro) => micro.id == null
      );
      if (listOfNewMicroAgroupment.length > 0)
        request.listOfNewMicroAgroupment = listOfNewMicroAgroupment.map(
          (newMicro) => {
            return {
              nome: newMicro.nome,
              grupoClientes: newMicro.grupoClientes,
              variacao: newMicro.variacao ?? 0,
            };
          }
        );

      if (this.listOfIdsToRemoveMicroAgroupment.length > 0)
        request.listOfIdsToRemoveMicroAgroupment =
          this.listOfIdsToRemoveMicroAgroupment;

      if (this.listOfUpdateMicroAgroupment.length > 0)
        request.listOfUpdateMicroAgroupment = this.listOfUpdateMicroAgroupment;

      return request;
    },

    async updateAgroupment() {
      this.itsLoading = true;
      const request = this.prepareRequestForUpdateAgroupment();
      try {
        await this.service.updateAgroupment(this.selectedAgroupmentId, request);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_SUCCESS_UPDATED_GROUPING"),
          type: "success",
        });
        this.resetModal();
        this.$emit("refreshTargetMarketSegmentationTable");
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_UPDATED_GROUPING"),
          type: "error",
        });
      } finally {
        this.itsLoading = false;
      }
    },
  },
};
</script>