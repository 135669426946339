<template>
  <div>
    <Prd-btn
      :disabled="disabledConfirmButton"
      :title="$t('BTN_OPEN_TICKET')"
      @click="isModalOpen = true"
    />
    <v-dialog v-model="isModalOpen" :max-width="600" persistent>
      <v-card class="pa-4">
        <div>
          <div class="my-flex">
            <v-icon :color="$prdStyles('color-success')" size="50"
              >mdi-checkbox-marked-circle-outline</v-icon
            >
            <h4 class="my-4">{{ $t("TITLE_CONFIRM_OPEN_TICKET") }}</h4>
            <span>
              {{ $t("MSG_CONFIRM_OPEN_TICKET") }}
            </span>
          </div>

          <div class="ticket-info pa-2">
            <span class="font-weight-bold">{{ localTicketTitle }}</span>
            <span
              >{{ $t("TXT_SUBJECT") }}:
              <span class="font-weight-bold">{{ subject }}</span></span
            >
            <span
              >{{ $t("TXT_REASON") }}:
              <span class="font-weight-bold">{{ reason }}</span></span
            >
            <span
              >{{ $t("TXT_MESSAGE") }}:
              <span class="font-weight-bold">{{ localMessage }}</span></span
            >
          </div>

          <div class="text-center">
            <span>{{ $t("INFO_OPEN_TICKET_CONFIRMATION") }}</span>
          </div>

          <Prd-card-actions
            :title="$t('BTN_CONFIRM')"
            :outlinedTitle="$t('BTN_CANCEL')"
            :loading="isLoading"
            :isDisable="isLoading"
            @clickOutlined="isModalOpen = false"
            @click="createTicket"
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import PrdCardActions from "@/components/common/prd-card-actions.vue";
import service from "@/service/support/index.js";
const Service = new service();
export default {
  props: ["ticketTitle", "selectedSubject", "selectedReason", "message"],
  components: {
    PrdBtn,
    PrdCardActions,
  },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    subject() {
      return this.selectedSubject?.translatedDescription ?? "-";
    },
    reason() {
      return this.selectedReason?.translatedDescription ?? "-";
    },
    disabledConfirmButton() {
      let validations = false;
      if (this.selectedSubject?.value != 2) {
        validations = this.reason != null && this.reason != "-" ? false : true;
      }
      return !this.ticketTitle || !this.message || validations;
    },
    localTicketTitle() {
      return this.ticketTitle ?? "-";
    },
    localMessage() {
      return this.message ?? "-";
    },
  },
  methods: {
    async createTicket() {
      this.isLoading = true;

      let request = {
        idMotive: this.selectedReason?.idSupport_TicketSubject ?? null,
        idSubject: this.selectedSubject?.idSupport_TicketSubject ?? null,
        message: this.message ?? null,
        title: this.ticketTitle ?? null,
        idCompany: this.idCompany,
      };

      try {
        await Service.createTicket(request);
        this.$emit("showTicketList");

        this.$store.commit("snackbarV2/set", {
          message: this.$t("MSG_SUCCESS_OPEN_TICKET"),
          type: "success",
        });

        this.isModalOpen = false;
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$t("MSG_ERROR_OPEN_TICKET"),
          type: "error",
        });
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 16px;
}

.ticket-info {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.align-center {
  text-align: center;
}
</style>