import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";


export default class CompanyService extends ServiceBase {

  async getResources(idCompany) {
    return await axiosInstance.get(API_URL + "api/Empresa/GetResources", { params: { idCompany } });
  }

  async getCurrency(idCompany) {
    return await axiosInstance.get(API_URL + "api/Empresa/GetCurrencySymbol", { params: { idCompany } });
  }

  async setCurrency(request) {
    return await axiosInstance.post(API_URL + "api/Empresa/UpdateCurrencySymbol", request);

  }
}
