<template>
  <v-snackbar v-model="isOpen" :color="setColor(snackbarData.type)">
    {{ snackbarData.message }}
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    setColor(color) {
      if (!color) return;
      if (color == "info") return "#3c5ca7";
      return color;
    },
  },
  computed: {
    snackbarData() {
      return this.$store.getters["snackbarV2/snackbarData"];
    },
    timeout() {
      return this.snackbarData?.timeout ?? 2500;
    },
  },
  watch: {
    isOpen: {
      handler(value) {
        if (!value) {
          this.$store.commit("snackbarV2/reset");
        }
      },
      immediate: true,
      deep: true,
    },
    snackbarData: {
      handler(value) {
        this.isOpen = false;
        if (value.message && value.type) {
          this.isOpen = true;
          clearTimeout(this.timeoutId);
          this.timeoutId = setTimeout(() => {
            this.isOpen = false;
          }, this.timeout);
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
