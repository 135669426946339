<template>
  <Section-card
    class="my-4"
    :title="$t('TXT_MANAGEMENT_USERS')"
    :useHeaderSection="false"
    :disabled="false"
  >
    <template slot="content">
      <Register-new-user
        class="mt-6 mb-4"
        :selectedCompany="selectedCompany"
        @getUsers="getUsersFlag = !getUsersFlag"
      />
      <User-list-main
        :selectedCompany="selectedCompany"
        :getUsersFlag="getUsersFlag"
      />
    </template>
  </Section-card>
</template>

<script>
import SectionCard from "../../../../common-use-components/section-card.vue";
import RegisterNewUser from "./components/register-new-user/register-new-user-main.vue";
import UserListMain from "./components/user-list/user-list-main.vue";
export default {
  components: { SectionCard, RegisterNewUser, UserListMain },
  props: {
    selectedCompany: {
      type: Object,
    },
  },
  data() {
    return {
      getUsersFlag: false,
    };
  },
};
</script>