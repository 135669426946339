<template>
  <v-card
    flat
    :disabled="isLoadingInitial || externalDisabled"
    class="list-card"
  >
    <h2>{{ title }}</h2>
    <p class="ma-0 mb-6">{{ description }}</p>

    <v-row no-gutters class="mb-4">
      <slot name="search"></slot>
    </v-row>

    <v-row no-gutters class="mb-4">
      <slot name="filters"></slot>
    </v-row>
    
    <div
      :class="['scrollable-list', { loading: isLoadingInitial }]"
      @scroll="handleScroll"
    >
      <slot name="list"></slot>
      <prd-loading-circular v-if="isLoadingInitial || isLoadingMoreItems" />
    </div>
  </v-card>
</template>

<script>
import prdLoadingCircular from "@/components/common/prd-loading-circular.vue";
export default {
  components: { prdLoadingCircular },
  props: {
    title: { type: String, default: null, require: true },
    description: {
      type: String,
      default: null,
      require: true,
    },
    isLoadingInitial: { type: Boolean, default: false, require: true },
    isLoadingMoreItems: { type: Boolean, default: false, require: true },
    ofsetYInfiniteScroll: { type: Number, default: 50, require: false },
    externalDisabled: { type: Boolean, default: false },
  },
  methods: {
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target;

      if (scrollTop + clientHeight >= scrollHeight - this.ofsetYInfiniteScroll)
        this.$emit("loadMoreItems");
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollable-list {
  width: 100%;
  max-height: 280px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 8px;
}
.loading {
  overflow: hidden;
}
</style>
