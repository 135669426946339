<template>
  <!--desktop sidebar-->
  <v-navigation-drawer
    v-if="$vuetify.breakpoint.smAndUp"
    style="min-width: 88px"
    v-model="drawer"
    :mini-variant.sync="mini"
    expand-on-hover
    permanent
    app
    id="sidebar"
    color="var(--dark-moderate-blue)"
  >
    <v-list-item class="pa-0 mt-0" style="height: 88px">
      <v-list-item class="pa-1" style="max-width: 88px">
        <v-img
          class="my-0 mx-auto"
          style="max-width: 46px"
          src="../../assets/seta-logo.png"
        ></v-img>
      </v-list-item>
      <v-list-item class="pa-0">
        <v-img
          style="max-width: 126px"
          src="../../assets/name-logo.png"
        ></v-img>
      </v-list-item>
    </v-list-item>

    <v-list dense>
      <div v-for="(item, index) in items" :key="index">
        <v-list-item
          class="ma-1"
          link
          @click="redirect(item.linkTo)"
          :style="{ color: 'var(--white)' }"
          v-if="
            companyRequired(item.companyRequired) &&
            adminRequired(item.adminOnly) &&
            adminPredifyOnly(item.predifyAdminOnly) &&
            showToCompany(item.showToCompany) &&
            hideToCompany(item.hideToCompany) &&
            showToResource(item.showToResource) &&
            hideToResource(item.hideToResource) &&
            hideToRoles(item.hideToRoles)
          "
        >
          <v-list-item-icon center class="my-0 mx-auto pl-2 pr-3 py-3">
            <font-awesome-icon
              :icon="item.icon"
              :class="{ isAdmin: item.predifyAdminOnly }"
            />
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :class="{ isAdmin: item.predifyAdminOnly }">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "appBarComponent",
  props: {
    drawerMobile: Boolean,
  },
  data: () => ({
    /**
     * @param {boolean} companyRequired -
     * @param {array} company -
     * @param {array} hideToCompany -
     * @param {boolean} adminOnly -
     * @param {boolean} predifyAdminOnly -
     */
    items: [
      {
        title: "Home",
        icon: "fa-solid fa-house-user",
        linkTo: "/main/home",
        companyRequired: true,
        hideToCompany: [3116],
        hideToResource: ["predimonitor.only"],
      },
      {
        title: "Selecionar empresa",
        icon: "fa-solid fa-building",
        linkTo: "/main/company-select",
      },
      {
        title: "PrediMonitor",
        icon: "fa-solid fa-chart-line",
        linkTo: "/main/predimonitor?type=competitor",
        companyRequired: true,
        hideToCompany: [2652],
        showToResource: ["predimonitor"],
        hideToResource: ["predimonitor.v2"],
      },
      {
        title: "PrediMonitor Custo",
        icon: "fa-solid fa-chart-line",
        linkTo: "/main/predimonitor?type=cost",
        companyRequired: true,
        showToResource: ["predimonitor.custo"],
        hideToResource: ["predimonitor.v2"],
      },
      {
        title: "PrediMonitor Fujioka",
        icon: "fa-solid fa-chart-line",
        linkTo: "/main/predimonitor-V2",
        companyRequired: true,
        showToResource: ["predimonitor.v2"],
      },
      {
        title: "Pricer",
        icon: "fa-solid fa-money-bill-trend-up",
        linkTo: "/main/pricer",
        companyRequired: true,
        empresa: [],
        hideToResource: ["predimonitor.v2", "predimonitor.only"],
        hideToRoles: ["2002"],
      },
      {
        title: "Precificação IA",
        icon: "fa-solid fa-money-bill-trend-up",
        linkTo: "/main/ai-pricing-fujioka",
        companyRequired: true,
        showToCompany: [2652, 2806, 1106],
      },
      {
        title: "Pricetickets",
        icon: "fa-solid fa-futbol",
        linkTo: "/main/predifut/select-game",
        companyRequired: true,
        showToResource: ["enterprise.predfut"],
        hideToResource: ["predimonitor.only"],
        showToCompany: [2454],
      },
      {
        title: "Preço de compra",
        icon: "fa-solid fa-money-bill",
        linkTo: "/main/acelen",
        showToCompany: [1411]
      },
      {
        title: "Regras",
        icon: "fa-cogs",
        linkTo: "/main/pricing-rules",
        companyRequired: true,
      },
      {
        title: "Análise de Churn",
        icon: "fa-solid fa-table-columns",
        linkTo: "/main/churn-analysis",
        companyRequired: true,
        showToResource: ["enterprise.churn"],
      },
      {
        title: "UPL - One Page",
        icon: "fa-solid fa-hotel",
        linkTo: "/main/upl",
        companyRequired: true,
        showToCompany: [2858],
      },
      {
        title: "UPL - Deliberation",
        icon: "fa-solid fa-business-time",
        linkTo: "/main/deliberation-main",
        companyRequired: true,
        showToCompany: [2858],
      },
      {
        title: "Suzano",
        icon: "fa-solid fa-landmark",
        linkTo:
          "https://predifysuzano.azurewebsites.net/main/premise-registration",
        showToCompany: [2857],
      },
      {
        title: "Configurações da empresa",
        icon: "fa-solid fa-building-user",
        linkTo: "/main/company-config",
        adminOnly: true,
        hideToRoles: [2001, 2002, 2003],
      },
      /*{
        title: "Usuários",
        icon: "fa-solid fa-users",
        linkTo: "/main/administracao/administracao",
        companyRequired: false,
        predifyAdminOnly: true,
      },*/
      {
        title: "Configuração de crawlers",
        icon: "fa-solid fa-robot",
        linkTo: "/main/crawler-config",
        predifyAdminOnly: true,
      },
      {
        title: "Mix de Produtos - Apriori",
        icon: "fa-solid fa-table-columns",
        linkTo: "/main/mix-products-apriori",
        companyRequired: true,
        predifyAdminOnly: true,
      },
      {
        title: "Suporte",
        icon: "fa-solid fa-ticket",
        linkTo: "/main/support",
        companyRequired: true,
      },
      {
        title: "Configuração",
        icon: "fa-solid fa-gears",
        linkTo: "/main/empresa/admin-configuracoes",
        predifyAdminOnly: true,
      },

      {
        title: "Logout",
        icon: "fa-solid fa-right-from-bracket",
        linkTo: "/login/sign-out",
        companyRequired: false,
      },
    ],

    mini: true,
    drawer: true,
    mobile: false,
  }),

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    isPredifyAdmin() {
      return this.$store.getters?.isPredifyAdmin ?? false;
    },
    adminRoles() {
      return this.$store?.getters?.adminRoles ?? [];
    },
    resources() {
      return this.$store?.getters?.resources ?? [];
    },
  },

  watch: {
    drawerMobile(newvalue) {
      this.mobile = newvalue;
    },
  },

  methods: {
    redirect(link) {
      if (link.startsWith("http://") || link.startsWith("https://"))
        window.location.href = link;
      else this.$router.push({ path: link });
    },
    hideMenu() {
      this.$emit("showOrHideMenu");
    },
    hasResource(value) {
      return this.$store.getters.hasResource(value);
    },
    companyRequired(isRequired) {
      if (isRequired && !this.idCompany) return false;
      else return true;
    },
    adminRequired(isRequired) {
      if (!isRequired) return true;

      if (isRequired && !this.idCompany) return false;

      if (isRequired && (this.isPredifyAdmin || this.adminRoles.length > 0))
        return true;
      else return false;
    },
    hideToRoles(rolesData) {
      if (!rolesData || rolesData.length <= 0) return true;

      let foundedResource = rolesData.some((item) =>
        this.adminRoles.includes(item)
      );

      if (foundedResource) return false;
      else return true;
    },
    adminPredifyOnly(isRequired) {
      if (!isRequired) return true;

      if (isRequired && this.isPredifyAdmin) return true;
      else return false;
    },
    showToCompany(companys) {
      if (!companys || companys.length <= 0) return true;

      if (companys.includes(this.idCompany)) return true;
      else return false;
    },
    hideToCompany(companys) {
      if (!companys || companys.length <= 0) return true;

      if (companys.includes(this.idCompany)) return false;
      else return true;
    },
    showToResource(resources) {
      if (!resources || resources.length <= 0) return true;

      let foundedResource = resources.some((item) =>
        this.resources.includes(item)
      );

      if (foundedResource) return true;
      else return false;
    },
    hideToResource(resources) {
      if (!resources || resources.length <= 0) return true;

      let foundedResource = resources.some((item) =>
        this.resources.includes(item)
      );

      if (foundedResource) return false;
      else return true;
    },
  },
};
</script>

<style scoped lang="scss">
#sidebar ::-webkit-scrollbar {
  width: 0 !important;
  margin-right: 80px;
}

.isAdmin {
  color: $feedback-color-helper-pure;
}
</style>
