<template>
  <v-data-table
    :headers="headers"
    :items="localItems"
    :hide-default-footer="localItems == [] || localItems.length == 0"
    :footer-props="footerProps"
    :no-data-text="$t('TXT_NO_DATA_AVAILABLE')"
  >
    <template v-slot:[`item.percentLMUSD`]="{ item }">
      {{ item.percentLMUSD + " %" }}
    </template>
    <template v-slot:[`item.percentLMBRL`]="{ item }">
      {{ item.percentLMBRL + " %" }}
    </template>
    <template v-slot:[`item.unitPriceBRLOnePage`]="{ item }">
      {{ setCurrencyUPL(item.unitPriceBRLOnePage, false) }}
    </template>
    <template v-slot:[`item.unitPriceUSDOnePage`]="{ item }">
      {{ setCurrencyUPL(item.unitPriceUSDOnePage, true) }}
    </template>
    <template v-slot:[`item.iaPriceBRL`]="{ item }">
      {{ setCurrencyUPL(item.iaPriceBRL, false) }}
    </template>
    <template v-slot:[`item.iaPriceUSD`]="{ item }">
      {{ setCurrencyUPL(item.iaPriceUSD, true) }}
    </template>

    <template v-slot:[`item.unitPriceListUSD`]="{ item }">
      <v-tooltip bottom content-class="custom-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <div v-if="!item.isEditUSD">
              {{ setCurrencyUPL(item.unitPriceListUSD, true) }}
              <v-icon
                v-if="isAdminUpl"
                :color="$prdStyles('color-primary')"
                @click="item.isEditUSD = true"
                right
              >
                mdi-pencil-box</v-icon
              >
            </div>

            <div v-else class="my-flex">
              <v-text-field
                :loading="item.isLoading"
                :disabled="item.isLoading"
                v-model="item.unitPriceListUSD"
                outlined
                dense
                hide-details
                style="max-width: 120px"
              >
              </v-text-field>
              <v-icon
                :disabled="item.isLoading"
                @click="editUnitPriceDeliberation('USD', item)"
                :color="$prdStyles('color-success')"
              >
                mdi-checkbox-outline
              </v-icon>
              <v-icon
                :disabled="item.isLoading"
                @click="closeEditModeDeliberation('USD', item)"
                :color="$prdStyles('color-error')"
              >
                mdi-close-box
              </v-icon>
            </div>
          </div>
        </template>
        {{ $t("TXT_RULECONDITION_LAST_PRICE") }}:
        {{ setCurrencyUPL(item.lastPriceUSD, true) }}
      </v-tooltip>
    </template>

    <template v-slot:[`item.unitPriceListBRL`]="{ item }">
      <v-tooltip bottom content-class="custom-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <div v-if="!item.isEditBRL">
              {{ setCurrencyUPL(item.unitPriceListBRL, false) }}
              <v-icon
                v-if="isAdminUpl"
                :color="$prdStyles('color-primary')"
                @click="item.isEditBRL = true"
                right
              >
                mdi-pencil-box</v-icon
              >
            </div>
            <div v-else class="my-flex">
              <v-text-field
                :loading="item.isLoading"
                :disabled="item.isLoading"
                v-model="item.unitPriceListBRL"
                outlined
                dense
                hide-details
                style="max-width: 120px"
              >
              </v-text-field>
              <v-icon
                :disabled="item.isLoading"
                @click="editUnitPriceDeliberation('BRL', item)"
                :color="$prdStyles('color-success')"
              >
                mdi-checkbox-outline
              </v-icon>
              <v-icon
                :disabled="item.isLoading"
                @click="closeEditModeDeliberation('BRL', item)"
                :color="$prdStyles('color-error')"
              >
                mdi-close-box
              </v-icon>
            </div>
          </div>
        </template>
        {{ $t("TXT_RULECONDITION_LAST_PRICE") }}:
        {{ setCurrencyUPL(item.lastPriceBRL, true) }}
      </v-tooltip>
    </template>

    <template v-slot:[`item.coments`]="{ item }">
      <div v-if="!item.isEditComments">
        {{ item.coments }}
        <v-icon
          v-if="isAdminUpl"
          :color="$prdStyles('color-primary')"
          @click="item.isEditComments = true"
          right
        >
          mdi-pencil-box</v-icon
        >
      </div>

      <div v-else class="my-flex">
        <v-text-field
          :loading="item.isLoading"
          :disabled="item.isLoading"
          v-model="item.coments"
          outlined
          dense
          hide-details
        >
        </v-text-field>
        <v-icon
          :disabled="item.isLoading"
          @click="editUnitPriceDeliberation('Comments', item)"
          :color="$prdStyles('color-success')"
        >
          mdi-checkbox-outline
        </v-icon>
        <v-icon
          :disabled="item.isLoading"
          @click="closeEditModeDeliberation('Comments', item)"
          :color="$prdStyles('color-error')"
        >
          mdi-close-box
        </v-icon>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import UPLService from "@/service/upl";
export default {
  props: {
    localItems: { type: Array, default: () => [], required: true },
    items: { type: Array, default: () => [], required: true },
    headers: { type: Array, default: () => [], required: true },
    setCurrencyUPL: { type: Function, default: () => "-", required: true },
    isAdminUpl: { type: Boolean, default: false, required: true },
  },
  data() {
    return {
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
      service: new UPLService(),
    };
  },
  methods: {
    async editUnitPriceDeliberation(type, item) {
      const originalIndex = this.getIndex(item.id);
      item.isLoading = true;
      const request = structuredClone(item);
      request.percentLMUSD = String(request.percentLMUSD);
      request.percentLMBRL = String(request.percentLMBRL);

      await this.service
        .updateDeliberationDeliberation(request)
        .then((res) => {
          if (res.status == 200) {
            if (type != "Comments") {
              this.$emit("hasNewValues", true);
            }
            this.$emit("updateOriginalData", originalIndex, res.data);
            const response = res.data;
            item.id = response.id;
            item.unitPriceListUSD = response.unitPriceListUSD;
            item.unitPriceUSDOnePage = response.unitPriceUSDOnePage;
            item.iaPriceUSD = response.iaPriceUSD;
            item.percentLMUSD = response.percentLMUSD;
            item.iaPriceBRL = response.iaPriceBRL;
            item.unitPriceListBRL = response.unitPriceListBRL;
            item.unitPriceBRLOnePage = response.unitPriceBRLOnePage;
            item.percentLMBRL = response.percentLMBRL;
            item.coments = response.coments;
            item.brand = response.brand;
            item.segment = response.segment;
            item.isEditUSD = response.isEditUSD;
            item.isEditBRL = response.isEditBRL;
            item.isEditComments = response.isEditComments;
            item.isLoading = response.isLoading;
            item.lastPriceUSD = response.lastPriceUSD;
            item.lastPriceBRL = response.lastPriceBRL;
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_UPDATED_DATA_SUCCESS"),
              type: "success",
            });
          } else {
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_PROBLEM_UPDATE_DATAS"),
              type: "info",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          const fieldName = this.getFieldName(type);
          item[fieldName] = this.items[originalIndex][fieldName];
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_ERROR_UPDATE_DATAS"),
            type: "error",
          });
        })
        .finally(() => {
          item.isLoading = false;
        });
    },
    getIndex(id) {
      return this.items.findIndex((item) => item.id === id);
    },
    getFieldName(fieldType) {
      if (fieldType === "Comments") return "coments";
      return `unitPriceList${fieldType}`;
    },

    closeEditModeDeliberation(type, item) {
      const edit = `isEdit${type}`;
      item[edit] = false;
      const fieldName = this.getFieldName(type);
      const originalIndex = this.getIndex(item.id);
      item[fieldName] = this.items[originalIndex][fieldName];
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-tooltip {
  background-color: black;
  color: white;
  max-width: 200px;
  text-align: center;
}
.my-flex {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}
</style>