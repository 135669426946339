<template>
  <v-card class="pa-4">
    <h4 class="mb-4">{{ $t("TXT_RESOURCE_GROUP").toUpperCase() }}</h4>
    <span :class="{ disabled: disable }">{{ $t("TXT_SELECT_GOUPS") }}</span>
    <v-row no-gutters class="mt-4">
      <v-col cols="12" md="6">
        <Prd-search
          v-model="search"
          :delay="0"
          :disabled="disable || isLoading"
        />
      </v-col>
    </v-row>

    <Prd-loading-circular v-if="isLoading" class="mt-4" />
    <div v-else class="main-list">
      <div
        v-for="group in filteredGroups"
        :key="group.idResourceGroup"
        class="group-item"
      >
        <span>{{ group.name }}</span>
        <v-icon
          :disabled="disable"
          :color="$prdStyles('color-primary')"
          size="30px"
          @click="editGroup(group)"
          >mdi-pencil-box</v-icon
        >
      </div>
    </div>
  </v-card>
</template>

<script>
import PrdSearch from "@/components/common/prd-search.vue";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import service from "@/service/company-config/permission-management.js";
const Service = new service();
export default {
  props: ["disable", "tab"],
  components: { PrdSearch, PrdLoadingCircular },
  data() {
    return {
      groups: [],
      filteredGroups: [],
      isLoading: false,
      search: "",
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  watch: {
    tab: {
      handler(value) {
        if (value == 1) this.getGroups();
      },
      immediate: true,
    },

    search(newValue) {
      let filteredList = [];
      filteredList = this.groups.filter((el) => {
        return el.name.toLowerCase().includes(newValue.toLowerCase());
      });
      this.filteredGroups = filteredList;
    },
  },

  methods: {
    getGroups() {
      this.isLoading = true;

      Service.getGroups(this.idCompany)
        .then((res) => {
          this.groups = res?.data?.answer ?? [];
          this.filteredGroups = res?.data?.answer ?? [];
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    editGroup(group) {
      this.search = "";
      this.$emit("openEditMode", group);
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  opacity: 0.4;
}
.main-list {
  margin-top: 16px;
  max-height: 250px;
  overflow-y: scroll;

  .group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $neutral-color-high-light;
    border-top-left-radius: 5px;
    padding: 8px 6px;
    margin: 8px 0px;
  }
}
</style>