var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('titleBreadCrumbs',{attrs:{"title":_vm.$t('TXT_PAYMENT_HISTORY').toUpperCase(),"hasTooltip":false}}),_c('v-card',{staticClass:"pa-4"},[_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('Prd-combo-box',{attrs:{"title":_vm.$t('TXT_STATUS'),"items":_vm.enumStatus,"placeholder":_vm.$t('TXT_ALL')},on:{"input":_vm.filterItems}})],1)],1),(_vm.isLoading)?_c('Prd-loading-circular'):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"hide-default-footer":_vm.filteredItems.length <= 5,"footer-props":_vm.footerProps,"no-data-text":_vm.$t('TXT_NO_DATA_AVAILABLE')},scopedSlots:_vm._u([{key:"item.dataSolicitacaoPagamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(new Date(item.dataSolicitacaoPagamento), "dd/MM/yyyy"))+" ")]}},{key:"item.dataPagamentoEfetivacao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(new Date(item.dataPagamentoEfetivacao), "dd/MM/yyyy"))+" ")]}},{key:"item.dataPagamentoExpiracao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(new Date(item.dataPagamentoExpiracao), "dd/MM/yyyy"))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setStatus(item.status))+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }