<template>
  <div>
    <v-skeleton-loader
      v-for="rule in ruleListLength"
      :key="rule"
      type="image"
      :height="60"
      tile
      class="mt-4"
    ></v-skeleton-loader>
  </div>
</template>
<script>
export default {
  props: ["ruleListLength"],
};
</script>
