<template>
  <v-card class="pa-4" :disabled="isLoading">
    <h4>{{ $t("TXT_REGISTRED_USERS").toUpperCase() }}</h4>
    <Prd-search
      v-model="search"
      :delay="0"
      :disabled="disableInputs"
      :title="$t('TXT_SEARCH_BY_NAME_OR_EMAIL')"
      class="search mt-6"
    />

    <User-list
      :search="search"
      @editMode="disableInputs = $event"
      ref="user-list"
    />
  </v-card>
</template>

<script>
import PrdSearch from "@/components/common/prd-search.vue";
import UserList from "./user-list/user-list.vue";
export default {
  components: { PrdSearch, UserList },
  props: ["isLoading", "updateUserList"],
  data() {
    return {
      search: "",
      disableInputs: false
    };
  },

  watch: {
    updateUserList: {
      handler(value) {
        if (value) this.$refs["user-list"].getUsers();
        this.$emit("updateUserList", false);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  width: 30%;
}
</style>