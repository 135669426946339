<template>
  <div v-if="showClusters" class="d-flex align-center">
    <div>
      <p class="ma-0 pa-0">
        Preços com desconto
        <Prd-tooltip-informations
          :text="$i18n.t('TXT_BOLD_CLUSTER_TOOLTIP')"
          class="ma-0 pa-0"
        />
      </p>
    </div>

    <Skeleton-loader v-if="isLoading" />

    <div v-else class="d-flex align-center ml-2">
      <div v-for="(cluster, index) in clusters" :key="index">
        <Cluster-data
          :title="cluster.title"
          :value="cluster.value"
          :margin="cluster.margin"
          :discount="cluster.discount"
          :optimalPrice="cluster.optimalPrice"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ClusterData from "./components/cluster-data.vue";
import service from "@/service/pricer/product-list.js";
import SkeletonLoader from "./components/skeleton-loader.vue";
import PrdTooltipInformations from "@/components/common/prd-tooltip.vue";
const Service = new service();
export default {
  components: { ClusterData, SkeletonLoader, PrdTooltipInformations },
  props: {
    idEnterprisePricesProjection: {
      type: Number,
    },
  },

  data: () => ({
    isLoading: false,
    clusters: [
      {
        title: "Ouro",
        margin: "discountPriceMargin1",
        discount: "percentualDiscount1",
        optimalPrice: "optimalPrice",
        value: "discountPrice1",
      },
      {
        title: "Prata",
        margin: "discountPriceMargin2",
        discount: "percentualDiscount2",
        optimalPrice: "optimalPrice",
        value: "discountPrice2",
      },
      {
        title: "Bronze",
        margin: "discountPriceMargin3",
        discount: "percentualDiscount3",
        optimalPrice: "optimalPrice",
        value: "discountPrice3",
      },
    ],
  }),

  computed: {
    showClusters() {
      if (this.isLoading) return true;

      return this.clusters.every((cluster) => {
        return (
          cluster.value !== null &&
          cluster.value !== "-" &&
          cluster.margin !== null &&
          cluster.margin !== "-"
        );
      });
    },
  },

  methods: {
    async getClusters() {
      this.isLoading = true;

      try {
        let response = await Service.getBoldClusters(
          this.idEnterprisePricesProjection
        );

        this.formatData(response?.data?.result ?? null);
      } catch (error) {
        this.$handleError(error, "Erro ao buscar clusters");
      }

      this.isLoading = false;
    },

    formatData(response) {
      if (!response) return;

      this.clusters = this.clusters.map((cluster) => ({
        title: cluster.title,
        margin: response[cluster.margin],
        discount: response[cluster.discount],
        optimalPrice: response[cluster.optimalPrice],
        value: response[cluster.value],
      }));
    },
  },

  mounted() {
    this.getClusters();
  },
};
</script>
