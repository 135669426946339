<template>
  <v-card class="mt-8 pa-4" :disabled="!isEditing">
    <h4 class="mb-4">{{ $t("TXT_LINKED_RESOURCES").toUpperCase() }}</h4>
    <Prd-loading-circular v-if="isLoading" class="mt-6" />
    <v-data-table v-else :headers="headers" :items="resources">
      <!-- HEADERS -->
      <template v-slot:[`header.select`]>
        <div class="text-center">
          <v-checkbox
            v-model="selectedAll"
            @click="selectAll"
            hide-details
            class="mt-0 pa-0 ml-2"
          ></v-checkbox>
        </div>
      </template>

      <template v-slot:[`item.select`]="{ item }">
        <div class="text-center">
          <v-checkbox
            v-model="item.select"
            hide-details
            class="pa-0 ma-0 ml-2"
          ></v-checkbox>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import { mapActions } from "vuex";
export default {
  components: { PrdLoadingCircular },
  props: ["isEditing"],
  data() {
    return {
      headers: [
        {
          text: this.$i18n.t("TXT_SELECT_2"),
          align: "center",
          sortable: false,
          value: "select",
          width: "50px",
        },
        {
          text: this.$i18n.t("TXT_RESOURCE_NAME"),
          align: "center",
          sortable: true,
          value: "name",
        },
        {
          text: this.$i18n.t("TXT_RESOURCE_DESCRIPTION"),
          align: "center",
          sortable: true,
          value: "description",
        },
      ],
      items: [],
      isLoading: false,
      selectedAll: false,
    };
  },
  computed: {
    resources() {
      return this.$store?.state?.companyConfigStore?.resources ?? [];
    },
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    ...mapActions(["GET_RESOURCES"]),

    async getResources() {
      this.isLoading = true;
      await this.GET_RESOURCES(this.idCompany);
      this.isLoading = false;
    },

    selectAll() {
      this.resources.forEach((el) => {
        el.select = this.selectedAll;
      });
    },
  },

  created() {
    this.getResources();
  },
};
</script>

<style lang="scss" scoped>
.text-center {
  display: flex;
  justify-content: center;
}
</style>