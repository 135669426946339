<template>
  <div class="mt-6">
    <div class="my-flex">
      <v-tabs
        v-model="tab"
        color="black"
        slider-color="#1D8527"
        background-color="transparent"
        class="group-filters mb-4 mr-4"
        height="25px"
      >
        <v-tab>
          <v-row no-gutters>
            <h4>{{ $t("TXT_OPORTUNITIES").toUpperCase() }}</h4>
          </v-row>
        </v-tab>
      </v-tabs>
    </div>

    <card-increase :oportunitiesData="oportunitiesData" />
  </div>
</template>

<script>
import CardIncrease from "./components/card-increase.vue";
export default {
  props: ["oportunitiesData"],
  components: {CardIncrease },
  data() {
    return {
      tab: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.group-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.281);
  display: flex;
}
</style>