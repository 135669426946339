<template>
  <div>
    <v-row no-gutters class="gap-4 mb-4">
      <Export-file-main />
      <Import-file-main @refeshUsers="$refs.userList.getInitialPage()" />
    </v-row>

    <v-row no-gutters justify="center">
      <v-row no-gutters justify="center" class="mb-4">
        <v-card class="pa-4 flex-1 mr-4">
          <User-list-main ref="userList" />
        </v-card>

        <v-card class="flex-1 pa-4">
          <Store-list-main />
        </v-card>
      </v-row>
    </v-row>

    <v-row
      v-if="hasSelectedUser || hasChanges"
      no-gutters
      justify="end"
      class="gap-4 mb-12"
    >
      <Prd-btn
        :outlined="true"
        :title="$t('TXT_CANCEL')"
        @click="removeAllSelections"
      />
      <Prd-btn
        :disabled="!hasChanges"
        :title="$t('TXT_CONFIRM_CHANGES')"
        @click="$refs.handlerChangesModal.toggleControl()"
      />
    </v-row>

    <handler-changes-modal ref="handlerChangesModal" />
  </div>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import HandlerChangesModal from "./components/handler-changes-modal/handler-changes-modal.vue";
import StoreListMain from "./components/store-list/store-list-main.vue";
import UserListMain from "./components/user-list/user-list-main.vue";
import ExportFileMain from "./components/export-file-main.vue";
import ImportFileMain from "./components/import-file-main.vue";

export default {
  components: {
    UserListMain,
    StoreListMain,
    HandlerChangesModal,
    PrdBtn,
    ExportFileMain,
    ImportFileMain,
  },
  computed: {
    hasSelectedUser() {
      return this.$store.getters.hasSelectedUser;
    },
    hasChanges() {
      return this.$store.getters.hasChanges ?? false;
    },
    changes() {
      return this.$store.state.storeManagementStore.changes;
    },
  },
  methods: {
    removeAllSelections() {
      this.$store.commit("removeAllSelectedUser");
      this.$store.commit("removeAllSelectedStore");
      this.$store.commit("clearChanges");
    },
  },
};
</script>
