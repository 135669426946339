import { buildFiltersRequest, buildRequestCreateRuleInGroup, buildRequestRules } from "./copilot-utils"
import service from "@/service/pricing-rules/create-rules.js"
const Service = new service()

export async function handleOperation2(payload) {
    const rulesPromises = payload.operation.RuleGroup.Rules.map((rule) => {
        let request = buildRequestCreateRuleInGroup(payload.idCompany, payload.operation.RuleGroup.Id, rule);
        return Service.addRuleInGroup(request);
    });

    const request = buildFiltersRequest(payload);
    const filtersPromise = Service.updateFilter(request);

    return await Promise.all([rulesPromises, filtersPromise]);
}

export async function createRuleGroup(payload) {
    let request = buildRequestRules(payload.idCompany, payload.operation)
    try {
        await Service.saveRule(request)
    } catch (error) {
        console.log('createRuleGroup')
        throw error
    }
}

export async function deleteRuleFromGroup(payload) {
    try {
        const promises = payload.operation.RuleGroup.Rules.map((rule) => {
            return Service.deleteRuleFromGroup(payload.idCompany, payload.operation.RuleGroup.Id, rule.Id)
        });

        await Promise.all(promises);
    } catch (error) {
        console.log('deleteRuleFromGroup', error);
        throw error
    }
}

export async function deleteGroupRules(payload) {
    try {
        await Service.deleteRule(payload.idCompany, payload.operation.RuleGroup.Id)
    } catch (error) {
        console.log('deleteGroupRules', error)
        throw error
    }
}