<template>
  <v-card class="pa-4" :disabled="isDisabled">
    <h3 class="mb-4">{{$t("TXT_TYPE_PRECIFICATION").toUpperCase()}}</h3>
    <p>{{$t("TXT_SELECT_TYPE_PRECIFICCATION")}}</p>
    <v-col class="pa-0" cols="12" sm="4">
      <Prd-combo-box
        v-model="selectedPrecification"
        :title="$t('TXT_TYPE_PRECIFICATION')"
        :placeholder="$t('TXT_SELECT')"
        :items="precificationList"
      />
    </v-col>
  </v-card>
</template>

<script>
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
export default {
  components: { PrdComboBox },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    productForEdition: {
      type: Object,
    },
  },
  data() {
    return {
      precificationList: [
        "Value Price",
        "Down Price",
        "Preço de competição",
        "Oportunidade de Mercado",
        "Produto Líder",
        "Custo",
        "Mistura Interna",
        "Mistura Externa",
      ],
      selectedPrecification: null,
    };
  },
  watch: {
    selectedPrecification: {
      handler(value) {
        this.$emit("setPrecificationType", value);
      },
    },
    productForEdition: {
      handler(value) {
        if (value) {
          if (value.tipoPrecificacao != "Concorrente")
            this.selectedPrecification = value.tipoPrecificacao;
          else this.selectedPrecification = value.estrategiaPrecificacao;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>