<template>
  <div>
    <Create-user
      @createUserLoading="isLoading = $event"
      @updateUserList="updateUserList = $event"
    />
    <Registered-Users
      :isLoading="isLoading"
      :updateUserList="updateUserList"
      @updateUserList="updateUserList = $event"
    />
  </div>
</template>

<script>
import CreateUser from "./components/create-user.vue";
import RegisteredUsers from "./components/registered-users.vue";
export default {
  components: { CreateUser, RegisteredUsers },
  data() {
    return {
      isLoading: false,
      updateUserList: false,
    };
  },
};
</script>