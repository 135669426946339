<template>
  <div>
    <div id="map"></div>
    <div id="popup" class="ol-popup">
      <a href="#" id="popup-closer" class="ol-popup-closer"></a>
      <div id="popup-content"></div>
    </div>
  </div>
</template>

<script>
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { fromLonLat } from "ol/proj";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Style, Circle, Fill, Stroke, Text } from "ol/style";
import Cluster from "ol/source/Cluster";
import Overlay from "ol/Overlay";
import { formatMonetary } from "@/utils/format-toMonetary";
import { colors } from "../utils/type-color-names";
import { getBrazilianStateData } from "../utils/brazilian-states";
export default {
  name: "MapComponent",
  props: {
    setMapCenterFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      map: null,
      layers: [],
    };
  },
  computed: {
    mapPoints() {
      return this.$store?.getters?.getMapPoints ?? [];
    },
    selectedState() {
      return this.$store?.getters?.getSelectedState ?? null;
    },
    types() {
      return [].concat(...new Set(this.mapPoints.map((item) => item.type)));
    },
    firstPointCenter() {
      const item = this.mapPoints.slice(0, 1)[0];
      const center = [item?.lng ?? -47.9291, item?.lat ?? -15.7783];
      return center;
    },
  },

  watch: {
    mapPoints: {
      handler() {
        this.prepareLayers();
        this.preparePopups();
      },
      deep: true,
    },
    setMapCenterFlag: {
      handler() {
        this.setMapCenter();
      },
    },
    selectedState: {
      handler(value) {
        this.setMapCenter(value);
      },
    },
  },

  methods: {
    initMap() {
      this.map = new Map({
        target: "map",
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
        view: new View({
          center: fromLonLat(this.firstPointCenter),
          zoom: 4,
        }),
      });
    },
    prepareLayers() {
      this.cleanMap();
      const categories = {};

      this.mapPoints.forEach((item) => {
        if (!categories[item.type]) {
          categories[item.type] = [];
        }
        categories[item.type].push(item);
      });

      const sourcesByCategory = {};
      for (const category in categories) {
        const features = categories[category].map((item) => {
          return new Feature({
            geometry: new Point(fromLonLat([item.lng, item.lat])),
            name: item.name,
            type: item.type,
            price: item.price,
            address: item.address,
            amountAnnouncement: item.amountAnnouncement,
            category: item.type,
          });
        });
        sourcesByCategory[category] = new VectorSource({ features: features });
      }

      const clusterSourcesByCategory = {};
      for (const category in sourcesByCategory) {
        clusterSourcesByCategory[category] = new Cluster({
          distance: 18,
          source: sourcesByCategory[category],
        });
      }

      const getImage = (category, size) => {
        return new Circle({
          radius: size,
          stroke: new Stroke({
            color: "#fff",
          }),
          fill: new Fill({
            color: this.getColorByType(category),
          }),
        });
      };

      const styleCache = {};

      for (const category in clusterSourcesByCategory) {
        this.layers.push(
          new VectorLayer({
            source: clusterSourcesByCategory[category],
            style: (feature) => {
              const size = feature.get("features").length;
              let style = styleCache[category + size];
              if (!style) {
                if (size > 1) {
                  style = new Style({
                    image: getImage(category, 15),
                    text: new Text({
                      text: size.toString(),
                      fill: new Fill({
                        color: "#fff",
                      }),
                    }),
                  });
                } else {
                  style = new Style({
                    image: getImage(category, 5),
                  });
                }
                styleCache[category + size] = style;
              }
              return style;
            },
          })
        );
      }

      this.layers.forEach((layer) => {
        this.map.addLayer(layer);
      });
    },
    cleanMap() {
      if (this.layers.length > 0) {
        this.layers.forEach((layer) => {
          this.map.removeLayer(layer);
        });
        this.layers = [];
      }
    },
    getColorByType(paramType) {
      const index = this.types.findIndex((type) => type == paramType);
      const color = colors[index];
      return color;
    },
    preparePopups() {
      const container = document.getElementById("popup");
      const content = document.getElementById("popup-content");
      const closer = document.getElementById("popup-closer");

      const overlay = new Overlay({
        element: container,
      });
      this.map.addOverlay(overlay);

      closer.onclick = function () {
        overlay.setPosition(undefined);
        closer.blur();
        return false;
      };

      const displayPopup = (event) => {
        if (this.map.hasFeatureAtPixel(event.pixel)) {
          this.map.forEachFeatureAtPixel(event.pixel, (feature) => {
            const coordinates = feature.getGeometry().getCoordinates();
            const features = feature.get("features");
            if (features.length === 1) {
              const properties = features[0].getProperties();
              content.innerHTML = `<h3>${properties.name}</h3><br>
                                  <strong>${this.$i18n.t(
                                    "TXT_TYPE"
                                  )}:</strong> ${
                properties?.type ?? this.$i18n.t("TXT_UNKNOW")
              }<br>
                                 <strong>${this.$i18n.t(
                                   "TXT_PRICE"
                                 )}:</strong> ${formatMonetary(
                properties.price
              )}<br>
                                  <strong>${this.$i18n.t(
                                    "TXT_ADDRESS"
                                  )}:</strong> ${properties.address}<br>
                                  ${
                                    properties.amountAnnouncement > 1
                                      ? `<strong>${this.$i18n.t(
                                          "TXT_ANNOUCEMENT"
                                        )}:</strong> ` +
                                        properties.amountAnnouncement
                                      : ""
                                  }`;
              overlay.setPosition(coordinates);
            }
          });
        } else {
          overlay.setPosition(undefined);
        }
      };

      this.map.on("pointermove", displayPopup);

      this.map.on("singleclick", displayPopup);
    },
    setMapCenter(state) {
      let zoom = 4;
      let center = fromLonLat(this.firstPointCenter);
      if (state) {
        zoom = 6;
        const { lat, lng } = getBrazilianStateData(state);
        center = fromLonLat([lng, lat]);
      }
      this.map.getView().setCenter(center);
      this.map.getView().setZoom(zoom);
    },
  },

  mounted() {
    this.initMap();
  },
};
</script>

<style lang="scss" scoped>
.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 300px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-color: rgba(204, 204, 204, 0);
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}
#map {
  width: 100%;
  height: 550px;
}
</style>
