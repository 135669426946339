import { render, staticRenderFns } from "./handler-rule-datas.vue?vue&type=template&id=e8d19a74&scoped=true"
import script from "./handler-rule-datas.vue?vue&type=script&lang=js"
export * from "./handler-rule-datas.vue?vue&type=script&lang=js"
import style0 from "./handler-rule-datas.vue?vue&type=style&index=0&id=e8d19a74&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8d19a74",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCheckbox,VRow})
