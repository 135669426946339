import { render, staticRenderFns } from "./competitiveness-history-card.vue?vue&type=template&id=a0f14718&scoped=true"
import script from "./competitiveness-history-card.vue?vue&type=script&lang=js"
export * from "./competitiveness-history-card.vue?vue&type=script&lang=js"
import style0 from "./competitiveness-history-card.vue?vue&type=style&index=0&id=a0f14718&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0f14718",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCol,VIcon,VRow,VSpacer,VTab,VTabs,VTooltip})
