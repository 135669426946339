import { render, staticRenderFns } from "./competitiveness-graphic-main.vue?vue&type=template&id=5e3055eb&scoped=true"
import script from "./competitiveness-graphic-main.vue?vue&type=script&lang=js"
export * from "./competitiveness-graphic-main.vue?vue&type=script&lang=js"
import style0 from "./competitiveness-graphic-main.vue?vue&type=style&index=0&id=5e3055eb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e3055eb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VContainer,VTab,VTabs})
