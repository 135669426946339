<template>
  <div class="notItemTextAlign">
    <span class="grayText" v-html="text" />
  </div>
</template>

<script>
export default {
  props: ["text"],
};
</script>

<style lang="scss" scoped>
.notItemTextAlign {
  height: 100%;
  display: flex;
  align-items: center;
}
.grayText {
  color: $neutral-color-low-medium;
}
</style>