import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class CrawlerManagementService extends ServiceBase {
    async getCompanyList() {
        return await axiosInstance.get(API_URL + "api/Empresa/GetEmpresaNome");
    }
    async getCrawlerByCompany(idCompany) {
        return await axiosInstance.get(API_URL + "api/V2/Company_MonitoringCrawler/ListCompanyCrawlers", { params: { idCompany: idCompany } });
    }
    async getAllCrawler() {
        return await axiosInstance.get(API_URL + "api/V2/MonitoringCrawler/List");
    }
    async saveCrawlers(request) {
        return await axiosInstance.post(API_URL + "api/V2/Company_MonitoringCrawler", request);
    }
    async setCrawlerRotine(request) {
        return await axiosInstance.get(API_URL + "api/V2/Monitoring/CalculateMarketResult", { params: { idCompany: request.idCompany, startDate: request.startDate, endDate: request.endDate, refDate: request.refDate, skip: request.skipItems, removeOlds: request.removeOlds, removeOutliers: request.removeOutliers } });
    }
}