export default {
    state: () => ({
        productList: [],
        semaphoreFilter: [],
        idPriceGroup: null,
        currentPage: 1,
        order: 0,
        itemsPerPage: 10,
        isLoading: false,
        isFirstSearch: true,
        workflowId: null,
        cleanSemaphoreFilters: false,
        setIsManualLoading: false
    }),
    getters: {
        productList: (state) => state.productList,
        semaphoreFilter: (state) => state.semaphoreFilter,
        idPriceGroup: (state) => state.idPriceGroup,
        currentPage: (state) => state.currentPage,
        order: (state) => state.order,
        itemsPerPage: (state) => state.itemsPerPage,
        isLoading: (state) => state.isLoading,
        isFirstSearch: (state) => state.isFirstSearch,
        workflowId: (state) => state.workflowId,
        cleanSemaphoreFilters: (state) => state.cleanSemaphoreFilters,
        setIsManualLoading: (state) => state.setIsManualLoading,
    },
    mutations: {
        saveProductsIA(state, products) {
            state.productList = products
        },
        setSemaphoreFilter(state, filters) {
            state.semaphoreFilter = filters
        },
        setIdPriceGroup(state, idPriceGroup) {
            state.idPriceGroup = idPriceGroup
        },
        setCurrentPage(state, page) {
            state.currentPage = page
        },
        setItemsPerPage(state, numberOfItems) {
            state.itemsPerPage = numberOfItems
        },
        setLoading(state, value) {
            state.isLoading = value
        },
        setFirstSearch(state, value) {
            state.isFirstSearch = value
        },
        updatedSemaphore(state, pricing) {
            if (pricing) {
                const index = state.productList.records.findIndex(el => el.displayName === pricing.displayName);
                if (index !== -1) {
                    state.productList.records.splice(index, 1, pricing);
                }
            }
        },
        cleanSemaphore(state) {
            state.cleanSemaphoreFilters = !state.cleanSemaphoreFilters
        },
        setIsManualLoading(state, payload) {
            state.setIsManualLoading = payload
        },
        setOrder(state, order) {
            state.order = order
        },
    },
};
