<template>
  <Modal-action
    :useExternalActivatorButton="true"
    :externalIsOpen="itsOpen"
    @handlerIsOpen="itsOpen = $event"
    :icon="'mdi-alert-outline'"
    :iconColor="'red'"
    :modalTitle="$t('TXT_EXCLUDE_RULE_GROUP_TITLE')"
    :question="$t('TXT_EXCLUDE_RULE_GROUP_QUESTION')"
    :alertMessage="$t('TXT_EXCLUDE_RULE_GROUP_ALERT')"
    :confirmButtonText="$t('TXT_EXCLUDE')"
    :confirmButtonIcon="'mdi-delete-outline'"
    :buttonsColor="'red'"
    @handleConfirmAction="deleteRule"
  >
    <template slot="externalActivatorButton">
      <v-icon
        v-show="showOpenButton"
        :color="$prdStyles('color-warning')"
        :disabled="externalLoading || disableRulesActions"
        size="26"
        @click.stop="openModal"
        class="mr-2"
        >mdi-delete-outline</v-icon
      >

      <v-progress-circular
        class="mx-6"
        indeterminate
        :color="$prdStyles('color-primary')"
        v-show="!showOpenButton"
      />
    </template>
    <template slot="content">
      <div>
        <p class="ma-0">
          <span class="font-weight-bold">{{ $t("TXT_GROUP_NAME") }}: </span
          >{{ ruleData ? ruleData.description : "-" }}
        </p>
        <p class="ma-0">
          <span class="font-weight-bold">{{ $t("TXT_ACTIVE") }}: </span
          >{{ ruleData && ruleData.isActive ? "Sim" : "Não" }}
        </p>

        <div v-if="namesConditions.length > 0">
          <h4>{{ $t("TXT_CONTAINED_RULES") }}:</h4>
          <v-row
            v-for="name in namesConditions"
            :key="name"
            no-gutters
            class="ml-5"
          >
            <v-icon :color="$prdStyles('color-primary')"
              >mdi-circle-small</v-icon
            >
            <p class="my-0 mr-2">
              <span class="font-weight-bold">{{ $t("TXT_NAME") }}: </span>
              {{ name }}
            </p>
          </v-row>
        </div>
      </div>
    </template>
  </Modal-action>
</template>

<script>
import ModalAction from "@/components/common/modal-action.vue";
import PricingRuleService from "@/service/pricing-rules/index.js";
const pricingRuleService = new PricingRuleService();

import HandleRuleService from "@/service/pricing-rules/create-rules.js";
const handleRuleService = new HandleRuleService();

export default {
  components: { ModalAction },
  props: {
    externalLoading: {
      type: Boolean,
      default: false,
    },
    idRule: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      itsOpen: false,
      itsLoading: false,
      itsLoadingRuleData: false,
      ruleData: null,
    };
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    showOpenButton() {
      return !this.itsLoadingRuleData && !this.itsLoading;
    },
    namesConditions() {
      if (!this.ruleData) return [];
      const conditions = this.ruleData.conditions.map(
        (condition) => condition.conditionType
      );

      const uniqueConditions = Array.from(new Set(conditions).values());

      const namesConditions = uniqueConditions.map((conditionType) =>
        this.$store.getters.getRuleNameByConditionType(conditionType)
      );

      return namesConditions ?? [];
    },
    disableRulesActions() {
      return this.$store?.getters?.disableRulesActions;
    },
  },

  methods: {
    async openModal() {
      if (this.ruleData == null) await this.getRule();
      this.itsOpen = true;
    },

    async getRule() {
      this.itsLoadingRuleData = true;

      try {
        let response = await handleRuleService.getRule(
          this.idCompany,
          this.idRule
        );
        this.ruleData = response?.data?.answer ?? null;
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_SEARCH_DATA_RULE_ERROR"),
          type: "error",
        });
      } finally {
        this.itsLoadingRuleData = false;
      }
    },

    async deleteRule() {
      this.itsLoading = true;

      try {
        await pricingRuleService.deleteRule(this.idCompany, this.idRule);
        this.$store.dispatch("getRules");

        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_DELETED_RULES_SUCCESS"),
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_DELETED_RULES_ERROR"),
          type: "error",
        });
      }

      this.itsLoading = false;
    },
  },
};
</script>