<template>
  <div class="mt-4">
    <v-row no-gutters class="gap-4" align="center">
      <Prd-text-field
        v-model="model.min"
        :title="$t('TXT_MIN_RANGE')"
        :type="'number'"
        hide-spin-buttons
        :min="0"
      />
      <Prd-text-field
        v-model="model.max"
        :title="$t('TXT_MAX_RANGE')"
        :type="'number'"
        hide-spin-buttons
        :min="0"
      />
      <Prd-text-field
        v-model="model.value"
        :title="$t('TXT_VALUE')"
        :type="'number'"
        hide-spin-buttons
        :min="0"
      />
      <Prd-combo-box
        v-model="model.reference"
        :title="$t('TXT_REFERENCE')"
        :items="referenceItems"
      />
    </v-row>
  </div>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
import PrdComboBox from "@/components/common/prd-comboBox.vue";

export default {
  props: ["index", "values", "idRule"],
  components: { PrdTextField, PrdComboBox },
  data() {
    return {
      model: {
        min: null,
        max: null,
        value: null,
        reference: { text: this.$i18n.t("TXT_STANDARD"), value: 0 },
      },
      referenceItems: [
        { text: this.$i18n.t("TXT_STANDARD"), value: 0 },
        { text: this.$i18n.t("TXT_LOWER"), value: 1 },
        { text: this.$i18n.t("TXT_HIGHER"), value: 2 },
        { text: this.$i18n.t("TXT_CLOSER"), value: 3 },
      ],
    };
  },
  watch: {
    model: {
      handler(value) {
        this.updateConditions(value);
      },
      deep: true,
    },
    values: {
      handler(value) {
        if (value.values?.length > 0) this.setInputs(value.values);
        else
          this.model.reference = {
            text: this.$i18n.t("TXT_STANDARD"),
            value: 0,
          };
      },
      immediate: true,
    },
  },
  methods: {
    updateConditions(inputs) {
      let formatedValue = [
        {
          stringValue: "MinRange",
          decimalValue: inputs.min ?? 0,
          booleanValue: false,
        },
        {
          stringValue: "MaxRange",
          decimalValue: inputs.max ?? 0,
          booleanValue: false,
        },
        {
          stringValue: "SetValue",
          decimalValue: inputs.value ?? 0,
          booleanValue: false,
        },
        {
          stringValue: "RelativeRound",
          decimalValue: inputs.reference.value ?? 0,
          booleanValue: false,
        },
      ];
      this.$store.dispatch("updateConditions", {
        idRule: this.idRule,
        key: "updateConditionsDefault",
        conditionType: 16,
        index: this.index,
        values: formatedValue,
      });
    },
    setInputs(conditionList) {
      const minRange = conditionList.find(
        (condition) => condition.stringValue == "MinRange"
      ).decimalValue;
      this.model.min = minRange;

      const maxRange = conditionList.find(
        (condition) => condition.stringValue == "MaxRange"
      ).decimalValue;
      this.model.max = maxRange;

      const value = conditionList.find(
        (condition) => condition.stringValue == "SetValue"
      ).decimalValue;
      this.model.value = value;

      const reference = conditionList.find(
        (condition) => condition.stringValue == "RelativeRound"
      ).decimalValue;
      this.model.reference = this.referenceItems.find(
        (item) => item.value == reference
      );
    },
  },
};
</script>