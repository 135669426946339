<template>
  <v-card
    class="pa-4 main-box"
    :class="setBorder(company.statusPagamento)"
    :disabled="company.isBloqueado"
    @click="selectCompany"
  >
    <!-- LEFT INFO -->
    <div class="flex-column-left">
      <h3>{{ company.nomeRazaoSocial }} ({{ company.idEmpresa }})</h3>
      <span class="mt-4 font-size-18 primary-color"
        >{{ $t("TXT_RESPONSIBLE") }}: {{ company.proprietarioNome }}
      </span>
      <span class="font-size-14"
        ><span class="font-weight-bold">{{ $t("TXT_RESPONSIBLE") }}:</span>
        {{ company.proprietarioEmail }}
      </span>
      <span class="font-size-14"
        ><span class="font-weight-bold">{{ $t("TXT_PHONE") }}</span
        >:
        {{ company.proprietarioTelefone }}
      </span>
   
    </div>

    <!-- RIGHT INFO -->
    <div class="flex-column-right">
      <div class="my-flex">
        <div
          class="company_situation"
          :class="setBackground(company.statusPagamento)"
        >
          {{ setText(company.statusPagamento) }}
        </div>
        <!-- <img src="@/assets/flags/brasil.svg" class="flag" alt="" /> -->
      </div>

      <div v-if="company.isBloqueado" class="my-flex">
        <div class="company_situation-block">{{ $t("TXT_BLOCKED_2") }}</div>
      </div>

      <span class="my-flex font-size-14"
        >{{ $t("TXT_LAST_ACCESS") }}: {{ date }}</span
      >
    </div>
  </v-card>
</template>

<script>
import service from "@/service/company-select/index.js";
const Service = new service();
import homeService from "@/service/home-vem/index.js";
const HomeService = new homeService();
export default {
  props: ["company"],
  data() {
    return {};
  },

  computed: {
    date() {
      if (!this.company.dataHoraUltimaAlteracao) return null;

      const dataObj = new Date(this.company.dataHoraUltimaAlteracao);

      const dia = dataObj.getDate().toString().padStart(2, "0");
      const mes = (dataObj.getMonth() + 1).toString().padStart(2, "0");
      const ano = dataObj.getFullYear().toString();

      return dia + "/" + mes + "/" + ano;
    },
  },

  methods: {
    async selectCompany() {
      this.$store.commit("toggleSelectCompanyLoading", true);

      try {
        let response = await Service.selectCompany(this.company.idEmpresa);
        let company = response.data;

        if (this.company.idEmpresa == 3026) await this.getHierarchy();
        this.setCompany(company);
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_SELECT_COMPAY"),
          type: "error",
        });
      }

      this.$store.commit("toggleSelectCompanyLoading", false);
    },

    async getHierarchy() {
      try {
        let response = await HomeService.getHierarchy(this.company.idEmpresa);
        this.$store.commit("setVemUser", response?.data?.result ?? null);
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_SELECT_COMPAY"),
          type: "error",
        });
      }
    },

    async setCompany(company) {
      let userStore = this.$store.getters.userData;

      let userData = {
        ...userStore,
        idCompany: company.idEmpresa,
        companyName: company.nomeRazaoSocial,
      };

      await this.$store.dispatch("setUser", userData);
      await this.$store.dispatch("setResources", company.recursos);
      await this.$store.dispatch("setCompanyId", company.idEmpresa);
      this.$router.push("/main/home");
    },

    setBorder(status) {
      if (this.company.isBloqueado) return "border-expired";
      switch (status) {
        case 0:
          return "border-expired";
        case 1:
          return "border-free";
        case 2:
          return "border-active";
      }
    },

    setBackground(status) {
      switch (status) {
        case 0:
          return "background-expired";
        case 1:
          return "background-free";
        case 2:
          return "background-active";
      }
    },

    setText(status) {
      switch (status) {
        case 0:
          return this.$i18n.t("TXT_EXPIRED");
        case 1:
          return this.$i18n.t("TXT_FREE");
        case 2:
          return this.$i18n.t("TXT_ACTIVE");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-box {
  display: flex;
  justify-content: space-between;
}

.main-box:hover {
  opacity: 0.8;
}

.border-expired {
  border-left: 7px solid #b00020;
}

.border-free {
  border-left: 7px solid #3c5ca7;
}

.border-active {
  border-left: 7px solid #1d8527;
}

.background-expired {
  background: #b00020;
}

.background-free {
  background: #3c5ca7;
}

.background-active {
  background: #1d8527;
}

.my-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-column-left {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.flex-column-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.company_situation {
  padding: 2px 32px;
  border-radius: 4px;
  margin-right: 18px;
  color: white;
}

.company_situation-block {
  padding: 2px 32px;
  border-radius: 4px;
  color: white;
  background-color: $feedback-color-warning-pure;
  width: min-content;
}

.flag {
  width: 30px;
  height: 30px;
  border-radius: 10px;
}

.primary-color {
  color: $brand-color-primary-pure;
}
</style> 