<template>
  <v-card class="pa-4" :disabled="disabled">
    <v-row no-gutters class="sectionName">
      <span class="font-weight-bold px-1">{{ title }}</span>
    </v-row>
    <v-row
      v-if="useHeaderSection"
      no-gutters
      class="pa-4 my-4 headerSection"
      align="center"
    >
      <slot name="header-left" />
      <v-spacer />
      <slot name="header-right" />
    </v-row>
    <slot name="content" />
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    useHeaderSection: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.sectionName {
  border-radius: 0px !important;
  border-bottom: 1px solid $neutral-color-low-light;
  span {
    border-bottom: 4px solid $brand-color-secondary-pure;
  }
}

.headerSection {
  border-radius: 4px;
  background-color: $neutral-color-high-light;
}
</style>