<template>
  <v-dialog v-model="modalOpen" :max-width="600" persistent>
    <v-card class="pa-4">
      <div class="my-flex">
        <v-icon :color="$prdStyles('color-success')" size="35"
          >mdi-check-circle-outline
        </v-icon>
        <h4 class="my-4">{{ $t("TXT_NEW_GROUP_RESOURCE").toUpperCase() }}</h4>
        <span>{{ $t("TXT_NEW_GROUP_RESOURCE_QUESTION") }}</span>
      </div>

      <div class="group">
        <span
          >{{ $t("TXT_NAME") }}:
          <span class="font-weight-bold">{{ name }}</span></span
        >
        <span
          >{{ $t("TXT_DESCRIPTION") }}:
          <span class="font-weight-bold">{{ description }}</span></span
        >
        <span
          >{{ $t("TXT_LINKED_RESOURCES") }}:
          <span
            v-for="resource in resources"
            :key="resource.idResource"
            class="font-weight-bold"
            ><span v-show="resource.select">{{ resource.name }} |</span>
          </span></span
        >
      </div>

      <span>{{ $t("TXT_CONFIRM_USER_ACCESS_INFO") }}</span>
      <Prd-card-actions
        :outlinedTitle="$t('TXT_CANCEL')"
        :title="$t('TXT_CONFIRM')"
        :loading="isLoading"
        :isDisable="isLoading"
        @clickOutlined="cancelChanges"
        @click="createGroup"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import PrdCardActions from "@/components/common/prd-card-actions.vue";

export default {
  components: { PrdCardActions },
  props: [
    "group",
    "isLoading",
    "name",
    "description",
    "resources",
    "isModalOpen",
  ],
  data() {
    return {
      modalOpen: false,
    };
  },
  watch: {
    isModalOpen: {
      handler(value) {
        this.modalOpen = value;
      },
    },
  },
  methods: {
    createGroup() {
      this.$emit("createGroup", this.group);
    },
    cancelChanges() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 16px;
}
.destaq-color {
  color: $feedback-color-warning-pure;
}

.user-info {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
.align-center {
  text-align: center;
}

.group {
  background-color: $neutral-color-high-light;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>