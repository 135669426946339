<template>
  <v-card class="card-relative pa-4">
    <v-row no-gutters align="center">
      <h4>{{ $t("TXT_MARKET_ANALYSIS") }}</h4>
      <Competitors-price-modal
        :idEnterprisePricesProjection="idEnterprisePricesProjection"
        :productName="productName"
      />
      <v-spacer></v-spacer>
      <Variable-weights-chart-modal
        v-if="UPL"
        :idCompany="idCompany"
        :projectionReference="projectionReference"
      />
    </v-row>

    <Prd-loading-circular v-if="isLoading" class="div-centered" />

    <div class="main-inputs mt-4" v-else>
      <div class="monitoring-items">
        <span>{{ $t("TXT_PRODUCT_COST") }}</span>
        <div class="input-box">{{ formatMonetary(productCost) }}</div>
      </div>
      <div class="monitoring-items">
        <span>{{ $t("TXT_CURRENT_PRICE") }}</span>
        <div class="input-box">{{ formatMonetary(currentPrice) }}</div>
      </div>
      <div class="main-monitoring">
        <span>{{ $t("TXT_MARKET_MONITORING_IA") }}</span>
        <div class="input-box monitoring px-2">
          <Tooltip-price
            :icon="'mdi-arrow-down-bold-outline'"
            :iconColor="$prdStyles('color-warning')"
            :price="marketMinPrice"
            :tooltipText="'TXT_LOWEST_PRICE_FOUNDED'"
          />
          <div class="gap-2">
            <Tooltip-price
              :icon="'mdi-minus-circle-outline'"
              :price="marketAvgPrice"
              :tooltipText="'TXT_AVERAGE_PRICE_COMPETITORS'"
            />
            <Tooltip-price
              :icon="'mdi-circle-double'"
              :price="marketModaPrice"
              :tooltipText="'TXT_MOST_FREQUENT_PRICE_FOUNDED'"
            />
          </div>
          <Tooltip-price
            :icon="'mdi-arrow-up-bold-outline'"
            :iconColor="$prdStyles('color-success')"
            :price="marketMaxPrice"
            :tooltipText="'TXT_HIGHEST_PRICE_FOUNDED'"
          />
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import CompetitorsPriceModal from "./components/competitors-price-modal.vue";
import VariableWeightsChartModal from "./components/variable-wights-chart/variable-weights-chart-modal.vue";
import TooltipPrice from "./components/tooltip-price.vue";
import service from "@/service/pricer/product-list.js";
const Service = new service();

import { formatMonetary } from "@/utils/format-toMonetary.js";

export default {
  props: ["idEnterprisePricesProjection", "productName", "projectionReference"],
  components: {
    CompetitorsPriceModal,
    PrdLoadingCircular,
    VariableWeightsChartModal,
    TooltipPrice,
  },

  data() {
    return {
      isLoading: false,

      data: [],
    };
  },

  computed: {
    productCost() {
      return this.data?.productCost ?? "-";
    },
    currentPrice() {
      return this.data?.currentPrice ?? "-";
    },
    marketMinPrice() {
      return this.data?.marketMinPrice;
    },
    marketAvgPrice() {
      return this.data?.marketAvgPrice;
    },
    marketMaxPrice() {
      return this.data?.marketMaxPrice;
    },
    marketModaPrice() {
      return this.data?.modaPrice;
    },
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    UPL() {
      return this.idCompany == 2858;
    },
  },

  methods: {
    formatMonetary,
    async getMarketAnalysis() {
      this.isLoading = true;

      try {
        let response = await Service.getMarketAnalysis(
          this.idEnterprisePricesProjection
        );
        this.data = response?.data?.result ?? null;
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_GET_MARKET_ANALISYS_DATAS"),
          type: "error",
        });
        this.data = null;
      }

      this.isLoading = false;
    },
  },

  created() {
    this.getMarketAnalysis();
  },
};
</script>

<style lang="scss" scoped>
.div-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-relative {
  position: relative;
}
.main-inputs {
  display: flex;
  gap: 1rem;

  .monitoring-items {
    width: 20%;
  }

  .input-box {
    border: 1px solid #bab8b8;
    border-radius: 5px;
    padding: 5px 0px 5px 5px;
    margin-top: 4px;
  }

  .main-monitoring {
    flex-grow: 1;
    .monitoring {
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
