<template>
  <v-row
    no-gutters
    :class="`py-2 px-4 originalItem ${setBackgroundColor}`"
    justify="space-between"
  >
    {{ licenseData.nome }}
    <v-icon
      v-if="licenseData.canShowToSales"
      color="white"
      @click="$emit('handlerLicense', licenseData)"
      :class="`bodyIcon ${isLinkedLicense ? 'red' : 'green'}Icon`"
      >mdi-chevron-{{ isLinkedLicense ? "left" : "right" }}</v-icon
    >
  </v-row>
</template>

<script>
export default {
  props: {
    licenseData: { type: Object },
    isLinkedLicense: { type: Boolean, default: false },
  },
  computed: {
    isDiferentInitialLocal() {
      return this.licenseData.isLinked != this.licenseData.originalIsLinked;
    },
    setBackgroundColor() {
      if (!this.isDiferentInitialLocal) return "";
      return this.licenseData.isLinked ? "linkedItem" : "unlinkedItem";
    },
  },
};
</script>

<style lang="scss" scoped>
.originalItem {
  border-radius: 4px 4px 0px 0px;
  background-color: $neutral-color-high-light;
}
.linkedItem {
  background-color: $feedback-color-success-light;
}
.unlinkedItem {
  background-color: $feedback-color-warning-light;
}
.bodyIcon {
  border-radius: 4px;
}
.redIcon {
  background-color: $feedback-color-warning-pure;
}

.greenIcon {
  background-color: $feedback-color-success-pure;
}
</style>