<template>
  <v-expansion-panels v-model="panel" flat v-if="content && title">
    <v-expansion-panel>
      <v-expansion-panel-header class="header">
        {{ $i18n.t(title) }}
      </v-expansion-panel-header>
      <v-expansion-panel-content class="content">
        {{ $i18n.t(content) }}
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: ["title", "content"],
  data() {
    return {
      panel: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  border: 1px solid gray;
  border-radius: 8px;
  transition: 0.3s;
  &:hover {
    scale: 1.01;
  }
}

.content {
  margin-top: 4px;
  margin-left: 8px;
  border-left: 6px solid $brand-color-primary-pure;
  height: auto;
  color: gray;
}
</style>