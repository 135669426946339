<template>
  <v-expansion-panels v-model="panel">
    <v-expansion-panel :disabled="itsLoading || itsDisabledLocal" class="py-3">
      <v-expansion-panel-header>
        <v-row no-gutters align="center">
          <v-col cols="auto" class="mr-4">
            <v-row no-gutters>
              <label class="title-card">
                {{ $t("TXT_ANNOUNCEMENT_MAP").toUpperCase() }}
              </label>
              <Prd-tooltip :text="$t('TXT_ANNOUNCEMENT_MAP_TOOLTIP')" />
            </v-row>
          </v-col>
          <v-col v-if="itsLoading">
            <Prd-loading-msg :text="$t('TXT_CARRYING')" />
          </v-col>
        </v-row>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <Filters-main
          :externalLoading="isLoading"
          @listToTable="listToTable = $event"
          class="mb-4"
        />
        <v-row no-gutters>
          <v-col cols="6">
            <Map-main-openlayers
              v-if="showOpenlayerMap"
              :setMapCenterFlag="panel == undefined"
              class="mb-4"
            />
            <Map-main-here
              v-else
              :setMapCenterFlag="panel == undefined"
              class="mb-4"
            />
            <Legend-main />
          </v-col>
          <v-col cols="6" class="pl-2">
            <Table-main />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import PrdTooltip from "@/Design_System/common/prd-tooltip.vue";
import FiltersMain from "./components/filters-main.vue";
import TableMain from "./components/table-main.vue";
import MapMainHere from "./components/map-main-here.vue";
import MapMainOpenlayers from "./components/map-main-openlayers.vue";
import LegendMain from "./components/legend-main.vue";
import PredimonitorService from "@/service/predimonitor/predimonitor-service";
import PrdLoadingMsg from "@/components/common/prd-loading-msg.vue";
export default {
  name: "Announcement-Map-V2",

  components: {
    PrdTooltip,
    FiltersMain,
    TableMain,
    MapMainHere,
    MapMainOpenlayers,
    LegendMain,
    PrdLoadingMsg,
  },

  data() {
    return {
      itsLoading: false,
      service: new PredimonitorService(),
      listToTable: [],
      panel: 0,
    };
  },

  computed: {
    itsDisabledLocal() {
      return this.$store.getters.getPredimonitorResults.length < 1;
    },
    isLoading() {
      return this.$store.state.PredimonitorStore.isLoading;
    },
    enableOpenlayerMap() {
      return process.env.VUE_APP_ENABLE_OPEN_LAYER_MAP;
    },

    showOpenlayerMap() {
      return this.enableOpenlayerMap == "true";
    },
  },

  watch: {
    isLoading: {
      handler(value) {
        if (value) this.panel = undefined;
      },
    },
    panel: {
      handler(value) {
        if (!value) this.$store.dispatch("setState", null);
      },
    },
  },

  methods: {
    prepareRequest(search) {
      return {
        idCompany: search.idCompany,
        idMonitoringItems:
          search?.monitoringItemIds ?? search?.filters?.idProducts ?? [],
      };
    },

    async getAffiliateMapList(search) {
      if (!search) return;
      const request = this.prepareRequest(search);
      this.itsLoading = true;
      try {
        const response = await this.service.getAffiliateMapList(request);
        this.$store.dispatch("setAffiliateList", response?.data?.result ?? []);
      } catch (error) {
        console.log(error);
      } finally {
        this.itsLoading = false;
      }
    },
  },
  created() {
    setTimeout(() => {
      this.panel = undefined;
    }, 100);
  },
};
</script>

<style lang="scss" scoped>
.title-card {
  font-size: 16px;
  font-weight: 500;
}
</style>
