<template>
  <Standard-list-card
    :title="$t('TXT_STORES')"
    :description="$t('TXT_MANAGEMENT_STORES_FOR_SELECTED_USER')"
    :isLoading="isLoading"
    :externalDisabled="!hasSelectedUser"
  >
    <template v-slot:search>
      <Prd-search
        v-model="searchTerm"
        :searchLabel="$t('TXT_TYPE_NAME_CODE_FOR_MANAGEMENT_STORE')"
      />
    </template>
    <template v-slot:filters>
      <prd-combo-box
        v-model="order"
        :title="$t('TXT_SORT_BY')"
        :items="orderItems"
        :itemText="'description'"
      />
    </template>

    <template v-slot:list>
      <store-card
        v-for="(store, index) in storeList"
        :key="index"
        :storeData="store"
        @click.native="toggleSelectedStore(store.value)"
      />
      <h3 class="text-center" v-if="!isLoading && !storeList.length">
        {{ $t("TXT_NO_DATA_AVAILABLE") }}
      </h3>
    </template>
  </Standard-list-card>
</template>

<script>
import PrdSearch from "@/components/common/prd-search.vue";
import StandardListCard from "../common/standard-list-card.vue";
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import StoreCard from "./store-card.vue";
export default {
  components: { StandardListCard, PrdSearch, PrdComboBox, StoreCard },
  data() {
    return {
      isLoading: false,
      searchTerm: null,
      order: {
        value: 0,
        description: this.$i18n.t("TXT_ALPHABETICAL_ORDER"),
      },
      orderItems: [
        {
          value: 0,
          description: this.$i18n.t("TXT_ALPHABETICAL_ORDER"),
        },
        {
          value: 1,
          description: this.$i18n.t("TXT_LINKED_STORES"),
        },
        {
          value: 2,
          description: this.$i18n.t("TXT_UNLINKED_STORES"),
        },
      ],
    };
  },
  computed: {
    hasSelectedUser() {
      return this.$store.getters.hasSelectedUser;
    },

    storeList() {
      let copyList = structuredClone(
        this.$store.state.storeManagementStore.storeList
      );

      if (this.order.value == 1)
        copyList.sort((a, b) => b.isSelected - a.isSelected);
      else if (this.order.value == 2)
        copyList.sort((a, b) => a.isSelected - b.isSelected);
      else copyList.sort((a, b) => a.description.localeCompare(b.description));

      if (this.searchTerm)
        copyList = copyList.filter((store) =>
          store.description.includes(this.searchTerm)
        );

      return copyList;
    },
  },
  methods: {
    toggleSelectedStore(value) {
      this.$store.commit("toggleSelectedStore", value);
      this.$store.commit("updateChanges", value);
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("getStoreList");
    this.isLoading = false;
  },
};
</script>
