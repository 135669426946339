<template>
  <div class="mb-16">
    <p class="mb-8 espaceLine">
      {{ $t("TXT_INSTRUCTIONS_IN_EMAIL") }}
    </p>
    <p class="mb-8 espaceLine">{{ $t("TXT_CHECK_MAIN_BOX") }}</p>
    <Prd-btn
      :title="$t('TXT_RETURN_TO_LOGIN')"
      style="width: 100%"
      @click="returnToLoginView"
    />
  </div>
</template>
  
  <script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  components: { PrdBtn },
  methods: {
    returnToLoginView() {
      this.$router.push({ name: "sign-in" });
    },
  },
};
</script>
<style scoped>
.espaceLine {
  line-height: 1.2 !important;
}
</style>