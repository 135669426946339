<template>
  <div class="dsg-tabs mt-4">
    <p v-t="'TXT_VIZUALIZATION'" class="ma-0 pa-0 mr-6"></p>
    <v-tabs
      v-model="tab"
      :slider-color="$prdStyles('color-secundary')"
      slider-size="6"
      height="35"
      color="black"
      align-with-title
      class="group-filters"
      @change="$emit('setTab', $event)"
      :disabled="externalDisable"
    >
      <v-tab
        :disabled="externalDisable"
        v-t="'TXT_ADOPTION_IA'"
        class="ml-0 pl-0"
      >
      </v-tab>
      <v-tab :disabled="externalDisable" v-t="'TXT_DEMAND'"> </v-tab>
      <v-tab :disabled="externalDisable" v-t="'TXT_BRUTE_RECIPE'"> </v-tab>
      <v-tab :disabled="externalDisable" v-t="'TXT_BRUTE_MARGIN'"> </v-tab>
      <v-tab :disabled="externalDisable" v-t="'TXT_GROSS_PROFIT'"> </v-tab>
</v-tabs>
  </div>
</template>

<script>
export default {
  props: {
    externalDisable: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    tab: 0,
  }),
};
</script>

<style lang="scss" scoped>
.dsg-tabs {
  display: flex;
  align-items: center;
  width: max-content;
}

.group-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.281);
}
.v-tab {
  font-size: 14px !important;
  text-transform: inherit;
  margin-right: 25px;
  font-weight: 600;
}
</style>