<template>
  <v-row no-gutters align="center" class="mr-1">
    <p class="ma-0">
      <span v-if="title != 'Ouro'">|</span> {{ title }}:
      <span class="font-weight-bold"> {{ formatMonetary(value) }}</span>
    </p>
    <Prd-tooltip-informations v-if="showtooltip" :text="tooltipContent" />
  </v-row>
</template>

<script>
import PrdTooltipInformations from "@/components/common/prd-tooltip.vue";
import { formatMonetary } from "@/utils/format-toMonetary.js";
import { formatPercentage } from "@/utils/format-percentage.js";

export default {
  components: { PrdTooltipInformations },

  props: {
    title: { default: "Desconhecido" },
    value: { default: "R$ 00,00" },
    margin: { default: "0%" },
    discount: { default: "0%" },
    optimalPrice: { default: "R$ 00,00" },
  },

  methods: {
    formatMonetary,
    formatPercentage,
  },

  computed: {
    tooltipContent() {
      return `
      <p class="text-caption ma-0 pa-0">${this.$i18n.t(
        "TXT_CALC_BASE"
      )}: <b>${this.formatMonetary(this.optimalPrice)}</b></p>
      <p class="text-caption ma-0 pa-0">${this.$i18n.t(
        "TXT_DISCOUNT"
      )}: <b>${this.formatPercentage(this.discount)}</b></p>
      <p class="text-caption ma-0 pa-0">${this.$i18n.t(
        "TXT_MARGIN"
      )}: <b>${this.formatPercentage(this.margin)}</b></p>
      `;
    },

    showtooltip() {
      return this.margin && this.margin != "-";
    },
  },
};
</script>
