<template>
  <div>
    <v-icon
      :color="$prdStyles('color-success')"
      :disabled="disable"
      @click="isModalOpen = true"
      class="mr-2"
    >
      mdi-checkbox-marked
    </v-icon>

    <v-dialog v-model="isModalOpen" :max-width="650" persistent>
      <v-card class="pa-4">
        <div>
          <div class="my-flex">
            <v-icon :color="$prdStyles('color-success')" size="50"
              >mdi-checkbox-marked-circle-outline</v-icon
            >
            <h4 class="my-4">
              {{ $t("TXT_CONFIRM_EDIT_USER").toUpperCase() }}
            </h4>
            <span>{{ $t("TXT_MODAL_CONFIRM_EDIT_USER_QUESTION") }}</span>
          </div>
          <div class="user-info">
            <span
              >{{ $t("TXT_USER_NAME") }}:
              <span class="font-weight-bold">{{ user.nome }}</span>
            </span>
            <span
              >E-mail:
              <span class="font-weight-bold">{{ user.email }}</span>
            </span>
            <span
              >{{ $t("TXT_PHONE") }}:
              <span class="font-weight-bold">{{ user.telefone }}</span>
            </span>
            <span
              >{{ $t("TXT_ACCESS_PLURAL") }}:
              <span
                v-show="user.adminRoles.length > 0"
                class="font-weight-bold"
              >
                <span v-show="user.adminRoles.includes('1003')"
                  >{{ $t("TXT_ADMIN") }}
                  <v-icon color="#FFC700"> mdi-account-box </v-icon>
                  |
                </span>
                <span v-show="user.adminRoles.includes('1000')"
                  >{{ $t("TXT_BRAZIL") }} |
                </span>
                <span v-show="user.adminRoles.includes('1001')"
                  >{{ $t("TXT_UNITED_STATES") }} |
                </span>
                <span v-show="user.adminRoles.includes('1002')"
                  >{{ $t("TXT_EUROPE") }} |
                </span>
              </span>
            </span>
          </div>

          <div class="align-center">
            <span>
              {{ $t("TXT_MODAL_CONFIRM_EDIT_USER_ALERT_MESSAGE") }}
            </span>
          </div>

          <v-row no-gutters justify="end" class="mt-6">
            <Prd-btn
              :title="$t('TXT_CANCEL')"
              :outlined="true"
              :disabled="isLoading"
              @click="isModalOpen = false"
              class="mr-4"
            />
            <Prd-btn
              :title="$t('TXT_CONFIRM')"
              :loading="isLoading"
              :disabled="isLoading"
              @click="editUser"
            />
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  props: ["user", "disable", "isLoading"],
  components: { PrdBtn },
  data() {
    return {
      isModalOpen: false,
    };
  },

  watch: {
    isLoading: {
      handler(value) {
        if (!value) {
          this.isModalOpen = false;
        }
      },
      immediate: true,
    },
  },

  methods: {
    editUser() {
      this.$emit("updateUser", this.user);
    },
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 16px;
}
.destaq-color {
  color: $feedback-color-warning-pure;
}

.user-info {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.align-center {
  text-align: center;
}
</style>