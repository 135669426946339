<template>
  <div>
    <div class="border-title mt-8">
      <h4>{{ $t("TXT_FILTERS_LOWERCASE") }}</h4>
    </div>

    <Prd-loading-msg
      v-if="loadingFilters"
      :text="$t('TXT_LOADING_FILTERS')"
      :messageInFront="true"
      class="mt-6"
    />

    <div class="mt-4">
      <v-row no-gutters>
        <v-col
          v-for="(filter, index) in filters"
          :key="index"
          cols="12"
          md="3"
          class="mb-4"
        >
          <Prd-combo-box
            v-model="filter.selected"
            :title="setTranslations(filter.fieldName)"
            :items="filter.values"
            :disabled="filter.values.length == 0"
            :filled="filter.values.length == 0"
            :textTooltipInformation="filter.tooltip"
            :useTooltipInformation="filter.tooltip"
            :itemText="'description'"
            :itemValue="'value'"
            class="mr-4"
          />
        </v-col>
        <v-col v-if="!loadingFilters && !loadingRules" cols="12" md="3">
          <Prd-combo-box
            v-model="curve"
            :title="$t('TXT_ABC_ANALYSIS')"
            :items="abcItems"
            class="mr-4"
          />
        </v-col>
        <v-col v-if="!loadingFilters && !loadingRules" cols="12" md="3">
          <Prd-combo-box
            v-model="elasticity"
            :title="$t('TXT_ELASTICITY')"
            :items="elasticityItems"
            class="mr-4"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import service from "@/service/pricing-rules/create-rules.js";
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import PrdLoadingMsg from "@/components/common/prd-loading-msg.vue";
import { setTranslations } from "@/utils/setTranslations";
const Service = new service();
export default {
  components: { PrdComboBox, PrdLoadingMsg },
  props: {
    loadingRules: { type: Boolean, default: false },
    resetFieldsFlag: { type: Boolean, default: false },
    idRule: { type: Number },
    rule: { type: Object },
  },
  data() {
    return {
      abcItems: ["ABC", "AB", "AC", "BC", "A", "B", "C"],
      elasticityItems: [
        {
          text: `${this.$i18n.t("TXT_ELASTIC_2")} / ${this.$i18n.t(
            "TXT_INELASTIC_2"
          )}`,
          value: 0,
        },
        {
          text: this.$i18n.t("TXT_ELASTIC_2"),
          value: 1,
        },
        {
          text: this.$i18n.t("TXT_INELASTIC_2"),
          value: 2,
        },
      ],

      filters: [],
      curve: null,
      elasticity: null,
      loadingFilters: false,
      getFiltersFlag: false,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  watch: {
    idRule: {
      async handler() {
        if (!this.getFiltersFlag) {
          this.getFiltersFlag = true;
          await this.getFilters();
          this.getFiltersFlag = false;
        }
      },
      immediate: true,
      deep: true,
    },

    rule: {
      handler(value) {
        if (value) this.fillFields(value);
      },
      immediate: true,
      deep: true,
    },

    filters: {
      handler(value) {
        this.$emit("filters", value);
      },
      immediate: true,
      deep: true,
    },

    curve: {
      handler(value) {
        this.$emit("curve", value);
      },
      immediate: true,
      deep: true,
    },

    elasticity: {
      handler(value) {
        this.$emit("elasticity", value);
      },
      immediate: true,
      deep: true,
    },

    resetFieldsFlag() {
      this.resetFields();
    },
  },
  methods: {
    setTranslations,

    async getFilters() {
      this.loadingFilters = true;

      try {
        let response = await Service.getFilters(this.idCompany);

        response.data.result.forEach((filter) => {
          filter.selected = null;
        }) ?? [];

        this.filters =
          response?.data?.result.filter(
            (filter) => filter.fieldName !== "Curva ABC"
          ) ?? [];
        if (this.rule?.filters) this.fillFilters(this.rule.filters);
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_SEARCH_FILTERS_ERROR"),
          type: "error",
        });
      }

      this.loadingFilters = false;
    },

    fillFields(rule) {
      this.curve = rule.allowAbcCurve;
      this.elasticity = this.elasticityItems[rule.enterprise_ElasticityStatus];
      this.fillFilters(rule.filters);
    },

    fillFilters(filters) {
      this.filters.forEach((item) => {
        filters.forEach((el) => {
          if (
            el.idEnterprisePriceGroup_DefaultFilter ==
            item.idEnterprisePriceGroupDefaultFilter
          ) {
            let foundedIndex = item.values.find((ff) => ff.value == el.value);
            item.selected = foundedIndex;
          }
        });
      });
    },
    
    resetFields() {
      this.filters = this.filters.map((filter) => {
        return { ...filter, selected: null };
      });
      this.curve = null;
      this.elasticity = null;
    },
  },
};
</script>