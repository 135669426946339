<template>
  <div>
    <v-row no-gutters align="center" class="mb-1">
      <span class="mr-2">{{ title }}</span>
      <tooltip-informations
        v-if="useTooltipInformation"
        :title="textTooltipInformation"
        :rightIcon="true"
      />
    </v-row>
    <v-combobox
      v-bind="$attrs"
      append-icon="mdi-chevron-down"
      :placeholder="$t('TXT_SELECT')"
      :item-text="itemText"
      :label="personalizedLabel"
      single-line
      outlined
      dense
      hide-details
      return-object
      item-value="value"
      @input="onInput"
      @change="change"
      @blur="onBlur"
      @focus="onFocus"
      @click:clear="onClearable"
    >
      <template v-for="slot in parentSlots" #[slot]>
        <slot :name="slot" />
      </template>

      <template v-for="(index, name) in parentScopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data" />
      </template>
    </v-combobox>
  </div>
</template>

<script>
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";
export default {
  name: "prdComboBox",
  components: { TooltipInformations },
  data() {
    return {
      hasSelections: false,
      previousSelections: null,
    };
  },
  props: {
    title: {
      type: String,
    },
    textTooltipInformation: {
      type: String,
    },
    useTooltipInformation: {
      type: Boolean,
      default: false,
    },
    itemText: {
      type: String,
      default: "text",
    },
  },

  methods: {
    onInput(values) {
      this.$emit("input", values);
    },
    change(values) {
      this.$emit("change", values);
      this.hasSelections = true;
    },
    onFocus() {
      this.$emit("onFocus", this.$attrs.value);
      this.previousSelections = this.$attrs?.value ?? null;
    },
    onBlur() {
      this.$emit("onBlur", this.$attrs.value);
      if (
        this.hasSelections &&
        (JSON.stringify(this.$attrs.value) !==
          JSON.stringify(this.previousSelections) ||
          (this.$attrs.value.length == 0 &&
            this.previousSelections.length == 0))
      )
        this.$emit("hasSelections");
      this.hasSelections = false;
    },
    onClearable() {
      this.hasSelections = true;
      this.previousSelections = null;
    },
  },
  computed: {
    parentSlots() {
      if (this.$slots) return Object.keys(this.$slots);
      return [];
    },
    parentScopedSlots() {
      if (this.$scopedSlots) return Object.keys(this.$scopedSlots);
      return [];
    },
    personalizedLabel() {
      return this.$attrs?.items?.length == 0
        ? this.$i18n.t("TXT_NO_HAS_DATA")
        : this.$attrs.label;
    },
  },
};
</script>

<style scoped>
.test {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
</style>