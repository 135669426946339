<template>
  <highcharts class="hc" :options="chartOptions" />
</template>

<script>
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import HighchartsMore from "highcharts/highcharts-more";
import noData from "highcharts/modules/no-data-to-display";

exportingInit(Highcharts);
exportData(Highcharts);
HighchartsMore(Highcharts);
noData(Highcharts);

import { formatMonetary } from "@/utils/format-toMonetary";
import i18n from "@/i18n";
import { getTranslationByAgroupmentType } from "../utils/get-translation-by-agroupment-type";
export default {
  data() {
    return {
      chartOptions: {
        chart: {
          type: "boxplot",
          inverted: true,
        },

        title: {
          text: null,
        },

        legend: {
          enabled: false,
        },
        lang: {
          viewFullscreen: this.$i18n.t("TXT_VISUALIZE_FULLSCREEN"),
          printChart: this.$i18n.t("TXT_PRINT_CHART"),
          downloadCSV: this.$i18n.t("TXT_DOWNLOAD_CSV_FILE"),
          downloadJPEG: this.$i18n.t("TXT_DOWNLOAD_JPEG_IMAGE"),
          downloadPDF: this.$i18n.t("TXT_DOWNLOAD_PDF"),
          downloadPNG: this.$i18n.t("TXT_DOWNLOAD_PNG_IMAGE"),
          downloadSVG: this.$i18n.t("TXT_DOWNLOAD_SVG_IMAGE"),
          downloadXLS: this.$i18n.t("TXT_DOWNLOAD_XLS_FILE"),
          exitFullscreen: this.$i18n.t("TXT_LEAVE_FULLSCREEN"),
          viewData: this.$i18n.t("TXT_DISPLAY_DATA_IN_TABLE"),
          noData: this.$i18n.t("TXT_NO_DATA_AVAILABLE"),
        },

        noData: {
          style: {
            fontWeight: "bold",
            fontSize: "15px",
            color: "#303030",
          },
        },

        xAxis: {
          categories: [],
          title: {
            text: this.$i18n.t("TXT_PRODUCT"),
          },
        },

        yAxis: {
          title: {
            text: this.$i18n.t("TXT_PRICE"),
          },
          labels: {
            formatter: function () {
              return formatMonetary(this.value);
            },
            decimals: 2,
          },
          allowDecimals: false,
          crosshair: true,
        },

        plotOptions: {
          boxplot: {
            fillColor: "#76D680",
            lineWidth: 1,
            lineColor: "#00000048",
            medianColor: "#00000048",
            medianWidth: 4,
            stemColor: "#00000048",
            stemWidth: 2,
            whiskerColor: "#00000048",
            whiskerLength: "25%",
            whiskerWidth: 3,
            maxPointWidth: 35,
            tooltip: {
              headerFormat: null,
              pointFormatter: function () {
                return (
                  "<b>" +
                  this.category +
                  "</b><br/>" +
                  `${i18n.t("TXT_MIN")}: ` +
                  formatMonetary(this.low) +
                  "<br/>" +
                  `${i18n.t("TXT_FIRST_QUARTILE")}: ` +
                  formatMonetary(this.q1) +
                  "<br/>" +
                  `${i18n.t("TXT_MEDIAN")}: ` +
                  formatMonetary(this.median) +
                  "<br/>" +
                  `${i18n.t("TXT_THIRD_QUARTILE")}: ` +
                  formatMonetary(this.q3) +
                  "<br/>" +
                  `${i18n.t("TXT_MAX")}: ` +
                  formatMonetary(this.high) +
                  "<br/>"
                );
              },
            },
          },
          scatter: {
            tooltip: {
              headerFormat: null,
              pointFormatter: function () {
                return `${i18n.t("TXT_OBSERVATION")}: ${formatMonetary(
                  this.y
                )}`;
              },
            },
          },
        },

        series: [],
      },
    };
  },
  computed: {
    preparedDatas() {
      return this.$store.state.ProductComparisionStore.preparedDatasForBoxPlot;
    },
    groupBy() {
      return this.$store.state.ProductComparisionStore.groupBy;
    },
    xAxisTitle() {
      return getTranslationByAgroupmentType(this.groupBy);
    },
  },

  watch: {
    preparedDatas: {
      handler() {
        this.prepareChart();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    prepareChart() {
      this.cleanChart();

      this.chartOptions.xAxis.title.text = this.xAxisTitle;

      let topTen = null;
      if (this.groupBy == "sellerName") {
        topTen = structuredClone(this.preparedDatas)
          .sort((a, b) => b.data.avg - a.data.avg)
          .slice(0, 10)
          .sort((a, b) => a.description.localeCompare(b.description));
      }

      this.populateChart(topTen ?? this.preparedDatas, topTen == null);
    },

    cleanChart() {
      this.chartOptions.xAxis.categories = [];
      this.chartOptions.xAxis.title.text = null;
      this.chartOptions.series = [];
    },

    populateChart(datas, useOutliers) {
      this.chartOptions.xAxis.categories =
        datas.map((item) => item.description) ?? [];
      this.addBoxPlot(datas);
      if (useOutliers) this.addOutliers(datas);
    },

    addBoxPlot(datas) {
      this.chartOptions.series.push({
        data: datas.map((serie) => {
          return {
            type: "boxplot",
            ...serie.data,
          };
        }),
      });
    },

    addOutliers(datas) {
      let outliers = {
        type: "scatter",
        data: datas.flatMap((element) => element.outliers),
        marker: {
          fillColor: "white",
          lineWidth: 1,
          lineColor: Highcharts.getOptions().colors[0],
        },
      };

      this.chartOptions.series.push(outliers);
    },
  },
};
</script>