
<template>
  <v-expansion-panels :disabled="disablePanel" focusable class="mb-4">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-row no-gutters justify="space-between" class="mr-2" align="center">
          <span class="font-weight-bold">{{ title }}</span>
          <v-progress-circular
            v-if="disablePanel"
            indeterminate
            :color="$prdStyles('color-primary')"
          ></v-progress-circular>
          <v-icon v-else size="32" :color="$prdStyles('color-primary')">
            {{ icon }}
          </v-icon>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <base-card
          v-for="(base, index) in bases"
          :key="index"
          :class="index < bases.length - 1 ? 'mb-4' : ''"
          :title="base.title"
          :lastImportation="base.lastImportation"
          :isLoadingUpload="base.isLoadingUpload"
          :isLoadingDownload="base.isLoadingDownload"
          :isLoadingDownloadLast="base.isLoadingDownloadLast"
          :useTemplate="base.useTemplate"
          @download="$emit('download', index)"
          @downloadLastFile="$emit('downloadLastFile', index)"
          @upload="$emit('upload', { index, file: $event })"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>


<script>
import baseCard from "./base-card.vue";
export default {
  props: ["disablePanel", "title", "icon", "bases"],
  components: { baseCard },
};
</script>