<template>
  <v-container fluid>
    <v-row no-gutters justify="space-between" align="center">
      <titleBreadCrumbs :title="'FAQs'" :hasTooltip="false" />
      <prd-btn
        v-if="previousPagePath != null && previousPagePath != undefined"
        :title="$t('TXT_BACK')"
        :icon="'mdi-arrow-left'"
        :smallIcon="true"
        @click.native="$router.push(previousPagePath)"
      />
    </v-row>
    <v-card class="pa-4">
      <Prd-loading-circular v-show="isLoading" />
      <expansion-item
        v-for="(faq, index) in listFaqs"
        :key="index"
        :title="faq.pergunta"
        :content="faq.resposta"
        :class="index != listFaqs.length - 1 ? 'mb-2' : ''"
      />
    </v-card>
  </v-container>
</template>

<script>
import titleBreadCrumbs from "@/Design_System/common/title.vue";
import ExpansionItem from "./components/expansion-item.vue";
import HeaderService from "@/service/header/index.js";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  name: "FaqsMain",
  components: { titleBreadCrumbs, ExpansionItem, PrdLoadingCircular, PrdBtn },
  data() {
    return {
      service: new HeaderService(),
      listFaqs: [],
      isLoading: false,
    };
  },
  computed: {
    previousPagePath() {
      return this.$route.params.data?.previousPagePath;
    },
  },
  methods: {
    getFaqs() {
      this.isLoading = true;
      this.service
        .GetFaqs()
        .then((res) => {
          this.listFaqs = res.data;
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_ERROR"),
            type: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getFaqs();
  },
};
</script> 
