<template>
  <div class="mt-6">
    <h4 v-t="'TXT_FILTER'" class="mb-6"></h4>
    <Prd-loading-msg
      v-if="filterLoading"
      :text="$t('TXT_LOADING_FILTERS')"
      :messageInFront="true"
    />

    <div class="filters-grid gap-6 align-end">
      <Prd-combo-box
        v-for="filter in filters"
        v-model="filter.value"
        v-show="filter.dbField !== 900000"
        :key="filter.IdEnterprisePriceProjection_DefaultFilter"
        :title="filter.fieldName"
        :style="`grid-column: span ${filter.displaySpan}`"
        :placeholder="$t('TXT_SELECT')"
        :hideIcon="true"
        :multiple="false"
        :disabled="filter.values.length == 0 || filterValuesLoading"
        :loading="filterValuesLoading"
        :items="translateFilters(filter.values)"
        :itemText="'description'"
        @change="sendFilters"
        clearable
      />
    </div>
  </div>
</template>

<script>
import service from "@/service/pricer/filters.js";
import PrdLoadingMsg from "@/components/common/prd-loading-msg.vue";
import PrdComboBox from "@/components/common/prd-comboBox.vue";
const Service = new service();
export default {
  props: ["selectedPricing"],
  components: { PrdLoadingMsg, PrdComboBox },
  data() {
    return {
      filterLoading: false,
      filterValuesLoading: false,
      filters: [],
      semaphoreFilters: [],
    };
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    idPriceGroup() {
      return this.selectedPricing?.idEnterprisePriceGroups ?? null;
    },

    semaphoreFilter() {
      return this.$store?.getters?.semaphoreFilter ?? [];
    },

    hasFilters() {
      return this.filters.some((el) => el.value !== null);
    },

    selectedFilters() {
      return this.filters
        .filter((el) => el.value)
        .map((el) => ({
          dbField: el.dbField,
          idEnterprisePriceProjectionDefaultFilter:
            el?.idEnterprisePriceProjectionDefaultFilter ?? null,
          value: el.value.value,
        }));
    },
  },

  watch: {
    idPriceGroup() {
      this.getFilters();
    },
    semaphoreFilter: {
      handler(value) {
        let filtersToSend = value.map((el) => ({
          dbField: 900000,
          idEnterprisePriceProjectionDefaultFilter: 1457,
          value: el,
        }));
        this.semaphoreFilters = filtersToSend;
        this.sendFilters();
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    async getFilters() {
      if (this.filters.length <= 0) this.filterLoading = true;
      else this.filterValuesLoading = true;

      try {
        let response = await Service.getFilters(
          this.idCompany,
          this.idPriceGroup
        );

        let data =
          response?.data?.result?.customFilters.filter(
            (el) => el.dbField != 900001
          ) ?? [];

        data.forEach((filter) => {
          filter.value = null;
        });

        this.filters = data;
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_FIND_FILTERS"),
          type: "error",
        });
      }

      this.filterLoading = false;
      this.filterValuesLoading = false;
    },

    sendFilters() {
      let filtersToSend = [];
      filtersToSend = this.semaphoreFilters.concat(this.selectedFilters);
      this.$emit("setFilters", filtersToSend ?? null);
    },

    translateFilters(filters) {
      let translatedFilters = [];

      if (filters) {
        filters.forEach((filter) => {
          if (filter.description.includes("TXT")) {
            translatedFilters.push({
              description: this.$i18n.t(filter.description),
              value: filter.value,
            });
          } else {
            translatedFilters.push({
              description: filter.description,
              value: filter.value,
            });
          }
        });
      }

      return translatedFilters;
    },
  },

  created() {
    this.getFilters();
  },
};
</script>

<style lang="scss" scoped>
.filters-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
</style>