<template>
  <v-card class="pa-4" :disabled="calculateRulesLoading || isLoading">
    <div class="main-filters">
      <div>
        <Prd-combo-box
          v-if="preFilters.length > 0"
          v-model="selectedPreFilter"
          :title="preFilters[0].fieldName"
          :items="preFilters[0].values"
          :loading="preFiltersLoading"
          :disabled="preFiltersLoading"
          :itemText="'description'"
          @change="getPricingList"
          item-value="value"
          class="mr-6 pricing-combobox"
        />

        <Date-select
          v-if="!preFiltersLoading && UPL"
          :disabledIcon="
            pricingListLoading ||
            controlRecalculateModal ||
            calculateRulesLoading ||
            disablePricingList
          "
          @selectDate="selectDate = $event"
        />

        <Combobox-pricing
          :preFilter="selectedPreFilter"
          :pricingList="pricingList"
          :isLoading="pricingListLoading"
          :disablePricingList="disablePricingList"
          :disableList="disableList"
          @verifyPricing="verifyPricing"
          @refreshList="getPricingList"
        />

        <Create-modal-main
          :preSelect="preFilters"
          :loadingProductList="disablePricingList || preFiltersLoading"
          :preFilters="preFilters"
          :selectedPreFilter="selectedPreFilter"
          @calculateFinish="getPricingList"
          ref="createPricingModal"
        />

        <Prd-loading-circular v-if="preFiltersLoading" class="mt-7 ml-4" />
      </div>
      <div class="mt-4">
        <Prd-btn
          v-if="peralta"
          :title="$t('TXT_RECALCULATE_PRICING_LOWER')"
          :disabled="
            disablePricingList || !selectedPricing || selectedPricing.published
          "
          @click="controlRecalculateModal = true"
          class="mr-4"
        />
        <Prd-btn
          :title="$t('TXT_RECALCULATE_RULES')"
          :disabled="
            disablePricingList ||
            !selectedPricing ||
            calculateRulesLoading ||
            selectedPricing.published
          "
          :outlined="true"
          :loading="calculateRulesLoading"
          @click="calculateRules"
        />
      </div>
    </div>

    <v-row v-if="UPL" no-gutters class="mt-4">
      <Variavel-tempo-ia />
    </v-row>

    <Filters-main
      :selectedPricing="selectedPricing"
      @setFilters="filters = $event"
    />

    <Recalculate-modal
      :recalculateModal="controlRecalculateModal"
      :selectedPriceGroup="selectedPricing"
      @closeRecalculateModal="controlRecalculateModal = false"
      @recalculatePricing="recalculatePricing"
    />
  </v-card>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import CreateModalMain from "../create-pricing-modal/create-modal-main.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import service from "@/service/pricer/filters.js";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import RecalculateModal from "../create-pricing-modal/recalculate-modal.vue";
import DateSelect from "./components/date-select.vue";
import VariavelTempoIa from "@/views/main/upl/components/info-product/variavel-tempo-ia.vue";
import FiltersMain from "./components/filters-main.vue";
import ComboboxPricing from "./components/combobox-pricing.vue";
const Service = new service();
export default {
  components: {
    PrdComboBox,
    CreateModalMain,
    PrdBtn,
    PrdLoadingCircular,
    FiltersMain,
    RecalculateModal,
    DateSelect,
    ComboboxPricing,
    VariavelTempoIa,
  },
  data() {
    return {
      isLoading: false,

      preFiltersLoading: false,
      preFilters: [],
      selectedPreFilter: null,

      pricingListLoading: false,
      disableList: true,
      pricingList: [],
      selectedPricing: null,
      selectDate: null,
      filters: null,

      controlRecalculateModal: false,

      calculateRulesLoading: false,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    UPL() {
      return this.idCompany == 2858;
    },

    peralta() {
      return this.isPredifyAdmin || this.idCompany !== 2941;
    },

    isPredifyAdmin() {
      return this.$store.getters.isPredifyAdmin;
    },

    disablePricingList() {
      return this.preFilters.length > 0 && !this.selectedPreFilter;
    },

    currentPage() {
      return this.$store?.getters?.currentPage ?? 1;
    },

    itemsPerPage() {
      return this.$store?.getters?.itemsPerPage ?? 1;
    },

    order() {
      return this.$store?.getters?.order ?? 0;
    },
  },

  watch: {
    filters: {
      handler(value) {
        this.$store.commit("setCurrentPage", 1);
        this.filters = value;

        this.listProducts();
      },
      deep: true,
    },

    currentPage() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      this.listProducts();
    },

    order() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      this.listProducts();
    },

    itemsPerPage() {
      this.listProducts();
    },

    selectDate: {
      handler() {
        this.getPricingList();
      },
    },
  },

  methods: {
    async getPreFilters() {
      this.preFiltersLoading = true;

      try {
        let response = await Service.getPreFilters(this.idCompany);
        this.preFilters = response?.data?.result ?? [];

        if (this.preFilters.length == 0) this.getPricingList();
      } catch (error) {
        console.log("Erro", error);

        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_FIND_FILTERS"),
          type: "error",
        });
        this.preFilters = [];
        this.getPricingList();
      }

      this.preFiltersLoading = false;
      this.disableList = false;
    },

    async getPricingList(idEnterprisePriceGroups, fromLoading) {
      this.pricingListLoading = true;
      this.$store.commit("saveProductsIA", []);

      this.selectedPricing = null;
      this.pricingList = [];

      let request = {
        filters: this.selectedPreFilter
          ? this.buildFilter(this.selectedPreFilter)
          : [],
        start_date: this.selectDate?.start_date,
        end_date: this.selectDate?.end_date,
        idCompany: this.idCompany,
        simulation: false,
      };

      try {
        let response = await Service.getPricingList(request);
        this.pricingList = response?.data?.result ?? [];

        if (fromLoading && idEnterprisePriceGroups) {
          this.selectedPricing = this.pricingList[0];
          this.listProducts();
        }
      } catch (error) {
        console.log(error);

        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_FIND_PRICING"),
          type: "error",
        });
      }

      this.pricingListLoading = false;
    },

    async listProducts(fromVerify) {
      if (!this.selectedPricing) return;
      if (fromVerify) this.filters = null;
      this.isLoading = true;
      this.$store.commit("saveProductsIA", []);
      this.$store.commit("setLoading", true);

      let request = this.buildRequest(this.selectedPricing, this.filters);

      try {
        let response = await Service.getProductList(request);

        this.$store.commit("saveProductsIA", response?.data?.result ?? []);
        this.$store.commit(
          "setIdPriceGroup",
          this.selectedPricing?.idEnterprisePriceGroups ?? null
        );
        this.$store.commit("setFirstSearch", false);
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_FAIL_SEARCH_PRODUCT"),
          type: "error",
        });
      }

      this.isLoading = false;
      this.$store.commit("setLoading", false);
    },

    async calculateRules() {
      this.calculateRulesLoading = true;

      try {
        await Service.calculateRules(
          this.idCompany,
          this.selectedPricing.idEnterprisePriceGroups
        );
        this.listProducts();
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_CALCULATE_PRODUCTS_ERROR"),
          type: "error",
        });
      }

      this.calculateRulesLoading = false;
    },

    buildFilter(selectedFilter) {
      return [
        {
          idEnterprisePriceGroupDefaultFilter:
            this.preFilters[0].idEnterprisePriceGroupDefaultFilter,
          ...selectedFilter,
        },
      ];
    },

    verifyPricing(pricing) {
      this.selectedPricing = pricing;

      if (!this.selectedPricing || typeof this.selectedPricing === "string")
        return;

      this.filters = null;

      if (this.selectedPricing.isCalculating) {
        this.$refs.createPricingModal.openLoadingModal(this.selectedPricing);
      } else if (!this.selectedPricing.calcDate) {
        this.$refs.createPricingModal.openModal(this.selectedPricing);
      } else {
        this.$store.commit("cleanSemaphore");
        this.listProducts(true);
      }
    },

    recalculatePricing() {
      this.$refs.createPricingModal.openModal(this.selectedPricing, true);
      this.controlRecalculateModal = false;
    },

    buildRequest(selectedPricing, filters) {
      return {
        filter: filters || [],
        idCompany: this.idCompany,
        idEnterprisePriceGroups: selectedPricing.idEnterprisePriceGroups,
        ListIdEnterprisePricesProjection:
          selectedPricing.listIdEnterprisePricesProjection || [],
        isSimulation: false,
        order: this.order,
        page: this.currentPage,
        recordsPerPage: this.itemsPerPage,
      };
    },

    verifyPricingFromHome() {
      let pricingFromHome = this.$route.params.data || null;

      if (pricingFromHome) {
        this.selectedPricing = {
          idEnterprisePriceGroups: pricingFromHome.idEnterprisePriceGroup,
          listIdEnterprisePricesProjection:
            pricingFromHome.listIdEnterprisePricesProjection,
        };

        this.listProducts();
      }
    },
  },

  created() {
    this.verifyPricingFromHome();
    this.getPreFilters();
  },
};
</script>

<style lang="scss" scoped>
.main-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
  }
}
</style>
