export default {
  state: () => ({
    snackbarText: "",
    type: "info",
  }),
  mutations: {
    ['snackbar'](state, newText) {
      state.snackbarText = newText;
      state.type = 'info';
    },
    ['snackbar-error'](state, newText) {
      state.snackbarText = newText;
      state.type = 'error';
    },
    ['snackbar-warning'](state, newText) {
      state.snackbarText = newText;
      state.type = 'warning';
    },
    ['snackbar-success'](state, newText) {
      state.snackbarText = newText;
      state.type = 'success';
    },
  },
};
