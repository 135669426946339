<template>
  <div class="copilot pa-6 pb-2 d-flex flex-column">

    <Copilot-overlay />

    <Copilot-header />

    <div class="chat-content d-flex flex-column flex-fill">
      <div ref="textDiscussion" class="text-discussion pr-2">
        <Copilot-hello v-if="!socketConnectionLoading" />

        <Copilot-messages @messagesUpdated="scrollToBottom" />
      </div>

      <Copilot-text-area v-if="!socketConnectionLoading" />
    </div>

    <Copilot-available-prompts />

    <Copilot-api-response />
  </div>
</template>

<script>
import CopilotApiResponse from './components/copilot-api-response.vue';
import CopilotHeader from "./components/copilot-header.vue";
import CopilotHello from "./components/copilot-hello.vue";
import CopilotMessages from "./components/copilot-messages.vue";
import CopilotOverlay from "./components/copilot-overlay.vue";
import CopilotTextArea from "./components/copilot-text-area.vue";
import CopilotAvailablePrompts from "./components/copilot-available-prompts.vue";

export default {
  components: {
    CopilotHello,
    CopilotTextArea,
    CopilotMessages,
    CopilotHeader,
    CopilotOverlay,
    CopilotApiResponse,
    CopilotAvailablePrompts
  },

  computed: {
    socketConnectionLoading() {
      return this.$store?.state?.copilotStore?.socketConnectionLoading ?? false;
    },
  },

  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.textDiscussion;
        if (container) {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: "smooth",
          });
        }
      });
    },
  },

  created() {
    this.scrollToBottom();
  },

  beforeDestroy() {
    this.$store.commit("TOGGLE_COPILOT_MODAL", false);
  },
};
</script>


<style lang="scss" scoped>
.copilot {
  background-color: #fff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  width: 40%;
  height: 80vh;

  .chat-content {
    display: flex;
    flex-direction: column;
    height: 80%;
  }

  .text-discussion {
    flex: 1;
    overflow-y: scroll;
  }

  .text-discussion::-webkit-scrollbar {
    width: 6px;
  }

  .text-discussion::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  .text-discussion::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  .text-discussion::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .text-discussion::-webkit-scrollbar-thumb:active {
    background: #333;
  }
}
</style>