const brazilianStates = [
  { "name": "Acre", "abbreviation": "AC", "lat": -9.0238, "lng": -70.8120 },
  { "name": "Alagoas", "abbreviation": "AL", "lat": -9.5713, "lng": -36.7820 },
  { "name": "Amapá", "abbreviation": "AP", "lat": 0.9020, "lng": -52.0030 },
  { "name": "Amazonas", "abbreviation": "AM", "lat": -3.4168, "lng": -65.8561 },
  { "name": "Bahia", "abbreviation": "BA", "lat": -12.5797, "lng": -41.7007 },
  { "name": "Ceará", "abbreviation": "CE", "lat": -5.4984, "lng": -39.3206 },
  { "name": "Distrito Federal", "abbreviation": "DF", "lat": -15.7939, "lng": -47.8828 },
  { "name": "Espírito Santo", "abbreviation": "ES", "lat": -19.1834, "lng": -40.3089 },
  { "name": "Goiás", "abbreviation": "GO", "lat": -15.8270, "lng": -49.8362 },
  { "name": "Maranhão", "abbreviation": "MA", "lat": -4.9609, "lng": -45.2744 },
  { "name": "Mato Grosso", "abbreviation": "MT", "lat": -12.6819, "lng": -56.9211 },
  { "name": "Mato Grosso do Sul", "abbreviation": "MS", "lat": -20.7722, "lng": -54.7852 },
  { "name": "Minas Gerais", "abbreviation": "MG", "lat": -18.5122, "lng": -44.5550 },
  { "name": "Pará", "abbreviation": "PA", "lat": -3.4168, "lng": -52.2468 },
  { "name": "Paraíba", "abbreviation": "PB", "lat": -7.2399, "lng": -36.7819 },
  { "name": "Paraná", "abbreviation": "PR", "lat": -24.8916, "lng": -51.6909 },
  { "name": "Pernambuco", "abbreviation": "PE", "lat": -8.8137, "lng": -36.9541 },
  { "name": "Piauí", "abbreviation": "PI", "lat": -7.7183, "lng": -42.7289 },
  { "name": "Rio de Janeiro", "abbreviation": "RJ", "lat": -22.9099, "lng": -43.2095 },
  { "name": "Rio Grande do Norte", "abbreviation": "RN", "lat": -5.7945, "lng": -36.3209 },
  { "name": "Rio Grande do Sul", "abbreviation": "RS", "lat": -30.0346, "lng": -51.2177 },
  { "name": "Rondônia", "abbreviation": "RO", "lat": -10.8253, "lng": -63.3374 },
  { "name": "Roraima", "abbreviation": "RR", "lat": 2.7376, "lng": -62.0751 },
  { "name": "Santa Catarina", "abbreviation": "SC", "lat": -27.2423, "lng": -50.2189 },
  { "name": "São Paulo", "abbreviation": "SP", "lat": -23.5505, "lng": -46.6333 },
  { "name": "Sergipe", "abbreviation": "SE", "lat": -10.5741, "lng": -37.3857 },
  { "name": "Tocantins", "abbreviation": "TO", "lat": -10.1753, "lng": -48.2982 }
]

export const getBrazilianStateData = (term) => {
  if (!term) return
  if (term.length > 2) return brazilianStates.find(state => state.name == term)
  else return brazilianStates.find(state => state.abbreviation == term)
}