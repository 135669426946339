<template>
  <v-card class="pa-4" id="currentPrice" :disabled="simulationLoading" flat>
    <v-row no-gutters align="center" justify="end" class="mb-4">
      <Prd-btn
        :title="'Simular'"
        :disabled="isLoading || simulationLoading"
        :loading="simulationLoading"
        @click="runSimulation"
        outlined
        class="mr-4"
      />
      <Prd-btn
        :title="'Exportar'"
        :icon="'mdi-download-outline'"
        :smallIcon="true"
        :leftIcon="true"
        :disabled="
          isLoading || !acelenSimulationList.length || simulationLoading
        "
        @click="downloadData(acelenSimulationList, 'simulação')"
      />
    </v-row>
    <v-data-table
      :headers="headers"
      :items="acelenSimulationList"
      :loading="isLoading"
      :footer-props="footerProps"
      :disabled="simulationLoading"
      :loading-text="'Carregando dados'"
      :no-data-text="$t('TXT_NO_DATA_FOUND_DISPLAY')"
      :no-results-text="$t('TXT_NO_DATA_FOUND_DISPLAY')"
      :items-per-page="10"
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ momentFormatDate(item.date, false) }}
      </template>

      <template v-slot:[`item.price`]="{ item }">
        {{ formatMonetary(item.price) }}
      </template>

      <template v-slot:[`item.lowerLimit`]="{ item }">
        {{ formatMonetary(item.lowerLimit) }}
      </template>

      <template v-slot:[`item.upperLimit`]="{ item }">
        {{ formatMonetary(item.upperLimit) }}
      </template>

      <template v-slot:[`item.variation`]="{ item }">
        {{ formatPercentage(item.variation) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { momentFormatDate } from "@/utils/prd-format-date";
import { formatMonetary } from "@/utils/format-toMonetary";
import { formatPercentage } from "@/utils/format-percentage";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import { downloadData } from "@/utils/downloadData.js";
import acelenService from "@/service/acelen/index";
const Service = new acelenService();

export default {
  components: { PrdBtn },
  props: ["acelenList"],
  data() {
    return {
      headers: [
        {
          text: "Data",
          align: "center",
          value: "date",
        },
        {
          text: "Combustivel",
          align: "center",
          value: "fuel",
        },
        {
          text: "Mobilidade",
          align: "center",
          value: "modality",
        },
        {
          text: "Local",
          align: "center",
          value: "local",
        },
        {
          text: "Valor predito (R$)",
          align: "center",
          value: "price",
        },
        {
          text: "Limite inferior (R$)",
          align: "center",
          value: "lowerLimit",
        },
        {
          text: "Limite superior (R$)",
          align: "center",
          value: "upperLimit",
        },
        {
          text: "Variação em relação ao preço vigente (%)",
          align: "center",
          value: "variation",
        },
      ],

      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },

      acelenSimulationList: [],
      isLoading: false,
      simulationLoading: false,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    momentFormatDate,
    formatMonetary,
    formatPercentage,
    downloadData,

    async getAcelenListSimulation() {
      this.isLoading = true;

      try {
        let response = await Service.getListAcelenSimulation();
        this.acelenSimulationList = response?.data ?? [];
        this.insertVariation();
        this.$emit("onListChange", this.acelenSimulationList);
      } catch (error) {
        this.$handleError(error, "Erro ao buscar tabela de simulação");
      }

      this.isLoading = false;
    },

    insertVariation() {
      if (this.acelenSimulationList == null || this.acelenList == null) return;

      for (let i of this.acelenSimulationList) {
        let currentPrice = this.acelenList.find((x) => x.fuel === i.fuel);
        if (currentPrice == null) continue;

        i.variation =
          ((i.price - currentPrice.price) / currentPrice.price) * 100;
      }
    },

    async runSimulation() {
      this.simulationLoading = true;

      try {
        await Service.getSimulation();
        this.getAcelenListSimulation()
      } catch (error) {
        this.$handleError(error, "Erro ao gerar simulação!");
      }

      this.simulationLoading = false;
    },
  },

  created() {
    this.getAcelenListSimulation();
  },
};
</script>
