<template>
  <v-card
    class="pa-4 pt-0 mt-4"
    flat
    :loading="isLoading"
    :disabled="isLoading || isDisable"
  >
    <h4 class="mt-2 pt-4">{{ this.$i18n.t("TXT_RESULT") }}</h4>
    <v-tabs class="mb-4" background-color="transparente">
      <v-row>
        <v-tab
          v-for="tab in tabList"
          :key="tab.id"
          @click="selectedTab = tab.id"
          class="ml-3"
          style="
            justify-content: flex-start;
            font-size: 16px !important;
            text-transform: capitalize;
          "
        >
          {{ tab.title }}
        </v-tab>
      </v-row>
    </v-tabs>
    <mix-products-table v-show="selectedTab == 1" :dataTable="itemsMixTable" />
    <frequency-table
      v-show="selectedTab == 2"
      :dataTable="itemsFrequencyTable"
    />
  </v-card>
</template>

<script>
import mixProductsTable from "./components/mix-products-table.vue";
import frequencyTable from "./components/frequency-table.vue";
import mixProductsAprioriService from "@/service/mix-product-apriori/mix-product-apriori-service";
export default {
  components: { mixProductsTable, frequencyTable },
  props: ["dataSimulation"],
  data() {
    return {
      service: new mixProductsAprioriService(),
      isLoading: false,
      isDisable: true,
      selectedTab: 1,
      tabList: [
        {
          id: 1,
          title: this.$i18n.t("TXT_PRODUCT_MIX"),
        },
        {
          id: 2,
          title: this.$i18n.t("TXT_FREQUENCY"),
        },
      ],
      itemsMixTable: null,
      itemsFrequencyTable: null,
    };
  },
  methods: {
    savedSimulationData() {
      this.$emit("showButtonSaveSimulation", false);
      this.service
        .getSavedSimulation(
          this.dataSimulation.idCompany,
          this.dataSimulation.idEnterpriseResultGroup
        )
        .then((response) => {
          if (response.status !== 500) {
            if (response.data.answer) {
              if (response.data.answer.mixs !== null) {
                this.itemsMixTable = response.data.answer.mixs;
                this.isDisable = false;
              } else {
                this.$store.commit("snackbarV2/set", {
                  message: `${this.$i18n.t(
                    "TXT_NO_DATA_FOUND_TABLE"
                  )} : ${this.$i18n.t("TXT_PRODUCT_MIX")}`,
                  type: "info",
                });
              }

              if (response.data.answer.frequencies !== null) {
                this.itemsFrequencyTable = response.data.answer.frequencies;
                this.isDisable = false;
              } else {
                this.$store.commit("snackbarV2/set", {
                  message: `${this.$i18n.t(
                    "TXT_NO_DATA_FOUND_TABLE"
                  )} : ${this.$i18n.t("TXT_FREQUENCY")}.`,
                  type: "info",
                });
              }
            } else {
              this.$store.commit("snackbarV2/set", {
                message: this.$i18n.t("TXT_NO_DATA_FOUND"),
                type: "info",
              });
            }
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.isDisable = true;
        });
    },

    newSimulationData() {
      this.$emit("setLoading", true);
      delete this.dataSimulation.type;
      this.service
        .getNewSimulation(this.dataSimulation)
        .then((response) => {
          if (response.status !== 500) {
            if (response.data.result) {
              this.$emit("showButtonSaveSimulation", true);
              this.$emit(
                "getIdEnterpriseAprioriResultGroup",
                response.data.result.idEnterpriseAprioriResultGroup
              );
              if (
                response.data.result.mixs !== null &&
                response.data.result.mixs.length > 0
              ) {
                this.itemsMixTable = response.data.result.mixs;
                this.isDisable = false;
                this.$emit("showButtonSaveSimulation", true);
              } else {
                this.$store.commit("snackbarV2/set", {
                  message: `${this.$i18n.t(
                    "TXT_NO_DATA_FOUND_TABLE"
                  )} : ${this.$i18n.t("TXT_PRODUCT_MIX")}.`,
                  type: "info",
                });
              }

              if (
                response.data.result.frequencies !== null &&
                response.data.result.frequencies.length > 0
              ) {
                this.itemsFrequencyTable = response.data.result.frequencies;
                this.isDisable = false;
              } else {
                this.$store.commit("snackbarV2/set", {
                  message: `${this.$i18n.t(
                    "TXT_NO_DATA_FOUND_TABLE"
                  )} : ${this.$i18n.t("TXT_FREQUENCY")}.`,
                  type: "info",
                });
              }
            } else {
              this.$store.commit("snackbarV2/set", {
                message: this.$i18n.t("TXT_NO_DATA_FOUND"),
                type: "info",
              });
            }
          }
          this.isLoading = false;
          this.$emit("setLoading", false);
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_ERROR"),
            type: "error",
          });
          this.isLoading = false;
          this.isDisable = this.isLoading;
          this.$emit("setLoading", false);
        });
    },

    getSimulationData() {
      this.itemsMixTable = null;
      this.itemsFrequencyTable = null;
      this.isLoading = true;
      this.isDisable = this.isLoading;

      if (this.dataSimulation) {
        if (this.dataSimulation.type === "newSimulation") {
          this.newSimulationData();
        } else if (this.dataSimulation.type === "savedSimulation") {
          this.savedSimulationData();
        }
      } else {
        this.isLoading = false;
        this.$emit("showButtonSaveSimulation", false);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_PROBLEM_DATA_SIMULATION"),
          type: "error",
        });
      }
    },
  },
  watch: {
    dataSimulation() {
      this.getSimulationData();
    },
  },
};
</script>
