<template>
  <v-card
    class="pa-4 pt-0"
    flat
    :disabled="isDisable || isLoading"
    :loading="isLoading"
  >
    <v-row no-gutters class="mb-4 pt-4">
      <h4>{{ this.$i18n.t("TXT_BUSINESS") }}</h4>
      <tooltip-informations :title="'tooltip'" :rightIcon="true" />
    </v-row>

    <v-row no-gutters>
      <prd-search
        @input="searchTerm = $event"
        :searchLabel="$t('TXT_SEARCH_BY_CODE_COMPANY')"
        :useDelay="false"
      />
    </v-row>

    <div class="mt-4 company-list" v-if="companyList.length > 0">
      <div
        v-for="(company, index) in companyList"
        :key="index"
        :class="
          selectedIndex == null
            ? 'company-item pa-4'
            : selectedIndex == index
            ? 'company-item pa-4 active'
            : 'company-item pa-4 desactive'
        "
      >
        <span
          >{{ company.idEmpresa }} -
          {{ company.nomeRazaoSocial.toUpperCase() }}</span
        >
        <v-icon
          @click="configCrawler(company, index)"
          :class="
            selectedIndex == null || index == selectedIndex
              ? 'active-icon'
              : 'desactive-icon'
          "
          >mdi-arrow-right-bold-box</v-icon
        >
      </div>
    </div>
  </v-card>
</template>

<script>
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";
import PrdSearch from "@/Design_System/common/prd-search.vue";
import service from "@/service/crawlers/crawler-management.js";
const Service = new service();
export default {
  components: { TooltipInformations, PrdSearch },
  props: {
    isDisableFirstCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDisable: false,
      isLoading: false,
      companyList: [],
      originalItems: [],
      searchTerm: "",
      selectedIndex: null,
    };
  },
  watch: {
    searchTerm: {
      handler(newTerm) {
        this.companyList = this.search(newTerm);
      },
      immediate: true,
    },

    isDisableFirstCard: {
      handler(newValue) {
        this.isDisable = newValue;
        if (!newValue) {
          this.selectedIndex = null;
        }
      },
    },
  },

  methods: {
    configCrawler(company, index) {
      this.isLoading = true;
      this.selectedIndex = index;
      this.getLinkedCrawlers(company);
      this.isDisable = true;
      this.$emit("invertCard", true);
      this.isLoading = false;
    },

    search(term) {
      this.isLoading = true;
      if (!term || term == null || term == "") {
        this.isLoading = false;
        return this.originalItems;
      } else {
        this.isLoading = false;

        return this.companyList.filter((item) => {
          if (
            String(item["idEmpresa"])
              .toLowerCase()
              .includes(term.toLowerCase()) ||
            String(item["nomeRazaoSocial"])
              .toLowerCase()
              .includes(term.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        });
      }
    },

    getCompanys() {
      this.isLoading = true;
      Service.getCompanyList()
        .then((res) => {
          if (res.status != 500) {
            res.data.forEach((element) => {
              let newElement = {
                idEmpresa: element.idEmpresa,
                nomeRazaoSocial: element.nomeRazaoSocial,
              };
              this.originalItems.push(newElement);
            });
            this.companyList = this.originalItems;
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;

          console.log("Erro ao buscar companhias", error);
        });
    },

    getLinkedCrawlers(company) {
      this.isLoading = true;
      Service.getCrawlerByCompany(company.idEmpresa)
        .then((res) => {
          if (res.status != 500) {
            company.crawlerLinked = res.data.result.crawlers;
            this.$emit("dataCrawlersLinked", company);
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log("Erro ao buscar crawlers por empresa", error);
        });
    },
  },

  created() {
    this.getCompanys();
  },
};
</script>

<style lang="scss" scoped>
.company-list {
  max-height: 300px !important;
  overflow: auto;
}

.company-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $neutral-color-high-light;
  color: black;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
}
.desactive {
  color: $neutral-color-low-light !important;
}
.active {
  background-color: $neutral-color-low-light !important;
  color: black !important;
}

.desactive-icon {
  color: $neutral-color-low-light;
}
.active-icon {
  color: $brand-color-primary-pure;
}
</style>
        