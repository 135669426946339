
import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class ResourceGroupManagement extends ServiceBase {
    async getGroups(idCompany) {
        return await axiosInstance.get(API_URL + `api/V2/Access/ResourceGroup/List?idCompany=${idCompany}`);
    }

    async getResources(idCompany) {
        return await axiosInstance.get(API_URL + `api/V2/Access/Resource/ListOfCompany?idCompany=${idCompany}`);
    }

    async updateGroup(request) {
        /**
         * @param {number} idCompany
         * @param {number} idResourceGroup - 0 New resource 
         * @param {string} name 
         * @param {string} description 
         * @param {array} resources  - array of numbers
         */
        return await axiosInstance.put(API_URL + "api/V2/Access/ResourceGroup", request);
    }

    async deleteGroup(idCompany, idResourceGroup) {
        return await axiosInstance.delete(API_URL + `api/V2/Access/ResourceGroup?idCompany=${idCompany}&idResourceGroup=${idResourceGroup}`);
    }
}
