<template>
  <div class="my-4">
    <v-row no-gutters class="gap-4">
      <v-col cols="12" sm="4">
        <Prd-combo-box
          v-model="orignalSelectedProduct"
          :title="$t('TXT_PRODUCT')"
          :placeholder="$t('TXT_SELECT')"
          :items="productsUPL"
        />
      </v-col>
      <v-col cols="12" sm="2">
        <Prd-text-field
          v-model="selectedProduct.preco"
          :disabled="!selectedProduct.id"
          :title="$t('TXT_PRICE')"
          :prefix="currency"
          :type="'number'"
          :min="0"
        />
      </v-col>
      <v-col cols="12" sm="2">
        <Prd-text-field
          v-model="selectedProduct.dose"
          :disabled="!selectedProduct.id"
          :title="$t('TXT_DOSE')"
          :type="'number'"
          :min="0"
          :max="100"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import PrdTextField from "@/components/common/prd-text-field.vue";
export default {
  components: { PrdComboBox, PrdTextField },
  props: { productsUPL: { type: Array }, captureData: { type: Object } },
  data() {
    return {
      selectedProduct: { id: null, preco: 0, dose: 0 },
      orignalSelectedProduct: null,
      isFirstTime: true,
    };
  },
  computed: {
    currency() {
      return localStorage.getItem("currency") || "R$";
    },
  },
  watch: {
    orignalSelectedProduct: {
      handler(value) {
        this.setSelectedProduct(value);
      },
    },
    selectedProduct: {
      handler(value) {
        this.$emit("setUplCapData", value);
      },
      deep: true,
    },
    productsUPL: {
      handler(value) {
        if (value.length > 0)
          this.orignalSelectedProduct = this.productsUPL.find(
            (product) => product.id == this.captureData.idProdutoUPL
          );
      },
      immediate: true,
      deep: true,
    },
    captureData: {
      handler(value) {
        this.selectedProduct.id = value.idProdutoUPL;
        this.selectedProduct.preco = value.precoProdutoUPL;
        this.selectedProduct.dose = value.doseProdutoUPL;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setSelectedProduct(data) {
      if (data) {
        const original = JSON.parse(JSON.stringify(data));
        this.selectedProduct.id = original.id;
        if (!this.isFirstTime) {
          this.selectedProduct.preco = original.precoQuilo;
          this.selectedProduct.dose = original.dose;
        } else {
          this.isFirstTime = false;
        }
      }
    },
  },
};
</script>