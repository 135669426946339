<template>
  <v-row no-gutters justify="end" class="mt-4">
    <Prd-btn
      :outlined="true"
      :title="outlinedTitle"
      :disabled="isDisable"
      :color="outlinedColor"
      @click="$emit('clickOutlined', $event)"
      class="mr-4"
    />
    <Prd-btn
      :title="title"
      :disabled="isDisable"
      :loading="loading"
      :color="color"
      @click="$emit('click', $event)"
    />
  </v-row>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: "Salvar",
    },

    outlinedTitle: {
      type: String,
      required: true,
      default: "Cancelar",
    },
    outlinedColor: {
      type: String,
    },
    color: {
      type: String,
    },
    icon:{
      type: String
    },
    isDisable: {
      type: Boolean,
      required: false,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { PrdBtn },

};
</script>

<style>
</style>