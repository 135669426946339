import axiosInstance, { axiosRaw, API_URL } from "@/configs/axiosConfig";
import ServiceBase from "@/service/service-base";
import LocalDataService from "./local-data-service";
const localDataService = new LocalDataService();

const urlGetUser = API_URL + "api/Usuario/GetUsuario";

export default class UserService extends ServiceBase {
  async getUsuario(email) {
    const res = await axiosRaw
      .get(urlGetUser, { params: { email: email } })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    return res;
  }

  async setCurrentUser(usuario) {
    localDataService.set("userData", usuario);
    return usuario;
  }

  async getCurrentUser() {
    return localDataService.get("userData");
  }

  async getAssignedUserToCompany(data) {
    return await fetch(`http://localhost:3000/users?idCompany=${data}`);
  }

  async assignUserToCompany(data) {
    const user = JSON.stringify(data);
    return await fetch("http://localhost:3000/users", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: user,
    });
  }

  async updateUserAssignedToCompany(data) {
    const user = JSON.stringify(data);
    return await fetch(`http://localhost:3000/users/${data.id}`, {
      method: "PUT",
      headers: { "Content-type": "application/json" },
      body: user,
    });
  }

  async deleteUserAssigned(data) {
    return await fetch(`http://localhost:3000/users/${data}`, {
      method: "DELETE",
    });
  }

  async searchAssignedUser(data) {
    return await fetch(
      `http://localhost:3000/users?name_like=${data.search}&idCompany=${data.idCompany}`
    );
  }

  async getResourcesGroupById(idGroup, idCompany) {
    return await fetch(
      `http://localhost:3000/users?groups.id?q=${idGroup}&idCompany=${idCompany}`
    );
  }

  async getAllUsers() {
    return await axiosInstance.get(API_URL + "api/Usuario/ListUsers");
  }

  async SetAdminRole(request) {
    return await axiosInstance.post(API_URL + "api/Usuario/SetAdminRole", request);
  }

  async RemoveAdminRole(request) {
    return await axiosInstance.post(API_URL + "api/Usuario/RemoveAdminRole", request);
  }

}
