<template>
  <v-card class="pa-4">
    <filter-list
      class="mb-4"
      :initialList="list"
      @filteredList="filteredList = $event"
    />
    <div class="product-container pr-4">
      <v-row v-show="isLoading" no-gutters justify="center">
        <v-progress-circular
          :color="$prdStyles('color-primary')"
          indeterminate
        ></v-progress-circular>
      </v-row>
      <product-comparision-card
        v-show="!isLoading"
        v-for="(product, index) in filteredList"
        :key="index"
        :product="product"
      />
    </div>
  </v-card>
</template>

<script>
import FilterList from "./components/filter-list.vue";
import productComparisionCard from "./components/product-comparision-card.vue";
export default {
  props: ["list", "isLoading"],
  components: { productComparisionCard, FilterList },
  data() {
    return {
      filteredList: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.product-container {
  //   background-color: #3c5ca763;
  max-height: 235px;
  overflow: hidden;
  overflow-x: none;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    // border: 1px solid $brand-color-primary-pure;
    background-color: $neutral-color-high-medium;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $brand-color-primary-pure;
    border-radius: 15px;
    border: 1px solid $brand-color-primary-medium;
  }
}
</style>