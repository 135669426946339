<template>
  <v-card class="pa-4">
    <v-row
      v-if="infoProjectionLoading"
      class="mt-10"
      no-gutters
      justify="center"
    >
      <v-progress-circular
        indeterminate
        :color="$prdStyles('color-primary')"
      ></v-progress-circular>
    </v-row>

    <div
      v-else
      v-for="(info, index) in infoProjection"
      :key="index"
      class="my-flex"
    >
      <span>{{ info.propertyName }}</span>
      <span class="font-weight-bold">{{
        setProperty(info.propertyName, info.value)
      }}</span>
    </div>
  </v-card>
</template>

<script>
import { setCurrencyFujioka } from "@/utils/format-currency-fujioka.js";

export default {
  props: ["infoProjection", "infoProjectionLoading"],
  data() {
    return {};
  },

  methods: {
    setCurrencyFujioka,
    setProperty(property, value) {
      if (property == "Média Gerencial") {
        if (!value) return "-";

        var stringToValue = value.replace(",", ".");

        var toDecimals = parseFloat(stringToValue);

        return this.setCurrencyFujioka(toDecimals);
      } else {
        return value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

span {
  font-size: 12px;
}
</style>