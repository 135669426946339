<template>
  <v-container fluid class="mt-6 pa-0">
    <div class="my-flex">
      <v-tabs
        color="black"
        slider-color="#3c5ca7"
        background-color="transparent"
        class="group-filters mb-4 mr-4"
        height="25px"
      >
        <v-tab>
          <h4>{{ $t("TXT_MARGIN_CHART").toUpperCase() }}</h4>
        </v-tab>
      </v-tabs>
    </div>
    <v-row no-gutters justify="center" align="center" v-if="isLoading">
      <v-progress-circular indeterminate color="#3C5CA7" size="50" />
    </v-row>
    <v-card v-else class="pa-4 mb-4" elevation="4" v-show="idStore">
      <chart-table-select
        class="mb-4"
        @tab="tab = $event"
        :allData="allData"
        :allMargins="allMargins"
        @filteredItems="filteredItems = $event"
        @superiorLimit="superiorLimit = $event"
        @inferiorLimit="inferiorLimit = $event"
      />
      <scatter-plot-chart
        v-show="tab == 0"
        :allData="filteredItems"
        :inferiorLimit="inferiorLimit"
        :superiorLimit="superiorLimit"
      />
      <product-table-info
        v-show="tab == 1"
        :allData="filteredItems"
        :inferiorLimit="inferiorLimit"
        :superiorLimit="superiorLimit"
      />
    </v-card>
  </v-container>
</template>

<script>
import ChartTableSelect from "./components/chart-table-select.vue";
import ScatterPlotChart from "./components/scatter-plot-chart.vue";
import ProductTableInfo from "./components/product-table-info.vue";
import ServiceHome20 from "@/service/home20/index";

export default {
  props: ["idStore"],
  components: {
    ChartTableSelect,
    ScatterPlotChart,
    ProductTableInfo,
  },
  data() {
    return {
      tab: 0,
      service: new ServiceHome20(),
      allData: null,
      allMargins: null,
      filteredItems: null,
      isLoading: false,
      superiorLimit: null,
      inferiorLimit: null,
    };
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },
  methods: {
    async getAllDatas(idStore) {
      this.isLoading = true;
      await this.getMarginByIdCompany();
      await this.getData(idStore);
      this.isLoading = false;
    },
    async getMarginByIdCompany() {
      await this.service
        .GetMarginByIdCompany(this.idCompany)
        .then((res) => {
          this.allMargins = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getData(idStore) {
      await this.service
        .getDataScatterPlot(idStore)
        .then((res) => {
          this.allData = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    idStore: {
      handler(value) {
        this.getAllDatas(value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.my-flex {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.group-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.281);
  display: flex;
}
</style>
