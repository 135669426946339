<template>
  <v-card flat class="pa-4">
    <v-row no-gutters justify="space-between" class="mb-6">
      <h3>{{ $t("TXT_UPDATE_HISTORY") }}</h3>
      <v-icon :color="$prdStyles('color-warning')" @click="$emit('closeModal')"
        >mdi-close</v-icon
      >
    </v-row>
    <Date-select
      class="mb-6"
      @dateSelection="$emit('dateSelection', $event)"
      :isOpen="isOpen"
    />
    <div
      style="max-height: 350px; overflow: hidden; overflow-y: scroll"
      class="pr-2"
    >
      <Updated-card
        v-for="(data, index) in filteredList"
        :key="index"
        :data="data"
      />
      <p v-show="filteredList.length == 0">
        {{ $t("TXT_NO_HAVE_UPDATES_ALERT") }}
      </p>
    </div>
  </v-card>
</template>

<script>
import DateSelect from "./components/date-select.vue";
import UpdatedCard from "./components/updated-card.vue";
export default {
  components: { DateSelect, UpdatedCard },
  props: {
    filteredList: {
      type: Array,
    },
    isOpen: {
      type: Boolean,
    },
  },
};
</script>