<template>
  <div>
    <v-expansion-panel-header hide-actions class="pa-0 pointer-none">
      <div class="main-header">
        <div>
          <span class="font-weight-bold">{{ product.descricao }}</span> |
          <span
            ><span class="font-weight-bold">{{ $t("TXT_SELLER") }}:</span>
            {{ product.seller }}</span
          >
          |
          <span
            ><span class="font-weight-bold">{{ $t("TXT_PRICE") }}:</span>
            {{ formatMonetary(product.price) }}</span
          >
        </div>

        <div>
          <v-icon :color="$prdStyles('color-primary')" class="pointer-all"
            >mdi-pencil-box</v-icon
          >
          <v-icon
            @click.stop="deleteItem"
            :color="$prdStyles('color-warning')"
            class="pointer-all"
            >mdi-delete-outline</v-icon
          >
        </div>
      </div>
    </v-expansion-panel-header>

    <v-expansion-panel-content id="manual-product-table" class="pa-0">
      <v-card class="pa-4" id="add-product-manual">
        <v-tabs
          color="black"
          slider-color="#EC4C37"
          background-color="transparent"
          height="25px"
          class="pa-0"
        >
          <v-tab>
            <span class="font-weight-bold pa-0">{{
              $t("TXT_EDIT_PRODUCT_2")
            }}</span>
          </v-tab>
          <v-tab> </v-tab>
        </v-tabs>
        <!-- PRODUCT NAME -->
        <v-row no-gutters class="mt-6 mb-4">
          <v-col>
            <label>{{ $t("TXT_PRODUCT_NAME") }}</label>
            <v-text-field
              v-model="productObj.descricao"
              outlined
              dense
              hide-details
              class="mr-4"
            ></v-text-field>
          </v-col>
          <v-col>
            <label>{{ $t("TXT_PRODUCT_LINK") }}</label>
            <v-text-field
              v-model="productObj.productLink"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <div style="display: flex; gap: 1rem" class="mb-6">
          <!-- SELLER-->
          <div>
            <label>{{ $t("TXT_SELLER") }}</label>
            <v-text-field
              v-model="productObj.seller"
              outlined
              dense
              hide-details
            ></v-text-field>
          </div>
          <!-- PRICE -->
          <div>
            <label>{{ $t("TXT_PRICE") }}</label>
            <v-text-field
              v-model="productObj.price"
              prefix="R$"
              placeholder="Ex: 28,44"
              type="number"
              hide-spin-buttons
              outlined
              dense
              hide-details
            ></v-text-field>
          </div>
          <!-- INSTALLSMENT -->
          <div>
            <label>{{ $t("TXT_INSTALLMENTS") }}</label>
            <v-text-field
              v-model="productObj.installment"
              type="number"
              hide-spin-buttons
              outlined
              dense
              hide-details
            ></v-text-field>
          </div>
          <!-- DATA PICKER -->
          <div>
            <label>{{ $t("TXT_COLECT_DATE") }}</label>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              ref="menu"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="productObj.refDate"
                  v-bind="attrs"
                  v-on="on"
                  :label="$t('TXT_SELECT_PERIOD')"
                  :placeholder="$t('TXT_SELECT_PERIOD')"
                  hide-details
                  outlined
                  single-line
                  dense
                  readonly
                  class="input-datapicker"
                >
                  <template v-slot:append>
                    <v-icon class="append-calendar" color="white" small>
                      mdi-calendar</v-icon
                    >
                  </template>
                </v-text-field>
              </template>
              <!-- TODO - properly use user's locale -->
              <v-date-picker
                v-model="productObj.refDate"
                no-title
                scrollable
                :locale="localeForDatePicker"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="error"
                  @click="menu = false"
                  v-t="'TXT_CANCEL'"
                ></v-btn>
                <v-btn
                  color="primary"
                  @click="$refs.menu.save(productObj.refDate)"
                  v-t="'TXT_OK'"
                ></v-btn>
              </v-date-picker>
            </v-menu>
          </div>
        </div>
        <v-row no-gutters align="center" justify="end">
          <prd-btn
            :title="$t('TXT_CANCEL')"
            @click="cancelChanges"
            :outlined="true"
            class="mr-4"
          />
          <prd-btn
            :title="$t('TXT_SAVE_CHANGES')"
            :disabled="
              !productObj.descricao ||
              !productObj.productLink ||
              !productObj.seller ||
              !productObj.price ||
              productObj.installment < 1
            "
            @click="updateProduct"
          />
        </v-row>
      </v-card>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import { formatMonetary } from "@/utils/format-toMonetary.js";

export default {
  props: ["product", "idMonitoringItem"],
  components: { prdBtn },
  data() {
    return {
      menu: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      productObj: null,
      isPanelOpen: false,
    };
  },
  computed: {
    localeForDatePicker() {
      return localStorage.getItem("lang") ?? "pt-BR";
    },
  },

  watch: {
    product: {
      handler(newValue) {
        this.productObj = { ...newValue };
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    formatMonetary,

    updateProduct() {
      this.$emit("updateProduct", this.productObj, false);
    },

    deleteItem() {
      this.$emit("deleteItem", this.product, true);
      this.$emit("cancelChanges");
    },

    openPanel() {
      this.isPanelOpen = true;
    },

    cancelChanges() {
      this.productObj = { ...this.product };
      this.$emit("cancelChanges");
    },
  },
};
</script>

<style lang="scss" scoped>
.append-pencil {
  background: #3c5ca7;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.append-minus {
  background: red;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.input-datapicker .v-input__app end-inner {
  margin-top: 0px !important;
}

.append-calendar {
  height: 40px;
  width: 40px;
  background: #3c5ca7;
  margin-right: -11px;
  border-radius: 0px 4px 4px 0px;
  color: white;
}
.pointer-all {
  pointer-events: all;
}
.pointer-none {
  pointer-events: none;
}
.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 10px 8px;
  border-radius: 5px;
}
</style>