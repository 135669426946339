<template>
  <div class="my-6">
    <span class="span-destaq">{{ $t("TXT_USER_WITH_ADMIN_ACCESS") }}</span>

    <Prd-loading-circular v-if="listLoading" />

    <div v-else>
      <v-data-table
        :headers="headers"
        :items="userListCurrentPage.list"
        :loading="acessLoading"
        hide-default-footer
        class="mt-6"
      >
        <!-- RESOURCE GROUP -->
        <template v-slot:[`item.resourceGroup`]="{ item }">
          <div class="access-row">
            <span v-for="(group, index) in item.resourceGroup" :key="index">
              {{ group }} |
            </span>
            <v-icon :color="$prdStyles('color-primary')">
              mdi-cog-outline
            </v-icon>
          </div>
        </template>

        <!-- NAME -->
        <template v-slot:[`item.nome`]="{ item }">
          <div style="display: flex; justify-content: center">
            <span v-if="!item.isEdit">{{ item.nome }}</span>
            <v-text-field
              v-model="item.nome"
              v-else
              outlined
              dense
              hide-details
            ></v-text-field>
          </div>
        </template>

        <!-- PHONE -->
        <template v-slot:[`item.telefone`]="{ item }">
          <div style="display: flex; justify-content: center">
            <span v-if="!item.isEdit">{{ item.telefone }}</span>
            <v-text-field
              v-model="item.telefone"
              v-else
              outlined
              dense
              hide-details
              class="input-width"
            ></v-text-field>
          </div>
        </template>

        <!-- ACCESS -->
        <template v-slot:[`item.access`]="{ item }">
          <div class="access-row">
            <img
              :class="{ enableFlag: item.adminRoles.includes('1000') }"
              @click="setAdminRole('1000', item)"
              src="@/assets/flags/brasil.svg"
              class="flag"
            />
            <img
              :class="{ enableFlag: item.adminRoles.includes('1001') }"
              @click="setAdminRole('1001', item)"
              src="@/assets/flags/us.svg"
              class="flag"
            />
            <img
              :class="{ enableFlag: item.adminRoles.includes('1002') }"
              @click="setAdminRole('1002', item)"
              src="@/assets/flags/es.svg"
              class="flag"
            />
          </div>
        </template>

        <!-- ACTIONS -->
        <template v-slot:[`item.actions`]="{ item }">
          <div>
            <div v-if="!item.isEdit" class="access-row">
              <v-icon
                :color="$prdStyles('color-primary')"
                :disabled="item.isDisable"
                @click="editUser(item)"
              >
                mdi-pencil-box
              </v-icon>
              <Delete-user-modal
                :user="item"
                :disable="item.isDisable"
                :isLoading="deleteLoading"
                @deleteUser="deleteUser"
              />
            </div>

            <div v-else>
              <div class="actions">
                <Edit-user-modal
                  :user="item"
                  :idCompany="idCompany"
                  :isLoading="editLoading"
                  @updateUser="updateUser"
                />

                <v-icon
                  :color="$prdStyles('color-warning')"
                  @click="cancelEditUser(item)"
                >
                  mdi-close-box
                </v-icon>
              </div>
            </div>
          </div>
        </template>
      </v-data-table>

      <Prd-pagination
        v-model="currentPage"
        :totalRegisters="totalRecords"
        :length="totalPages"
        :disabled="disablePagination"
        :showingRegistersLen="numberOfRecords"
        :resultLabel="$t('TXT_PAGINATION')"
        :pageLabel="$t('TXT_PAGE_LABEL')"
      />
    </div>
  </div>
</template>

<script>
import service from "@/service/company-config/user-management.js";
const Service = new service();

import PrdPagination from "@/Design_System/common/prd-pagination.vue";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import DeleteUserModal from "./components/delete-user-modal.vue";
import EditUserModal from "./components/edit-user-modal.vue";

export default {
  components: {
    PrdPagination,
    PrdLoadingCircular,
    DeleteUserModal,
    EditUserModal,
  },
  props: ["search"],
  data() {
    return {
      headers: [
        {
          text: "E-mail",
          align: "center",
          sortable: true,
          value: "email",
          width: "250px",
        },
        {
          text: this.$i18n.t("TXT_USER_NAME"),
          align: "center",
          sortable: true,
          value: "nome",
          width: "250px",
        },
        {
          text: this.$i18n.t("TXT_PHONE"),
          align: "center",
          sortable: true,
          value: "telefone",
        },
        {
          text: this.$i18n.t("TXT_REGIONAL_ACCESS"),
          align: "center",
          sortable: true,
          value: "access",
        },
        {
          text: this.$i18n.t("TXT_ACTIONS"),
          align: "center",
          sortable: true,
          value: "actions",
        },
      ],

      userList: [],
      userListByPages: [],
      userListCurrentPage: [],

      currentPage: 1,
      totalRecords: 0,
      totalPages: 0,
      numberOfRecords: 0,
      userForEdit: null,

      listLoading: false,
      acessLoading: false,
      deleteLoading: false,
      editLoading: false,
      disablePagination: false,
    };
  },

  watch: {
    currentPage() {
      this.userListCurrentPage = this.userListByPages.find((el) => {
        return el.index == this.currentPage;
      });

      this.numberOfRecords = this.userListCurrentPage?.list.length ?? 0;
    },

    search(newValue) {
      let filteredList = [];
      filteredList = this.userList.filter((el) => {
        return (
          el.nome.toLowerCase().includes(newValue.toLowerCase()) ||
          el.email.toString().includes(newValue)
        );
      });
      this.listUsers(filteredList);
    },
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    getUsers() {
      this.listLoading = true;
      Service.getUsers(this.idCompany)
        .then((res) => {
          let response = res?.data ?? [];

          response.forEach((el) => {
            el.isEdit = false;
            el.isDisable = false;
          });

          this.userList = response;
          this.listUsers(response);
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_ERROR_SEARCH_USER"),
            type: "error",
          });
        });
    },

    listUsers(data) {
      let list = this.setPaginatedList(data, 5);
      this.userListByPages = list;

      this.userListCurrentPage =
        this.userListByPages.find((el) => {
          return el.index == this.currentPage;
        }) ?? [];

      this.totalRecords = data.length;
      this.totalPages = this.userListByPages.length;
      this.numberOfRecords = this.userListCurrentPage?.list?.length ?? 0;
      this.listLoading = false;
    },

    setPaginatedList(array, groupSize) {
      const groupedArray = [];

      for (let i = 0; i < array.length; i += groupSize) {
        const group = {
          index: Math.floor(i / groupSize) + 1,
          list: array.slice(i, i + groupSize),
        };
        groupedArray.push(group);
      }
      return groupedArray;
    },

    editUser(user) {
      this.$emit("editMode", true);
      this.disablePagination = true;
      this.userForEdit = { ...user };
      let userId = user.idUsuario;

      this.userListCurrentPage.list.forEach((el) => {
        if (el.idUsuario != userId) el.isDisable = true;
      });

      user.isEdit = true;
    },

    cancelEditUser(user) {
      const userId = user.idUsuario;

      this.userListCurrentPage.list.forEach((el, index) => {
        if (el.idUsuario === userId) {
          this.$set(this.userListCurrentPage.list, index, {
            ...this.userForEdit,
          });

          el.isEdit = false;
        } else {
          el.isDisable = false;
        }
      });
      this.disablePagination = false;
      this.$emit("editMode", false);
    },

    setAdminRole(role, user) {
      this.acessLoading = true;
      let request = {
        UserEmail: user.email,
        AccessLevel: role,
      };

      let foundedRole = user.adminRoles.includes(role);

      if (foundedRole) {
        Service.removeAdminRole(request)
          .then(() => {
            let index = user.adminRoles.indexOf(role);

            if (index !== -1) {
              user.adminRoles.splice(index, 1);
            }
            this.acessLoading = false;
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_SUCCESS_MANAGEMENT_PERMISSIONS"),
              type: "success",
            });
          })
          .catch((error) => {
            console.log(error);
            this.acessLoading = false;
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_ERROR_MANAGEMENT_PERMISSIONS"),
              type: "error",
            });
          });
      } else {
        Service.addAdminRole(request)
          .then(() => {
            user.adminRoles.push(role);
            this.acessLoading = false;
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_SUCCESS_MANAGEMENT_PERMISSIONS"),
              type: "success",
            });
          })
          .catch((error) => {
            console.log(error);
            this.acessLoading = false;
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_ERROR_MANAGEMENT_PERMISSIONS"),
              type: "error",
            });
          });
      }
    },

    deleteUser(user) {
      this.deleteLoading = true;
      Service.removeUser(this.idCompany, user.idUsuario)
        .then(() => {
          this.deleteLoading = false;
          this.getUsers();
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_SUCCESS_EXCLUDE_USER"),
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error);
          this.deleteLoading = false;
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_ERROR_EXCLUDE_USER"),
            type: "error",
          });
        });
    },

    updateUser(user) {
      this.editLoading = true;

      let request = {
        email: user.email,
        id: user.idUsuario,
        idCompany: this.idCompany,
        idioma: user.lang,
        name: user.nome,
        phone: user.telefone,
      };

      Service.editUser(request)
        .then(() => {
          this.userListCurrentPage.list.forEach((el) => {
            el.isEdit = false;
            el.isDisable = false;
          });
          this.editLoading = false;
          this.disablePagination = false;
          this.$emit("editMode", false);
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_SUCCESS_TO_EDIT_USER", {
              user: this.$i18n.t("TXT_USER").toLowerCase(),
            }),
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_ERROR_TO_EDIT_USER", {
              user: this.$i18n.t("TXT_USER").toLowerCase(),
            }),
            type: "error",
          });
          this.editLoading = false;
          this.cancelEditUser(this.userForEdit);
        });
    },
  },

  created() {
    this.getUsers();
  },
};
</script>

<style lang="scss" scoped>
.span-destaq {
  border-left: 6px solid $feedback-color-helper-pure;
  padding-left: 8px;
}

.flag {
  width: 27px;
  height: 27px;
  border-radius: 10px;
  opacity: 0.2;
  cursor: pointer;
  transition: 0.4s;
}

.flag:hover {
  opacity: 0.5;
}

.enableFlag {
  opacity: 1;
  pointer-events: all;
}

.enableFlag:hover {
  opacity: 0.5;
}

.access-row {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}

.itemDisable {
  opacity: 0.5;
}

.input-width {
  max-width: 110px;
}

.actions {
  display: flex;
  justify-content: center;
  text-align: center;
}

.admin-icon:hover {
  transform: scale(1.1);
}
</style>