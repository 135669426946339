<template>
  <div class="my-6 main" id="informative-table">
    <progress-component v-if="false"> </progress-component>
    <div>
      <v-data-table
        :loading="isLoading"
        :search="search"
        :headers="headers"
        :items="items"
      >
        <!-- MINEIRÃO -->
        <template v-slot:[`item.vermelho_superior`]="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox v-model="item.vermelho_superior" disabled></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.amarelo_superior`]="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox v-model="item.amarelo_superior" disabled></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.laranja_superior`]="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox v-model="item.laranja_superior" disabled></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.roxo_superior`]="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox v-model="item.roxo_superior" disabled></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.vermelho_inferior`]="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox v-model="item.vermelho_inferior" disabled></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.amarelo_inferior`]="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox v-model="item.amarelo_inferior" disabled></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.laranja_inferior`]="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox v-model="item.laranja_inferior" disabled></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.roxo_inferior`]="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox v-model="item.roxo_inferior" disabled></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.roxo_diamante`]="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox v-model="item.roxo_diamante" disabled></v-checkbox>
          </div>
        </template>

        <!-- ARENA INDEPENDENCIA -->
        <template v-slot:[`item.especial_pitangui`]="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox v-model="item.especial_pitangui" disabled></v-checkbox>
          </div>
        </template>

        <template v-slot:[`item.especial_ismênia`]="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox v-model="item.especial_ismênia" disabled></v-checkbox>
          </div>
        </template>

        <template v-slot:[`item.especial_minas`]="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox v-model="item.especial_minas" disabled></v-checkbox>
          </div>
        </template>

        <template v-slot:[`item.vip_pitangui`]="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox v-model="item.vip_pitangui" disabled></v-checkbox>
          </div>
        </template>

        <template v-slot:[`item.vip_minas`]="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox v-model="item.vip_minas" disabled></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.cadeira_ismênia`]="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox v-model="item.cadeira_ismênia" disabled></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.cadeira_pitangui`]="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox v-model="item.cadeira_pitangui" disabled></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.cadeira_minas`]="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox v-model="item.cadeira_minas" disabled></v-checkbox>
          </div>
        </template>

        <template v-slot:[`item.expectedPublic`]="{ item }">
          <div v-if="!item.isEdit">
            {{ item.expectedPublic }}%
            <v-icon
              @click="item.isEdit = true"
              :color="$prdStyles('color-primary')"
              >mdi-pencil-box
            </v-icon>
          </div>
          <div style="display: flex; align-items: center; gap: 8px" v-else>
            <v-text-field
              v-model="item.expectedPublic"
              outlined
              dense
              hide-details
              type="number"
              hide-spin-buttons
            >
            </v-text-field>
            <div style="display: flex; flex-direction: column">
              <v-icon @click="editExpectedPublic(item)" color="#1D8527" small>
                mdi-checkbox-marked
              </v-icon>
              <v-icon @click="item.isEdit = false" color="#B00020" small>
                mdi-close-box
              </v-icon>
            </div>
          </div>
        </template>
        <template v-slot:[`item.membersExpected`]="{ item }">
          {{ item.membersExpected ? item.membersExpected.toFixed(0) : 0 }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import progressComponent from "@/Design_System/common/progressComponent";
import i18n from "@/i18n";
export default {
  props: [
    "error",
    "isLoading",
    "idCompany",
    "place",
    "crowding",
    "tickets",
    "disableInput",
  ],

  components: {
    progressComponent,
  },
  data: () => ({
    headers: [
      {
        text: i18n.t("TXT_NAME"),
        value: "name",
        align: "center",
        sortable: true,
      },
      {
        text: i18n.t("TXT_TOTAL_MEMBERSHIP"),
        value: "total_member",
        align: "center",
        sortable: true,
      },
    ],

    items: [],
    search: "",
  }),

  watch: {
    tickets: {
      handler() {
        if (this.tickets) this.setTickets();
      },
      immediate: true,
    },

    place() {
      if (this.place) this.setPlaces();
    },

    crowding: {
      handler(value) {
        if (value) {
          this.headers = [];
          this.items = [];
          this.setTickets();
          this.setPlaces();
          this.setCrowding(value);
        }
      },
      deep: true,
    },
  },

  methods: {
    setTickets() {
      let ticketArray = [];

      this.tickets.forEach((el) => {
        let ticketObject = {
          idEnterpriseEventTicket: el.idEnterpriseEventTicket,
          name: el.name,
          total_member: el.total_member,
          isEdit: false,
        };
        ticketArray.push(ticketObject);
      });

      this.items = ticketArray;
    },

    setPlaces() {
      this.headers = [
        {
          text: this.$i18n.t("TXT_NAME"),
          value: "name",
          align: "center",
          sortable: true,
        },
      ];

      this.place?.sectors.forEach((el) => {
        let headerObj = {
          text: el.name,
          value: this.transformStringToValue(el.name),
          align: "center",
          sortable: true,
          idEnterpriseEventSector: el.idEnterpriseEventSector,
        };

        this.headers.push(headerObj);
      }) ?? null;

      for (const header of this.headers) {
        const value = header.value;
        const exists = this.items.some((item) =>
          Object.prototype.hasOwnProperty.call(item, value)
        );
        if (!exists) {
          for (const item of this.items) {
            item[value] = false;
          }
        }
      }

      this.headers.push({
        text: this.$i18n.t("TXT_MEMBERSHIP"),
        value: "total_member",
        align: "center",

        sortable: true,
      });
      this.headers.push({
        text: this.$i18n.t("TXT_AUDIENCE_ESTIMATION"),
        value: "expectedPublic",
        align: "center",

        sortable: true,
      });
      this.headers.push({
        text: this.$i18n.t("TXT_EXPECTED_PARTNERS"),
        value: "membersExpected",
        align: "center",

        sortable: true,
      });

      this.items.forEach((item) => {
        item.expectedPublic = 50;
        item.membersExpected = (item.expectedPublic / 100) * item.total_member;
      });
    },

    setCrowding(value) {
      value.sector_releases.forEach((sector) => {
        let foundedPlace = this.place.sectors.find((place) => {
          return (
            sector.idEnterpriseEventSector == place.idEnterpriseEventSector
          );
        });

        if (foundedPlace)
          sector.name = this.transformStringToValue(foundedPlace.name);
      });

      this.items.forEach((item) => {
        value.sector_releases.forEach((sector) => {
          if (sector.tickets_selected.includes(item.idEnterpriseEventTicket)) {
            item[sector.name] = true;
          } else {
            item[sector.name] = false;
          }
        });
      });
    },

    editExpectedPublic(item) {
      item.membersExpected = (item.expectedPublic / 100) * item.total_member;
      item.isEdit = false;
    },

    transformStringToValue(string) {
      string = string.toLowerCase().replace(/ /g, "_");
      return string;
    },

    getItems() {
      this.$emit("getItems", this.items);
    },
  },
};
</script>
<style lang="scss">
#informative-table {
  width: 100%;
  tr th i {
    display: none !important;
  }
  .grid {
    display: grid;
    width: 100%;
    overflow-x: auto;
    column-gap: 4px;
    row-gap: 4px;
  }
  .table-header {
    background-color: $neutral-color-high-dark;
    padding: 12px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .table-item {
    background-color: $neutral-color-high-light;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  tr th {
    width: 200px !important;
  }
}
</style>
