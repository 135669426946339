<template>
  <div class="mt-4">
    <v-row no-gutters align="center" class="mb-6">
      <Input-modal
        @file="selectedFile = $event"
        :values="values"
        :index="index"
        :idRule="idRule"
      />
      <v-icon v-if="this.selectedFile" class="grayText mr-1 ml-4"
        >mdi-paperclip</v-icon
      >
      <span :class="`grayText ${this.selectedFile ? '' : 'ml-4'}`">{{
        fileName
      }}</span>
    </v-row>
    <Inputs-table
      v-if="values.values.length > 0"
      :values="values"
      :index="index"
    />
  </div>
</template>

<script>
import InputModal from "./components/input-modal.vue";
import InputsTable from "./components/table/inputs-table-main.vue";

export default {
  props: ["index", "values", "idRule"],
  components: { InputModal, InputsTable },
  data() {
    return {
      selectedFile: null,
    };
  },
  computed: {
    fileName() {
      return this.selectedFile?.name ?? this.$i18n.t("TXT_NO_SELECTED_FILE");
    },
  },
};
</script>

<style lang="scss" scoped>
.grayText {
  color: $neutral-color-low-light;
}
</style>