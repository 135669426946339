<template>
  <modal-action
    :useExternalActivatorButton="true"
    :externalIsOpen="isOpen"
    @handlerIsOpen="isOpen = $event"
    :icon="'mdi-alert-outline'"
    :iconColor="'red'"
    :modalTitle="$t('TXT_EXCLUDE_USER')"
    :question="question"
    :alertMessage="alertMessage"
    :confirmButtonText="$t('TXT_EXCLUDE')"
    :confirmButtonIcon="'mdi-delete-outline'"
    :buttonsColor="'red'"
    @handleConfirmAction="unlinkUserCompany"
  >
    <template slot="externalActivatorButton">
      <v-progress-circular
        indeterminate
        :color="$prdStyles('color-primary')"
        v-show="isLoading"
      />
      <v-icon
        v-show="!isLoading"
        :disabled="user.generalIsLoading"
        :color="$prdStyles('color-warning')"
        size="18"
        class="borderIcon"
        @click="isOpen = true"
        >mdi-delete-outline</v-icon
      >
    </template>
    <template slot="content">
      <user-infos :user="user" />
    </template>
  </modal-action>
</template>

<script>
import ModalAction from "@/components/common/modal-action.vue";
import userInfos from "../../register-new-user/components/user-infos.vue";
import GeneralSystemConfigsService from "@/service/general-system-configs";

export default {
  components: { userInfos, ModalAction },
  props: {
    user: { type: Object },
    idCompany: { type: Number },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      service: new GeneralSystemConfigsService(),
    };
  },
  computed: {
    question() {
      return this.$i18n.t("TXT_MODAL_EXCLUDE_USER_QUESTION");
    },
    alertMessage() {
      return this.$i18n.t("TXT_MODAL_EXCLUDE_USER_ALERT_MESSAGE");
    },
  },
  watch: {
    isLoading: {
      handler(value) {
        this.$emit("isLoading", value);
      },
    },
  },
  methods: {
    async unlinkUserCompany() {
      this.isLoading = true;
      let message,
        type = null;
      try {
        await this.service.unlinkUserCompany(
          this.idCompany,
          this.user.idUsuario
        );
        message = this.$i18n.t("TXT_EXCLUDE_USER_SUCCESS");
        type = "success";
        this.$emit("resetOriginalList");
      } catch (error) {
        console.log(error);
        message = this.$i18n.t("TXT_EXCLUDE_USER_ERROR");
        type = "error";
      } finally {
        this.isLoading = false;
        this.$store.commit("snackbarV2/set", { message, type });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.borderIcon {
  border: 1px solid;
  border-radius: 4px;
}
</style>