<template>
  <v-data-table
    :headers="headers"
    :items="rules"
    hide-default-footer
    :items-per-page="-1"
  >
    <template v-slot:[`item.order`]="{ item }">
      <td class="text-center">
        {{ item.order }}
      </td>
    </template>
    <template v-slot:[`item.oldPrice`]="{ item }">
      <td class="text-center">
        {{ formatMonetary(item.oldPrice) }}
      </td>
    </template>

    <template v-slot:[`item.currentPrice`]="{ item }">
      <td class="text-center">
        {{ formatMonetary(item.currentPrice) }}
      </td>
    </template>

    <template v-slot:[`item.oldMargin`]="{ item }">
      <td class="text-center">
        {{ formatPercentage(item.oldMargin) }}
      </td>
    </template>

    <template v-slot:[`item.currentMargin`]="{ item }">
      <td class="text-center">
        {{ formatPercentage(item.currentMargin) }}
      </td>
    </template>

    <template v-slot:[`item.nameCondition`]="{ item }">
      <td class="text-center">
        {{ $t(item.nameCondition) }}
      </td>
    </template>

    <template v-slot:[`item.statusMessage`]="{ item }">
      <td class="text-center" :class="setStatusColor(item.statusMessage)">
        {{ $t(`${item.statusMessage}_2`) }}
      </td>
    </template>

    <template v-slot:[`item.messageBlockedRuleCondition`]="{ item }">
      <td class="text-center">
        {{
          item.messageBlockedRuleCondition
            ? $t(item.messageBlockedRuleCondition)
            : "-"
        }}
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { formatPercentage } from "@/utils/format-percentage";
import { formatMonetary } from "@/utils/format-toMonetary";
export default {
  props: { rules: { type: Array, default: () => [] } },
  data() {
    return {
      headers: [
        {
          text: this.$i18n.t("TXT_ORDER"),
          align: "center",
          sortable: true,
          value: "order",
        },
        {
          text: this.$i18n.t("TXT_APPLIED_RULE"),
          align: "center",
          sortable: true,
          value: "nameCondition",
          width: "170px",
        },
        {
          text: this.$i18n.t("TXT_PRE_PRICE"),
          align: "center",
          sortable: true,
          value: "oldPrice",
        },
        {
          text: this.$i18n.t("TXT_POST_PRICE"),
          align: "center",
          sortable: true,
          value: "currentPrice",
        },
        {
          text: this.$i18n.t("TXT_PRE_MARGIN"),
          align: "center",
          sortable: true,
          value: "oldMargin",
        },
        {
          text: this.$i18n.t("TXT_POST_MARGIN"),
          align: "center",
          sortable: true,
          value: "currentMargin",
        },
        {
          text: this.$i18n.t("TXT_STATUS"),
          align: "center",
          sortable: true,
          value: "statusMessage",
        },
        {
          text: this.$i18n.t("TXT_REASON"),
          align: "center",
          sortable: true,
          value: "messageBlockedRuleCondition",
        },
      ],
    };
  },
  methods: {
    formatPercentage,
    formatMonetary,
    setStatusColor(status) {
      if (status == "TXT_APPROVED" || status == 1) return "success-color";
      else if (status == "TXT_NOT_APPLIED" || status == 2) return "";
      return "error-color";
    },
  },
};
</script>

<style lang="scss" scoped>
.success-color {
  color: #1d8527;
}
.error-color {
  color: #b00020;
}
td {
  background-color: white !important;
}
</style>
