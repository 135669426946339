<template>
  <v-card class="pa-4" :disabled="isLoadingAlerts">
    <v-row no-gutters>
      <label class="font-size-16"
        >{{ $t("TXT_NUMBER_REPROVED_ITEMS") }}
        <span class="font-size-18 font-weight-bold">{{ cardType }}</span></label
      >
      <v-spacer></v-spacer>
      <v-icon
        v-if="newData.length > 0 && isPredifyAdmin"
        :color="$prdStyles('color-primary')"
        @click="redirectToAiScreen"
      >
        mdi-arrow-right-bold-box
      </v-icon>
    </v-row>
    <div v-if="isLoadingAlerts">
      <v-progress-circular indeterminate color="#3c5ca7" />
    </div>
    <div v-else>
      <span class="font-size-18 font-weight-bold destaq">{{ cardValue }}</span>

      <div class="product-main">
        <div
          v-for="(product, index) in newData"
          :key="index"
          class="product-info mt-2"
        >
          <v-row no-gutters>
            <span class="font-weight-bold">{{ product.productName }}</span>
            <v-spacer></v-spacer>
            <span class="font-weight-bold mr-1">
              {{ productInfo }}:
              <span class="product-price">
                {{ showFormatedData(product.data) }}</span
              >
            </span>
          </v-row>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["data", "isLoadingAlerts"],
  components: {},
  data() {
    return {};
  },

  methods: {
    showFormatedData(value) {
      if (
        this.data?.cardType.toLowerCase() == "margem" ||
        this.data?.cardType.toLowerCase() == "ìndice de competitividade"
      ) {
        return "+" + value.toString().split(".")[0] + " %";
      } else {
        return "R$ " + value.toFixed(2).replace(".", ",");
      }
    },

    gelAllIdEnterprisePricesProjectionByArray(products) {
      return products.map((product) => {
        return product.idEnterprisePricesProjection;
      });
    },
    getIdEnterprisePriceGroupByArray(productsArray) {
      return productsArray[0].idEnterprisePriceGroup;
    },
    buildBodyToRedirect(
      idEnterprisePriceGroup,
      listIdEnterprisePricesProjection
    ) {
      return {
        idEnterprisePriceGroup: idEnterprisePriceGroup,
        listIdEnterprisePricesProjection: listIdEnterprisePricesProjection,
      };
    },
    redirectToAiScreen() {
      const idEnterprisePriceGroups = this.getIdEnterprisePriceGroupByArray(
        this.newData
      );

      const listIdEnterprisePricesProjection =
        this.gelAllIdEnterprisePricesProjectionByArray(this.newData);

      const bodyToRedirect = this.buildBodyToRedirect(
        idEnterprisePriceGroups,
        listIdEnterprisePricesProjection
      );
      this.$router.push({
        name: "pricer-main",
        params: {
          data: bodyToRedirect,
        },
      });
    },
  },

  computed: {
    newData() {
      return this.data?.products ?? [];
    },
    cardValue() {
      return this.data?.cardValue ?? null;
    },
    productInfo() {
      if (!this.data?.cardType) return "-";
      if (this.data?.cardType.toLowerCase() == "margem") return "Margem";
      if (this.data?.cardType.toLowerCase() == "ìndice de competitividade")
        return "IC";
      if (this.data?.cardType.toLowerCase() == "variação de lucro")
        return "Variação";
      else return "Preço";
    },

    cardType() {
      if (!this.data?.cardType) return "-";
      return this.data?.cardType.toUpperCase();
    },
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    isPredifyAdmin() {
      return this.$store.getters.isPredifyAdmin;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-main {
  max-height: 170px;
  overflow-y: scroll;

  .product-info {
    background-color: $neutral-color-high-light;
    border-left: 6px solid $feedback-color-warning-pure;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 10px;

    .product-price {
      color: $feedback-color-warning-pure;
    }
  }
}

.destaq {
  color: $feedback-color-warning-pure;
}
</style>