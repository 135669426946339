<template>
  <div>
    <label class="label-comboBox"> {{ title }} </label>

    <v-radio-group
      v-model="value"
      v-bind="$attrs"
      class="mt-1"
      hide-details="auto"
    >
      <v-radio
        v-for="opt in options"
        :key="opt.value"
        :label="opt.label"
        :value="opt.value"
      />
    </v-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
    },
  },
  data() {
    return {
      value: null,
    };
  },
  watch: {
    value: {
      handler(value) {
        this.$emit("getValue", value);
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>