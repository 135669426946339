var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('prd-btn',{attrs:{"title":_vm.$t('TXT_SAVE')},on:{"click":_vm.openDialog}}),(_vm.localData != null)?_c('v-dialog',{attrs:{"max-width":550,"persistent":""},model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}},[_c('v-card',{staticClass:"pa-4",attrs:{"flat":"","disabled":_vm.isLoading}},[_c('h4',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$t("TXT_TRAILED_CRAWLER")))]),_c('span',[_vm._v(_vm._s(_vm.$t("TXT_COMPANY_CHANGES")))]),_c('div',{staticClass:"changes-main pa-4 mb-4"},[_c('h4',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.localData.idEmpresa)+" - "+_vm._s(_vm.localData.nomeRazaoSocial ? _vm.localData.nomeRazaoSocial.toUpperCase() : "")+" ")]),_c('v-divider'),(
            _vm.localData.newCrawlersLinked
              ? _vm.localData.newCrawlersLinked.length > 0
              : false
          )?_c('div',{staticClass:"my-4 crawler-div"},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("TXT_NEW_CRAWLERS"))+" "),_c('span',{staticClass:"green-text"},[_vm._v(_vm._s(_vm.$t("TXT_ATTACHED")))]),_vm._v(": ")]),_vm._l((_vm.localData.newCrawlersLinked),function(crawler,index){return _c('div',{key:index,staticClass:"my-flex"},[_vm._v(" "+_vm._s(crawler.description)+" - "+_vm._s(crawler.idCrawler)+" "),(crawler.idCountry)?_c('span',{staticStyle:{"align-items":"center !important"}},[_c('img',{staticStyle:{"border-radius":"5px"},attrs:{"width":"25px","src":_vm.setToCountryFlag(crawler.idCountry)}})]):_vm._e(),_vm._v(" | ")])})],2):_vm._e(),_c('v-divider'),(
            _vm.localData.crawlersUnlinked
              ? _vm.localData.crawlersUnlinked.length > 0
              : false
          )?_c('div',{staticClass:"my-4 crawler-div"},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("TXT_CRAWLERS_WILL_BE"))+" "),_c('span',{staticClass:"red-text"},[_vm._v(_vm._s(_vm.$t("TXT_UNCHARGED")))]),_vm._v(": ")]),_vm._l((_vm.localData.crawlersUnlinked),function(crawler,index){return _c('div',{key:index,staticClass:"my-flex"},[_vm._v(" "+_vm._s(crawler.description)+" - "+_vm._s(crawler.idCrawler)+" "),(crawler.idCountry)?_c('span',[_c('img',{staticClass:"pt-1",staticStyle:{"border-radius":"5px"},attrs:{"width":"25px","src":_vm.setToCountryFlag(crawler.idCountry)}})]):_vm._e(),_vm._v(" | ")])})],2):_vm._e()],1),_c('span',{staticClass:"crawler-div"},[_vm._v(_vm._s(_vm.$t("TXT_CONFIRM_ALTERATIONS"))+"?")]),_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-spacer'),_c('prd-btn',{staticClass:"mr-4",attrs:{"disabled":_vm.isLoading,"title":_vm.$t('TXT_CANCEL'),"outlined":true},on:{"click":function($event){_vm.isDialogOpen = false}}}),_c('prd-btn',{attrs:{"disabled":_vm.isLoading,"loading":_vm.isLoading,"title":_vm.$t('TXT_CONFIRM')},on:{"click":_vm.confirmChanges}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }