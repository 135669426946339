<template>
  <div class="d-flex justify-center">
    <v-card flat width="330">
      <h2 class="title my-8">{{ $t("TXT_REDEFINE_PASSWORD") }}</h2>
      <Alert-message v-if="isRecoverySuccess" />
      <Recovery-form v-else @isRecoverySuccess="isRecoverySuccess = true" />
    </v-card>
  </div>
</template>

<script>
import RecoveryForm from "./components/recovery-form.vue";
import AlertMessage from "./components/alert-message.vue";
export default {
  components: { RecoveryForm, AlertMessage },
  data() {
    return {
      isRecoverySuccess: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
  font-weight: $font-weight-bold;
  font-size: $font-size-lg;
  color: $brand-color-primary-pure;
  text-transform: uppercase;
}
</style>