<template>
  <v-card id="pdfContent" class="mt-6 personalizedPaddings">
    <Info-header-pdf />
    <Infos-header :lastRequest="lastRequest" :nameStore="nameStore" />
    <Average-chart
      v-for="(data, index) in chartsData"
      :dataGraphic="data"
      :key="index"
    />
  </v-card>
</template>

<script>
import InfosHeader from "../select-data-card/components/infos-header.vue";
import AverageChart from "./components/average-chart-to-pdf.vue";
import InfoHeaderPdf from "./components/info-header-pdf.vue";

export default {
  components: { InfosHeader, InfoHeaderPdf, AverageChart },
  props: ["chartsData", "nameStore", "lastRequest"],
  mounted() {
    const el = document.querySelector("#pdfContent");
    this.$emit("pdfContent", el);
  },
  
};
</script>

<style lang="scss" scoped>
.personalizedPaddings {
  padding: 0px 40px;
}
</style>