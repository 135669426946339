<template>
  <v-row no-gutters class="licenseName py-2 mr-1">
    {{ name }}
  </v-row>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.licenseName {
  border-bottom: 1px solid $neutral-color-low-light;
}
</style>