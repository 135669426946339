<template>
  <div v-if="list.length">
    <v-divider></v-divider>
    <v-card class="pa-4" flat>
      <h4 class="mb-4">HISTÓRICO</h4>
      <highcharts class="hc mt-4" :options="chartData"></highcharts>
    </v-card>
    <v-divider></v-divider>
  </div>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary";
export default {
  props: ["list"],
  data() {
    return {
      chartData: {
        tooltip: {
          formatter: function () {
            return this.points.reduce(function (s, point) {
              return (
                s + "<br/>" + point.series.name + ": " + formatMonetary(point.y)
              );
            }, "<b>" + this.x + "</b>");
          },
          shared: true,
        },

        title: {
          text: "",
        },

        yAxis: {
          title: {
            text: "Valor (R$)",
          },
        },

        xAxis: {
          categories: [],
        },

        series: [],

        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                },
              },
            },
          ],
        },
        exporting: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
      },
    };
  },

  watch: {
    list: {
      handler(value) {
        let concatenatedItems = this.transformItems(value);
        this.chartData.series = this.processData(concatenatedItems);
        this.chartData.xAxis.categories = this.extractDates(value);
      },
    },
    deep: true,
    immediate: true,
  },

  methods: {
    formatMonetary,

    transformItems(data) {
      return data.map((item) => {
        return {
          fuel: `${item.fuel} ${item.modality} ${item.local}`,
          price: item.price,
          date: item.date,
        };
      });
    },

    processData(data) {
      const allDates = this.extractDates(data);

      const groupedByFuel = data.reduce((acc, curr) => {
        const date = new Date(curr.date).toISOString().split("T")[0];

        if (!acc[curr.fuel]) {
          acc[curr.fuel] = {};
        }
        if (!acc[curr.fuel][date]) {
          acc[curr.fuel][date] = [];
        }
        acc[curr.fuel][date].push(curr.price);
        return acc;
      }, {});

      const result = Object.keys(groupedByFuel).map((fuelName) => {
        const dailyAverages = allDates.map((date) => {
          if (groupedByFuel[fuelName][date]) {
            const prices = groupedByFuel[fuelName][date];
            const average =
              prices.reduce((sum, price) => sum + price, 0) / prices.length;
            return average;
          } else {
            return null;
          }
        });

        return {
          name: fuelName,
          data: dailyAverages,
        };
      });

      return result;
    },

    extractDates(data) {
      const uniqueDates = [
        ...new Set(
          data.map((item) => new Date(item.date).toISOString().split("T")[0])
        ),
      ];
      return uniqueDates.sort();
    },
  },
};
</script>
