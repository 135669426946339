var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.priceResults,"items-per-page":5},scopedSlots:_vm._u([{key:"item.productName",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"table-product-name"},[_c('div',{staticClass:"copy-box"},[_c('v-icon',{staticClass:"copy-button",attrs:{"color":_vm.$prdStyles('color-primary'),"left":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyFunction(item.productName)}}},[_vm._v("mdi-content-copy")]),_c('a',{attrs:{"href":item.productLink,"target":"_blank"}},[_vm._v(" "+_vm._s(item.productName))])],1)])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total_priceInstallment ? _vm.formatMonetary(item.total_priceInstallment) : _vm.formatMonetary(item.price))+" ")]}},{key:"item.price_tax_st",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.price_tax_st ? item.price_tax_st : "-")+" ")])]}},{key:"item.deflatedValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMonetary(item.deflatedValue))+" ")]}},{key:"item.quantityInstallment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantityInstallment ? item.quantityInstallment + "x" : "-")+" ")]}},{key:"item.crawlerDate",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.crawlerDate ? item.crawlerDate .slice(0, 10) .replace("-", "/") .replace("-", "/") : null)+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }