<template>
  <v-row no-gutters>
    <v-col cols="6">
      <Header-table />
      <Line-table
        :list="values.values"
        @updatePsycologicPriceConditions="updatePsycologicPriceConditions"
      />
    </v-col>
  </v-row>
</template>

<script>
import HeaderTable from "./components/header-table.vue";
import LineTable from "./components/line-table.vue";
export default {
  components: { HeaderTable, LineTable },
  props: ["index", "values", "idRule"],
  methods: {
    updatePsycologicPriceConditions(data) {
      const newList = this.values.values;
      const foundedItem = newList.find(
        (item) => item.idConditionValue == data.id
      );
      foundedItem.decimalValue = parseFloat(data.value);

      this.$store.dispatch("updateConditions", {
        idRule: this.idRule,
        key: "updatePsycologicPriceConditions",
        conditionType: 6,
        index: this.index,
        values: newList,
      });
    },
  },
};
</script>