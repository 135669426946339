<template>
  <div v-if="chats.length == 0">
    <Copilot-text-box
      :text="$i18n.t('TXT_COPILOT_HELLO_1')"
      :author="$i18n.t('TXT_COPILOT')"
    />
    
    <Copilot-text-box
      :text="$i18n.t('TXT_COPILOT_HELLO_2')"
      :author="$i18n.t('TXT_COPILOT')"
      :hideLabel="true"
    />
  </div>
</template>

<script>
import CopilotTextBox from "./copilot-text-box.vue";
export default {
  components: { CopilotTextBox },

  computed: {
    chats() {
      return this.$store?.state?.copilotStore?.chats ?? [];
    },
  },
};
</script>

<style lang="scss" scoped>
.text-box {
  border: 0.5px solid black;
  border-radius: 5px;

  background-color: #789ffc57;
  padding: 4px 12px;
}

.fogged {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.521);
}
</style>