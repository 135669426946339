<template>
  <v-dialog v-model="isOpen" :persistent="isLoading" max-width="600">
    <v-card class="pa-4">
      <v-row no-gutters justify="center" class="mb-3">
        <v-icon :color="$prdStyles('color-primary')" x-large>
          mdi-information-outline
        </v-icon>
      </v-row>
      <h2 class="mb-6 text-center">{{ $t("TXT_MANAGEMENT_STORE") }}</h2>
      <v-row no-gutters>
        <v-col cols="12" >
          <Prd-tabs
            :configs="tabConfigs"
            @changedTab="tab = $event"
            class="mb-3"
          />
        </v-col>
      </v-row>
      <div class="content mb-3">
        <store-container
          v-if="linkedList.length > 0"
          :isLinkedList="true"
          :list="linkedList"
          class="mb-4"
        />
        <store-container
          v-if="unlinkedList.length > 0"
          :isLinkedList="false"
          :list="unlinkedList"
        />
      </div>
      <v-row no-gutters justify="end" class="gap-4">
        <Prd-btn
          :outlined="true"
          :title="$t('TXT_CANCEL')"
          :disabled="isLoading"
          @click="isOpen = false"
        />
        <Prd-btn
          :title="$t('TXT_SAVE')"
          :loading="isLoading"
          @click="fixChanges"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdTabs from "@/components/common/prd-tabs.vue";
import StoreContainer from "./components/store-container.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  components: { PrdTabs, StoreContainer, PrdBtn },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      tab: 0,
    };
  },
  computed: {
    tabConfigs() {
      return {
        showArrows: true,
        centerActive: true,
        tabs: this.changeList
          .filter(
            ({ linkedStores, unlinkedStores }) =>
              linkedStores.length > 0 || unlinkedStores.length > 0
          )
          .map((user) => {
            return { name: user.name };
          }),
      };
    },
    changeList() {
      return this.$store.state.storeManagementStore.changes;
    },
    linkedList() {
      return this.extractStoreName("linkedStores");
    },
    unlinkedList() {
      return this.extractStoreName("unlinkedStores");
    },
  },
  methods: {
    extractStoreName(arrayName) {
      if (!this.changeList.length || !this.changeList[this.tab]) return [];
      return (
        this.changeList[this.tab][arrayName]?.map((item) => item.description) ??
        []
      );
    },
    toggleControl() {
      this.isOpen = !this.isOpen;
    },

    async fixChanges() {
      this.isLoading = true;
      try {
        const changes = this.$store.getters.getChanges;
        await Promise.all(
          changes.map(
            async (user) => await this.$store.dispatch("updateStoreAcess", user)
          )
        );
        this.$store.commit("fixChanges", changes);
        this.$store.commit("removeAllSelectedUser");
        this.$store.commit("removeAllSelectedStore");
        this.$store.commit("clearChanges");
        this.isOpen = false;
        this.$store.commit("snackbarV2/set", {
          type: "success",
          message: this.$i18n.t("TXT_SUCCESS_WHEN_UPDATE_PERMISSION_FOR_USER"),
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  border-radius: 8px !important;
  background-color: $neutral-color-high-light;
  padding: 8px 16px;
}
</style>
