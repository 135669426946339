<template>
  <v-expansion-panel
    :class="[productBorder(headerData.semaphore), 'personalized-border', 'mt-2']"
  >
    <Granularity-header
      :product="product"
      :marginToHeader="marginToHeader"
      :priceToHeader="priceToHeader"
      :isLoading="isLoading"
      :headerData="headerData"
    />

    <Granularity-body
      :product="product"
      :pricingName="pricingName"
      @marginToHeader="marginToHeader = $event"
      @setPriceToHeader="priceToHeader = $event"
      @updatedSemaphore="updatedSemaphore = $event"
      @updateHeaderPricing="getHeaderData"
    />
  </v-expansion-panel>
</template>

<script>
import GranularityHeader from "./granularity-header.vue";
import GranularityBody from "./granularity-body.vue";
import service from "@/service/pricer/product-list";

const Service = new service();

export default {
  components: {
    GranularityHeader,
    GranularityBody,
  },
  props: ["product", "index", "pricingName"],
  data() {
    return {
      pricing: {},
      isLoading: false,
      marginToHeader: null,
      priceToHeader: null,
      updatedSemaphore: null,
      headerData: {
        displayName: null,
        regularPrice: null,
        lastSalePrice: null,
        isAIPrice: null,
        isManual: null,
        optimizedPrice: null,
        wholesalePrice: null,
        optimizedMargin: null,
        idealDiscount: null,
        competitivenessIndex: null,
        semaphore: null
      },
    };
  },
  computed: {
    semaphore() {
      return this.updatedSemaphore
        ? this.updatedSemaphore
        : this.headerData?.semaphore;
    },
  },

  methods: {
    productBorder(semaphore) {
      switch (semaphore) {
        case 0:
          return "info-products";

        case 1:
          return "approved-products";

        case 2:
          return "manual-products";

        case 3:
          return "negative-products";

        case 4:
          return "not-applied-rule-products";
      }
    },

    async getHeaderData() {
      this.isLoading = true;

      try {
        let response = await Service.getDataById(
          this.product.idEnterprisePricesProjection
        );
        this.formatData(response?.data?.result ?? null);
      } catch (error) {
        this.$handleError(error, "Erro ao buscar dados");
      }

      this.isLoading = false;
    },

    formatData(response) {
      if (!response) {
        return;
      }

      this.headerData = {
        displayName: response.displayName,
        regularPrice: response.regularPrice,
        lastSalePrice: response.lastSalePrice,
        isAIPrice: response.isAIPrice,
        isManual: response.isManual,
        optimizedPrice: response.optimizedPrice,
        wholesalePrice: response.wholesalePrice,
        optimizedMargin: response.optimizedMargin,
        idealDiscount: response.idealDiscount,
        competitivenessIndex: response.competitivenessIndex,
        semaphore: response.semaphore,
      };
    },
  },
  mounted() {
    this.getHeaderData();
  },
};
</script>

<style lang="scss" scoped>
.personalized-border {
  border-left: 6px solid;
}
.approved-products {
  border-color: $feedback-color-success-pure !important;
}
.negative-products {
  border-color: $feedback-color-warning-pure !important;
}
.manual-products {
  border-color: $feedback-color-helper-pure !important;
}
.info-products {
  border-color: $brand-color-primary-pure !important;
}
.not-applied-rule-products {
  border-color: #9510ac !important;
}
</style>