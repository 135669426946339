<template>
  <div>
    <Prd-loading-msg class="mt-4" v-show="someLoading" :text="loadingText" />
    <div v-show="!someLoading">
      <h4 v-if="idRule == 0" class="mb-4 pt-4">
        {{ $t("TXT_ADD_RULE").toUpperCase() }}
      </h4>

      <div>
        <Prd-text-field
          v-model="localDescription"
          :title="$t('TXT_DESCRIPTION')"
          :placeholder="$t('TXT_INSERT_HERE_RULE')"
          hide-details
        />
        <v-checkbox
          v-model="localIsAllProducts"
          :label="$t('TXT_SELECT_ALL_PRODUCTS_AND_CATEGORIES')"
          class="ma-0 pa-0 mt-4"
          hide-details
        />
      </div>

      <Filters-area
        :loadingRules="loadingRules"
        :idRule="parseInt(idRule)"
        :rule="ruleForFieldArea"
        :resetFieldsFlag="localResetFieldsFlag"
        @elasticity="$emit('updateElasticity', $event)"
        @filters="$emit('updateFilters', $event)"
        @curve="$emit('updateCurve', $event)"
      />

      <div class="border-title mt-8 mb-4">
        <h4>{{ $t("TXT_PARAMETERIZATIONS") }}</h4>
      </div>

      <Param-rules :idRule="idRule" />

      <v-row no-gutters justify="end">
        <Prd-btn
          :title="$t('TXT_CANCEL')"
          :outlined="true"
          class="mr-4"
          @click="cancelEditon"
        />
        <Prd-btn
          :title="textButton"
          :disabled="!localDescription || disableRulesActions"
          @click="$emit('saveRules')"
        />
      </v-row>
    </div>
  </div>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
import FiltersArea from "./components/filters-area.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import ParamRules from "./components/param-rules.vue";
import PrdLoadingMsg from "@/components/common/prd-loading-msg.vue";
import HandlerRules from "@/service/pricing-rules/create-rules.js";
const handlerRules = new HandlerRules();
export default {
  props: {
    idRule: {
      type: Number,
      default: 0,
    },
    resetFieldsFlag: {
      type: Boolean,
      default: false,
    },
    loadingRules: {
      type: Boolean,
      default: false,
    },
    loadingSavingChanges: {
      type: Boolean,
      default: false,
    },
    ruleForFieldArea: {
      type: Object,
      default: () => ({}),
    },
    description: {
      type: String,
      default: "",
    },
    isAllProducts: {
      type: Boolean,
      default: false,
    },
  },
  components: { PrdTextField, FiltersArea, PrdBtn, ParamRules, PrdLoadingMsg },
  data() {
    return {
      // request
      localDescription: null,
      localIsAllProducts: false,
      localNumberOrder: 0,
      localResetFieldsFlag: false,
    };
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    textButton() {
      return this.idRule
        ? this.$i18n.t("TXT_SAVE_EDITIONS")
        : this.$i18n.t("TXT_ADD_RULE");
    },
    loadingText() {
      return this.$i18n.t(
        !this.loadingSavingChanges && this.loadingRules
          ? "TXT_LOADING_DATA_RULE_GROUP"
          : this.loadingSavingChanges && !this.loadingRules
          ? "TXT_SAVING"
          : "TXT_LOADING_DATA"
      );
    },
    someLoading() {
      return this.loadingRules || this.loadingSavingChanges;
    },

    disableRulesActions() {
      return this.$store?.getters?.disableRulesActions;
    },
  },
  watch: {
    resetFieldsFlag: {
      handler(value) {
        this.localResetFieldsFlag = value;
      },
      immediate: true,
    },
    localResetFieldsFlag: {
      handler(value) {
        this.$emit("updateResetFieldsFlag", value);
      },
      immediate: true,
    },
    description: {
      handler(value) {
        this.localDescription = value;
      },
      immediate: true,
    },
    localDescription: {
      handler(value) {
        this.$emit("updateDescription", value);
      },
      immediate: true,
    },
    isAllProducts: {
      handler(value) {
        this.localIsAllProducts = value;
      },
      immediate: true,
    },
    localIsAllProducts: {
      handler(value) {
        this.$emit("updateIsAllProducts", value);
      },
      immediate: true,
    },
  },
  methods: {
    cancelEditon() {
      if (this.idRule == 0) {
        this.localResetFieldsFlag = !this.localResetFieldsFlag;
        this.$router.push("/main/pricing-rules");
      } else {
        this.$emit("closePanel");
      }
    },
    async getCompetitors() {
      this.$store.commit("updateSellerListLoading", true);

      try {
        const response = await handlerRules.getCompetitors(this.idCompany);
        this.$store.commit("updateSellerList", response.data.result);
      } catch (error) {
        if (
          error?.response?.data?.message ==
            "A sequência não contém elementos" ||
          error?.response?.data?.message == "Sequence contains no elements"
        )
          return;
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR"),
          type: "error",
        });
        this.$store.commit("updateSellerListLoading", false);
      }

      this.$store.commit("updateSellerListLoading", false);
    },
  },

  created() {
    this.getCompetitors();
  },
};
</script>

<style lang="scss" scoped>
.border-title {
  border-bottom: 2px solid #bab8b8;
  display: flex;
  h4 {
    border-bottom: 4px solid #ec4c37;
    margin-bottom: -2px;
  }
}
</style>