var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.localItems,"hide-default-footer":_vm.localItems == [] || _vm.localItems.length == 0,"footer-props":_vm.footerProps,"no-data-text":_vm.$t('TXT_NO_DATA_AVAILABLE')},scopedSlots:_vm._u([{key:"item.percentLMUSD",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.percentLMUSD + " %")+" ")]}},{key:"item.percentLMBRL",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.percentLMBRL + " %")+" ")]}},_vm._l((_vm.priceProperties),function(ref,index){
var name = ref.name;
var isUsd = ref.isUsd;
return {key:("item." + name),fn:function(ref){
var item = ref.item;
return [_c('span',{key:index},[_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item[name], isUsd)))])]}}})],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }