<template>
  <v-container fluid>
    <v-row class="d-flex flex-row justify-space-between">
      <h2 class="mb-4 text-body-1 font-weight-bold">
        {{ $t("TXT_SIMULATE_GAME") }}
      </h2>
      <prd-btn
        @click="clickBack"
        :title="$t('TXT_BACK')"
        :icon="'mdi-arrow-left'"
        :leftIcon="true"
        :smallIcon="true"
      />
    </v-row>
    <v-row v-if="enterpriseEvent" align="center">
      <div class="pa-4 card rounded-lg full-width">
        <!-- ERROS -->
        <div>
          <h4 class="font-weight-bold grid-column-span-4 mb-6">
            {{ enterpriseEvent.championship }} |
            {{ enterpriseEvent.home_team }} X {{ enterpriseEvent.guest_team }} |
            {{ enterpriseEventDate }}
          </h4>
          <div
            class="grid-column-span-4 color-error"
            v-if="service.listPlaces.state() === 'ERROR'"
          >
            {{ $t("TXT_ERROR_GET_STADIUMS") }}:
            {{ service.listPlaces.error.message || service.listPlaces.error }}
          </div>
          <div
            class="grid-column-span-4 color-error"
            v-if="service.listCrowding.state() === 'ERROR'"
          >
            {{ $t("TXT_ERROR_GET_SCENARIO") }}:
            {{
              service.listCrowding.error.message || service.listCrowding.error
            }}
          </div>
          <div
            class="grid-column-span-4 color-error"
            v-if="service.listTickets.state() === 'ERROR'"
          >
            {{ $t("TXT_ERROR_GET_TICKET_LIST") }}:
            {{ service.listTickets.error.message || service.listTickets.error }}
          </div>
          <div
            class="grid-column-span-4 color-error"
            v-if="service.place.state() === 'ERROR'"
          >
            {{ $t("TXT_ERROR_GET_DATA_STADIUM") }}:
            {{ service.place.error.message || service.place.error }}
          </div>
          <div
            class="grid-column-span-4 color-error"
            v-if="service.crowding.state() === 'ERROR'"
          >
            {{ $t("TXT_ERROR_GET_DATA_SCENARIO") }}:
            {{ service.crowding.error.message || service.crowding.error }}
          </div>

          <div style="display: flex; gap: 1rem">
            <!-- ESTÁDIO -->
            <combo-box
              :items="comboboxPlace"
              hideIcon
              :placeholder="$t('TXT_SELECT')"
              @input="selectPlace"
              :multiple="false"
              :loading="service.listPlaces.isLoading"
              :disabled="service.listPlaces.state() !== 'DATA_READY'"
            >
              <template v-slot:title> {{ $t("TXT_STADIUM") }} </template>
            </combo-box>

            <!-- CENÁRIO -->
            <combo-box
              :items="comboboxCrowding"
              hideIcon
              :placeholder="$t('TXT_SELECT')"
              @input="selectCrowding"
              :multiple="false"
              :loading="service.listCrowding.isLoading"
              :disabled="service.listCrowding.state() !== 'DATA_READY'"
            >
              <template v-slot:title>
                {{ $t("TXT_SCENARIO") }}
                <tooltip-informations :title="$t('TXT_PREDIFUT_SCENARIO')" />
              </template>
            </combo-box>

            <!-- PREVISÃO DE PUBLICO -->
            <text-field-with-suggestion
              v-model="publicPrediction"
              :suggestionState="service.getHistoryCalculated.state()"
              :disabled="!selectedPlace || !selectedCrowding"
              :format-on-blur="formatNumberOnBlur"
            >
              {{ $t("TXT_AUDIENCE_FORECASTING") }}
              <tooltip-informations
                :title="$t('TXT_PREDIFUT_PUBLIC_PREVISION')"
              />
              <template v-slot:suggestion>
                <span
                  v-if="
                    service.getHistoryCalculated.data &&
                    service.getHistoryCalculated.data.answer
                  "
                  @click="publicPredictionSuggestionClick()"
                  class="cursor"
                >
                  {{ $t("TXT_AUDIENCE_FORECASTING_BY_AI") }}:
                  {{ audiencePrediction }}
                  {{ $t("TXT_PEOPLE") }}
                </span>
              </template>
              <template v-slot:error>
                {{ $t("TXT_ERROR_GET_AUDIENCE_FORECASTING_BY_AI") }}
              </template>
            </text-field-with-suggestion>

            <!-- DESPESAS POR IA -->
            <text-field-with-suggestion
              v-model="expenses"
              :suggestionState="service.getHistoryCalculated.state()"
              :disabled="!selectedPlace || !selectedCrowding"
              :format-on-blur="formatMoneyOnBlur"
            >
              {{ $t("TXT_EXPENDITURE") }}
              <tooltip-informations :title="$t('TXT_PREDIFUT_EXPENSES')" />
              <template v-slot:prepend-inner>
                <span class="mt-1">R$</span>
              </template>
              <template v-slot:suggestion>
                <span
                  v-if="
                    service.getHistoryCalculated.data &&
                    service.getHistoryCalculated.data.answer
                  "
                  @click="expensesSuggestionClick"
                  class="cursor"
                >
                  {{ $t("TXT_EXPENDITURE_BY_AI") }}:
                  {{
                    formatMoney(
                      service.getHistoryCalculated.data.answer.expense
                    )
                  }}
                </span>
              </template>
              <template v-slot:error>
                {{ $t("TXT_ERROR_GET_EXPENDITURE_BY_AI") }}
              </template>
            </text-field-with-suggestion>
          </div>

          <v-row no-gutters align="center">
            <h4
              v-show="loadingSectors || sectors.length != 0"
              class="my-4 mr-4"
            >
              {{ $t("TXT_SECTORS").toUpperCase() }}
            </h4>
            <v-progress-circular
              v-if="loadingSectors"
              indeterminate
              :color="$prdStyles('color-primary')"
            >
            </v-progress-circular>
          </v-row>

          <!--Setores -->
          <div
            v-if="!loadingSectors && sectors.length != 0"
            style="display: flex; flex-wrap: wrap; gap: 1rem"
            class="mt-4"
          >
            <text-field
              v-for="sector in sectors"
              :key="sector.idEnterpriseEventPlace"
              v-model="sector.value"
              :format-on-blur="formatMoneyOnBlur"
              :disabled="!selectedPlace || !selectedCrowding"
              placeholder="xxxx,xx"
              style="flex: 0 0 calc(20% - 1rem)"
            >
              <template v-slot:prepend-inner>
                <span class="mt-1">R$</span>
              </template>
              <template v-slot>
                {{ sector.name }}
                <tooltip-informations :title="''" />
              </template>
            </text-field>
          </div>

          <div class="grid-column-span-4 actions-grid full-width">
            <checkbox-component
              label="Usar tabela informativa"
              class="ma-0"
              v-model="shouldUseInformativeTable"
            ></checkbox-component>
            <div
              v-show="shouldUseInformativeTable"
              class="grid-column-span-2 overflow-x-auto"
            >
              <informative-table
                :idCompany="idCompany"
                :isLoading="
                  this.service.listTickets.isLoading ||
                  this.service.place.isLoading ||
                  this.service.crowding.isLoading
                "
                :tickets="
                  this.service.listTickets.data &&
                  this.service.listTickets.data.answer
                "
                :place="
                  this.service.place.data && this.service.place.data.answer
                "
                :crowding="
                  this.service.crowding.data &&
                  this.service.crowding.data.answer
                "
                :error="
                  this.service.listTickets.error ||
                  this.service.place.error ||
                  this.service.crowding.error
                "
                @getItems="getItems"
                ref="informative-table"
              ></informative-table>
            </div>
            <div v-if="shouldUseInformativeTable"></div>
            <prd-btn
              title="Simular partida"
              @click="simulateGame"
              :disabled="disableSimulate || sectors.length == 0"
              class="justify-self-end"
            ></prd-btn>
          </div>
        </div>
      </div>
      <progress-component v-if="service.simulateGame.isLoading" class="ma-4" />

      <div v-else-if="service.simulateGame.error">
        {{ $t("TXT_FAIL_SIMULATE_GAME") }}:
        <div>{{ service.simulateGame.error }}</div>
      </div>

      <results-table
        v-else
        :simulationResponse="service.simulateGame.data"
        :publicPrediction="requestedPublicPrediction"
        :sectors="service.place.data && service.place.data.answer.sectors"
        :tickets="service.listTickets.data && service.listTickets.data.answer"
      />
    </v-row>
  </v-container>
</template>
<script>
import prdBtn from "@/Design_System/common/prd-btn";
import checkboxComponent from "@/Design_System/common/checkBoxComponent";
import comboBox from "@/Design_System/common/comboBox";
import textField from "@/Design_System/common/text-field";
import { formatDate } from "@/utils/format-date";
import PredifutService from "@/service/predifut-service";
import textFieldWithSuggestion from "./components/text-field-with-suggestion";
import informativeTable from "./components/informative-table";
import resultsTable from "./components/results-table";
import { formatMoney } from "@/utils/format-money";
import { validateMatchSimulation } from "./validation/simulate-match";
import progressComponent from "@/Design_System/common/progressComponent";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";

export default {
  components: {
    prdBtn,
    textFieldWithSuggestion,
    checkboxComponent,
    comboBox,
    textField,
    informativeTable,
    resultsTable,
    progressComponent,
    PrdBtn,
    TooltipInformations,
  },
  data: () => ({
    service: new PredifutService(),
    enterpriseEvent: null,
    selectedPlace: null,

    yellowPrice: null,
    redPrice: null,
    orangePrice: null,
    purplePrice: null,

    publicPrediction: null,
    contributionMargin: null,
    expenses: null,
    shouldUseInformativeTable: false,
    selectedCrowding: null,

    requestedPublicPrediction: null,

    sectors: [],
    ticketsToRequest: [],
    loadingSectors: false,
  }),
  methods: {
    clickBack() {
      this.$router.push("../select-game");
    },
    selectPlace({ value }) {
      this.selectedPlace = value;
    },
    selectCrowding({ value }) {
      this.selectedCrowding = value;
    },
    formatMoney,
    formatMoneyOnBlur(value) {
      if (!value) return null;
      let onlyNumbers = value.replace(/[^0-9.,]/g, "");
      onlyNumbers = onlyNumbers.replace(/[.,](?!\d\d$)/g, "");
      onlyNumbers = onlyNumbers.replace(/[.,](?=\d\d$)/, ".");
      return formatMoney(Number(onlyNumbers)).replace(/R\$(\s)*/g, "");
    },
    formatNumberOnBlur(value) {
      if (!value) return null;

      let onlyNumbers = value.replace(/[^0-9.,]/g, "");
      onlyNumbers = onlyNumbers.replace(/[.,](?!\d\d$)/g, "");
      onlyNumbers = onlyNumbers.replace(/[.,](?=\d\d$)/, ",");
      onlyNumbers = onlyNumbers.replace(
        /(\d)(?=(?:\d\d\d)+(?:,\d\d){0,1}$)/g,
        "$1."
      );
      return onlyNumbers;
    },
    publicPredictionSuggestionClick() {
      this.publicPrediction =
        this.service.getHistoryCalculated.data.answer.audience.toString();
    },
    getItems(data) {
      const localExpectedOutput = [];

      data.forEach((item) => {
        const selectedSectors = [];

        this.sectors.forEach((sector) => {
          if (item[this.transformStringToValue(sector.name)]) {
            selectedSectors.push(sector.idEnterpriseEventSector);
          }
        });

        localExpectedOutput.push({
          idEnterpriseEventTicket: item.idEnterpriseEventTicket,
          selected: selectedSectors,
          estimate_public: parseFloat(item.expectedPublic),
        });
      });

      this.ticketsToRequest = localExpectedOutput;
    },
    transformStringToValue(string) {
      string = string.toLowerCase().replace(/ /g, "_");
      return string;
    },
    expensesSuggestionClick() {
      this.expenses =
        this.service.getHistoryCalculated.data.answer.expense.toString();
    },
    async fetchPlaces() {
      await this.service.listPlaces.safeCall(this.idCompany);
    },
    async fetchCrowdings() {
      await this.service.listCrowding.safeCall(
        this.idCompany,
        this.selectedPlace.idEnterpriseEventPlace
      );
    },
    async fetchHistoryCalculated() {
      await this.service.getHistoryCalculated.safeCall(
        this.idCompany,
        this.enterpriseEvent.idEnterpriseEventHistory
      );
    },
    async simulateGame() {
      this.$refs["informative-table"].getItems();

      this.shouldUseInformativeTable = false;
      const idEnterpriseEventHistory =
        this.enterpriseEventHistory.idEnterpriseEventHistory;

      const errors = validateMatchSimulation(this);
      if (errors) {
        this.$store.commit("snackbarV2/set", {
          message: errors,
          type: "error",
        });
        return;
      }

      function prepareNumber(numString) {
        return Number(
          numString.replace(/\D(?!\d\d$)/g, "").replace(/,(?=\d\d$)/, ".")
        );
      }

      const data = {
        idCompany: this.idCompany,
        idEnterpriseEventPlace: this.selectedPlace.idEnterpriseEventPlace,
        audience: prepareNumber(this.publicPrediction),
        expense: prepareNumber(this.expenses),
        idEnterpriseEventHistory: idEnterpriseEventHistory,
        crowding: {
          capacity: this.service.crowding.data.answer.capacity,
          idEnterpriseEventCrowding:
            this.selectedCrowding.idEnterpriseEventCrowding,
        },
        sector_prices: this.setRequestForSectors(this.sectors),
        tickets: this.ticketsToRequest,
      };

      this.requestedPublicPrediction = data.audience;

      this.service.simulateGame.call(data);
    },

    setRequestForSectors(sectors) {
      let sectorsArray = [];

      sectors.forEach((el) => {
        let sectorObj = {
          sector_id: el.idEnterpriseEventSector,
          price: parseFloat(el.value) ?? null,
        };

        sectorsArray.push(sectorObj);
      });

      return sectorsArray;
    },
    async fetchTickets() {
      this.service.listTickets.safeCall(this.idCompany);
    },
    async fetchPlace() {
      if (!this.selectedPlace) return;
      this.service.place.safeCall(
        this.idCompany,
        this.selectedPlace.idEnterpriseEventPlace
      );
    },
    async fetchCrowding() {
      if (!this.selectedCrowding) return;
      this.service.crowding.safeCall(
        this.idCompany,
        this.selectedCrowding.idEnterpriseEventCrowding
      );
    },

    fetchSectors() {
      if (!this.selectedPlace) return;
      this.loadingSectors = true;

      this.service
        .listSectors(this.idCompany, this.selectedPlace.idEnterpriseEventPlace)
        .then((res) => {
          let response = res?.data?.answer ?? [];
          response.forEach((el) => {
            el.value = null;
          });
          this.sectors = response;
          this.loadingSectors = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingSectors = false;
        });
    },
  },
  watch: {
    selectedPlace() {
      this.fetchCrowdings();
      this.fetchPlace();
      this.fetchSectors();
    },
    selectedCrowding() {
      this.fetchCrowding();
    },
  },
  computed: {
    disableSimulate() {
      let foundedItem = false;

      this.sectors.find((el) => {
        if (el.value == "" || !el.value) {
          foundedItem = true;
        }
      });

      return foundedItem;
    },
    audiencePrediction() {
      if (
        !this.service.getHistoryCalculated.data ||
        !this.service.getHistoryCalculated.data.answer
      )
        return 0;
      return this.formatNumberOnBlur(
        Math.round(
          this.service.getHistoryCalculated.data.answer.audience
        ).toString()
      );
    },
    enterpriseEventDate() {
      // Cannot continue in this case
      if (!this.enterpriseEvent) return "";

      return formatDate(
        new Date(this.enterpriseEvent.date),
        "dd/MM/yyyy - hh:mm:ss"
      );
    },
    comboboxPlace() {
      if (this.service.listPlaces.state() !== "DATA_READY") return [];

      return this.service.listPlaces.data.answer.map((place) => ({
        text: place.name,
        value: place,
      }));
    },
    comboboxCrowding() {
      if (this.service.listCrowding.state() !== "DATA_READY") return [];

      return this.service.listCrowding.data.answer.map((crowding) => ({
        text: crowding.name,
        value: crowding,
      }));
    },

    // Store accesses
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    enterpriseEventHistory() {
      return this.$store.state.predifutStore.enterpriseEventHistory;
    },
  },
  created() {
    const enterpriseEvent = this.enterpriseEventHistory;

    // Cannot continue in this case. Return to the select-game screen
    if (!enterpriseEvent) {
      this.$router.push("/main/predifut/select-game");
      return;
    }

    this.enterpriseEvent = enterpriseEvent;

    this.fetchPlaces();
    this.fetchTickets();
    this.fetchHistoryCalculated();
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: $neutral-color-high-pure;
}
.button-text {
  font-size: 18px;
}
.gap-7 {
  column-gap: 28px;
  row-gap: 28px;
}
.combobox-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.grid-column-span-4 {
  grid-column: span 4;
}
.grid-column-span-2 {
  grid-column: span 2;
}
.full-width {
  width: 100%;
}
.actions-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.overflow-x-auto {
  overflow-x: auto;
}
.justify-self-end {
  justify-self: flex-end;
}
.color-error {
  color: $feedback-color-warning-pure;
}
.cursor {
  cursor: pointer;
}
</style>
