<template>
  <prd-btn @click="$emit('click', $event)" v-bind="$attrs">
    <template v-if="isLoading" v-slot:icon-after>
      <progress-component :size="24" :width="3" class="ml-2" />
    </template>
    <template v-if="$slots.title" v-slot:title>
      <slot name="title" />
    </template>
  </prd-btn>
</template>
<script>
import prdBtn from "@/Design_System/common/prd-btn";
import progressComponent from "@/Design_System/common/progressComponent";

export default {
  components: {
    prdBtn,
    progressComponent,
  },
  props: ["isLoading"],
};
</script>
<style lang="scss" scoped></style>
