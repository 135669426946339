<template>
  <v-col>
    <v-row no-gutters align="center">
      <v-col cols="auto">
        <h4>{{ name }}</h4>
        <label>{{ subtitle }}</label>
      </v-col>
      <v-spacer></v-spacer>
      <Prd-btn
        v-if="!isLoading"
        :title="$t('TXT_EXTRACT_FILE')"
        :class="isAdminUpl ? 'mr-4' : ''"
        :loading="reportLoading"
        :disabled="reportLoading || inEditMode"
        @click="extractMatrix(items)"
      />

      <v-col cols="auto" v-if="isAdminUpl">
        <Prd-btn
          v-if="!inEditMode"
          :title="$t('TXT_EDIT_DATA')"
          @click="inEditMode = !inEditMode"
        />

        <v-row v-else no-gutters class="gap-4" align="center">
          <span v-if="isLoading">{{ $t("TXT_SAVING_DATA") }}</span>
          <prd-loading-circular v-if="isLoading" />
          <Prd-btn
            v-if="inEditMode"
            :title="$t('TXT_SAVE')"
            :disabled="isLoading || !unlockSaveButton"
            @click="saveEditions"
          />
          <Prd-btn
            v-if="inEditMode"
            :title="$t('TXT_CANCEL')"
            :outlined="true"
            :disabled="isLoading"
            @click="resetTable"
          />
        </v-row>
      </v-col>
    </v-row>

    <v-data-table
      class="mt-4"
      :headers="headers"
      :items="localItems"
      :hide-default-footer="localItems == [] || localItems.length == 0"
      :footer-props="footerProps"
      :no-data-text="$t('TXT_NO_DATA_AVAILABLE')"
    >
      <template v-slot:[`item.portifolioStatus`]="{ item }">
        <span>{{ setPortifolioStatus(item.portifolioStatus) }}</span>
      </template>
      <template v-slot:[`item.triangle`]="{ item }">
        <span v-if="!inEditMode">{{ item.triangle + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.triangle"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.rioVerde`]="{ item }">
        <span v-if="!inEditMode">{{ item.rioVerde + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.rioVerde"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.goiania`]="{ item }">
        <span v-if="!inEditMode">{{ item.goiania + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.goiania"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.bahia`]="{ item }">
        <span v-if="!inEditMode">{{ item.bahia + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.bahia"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.grCliCerradoLeste`]="{ item }">
        <span v-if="!inEditMode">{{ item.grCliCerradoLeste + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.grCliCerradoLeste"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.kaCerradoOeste`]="{ item }">
        <span v-if="!inEditMode">{{ item.kaCerradoOeste + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.kaCerradoOeste"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.primavera`]="{ item }">
        <span v-if="!inEditMode">{{ item.primavera + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.primavera"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.vale`]="{ item }">
        <span v-if="!inEditMode">{{ item.vale + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.vale"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.sorriso`]="{ item }">
        <span v-if="!inEditMode">{{ item.sorriso + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.sorriso"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.tangara`]="{ item }">
        <span v-if="!inEditMode">{{ item.tangara + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.tangara"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.litoralEFronteira`]="{ item }">
        <span v-if="!inEditMode">{{ item.litoralEFronteira + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.litoralEFronteira"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.missoes`]="{ item }">
        <span v-if="!inEditMode">{{ item.missoes + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.missoes"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.planalto`]="{ item }">
        <span v-if="!inEditMode">{{ item.planalto + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.planalto"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.prNorte`]="{ item }">
        <span v-if="!inEditMode">{{ item.prNorte + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.prNorte"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.prSul`]="{ item }">
        <span v-if="!inEditMode">{{ item.prSul + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.prSul"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.prOeste`]="{ item }">
        <span v-if="!inEditMode">{{ item.prOeste + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.prOeste"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.cafeECitrus`]="{ item }">
        <span v-if="!inEditMode">{{ item.cafeECitrus + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.cafeECitrus"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.hfEAgroindustrias`]="{ item }">
        <span v-if="!inEditMode">{{ item.hfEAgroindustrias + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.hfEAgroindustrias"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.nordesteEspec`]="{ item }">
        <span v-if="!inEditMode">{{ item.nordesteEspec + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.nordesteEspec"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.canaSul`]="{ item }">
        <span v-if="!inEditMode">{{ item.canaSul + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.canaSul"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.canaNorte`]="{ item }">
        <span v-if="!inEditMode">{{ item.canaNorte + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.canaNorte"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.pastagemGo`]="{ item }">
        <span v-if="!inEditMode">{{ item.pastagemGo + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.pastagemGo"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.pastagemCuiaba`]="{ item }">
        <span v-if="!inEditMode">{{ item.pastagemCuiaba + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.pastagemCuiaba"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.b2b`]="{ item }">
        <span v-if="!inEditMode">{{ item.b2b + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.b2b"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.mapi`]="{ item }">
        <span v-if="!inEditMode">{{ item.mapi + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.mapi"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.palmas`]="{ item }">
        <span v-if="!inEditMode">{{ item.palmas + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.palmas"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.sulDoMs`]="{ item }">
        <span v-if="!inEditMode">{{ item.sulDoMs + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.sulDoMs"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
      <template v-slot:[`item.supervisorDeNegocios`]="{ item }">
        <span v-if="!inEditMode">{{ item.supervisorDeNegocios + "%" }}</span>
        <Prd-text-field
          v-else
          v-model="item.supervisorDeNegocios"
          :suffix="'%'"
          :type="'number'"
          :hide-spin-button="true"
          :disabled="isLoading"
        />
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import PrdTextField from "@/components/common/prd-text-field.vue";
import UPLService from "@/service/upl";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
export default {
  components: { PrdBtn, PrdTextField, PrdLoadingCircular },
  props: {
    name: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      reportLoading: false,
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
      localItems: [],
      inEditMode: false,
      service: new UPLService(),
      isLoading: false,
      portifolioStatusItems: [
        { text: "Phase Out", value: 0 },
        { text: "Active", value: 1 },
        { text: "Pendente", value: 2 },
      ],
    };
  },

  computed: {
    isAdminUpl() {
      return (
        this.$store.getters.Acesso_E_Edicao_UPL_Geral ||
        this.$store.getters.isPredifyAdmin
      );
    },
    unlockSaveButton() {
      const updatedItems = this.getUpdatedDatas();
      return updatedItems.length > 0;
    },
  },

  watch: {
    items: {
      handler(value) {
        this.localItems = JSON.parse(JSON.stringify(value));
      },
      immediate: true,
      deep: true,
    },
    inEditMode: {
      handler(value) {
        this.$emit("setBadgeRegionalizationMatrix", value);
      },
      immediate: true,
    },
  },

  methods: {
    async extractMatrix() {
      this.reportLoading = true;
      try {
        let response = await this.service.extractRegionalMatrix();
        let formatedData = [];

        response.data.forEach((el) => {
          formatedData.push({
            "Código da regional": el.reginalCode,
            Marca: el.brand,
            variation: el.variation,
          });
        });

        const header = Object.keys(formatedData[0]).join(";");
        const csv = formatedData
          .map((row) => Object.values(row).join(";"))
          .join("\n");
        const csvData = "data:text/csv;charset=utf-8," + header + "\n" + csv;
        const encodedUri = encodeURI(csvData);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.log(error);
      }

      this.reportLoading = false;
    },
    convertArrayToCSV(array) {
      let csv = "";
      const header = Object.keys(array[0]).join(",") + "\n";
      csv += header;
      array.forEach((item) => {
        const row = Object.values(item).join(",") + "\n";
        csv += row;
      });
      return csv;
    },
    setPortifolioStatus(number) {
      return this.portifolioStatusItems.find((status) => status.value == number)
        .text;
    },
    resetTable() {
      this.localItems = JSON.parse(JSON.stringify(this.items));
      this.inEditMode = false;
    },
    async saveEditions() {
      this.isLoading = true;
      try {
        const updatedItems = this.getUpdatedDatas();
        if (updatedItems.length > 0) {
          await this.service.updateDeliberationRegionalizationMatrix(
            updatedItems
          );
          this.$emit("updateAllOriginalItems", this.localItems);
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_UPDATED_DATA_SUCCESS"),
            type: "success",
          });
          this.inEditMode = false;
        }
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_UPDATE_DATAS"),
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    getUpdatedDatas() {
      let updatedItems = [];
      this.items.forEach((originalItem) => {
        const foundeditem = this.localItems.find(
          (localItem) => localItem.id == originalItem.id
        );
        if (foundeditem) {
          const stringifyFoundedItem = JSON.stringify(foundeditem);
          const stringifyOriginalItem = JSON.stringify(originalItem);

          if (stringifyFoundedItem != stringifyOriginalItem)
            updatedItems.push(foundeditem);
        }
      });
      return updatedItems;
    },
  },
};
</script>