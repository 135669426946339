<template>
  <v-row no-gutters align="center">
    <v-progress-circular
      v-if="isLoading"
      indeterminate
      :color="$prdStyles('color-primary')"
      class="mr-2"
    />
    <Last-updated
      v-else
      :date="lastUpdated ? lastUpdated.date : ''"
      :hour="lastUpdated ? lastUpdated.hour : ''"
      :changeLog="lastUpdated ? lastUpdated.changeLog : ''"
      :productName="lastUpdated ? lastUpdated.productName : ''"
      :userName="lastUpdated ? lastUpdated.userName : ''"
    />
    <v-icon
      @click="$emit('openModal')"
      :class="`prd-icon ${isLoading ? 'disabled' : ''}`"
      :disabled="isLoading"
      >mdi-history</v-icon
    >
  </v-row>
</template>

<script>
import LastUpdated from "./components/last-updated.vue";
export default {
  components: { LastUpdated },
  props: {
    isLoading: {
      type: Boolean,
    },
    lastUpdated: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.prd-icon {
  color: white;
  background-color: $brand-color-primary-pure;
  border-radius: 5px;
  padding: 2px;
}
.disabled {
  background-color: $neutral-color-high-dark;
}
</style>