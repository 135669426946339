import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class CreateRules extends ServiceBase {

    async getFilters(idCompany) {
        return await axiosInstance.get(API_URL + `api/v2/Enterprise/PriceGroups/Filter?idCompany=${idCompany}`);
    }

    async saveRule(request) {
        return await axiosInstance.post(API_URL + `api/v2/DynamicPricingRules/CUDRule`, request);
    }

    async updateFilter(request) {
        return await axiosInstance.post(API_URL + `api/v2/DynamicPricingRules/UpdateFilter`, request);
    }

    async deleteRule(idCompany, idRule) {
        return await axiosInstance.delete(
            API_URL +
            `api/v2/DynamicPricingRules/Delete?idCompany=${idCompany}&idRule=${idRule}`
        );
    }

    async deleteRuleFromGroup(idCompany, idRule, idCondition) {
        return await axiosInstance.get(
            API_URL +
            `api/DinPreRules/DeleteCondition?idCompany=${idCompany}&idRule=${idRule}&idCondition=${idCondition}`
        );
    }

    async addRuleInGroup(request) {
        return await axiosInstance.post(API_URL + `api/DinPreRules/AddCondition`, request);
    }

    async getRule(idCompany, idRule) {
        return await axiosInstance.get(API_URL + `api/v2/DynamicPricingRules/GetRule?idCompany=${idCompany}&idRule=${idRule}`);
    }

    async getCompetitors(idCompany) {
        return await axiosInstance.get(API_URL + `api/v2/DynamicPricingRules/GetSellers?idCompany=${idCompany}`);
    }

}