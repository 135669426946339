var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4 mt-4",attrs:{"id":"currentPrice"}},[_c('v-row',[_c('v-col',{attrs:{"md":"3"}},[_c('Prd-data-picker',{attrs:{"hasTooltip":false,"externalDisabled":_vm.isLoading},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTable),expression:"showTable"}],staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.predPlattsList,"loading":_vm.isLoading,"footer-props":_vm.footerProps,"loading-text":'Carregando dados',"no-data-text":_vm.$t('TXT_NO_DATA_FOUND_DISPLAY'),"no-results-text":_vm.$t('TXT_NO_DATA_FOUND_DISPLAY'),"items-per-page":10000000,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.ref_date, false))+" ")]}},{key:"item.predicted_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMonetary(item.predicted_value))+" ")]}},{key:"item.lower_limit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMonetary(item.lower_limit))+" ")]}},{key:"item.upper_limit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMonetary(item.upper_limit))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }