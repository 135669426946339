<template>
  <v-dialog max-width="500" v-model="showDeleteFilterDialog" persistent>
    <v-card class="pa-4" :disabled="deleteFilterLoading">
      <h4 class="mb-4">{{ $t("TXT_EXCLUDE_FILTER").toUpperCase() }}</h4>
      <span
        >{{ $t("TXT_DELETE_CONFIRM") }}
        <span class="font-weight-bold">"{{ filterName }}"</span>?</span
      >
      <br />
      <br />
      <span class="danger-color">{{ $t("TXT_ACTION_CANNOT_UNDONE") }}</span>

      <v-row no-gutters class="mt-6">
        <v-spacer></v-spacer>
        <prd-btn
          @click="showDeleteFilterDialog = false"
          :outlined="true"
          :title="$t('TXT_CANCEL')"
          class="mr-4"
        />
        <prd-btn
          :loading="deleteFilterLoading"
          @click="$emit('deleteFilter')"
          :title="$t('TXT_EXCLUDE')"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  props: ["selectedFilter", "deleteFilterLoading"],
  components: { prdBtn },
  data() {
    return {
      showDeleteFilterDialog: false,
    };
  },
  computed: {
    filterName() {
      if (!this.selectedFilter) return null;
      return this.selectedFilter.name;
    },
  },
  methods: {
    openDialog() {
      this.showDeleteFilterDialog = true;
    },

    closeDialog() {
      this.showDeleteFilterDialog = false;
    },

    deleteFilter() {},
  },
};
</script>

<style lang="scss" scoped>
.danger-color {
  color: $brand-color-secondary-pure;
}
</style>