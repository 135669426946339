<template>
  <v-row no-gutters class="gap-2">
    <v-col cols="auto">
      <span class="font-weight-bold">{{ $t("TXT_CATEGORY") }}:</span>
    </v-col>
    <v-col>
      <v-row no-gutters>
        <v-col v-for="(category, index) in categories" :key="index" cols="4">
          <v-checkbox
            v-model="selectedCategories"
            :value="category.value"
            :label="category.text"
            hide-details
            dense
            :disabled="isDisabled"
            class="pa-0 ma-0 mb-2"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["isDisabled"],
  data() {
    return {
      selectedCategories: [],
      categories: [
        { text: this.$i18n.t("TXT_ALL_2"), value: "TODAS" },
        { text: this.$i18n.t("TXT_ALCOHOL"), value: "BEBIDAS ALCOÓLICAS" },
        {
          text: this.$i18n.t("TXT_NON_ALCOHOLIC_BEVARAGES"),
          value: "BEBIDAS NÃO ALCOÓLICAS",
        },
        {
          text: this.$i18n.t("TXT_BISCUIT_SNACKS"),
          value: "BISCOITOS E SNACKS",
        },
        { text: "Bomboniere", value: "BOMBONIERE" },
        { text: this.$i18n.t("TXT_FROZEN"), value: "CONGELADOS" },
      ],
    };
  },
  watch: {
    selectedCategories: {
      handler(value) {
        this.$emit("selectedCategories", value);
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style>
</style>