<template>
  <v-card class="pa-4">
    <h4 class="mb-4">{{ this.$i18n.t("TXT_AD_TRACKING_TITLE") }}</h4>
    <div class="product-info pa-4 mb-6">
      <h5 class="mb-4">{{ productName }}</h5>
      <span class="font-weight-bold mb-2">$ {{ price }}</span>
      <span
        >{{ this.$i18n.t("TXT_MARKET") }} : <span>{{ marketplace }}</span> |
        {{ this.$i18n.t("TXT_SELLER") }} : <span>{{ seller }}</span></span
      >
    </div>
    <!-- VARIATION TYPE -->
    <v-row no-gutters class="mb-4">
      <v-col>
        <prd-combo-box
          v-model="data.variationType"
          :items="variationTypeItems"
          :title="this.$i18n.t('TXT_SELECT_VARIATION')"
        />
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-4">
      <!-- INCREASE -->
      <v-col md="4" class="mr-4">
        <label>{{
          data.variationType.value == 1
            ? this.$i18n.t("TXT_MARKET")
            : this.$i18n.t("TXT_VALUE_INCREASE")
        }}</label>
        <v-text-field
          v-model="data.increaseValue"
          :prefix="data.variationType.value == 1 ? '%' : '$'"
          type="number"
          dense
          outlined
          hide-details
          class="mt-1"
        >
        </v-text-field>
      </v-col>

      <!-- DECREASE -->
      <v-col md="4">
        <label>{{
          data.variationType.value == 1
            ? this.$i18n.t("TXT_PERCENTAGE_DROP")
            : this.$i18n.t("TXT_VALUE_DROP")
        }}</label>
        <v-text-field
          v-model="data.decreaseValue"
          :prefix="data.variationType.value == 1 ? '%' : '$'"
          type="number"
          dense
          outlined
          hide-details
          class="mt-1"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <!-- RESOURCE  GROUP -->
    <v-row no-gutters>
      <v-col>
        <label>
          {{ this.$i18n.t("TXT_SELECT_USER_GROUP") }}
        </label>
        <v-combobox
          v-model="data.users"
          :items="resourceGroupArray"
          outlined
          dense
          append-icon="mdi-chevron-down"
          hide-details
          multiple
          deletable-chips
        >
          <template v-slot:[`item`]="{ item }">
            <v-icon left color="#FFC700">{{
              !item.idResourceGroup
                ? "mdi-account-outline"
                : "mdi-account-group-outline"
            }}</v-icon>
            {{ item.name }}
          </template>

          <template v-slot:[`selection`]="{ item, index }">
            <v-chip
              v-if="!item.isDeleted"
              :key="
                item.idResourceGroup ? item.idResourceGroup : item.idUsuario
              "
              label
              close
              @click:close="removeProduct(item, index)"
            >
              <v-icon left color="#FFC700">
                {{
                  !item.idResourceGroup
                    ? "mdi-account-outline"
                    : "mdi-account-group-outline"
                }}
              </v-icon>
              {{ item.name }}
            </v-chip>
          </template>
        </v-combobox>
      </v-col>
    </v-row>

    <v-card-actions class="pa-0 mt-6">
      <v-spacer></v-spacer>
      <prd-btn
        @click.stop="cancelChanges()"
        :outlined="true"
        :title="this.$i18n.t('TXT_CANCEL')"
        class="mr-4"
      />
      <prd-btn @click="saveChanges" :title="this.$i18n.t('TXT_SAVE_CHANGES')" />
    </v-card-actions>
  </v-card>
</template>

<script>
import prdComboBox from "../../../../../../../../Design_System/common/prd-comboBox.vue";
import prdBtn from "../../../../../../../../Design_System/common/prd-btn.vue";
import PredimonitorService from "@/service/predimonitor/price-alert-service";

export default {
  components: { prdComboBox, prdBtn },
  props: [
    "add",
    "idMonitoring",
    "isModalOpen",
    "resourceGroup",
    "userList",
    "listaDeAlertas",
    "priceCardLoading",
  ],
  data() {
    return {
      newPredimonitorService: new PredimonitorService(),

      variationTypeItems: [
        {
          text: this.$i18n.t("TXT_PERCENTAGE"),
          value: 1,
        },
        {
          text: this.$i18n.t("TXT_ABSOLUTE_VALUE"),
          value: 0,
        },
      ],
      userGroup: [
        {
          text: "Grupo 1",
          value: "group1",
          type: "group",
        },
        {
          text: "People2",
          value: "people2",
          type: "people",
        },
      ],

      data: {
        increaseValue: null,
        decreaseValue: null,
        variationType: {
          text: this.$i18n.t("TXT_ABSOLUTE_VALUE"),
          value: 0,
        },
        users: null,
        monitoringType: 0,
        isDeleted: false,
      },
    };
  },

  watch: {
    priceCardLoading() {
      if (this.priceCardLoading == true) {
        this.$emit("closeModal");
      }
    },
  },

  computed: {
    productName() {
      if (!this.add) return null;

      return this.add.productName;
    },
    price() {
      if (!this.add) return null;

      return this.add.price;
    },
    marketplace() {
      if (!this.add) return null;

      return this.add.marketplace;
    },
    seller() {
      if (!this.add) return null;

      return this.add.sellerName;
    },
    resourceGroupArray() {
      if (!this.resourceGroup || !this.userList) return [];
      let data = [];

      let newUserList = this.userList.map((obj) => {
        return {
          name: obj.nome,
          idUsuario: obj.idUsuario,
          isDeleted: false,
          idUser: null,
          idMonitoringItemPriceAlert: null,
          idMonitoringItemPriceAlertUsers: null,
        };
      });

      let newGroupList = this.resourceGroup.map((obj) => {
        return {
          idResourceGroup: obj.idResourceGroup,
          name: obj.name,
          isDeleted: false,
          idUser: null,
          idMonitoringItemPriceAlert: null,
          idMonitoringItemPriceAlertUsers: null,
        };
      });

      newGroupList.forEach((el) => {
        data.push(el);
      });

      newUserList.forEach((el) => {
        data.push(el);
      });
      return data;
    },
  },

  methods: {
    async saveChanges() {
      try {
        let request = await this.buildRequest(this.data);
        let newPredimonitorService = new PredimonitorService();

        newPredimonitorService.savePriceAlert(request).then((res) => {
          if (res.status !== 500) {
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_ITEM_SAVE"),
              type: "success",
            });
          } else {
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_ITEM_SAVE_PROBLEM"),
              type: "error",
            });
          }
        });
      } catch (error) {
        console.log("erro no salvar anuncio", error);
      }
    },

    setDataToCard() {
      if (this.listaDeAlertas) {
        this.data = { ...this.listaDeAlertas }; // Copia os dados recebidos para a propriedade "data"
        this.data.variationType = this.setVariationType(
          this.listaDeAlertas.variationType
        );
        this.data.monitoringType = this.type;

        this.data.users = this.listaDeAlertas.users?.map((user) => {
          let name = "";
          if (user.idUser) {
            const matchingResourceGroup = this.resourceGroupArray.find(
              (rg) => rg.idUsuario === user.idUser
            );
            if (matchingResourceGroup) {
              name = matchingResourceGroup.name;
            }
          } else if (user.idResourceGroup) {
            const matchingResourceGroup = this.resourceGroupArray.find(
              (rg) => rg.idResourceGroup === user.idResourceGroup
            );
            if (matchingResourceGroup) {
              name = matchingResourceGroup.name;
            }
          }

          return {
            idResourceGroup: user.idResourceGroup,
            idUser: user.idUser,
            name: name,
            idMonitoringItemPriceAlert: user.idMonitoringItemPriceAlert,
            idMonitoringItemPriceAlertUsers:
              user.idMonitoringItemPriceAlertUsers,
            isDeleted: false,
          };
        });
      }
    },

    cancelChanges() {
      this.$emit("closeModal");
    },

    buildRequest(el) {
      let request = {};
      let resourceGroup = [];
      request = {
        idMonitoringItemPriceAlert: el.idMonitoringItemPriceAlert
          ? el.idMonitoringItemPriceAlert
          : 0,
        idMonitoringItem: this.idMonitoring,
        productLink: this.add.productLink,
        productSeller: this.add.sellerName,
        variationType: el.variationType.value,
        monitoringType: 0,
        increaseValue: parseFloat(el.increaseValue),
        decreaseValue: parseFloat(el.decreaseValue),
        isParentItem: false,
        userOrResouceGroup: [],
        isDeleted: el.isDeleted,
      };

      el.users?.forEach((item) => {
        let teste = {};
        teste = {
          idUser: item.idResourceGroup ? null : item.idUsuario,
          idResourceGroup: item.idResourceGroup ? item.idResourceGroup : null,
          idMonitoringItemPriceAlert: el.idMonitoringItemPriceAlert
            ? el.idMonitoringItemPriceAlert
            : 0,
          idMonitoringItemPriceAlertUsers: item.idMonitoringItemPriceAlertUsers
            ? item.idMonitoringItemPriceAlertUsers
            : 0,
          isDeleted: item.isDeleted,
        };

        resourceGroup.push(teste);
        request.userOrResouceGroup = resourceGroup;
      });
      return request;
    },

    setVariationType(type) {
      let data = {
        text:
          type == 1
            ? this.$i18n.t("TXT_PERCENTAGE")
            : this.$i18n.t("TXT_ABSOLUTE_VALUE"),
        value: type == 1 ? 1 : 0,
      };

      return data;
    },

    removeProduct(item, index) {
      if (
        !item.idMonitoringItemPriceAlert &&
        !item.idMonitoringItemPriceAlertUsers
      ) {
        if (index >= 0) this.data.users.splice(index, 1);
      } else {
        item.isDeleted = true;
      }
    },
  },

  created() {
    this.setDataToCard();
  },
};
</script>

<style lang="scss" scoped>
.product-info {
  display: flex;
  flex-direction: column;
  background-color: $neutral-color-high-light;
}
</style>