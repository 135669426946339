const mimeTypes = {
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  csv: "text/csv",
};

const getMimeTypeForExtension = (extension) => {
  return mimeTypes[extension];
};

/**
 * Checks if the file matches any of the accepted file types.
 *
 * @param {string[]} acceptedTypes - An array of accepted file extensions (e.g., ['xlsx', 'csv']).
 * @param {File} file - The file to be checked.
 * @return {boolean} Returns true if the file type matches one of the accepted types, otherwise false.
 */
export function checkFileType(acceptedTypes, file) {
  if (!file || !acceptedTypes?.length) return false;

  return acceptedTypes.some(
    (extension) => file.type === getMimeTypeForExtension(extension)
  );
}
