<template>
  <v-data-table
    :headers="headers"
    :items="localItems"
    :hide-default-footer="localItems == [] || localItems.length == 0"
    :footer-props="footerProps"
    :no-data-text="$t('TXT_NO_DATA_AVAILABLE')"
  >
    <template v-slot:[`item.percentLMUSD`]="{ item }">
      {{ item.percentLMUSD + " %" }}
    </template>
    <template v-slot:[`item.percentLMBRL`]="{ item }">
      {{ item.percentLMBRL + " %" }}
    </template>
    <template
      v-for="({ name, isUsd }, index) in priceProperties"
      v-slot:[`item.${name}`]="{ item }"
    >
      <span :key="index"> {{ setCurrencyUPL(item[name], isUsd) }}</span>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    localItems: { type: Array, default: () => [], required: true },
    headers: { type: Array, default: () => [], required: true },
    setCurrencyUPL: { type: Function, default: () => "-", required: true },
  },
  data() {
    return {
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
      priceProperties: [
        { name: "grossUnitPriceFvBrlGeneralChannel", isUsd: false },
        { name: "grossUnitPriceFvBrlKaVf", isUsd: false },
        { name: "netUnitPriceFvBrlGeneralChannel", isUsd: false },
        { name: "netUnitPriceFvBrlKaVf", isUsd: false },
        { name: "minGrossPriceFvBrlGeneralChannel", isUsd: false },
        { name: "minGrossUnitPriceFvBrlKaVf", isUsd: false },
        { name: "minNetPriceFvBrlGeneralChannel", isUsd: false },
        { name: "minNetUnitPriceFvBrlKaVf", isUsd: false },
        { name: "grossUnitPricePvBrlGeneralChannel", isUsd: false },
        { name: "grossUnitPricePvBrlKaVf", isUsd: false },
        { name: "netUnitPricePvBrlGeneralChannel", isUsd: false },
        { name: "netUnitPricePvBrlKaVf", isUsd: false },
        { name: "minGrossPricePvBrlGeneralChannel", isUsd: false },
        { name: "minGrossUnitPricePvBrlKaVf", isUsd: false },
        { name: "minNetPricePvBrlGeneralChannel", isUsd: false },
        { name: "minNetUnitPricePvBrlKaVf", isUsd: false },
        { name: "minNetUnitPricePvUSDKaVf", isUsd: true },
        { name: "minNetPricePvUSDGeneralChannel", isUsd: true },
        { name: "minGrossUnitPricePvUSDKaVf", isUsd: true },
        { name: "minGrossPricePvUSDGeneralChannel", isUsd: true },
        { name: "netUnitPricePvUSDKaVf", isUsd: true },
        { name: "netUnitPricePvUSDGeneralChannel", isUsd: true },
        { name: "grossUnitPricePvUSDKaVf", isUsd: true },
        { name: "grossUnitPricePvUSDGeneralChannel", isUsd: true },
        { name: "minNetUnitPriceFvUsdKaVf", isUsd: true },
        { name: "minNetPriceFvUsdGeneralChannel", isUsd: true },
        { name: "minGrossUnitPriceFvUsdKaVf", isUsd: true },
        { name: "minGrossPriceFvUsdGeneralChannel", isUsd: true },
        { name: "netUnitPriceFvUsdKaVf", isUsd: true },
        { name: "netUnitPriceFvUsdGeneralChannel", isUsd: true },
        { name: "grossUnitPriceFvUsdGeneralChannel", isUsd: true },
        { name: "grossUnitPriceFvUsdKaVf", isUsd: true },
        { name: "unitPriceListUSD", isUsd: true },
        { name: "unitPriceListBRL", isUsd: false },
        { name: "ordersPrice", isUsd: true },
      ],
    };
  },
};
</script>