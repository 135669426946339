function getMedian(array) {
  if (array.length == 1) return array[0]
  const sortedArray = array.slice().sort((a, b) => a - b);
  const mid = Math.floor(sortedArray.length / 2);

  if (sortedArray.length % 2 === 0) {
    return (sortedArray[mid - 1] + sortedArray[mid]) / 2;
  } else {
    return sortedArray[mid];
  }
}

function getQuartile(array, quartile) {
  
  if (array.length == 1) return array[0]

  if (array.length === 2) {
    if (quartile === 1) return Math.min(...array);
    if (quartile === 3) return Math.max(...array);
    if (quartile === 2) return getMedian(array);
  }

  const sortedArray = array.slice().sort((a, b) => a - b);
  const pos = (quartile / 4) * (sortedArray.length + 1);

  if (pos % 1 === 0) {
    return sortedArray[pos - 1];
  } else {
    const lower = Math.floor(pos) - 1;
    const upper = Math.ceil(pos) - 1;
    return sortedArray[lower] + (sortedArray[upper] - sortedArray[lower]) * (pos - Math.floor(pos));
  }
}

function getMostFrequently(array) {
  if (array.length == 1) return array[0]
  const frequency = {};
  let maxFreq = 0;
  let mode = null;

  array.forEach(value => {
    frequency[value] = (frequency[value] || 0) + 1;
    if (frequency[value] > maxFreq) {
      maxFreq = frequency[value];
      mode = value;
    }
  });

  return mode;
}

function getAverage(array) {
  if (array.length == 1) return array[0]
  const total = array.reduce((sum, value) => sum + value, 0);
  return total / array.length;
}

function identifyOutliers(index, array) {
  const sortedArray = array.slice().sort((a, b) => a - b);
  const min = Math.min(...sortedArray)
  const max = Math.max(...sortedArray)
  const Q1 = getQuartile(sortedArray, 1);
  const Q3 = getQuartile(sortedArray, 3);
  const IQR = Q3 - Q1;

  let lowerLimit = Q1 - 1.5 * IQR;
  let upperLimit = Q3 + 1.5 * IQR;

  if (lowerLimit < min) lowerLimit = min
  if (upperLimit > max) upperLimit = max

  const outliers = sortedArray.filter(value => value < lowerLimit || value > upperLimit).map((value) => ([index, value]));

  const uniqueOutliers = Array.from(new Set(outliers.map(JSON.stringify)))
    .map(JSON.parse);

  return { outliers: uniqueOutliers, low: lowerLimit, high: upperLimit };
}

export function calculateBoxPlotValuesWithOutliers(index, array) {

  const { high, low, outliers } = identifyOutliers(index, array);

  const values = {
    min: Math.min(...array),
    q1: getQuartile(array, 1),
    median: getMedian(array),
    q3: getQuartile(array, 3),
    max: Math.max(...array),
    avg: getAverage(array),
    mostFrequently: getMostFrequently(array),
    low,
    high
  };


  return { values, outliers };
}