<template>
  <v-col>
    <v-row no-gutters justify="space-between" align="center">
      <v-col cols="auto">
        <h4>{{ name }}</h4>
        <span>{{ subtitle }}</span>
      </v-col>

      <v-col cols="auto" v-if="isAdminUpl">
        <v-row no-gutters>
          <Prd-btn
            :title="$t('TXT_EXTRACT_FILE')"
            :loading="isLoadingExtractFile"
            class="mr-4"
            @click="extractFile"
          />
          <Prd-btn
            :title="`${$t('TXT_ADD')} ${$t('TXT_AGROUPMENT')}`"
            :disabled="isLoadingExtractFile"
            @click="eddEditModalItsOpen = true"
          />
        </v-row>
      </v-col>
    </v-row>

    <Target-market-segmentation-table
      :headers="headers"
      :items="items"
      @updateAgroupment="updateAgroupment"
      @refreshTargetMarketSegmentationTable="
        $emit('refreshTargetMarketSegmentationTable')
      "
    />

    <add-edit-agroupment-modal
      :itsOpen="eddEditModalItsOpen"
      :allAgroupments="items"
      :selectedAgroupmentId="selectedAgroupmentId"
      @closeModal="closeModal"
      @refreshTargetMarketSegmentationTable="
        $emit('refreshTargetMarketSegmentationTable')
      "
    />
  </v-col>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import TargetMarketSegmentationTable from "./components/target-market-segmentation-table.vue";
import AddEditAgroupmentModal from "./components/add-edit-agroupment-modal/add-edit-agroupment-modal.vue";
import UPLService from "@/service/upl";
export default {
  components: { PrdBtn, TargetMarketSegmentationTable, AddEditAgroupmentModal },
  props: {
    name: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      eddEditModalItsOpen: false,
      selectedAgroupmentId: null,
      isLoadingExtractFile: false,
      service: new UPLService(),
    };
  },
  computed: {
    isAdminUpl() {
      return (
        this.$store.getters.Acesso_E_Edicao_UPL_Geral ||
        this.$store.getters.isPredifyAdmin
      );
    },
  },
  methods: {
    updateAgroupment(id) {
      this.selectedAgroupmentId = id;
      this.eddEditModalItsOpen = true;
    },
    closeModal() {
      this.selectedAgroupmentId = null;
      this.eddEditModalItsOpen = false;
    },
    async extractFile() {
      this.isLoadingExtractFile = true;
      try {
        const response = await this.service.getSegmentationTableReport();

        const downloadLink = document.createElement("a");
        const blob = new Blob(["\ufeff", response.data]);
        const url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = `${this.name} - ${this.$i18n.t(
          "TXT_REPORT"
        )}.csv`;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) {
        this.$handleError(error, this.$i18n.t("TXT_EXTRACT_FILE_ERROR"));
      } finally {
        this.isLoadingExtractFile = false;
      }
    },
  },
};
</script>