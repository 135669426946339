<template>
  <div>
    <Header-list
      v-if="!isFirstSearch"
      :totalRecords="totalRecords"
      :isLoading="isLoading"
    />
    <Prd-loading-circular v-if="isLoading" :size="50" class="mt-12" />

    <v-expansion-panels v-if="products.length > 0 && !isLoading">
      <Product-item
        v-for="product in products"
        :key="product.idGroups"
        :product="product"
        class="mt-2"
      />
    </v-expansion-panels>

    <Prd-pagination
      v-if="products.length > 0 && !isLoading"
      v-model="currentPage"
      :totalRegisters="totalRecords"
      :length="totalPages"
      :showingRegistersLen="numberOfRecords"
      :resultLabel="$t('TXT_PAGINATION')"
      :pageLabel="$t('TXT_PAGE_LABEL')"
      :disabled="isLoading"
      class="mt-4"
    />
  </div>
</template>

<script>
import ProductItem from "./components/product-item/product-item-main.vue";
import HeaderList from "./components/header-list/header-list.vue";
import PrdPagination from "@/Design_System/common/prd-pagination.vue";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
export default {
  components: { HeaderList, ProductItem, PrdPagination, PrdLoadingCircular },
  data() {
    return {};
  },

  computed: {
    isLoading() {
      return this.$store?.getters?.isLoading ?? false;
    },

    isFirstSearch() {
      return this.$store?.getters?.isFirstSearch ?? false;
    },

    currentPage: {
      get() {
        return this.$store?.getters?.currentPage ?? 1;
      },
      set(value) {
        this.$store.commit("setCurrentPage", value);
      },
    },

    products() {
      return this.$store?.getters?.productList?.records ?? [];
    },

    totalRecords() {
      return this.$store?.getters?.productList?.totalRecords ?? 0;
    },

    totalPages() {
      return this.$store?.getters?.productList?.totalPages ?? 0;
    },

    numberOfRecords() {
      return this.$store?.getters?.productList?.numberOfRecords ?? 0;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>