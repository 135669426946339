import i18n from "@/i18n";

export function validateMatchSimulation(context) {
  const errors = [];
  if (!context.selectedPlace) errors.push(i18n.t("TXT_SELECT_STADIUM"));
  if (!context.selectedCrowding) errors.push(i18n.t("TXT_SELECT_SCENARIO"));
  // if (!context.yellowPrice) errors.push("Você deve fornecer um preço amarelo");
  // if (!context.redPrice) errors.push("Você deve fornecer um preço vermelho");
  // if (!context.orangePrice) errors.push("Você deve fornecer um preço laranja");
  // if (!context.purplePrice) errors.push("Você deve fornecer um preço roxo");
  if (!context.publicPrediction)
    errors.push(i18n.t("TXT_AUDIENCE_FORECASTING"));
  if (!context.expenses)
    errors.push(i18n.t("TXT_AMOUNT_FOR_EXPENSES"));

  if (context.service.crowding.error) {
    errors.push(i18n.t("TXT_ERROR_GET_INFORMATION_OF_SCENARIO"));
  }
  if (context.service.place.error) {
    errors.push(i18n.t("TXT_ERROR_GET_INFORMATION_OF_STADIUM"));
  }
  if (context.service.listTickets.error) {
    errors.push(i18n.t("TXT_ERROR_GET_ENTRANCE_LIST"));
  }
  return errors.join("\n");
}
