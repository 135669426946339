<template>
  <div :class="['user-store-card', { selected: isSelected }]">
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  props: { isSelected: { type: Boolean, default: false, require: true } },
};
</script>

<style lang="scss" scoped>
.user-store-card {
  background-color: $neutral-color-high-medium;
  width: 100%;
  height: fit-content;
  border-radius: 8px !important;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color:rgb(224, 224, 224,0.65);
  }
}
.selected {
  background-color: rgba(103, 147, 248, 0.5);
  &:hover {
    background-color: $brand-color-primary-light;
  }
}
</style>
