<template>
  <v-dialog v-model="isOpen" max-width="600">
    <v-card class="pa-4">
      <h2 class="mb-6">{{ title }}</h2>
      <div class="background-errors">
        <p>{{ $t("TXT_ERRORS_FOUND") }}:</p>
        <p class="red--text ma-0" v-for="error in errors" :key="error">
          {{ error }}
        </p>
      </div>
      <p>{{ $t("TXT_TO_SEE_PATTERN_ALERT") }}</p>
      <v-row no-gutters justify="end" class="mt-6">
        <Prd-btn
          :outlined="true"
          :title="$t('TXT_CLOSE')"
          @click="isOpen = false"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  components: {
    PrdBtn,
  },
  props: {
    title: {
      type: String,
      default: function () {
        return this.$i18n.t("TXT_FAIL_WHEN_IMPORTING_MONITORING_ITEMS");
      },
    },
  },
  data() {
    return {
      isOpen: false,
      errors: [],
    };
  },
  watch: {
    isOpen: {
      handler(value) {
        if (!value) this.closeModal();
      },
    },
  },
  methods: {
    openModal(message) {
      if (message.errors) {
        this.errors = message.errors.map(
          ({ textToken, line, column, expected, found, field }) => {
            return this.$i18n.t(textToken, {
              row: line,
              column,
              field: this.$i18n.t(field),
              founded: found,
              expected,
            });
          }
        );
      } else {
        this.errors = [message];
      }

      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.errors = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.background-errors {
  background-color: $neutral-color-high-light;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 8px;
}
</style>
