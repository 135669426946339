<template>
  <v-container fluid>
    <titleBreadCrumbsComponent
      :title="'PRODUCT DELIBERATION'"
      :hasTooltip="false"
    />
    <v-card class="pa-4 pt-0" :loading="isLoading">
      <table-main
        @setLoading="isLoading = $event"
        :isLoadingFather="isLoading"
      />
    </v-card>
  </v-container>
</template>

<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import TableMain from "./components/tables/table-main.vue";

export default {
  components: { titleBreadCrumbsComponent, TableMain },
  name: "deliberation-main",
  data() {
    return {
      isLoading: false,
    };
  },

  created() {
    document.title = "PREDIFY | DELIBERATION";
  },
};
</script> 