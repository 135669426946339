<template>
  <v-dialog
    v-model="isModalOpen"
    @click:outside="$emit('closeModal')"
    max-width="450"
  >
    <v-card class="pa-4">
      <h3 class="text-center mb-2">
        {{ $i18n.t("TXT_INFORMATIONS_PRICING_SELECTED") }}
      </h3>
      <p v-t="'TXT_APPROVED_PRICING_INFO'" class="px-4"></p>

      <div class="pricing-info mb-2 mx-4">
        <div class="flex-itens">
          <h4>{{ $i18n.t("TXT_APPROVER") }}:</h4>
          <span> {{ userEmail }}</span>
        </div>
        <div class="flex-itens">
          <h4>{{ $i18n.t("TXT_DATE") }}:</h4>
          <span>{{ date }}</span>
        </div>
        <div class="flex-itens">
          <h4>{{ $i18n.t("TXT_TIME_HOUR") }}:</h4>
          <span>{{ hours }}</span>
        </div>
      </div>
      <v-row no-gutters align="center" justify="end" class="mt-4">
        <Prd-btn
          :title="$i18n.t('TXT_CANCEL')"
          :outlined="true"
          @click="$emit('closeModal')"
          class="mr-4"
        />
        <Prd-btn
          :title="$i18n.t('TXT_CONTINUE')"
          @click="$emit('listPricing')"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import { formatDate } from "@/utils/prd-format-date";
export default {
  components: { PrdBtn },
  props: {
    modalControl: {
      type: Boolean,
      default: false,
    },
    pricing: {
      default: null,
    },
  },
  data() {
    return {
      isModalOpen: false,
    };
  },

  computed: {
    userEmail() {
      return this.pricing?.userEmail ?? "-";
    },
    date() {
      return (
        this.formatDate(this.pricing?.publishedDate ?? "-")?.split("-")[0] ??
        "-"
      );
    },
    hours() {
      return (
        this.formatDate(this.pricing?.publishedDate ?? "-")?.split("-")[1] ??
        "-"
      );
    },
  },

  watch: {
    modalControl: {
      handler(value) {
        this.isModalOpen = value;
      },
      immediate: true,
    },
  },

  methods: {
    formatDate,
  },
};
</script>

<style lang="scss" scoped>
.pricing-info {
  padding: 8px;
}
.flex-itens {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
</style>