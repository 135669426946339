<template>
  <v-row no-gutters>
    <v-col
      v-for="(filter, index) in filters"
      :key="index"
      :cols="filter.colSize"
      :class="filter.classess"
    >
      <Prd-combo-box
        v-model="filter.selected"
        :items="filter.items"
        :title="filter.title"
        :disabled="externalLoading"
        :loading="externalLoading"
        :label="$t('TXT_SELECT')"
        @hasSelections="$emit('handleChange')"
        @onFocus="$emit('toggleUnlockSeeResults', true)"
        @onBlur="$emit('toggleUnlockSeeResults', false)"
        multiple
        deletable-chips
        small-chips
        clearable
      />
    </v-col>
  </v-row>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
export default {
  components: { PrdComboBox },
  props: {
    externalLoading: {
      type: Boolean,
      default: false,
    },
    savedFilter: {
      type: Object,
    },
  },
  data() {
    return {
      filters: [
        {
          value: "states",
          title: "Estado / UF",
          valueType: 7,
          selected: [],
          items: [],
          classess: "pr-4",
          colSize: 3,
        },
      ],
    };
  },
  watch: {
    savedFilter: {
      handler(value) {
        this.populateFiltersBySavedFilter(value);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    populateFiltersBySavedFilter(data) {
      if (!data) return;
      this.cleanSelection();

      this.filters.forEach((currentFilter) => {
        const saved = data.values.filter(
          (save) => save.valueType == currentFilter.valueType
        );
        if (saved.length > 0) {
          currentFilter.selected = currentFilter.items.filter((item) =>
            saved.find((save) => save.value == item)
          );
        }
      });
    },
    cleanSelection() {
      this.filters.forEach((filter) => {
        filter.selected = [];
      });
    },
    getSelections() {
      let selections = {};
      this.filters.forEach((filter) => {
        selections[filter.value] = filter.selected;
      });
      return selections;
    },
    populateFilters(items) {
      this.filters.forEach((filter) => {
        filter.items = items[filter.value];
      });
    },
    removeNonExistentSelectedItem(availableItems) {
      const keys = Object.keys(availableItems);

      keys.forEach((key) => {
        const set = new Set(availableItems[key]);
        const filter = this.filters.find((filter) => filter.value == key);
        if (filter) {
          filter.selected = filter.selected.filter((item) => set.has(item));
        }
      });
    },
  },
};
</script>
