
import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class UserManagementService extends ServiceBase {
    async getUsers(idCompany) {
        return await axiosInstance.get(API_URL + `api/Usuario/GetusuariosByIdEmpresa?idEmpresa=${idCompany}`);
    }

    async getUser(email) {
        return await axiosInstance.get(API_URL + `api/Usuario/GetUsuario?email=${email}`);
    }

    async linkUserToCompany(request) {
        /** 
         * @param {number} idCompany
         * @param {object} user - Object with all user data
        */
        return await axiosInstance.post(API_URL + "api/Usuario/VincularEmpresaUsuario", request);
    }

    async addAdminRole(request) {
        /**
         *  @param {string} AccessLevel - Type of permission
         *  @param {string} UserEmail - User email
         */
        return await axiosInstance.post(API_URL + `api/Usuario/SetAdminRole`, request);
    }

    async removeAdminRole(request) {
        /**
         *  @param {string} AccessLevel - Type of permission
         *  @param {string} UserEmail - User email
         */
        return await axiosInstance.post(API_URL + `api/Usuario/RemoveAdminRole`, request);
    }

    async editUser(user) {
        /**
         *  @param {object} user
         */
        return await axiosInstance.put(API_URL + `api/Usuario`, user);
    }

    async removeUser(idCompany, idUser) {
        /**
         *  @param {number} idCompany
         *  @param {string} idUser
         */
        return await axiosInstance.delete(API_URL + `api/Usuario/DesvincularEmpresaUsuario?idEmpresa=${idCompany}&idUsuario=${idUser}`);
    }




}
