xAxisCategories<template>
  <highcharts style="width: 800px" :options="chartData"></highcharts>
</template>

<script>
import Highcharts from "highcharts";

// Exporta data in PNG, JPEG, PDF, SVG, imprime and fullscreen
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);

// export data CSV, XLS, e change vizualization per table
import exportData from "highcharts/modules/export-data";
exportData(Highcharts);
export default {
  props: ["xAxisCategories", "selectedProducts"],
  data() {
    return {
      chartData: {
        chart: {
          type: "spline", // Chart template
        },
        title: {
          text: "",
          align: "left",
        },
        lang: {
          // variables to rename the data exportation actions
          viewFullscreen: this.$i18n.t("TXT_VISUALIZE_FULLSCREEN"),
          printChart: this.$i18n.t("TXT_PRINT_CHART"),
          downloadCSV: this.$i18n.t("TXT_DOWNLOAD_CSV_FILE"),
          downloadJPEG: this.$i18n.t("TXT_DOWNLOAD_JPEG_IMAGE"),
          downloadPDF: this.$i18n.t("TXT_DOWNLOAD_PDF"),
          downloadPNG: this.$i18n.t("TXT_DOWNLOAD_PNG_IMAGE"),
          downloadSVG: this.$i18n.t("TXT_DOWNLOAD_SVG_IMAGE"),
          downloadXLS: this.$i18n.t("TXT_DOWNLOAD_XLS_FILE"),
          exitFullscreen: this.$i18n.t("TXT_LEAVE_FULLSCREEN"),
          viewData: this.$i18n.t("TXT_DISPLAY_DATA_IN_TABLE"),
          // Msg when have no data
        },
        yAxis: {
          title: {
            text: `${this.$i18n.t(
              "TXT_COMPETITIVENESS_INDEX"
            )} - ${this.$i18n.t("TXT_CI_UPPERCASE")} (%)`,
          },
          crosshair: true,
          startOnTick: true,
          labels: {
            formatter: function () {
              return this.value + "%";
            },
          },
        },

        xAxis: {
          title: {
            text: this.$i18n.t("TXT_TIME"),
          },
          categories: [],
        },
        plotOptions: {
          series: {
            connectNulls: true,
          },
        },
        tooltip: {
          formatter: function () {
            return `<b>${this.series.name}</b>
                    <br/>
                    <br/>
                    <b>${this.x}</b>
                    <br/>
                    <span style="color:${this.point.color}">●</span> IC: <b>${this.y}%</b>`;
          },
        },

        legend: {
          layout: "vertical",
          align: "center",
          verticalAlign: "bottom",
        },
        series: [],
      },
    };
  },
  watch: {
    xAxisCategories: {
      handler(value) {
        this.chartData.xAxis.categories = [];
        this.chartData.xAxis.categories = value;
      },
      immediate: true,
      deep: true,
    },
    selectedProducts: {
      handler(value) {
        this.chartData.series = [];
        this.chartData.series = value;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
