<template>
  <div>
    <label v-if="title" for="currencyField">{{ title }}</label>
    <div class="mt-1">
      <Money
        id="currencyField"
        v-model="localValue"
        v-bind="format"
        :disabled="disabled"
        :class="{ disabledField: useFilled }"
      />
    </div>
  </div>
</template>

<script>
import { Money } from "v-money";
export default {
  props: {
    title: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    format: {
      type: Object,
      default: () => ({
        decimal: ",",
        thousands: ".",
        precision: 2,
        prefix: `${localStorage.getItem("currency") ?? "R$"} `,
        suffix: "",
      }),
    },
  },
  components: { Money },
  computed: {
    useFilled() {
      return this.disabled && this.filled;
    },
    localValue: {
      get() {
        return this.$attrs.value;
      },
      set(value) {
        this.$emit("update", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.v-money {
  border: none;
  border: 1px solid;
  border-color: $neutral-color-low-light;
  border-radius: 4px;
  padding: 8px 12px 8px 12px;
  max-height: 40px;
  &:hover {
    border-color: $neutral-color-low-dark;
  }
}
.disabledField {
  background-color: #f0f0f0;
  color: #9595b5;
}
</style>
