var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":"","justify":"center"}},[(_vm.subtitle == '' && _vm.name == 'DISCOUNT MATRIX')?_c('DiscountMatrixTable',{attrs:{"name":_vm.name,"subtitle":_vm.subtitle,"headers":_vm.headers,"items":_vm.items},on:{"updateOriginalItemsModified":function($event){return _vm.$emit('updateOriginalItemsModified', $event)},"updateMarketingVariationMatrix":function($event){return _vm.$emit('updateMarketingVariationMatrix', $event)},"setBadgeDiscountMatrix":function($event){return _vm.$emit('setBadgeDiscountMatrix', $event)}}}):(_vm.subtitle == '' && _vm.name == 'MARKETING VARIATION MATRIX')?_c('Marketing-variation-matrix-table',{attrs:{"name":_vm.name,"subtitle":_vm.subtitle,"headers":_vm.headers,"items":_vm.items},on:{"updateAllOriginalItems":function($event){return _vm.$emit('updateAllOriginalItems', $event)},"setBadgeRegionalizationMatrix":function($event){return _vm.$emit('setBadgeRegionalizationMatrix', $event)}}}):(
        _vm.subtitle == 'AGRUPAMENTOS' && _vm.name == 'SEGMENTAÇÃO DE MERCADO'
      )?_c('TargetMarketSegmentationMain',{attrs:{"name":_vm.name,"subtitle":_vm.subtitle,"headers":_vm.headers,"items":_vm.items},on:{"refreshTargetMarketSegmentationTable":function($event){return _vm.$emit('refreshTargetMarketSegmentationTable')},"updateOriginalData":function($event){return _vm.$emit('updateOriginalData', $event)}}}):_c('v-col',[_c('h4',[_vm._v(_vm._s(_vm.name))]),_c('span',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.subtitle))]),(
          _vm.subtitle == 'FUTURE VALUE | CANAL GERAL' && _vm.name == 'DELIBERATION'
        )?_c('Deliberation-table',{attrs:{"headers":_vm.headers,"localItems":_vm.localItems,"items":_vm.items,"setCurrencyUPL":_vm.setCurrencyUPL,"isAdminUpl":_vm.isAdminUpl},on:{"hasNewValues":function($event){return _vm.$emit('hasNewValues', $event)},"updateOriginalData":_vm.updateOriginalData}}):(_vm.subtitle == '' && _vm.name == 'INFO')?_c('Info-table',{attrs:{"headers":_vm.headers,"localItems":_vm.localItems,"items":_vm.items,"setCurrencyUPL":_vm.setCurrencyUPL,"isAdminUpl":_vm.isAdminUpl},on:{"updateOriginalData":_vm.updateOriginalData}}):(
          _vm.subtitle == 'FUTURE VALUE | CG & KD/VD' &&
          _vm.name == 'LAST MONTH WHEIGHTED PRICE LIST & PERFORMANCE'
        )?_c('Last-month-weighted-table',{attrs:{"headers":_vm.headers,"localItems":_vm.localItems,"setCurrencyUPL":_vm.setCurrencyUPL}}):_c('Other-tables',{attrs:{"headers":_vm.headers,"localItems":_vm.localItems,"setCurrencyUPL":_vm.setCurrencyUPL}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }