<template>
  <div>
    <v-row no-gutters align="center" justify="space-between" class="mb-4">
      <v-row no-gutters align="center">
        <img
          src="@/assets/logo_nia.png"
          alt=""
          class="mr-2"
        />
        <h5 class="font-size-20 mr-4">
          {{ $i18n.t('TXT_COPILOT') }}
        </h5>
      </v-row>

      <v-icon
        :disabled="disableRulesActions"
        @click="closeCopilot"
        color="black"
      >
        mdi-arrow-right
      </v-icon>
    </v-row>
    <v-divider class="mb-4"></v-divider>
  </div>
</template>

<script>
export default {
  computed: {
    isPredifyAdmin() {
      return this.$store.getters.isPredifyAdmin;
    },
    disableRulesActions() {
      return this.$store?.getters?.disableRulesActions;
    },
  },

  methods: {
    closeCopilot() {
      this.$store.commit("TOGGLE_COPILOT_MODAL", false);
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  max-width: 30px;
}
</style>