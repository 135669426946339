<template>
  <v-row no-gutters align="center" justify="space-between">
    <v-col cols="auto" v-for="(tab, index) in tabs" :key="index">
      <Prd-tabs :configs="tab.config" @changedTab="tab.action($event)" />
    </v-col>
  </v-row>
</template>

<script>
import PrdTabs from "@/components/common/prd-tabs.vue";
export default {
  components: { PrdTabs },
  data() {
    return {
      tabs: [
        {
          config: {
            title: this.$i18n.t("TXT_GROUP_BY"),
            tabs: [
              {
                name: this.$i18n.t("TXT_PRODUCT"),
                tooltip: this.$i18n.t("TXT_COMPARISON_TOOLTIP_PRODUCT"),
              },
              {
                name: this.$i18n.t("TXT_RETAILER"),
                tooltip: this.$i18n.t("TXT_TEMPORAL_TOOLTIP_ORIGIN"),
              },
              {
                name: this.$i18n.t("TXT_MANUFACTURER"),
                tooltip: this.$i18n.t("TXT_TEMPORAL_TOOLTIP_RETAILER_2"),
              },
            ],
          },
          action: (index) => {
            this.$store.dispatch("setGroupBy", index);
          },
        },

        {
          config: {
            title: this.$i18n.t("TXT_VISUALIZATION"),
            tabs: [
              {
                name: this.$i18n.t("TXT_LIST"),
              },
              {
                name: this.$i18n.t("TXT_CHART"),
              },
            ],
          },
          action: (index) => {
            this.$store.dispatch("setVisualization", index);
          },
        },
      ],
    };
  },
};
</script>