<template>
  <v-expansion-panels v-model="panel">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <h3>{{ $t("TXT_REGISTER_NEW_USER").toUpperCase() }}</h3>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <Check-email
          @email="email = $event"
          @isNotExistsEmail="isNotExistsEmail = $event"
          @linkUserCompany="handlerUser"
          :resetFields="resetFields"
          :isLoadingProps="isLoading"
        />
        <User-data
          v-if="isNotExistsEmail"
          :email="email"
          :isLoading="isLoading"
          @user="user = $event"
          @resetFields="resetFields = !resetFields"
          @linkUserCompany="handlerUser(null)"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import CheckEmail from "./components/check-email.vue";
import UserData from "./components/user-data.vue";
import GeneralSystemCongisService from "@/service/general-system-configs/index";

export default {
  components: { CheckEmail, UserData },
  props: {
    selectedCompany: {
      type: Object,
    },
  },
  data() {
    return {
      panel: [],
      email: null,
      user: null,
      isNotExistsEmail: false,
      isLoading: false,
      resetFields: false,
      service: new GeneralSystemCongisService(),
    };
  },
  methods: {
    async handlerUser(usuario) {
      await this.linkUserCompany(usuario);
      if (usuario == null) {
        if (this.user.adminAccess) await this.makeIsOwnsUser();
        if (this.user.regionalAccess.length > 0) await this.setRegionalAcess();
      }
      this.resetFields = !this.resetFields;
      this.$emit("getUsers");
    },
    async linkUserCompany(usuario) {
      this.isLoading = true;
      const request = this.createRequestForLinkUserCompany(usuario);
      let message, type;
      try {
        await this.service.linkUserCompany(request);
        message = this.$i18n.t("TXT_REGISTER_NEW_USER_SUCCESS");
        type = "success";
      } catch (error) {
        console.log(error);
        message = this.$i18n.t("TXT_REGISTER_NEW_USER_ERROR");
        type = "error";
      }
      this.$store.commit("snackbarV2/set", { message, type });
      this.isLoading = false;
    },
    createRequestForLinkUserCompany(usuario) {
      if (!usuario) {
        usuario = {
          idUsuario: "",
          nome: this.user.name,
          email: this.user.email,
          telefone: this.user.phone,
          isTourDone: false,
          predifyAdmin: false,
          lang: this.user.language.value,
          addCompany: false,
          onboardingApresentacaoStarted: false,
          onboardingCustoFixoStarted: false,
          onboardingCustoVariavelStarted: false,
          onboardingCustoProducaoStarted: false,
          isProprietario: false,
          adminRoles: [],
        };
      }
      return {
        usuario,
        idEmpresa: this.selectedCompany.idEmpresa,
      };
    },

    async makeIsOwnsUser() {
      this.isLoading = true;
      const request = this.createRequestForMakeIsOwnsUser();
      let message, type;
      try {
        await this.service.makeIsOwnsUser(request);
        message = this.$i18n.t("TXT_MAKE_USER_ADMIN_SUCCESS");
        type = "success";
      } catch (error) {
        console.log(error);
        message = this.$i18n.t("TXT_MAKE_USER_ADMIN_ERROR");
        type = "error";
      }
      this.$store.commit("snackbarV2/set", { message, type });
      this.isLoading = false;
    },
    createRequestForMakeIsOwnsUser() {
      return {
        idCompany: this.selectedCompany.idEmpresa,
        email: this.user.email,
      };
    },

    async setRegionalAcess() {
      this.isLoading = true;
      this.user.regionalAccess.forEach(async (level) => {
        const request = this.createRequestForSetRegionalAcess(level);
        let message, type;
        try {
          await this.service.setAdminRole(request);
          message = this.$i18n.t("TXT_UPDATED_ACCESS_SUCCESS");
          type = "success";
        } catch (error) {
          console.log(error);
          message = this.$i18n.t("TXT_UPDATED_ACCESS_ERROR");
          type = "error";
        }
        this.$store.commit("snackbarV2/set", { message, type });
      });
      this.isLoading = false;
    },
    createRequestForSetRegionalAcess(level) {
      return {
        AccessLevel: level,
        UserEmail: this.user.email,
      };
    },
  },
};
</script>