<template>
  <v-card class="pa-4" :disabled="isLoading">
    <v-row no-gutters class="mb-4">
      <v-col>
        <label>{{ $t("TXT_NAME") }}</label>
        <v-text-field
          v-model="name"
          :disabled="!isEditing"
          :filled="!isEditing"
          dense
          outlined
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <label>{{ $t("TXT_DESCRIPTION") }}</label>
        <v-textarea
          v-model="description"
          :disabled="!isEditing"
          :filled="!isEditing"
          outlined
          no-resize
          hide-details
          height="300px"
        ></v-textarea>
      </v-col>
    </v-row>

    <Prd-card-actions
      :isDisable="!isEditing"
      :title="$t('TXT_SAVE_GROUP')"
      :outlinedTitle="$t('TXT_CANCEL')"
      @clickOutlined="cancelChanges"
      @click="isModalOpen = true"
    />

    <Create-group-modal
      :isModalOpen="isModalOpen"
      :name="name"
      :description="description"
      :resources="resources"
      :isLoading="isLoading"
      @createGroup="saveChanges"
      @closeModal="isModalOpen = false"
    />
  </v-card>
</template> 

<script>
import service from "@/service/company-config/resource-group-management.js";
const Service = new service();
import PrdCardActions from "@/components/common/prd-card-actions.vue";
import { mapActions } from "vuex";
import CreateGroupModal from "./create-group-modal.vue";

export default {
  props: ["isEditing", "groupToEdit"],
  components: { PrdCardActions, CreateGroupModal },
  data() {
    return {
      name: null,
      description: null,
      isLoading: false,
      isModalOpen: false,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    resources() {
      return this.$store?.state?.companyConfigStore?.resources ?? [];
    },
  },

  watch: {
    groupToEdit: {
      handler(value) {
        if (!value) return;
        this.name = value.name;
        this.description = value.description;

        value.resources.forEach((el) => {
          this.resources.forEach((item) => {
            if (el == item.idResource) {
              item.select = true;
            }
          });
        });
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    ...mapActions(["GET_GROUPS"]),

    async saveChanges() {
      this.isLoading = true;

      let request = {
        idCompany: this.idCompany,
        idResourceGroup: this.groupToEdit
          ? this.groupToEdit.idResourceGroup
          : 0,
        name: this.name,
        description: this.description,
        resources: this.resources
          .filter((el) => el.select)
          .map((el) => el.idResource),
      };

      try {
        await Service.updateGroup(request);
        this.GET_GROUPS(this.idCompany);
        this.cancelChanges();
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_SUCCESS_UPDATE_RESOURCE_GROUP"),
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_UPDATE_RESOURCE_GROUP"),
          type: "error",
        });
      }

      this.cleanData();
      this.isLoading = false;
      this.isModalOpen = false;
    },

    cancelChanges() {
      this.resources.forEach((el) => {
        el.select = false;
      });
      this.cleanData();
      this.$emit("cancelChanges");
    },

    cleanData() {
      this.name = null;
      this.description = null;
      this.$emit("cancelChanges");
    },
  },
};
</script>