var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-6"},[_c('span',{staticClass:"span-destaq"},[_vm._v(_vm._s(_vm.$t("TXT_USER_WITH_ADMIN_ACCESS")))]),(_vm.listLoading)?_c('Prd-loading-circular'):_c('div',[_c('v-data-table',{staticClass:"mt-6",attrs:{"headers":_vm.headers,"items":_vm.userListCurrentPage.list,"loading":_vm.acessLoading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.resourceGroup",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"access-row"},[_vm._l((item.resourceGroup),function(group,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(group)+" | ")])}),_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary')}},[_vm._v(" mdi-cog-outline ")])],2)]}},{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[(!item.isEdit)?_c('span',[_vm._v(_vm._s(item.nome))]):_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(item.nome),callback:function ($$v) {_vm.$set(item, "nome", $$v)},expression:"item.nome"}})],1)]}},{key:"item.telefone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[(!item.isEdit)?_c('span',[_vm._v(_vm._s(item.telefone))]):_c('v-text-field',{staticClass:"input-width",attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(item.telefone),callback:function ($$v) {_vm.$set(item, "telefone", $$v)},expression:"item.telefone"}})],1)]}},{key:"item.access",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"access-row"},[_c('img',{staticClass:"flag",class:{ enableFlag: item.adminRoles.includes('1000') },attrs:{"src":require("@/assets/flags/brasil.svg")},on:{"click":function($event){return _vm.setAdminRole('1000', item)}}}),_c('img',{staticClass:"flag",class:{ enableFlag: item.adminRoles.includes('1001') },attrs:{"src":require("@/assets/flags/us.svg")},on:{"click":function($event){return _vm.setAdminRole('1001', item)}}}),_c('img',{staticClass:"flag",class:{ enableFlag: item.adminRoles.includes('1002') },attrs:{"src":require("@/assets/flags/es.svg")},on:{"click":function($event){return _vm.setAdminRole('1002', item)}}})])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[(!item.isEdit)?_c('div',{staticClass:"access-row"},[_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary'),"disabled":item.isDisable},on:{"click":function($event){return _vm.editUser(item)}}},[_vm._v(" mdi-pencil-box ")]),_c('Delete-user-modal',{attrs:{"user":item,"disable":item.isDisable,"isLoading":_vm.deleteLoading},on:{"deleteUser":_vm.deleteUser}})],1):_c('div',[_c('div',{staticClass:"actions"},[_c('Edit-user-modal',{attrs:{"user":item,"idCompany":_vm.idCompany,"isLoading":_vm.editLoading},on:{"updateUser":_vm.updateUser}}),_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-warning')},on:{"click":function($event){return _vm.cancelEditUser(item)}}},[_vm._v(" mdi-close-box ")])],1)])])]}}],null,true)}),_c('Prd-pagination',{attrs:{"totalRegisters":_vm.totalRecords,"length":_vm.totalPages,"disabled":_vm.disablePagination,"showingRegistersLen":_vm.numberOfRecords,"resultLabel":_vm.$t('TXT_PAGINATION'),"pageLabel":_vm.$t('TXT_PAGE_LABEL')},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }