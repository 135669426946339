import i18n from '@/i18n'

const colors = [
  "#FF0000",
  "#6792f8",
  "#FFC700",
  "#1D8527",
  "#EC4C37",
  "#3c5ca7",
  "#9F3A2D",
  "#666666",
  "#C59900",
  "#B00020",
  "#760116",
  "#1C5922",
  "#00FF00",
  "#0000FF",
  "#FFFF00",
  "#FF00FF",
  "#00FFFF",
  "#FFA500",
  "#800080",
  "#008000",
  "#800000",
  "#808000",
  "#FFD700",
  "#A52A2A",
  "#800080",
  "#4B0082",
  "#FF6347",
  "#FF4500",
  "#2E8B57",
  "#F08080",
  "#4682B4",
  "#B0C4DE",
  "#00FF7F",
  "#20B2AA",
  "#FFE4B5",
  "#FF69B4",
  "#6A5ACD",
  "#00CED1",
  "#FFFFFF",
]

const names = {
  fillial: i18n.t('TXT_AFFILIATE'),
}

export { colors, names }