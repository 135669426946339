<template>
  <div>
    <v-row no-gutters class="listName">
      <span
        :class="`font-weight-bold px-1 borderTitle ${
          isLinkedList ? 'green' : 'red'
        }Border`"
      >
        {{ typeLicense }}
      </span>
    </v-row>
    <div class="list">
      <license-item
        v-for="license in localList"
        :key="license.idLicenca"
        :name="license.nome"
      />
    </div>
  </div>
</template>

<script>
import licenseItem from "./license-item.vue";
export default {
  components: { licenseItem },
  props: {
    isLinkedList: { type: Boolean, default: false },
    list: { type: Array },
  },
  data() {
    return {
      localList: [],
    };
  },
  computed: {
    typeLicense() {
      return this.isLinkedList
        ? this.$i18n.t("TXT_ALLOCATED_LICENSES")
        : this.$i18n.t("TXT_DEALLOCATED_LICENSES");
    },
  },
  watch: {
    list: {
      handler(value) {
        this.localList = value.filter(
          (license) => license.isLinked === this.isLinkedList
        );
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.listName {
  border-bottom: 1px solid $neutral-color-low-light;
}
.borderTitle {
  border-bottom: 4px solid;
}
.greenBorder {
  border-bottom-color: $feedback-color-success-pure;
}
.redBorder {
  border-bottom-color: $feedback-color-warning-pure;
}
.list {
  overflow: auto;
  max-height: 220px;
}
</style>