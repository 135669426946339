import { render, staticRenderFns } from "./direcionador-precos-mistura-externa.vue?vue&type=template&id=1a243469&scoped=true"
import script from "./direcionador-precos-mistura-externa.vue?vue&type=script&lang=js"
export * from "./direcionador-precos-mistura-externa.vue?vue&type=script&lang=js"
import style0 from "./direcionador-precos-mistura-externa.vue?vue&type=style&index=0&id=1a243469&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a243469",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
