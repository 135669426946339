<template>
  <v-card :disabled="crudRulesLoading" class="rules-list pa-4">
    <v-row no-gutters justify="space-between" class="mb-4">
      <h4 class="font-weight-bold title-destaq">
        {{ $i18n.t("TXT_RULES_PRODUCTION").toUpperCase() }}
      </h4>
      <div>
        <router-link to="/main/create-pricing-rule">
          <Prd-btn
            :title="$t('TXT_ADD_RULE')"
            :icon="'mdi-plus'"
            :leftIcon="true"
            :disabled="ruleListLoading || disableRulesActions"
            class="mr-4"
          />
        </router-link>
        <Prd-btn
          :title="$i18n.t('TXT_COPILOT')"
          :disabled="ruleListLoading || panels.length > 0"
          v-if="!copilotIsOpen && enableCopilot"
          @click="$emit('openCopilot')"
        />
      </div>
    </v-row>
    <Prd-loading-circular v-if="ruleListLoading" />

    <Skeleton-loader
      v-if="skeletonListLoading"
      :ruleListLength="ruleList.length"
    />

    <v-expansion-panels v-model="panels" multiple>
      <draggable
        v-model="ruleList"
        v-if="ruleList.length > 0 && !ruleListLoading && !skeletonListLoading"
        @start="drag = true"
        @end="drag = false"
        @change="onRulesChanged"
        class="removed-force-padding"
        handle=".handle"
      >
        <Rule-item
          v-for="(item, index) in ruleList"
          :key="item.idRule"
          :item="item"
          :index="index"
          :itsOpen="panels.includes(index)"
          @setLoading="disableRule = $event"
          @closePanel="closePanel(index)"
        />
      </draggable>
    </v-expansion-panels>
    <v-row
      v-if="!ruleListLoading && ruleList.length <= 0"
      align="center"
      justify="center"
      class="my-6"
    >
      <h4>{{ $t("TXT_NO_DATA_AVAILABLE").toUpperCase() }}</h4>
    </v-row>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import service from "@/service/pricing-rules/index.js";
const Service = new service();
import HandlerRules from "@/service/pricing-rules/create-rules.js";
const handlerRules = new HandlerRules();
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import RuleItem from "./components/rule-item/rule-item.vue";
import SkeletonLoader from "./components/skeleton-loader.vue";
export default {
  components: {
    draggable,
    PrdLoadingCircular,
    PrdBtn,
    RuleItem,
    SkeletonLoader,
  },
  data() {
    return {
      disableRule: false,
      panels: [],
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    copilotIsOpen() {
      return this.$store?.state?.copilotStore?.isOpen ?? false;
    },
    ruleList: {
      get() {
        return this.$store?.state?.pricingRule?.ruleList ?? [];
      },
      set(value) {
        this.$store.dispatch("updateSequenceGroupRules", value);
      },
    },
    ruleListLoading() {
      return this.$store?.state?.pricingRule?.ruleListLoading ?? [];
    },
    skeletonListLoading() {
      return this.$store?.state?.pricingRule?.skeletonListLoading ?? 0;
    },
    crudRulesLoading() {
      return this.$store?.state?.copilotStore?.crudRulesLoading ?? [];
    },
    showConfirmationRules() {
      return this.$store?.getters?.showConfirmationRules ?? [];
    },
    disableRulesActions() {
      return this.$store?.getters?.disableRulesActions;
    },
    enableCopilot() {
      return this.$store.getters.enableCopilot;
    },
    changedIdRules() {
      return this.$store?.state?.pricingRule?.changedIdRules ?? [];
    },
  },

  watch: {
    crudRulesLoading: {
      handler(value) {
        if (value) this.panels = [];
      },
    },

    changedIdRules: {
      handler(value) {
        if (value.length) {
          const panelIndexes = this.ruleList
            .map((rule, index) => (value.includes(rule.idRule) ? index : null))
            .filter((index) => index !== null);

          this.panels.push(...panelIndexes);
        }
      },
    },
  },

  methods: {
    closePanel(index) {
      this.panels = this.panels.filter((panel) => panel != index);
    },

    onRulesChanged(event) {
      if (event.moved) {
        const rule = this.ruleList[event.moved.newIndex];

        this.setOrder(rule, event.moved.newIndex);
      }
    },

    async swapRule(rule1, rule2) {
      try {
        await Service.swapRule(this.idCompany, rule2.idRule, rule1.idRule);
      } catch (error) {
        console.log(error);
      }
    },

    async setOrder(rule, orderToSet) {
      try {
        await Service.setOrder(this.idCompany, rule.idRule, orderToSet);
      } catch (error) {
        console.log(error);
      }
    },

    async getCompetitors() {
      this.$store.commit("updateSellerListLoading", true);

      try {
        const response = await handlerRules.getCompetitors(this.idCompany);
        this.$store.commit("updateSellerList", response.data.result);
      } catch (error) {
        if (
          error?.response?.data?.message ==
            "A sequência não contém elementos" ||
          error?.response?.data?.message == "Sequence contains no elements"
        )
          return;
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR"),
          type: "error",
        });
        this.$store.commit("updateSellerListLoading", false);
      }

      this.$store.commit("updateSellerListLoading", false);
    },
  },

  created() {
    this.$store.dispatch("getRules");
    this.getCompetitors();
  },
};
</script>

<style lang="scss" scoped>
.rules-list {
  flex-grow: 1;
}

.removed-force-padding {
  padding: 0px !important;
  margin: 0px !important;
  width: 100% !important;
}
</style>