<template>
  <v-card class="pa-4 pt-0" :disabled="isLoading">
    <v-row no-gutters class="pt-4 mb-4">
      <!-- DATA PICKER -->
      <v-col cols="12" sm="4" md="3" class="mr-6">
        <span style="display: flex; align-items: center">
          <label v-t="'TXT_REFERENCE_PERIOD'"></label>
          <tooltip-informations
            :title="$t('TXT_TOOLTIP_REFERENCE_PERIOD')"
            :rightIcon="true"
          />
        </span>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          ref="menu"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              v-bind="attrs"
              v-on="on"
              :disabled="searchLoading && productGroupLoading"
              :label="$t('TXT_SELECT_PERIOD')"
              :placeholder="$t('TXT_SELECT_PERIOD')"
              hide-details
              outlined
              single-line
              dense
              readonly
              class="input-datapicker mt-1"
            >
              <template v-slot:append>
                <v-icon class="append-calendar" color="white" small>
                  mdi-calendar</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <!-- TODO - properly use user's locale -->
          <v-date-picker
            v-model="date"
            no-title
            scrollable
            range
            :min="minDay"
            :max="maxDay"
            :locale="localeForDatePicker"
          >
            <v-spacer></v-spacer>

            <v-btn
              text
              color="error"
              @click="cancelSelection"
              v-t="'TXT_CANCEL'"
            ></v-btn>
            <v-btn outlined color="primary" @click="setDate">Reset</v-btn>

            <v-btn
              color="primary"
              @click="$refs.menu.save(date)"
              v-t="'TXT_OK'"
            ></v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="4" md="3">
        <label>{{ $t("TXT_PRODUCT_GROUP") }}</label>
        <v-combobox
          v-model="selectedPricingGroup"
          :items="productGroups"
          @change="monitoringGroupSelected($event)"
          :disabled="productGroupLoading"
          :loading="productGroupLoading"
          item-text="description"
          item-value="idMonitoringGroup"
          append-icon="mdi-chevron-down"
          class="mt-1"
          dense
          outlined
          hide-details
        />
      </v-col>
    </v-row>

    <v-row no-gutters align="center">
      <v-col>
        <label v-t="'TXT_PRODUCTS'"></label>
        <v-autocomplete
          v-model="products"
          :items="items"
          :search-input.sync="search"
          :loading="searchLoading"
          :disabled="searchLoading"
          :label="$t('TXT_SEARCH_2')"
          @change="selectedPricingGroup = null"
          item-text="getDescription"
          append-icon="mdi-chevron-down"
          single-line
          hide-details
          multiple
          return-object
          outlined
          dense
          deletable-chips
          small-chips
          cache-items
          class="mt-2 mr-4"
        >
          <!--selected item -->
          <template v-slot:selection="{ item }">
            <v-chip
              @click:close="removeProduct(item)"
              close
              small-chips
              dark
              color="#E0E0E0"
            >
              <span style="color: black">
                {{ item.getDescription }}
              </span>
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>

      <prd-btn
        @click="searchProducts"
        :disabled="
          (searchLoading && productGroupLoading) ||
          (!selectedPricingGroup && products.length == 0)
        "
        :title="$t('TXT_SEE_RESULTS')"
        class="mt-7"
      />
      <prd-btn
        @click="cleanProductsAndGroupsSelected"
        :disabled="
          (searchLoading && productGroupLoading) ||
          (!selectedPricingGroup && products.length == 0)
        "
        :title="$t('TXT_CLEAR')"
        :outlined="true"
        :leftIcon="true"
        :icon="'mdi-close'"
        class="ml-4 mt-7"
      />
    </v-row>
  </v-card>
</template>

<script>
import service from "@/service/predimonitor-V2/index.js";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";
const Service = new service();
export default {
  components: { PrdBtn, TooltipInformations },
  data() {
    return {
      searchLoading: false,
      productGroupLoading: false,
      isLoading: false,

      searchLoadingCurrentNumber: 0,

      maxProductSelected: 10,
      // data picker vars
      menu: false,
      date: [],
      minDay: null,
      maxDay: null,

      products: [],
      productGroups: [],
      items: [],
      search: "",
      selectedPricingGroup: null,
      dateRangeText: null,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    localeForDatePicker() {
      return localStorage.getItem("lang") ?? "pt-BR";
    },
  },

  watch: {
    search() {
      if (!this.searchLoading) {
        var startTimer = (scope) => {
          scope.searchProductTimer = setTimeout(function () {
            var searching = (scope) => {
              var searchExp = scope.search;
              scope.searchLoading = true;

              let data = {
                idCompany: scope.idCompany,
                page: 1,
                recordsPerPage: 100000,
                filters: {
                  description: scope.search,
                },
                IncludeConfigInfo: false,
              };

              Service.getProductList(data)
                .then((res) => {
                  scope.items = res.data.result.records;
                  scope.searchLoading = false;
                  if (searchExp != scope.search) searching(scope);
                })
                .catch(() => {
                  scope.searchLoading = false;
                });
            };
            searching(scope);
            scope.searchProductTimer = null;
          }, 1000);
        };

        if (this.searchProductTimer == null) {
          startTimer(this);
        } else {
          clearTimeout(this.searchProductTimer);
          startTimer(this);
        }
      }
      return;
    },
    date() {
      // Set the resource range limit
      let dateResource = this.$store.getters.resources;
      let maxRange;

      let filteredDateResource = dateResource.filter((item) =>
        item.startsWith("predimonitor.days_range_")
      );

      if (filteredDateResource.length != 0) {
        maxRange = filteredDateResource.sort((a, b) => {
          const numA = parseInt(a.split("_")[2]);
          const numB = parseInt(b.split("_")[2]);
          return numB - numA;
        });
        maxRange = parseInt(maxRange[0].split("_").pop());
      } else {
        maxRange = 30;
      }
      this.minDay = this.date[0];

      var baseDay = new Date(this.date[0]);
      var actualDay = new Date();

      baseDay.setDate(baseDay.getDate() + maxRange);

      if (
        baseDay.setDate(baseDay.getDate()) >
        actualDay.setDate(actualDay.getDate())
      ) {
        this.maxDay = actualDay.toISOString().slice(0, 10);
      } else {
        this.maxDay = baseDay.toISOString().slice(0, 10);
      }
      this.dateRangeText = this.date.join(" ~ ");
    },
    products() {
      if (this.maxProductSelected < 10) this.maxProductSelected = 10;

      if (
        this.maxProductSelected > 10 &&
        this.products.length < this.maxProductSelected
      )
        this.maxProductSelected = this.products.length;

      if (this.products.length > this.maxProductSelected) {
        this.products.length = this.maxProductSelected;
      }
    },
    searchLoadingCurrentNumber() {
      if (this.searchLoadingCurrentNumber == 0 && !this.isLoading) {
        this.isLoading = false;
        this.$emit("setLoading", false);
        this.$emit("setLoadingGroup", false);
      }
    },
  },

  methods: {
    cancelSelection() {
      this.setDate();
      this.menu = false;
    },
    cleanProductsAndGroupsSelected() {
      this.products = [];
      this.selectedPricingGroup = null;
    },
    monitoringGroupSelected(event) {
      this.products = [];
      this.productGroupLoading = true;
      Service.getProductGroup(this.idCompany, event.idMonitoringGroup)
        .then((res) => {
          res.data.result.items.forEach((e) => {
            let item = this.items.find(
              (x) => x.idMonitoringItem == e.idMonitoringItem
            );

            if (item == null) return;

            this.products.push(item);
          });
          this.maxProductSelected = this.products.length;
          this.productGroupLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.productGroupLoading = false;
        });
    },

    setDate() {
      this.minDay = null;
      this.maxDay = null;
      var startDay = new Date();
      startDay.setDate(startDay.getDate() - 6);
      this.minRange = startDay.toISOString().slice(0, 10);
      this.maxDay = new Date().toISOString().slice(0, 10);

      this.date[0] = this.minRange;
      this.date[1] = new Date().toISOString().slice(0, 10);
      this.dateRangeText = this.date.join(" ~ ");
    },

    pushOneMoreDay(dataString) {
      const data = new Date(dataString);
      data.setDate(data.getDate() + 1);
      const ano = data.getFullYear();
      const mes = String(data.getMonth() + 1).padStart(2, "0");
      const dia = String(data.getDate()).padStart(2, "0");
      return `${ano}-${mes}-${dia}`;
    },

    removeProduct(item) {
      const index = this.products.indexOf(item);
      if (index >= 0) this.products.splice(index, 1);

      this.selectedPricingGroup = null;
    },

    async searchProducts() {
      this.isLoading = true;
      this.$emit("searchProducts", null);
      this.$emit("dateRange", this.date[0], this.date[1]);
      this.$emit("setLoading", true, this.products.length);
      this.$emit("productsLoading", this.products);
      this.$emit("isProductGroup", false);

      try {
        let newDateString = new Date(this.date[1]);
        var newDate = new Date(newDateString);
        newDate.setDate(newDateString.getDate() + 1);
        var finalDate = newDate.toISOString().split("T")[0];

        // Dividindo os produtos em lotes de 10
        const batches = [];
        for (let i = 0; i < this.products.length; i += 10) {
          batches.push(this.products.slice(i, i + 10));
        }

        // Enviando de 10 em 10 repetições sem esperar que cada uma delas termine
        for (const batch of batches) {
          const requests = batch.map((el) => {
            return Service.searchV3({
              end_date: this.pushOneMoreDay(finalDate),
              idCompany: this.idCompany,
              idMonitoringGroup: 0,
              idMonitoringItem: el.idMonitoringItem,
              includeIgnored: true,
              search_type: 1, //1- produto 3- grupo
              start_date: this.date[0],
            }).then((res) => {
              this.searchLoadingCurrentNumber--;
              if (res.data.answerType == 3) {
                this.$emit("searchProducts", [
                  {
                    idPredify: null,
                    idMonitoringItem: el.idMonitoringItem,
                    predify_product_code: el.idMonitoringItem,
                    predify_product_description: el.description,
                    hasError: true,
                    predify_product_category: null,
                    monitoringCreateDate: null,
                    lead: null,
                    results: [],
                  },
                ]);
              } else {
                let product = res?.data?.answer?.products ?? [];
                if (product.length > 0)
                  this.$emit("searchProducts", [product[0]]);
              }
            });
          });

          this.searchLoadingCurrentNumber += batch.length;

          await Promise.all(requests);
        }
      } catch (error) {
        this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_FAIL_SEARCH_PRODUCT"),
            type: "error",
          });
      } finally {
        this.isLoading = false;
        this.$emit("setLoading", false);
        this.$emit("setLoadingGroup", false);
      }
    },

    getProductGroups() {
      this.productGroupLoading = true;
      Service.getProductGroups(this.idCompany)
        .then((res) => {
          this.productGroups = res.data.result;
          this.productGroupLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.productGroupLoading = false;
        });
    },

    cleanProducts() {
      this.products = [];
    },
  },
  created() {
    this.setDate();
    this.getProductGroups();
  },
};
</script>

<style lang="scss" scoped>
.append-pencil {
  background: #3c5ca7;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.append-minus {
  background: red;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.input-datapicker .v-input__app end-inner {
  margin-top: 0px !important;
}

.append-calendar {
  height: 40px;
  width: 40px;
  background: #3c5ca7;
  margin-right: -11px;
  border-radius: 0px 4px 4px 0px;
  color: white;
}
</style>