<template>
  <div>
    <div class="main-card">
      <Resource-group
        :disable="isEditingGroup"
        :tab="tab"
        @openEditMode="editGroup"
        class="half-width"
      />
      <User-permission
        :editMode="isEditingGroup"
        :selectedGroup="selectedGroup"
        :tab="tab"
        @closeEditMode="isEditingGroup = false"
        class="half-width"
      />
    </div>
  </div>
</template>

<script>
import ResourceGroup from "./components/resource-group.vue";
import UserPermission from "./components/user-permission.vue";

export default {
  props: ["tab"],
  components: { ResourceGroup, UserPermission },
  data() {
    return {
      isEditingGroup: false,
      selectedGroup: null,
    };
  },
  methods: {
    editGroup(group) {
      this.selectedGroup = group;
      this.isEditingGroup = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-card {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  background-color: $neutral-color-high-light;

  .half-width {
    width: 50%;
  }
}
</style>