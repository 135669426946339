var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"expandable-table",attrs:{"headers":_vm.headers,"items":_vm.groups,"no-data-text":_vm.$t('TXT_NO_DATA_FOUND_DISPLAY'),"no-results-text":_vm.$t('TXT_NO_DATA_FOUND_DISPLAY'),"items-per-page":-1,"item-key":"idRule","show-expand":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center bg-grey mb-4"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.prePrice",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center bg-grey mb-4"},[_vm._v(" "+_vm._s(_vm.formatMonetary(item.prePrice))+" ")])]}},{key:"item.posPrice",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center bg-grey mb-4"},[_vm._v(" "+_vm._s(_vm.formatMonetary(item.posPrice))+" ")])]}},{key:"item.preMargin",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center bg-grey mb-4"},[_vm._v(" "+_vm._s(_vm.formatPercentage(item.preMargin))+" ")])]}},{key:"item.posMargin",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center bg-grey mb-4"},[_vm._v(" "+_vm._s(_vm.formatPercentage(item.posMargin))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center bg-grey mb-4",class:_vm.setStatusColor(item.status)},[_vm._v(" "+_vm._s(_vm.$t(item.statusMessage))+" ")])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"reset-expanded-item",attrs:{"colspan":_vm.headers.length}},[_c('div',{staticClass:"expanded-item-content"},[_c('v-divider',{attrs:{"color":"#666666"}}),_c('Rule-table',{attrs:{"rules":item.rules}})],1)])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }