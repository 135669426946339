<template>
  <div :class="[{ 'color-destaq': useDestaq },'pa-2' ,'info-item']">
    <div class="mb-2">
      <span class="destaq">{{ title }}</span>
      <Prd-tooltip :text="tooltip" />
    </div>

    <v-skeleton-loader v-if="loading" type="button" :height="22" class="mb-2" />

    <span class="font-weight-bold mb-2">{{ formatMonetary(price) }}</span>

    <div class="skeleton-loader">
      <span class="item-title">{{ $i18n.t("TXT_DEMAND_PROJECTION") }}: </span>

      <v-skeleton-loader v-if="loading" type="button" :height="20" />
      <span v-else class="font-weight-bold"
        >{{ formatDecimals(demand, 2) }}
      </span>
    </div>

    <div class="skeleton-loader">
      <span class="item-title">{{ $i18n.t("TXT_REVENUE_PROJECTION") }}: </span>

      <v-skeleton-loader v-if="loading" type="button" :height="20" />
      <span v-else class="font-weight-bold">{{ formatMonetary(profit) }} </span>
    </div>
  </div>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary.js";
import { formatDecimals } from "@/utils/format-decimals.js";
import PrdTooltip from "@/Design_System/common/prd-tooltip.vue";

export default {
  props: [
    "title",
    "tooltip",
    "price",
    "demand",
    "profit",
    "loading",
    "useDestaq",
  ],
  components: { PrdTooltip },

  methods: {
    formatMonetary,
    formatDecimals,
  },
};
</script>

<style lang="scss" scoped>
.skeleton-loader {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.main-info {
  display: flex;
  gap: 0.5rem;
  .info-item {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 2px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    padding: 8px;
    border-radius: 5px;

    .destaq {
      color: #3c5ca7;
      font-size: 14px;
    }

    .item-title {
      font-size: 14px;
    }
  }

  .info-destaq {
    border: 1px solid #3c5ca7;
  }
}

.color-destaq {
  border: 3px solid #3c5ca7 !important;
  box-shadow: 0px 3px 5px -1px #8ba2d8, 0px 2px 4px 0px #8ba2d8,
    0px 1px 10px 0px #8ba2d8;
}
</style>