<template>
  <v-expansion-panel-header @click="$emit('openPanel')" hide-actions>
    <div class="header-info-main">
      <!-- PRODUCT NAME -->
      <div class="my-flex-space-between font-weight-bold font-size-16">
        <div class="my-flex">
          <v-checkbox
            v-model="workFlowValue"
            v-if="workFlowAccess"
            :disabled="workFlowRules || wasErpSend"
            @click="aproveProduct"
            color="#3c5ca7"
          ></v-checkbox>
          <span class="font-weight-bold mr-6"
            >Artigo - {{ product.product }}</span
          >
          <span class="font-weight-bold">{{ product.productName }}</span>
        </div>

        <div>
          <v-tooltip
            v-if="!isElastic && !isLoading"
            class="tootip"
            left
            content-class="tooltip"
            color="#f5f5f5"
            max-width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                style="padding: 6px"
                class="card-destaq card-color-error"
                >Inelástico
              </span>
            </template>
            <label class="span">
              <slot
                ><span style="color: black"
                  >O produto apresenta caráter inelástico e portanto a adoção do
                  preço sugerido não causará aumento de receita e pode gerar
                  prejuízo</span
                ></slot
              >
            </label>
          </v-tooltip>

          <tooltip-informations
            v-if="showTooltipSemaphore.length != 0"
            color="#f2f2f2"
            :title="''"
            class="tootip"
            :rightIcon="true"
          >
            <table>
              <thead>
                <tr class="label">
                  <th>{{ $t("TXT_ORDER") }}</th>
                  <th>{{ $t("TXT_APPLIED_RULE") }}</th>
                  <th>{{ $t("TXT_PRE_PRICE") }}</th>
                  <th>{{ $t("TXT_POST_PRICE") }}</th>
                  <th>{{ $t("TXT_PRE_MARGIN") }}</th>
                  <th>{{ $t("TXT_POST_MARGIN") }}</th>
                  <th>{{ "Status" }}</th>
                  <th>{{ $t("TXT_REASON") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in showTooltipSemaphore"
                  :key="item.id"
                  class="label"
                >
                  <td>
                    {{ item.order }}
                  </td>
                  <td>
                    {{ $t(item.nameCondition) }}
                  </td>
                  <td>
                    {{ formatMonetary(item.oldPrice) }}
                  </td>
                  <td>
                    {{ formatMonetary(item.currentPrice) }}
                  </td>
                  <td>
                    {{ formatPercentage(item.oldMargin) }}
                  </td>
                  <td>
                    {{ formatPercentage(item.currentMargin) }}
                  </td>
                  <td>
                    {{ $t(item.statusMessage) }}
                  </td>
                  <td>
                    {{ $t(item.messageBlockedRuleCondition) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </tooltip-informations>
        </div>
      </div>

      <Prd-loading-circular v-if="isLoading" />

      <div v-else style="display: flex">
        <!-- LEFT CARD -->
        <div class="left-card">
          <div>
            <!-- PML -->
            <Card-header-info
              :value="pml"
              :formater="formatPercentage"
              :loading="loadingManualPrice"
              :title="'PML'"
            />

            <!-- ULT COMPRA -->
            <Card-header-info
              :value="lastPurchase"
              :loading="loadingManualPrice"
              :title="'Últ. compra'"
            />
          </div>

          <div>
            <!-- PISO -->
            <Card-header-info
              :value="floor"
              :formater="formatPercentage"
              :loading="loadingManualPrice"
              :title="'Piso'"
            />

            <!-- ESTOQUE -->
            <Card-header-info
              :value="stock"
              :formater="formatPercentage"
              :loading="loadingManualPrice"
              :title="'Estoque '"
            />
          </div>

          <div>
            <!-- PREÇO ATUAL -->
            <Card-header-info
              :value="currentPrice"
              :formater="formatMonetary"
              :loading="loadingManualPrice"
              :title="'Preço atual'"
            />

            <!--WEEK -->
            <Card-header-info
              :value="weeks"
              :loading="loadingManualPrice"
              :title="'Week\'s'"
            />
          </div>

          <div>
            <!--RO -->
            <Card-header-info
              :value="RO"
              :formater="formatPercentage"
              :loading="loadingManualPrice"
              :title="'(%) RO '"
            />

            <!--REFERENCIA-->
            <Card-header-info
              :value="reference"
              :loading="loadingManualPrice"
              :borderColor="'card-color-info'"
              :title="'Referência '"
            />
          </div>
        </div>
        <!-- RIGTH CARD -->
        <div class="right-card">
          <!-- <carder-header-info-full
            :value="minMarketPrice"
            :valueFormater="formatMonetary"
            :loading="loadingManualPrice"
            :title="'Menor Concorrente'"
            :tooltipText="'Preço do menor concorrente dividido pelo preço atual'"
          /> -->

          <!-- MENOR CONC -->
          <div>
            <span>Menor Concorrente</span>
            <span v-if="!loadingManualPrice">{{
              formatMonetary(minMarketPrice)
            }}</span>
            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />

            <span
              v-if="!loadingManualPrice"
              class="card-destaq mb-4 card-color-info teste"
            >
              {{ minMarketSellerName }}
            </span>

            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />

            <!-- GAP MENOR -->
            <span class="card-label font-weight-bold"
              >GAP
              <tooltip-informations
                :title="'Preço do menor concorrente dividido pelo preço atual'"
              />
            </span>

            <span
              v-if="!loadingManualPrice"
              class="card-destaq"
              :class="setMarginColor(gapMinMarket)"
              >{{ formatPercentage(gapMinMarket) }}</span
            >
            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
            />
          </div>

          <!-- MÉDIA MERCADO -->
          <div>
            <span>Média mercado </span>
            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />
            <span v-else>{{ formatMonetary(avgMarketPrice) }}</span>

            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />
            <span
              v-else
              :class="setCompetitorColor(positioningPercentage)"
              class="card-destaq mb-4"
            >
              {{ qtdMarketCompetitor }}
            </span>

            <!-- GAP MERCADO -->
            <span class="card-label font-weight-bold"
              >GAP
              <tooltip-informations
                :title="'Preço médio de mercado dividido pelo atual'"
            /></span>

            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />

            <span
              v-else
              class="card-destaq"
              :class="setMarginColor(gapAvgMarket)"
            >
              {{ formatPercentage(gapAvgMarket) }}
            </span>
          </div>

          <!-- PREÇO SUG IA -->
          <div>
            <span>Preço Sug. IA </span>
            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />

            <span v-else>
              {{ formatMonetary(suggestedPrice) }}
            </span>

            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />

            <span
              v-else
              class="card-destaq mb-4"
              :class="setMarginColor(suggestedPriceMargin)"
              >RO: {{ formatPercentage(suggestedPriceMargin) }}</span
            >

            <!-- GAP SUG -->
            <span class="card-label font-weight-bold"
              >GAP
              <tooltip-informations
                :title="'Preço concorrente dividido pelo preço da IA'"
            /></span>

            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />
            <span
              v-else
              class="card-destaq"
              :class="setMarginColor(gapSuggested)"
            >
              {{ formatPercentage(gapSuggested) }}
            </span>
          </div>

          <!-- AJUSTE MANUAL -->
          <div>
            <span>Ajuste manual </span>
            <span class="mb-4">
              <span style="font-size: 18px" v-show="!showAdjusPrice">{{
                formatMonetary(manualPrice)
              }}</span>
              <v-icon
                @click.stop="showAdjusPrice = true"
                v-show="!showAdjusPrice"
                :disabled="erpLoading || wasErpSend || isAproved"
                right
                :color="$prdStyles('color-primary')"
                class="mr-2"
                >mdi-pencil-box</v-icon
              >

              <div
                v-show="showAdjusPrice && !erpLoading"
                style="display: flex; align-items: center"
              >
                <v-text-field
                  v-model="manualPrice"
                  :loading="manualPriceLoading"
                  :disabled="manualPriceLoading"
                  @click.stop
                  @keyup.enter="adjustPrice"
                  dense
                  outlined
                  type="number"
                  hide-spin-buttons
                  hide-details
                ></v-text-field>
                <div class="flex-direction: column">
                  <v-icon
                    @click.stop="adjustPrice"
                    :disabled="manualPriceLoading"
                    color="#1D8527"
                    small
                  >
                    mdi-checkbox-marked
                  </v-icon>
                  <v-icon
                    @click.stop="showAdjusPrice = false"
                    :disabled="manualPriceLoading"
                    color="#B00020"
                    small
                  >
                    mdi-close-box
                  </v-icon>
                </div>
              </div>
            </span>

            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />

            <span v-else class="card-destaq card-color-warning mb-4"
              >RO: {{ formatPercentage(manualPriceMargin) }}</span
            >

            <!-- GAP MANUAL  -->
            <span class="card-label font-weight-bold"
              >GAP
              <tooltip-informations
                :title="'Preço concorrente dividido pelo preço do ajuste manual'"
              />
            </span>

            <v-skeleton-loader
              v-if="loadingManualPrice"
              type="card"
              height="22px"
              class="mb-4"
            />
            <span v-else class="card-destaq" :class="setMarginColor(gapManual)">
              {{ formatPercentage(gapManual) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </v-expansion-panel-header>
</template>

<script>
import { formatPercentage } from "@/utils/format-percentage.js";
import { formatMonetary } from "@/utils/format-toMonetary.js";
import tooltipInformations from "@/Design_System/common/tooltip-informations.vue";
import headerService from "@/service/new-ai-pricing/header.js";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import CardHeaderInfo from "./card-header-info.vue";
// import CarderHeaderInfoFull from "./carder-header-info-full.vue";
const HeaderService = new headerService();

export default {
  components: {
    tooltipInformations,
    PrdLoadingCircular,
    CardHeaderInfo,
  },
  props: ["product", "index"],
  data() {
    return {
      isLoading: false,
      erpLoading: false,
      manualPriceLoading: false,
      loadingManualPrice: false,
      showAdjusPrice: false,
      headerData: null,
      isMargin: false,
      workFlowValue: this.verifyWorkflow(),
      showTooltipSemaphore: [],
    };
  },

  watch: {
    product: {
      handler() {
        this.getHeaderPricing();
        this.getRulepath();
      },

      immediate: true,
    },
  },

  computed: {
    isElastic() {
      return this.headerData?.isElastic ?? null;
    },
    pml() {
      return this.headerData?.pml * 100 ?? "-";
    },
    floor() {
      return this.headerData?.floor * 100 ?? "-";
    },
    stock() {
      return this.headerData?.stock ?? "-";
    },
    weeks() {
      return this.headerData?.weeks ?? "-";
    },
    RO() {
      return this.headerData?.currentMargin ?? null;
    },
    manualPriceMargin() {
      return this.headerData?.manualPriceMargin ?? null;
    },
    reference() {
      return this.headerData?.priceReference ?? "-";
    },
    lastPurchase() {
      return this.headerData?.lastPurchase ?? "-";
    },
    markupSug() {
      return this.headerData?.markupSug.toFixed(2) ?? "-";
    },
    currentPrice() {
      return this.headerData?.currentPrice ?? "-";
    },
    minMarketPrice() {
      return this.headerData?.minMarketPrice ?? "-";
    },
    minMarketSellerName() {
      return this.headerData?.minMarketSellerName ?? "-";
    },
    avgMarketPrice() {
      return this.headerData?.avgMarketPrice ?? "-";
    },
    qtdMarketCompetitor() {
      return this.headerData?.qtdMarketCompetitor ?? "-";
    },
    positioningPercentage() {
      return this.headerData?.positioningPercentage ?? "-";
    },
    suggestedPrice() {
      return this.headerData?.suggestedPrice ?? "-";
    },
    suggestedPriceMargin() {
      return this.headerData?.suggestedPriceMargin ?? "-";
    },
    manualPrice: {
      get() {
        return this.headerData?.manualPrice ?? "-";
      },
      set(value) {
        this.headerData.manualPrice = value;
      },
    },
    gapMinMarket() {
      return this.headerData?.gapMinMarket ?? "-";
    },
    gapAvgMarket() {
      return this.headerData?.gapAvgMarket ?? "-";
    },
    gapSuggested() {
      return this.headerData?.gapSuggested ?? "-";
    },
    gapManual() {
      return this.headerData?.gapManual ?? "-";
    },
    isPublished() {
      if (
        this.product?.workflowApproval?.lastAction?.toSend &&
        !this.product?.isPublished
      )
        return true;

      return false;
    },
    wasErpSend() {
      return this.product.isPublished;
    },
    workFlowAccess() {
      if (!this.product?.workflowApproval?.actions) return false;

      let access = this.product.workflowApproval.actions.filter((el) => {
        return el.action == 0 || el.action == 1;
      });

      if (access.length != 0) return true;
      else return false;
    },
    workFlowRules() {
      if (!this.product?.workflowApproval) return false;

      let action = this.product.workflowApproval.actions.filter((el) => {
        return el.action == 0;
      });

      if (
        this.workFlowValue &&
        action.filter((el) => {
          if (el.action == 1) return true;
        })
      ) {
        return false;
      }

      if (
        !this.workFlowValue &&
        action.filter((el) => {
          if (el.action == 0) return true;
        })
      ) {
        return false;
      }

      return true;
    },
    isAproved() {
      if (!this.product?.workflowApproval) return false;

      if (
        this.product?.workflowApproval?.lastAction?.action == 0 &&
        this.product?.workflowApproval?.lastAction?.userType == 0
      )
        return true;
      else return false;
    },
    erpList() {
      return this.$store.getters.erpList;
    },
  },

  methods: {
    formatPercentage,
    formatMonetary,

    getHeaderPricing(isManual) {
      if (isManual) this.loadingManualPrice = true;
      else this.isLoading = true;

      HeaderService.getHeaderPricing(this.product.idEnterprisePricesProjection)
        .then((res) => {
          (this.workFlowValue = this.verifyWorkflow()),
            (this.headerData = res.data.result);
          this.isLoading = false;
          this.loadingManualPrice = false;
          this.$emit("headerPricing", this.headerData);
        })
        .catch((error) => {
          console.log("Erro ao buscar pricing do header", error);
          this.isLoading = false;
          this.loadingManualPrice = false;
        });
    },

    async getRulepath() {
      let response = await HeaderService.getRulePath(
        this.product.idEnterprisePricesProjection
      );
      this.showTooltipSemaphore = response?.data?.result ?? [];
    },

    verifyWorkflow() {
      let workFlow = this.product.workflowApproval;
      if (
        workFlow?.lastAction?.userType == 0 &&
        workFlow?.lastAction?.action == 0
      )
        return true;
      else return false;
    },

    async aproveProduct() {
      this.$emit("setLoading", true);

      try {
        const { idEnterprisePricesProjection } = this.product;
        const action = this.workFlowValue ? 0 : 1;

        const aprovationResponse = await HeaderService.setWorkflow({
          idEnterprisePricesProjection,
          action,
        });

        const pricingObject = {
          idEnterprisePricesProjection,
          productsToReplicate: aprovationResponse.data.result,
        };
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_UPDATED_PRECIFICATION"),
          type: "success",
        });
        this.$emit("attNewPricing", pricingObject);
      } catch (error) {
        console.log("Erro no aproveProduct", error);
        this.workFlowValue = !this.workFlowValue;
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_UPDATED_PRECIFICATION"),
          type: "error",
        });
      } finally {
        this.$emit("setLoading", false);
      }
    },

    adjustPrice() {
      this.manualPriceLoading = true;
      let request = {
        idEnterprisePricesProjection: this.product.idEnterprisePricesProjection,
        manualPrice: parseFloat(this.manualPrice),
        manualContributionMargin: null,
        isManual:
          this.manualPrice == 0 || this.manualPrice == "" ? false : true,
      };

      HeaderService.adjustPrice(request)
        .then((res) => {
          if (res.data.result) {
            this.showAdjusPrice = false;
            this.manualPriceLoading = false;
            this.getHeaderPricing(true);
            this.$emit("setProductContent");
          }
        })
        .catch((error) => {
          console.log("Erro ao editar preço manual", error);
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_ERROR_UPDATED_MANUAL_PRICE"),
            type: "error",
          });
          this.manualPriceLoading = false;
        });
    },

    copyFunction(text) {
      var textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setTimeout(() => {}, 1000);
      this.$store.commit("snackbarV2/set", {
        message: this.$i18n.t("TXT_COPY"),
        type: "info",
      });
    },

    setMarginColor(margin) {
      if (margin == "-") return "card-color-info";

      if (margin < 0) return "card-color-error";

      return "card-color-success";
    },

    setCompetitorColor(decimalValue) {
      let value = Math.floor(decimalValue);

      if (!value || value == "-") {
        return "card-color-black";
      } else if (value >= 1 && value <= 49) {
        return "card-color-error";
      } else if (value >= 50 && value < 70) {
        return "card-color-warning";
      } else if (value >= 70 && value <= 99) {
        return "card-color-success";
      } else if (value >= 100) {
        return "card-color-info";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ref {
  transition: 0.1s;
}
.ref:hover {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.teste {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.teste:hover {
  white-space: break-spaces;
  overflow: visible;
}
.header-info-main {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .left-card {
    display: flex;
    width: 45%;
    gap: 8px;
    margin-right: 8px;

    > div {
      width: 25%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      div {
        .content-box {
          span:first-child {
            font-size: 12px;
            margin-bottom: 8px !important;
          }

          span:nth-child(2) {
            font-size: 16px;
            font-weight: bold;
            color: $brand-color-primary-pure;
          }
        }
      }
    }

    .content-box {
      span:first-child {
        font-size: 12px;
        margin-bottom: 8px !important;
      }

      span:nth-child(2) {
        font-size: 16px;
        font-weight: bold;
        color: $brand-color-primary-pure;
      }
    }
  }

  .right-card {
    display: flex;
    width: 55%;

    > div {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      flex-grow: 1;

      span:first-child {
        font-size: 12px;
        margin-bottom: 8px !important;
      }

      span:nth-child(2) {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 24px;
        color: $brand-color-primary-pure;
      }
    }
  }

  .flex-grow-1 {
    flex-grow: 1;
  }

  .content-box {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    flex-grow: 1;
  }

  .content-box span:first-child {
    margin-bottom: 4px;
  }

  .more-info {
    color: #3c5ca7;
    font-weight: bold;
  }
  .gap {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .error-color {
    color: #b00020;
    font-size: 16px;
  }
  .success-color {
    color: #1d8527;
    font-size: 16px;
  }
}
.card-label {
  font-size: 12px;
  margin-bottom: 8px !important;
}

.card-value {
  font-size: 16px;
  font-weight: bold;
  color: $brand-color-primary-pure;
}

.card-destaq {
  color: $brand-color-primary-pure;
  background-color: $brand-color-primary-light;
  padding: 4px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
}

.card-color-black {
  background-color: $neutral-color-low-medium;
  color: black;
  font-weight: 400;
}

.card-color-info {
  background-color: $brand-color-primary-light;
  color: black;
  font-weight: 400;
}
.card-color-success {
  background-color: $feedback-color-success-light;
  color: black;
  font-weight: 400;
}
.card-color-error {
  background-color: $feedback-color-warning-light;
  color: black;
  font-weight: 400;
}
.card-color-warning {
  background-color: $feedback-color-helper-light;
  color: black;
  font-weight: 400;
}
.card-color-white-space {
  background-color: $neutral-color-high-dark;
  color: black;
  font-weight: 400;
}

.my-flex {
  display: flex;
  align-items: center;
}
.my-flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>