import store from "@/store";

const buildFiltersRequest = (filters) => {
  let filtersForRequest = [];
  filters.forEach((filter) => {
    if (filter.selected) {
      filtersForRequest.push({
        idEnterprisePriceGroup_DefaultFilter:
          filter.idEnterprisePriceGroupDefaultFilter,
        value: filter.selected.value,
      });
    }
  });
  return filtersForRequest;
}

class GroupRules {
  idRule = 0;
  description = null;
  isAllProducts = false;
  isActive = false;
  numberOrder = 0;
  filters = [];
  curve = null;
  elasticity = null;
  conditions = [];
  originalObj = null

  constructor(idRule, description, isActive, numberOrder) {
    this.idRule = idRule ?? 0;
    this.description = description ?? "";
    this.isActive = isActive ?? false;
    this.numberOrder = numberOrder ?? 0;
  }

  buildRequest(elasticity, curve) {
    let request = {
      idCompany: store.getters.userData.idCompany,
      idRule: parseInt(this.idRule) ?? 0,
      description: this.description,
      isAllProducts: this.isAllProducts,
      numberOrder: this.numberOrder,
      filters: buildFiltersRequest(this.filters),
      allowAbcCurve: curve || "ABC",
      enterprise_ElasticityStatus: elasticity?.value ?? 0,
      conditions: this.conditions,
      categories: [],
      enterprisePricingType: 0,
      isActive: true,
      isBlocked: false,
      isSimulation: false,
      products: [],
    };
    return request;
  }

  addRule(conditionType) {
    this.conditions.push({
      conditionType: conditionType,
      idCondition: 0,
      isActive: true,
      numberOrder: this.conditions.length,
      values: [],
    });
  }

  removeRule(indexCondition) {
    this.conditions.splice(indexCondition, 1).forEach((el, index) => [
      (el.numberOrder = index),
    ]);
  }

  updateSequenceRules(updatedList) {
    this.conditions = updatedList;
  }

  getCondition(conditionType, indexCondition) {
    let foundedItem = this.conditions.find(
      (el, index) =>
        el.conditionType == conditionType && index == indexCondition
    );
    return foundedItem ?? null
  }

  updateLimitWeekDays(conditionType, indexCondition, selectedDays) {
    let foundedItem = this.getCondition(conditionType, indexCondition)
    if (foundedItem) {
      foundedItem.values = [];

      selectedDays.forEach((day) => {
        foundedItem.values.push({
          idConditionValue: 0,
          idRuleCondition: 0,
          stringValue: day,
        });
      });
    }
  }

  updatePsycologicPriceConditions(conditionType, indexCondition, values) {

    let foundedItem = this.getCondition(conditionType, indexCondition)
    if (foundedItem) foundedItem.values = values;
  }

  updateConditionsDefault(conditionType, indexCondition, formatedValue) {

    let foundedItem = this.getCondition(conditionType, indexCondition)
    if (foundedItem) {
      foundedItem.values = [];

      formatedValue.forEach((item) => {
        foundedItem.values.push({
          ...item,
          idCondition: item.idCondition ?? 0,
        });
      });
    }
  }

  reset() {

    if (this.originalObj == null) return;

    const copy = structuredClone(this.originalObj)
    this.description = copy.description;
    this.isAllProducts = copy.isAllProducts;
    this.isActive = copy.isActive;
    this.numberOrder = copy.numberOrder;
    this.filters = copy.filters;
    this.curve = copy.curve;
    this.elasticity = copy.elasticity;
    this.conditions = copy.conditions;
  }

  updateOriginalObject() {
    this.originalObj.description = structuredClone(this.description);
    this.originalObj.isAllProducts = structuredClone(this.isAllProducts);
    this.originalObj.isActive = structuredClone(this.isActive);
    this.originalObj.numberOrder = structuredClone(this.numberOrder);
    this.originalObj.filters = structuredClone(this.filters);
    this.originalObj.curve = structuredClone(this.curve);
    this.originalObj.elasticity = structuredClone(this.elasticity);
    this.originalObj.conditions = structuredClone(this.conditions);
  }
}

export default GroupRules