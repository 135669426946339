<template>
  <div>
    <label class="label-comboBox"> {{ title }} </label>
    <v-textarea
      @input="$emit('input', $event)"
      v-bind="$attrs"
      dense
      outlined
      hide-details="auto"
      class="mt-1"
    >
    </v-textarea>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
</style>