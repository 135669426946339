import { render, staticRenderFns } from "./product-card.vue?vue&type=template&id=5ca11cb6&scoped=true"
import script from "./product-card.vue?vue&type=script&lang=js"
export * from "./product-card.vue?vue&type=script&lang=js"
import style0 from "./product-card.vue?vue&type=style&index=0&id=5ca11cb6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ca11cb6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCombobox,VIcon,VRow,VSwitch,VTextField})
