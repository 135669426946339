import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class CrawlerConfigService extends ServiceBase {

    async GetAllCrawlerPerPage(page, recordsPerPage, search) {
        return await axiosInstance.get(API_URL + "api/V2/MonitoringCrawler/ListPaginated",
            { params: { page, recordsPerPage, search } }
        );
    }
    async GetAllCrawler() {
        return await axiosInstance.get(API_URL + "api/V2/MonitoringCrawler/List");
    }

    async GetCountries() {
        return await axiosInstance.get(API_URL + "api/V2/Region/ListCountries");
    }

    async InsertUpdateDeleteCrawler(data) {
        return await axiosInstance.post(API_URL + "api/V2/MonitoringCrawler",
            data
        );
    }

}
