import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class StoreManagementService extends ServiceBase {
  /**
   * Retrieves the user list for a specified company.
   *
   * @async
   * @function GetUserList
   * @param {string} idCompany - The unique identifier for the company to retrieve the user list.
   * @param {number} page - The current page of results for pagination.
   * @param {number} pageSize - The number of records per page.
    * @param {string} [searchTerm] - The term to search by name or email.
    * @param {string} [affiliateId] - The affiliate ID to filter users by a specific branch.
    * @param {string} [cargoId] - The cargo ID to filter users by a specific position.
    * @returns {Promise<Array<User>>} A Promise that resolves to an array of user objects.
   *
   * @typedef {Object} User
   * @property {string} id - The unique identifier for the user.
   * @property {string} name - The user's name.
   * @property {string} phoneNumber - The user's phone number.
   * @property {string} email - The user's email address.
   * @property {string} cargo - The user's position or role.
   * @property {number} cargoId - The unique identifier for the user's position.
   * @property {Array<FilterPermissionValue>} filterPermissionValues - An array of objects representing the user's filter permissions.
   *
   * @typedef {Object} FilterPermissionValue
   * @property {string} filter - The type of filter applied, e.g., "Filial".
   * @property {Array<string>} values - An array of values that correspond to the filter applied.
   */
  async GetUserList({
    idCompany,
    page,
    pageSize,
    searchTerm,
    affiliateId,
    cargoId,
  }) {
    let url = `api/v2/Enterprise/PriceGroups/GetUsersFilterPermissionsByCompanyId?companyId=${idCompany}&page=${page}&pageSize=${pageSize}&filters=Filial`;

    if (searchTerm) url += `&nameOrEmail=${searchTerm}`;
    if (affiliateId) url += `&filterValue=${affiliateId}`;
    if (cargoId) url += ` &cargoId=${cargoId}`;

    return await axiosInstance.get(API_URL + url);
  }

  /**
   * Retrieves the list of all stores associated with a specified company.
   *
   * @async
   * @function GetStoreList
   * @param {string} idCompany - The unique identifier for the company to retrieve the list of stores.
   * @returns {Promise<Array<Store>>} A Promise that resolves to an array of objects, where each object represents a store.
   *
   * @typedef {Object} Store
   * @property {string} value - The code of store.
   * @property {string} description - The union of code and name of store.
   */
  async GetStoreList(idCompany) {
    return await axiosInstance.get(
      API_URL +
        `/api/v2/Enterprise/PriceGroups/FilterValuesByCompanyId?companyId=${idCompany}&filterName=Filial`
    );
  }

  /**
   * Retrieves a 200 code when the associated fillial are updated.
   *
   * @async
   * @function UpdateStore
   * @param {string} idCompany - The code of company
   * @param {string} userId - The unique identifier of user
   * @param {Array<string>} request - List of code that will linked for user
   */
  async UpdateStore(idCompany, userId, request) {
    return await axiosInstance.post(
      API_URL +
        `/api/v2/Enterprise/PriceGroups/UpdateFilterPermissionValuesForUser?companyId=${idCompany}&userId=${userId}&filterName=Filial`,
      request
    );
  }

  /**
   * Exports an xlsx file containing users and their associations based on the company ID.
   *
   * @async
   * @function exportFile
   * @param {string} idCompany - The ID of the company.
   * @returns {Promise<Blob>} A Promise that resolves to a Blob representing the xlsx file.
   */
  async exportFile(idCompany) {
    return await axiosInstance.get(
      API_URL +
        `api/v2/Enterprise/PriceGroups/Export/UsersFilterPermissionsByCompanyId?companyId=${idCompany}`,
      { responseType: "blob" }
    );
  }
    /**
    * Retrieves a 200 code when the associated fillial are updated by file import.
    *
    * @async
    * @function ImportFile
    * @param {string} idCompany - The code of company
    * @param {File} request - Archive of user that going to updated
    */
    async ImportFile(idCompany, request) {
        return await axiosInstance.post(API_URL + `api/v2/Enterprise/PriceGroups/Import/UsersFilterPermissionsByCompanyId?companyId=${idCompany}`, request);
    }

}
