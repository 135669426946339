<template>
  <v-card elevation="4">
    <highcharts class="hc" :options="chartData"></highcharts>
  </v-card>
</template>

<script>
import ServiceUPL from "@/service/upl/index";
import { formatMonetary } from "@/utils/format-toMonetary";
export default {
  props: ["product"],
  data() {
    return {
      service: new ServiceUPL(),
      dados: [],
      series: [],
      chartData: {
        title: {
          text: `<b>${this.$i18n.t("TXT_COST_PER_HECTARE")} | ${this.$i18n.t(
            "TXT_EXTERNAL_CAPTURE"
          )} | BRL</b>`,
          align: "left",
        },

        subtitle: {
          text: this.$i18n.t("TXT_SUBTITLE_CHART_UPL", {
            font: "Mq Stat",
            date: "-",
          }),
          align: "left",
        },

        yAxis: {
          title: {
            text: "",
          },
        },

        xAxis: {
          categories: [],
        },
        tooltip: {
          enabled: false,
        },

        series: [],
      },
    };
  },
  async beforeMount() {
    if (this.product != null) {
      await this.loadChartData(this.product.nome, "#3C5CA7");

      if (
        this.product.tipoPrecificacao === "Concorrente" ||
        this.product.tipoPrecificacao === "Mistura Interna" ||
        this.product.tipoPrecificacao === "Mistura Externa"
      ) {
        await this.loadChartDataForCapturas();
      }

      this.chartData.series = this.series;
    }
  },

  methods: {
    async loadChartData(name, color) {
      const res = await this.service.getGraficoCustoParaPrecificacao(name);
      this.dados = res.data;

      if (this.dados) {
        this.series.push({
          name,
          data: this.dados.values,
          color,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return formatMonetary(this.y);
            },
          },
        });

        this.chartData.xAxis.categories = this.dados.meses;
        this.chartData.subtitle.text = this.$i18n.t("TXT_SUBTITLE_CHART_UPL", {
          font: "Mq Stat",
          date: this.dados.dataInsert,
        });
      }
    },

    async loadChartDataForCapturas() {
      const colors = ["#EC4C37", "#1D8527"];
      for (let i = 0; i < this.product.capturas.length; i++) {
        if (this.product.capturas[i] != null) {
          await this.loadChartData(
            this.product.capturas[i].nomeCaptura,
            colors[i]
          );
        }
      }
    },
  },
};
</script>