<template>
  <div>
    <Prd-modal
      :controlModal="calculateModal"
      :title="$t('TXT_NEW_PRICING_TITLE')"
      :icon="'mdi-checkbox-marked-circle-outline'"
      :iconColor="'#1D8527'"
      :titleDescription="$t('TXT_PRICING_SAVED_SUCCESS')"
      :description="$t('TXT_NEW_PRICING_DESCRIPTION')"
      :buttonTitle2="$t('TXT_CALCULATE_PRICES')"
      @actionButton1="$emit('closeCalculateModal')"
      @actionButton2="actionButton2"
    >
      <template slot="content">
        <div>
          <p class="font-weight-bold ma-0 pa-0 mb-2">{{ pricingName }}</p>
          <p v-show="company" class="ma-0 pa-0 mb-2">
            {{ $t("TXT_BRANCH") }}:
            <span class="font-weight-bold">{{ company }}</span>
          </p>
        </div>
        <div v-if="filters.length > 0">
          <Filter-expansion-panel
            v-show="
              filter.values.length > 0 &&
              filter.dbField != 7 &&
              filter.dbField != 0
            "
            v-for="filter in filters"
            :key="filter.id"
            :filter="filter"
            class="mb-2"
          />
        </div>
      </template>
    </Prd-modal>
  </div>
</template>

<script>
import FilterExpansionPanel from "./filter-expansion-panel.vue";

import PrdModal from "@/components/common/prd-modal.vue";

export default {
  components: { PrdModal, FilterExpansionPanel },
  props: ["calculateModal", "pricing"],
  data() {
    return {};
  },

  computed: {
    pricingName() {
      return this.pricing?.pricingName ?? "";
    },

    company() {
      return this.pricing?.company ?? null;
    },

    filters() {
      return this.pricing?.filters ?? [];
    },
  },

  methods: {
    actionButton2() {
      this.$emit("calculatePricing");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>