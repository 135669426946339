<template>
  <div class="stats-container" v-if="enableStatus">
    <Prd-btn
      title="Status"
      :outlined="true"
      :leftIcon="disableButton ? false : isLoading ? false : true"
      :color="buttonColor"
      :icon="iconType"
      :disabled="isLoading || disableButton || disableTagImport"
      :loading="isLoading && isFirstSearch"
      @click="checkImportStatus"
    />

    <transition name="slide-fade">
      <div v-show="showStatus" class="status">
        <v-card class="pa-4">
          <h4>{{ $i18n.t("TXT_IMPORTATION_STATUS") }}</h4>
          <Skeleton-loader v-if="isLoading" class="mt-4" />

          <Stepper-status
            v-show="!isLoading"
            :showStatus="showStatus"
            :currentStep="currentStep"
            :stepStatus="stepStatus"
            :stepMessage="stepMessage"
            :stepPercent="stepPercent"
            :hideProgression="hideProgression"
            :stepList="stepList"
            @closeStatus="showStatus = false"
          />
        </v-card>
      </div>
    </transition>
  </div>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import SkeletonLoader from "./components/skeleton-loader.vue";
import StepperStatus from "./components/stepper-status.vue";
import service from "@/service/tags-config/index";
const Service = new service();
export default {
  components: { PrdBtn, SkeletonLoader, StepperStatus },
  props: ["disableTagImport"],
  data() {
    return {
      isLoading: false,
      showStatus: false,
      hideProgression: true,
      isFirstSearch: true,
      disableButton: false,
      timeoutIds: [],

      stepList: [],

      // Pending = 0,
      // Running = 1,
      // Finished = 2,
      // Error = 3
      stepStatus: null,

      currentStep: null,
      stepMessage: "",
      stepPercent: null,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    buttonColor() {
      return this.stepStatus == 3
        ? this.$prdStyles("color-warning")
        : this.$prdStyles("color-primary");
    },

    iconType() {
      if (this.disableButton) return "";

      return this.isLoading
        ? ""
        : this.stepStatus == 3
        ? "mdi-alert-outline"
        : "mdi-list-status";
    },

    statusEnv() {
      return process.env.VUE_APP_ENABLE_IMPORT_STATUS;
    },

    enableStatus() {
      return this.statusEnv == "true";
    },
  },

  methods: {
    checkImportStatus() {
      if (!this.showStatus && this.isFirstSearch) {
        this.showStatus = true;
        this.isFirstSearch = false;
        return;
      }

      if (this.showStatus && !this.isFirstSearch) {
        this.showStatus = false;
        return;
      }

      this.showStatus = !this.showStatus;
      this.getStatus();
    },

    async getStatus() {
      this.cancelAllTimeouts();
      this.isLoading = true;
      this.stepList = this.returnStepList();

      try {
        let response = await Service.checkImportStatus(this.idCompany);
        if (response.status == 204) {
          this.isLoading = false;
          return;
        }

        this.hideProgression = false;

        this.stepStatus = response?.data?.status ?? 0;
        this.currentStep = response?.data?.currentStep ?? -1;
        this.stepMessage = response?.data?.message ?? "";
        this.stepPercent = response?.data?.percent ?? null

        this.disableButton = false;
        this.removeIndexIfError(this.stepStatus, this.currentStep);
        this.closeStatusAfterTime(this.isFirstSearch);
      } catch (error) {
        this.handleError(error);
      }

      this.isLoading = false;
    },

    removeIndexIfError(status, currentStep) {
      if (status == 3) {
        let foundedIndex = this.stepList.findIndex(
          (step) => step.number == currentStep
        );

        this.stepList.splice(foundedIndex + 1);
      }
    },

    returnStepList() {
      return [
        {
          number: "1",
          text: "TXT_STARTING_IMPORT",
        },
        {
          number: "2",
          text: "TXT_IMPORTING_MONITORING_ITEMS",
        },
        {
          number: "3",
          text: "TXT_CLEANUP_DATA",
        },
        {
          number: "4",
          text: "TXT_REINDEX_DATA",
        },
      ];
    },

    handleError(error) {
      console.log(error);
      this.$store.commit("snackbarV2/set", {
        type: "error",
        message: this.$i18n.t("TXT_TAGS_IMPORT_STATUS_ERROR"),
      });
      this.disableButton = true;
    },

    openAndGetStatus() {
      this.showStatus = true;
      this.isFirstSearch = false;
      this.getStatus();
    },

    closeStatusAfterTime(isFirstSearch) {
      if (isFirstSearch) return;

      this.addTimeout(() => {
        this.showStatus = false;
      }, 6000);
    },

    addTimeout(callback, delay) {
      const timeoutId = setTimeout(callback, delay);
      this.timeoutIds.push(timeoutId);
    },

    cancelAllTimeouts() {
      this.timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
      this.timeoutIds = [];
    },
  },

  created() {
    if (this.enableStatus) this.getStatus();
  },
};
</script>

<style scoped lang="scss">
.stats-container {
  position: relative;

  .status {
    margin-top: 10px;
    top: 100%;
    border-radius: 5px;
    position: absolute;
    width: max-content;
    right: -25%;
    z-index: 1000;
  }
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-20%);
  opacity: 0;
}
</style>