<template>
  <standard-user-store-card :isSelected="isSelected">
    <template v-slot:content>
      <v-badge dot :color="$prdStyles('color-secundary')" :value="hasChanges">
        <h3>{{ name }}</h3>
      </v-badge>
      <div class="d-flex gap-1">
        <p v-for="(cargo, index) in userData.positions" :key="index">
          {{ cargo.position }} ;
        </p>
      </div>
      <p>{{ email }}</p>
      <p>
        <span class="font-weight-bold mr-1">{{ $t("TXT_LINKED_STORES") }}:</span
        >{{ linkedStores }}
      </p>
    </template>
  </standard-user-store-card>
</template>

<script>
import StandardUserStoreCard from "../common/standard-user-store-card.vue";
export default {
  props: {
    userData: {
      type: Object,
      require: true,
      default: () => ({
        id: null,
        name: null,
        cargo: null,
        email: null,
        linkedStores: [],
        isSelected: false,
      }),
    },
  },
  components: {
    StandardUserStoreCard,
  },
  computed: {
    name() {
      return this.userData?.name;
    },
    cargo() {
      return this.userData?.cargo;
    },
    email() {
      return this.userData?.email;
    },
    linkedStores() {
      const filialValues =
        this.userData?.filterPermissionValues.find(
          (filter) => filter.filter.replace(/\s+/g, "") == "Filial"
        )?.values ?? [];

      if (
        !this.userData?.filterPermissionValues?.length ||
        !filialValues.length
      )
        return "-";

      const getStoreNameByIndex = (index) => {
        const value = filialValues[index];
        const name = this.$store.getters.getStoreNameByValue(value);
        return name;
      };

      const firstStore = getStoreNameByIndex(0);
      const secondStore = getStoreNameByIndex(1);

      if (filialValues.length == 1) return `${firstStore}`;
      else return `${firstStore},${secondStore} ...`;
    },
    hasChanges() {
      return this.$store.getters.hasChangesByUserId(this.userData.id) ?? false;
    },
    isSelected() {
      return this.userData?.isSelected ?? false;
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0px;
  color: $neutral-color-low-medium;
}
</style>
