<template>
  <v-dialog
    v-model="isOpen"
    persistent
    :max-width="isLoading ? '100%' : '785px'"
  >
    <template v-slot:activator="{ on, attrs }">
      <Prd-btn
        :leftIcon="true"
        :icon="'mdi-upload-outline'"
        :title="$t('TXT_EXPORT')"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <Select-data-card
      @closeModal="isOpen = false"
      @selectedCategories="selectedCategories = $event"
      @exportCharts="exportCharts"
      :isLoading="isLoading"
      :nameStore="nameStore"
      :lastRequest="lastRequest"
    />
    <charts-pdf-main
      v-show="showPdf"
      @pdfContent="elementToPDF = $event"
      :chartsData="chartsData"
      :nameStore="nameStore"
      :lastRequest="lastRequest"
    />
  </v-dialog>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import Service from "@/service/home20/index";
import SelectDataCard from "./components/select-data-card/select-data-card.vue";
import ChartsPdfMain from "./components/charts-pdf/charts-pdf-main.vue";
import html2pdf from "html2pdf.js";
import { formatDate } from "@/utils/prd-format-date";
export default {
  components: {
    PrdBtn,
    SelectDataCard,
    ChartsPdfMain,
  },
  props: ["nameStore", "lastRequest"],

  data() {
    return {
      isOpen: false,
      isLoading: false,
      selectedCategories: [],
      service: new Service(),
      chartsData: [],
      showPdf: false,
      elementToPDF: null,
    };
  },
  methods: {
    async exportCharts() {
      this.isLoading = true;
      this.chartsData = [];

      try {
        await Promise.all(
          this.selectedCategories.map(async (category) => {
            const request = this.lastRequest;
            request.category = category;

            const response = await this.service.GetGraficoIC(request);

            const chartData = this.bodyToChartPDF(category, response.data);

            this.chartsData.push(chartData);
          })
        );
        this.isLoading = false;
        this.savePDF();
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },

    bodyToChartPDF(category, dataList) {
      return {
        category,
        dataList,
      };
    },

    async savePDF() {
      this.showPdf = true;
      this.isLoading = true;

      const options = {
        margin: [8, 0, 0, 0],
        filename: `${this.$i18n
          .t("TXT_COPMPETITIVENESS_CHARTS")
          .replace(/\s/g, "")}${formatDate(new Date())}.pdf`,
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "landscape",
          // orientation: "portrait",
        },
        pagebreak: { mode: "avoid-all", before: "#page2el" },
      };
      setTimeout(async () => {
        await html2pdf()
          .set(options)
          .from(this.elementToPDF)
          .save()
          .finally(() => {
            this.showPdf = false;
            this.isLoading = false;
          });
      }, 1500);
    },
  },
};
</script>