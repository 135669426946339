<template>
  <div>
    <label class="label-comboBox">
      <slot name="title">{{ title }}</slot>
      <Prd-tooltip v-if="tooltip" :text="tooltip" />
    </label>
    <v-text-field
      dense
      outlined
      hide-details="auto"
      v-bind="$attrs"
      class="mt-1"
      @input="$emit('input', $event)"
      @blur="$emit('blur', $event)"
      @focus="$emit('focus', $event)"
    >
    </v-text-field>
  </div>
</template>

<script>
import PrdTooltip from "@/Design_System/common/prd-tooltip.vue";
export default {
  components: { PrdTooltip },
  props: {
    title: {
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
      default: "",
    },
  },
};
</script>