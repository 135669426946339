<template>
  <v-expansion-panel-content>
    <v-data-table :headers="headers" :items="priceResults" :items-per-page="5">
      <template v-slot:[`item.productName`]="{ item }">
        <td class="table-product-name">
          <div class="copy-box">
            <v-icon
              @click.stop="copyFunction(item.productName)"
              :color="$prdStyles('color-primary')"
              left
              small
              class="copy-button"
              >mdi-content-copy</v-icon
            >
            <a :href="item.productLink" target="_blank">
              {{ item.productName }}</a
            >
          </div>
        </td>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        {{
          item.total_priceInstallment
            ? formatMonetary(item.total_priceInstallment)
            : formatMonetary(item.price)
        }}
      </template>

      <template v-slot:[`item.price_tax_st`]="{ item }">
        <td class="text-center">
          {{ item.price_tax_st ? item.price_tax_st : "-" }}
        </td>
      </template>

      <template v-slot:[`item.deflatedValue`]="{ item }">
        {{ formatMonetary(item.deflatedValue) }}
      </template>

      <template v-slot:[`item.quantityInstallment`]="{ item }">
        {{ item.quantityInstallment ? item.quantityInstallment + "x" : "-" }}
      </template>

      <template v-slot:[`item.crawlerDate`]="{ item }">
        <td class="text-center">
          {{
            item.crawlerDate
              ? item.crawlerDate
                  .slice(0, 10)
                  .replace("-", "/")
                  .replace("-", "/")
              : null
          }}
        </td>
      </template>
    </v-data-table>
  </v-expansion-panel-content>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary.js";
export default {
  props: ["product"],
  data() {
    return {
      headers: [
        {
          text: "TXT_PRODUCT",
          align: "center",
          sortable: true,
          value: "productName",
        },
        {
          text: this.$i18n.t("TXT_SELLER"),
          align: "center",
          sortable: true,
          value: "sellerName",
        },
        {
          text: this.$i18n.t("TXT_PRICE"),
          align: "center",
          sortable: true,
          value: "price",
          width: "120px",
        },
        {
          text: this.$i18n.t("TXT_INSTALLMENTS"),
          align: "center",
          sortable: true,
          value: "quantityInstallment",
        },
        {
          text: `${this.$i18n.t("TXT_VALUE")} ST`,
          align: "center",
          sortable: true,
          value: "price_tax_st",
        },
        {
          text: this.$i18n.t("TXT_DEFLATOR_INDEX"),
          align: "center",
          sortable: true,
          value: "deflatedValue",
        },
        {
          text: this.$i18n.t("TXT_COLECTION"),
          align: "center",
          sortable: true,
          value: "crawlerDate",
        },
      ],
    };
  },

  computed: {
    priceResults() {
      return this.product?.priceResults ?? [];
    },
  },

  methods: {
    formatMonetary,
    copyFunction(text) {
      var textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setTimeout(() => {}, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-product-name {
  max-width: 350px !important;
  padding: 0.8rem !important;
}
.copy-box {
  max-height: 40px !important;
  overflow: hidden !important;
}
</style>