import axiosInstance, { API_URL_UPL, API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "../service-base.js";

export default class UplSSOLogin extends ServiceBase {

    async getPredifyLogin(bearToken) {
        return await axiosInstance.get(API_URL_UPL + `api/OktaSSO/${bearToken}`);
    }

    async getUserRoles(userEmail = null) {
        return await axiosInstance.get(API_URL + 'api/Usuario/GetUsuario', {
            params: {
                email: userEmail
            }
        });
    }
}