<template>
  <div>
    <Prd-btn
      :title="$i18n.t('TXT_EXPORT')"
      :icon="'mdi-download-outline'"
      :outlined="true"
      @click="isModalOpen = true"
    />
    <v-dialog v-model="isModalOpen" v-if="isModalOpen" max-width="1200">
      <v-card class="pa-4">
        <h2 class="mb-4">{{ title }}</h2>

        <p v-t="'TXT_EXTRACT_REPORT_SUBTITLE'" class="ma-0 pa-0"></p>

        <Report-filters
          :selectedAffiliate="selectedAffiliate"
          :type="type"
          @setFiltersLoading="isFiltersLoading = $event"
          @disableDownloadButton="disableDownloadButton = $event"
          ref="reportFilters"
        />

        <v-row no-gutters justify="end">
          <Prd-btn
            :title="$i18n.t('TXT_CANCEL')"
            :outlined="true"
            :disabled="isLoading"
            @click="isModalOpen = false"
            class="mr-4"
          />
          <Prd-btn
            :title="$i18n.t('TXT_DOWNLOAD')"
            :disabled="isFiltersLoading || isLoading || disableDownloadButton"
            :loading="isLoading"
            @click="checkToDownload"
          />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import ReportFilters from "./components/report-filters.vue";
import service from "@/service/home-vem/index.js";
const Service = new service();
export default {
  props: ["type", "selectedAffiliate"],
  components: { PrdBtn, ReportFilters },
  data: () => ({
    isModalOpen: false,
    isFiltersLoading: false,
    isLoading: false,
    disableDownloadButton: false,
  }),

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    title() {
      if (!this.type) return "Extraír relatório";

      return this.type == "impact"
        ? this.$i18n.t("TXT_EXTRACT_IMPACT_REPORT")
        : this.$i18n.t("TXT_EXTRACT_APPROVATION_REPORT");
    },
  },

  methods: {
    checkToDownload() {
      if (this.type == "impact") this.checkImpactDownload();
      else this.downloadApprovationOrRawReport("approvation");
    },

    checkImpactDownload() {
      let selectedFilters = this.$refs.reportFilters.emitFilters();

      const hasPricingSelected = selectedFilters.find(
        (filter) => filter.tag === "name"
      );

      if (hasPricingSelected.selected.length) {
        this.downloadApprovationOrRawReport("raw");
        return;
      } else {
        this.downloadConsolidatedReport(selectedFilters);
      }
    },

    async downloadConsolidatedReport(selectedFilters) {
      this.isLoading = true;
      let filtersReponse = this.$refs.reportFilters.emitFiltersResponse();

      let request = {
        priceGroups: this.filterByPropertys(selectedFilters, filtersReponse),
        affiliates: this.pushAffiliates(selectedFilters),
        companyId: this.idCompany,
      };

      try {
        const response = await Service.extractConsolidatedReport(request);
        this.downloadBlob(response.data, "consolidated");
      } catch (error) {
        console.log(error);
        console.error("Failed to download report:", error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_EXTRACT_REPORT_ERROR"),
          type: "error",
        });
      }
      this.isLoading = false;
    },

    async downloadApprovationOrRawReport(type) {
      this.isLoading = true;

      let reportType =
        type == "raw" ? "extractRawReport" : "extractApprovationReport";

      try {
        let request = this.buildRequest(type);
        const response = await Service[reportType](request);
        this.downloadBlob(response.data, type);
      } catch (error) {
        console.error("Failed to download report:", error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_EXTRACT_REPORT_ERROR"),
          type: "error",
        });
      }

      this.isLoading = false;
    },

    filterByPropertys(filters, rawFilters) {
      const monthFilter = filters.find(
        (filter) => filter.tag === "publishedDateMonth"
      );
      const yearFilter = filters.find(
        (filter) => filter.tag === "publishedDateYear"
      );
      const affiliateFilter = filters.find(
        (filter) => filter.tag === "affiliates"
      );

      const selectedMonths =
        monthFilter.selected.length > 0
          ? monthFilter.selected.map((month) => month.value)
          : monthFilter.items.map((month) => month.value);

      const selectedYears =
        yearFilter.selected.length > 0
          ? yearFilter.selected.map((year) => year.value)
          : yearFilter.items.map((year) => year.value);

      const selectedAffiliates =
        affiliateFilter.selected.length > 0
          ? affiliateFilter.selected.map((affiliate) => affiliate.value)
          : affiliateFilter.items.map((affiliate) => affiliate.value);

      const result = {};

      rawFilters.forEach((item) => {
        const month = item.publishedDateMonth;
        const year = item.publishedDateYear;
        const priceGroupId = item.idEnterprisePriceGroups;
        const itemAffiliates = item.affiliates.map(
          (affiliate) => affiliate.value
        );

        const isMonthSelected = selectedMonths.includes(month);
        const isYearSelected = selectedYears.includes(year);
        const isAffiliateSelected = selectedAffiliates.some(
          (selectedAffiliate) => itemAffiliates.includes(selectedAffiliate)
        );

        if (isMonthSelected && isYearSelected && isAffiliateSelected) {
          const key = `${year}-${month}`;

          if (!result[key]) {
            result[key] = {
              year: parseInt(year),
              month: parseInt(month),
              priceGroupIds: [],
            };
          }

          result[key].priceGroupIds.push(priceGroupId);
        }
      });

      return Object.values(result);
    },

    pushAffiliates(filters) {
      let affiliateFilter = filters.filter((el) => el.tag == "affiliates")[0];
      let affiliateArray = [];

      if (affiliateFilter.selected.length) {
        affiliateFilter.selected.forEach((filter) => {
          affiliateArray.push({
            description: filter.description,
            value: filter.value,
          });
        });
      } else {
        affiliateFilter.items.forEach((filter) => {
          affiliateArray.push({
            description: filter.description,
            value: filter.value,
          });
        });
      }
      return affiliateArray;
    },

    buildRequest(type) {
      const filters = this.$refs.reportFilters.emitFilters();

      const pricingList = filters.find((filter) => filter.tag === "name");

      let gambiarraDoBack = type == "raw" ? "companyId" : "idCompany";
      let gambiarraDoBack2 =
        type == "raw" ? "priceGroupIds" : "idEnterprisePriceGroups";
      const idPricingList = (
        pricingList.selected.length > 0
          ? pricingList.selected
          : pricingList.items
      ).map((pricing) => pricing.idEnterprisePriceGroups);

      return {
        [gambiarraDoBack]: this.idCompany,
        [gambiarraDoBack2]: idPricingList,
      };
    },

    downloadBlob(data, type) {
      let reportName = type + "_report";
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", reportName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style></style>
