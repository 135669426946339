import i18n from "@/i18n";
export function getNameMonth(number) {
  const realIndex = number <= 0 ? 0 : number >= 12 ? 11 : number - 1
  const monthNames = [
    "TXT_JANUARY",
    "TXT_FEBRUARY",
    "TXT_MARCH",
    "TXT_APRIL",
    "TXT_MAY",
    "TXT_JUNE",
    "TXT_JULY",
    "TXT_AUGUST",
    "TXT_SEPTEMBER",
    "TXT_OCTOBER",
    "TXT_NOVEMBER",
    "TXT_DECEMBER"
  ]
  const key = monthNames[realIndex]
  return i18n.t(key)
}