<template>
  <v-card class="pa-4 mt-4" id="currentPrice">
    <v-row>
      <v-col md="3">
        <Prd-data-picker
          v-model="date"
          :hasTooltip="false"
          :externalDisabled="isLoading"
        />
      </v-col>
    </v-row>

    <v-data-table
      v-show="showTable"
      :headers="headers"
      :items="predPlattsList"
      :loading="isLoading"
      :footer-props="footerProps"
      :loading-text="'Carregando dados'"
      :no-data-text="$t('TXT_NO_DATA_FOUND_DISPLAY')"
      :no-results-text="$t('TXT_NO_DATA_FOUND_DISPLAY')"
      :items-per-page="10000000"
      hide-default-footer
      class="mt-4"
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ formatDate(item.ref_date, false) }}
      </template>

      <template v-slot:[`item.predicted_value`]="{ item }">
        {{ formatMonetary(item.predicted_value) }}
      </template>

      <template v-slot:[`item.lower_limit`]="{ item }">
        {{ formatMonetary(item.lower_limit) }}
      </template>

      <template v-slot:[`item.upper_limit`]="{ item }">
        {{ formatMonetary(item.upper_limit) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import acelenService from "@/service/acelen/index";
import PrdDataPicker from "@/components/common/prd-date-picker.vue";
const Service = new acelenService();

export default {
  components: { PrdDataPicker },
  data() {
    return {
      menu: false,
      isLoading: false,
      showTable: false,
      date: null,

      headers: [
        {
          text: "Combustível",
          align: "center",
          value: "product",
        },
        {
          text: "Data",
          align: "center",
          value: "ref_date",
        },
        {
          text: "Indice predito",
          align: "center",
          value: "predicted_value",
        },
        {
          text: "Limite inferior",
          align: "center",
          value: "lower_limit",
        },
        {
          text: "Limit superior",
          align: "center",
          value: "upper_limit",
        },
      ],
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
      predPlattsList: [],
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  watch: {
    date() {
      if (this.date.length == 2) this.getPredPlatts();
    },
  },

  methods: {
    async getPredPlatts() {
      this.isLoading = true;
      this.menu = false;
      this.showTable = true;

      try {
        let response = await Service.getPredPlatts(
          this.date[0],
          this.date[1],
          this.idCompany
        );
        this.predPlattsList = response?.data?.answer ?? [];
      } catch (error) {
        this.$handleError(error, "Erro ao buscar PredPlatts");
      }

      this.isLoading = false;
    },
  },
};
</script>
