<template>
  <div class="d-flex flex-column gap-4">
    <label>
      <slot>{{ title }}</slot>
    </label>
    <v-text-field
      v-model="inputValue"
      v-bind="$attrs"
      dense
      outlined
      hide-details
      @blur="onBlur()"
    >
      <template v-slot:prepend-inner>
        <slot name="prepend-inner"></slot>
      </template>
      <template v-slot:append>
        <slot name="append"></slot>
      </template>
    </v-text-field>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    formatOnBlur: {
      type: Function,
      default: (v) => v,
    },
    value: {},
  },
  data: () => ({
    inputValue: "",
  }),
  methods: {
    onBlur() {
      this.$emit("blur");
      this.inputValue = this.formatOnBlur(this.inputValue);
    },
  },
  mounted() {
    this.inputValue = this.formatOnBlur(this.value);
  },
  watch: {
    inputValue() {
      this.$emit("input", this.inputValue);
    },
    value() {
      this.inputValue = this.value;
    },
  },
};
</script>
<style lang="scss">
.gap-4 {
  column-gap: 16px;
  row-gap: 16px;
}
.v-input {
  font-size: inherit;
}
</style>
