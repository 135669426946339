<template>
  <v-container fluid>
    <v-row class="ma-0 pa-0 mb-2" align="center">
      <TitleBreadCrumbsComponent
        :breadCrumbs="breadCrumbs"
        :title="'PREDIMONITOR'"
        :hasTooltip="false"
      />
    </v-row>

    <Search-Main
      v-if="showSearchV2"
      @startSearchActions="startSearchActions"
      class="mb-6"
    />

    <New-search v-else @startSearchActions="startSearchActions" class="mb-6" />

    <v-row no-gutters justify="space-between">
      <span class="font-weight-bold">{{ $t("TXT_RESULTS_UPPERCASE") }}</span>
      <router-link to="/main/tags-config">
        <Prd-btn :title="$t('TXT_TAG_CONFIGURATION')" :outlined="true" />
      </router-link>
    </v-row>

    <Table-results
      :idCompraAgora="idCompraAgora"
      :resourceGroup="resourceGroup"
      :userList="userList"
      @actualPriceModified="setActualPriceModified"
      @setGeneralIC="setIC"
      ref="table-result"
    />

    <TemporalEvolutionChart
      v-if="!hasResource('predimonitor.table.sellerpercent')"
      :dateCategories="dateCategories"
      class="mb-6"
    />

    <Competitiveness-index
      :ic="ic"
      :dataProducts="allActualPriceModifiedItems"
      :isDisableCompetitivenessIndex="isDisableCompetitivenessIndex"
      :selectedPeriod="selectedPeriod"
    />

    <Product-comparision-main class="mb-6" />

    <Announcement-map-v2 class="mb-6" ref="announcementMapV2" />

    <Price-Br-Map-Chart
      v-if="
        hasResource('predimonitor') &&
        hasResource('predimonitor.map.br') &&
        !hasResource('marketplace.machinery')
      "
      class="mb-6"
    />

    <Price-Usa-Map-Chart
      v-if="hasResource('predimonitor.map.usa')"
      class="mb-6"
    />

    <Loading-products />
  </v-container>
</template>

<script>
import TitleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import TableResults from "./components/table-results/table-results-main.vue";
import TemporalEvolutionChart from "./components/charts/temporal-evolution-chart.vue";

import PriceUsaMapChart from "./components/charts/priceUsa-map-chart.vue";
import PriceBrMapChart from "./components/charts/priceBr-map-chart.vue";

import PredimonitorService from "@/service/predimonitor/predimonitor-service";

import moment from "moment";
import PrdBtn from "@/Design_System/common/prd-btn.vue";

import CompetitivenessIndex from "./components/competitivenes-index/competitiveness-index-main.vue";

import companyService from "@/service/company-service.js";
import AnnouncementMapV2 from "./components/charts/annoouncement-map-v2/announcement-map-v2-main.vue";
import SearchMain from "./components/search/search-main.vue";
import ProductComparisionMain from "./components/product-comparision/product-comparision-main.vue";
import LoadingProducts from "./components/loading-products.vue";
import NewSearch from "./components/new-search/new-search.vue";
const Service = new companyService();

export default {
  components: {
    TemporalEvolutionChart,
    PriceUsaMapChart,
    PriceBrMapChart,
    TableResults,
    PrdBtn,
    TitleBreadCrumbsComponent,
    CompetitivenessIndex,
    AnnouncementMapV2,
    SearchMain,
    ProductComparisionMain,
    LoadingProducts,
    NewSearch,
  },
  data() {
    return {
      predimonitorService: new PredimonitorService(),
      dateCategories: [],
      breadCrumbs: [
        {
          text: "Predimonitor",
        },
      ],

      idCompraAgora: false,

      resourceGroup: null,
      userList: null,

      selectedPeriod: null,

      allActualPriceModifiedItems: [],

      ic: null,
      isDisableCompetitivenessIndex: true,
    };
  },

  computed: {
    resources() {
      return this.$store.state.resources;
    },

    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    currency() {
      return localStorage.getItem("currency") || "R$";
    },

    results() {
      return this.$store.state.PredimonitorStore.results;
    },
    enableSearchV2() {
      return process.env.VUE_APP_ENABLE_SEARCH_V2_PREDIMONITOR
    },

    showSearchV2(){
      return this.enableSearchV2 == "true";
    }
  },

  methods: {
    startSearchActions(request) {
      this.allActualPriceModifiedItems = [];
      this.selectedPeriod = null;
      this.ic = null;
      this.selectedPeriod = {
        startDate: request.startDate,
        endDate: request.endDate,
      };
      this.dateCategories = this.getDateInterval(
        request.startDate,
        request.endDate
      );
      this.$refs.announcementMapV2.getAffiliateMapList(request);
    },

    getDateInterval(dateStart, dateEnd) {
      let startDate = moment(dateStart);
      let endDate = moment(dateEnd);

      let dateArray = [];

      dateArray.push(startDate.format("YYYY-MM-DD"));

      while (startDate.isBefore(endDate)) {
        startDate.add(1, "days");
        dateArray.push(startDate.format("YYYY-MM-DD"));
      }

      return dateArray;
    },

    hasResource(value) {
      return this.$store.getters.hasResource(value);
    },

    verifyCompany() {
      if (
        this.$store.getters.userData.idCompany == 2647 ||
        this.$store.getters.userData.idCompany == 2648
      ) {
        this.idCompraAgora = true;
      }
    },

    getResourceGroup() {
      this.predimonitorService
        .getResourceGroup(this.idCompany)
        .then((res) => {
          if (res.status != 500) {
            this.resourceGroup = res.data.answer;
          } else {
            this.resourceGroup = null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getUsers() {
      this.predimonitorService
        .getUsers(this.idCompany)
        .then((res) => {
          if (res.status != 500) {
            this.userList = res.data;
          } else {
            this.userList = null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setCurrency() {
      Service.getCurrency(this.idCompany)
        .then((res) => {
          if (res.status != 500) {
            let currency = res.data.currencySymbol;

            if (!currency) {
              localStorage.setItem("currency", "R$");
            } else {
              localStorage.setItem("currency", currency);
            }
          }
        })
        .catch((error) => {
          console.log("Erro no buscar currency", error);
          localStorage.setItem("currency", "R$");
        });
    },

    setActualPriceModified(data) {
      this.isDisableCompetitivenessIndex = true;
      const foundedItemIndex = this.allActualPriceModifiedItems.findIndex(
        (element) => element.monitoringId == data.monitoringId
      );
      if (foundedItemIndex == -1) {
        let completeItem = this.results.find(
          (el) => el.idMonitoringItem == data.monitoringId
        );
        data.productName = completeItem.productName;
        data.priceResults = completeItem.priceResults;
        data.avgPrice = completeItem.avgPrice;
        this.allActualPriceModifiedItems.push(data);
      } else {
        if (data.value != "-") {
          this.allActualPriceModifiedItems[foundedItemIndex].actualPrice =
            data.actualPrice;
        } else {
          this.allActualPriceModifiedItems.splice(foundedItemIndex, 1);
        }
      }
      if (
        this.verifyValueIntoArray(
          this.allActualPriceModifiedItems,
          "actualPrice"
        )
      ) {
        this.isDisableCompetitivenessIndex = false;
      }
    },

    verifyValueIntoArray(array, property) {
      let isContains = false;
      array.forEach((element) => {
        if (element[property] > 0) {
          isContains = true;
        }
      });
      return isContains;
    },

    setIC() {
      this.ic = 0;
      let averageCurrentPrice = 0;
      let averageProductsPrice = 0;
      let count = 0;
      this.results.forEach((element) => {
        let currentPrice = this.allActualPriceModifiedItems.find(
          (el) => el.monitoringId == element.idMonitoringItem
        )?.actualPrice;
        if (currentPrice > 0) {
          averageCurrentPrice += Number(currentPrice);
          averageProductsPrice += element.avgPrice;
          count += 1;
        }
      });
      averageCurrentPrice = averageCurrentPrice / count;
      averageProductsPrice = averageProductsPrice / count;
      this.ic = (
        ((averageCurrentPrice - averageProductsPrice) / averageProductsPrice) *
          100 +
        100
      ).toFixed(2);
    },
  },

  created() {
    this.verifyCompany();
    this.getResourceGroup();
    this.getUsers();
    this.setCurrency();
  },
};
</script>

<style scoped lang="scss">
.page-title {
  font-size: 24px;
  font-weight: 600;
}

.page-breadcrumbs {
  font-size: 14px;
  opacity: 0.5;
}

.v-tooltip__content .menuable_content_active {
  border: 1px solid black;
}

.group-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.281);
}
</style> 
