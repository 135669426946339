<template>
  <v-row class="ma-0">
    <v-col
      v-if="!$route.path.includes('sign-out')"
      cols="12"
      sm="12"
      md="7"
      class="login-background hidden-sm-and-down"
    >
      <div
        height="100vh"
        class="d-flex fill-height flex-column justify-space-between"
      >
        <v-row no-gutters justify="center">
          <div class="pa-16 box-slogan">
            <h1 class="slogan white--text">
              {{ $t("TXT_FRASE1") }}
            </h1>
          </div>
        </v-row>
        <div class="d-flex justify-end">
          <div class="d-flex flex-column align-center">
            <span class="white--text">{{ $t("TXT_FRASE2") }}</span>
            <a href="https://neogrid.com/" target="_blank">
              <v-img
                src="@/assets/logo_neogrid_white.png"
                width="192px"
                height="77px"
              />
            </a>
          </div>
        </div>
      </div>
    </v-col>

    <v-col class="pa-0" cols="12" sm="12" md="5" style="background: white">
      <!-- <router-view class="py-15"/> -->
      <div class="login-content">
        <div
          v-if="!$route.path.includes('uplsso')"
          class="d-flex justify-center"
        >
          <img width="245" src="@/assets/logo_predify_blue.png" />
        </div>

        <router-view />
        <v-col
          v-if="!$route.path.includes('sign-out')"
          cols="12"
          class="d-flex justify-center mt-4"
        >
          <privacy-policy-terms-modal />
          <span>&nbsp; | &nbsp;</span>
          <user-terms-modal />
        </v-col>

        <!-- COPYRIGHT -->
        <v-col
          v-if="!$route.path.includes('sign-out')"
          cols="12"
          class="d-flex justify-center"
        >
          <v-row no-gutters justify="center" align="center">
            <v-icon class="mr-1" :color="$prdStyles('color-primary')" size="18">
              mdi-registered-trademark
            </v-icon>
            <span style="color: #3c5ca7">
              {{ copyrightText }}
            </span>
          </v-row>
        </v-col>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import PrivacyPolicyTermsModal from "./modals/privacy-policy-terms-modal.vue";
import userTermsModal from "./modals/user-terms-modal.vue";
export default {
  components: { userTermsModal, PrivacyPolicyTermsModal },
  name: "loginArea",

  computed: {
    copyrightText() {
      return `Predify ${new Date().getFullYear()} - ${this.$i18n.t(
        "TXT_COPYRIGHT"
      )}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-content {
  min-height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.login-background {
  background: url("@/assets/arrows_predify.png") no-repeat;
  background-color: $brand-color-primary-dark;
  background-position-y: bottom;
  background-position-x: left;
  background-size: 70%;
  color: $brand-color-primary-light;

  .box-slogan {
    max-width: 600px;
  }
  .slogan {
    text-align: center;
    font-size: $font-size-md;
    font-family: $font-family-base;
    font-weight: $font-weight-regular;
  }

  span {
    text-align: right;
  }

  .logo {
    width: 245px;
  }
}
</style>