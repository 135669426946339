<template>
  <div class="d-flex justify-end mt-2">
    <Prd-btn
      :outlined="true"
      :loading="isLoading"
      :title="$t('TXT_COPILOT_AVAILABLE_PROMPTS')"
      @click="getAvailablePrompts"
      class="available-link p-0"
    />

    <v-dialog
      v-model="isModalOpen"
      width="50%"
    >
      <v-card class="pa-4">
        <h3 class="mb-6">Prompts</h3>
        <div class="d-flex flex-column ma-4">
          <div
            v-for="group in prompts"
            :key="group.title"
            class="mb-4"
          >
            <h4 class="mb-2">{{ group.title }}</h4>
            <v-expansion-panels>
              <v-expansion-panel
                v-for="section in group.sections"
                :key="section.title"
                class="accordeon-panel"
              >
                <v-expansion-panel-header>
                  {{ section.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ul>
                    <li v-for="prompt in section.prompts" :key="prompt">
                      {{ prompt }}
                    </li>
                  </ul>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
import RuleManagerService from "@/service/rulemanager";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  components: { PrdBtn },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      prompts: []
    }
  },
  methods: {
    async getAvailablePrompts() {
      if (this.prompts.length > 0) {
        this.isModalOpen = true;
        return;
      }

      this.isLoading = true;

      try {
        const response = await new RuleManagerService().getAvailablePrompts();
        this.prompts = response.data;
        this.isModalOpen = true;
      } catch (error) {
        this.$handleError(error, this.$i18n.t("TXT_ERROR"));
        this.isModalOpen = false;
      }
      
      this.isLoading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.available-link::before {
  background-color: transparent !important;
}
.available-link {
  border-width: 0;
  text-decoration: underline;
}
.accordeon-panel {
  color: $neutral-color-low-medium !important;
}
</style>
