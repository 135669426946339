<template>
  <div>
    <Choose-plan-main
      :selectedCompany="selectedCompany"
      @setNewLastPlan="$emit('setNewLastPlan', $event)"
    />
    <Choose-use-days-main :selectedCompany="selectedCompany" />
    <Management-access-main :selectedCompany="selectedCompany" />
    <Management-license-main :selectedCompany="selectedCompany" />
    <Management-users-main :selectedCompany="selectedCompany" />
  </div>
</template>

<script>
import ChoosePlanMain from "./components/choose-plan-main.vue";
import ChooseUseDaysMain from "./components/choose-use-days-main.vue";
import ManagementAccessMain from "./components/management-access-main.vue";
import ManagementLicenseMain from "./components/management-license/management-license-main.vue";
import ManagementUsersMain from "./components/management-users/management-users-main.vue";
export default {
  components: {
    ChoosePlanMain,
    ChooseUseDaysMain,
    ManagementAccessMain,
    ManagementLicenseMain,
    ManagementUsersMain,
  },
  props: {
    selectedCompany: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
};
</script>