<template>
  <div
    @click="showTicketDetails"
    :class="[
      'ticket-card',
      'mt-4',
      setBorderStatus(ticket.status),
      { isDetails: isDetails },
    ]"
  >
    <div class="grow-1">
      <div class="ticket-header">
        <span class="ticket-id">
          {{ $t("TXT_TICKET_NUMBER") }}#{{ ticket.idSupport_Ticket }}
        </span>
        <span
          class="ticket-status"
          :class="setBackgroundStatus(ticket.status)"
          >{{ setStatus(ticket.status) }}</span
        >
      </div>

      <div class="ticket-title">
        <span>{{ ticket.title }}.</span>
      </div>

      <div>
        <span class="font-weight-bold"
          >{{ ticket.idCompany }} - {{ ticket.companyName }}</span
        >
      </div>

      <div class="ticket-details">
        <span
          >{{ $t("TXT_SUBJECT") }}:
          <span v-t="$t(ticket.subject.description)"> </span
        ></span>
        <div>
          <span class="last-update"
            >{{ $t("TXT_LAST_UPDATE") }}:
            {{ formatDate(ticket.last_update, "dd/MM/yyyy") }}</span
          >
        </div>
      </div>
    </div>

    <div v-if="!isDetails">
      <v-icon size="40">mdi-chevron-right</v-icon>
    </div>
  </div>
</template>
<script>
import { formatDate } from "@/utils/prd-format-date.js";
export default {
  props: {
    isDetails: {
      type: Boolean,
      default: false,
    },
    ticket: {
      type: Object,
    },
  },
  data() {
    return {};
  },

  methods: {
    formatDate,
    showTicketDetails() {
      this.$emit("showTicketDetails", this.ticket);
    },

    setStatus(status) {
      const statusText = [
        this.$t("STATUS_OPEN"),
        this.$t("STATUS_WAITING_CLIENT"),
        this.$t("STATUS_WAITING_SUPPORT"),
        this.$t("STATUS_CLOSED"),
      ];
      return statusText[status];
    },

    setBackgroundStatus(status) {
      const statusBackground = [
        "opened",
        "waiting-user",
        "waiting-support",
        "closed",
      ];
      return `border-${statusBackground[status]}`;
    },

    setBorderStatus(status) {
      let border = status == 3 ? "border-error" : "border-success";
      return border;
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 12px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;

  .grow-1 {
    flex-grow: 1;
    width: 95%;

    .ticket-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;

      .ticket-id {
        color: $brand-color-primary-pure;
      }

      .ticket-status {
        border-radius: 5px;
        color: #fff;
        padding: 6px 24px;
      }
    }

    .ticket-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 12px;
    }

    .ticket-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;

      .last-update {
        color: $brand-color-primary-pure;
        margin-left: 14px;
      }
    }
  }
}

.border-error {
  border-left: 8px solid $brand-color-secondary-pure !important;
}

.border-success {
  border-left: 8px solid $feedback-color-success-pure !important;
}

.border-opened {
  background-color: $feedback-color-success-pure;
}

.border-waiting-user {
  background-color: $brand-color-primary-medium;
}

.border-waiting-support {
  background-color: $brand-color-primary-pure;
}

.border-closed {
  background-color: $feedback-color-warning-pure;
}

.isDetails {
  border: 8px solid #f5f5f5 !important;
  background-color: #f5f5f5;
}
</style> 