<template>
  <Section-card
    class="my-4"
    :title="$t('TXT_RELEASE_DAYS_OF_USE')"
    :useHeaderSection="true"
    :disabled="isLoading"
  >
    <template slot="header-left">
      <span>
        {{ this.$i18n.t("TXT_CURRENT_PLAN") }}:
        <span class="font-weight-bold ml-1">{{
          selectedCompany ? selectedCompany.ultimoPlano.nome : null
        }}</span>
      </span>
    </template>

    <template slot="header-right"  v-if="false">
      <span
        >{{ this.$i18n.t("TXT_CURRENT_PLAN_SINCE") }}: 01/01/2024(Verificar se
        realmente tem essa data)</span
      >
    </template>

    <template slot="content">
      <p>
        {{ this.$i18n.t("TXT_CHOOSE_DAYS") }}
      </p>
      <v-row no-gutters align="end" class="gap-6">
        <Prd-text-field
          v-model="daysOfUse"
          :title="$t('TXT_DAYS').replace('d', 'D')"
          :loading="isLoading"
          :clearable="true"
          :type="'number'"
          :min="1"
        />
        <Modal-action
          :titleActivatorButton="$t('TXT_RELEASE')"
          :disabledActivatorButton="isLoading || daysOfUse <= 0"
          :icon="'mdi-alert-outline'"
          :iconColor="'red'"
          :modalTitle="$t('TXT_RELEASE_DAYS_OF_USE')"
          :question="$t('TXT_RELEASE_DAYS_QUESTION')"
          :alertMessage="$t('TXT_RELEASE_DAYS_ALERT_MESSAGE')"
          :buttonsColor="'red'"
          :confirmButtonText="`${this.$i18n.t('TXT_CONFIRM')} ${this.$i18n
            .t('TXT_RELEASE_2')
            .toLowerCase()}`"
          :useActivatorButtonMargin="false"
          @handleConfirmAction="changeDaysOfUse"
        >
          <template slot="content">
            {{ $t("TXT_RELEASED_DAYS") }}:
            <span class="font-weight-bold ml-1">{{ daysOfUse }}</span>
          </template>
        </Modal-action>
      </v-row>
    </template>
  </Section-card>
</template>

<script>
import SectionCard from "../../../common-use-components/section-card.vue";
import GeneralSystemCongisService from "@/service/general-system-configs/index";
import ModalAction from "../../../../../../components/common/modal-action.vue";
import PrdTextField from "@/components/common/prd-text-field.vue";

export default {
  components: { SectionCard, ModalAction, PrdTextField },
  props: {
    selectedCompany: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      daysOfUse: null,
      service: new GeneralSystemCongisService(),
    };
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },
  methods: {
    //     Objeto a ser mandado para requisição
    //     {
    //     "idPlanoInstituicao": "4061",
    //     "dataSolicitacaoPagamento": "2024-01-10T12:56:50.279Z",
    //     "dataSolicitacaoExpiracao": "2024-01-20T12:56:50.279Z",
    //     "valorBruto": 0,
    //     "valorDesconto": 0,
    //     "valorLiquido": 0,
    //     "pagamentoTipo": 0,
    //     "dataPagamentoEfetivacao": "2024-01-10T12:56:50.279Z",
    //     "dataPagamentoExpiracao": "2024-01-20T12:56:50.279Z",
    //     "codigo": 0,
    //     "status": "3",
    //     "geradoPorAdmin": true
    // }
    createBodyToRequest() {
      return {
        idPlanoInstituicao: null,
        dataSolicitacaoPagamento: null,
        dataSolicitacaoExpiracao: null,
        valorBruto: 0,
        valorDesconto: 0,
        valorLiquido: 0,
        pagamentoTipo: 0,
        dataPagamentoEfetivacao: null,
        dataPagamentoExpiracao: null,
        codigo: 0,
        status: "3",
        geradoPorAdmin: true,
      };
    },
    calcFutureDate(days) {
      let currentDate = new Date();
      let futureDate = new Date();
      futureDate.setDate(currentDate.getDate() + days);
      currentDate = currentDate.toISOString();
      futureDate = futureDate.toISOString();
      return {
        currentDate,
        futureDate,
      };
    },
    prepareRequest() {
      let request = this.createBodyToRequest();
      const { currentDate, futureDate } = this.calcFutureDate(this.daysOfUse);
      request.dataSolicitacaoPagamento = currentDate;
      request.dataPagamentoEfetivacao = currentDate;
      request.dataSolicitacaoExpiracao = futureDate;
      request.dataPagamentoExpiracao = futureDate;
      request.idPlanoInstituicao =
        this.selectedCompany.idUltimoPlanoInstituicao;
      return request;
    },
    async changeDaysOfUse() {
      this.isLoading = true;
      let message, type;
      const request = this.prepareRequest();
      try {
        const response = await this.service.changeDaysOfUse(request);
        if (response.status == 200) {
          this.daysOfUse = null;
          message = this.$i18n.t("TXT_INSERTED_DAYS_SUCCESS");
          type = "success";
        } else {
          message = this.$i18n.t("TXT_INSERTED_DAYS_PROBLEM");
          type = "info";
        }
      } catch (error) {
        console.log(error);
        message = this.$i18n.t("TXT_INSERTED_DAYS_ERROR");
        type = "error";
      } finally {
        this.isLoading = false;
        this.$store.commit("snackbarV2/set", { message, type });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.greenText {
  color: $feedback-color-success-pure;
}
</style>