<template>
  <Section-card
    class="my-4"
    :title="$t('TXT_MANAGEMENT_ACCESS')"
    :useHeaderSection="true"
    :disabled="isLoading"
  >
    <template slot="header-left" v-if="companyConfiguration">
      <v-row no-gutters justify="start" style="width: 100%">
        <v-col>
          <v-row no-gutters justify="space-between">
            <v-col cols="auto">
              <span>
                {{ $t("TXT_STATUS_CURRENT_ACCESS") }}:
                <span
                  :class="`font-weight-bold ml-1 ${
                    companyConfiguration.bloquearAcesso ? 'red' : 'green'
                  }Text`"
                  >{{
                    companyConfiguration.bloquearAcesso
                      ? $t("TXT_BLOCKED")
                      : $t("TXT_RELEASED")
                  }}</span
                >
              </span>
            </v-col>
            <v-col cols="auto" v-if="false">
              <span
                >{{ $t("TXT_CURRENT_STATUS_SINCE") }}: 01/01/2024(Verificar se
                realmente tem essa data)</span
              >
            </v-col>
          </v-row>
          <v-row no-gutters v-if="this.companyConfiguration.bloquearAcesso">
            {{ $t("TXT_BLOCK_REASON") }}:
            <span class="ml-1 font-weight-bold">{{
              this.companyConfiguration.motivoBloqueio
            }}</span>
          </v-row>
        </v-col>
      </v-row>
    </template>

    <template slot="content">
      <Prd-text-area
        v-model="motive"
        :title="titleTextArea"
        :loading="isLoading"
        :clearable="true"
        :no-resize="true"
      />
      <v-row no-gutters justify="end" class="mt-4">
        <Modal-action
          :titleActivatorButton="titleActivatorButton"
          :disabledActivatorButton="isLoading || !motive"
          :useActivatorButtonMargin="false"
          :activatorButtonColor="activatorButtonColor"
          :icon="iconModal"
          :iconColor="iconColor"
          :modalTitle="modalTitle"
          :question="question"
          :alertMessage="alertMessage"
          :buttonsColor="buttonsColor"
          :confirmButtonText="confirmButtonText"
          @handleConfirmAction="updateConfigs"
        >
          <template slot="content">
            <div>
              <v-row no-gutters class="mb-4">
                {{ $t("TXT_BUSINESS") }}:
                <span class="font-weight-bold ml-1">{{
                  selectedCompany ? selectedCompany.text : ""
                }}</span>
              </v-row>
              <v-row no-gutters>
                {{ typeMotive }}:
                <span class="font-weight-bold ml-1">{{ motive }}</span>
              </v-row>
            </div>
          </template>
        </Modal-action>
      </v-row>
    </template>
  </Section-card>
</template>

<script>
import SectionCard from "../../../common-use-components/section-card.vue";
import GeneralSystemCongisService from "@/service/general-system-configs/index";
import ModalAction from "../../../../../../components/common/modal-action.vue";
import PrdTextArea from "@/components/common/prd-text-area.vue";

export default {
  components: { SectionCard, ModalAction, PrdTextArea },
  props: {
    selectedCompany: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      motive: null,
      companyConfiguration: null,
      service: new GeneralSystemCongisService(),
    };
  },
  computed: {
    titleTextArea() {
      if (!this.companyConfiguration) return "";
      return this.companyConfiguration.bloquearAcesso
        ? this.$i18n.t("TXT_RELEASE_COMPANY")
        : this.$i18n.t("TXT_BLOCK_COMPANY");
    },
    titleActivatorButton() {
      if (!this.companyConfiguration) return "";
      return this.companyConfiguration.bloquearAcesso
        ? this.$i18n.t("TXT_RELEASE")
        : this.$i18n.t("TXT_BLOCK");
    },
    activatorButtonColor() {
      return this.setColor();
    },
    iconModal() {
      if (!this.companyConfiguration) return "";
      return this.companyConfiguration.bloquearAcesso
        ? "mdi-check-circle-outline"
        : "mdi-alert-outline";
    },
    iconColor() {
      return this.setColor();
    },
    modalTitle() {
      if (!this.companyConfiguration) return "";
      const action = this.companyConfiguration.bloquearAcesso
        ? this.$i18n.t("TXT_RELEASE")
        : this.$i18n.t("TXT_BLOCK");
      return `${action} ${this.$i18n.t("TXT_BUSINESS").toLowerCase()}`;
    },
    question() {
      if (!this.companyConfiguration) return "";
      const color = this.companyConfiguration?.bloquearAcesso
        ? "#1D8527"
        : "#B00020";
      const action = this.companyConfiguration?.bloquearAcesso
        ? this.$i18n.t("TXT_RELEASE").toUpperCase()
        : this.$i18n.t("TXT_BLOCK").toUpperCase();
      return this.$i18n.t("TXT_CHANGE_PLATAFORM_ACESS", { color, action });
    },
    alertMessage() {
      if (!this.companyConfiguration) return "";
      return this.companyConfiguration.bloquearAcesso
        ? this.$i18n.t("TXT_CONSEQUENCE_RELEASED_COMPANY")
        : `<span style='color:#B00020'> ${this.$i18n.t(
            "TXT_CONSEQUENCE_BLOCK_COMPANY"
          )}</span>`;
    },
    buttonsColor() {
      return this.setColor();
    },
    confirmButtonText() {
      if (!this.companyConfiguration) return "";
      return `${this.$i18n.t("TXT_CONFIRM")} ${
        this.companyConfiguration.bloquearAcesso
          ? this.$i18n.t("TXT_RELEASE_2").toLowerCase()
          : this.$i18n.t("TXT_BLOCK_2").toLowerCase()
      }`;
    },
    typeMotive() {
      if (!this.companyConfiguration) return "";
      return this.companyConfiguration.bloquearAcesso
        ? this.$i18n.t("TXT_RELEASE_REASON")
        : this.$i18n.t("TXT_BLOCK_REASON");
    },
  },

  methods: {
    setColor() {
      if (!this.companyConfiguration) return "";
      return this.companyConfiguration.bloquearAcesso ? "green" : "red";
    },
    async getCompanyConfigurations(idEmpresa) {
      this.isLoading = true;
      let message, type;
      try {
        const response = await this.service.getCompanyConfigurations(idEmpresa);
        this.companyConfiguration = response.data;
      } catch (error) {
        console.log(error);
        message = this.$i18n.t("TXT_ERROR_WHEN_GET_DATA_COMPANY");
        type = "error";
      } finally {
        this.isLoading = false;
        this.$store.commit("snackbarV2/set", { message, type });
      }
    },
    createBodyToRequest() {
      return {
        idEmpresa: this.selectedCompany.idEmpresa,
        motivoBloqueio: this.motive,
        bloquearAcesso: !this.companyConfiguration.bloquearAcesso,
      };
    },
    async updateConfigs() {
      this.isLoading = true;
      const request = this.createBodyToRequest();
      let message, type;
      try {
        await this.service.updateCompanyConfigurations(request);
        const key = this.companyConfiguration.bloquearAcesso
          ? "TXT_UNLOCK_SUCCESS"
          : "TXT_BLOCK_SUCCESS";
        message = this.$i18n.t(key);
        type = "success";
        this.companyConfiguration.bloquearAcesso =
          !this.companyConfiguration.bloquearAcesso;
        this.companyConfiguration.motivoBloqueio = this.motive;
        this.motive = null;
      } catch (error) {
        console.log(error);
        const key = this.companyConfiguration.bloquearAcesso
          ? "TXT_UNLOCK_ERROR"
          : "TXT_BLOCK_ERROR";
        message = this.$i18n.t(key);
        type = "error";
      } finally {
        this.isLoading = false;
        this.$store.commit("snackbarV2/set", { message, type });
      }
    },
  },
  watch: {
    selectedCompany: {
      handler(value) {
        if (value) this.getCompanyConfigurations(value.idEmpresa);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.greenText {
  color: $feedback-color-success-pure;
}
.redText {
  color: $feedback-color-warning-pure;
}
</style>