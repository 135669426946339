<template>
  <Section-card
    class="my-4"
    :title="$t('TXT_MANAGEMENT_PLAN')"
    :useHeaderSection="true"
    :disabled="isLoading"
  >
    <template slot="header-left">
      <span>
        {{ $t("TXT_CURRENT_PLAN") }}:
        <span class="font-weight-bold ml-1">{{
          selectedCompany ? selectedCompany.ultimoPlano.nome : null
        }}</span>
      </span>
    </template>

    <template slot="header-right" v-if="false">
      <span
        >{{ $t("TXT_CURRENT_PLAN_SINCE") }}: 01/01/2024(Verificar se realmente
        tem essa data)</span
      >
    </template>

    <template slot="content">
      <p>{{ $t("TXT_CHOOSE_NEW_PLAN") }}</p>
      <v-row no-gutters align="end" class="gap-6">
        <Prd-combo-box
          @input="selectedPlan = $event"
          :title="$t('TXT_PLANS')"
          :placeholder="$t('TXT_SELECT')"
          :items="plansList"
          :loading="isLoading"
          :clearable="true"
        />
        <Modal-action
          :titleActivatorButton="$t('TXT_UPDATE_PLAN')"
          :disabledActivatorButton="isLoading || equalsPlans || !selectedPlan"
          :icon="'mdi-alert-outline'"
          :iconColor="'red'"
          :modalTitle="$t('TXT_UPDATE_PLAN')"
          :question="$t('TXT_MODAL_CHOOSE_NEW_PLAN_QUESTION')"
          :alertMessage="$t('TXT_MODAL_CHOOSE_NEW_PLAN_ALERT_MESSAGE')"
          :buttonsColor="'red'"
          :confirmButtonText="$t('TXT_CONFIRM_CHANGE')"
          @handleConfirmAction="changeNewPlan"
        >
          <template slot="content">
            <div style="width: 100%">
              <span>
                {{ $t("TXT_CURRENT_PLAN") }}:
                <span class="font-weight-bold ml-1">{{
                  selectedCompany ? selectedCompany.ultimoPlano.nome : null
                }}</span>
              </span>
              <v-divider class="my-4" />
              <span>
                {{ $t("TXT_NEW_PLAN") }}
                <span class="font-weight-bold ml-1 greenText">{{
                  selectedPlan ? selectedPlan.name : null
                }}</span>
              </span>
            </div>
          </template>
        </Modal-action>
      </v-row>
    </template>
  </Section-card>
</template>

<script>
import SectionCard from "../../../common-use-components/section-card.vue";
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import GeneralSystemCongisService from "@/service/general-system-configs/index";
import ModalAction from "../../../../../../components/common/modal-action.vue";

export default {
  components: { SectionCard, PrdComboBox, ModalAction },
  props: {
    selectedCompany: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      plansList: [],
      selectedPlan: null,
      service: new GeneralSystemCongisService(),
    };
  },
  computed: {
    equalsPlans() {
      return (
        this.selectedCompany?.ultimoPlano?.idPlanoItem ==
          this.selectedPlan?.idPlanItem ||
        this.selectedCompany?.ultimoPlano?.idPlanoItem ==
          this.selectedPlan?.idPlanoItem
      );
    },
  },
  methods: {
    async getPlansList() {
      this.isLoading = true;
      try {
        const response = await this.service.getPlansList();
        const mappedResponse = response.data.answer.map((plan) => {
          return {
            ...plan,
            text: `${plan.partnerName ? plan.partnerName : "Predify"} - ${
              plan.name
            }`,
          };
        });
        this.plansList = mappedResponse;
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_GET_PLAN_LIST_ERROR"),
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
    async changeNewPlan() {
      this.isLoading = true;
      let message, type;
      try {
        const response = await this.service.changeNewPlan(
          this.selectedCompany.idEmpresa,
          this.selectedPlan.idPlanItem
        );
        if (response.status == 200) {
          this.selectedPlan = null;
          message = this.$i18n.t("TXT_UPDATED_PLAN_SUCCESS");
          type = "success";
          this.$emit("setNewLastPlan", response.data.ultimoPlano);
        } else {
          message = this.$i18n.t("TXT_UPDATED_PLAN_PROBLEM");
          type = "info";
        }
      } catch (error) {
        console.log(error);
        message = this.$i18n.t("TXT_UPDATED_PLAN_ERROR");
        type = "error";
      } finally {
        this.isLoading = false;
        this.$store.commit("snackbarV2/set", { message, type });
      }
    },
  },
  watch: {
    selectedCompany: {
      handler(value) {
        this.selectedPlan = value?.ultimoPlano;
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    await this.getPlansList();
  },
};
</script>

<style lang="scss" scoped>
.greenText {
  color: $feedback-color-success-pure;
}
</style>