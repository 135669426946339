import { render, staticRenderFns } from "./expandable-table.vue?vue&type=template&id=0ea907d3&scoped=true"
import script from "./expandable-table.vue?vue&type=script&lang=js"
export * from "./expandable-table.vue?vue&type=script&lang=js"
import style0 from "./expandable-table.vue?vue&type=style&index=0&id=0ea907d3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ea907d3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VDataTable,VDivider})
