<template>
  <div
    class="grid text-center gap-1"
    :style="{
      'grid-template-columns': `repeat(${headers.length}, 1fr)`,
    }"
  >

    <div
      class="table-header"
      v-for="(headerData, index) in headers"
      :key="'H' + index"
      @click="sortBy(index)"
      :ref="`headers`"
    >
      {{ headerData }}
      <v-icon size="25">
        {{
          sortColumn === index
            ? sortDirection === "up"
              ? "mdi-menu-up"
              : "mdi-menu-down"
            : "mdi-menu-right"
        }}
      </v-icon>
    </div>
    <slot :items="tableData()">X</slot>
  </div>
</template>
<script>
let functionRefHold;

export default {
  props: ["headers", "rows", "stickyHeight"],
  data: () => ({
    sortColumn: null,
    sortDirection: "down",
    isStickyOn: false,
  }),
  watch: {
    headers() {
      this.bindStickyEventListeners(true);
    },
  },
  methods: {
    sortBy(columnIndex) {
      if (this.sortColumn === null || this.sortColumn !== columnIndex) {
        this.sortColumn = columnIndex;
        this.sortDirection = "down";
      } else {
        this.sortDirection = this.sortDirection === "down" ? "up" : "down";
      }
    },
    bindStickyEventListeners(shouldBind = true) {
      if (functionRefHold) {
        document.removeEventListener("scroll", functionRefHold);
      }

      if (!shouldBind) return;
      if ((this.stickyHeight ?? null) === null) return;
      if (this.$refs.headers.length === 0) return;
      functionRefHold = this.makeStickyHeaders.bind(this);
      document.addEventListener("scroll", functionRefHold);
    },

    makeStickyHeaders() {
      const { top } =
        this.$refs.headers[0].parentElement.getBoundingClientRect();
      if (top > this.stickyHeight) {
        if (!this.isStickyOn) return;
        this.$refs.headers.forEach((headerElem) => {
          headerElem.style.display = "static";
          headerElem.style.top = "0px";
        });
        this.isStickyOn = false;
      } else {
        this.isStickyOn = true;
        this.$refs.headers.forEach((headerElem) => {
          headerElem.style.position = "relative";
          headerElem.style.top = `${this.stickyHeight - top}px`;
        });
      }
    },
    tableData() {
      if (this.sortColumn === null) return this.rows.flat();

      return this.rows
        .map((row) => row)
        .sort((rowA, rowB) => {
          const valA = rowA[this.sortColumn];
          const valB = rowB[this.sortColumn];
          if (typeof valA === "number" || typeof valA === "boolean")
            return this.sortDirection === "down" ? valA - valB : valB - valA;
          if (typeof valA === "string")
            return this.sortDirection === "down"
              ? valA.localeCompare(valB)
              : valB.localeCompare(valA);
        })
        .flat();
    },
  },
  mounted() {
    this.bindStickyEventListeners(true);
  },
  beforeDestroy() {
    this.bindStickyEventListeners(false);
  },
};
</script>
<style lang="scss" scoped>
.grid {
  display: grid;
  width: 100%;
  overflow-x: auto;
}
.table-header {
  background-color: $neutral-color-high-dark;
  padding: 12px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .4s;
}
.table-header:hover{
  opacity: .9;
}
.table-item {
  background-color: $neutral-color-high-light;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gap-1 {
  gap: 4px;
}
.gap-x-1 {
  column-gap: 4px;
}
</style>
