<template>
  <v-container fluid>
    <v-row no-gutters align="center">
      <titleBreadCrumbsComponent
        :title="title"
        :tooltipText="'Tooltip'"
      />
      <v-spacer />

      <crawler-rotine />

      <prd-btn
        class="mt-10"
        :title="$t('TXT_CONFIGURATION_CRAWLER')"
        :icon="'mdi-robot'"
        :leftIcon="true"
        @click="callAnotherPage"
      />
    </v-row>

    <div class="my-flex">
      <!-- COMPANY LIST -->
      <company-list
        style="width: 50%"
        @invertCard="invertCard = $event"
        @dataCrawlersLinked="dataCrawlersLinked = $event"
        :isDisableFirstCard="invertCard"
      />

      <!-- COMPANY CRAWLERS -->
      <company-crawlers
        style="width: 50%"
        @invertCard="invertCard = $event"
        :dataCrawlersLinked="dataCrawlersLinked"
        @dataCrawlersLinked="dataCrawlersLinked = $event"
        :isDisableSecondCard="!invertCard"
      />
    </div>
  </v-container>
</template>

<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import CompanyList from "./components/management-company-list.vue";
import CompanyCrawlers from "./components/management-company-crawlers.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import router from "@/router/index.js";
import CrawlerRotine from "./components/crawler-rotine.vue";

export default {
  name: "crawler-main",
  components: {
    titleBreadCrumbsComponent,
    CompanyList,
    CompanyCrawlers,
    PrdBtn,
    CrawlerRotine,
  },
  data() {
    return {
      invertCard: false,
      title: this.$i18n.t("TXT_MANAGEMENT_CRAWLERS_UPPERCASE"),
      
      dataCrawlersLinked: null,
    };
  },

  methods: {
    callAnotherPage() {
      router.push("/main/crawler-config");
    },
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  gap: 24px;
}
</style>