<template>
  <div class="mt-2">
    <div v-for="(chat, index) in chats" :key="index" class="mt-2">
      <Copilot-text-box
        v-for="(message, index) in chat.messages"
        :key="index"
        :author="chat.author"
        :text="message.message"
      />
    </div>
 
    <Copilot-message-actions v-if="showConfirmationRules" />

    <Copilot-loading-chat :isLoading="isLoading" />
  </div>
</template>

<script>
import CopilotLoadingChat from "./copilot-loading-chat.vue";
import CopilotMessageActions from "./copilot-message-actions.vue";
import CopilotTextBox from "./copilot-text-box.vue";

export default {
  components: { CopilotLoadingChat, CopilotTextBox, CopilotMessageActions },

  computed: {
    chats() {
      return this.$store?.state?.copilotStore?.chats ?? [];
    },

    showConfirmationRules() {
      return this.$store?.getters?.showConfirmationRules ?? [];
    },

    isLoading() {
      return this.$store?.state?.copilotStore?.copilotChatLoading ?? false;
    },
  },

  watch: {
    chats() {
      this.$emit("messagesUpdated");
    },
  },
};
</script>

<style lang="scss" scoped>
.text-box {
  border: 0.5px solid black;
  border-radius: 5px;

  background-color: #fff;
  padding: 4px 12px;
}
</style>