<template>
  <Standard-user-store-card :isSelected="isSelected">
    <template v-slot:content>
      <h3>{{ description }}</h3>
    </template>
  </Standard-user-store-card>
</template>

<script>
import StandardUserStoreCard from "../common/standard-user-store-card.vue";
export default {
  props: {
    storeData: {
      type: Object,
      require: true,
      default: () => ({
        id: null,
        description: null,
        isSelected: false,
      }),
    },
  },
  components: { StandardUserStoreCard },
  computed: {
    description() {
      return this.storeData?.description ?? "-";
    },
    isSelected() {
      return this.storeData.isSelected;
    },
  },
};
</script>