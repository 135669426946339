<template>
  <Prd-btn
    :title="$t('TXT_EXPORT')"
    :icon="'mdi-download-outline'"
    :smallIcon="true"
    :loading="isLoading"
    @click="exportFile"
  />
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import StoreManagementService from "@/service/company-config/store-management";
import { downloadXlsx } from "@/utils/downloadData";
export default {
  components: {
    PrdBtn,
  },
  data() {
    return {
      isLoading: false,
      service: new StoreManagementService(),
    };
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    async exportFile() {
      try {
        this.isLoading = true;
        const response = await this.service.exportFile(this.idCompany);
        downloadXlsx(response.data, this.$i18n.t("TXT_USER_ASSOCIATION"));
      } catch (error) {
        this.$handleError(
          error,
          "Erro ao exportar arquivo de associação de usários"
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
