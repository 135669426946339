<template>
  <v-container fluid>
    <PageTitle :title="'PRICER'" :hasTooltip="false" />

    <Search-main />

    <Product-list-main />
  </v-container>
</template>

<script>
import PageTitle from "@/Design_System/common/title.vue";
import SearchMain from "./components/search-area/search-main.vue";
import ProductListMain from "./components/product-list/product-list-main.vue";

export default {
  name: "pricer-main",
  components: {
    PageTitle,
    SearchMain,
    ProductListMain,
  },
  data() {
    return {};
  },

  created() {
    this.$store.commit("setFirstSearch", true);
    this.$store.commit("saveProductsIA", []);
  },
};
</script>