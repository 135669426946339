<template>
  <v-row no-gutters justify="end" align="start">
    <v-col>
      <highcharts class="hc" :options="chartOptions" />
    </v-col>
    <v-col cols="auto">
      <v-icon
        style="margin-top: 9.5px"
        color="#292929"
        @click="$emit('closeModal')"
        >mdi-close</v-icon
      >
    </v-col>
  </v-row>
</template>

<script>
import Highcharts from "highcharts";

// Import the "xrange" module
import xrangeInit from "highcharts/modules/xrange";
xrangeInit(Highcharts);

// Export data in PNG, JPEG, PDF, SVG, print, and fullscreen
import exportingInit from "highcharts/modules/exporting";
exportingInit(Highcharts);
// import ServiceUPL from "@/service/upl/index";
// Export data as CSV, XLS, and change visualization per table
import exportData from "highcharts/modules/export-data";
exportData(Highcharts);
import i18n from "@/i18n";
export default {
  props: {
    categories: {
      type: Array,
    },
    chartData: {
      type: Array,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "xrange",
        },
        title: {
          text: `<b>${this.$i18n.t("TXT_VARIABLE_WEIGHTS").toUpperCase()}</b>`,
          align: "left",
        },
        subtitle: {
          text: "",
          align: "left",
        },
        lang: {
          viewFullscreen: this.$i18n.t("TXT_VISUALIZE_FULLSCREEN"),
          printChart: this.$i18n.t("TXT_PRINT_CHART"),
          downloadCSV: this.$i18n.t("TXT_DOWNLOAD_CSV_FILE"),
          downloadJPEG: this.$i18n.t("TXT_DOWNLOAD_JPEG_IMAGE"),
          downloadPDF: this.$i18n.t("TXT_DOWNLOAD_PDF"),
          downloadPNG: this.$i18n.t("TXT_DOWNLOAD_PNG_IMAGE"),
          downloadSVG: this.$i18n.t("TXT_DOWNLOAD_SVG_IMAGE"),
          downloadXLS: this.$i18n.t("TXT_DOWNLOAD_XLS_FILE"),
          exitFullscreen: this.$i18n.t("TXT_LEAVE_FULLSCREEN"),
          viewData: this.$i18n.t("TXT_DISPLAY_DATA_IN_TABLE"),
          noData: this.$i18n.t("TXT_NO_DATA_AVAILABLE"),
        },
        xAxis: {
          type: "int",
        },
        yAxis: {
          title: {
            text: "",
          },
          categories: [],
          reversed: true,
        },
        tooltip: {
          formatter: function () {
            return (
              '<span style="color:' +
              this.point.color +
              '">● </span><b>' +
              this.point.yCategory +
              `</b><br>${i18n.t("TXT_WEIGHT")}: <b>` +
              this.x.toFixed(2).replace(".", ",") +
              "</b>"
            );
          },
        },
        series: [
          {
            name: this.$i18n.t("TXT_WEIGHTS"),
            pointWidth: 20,
            data: [],
            dataLabels: {
              enabled: true,
            },
          },
        ],
      },
    };
  },
  methods: {
    setColors() {
      const positiveValues = this.chartOptions.series[0].data.filter(
        (variable) => variable.x > 0
      );
      const newPositiveColors = this.generateColorsWithOpacity(
        "#3c5ca7",
        positiveValues.length
      );
      const negativeValues = this.chartOptions.series[0].data.filter(
        (variable) => variable.x < 0
      );
      const newNegativeColors = this.generateColorsWithOpacity(
        "#EC4C37",

        negativeValues.length
      );
      this.chartOptions.series[0].data.forEach((data) => {
        if (data.x > 0) {
          data.color = newPositiveColors[0];
          newPositiveColors.shift();
        } else {
          data.color = newNegativeColors[0];
          newNegativeColors.shift();
        }
      });
    },
    generateColorsWithOpacity(color, amount) {
      let r = parseInt(color.slice(1, 3), 16);
      let g = parseInt(color.slice(3, 5), 16);
      let b = parseInt(color.slice(5, 7), 16);

      const newColors = [];

      for (let i = amount; i >= 1; i--) {
        let opacity = i / amount;
        let newColor = `rgba(${r}, ${g}, ${b}, ${opacity.toFixed(2)})`;
        newColors.push(newColor);
      }

      return newColors;
    },
  },
  watch: {
    categories: {
      handler(value) {
        this.chartOptions.yAxis.categories = value;
      },
      immediate: true,
      deep: true,
    },
    chartData: {
      handler(value) {
        this.chartOptions.series[0].data = value;
        this.setColors();
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>