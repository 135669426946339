<template>
  <div class="pa-4">
    <prd-loading-circular v-if="isLoading" class="my-6" />

    <v-row v-if="!isLoading && items.length == 0" no-gutters justify="center">
      <span class="font-weight-bold">{{ $t("TXT_NOT_FOUND_RESULT") }}</span>
    </v-row>

    <v-row
      v-if="!isLoading && items.length > 0"
      no-gutters
      justify="space-between"
      align="center"
    >
      <Prd-btn
        :title="$t('TXT_UPDATE')"
        :leftIcon="true"
        :icon="'mdi-refresh'"
        @click="getHistory"
        class="mt-6"
      />

      <Prd-combo-box
        v-model="itemsPerPage"
        :items="pageItems"
        :title="$t('TXT_ITEMS_PER_PAGE')"
        v-if="true"
      />
    </v-row>

    <v-expansion-panels
      v-if="!isLoading && items.length > 0"
      class="mt-4"
      accordion
    >
      <Product-Panel v-for="(item, index) in items" :key="index" :item="item" />
    </v-expansion-panels>

    <Prd-pagination
      v-model="currentPage"
      v-show="items.length > 0"
      :totalRegisters="totalRecords"
      :length="totalPages"
      :disable="true"
      :showingRegistersLen="numberOfRecords"
      :resultLabel="$t('TXT_PAGINATION')"
      :pageLabel="$t('TXT_PAGE_LABEL')"
      class="mt-4"
    />
  </div>
</template>

<script>
import PrdPagination from "@/Design_System/common/prd-pagination.vue";
import service from "@/service/crawlers/crawler-execute.js";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import ProductPanel from "@/views/main/crawlers/crawler-execute/components/product-panel.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";

const Service = new service();
export default {
  props: ["idCompany", "selectedTab"],
  components: {
    PrdPagination,
    PrdLoadingCircular,
    PrdComboBox,
    ProductPanel,
    PrdBtn,
  },
  data() {
    return {
      currentPage: 1,
      totalRecords: null,
      totalPages: null,
      numberOfRecords: null,
      crawlerLoading: true,
      isLoading: false,
      interval: null,
      itemsPerPage: 10,
      pageItems: [5, 10, 25],

      items: [],
    };
  },

  watch: {
    currentPage() {
      this.getHistory();
    },
    itemsPerPage() {
      this.getHistory();
    },
    selectedTab: {
      handler(value) {
        if (value == 1) {
          this.getHistory();
        } else {
          clearInterval(this.interval);
        }
      },
      immediate: true,
    },
  },

  methods: {
    async getHistory() {
      this.isLoading = true;

      try {
        let response = await Service.getHistory(
          this.idCompany,
          this.currentPage,
          this.itemsPerPage
        );
        let data = response?.data?.result ?? [];

        data.records.forEach((el) => {
          el.loading = false;
        });

        this.currentPage = data.currentPage;
        this.numberOfRecords = data.numberOfRecords;
        this.totalRecords = data.totalRecords;
        this.totalPages = data.totalPages;
        this.items = data.records;
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_SEARCH_HISTORY"),
          type: "error",
        });
      }

      this.isLoading = false;
    },
  },
};
</script>