<template>
  <v-row
    no-gutters
    class="card pa-4 mb-2"
    :class="`${
      product.lastPrice == product.fixedPrice ? 'equals' : 'diferents'
    }`"
    justify="space-between"
  >
    <v-col cols="auto">
      <h4>{{ product.description }}</h4>
    </v-col>
    <v-col cols="auto">
      <v-row no-gutters class="gap-4">
        <v-col cols="auto">
          <v-row no-gutters>
            <span class="font-weight-bold"
              >{{ $t("TXT_FIXED_PRICE") }}:
              {{ formatMonetary(product.fixedPrice) }}</span
            >
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-row no-gutters>
            <span class="font-weight-bold">
              <span class="mr-1">{{ $t("TXT_PRATICED_PRICE") }}:</span>
              <span
                :class="`${
                  product.lastPrice == product.fixedPrice
                    ? 'equals-text'
                    : 'diferents-text'
                }`"
                >{{ formatMonetary(product.lastPrice) }}</span
              >
            </span>
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-row no-gutters>
            <span class="font-weight-bold"
              >{{ $t("TXT_LAST_SALE") }}: {{ setDate(product.lastSale) }}</span
            >
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary.js";
export default {
  props: ["product"],
  methods: {
    formatMonetary,
    setDate(dateString) {
      if (!dateString) return "-";
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString().padStart(4, "0");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: $neutral-color-high-medium;
  border-radius: 10px;
  border-left: 10px solid;
}

.diferents {
  border-left-color: $feedback-color-warning-pure;
}

.equals {
  border-left-color: $brand-color-primary-pure;
}

.diferents-text {
  color: $feedback-color-warning-pure;
}

.equals-text {
  color: $brand-color-primary-pure;
}
</style>