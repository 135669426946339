<template>
  <v-card class="pa-4" :disabled="isDisabled">
    <h3>{{ $t("TXT_COMPETITORS_2").toUpperCase() }}</h3>
    <Product-inputs
      v-for="(cap, index) in captures"
      :key="index"
      :isUPL="isUPL"
      :index="index + 1"
      :cap="cap"
      :productsFiltered="productsFiltered"
      :productsUPL="productsUPL"
      :disabledDeleteCap="
        productForEdition &&
        productForEdition.tipoPrecificacao &&
        productForEdition.tipoPrecificacao != 'Mistura Externa' &&
        productForEdition.tipoPrecificacao != 'Custo'
      "
      @deleteCapture="deleteCapture(index)"
      @setIntExtCapData="setIntExtCapData(index, $event)"
      @setUplCapData="setUplCapData(index, $event)"
    />
    <p class="red--text" v-if="alertText ? true : false">{{ alertText }}</p>
    <Add-especification
      v-if="
        productForEdition &&
        productForEdition.tipoPrecificacao &&
        (productForEdition.tipoPrecificacao == 'Mistura Externa' ||
          productForEdition.tipoPrecificacao == 'Custo')
      "
      :disabled="disableAddEspecification"
      @addCapture="addCapture"
      class="mt-4"
    />
  </v-card>
</template>

<script>
import AddEspecification from "./components/add-especification.vue";
import ProductInputs from "./components/products-inputs/product-inputs-main.vue";
export default {
  components: { AddEspecification, ProductInputs },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    precificationType: {
      type: String,
      default: null,
    },
    externalProducts: {
      type: Array,
    },
    internalProducts: {
      type: Array,
    },
    productsUPL: {
      type: Array,
    },
    productForEdition: {
      type: Object,
    },
  },
  data() {
    return {
      captures: [],
      productsFiltered: [],
    };
  },

  computed: {
    disableAddEspecification() {
      return (
        !this.precificationType &&
        (!this.precificationType.includes("Mistura") ||
          !this.precificationType == "Custo")
      );
    },
    isUPL() {
      return this.precificationType == "Mistura Interna";
    },
    currency() {
      return localStorage.getItem("currency") || "R$";
    },
    alertText() {
      let text;
      if (
        this.productForEdition &&
        this.productForEdition.tipoPrecificacao &&
        this.productForEdition.tipoPrecificacao == "Mistura Externa"
      ) {
        if (this.productForEdition.capturas.length == 0)
          text = this.$i18n.t("TXT_ALERT_PRECIFICATION_1");
        else if (this.productForEdition.capturas.length == 1)
          text = this.$i18n.t("TXT_ALERT_PRECIFICATION_2");
      }

      return text;
    },
  },

  methods: {
    getNewCapture() {
      return {
        nomeCaptura: null,
        mediaCapturaExterna: 0,
        pesoCapturaExterna: 0,
        mediaCapturaInterna: 0,
        pesoCapturaInterna: 0,
        idProdutoUPL: null,
        doseProdutoUPL: 0,
        doseCompetidor: 0,
        precoProdutoUPL: 0,
      };
    },
    addCapture() {
      const emptyCapture = this.getNewCapture();
      this.captures.push(emptyCapture);
    },
    deleteCapture(index) {
      this.captures.splice(index, 1);
    },
    bodyToProductsFiltered() {
      return {
        text: null,
        precoExt: 0,
        doseExt: 0,
        pesoExt: 0,
        precoInt: 0,
        doseInt: 0,
        pesoInt: 0,
      };
    },
    setInternalExternalProducts() {
      this.productsFiltered = [];

      const names = this.prepareNames();

      const getProduct = (key, array, currentName) => {
        return array.find(
          (product) =>
            product[key].toString().toLowerCase() ==
            currentName.toString().toLowerCase()
        );
      };

      this.productsFiltered = Array.from(names)
        .map((name) => {
          const foundedInternal = getProduct(
            "descricaoMQ",
            this.internalProducts,
            name
          );
          const foundedExternal = getProduct(
            "product",
            this.externalProducts,
            name
          );

          const newItem = this.bodyToProductsFiltered();

          newItem.text = name;
          newItem.precoExt =
            this.currency == "R$"
              ? foundedExternal?.medianaBRL ?? 0
              : foundedExternal?.medianaUSD ?? 0;
          newItem.doseExt = foundedExternal?.doseha ?? 0;
          newItem.pesoExt = 0;
          newItem.precoInt =
            this.currency == "R$"
              ? foundedInternal?.mediaPrecoPVBRL ?? 0
              : foundedInternal?.mediaPrecoPVUSD ?? 0;
          newItem.doseInt = foundedExternal?.doseha ?? 0;
          newItem.pesoInt = 0;
          
          return newItem;
        })
        .filter((item) => item);
    },

    prepareNames() {
      const internalNames = this.internalProducts.map((int) => int.descricaoMQ);
      const externalNames = this.externalProducts.map((ext) => ext.product);
      const allNames = [...internalNames, ...externalNames];
      const filteredNames = new Set(allNames);
      return filteredNames;
    },

    setIntExtCapData(index, data) {
      this.captures[index].nomeCaptura =
        data.text ?? this.captures[index].nomeCaptura;
      this.captures[index].doseCompetidor = data.doseExt ?? data.doseInt;
      this.captures[index].mediaCapturaExterna = data.precoExt;
      this.captures[index].pesoCapturaExterna = data.pesoExt;
      this.captures[index].mediaCapturaInterna = data.precoInt;
      this.captures[index].pesoCapturaInterna = data.pesoInt;
    },

    setUplCapData(index, data) {
      this.captures[index].idProdutoUPL = data.id;
      this.captures[index].doseProdutoUPL = data.dose;
      this.captures[index].precoProdutoUPL = data.preco;
    },
    updateProductSelected(productName) {
      const foundedProduct = this.productsFiltered.find(
        (item) => (item.text = productName)
      );
      foundedProduct.itsSelected = !foundedProduct.itsSelected;
    },
  },
  watch: {
    precificationType: {
      handler(value) {
        this.captures = [];
        if (
          value == "Value Price" ||
          value == "Down Price" ||
          value == "Preço de competição" ||
          value == "Oportunidade de Mercado" ||
          value == "Produto Líder" ||
          value == "Mistura Interna"
        ) {
          this.addCapture();
        } else if (value == "Mistura Externa") {
          this.addCapture();
          this.addCapture();
        }
      },
      immediate: true,
    },
    captures: {
      handler(value) {
        this.$emit("setCaptures", value);
      },
    },
    internalProducts() {
      this.setInternalExternalProducts();
    },
    productForEdition: {
      handler(value) {
        if (value) this.captures = value.capturas;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
</style>