<template>
  <div>
    <v-card class="pa-4 mb-6">
      <v-expansion-panels
        :disabled="isDisabled || ic == null"
        :v-model="panel"
        accordion
        flat
        multiple
        class="pa-0"
      >
        <v-expansion-panel class="pa-0">
          <v-expansion-panel-header
            expand-icon="mdi-chevron-down"
            class="ma-0 pa-0"
          >
            <v-col class="pa-0 ma-0">
              <label class="font-weight-bold font-size-16">{{ title }}</label>
              <v-row
                v-if="isDisabled || ic == null"
                no-gutters
                align="center"
                justify="center"
                class="redBorder"
              >
                <span> {{ $t("TXT_DISCLAIMER_IC_PREDIMONITOR") }}</span>
              </v-row>
            </v-col>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="mt-4" no-gutters justify="center">
              <v-col class="mr-4" cols="3">
                <competitiveness-average-card
                  v-if="!isDisabled && ic != null"
                  :ic="localIC"
                  :disabled="isDisabled || ic == null"
                />
              </v-col>
              <v-col>
                <competitiveness-history-card
                  :disabled="isDisabled || ic == null"
                  v-if="!isDisabled && ic != null"
                  :selectedPeriod="selectedPeriod"
                  :products="dataProducts"
                  @selectedProductIC="getIC"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>
  

<script>
import competitivenessAverageCard from "./components/competitiveness-average/card/competitiveness-average-card.vue";
import CompetitivenessHistoryCard from "./components/competitiveness-history/competitiveness-history-card.vue";
export default {
  components: { competitivenessAverageCard, CompetitivenessHistoryCard },

  props: [
    "ic",
    "isDisableCompetitivenessIndex",
    "dataProducts",
    "selectedPeriod",
  ],
  data() {
    return {
      panel: [],
      localIC: null,
    };
  },
  computed: {
    title() {
      return this.$i18n.t("TXT_COMPETITIVENESS_INDEX").toUpperCase();
    },
    isDisabled() {
      return (
        this.$store.getters.getIsDisabled || this.isDisableCompetitivenessIndex
      );
    },
    isLoading() {
      return this.$store.state.PredimonitorStore.isLoading;
    },
  },
  watch: {
    isLoading: {
      handler(value) {
        if (value) {
          this.panel = [];
        }
      },
    },
    isDisabled: {
      handler(value) {
        if (value) {
          this.panel = [];
        }
      },
    },
    ic: {
      handler(value) {
        this.localIC = value;
      },
    },
  },
  methods: {
    getIC(data) {
      if (data != 0 && data) {
        this.localIC = data;
      } else {
        this.localIC = this.ic;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.redBorder {
  border: 2px solid $feedback-color-warning-pure;
  border-radius: 4px;
  padding: 16px;
  margin-top: 24px;
  color: black !important;
}
</style>