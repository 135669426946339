<template>
  <div>
    <v-row no-gutters align="center">
      <h5 class="my-4">{{ $t("TXT_SELECT_WEEK_DAYS") }}</h5>
      <Prd-tooltip :text="$t('TXT_LIMIT_WEEK_RULES_TOOLTIP')" :sizeIcon="20" />
    </v-row>
    <div class="flex-center">
      <v-checkbox
        v-for="(day, index) in days"
        v-model="day.selected"
        :key="index"
        :label="day.text"
        dense
        hide-details
        class="ma-0 pa-0 mr-4"
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
import PrdTooltip from "@/components/common/prd-tooltip.vue";

export default {
  props: ["index", "values", "idRule"],
  components: { PrdTooltip },
  data() {
    return {
      days: [
        {
          text: this.$i18n.t("TXT_WEEKDAY_SUNDAY"),
          value: "Domingo",
          selected: false,
        },
        {
          text: this.$i18n.t("TXT_WEEKDAY_MONDAY"),
          value: "Segunda-Feira",
          selected: false,
        },
        {
          text: this.$i18n.t("TXT_WEEKDAY_TUESDAY"),
          value: "Terça-Feira",
          selected: false,
        },
        {
          text: this.$i18n.t("TXT_WEEKDAY_WEDNESDAY"),
          value: "Quarta-Feira",
          selected: false,
        },
        {
          text: this.$i18n.t("TXT_WEEKDAY_THURSDAY"),
          value: "Quinta-Feira",
          selected: false,
        },
        {
          text: this.$i18n.t("TXT_WEEKDAY_FRIDAY"),
          value: "Sexta-Feira",
          selected: false,
        },
        {
          text: this.$i18n.t("TXT_WEEKDAY_SATURDAY"),
          value: "Sábado",
          selected: false,
        },
      ],
      ruleCondition: null,
    };
  },
  watch: {
    days: {
      handler(newDays) {
        const selectedDays = newDays
          .filter((day) => day.selected)
          .map((day) => day.value);

        this.$store.dispatch("updateConditions", {
          idRule: this.idRule,
          key: "updateLimitWeekDays",
          conditionType: 0,
          index: this.index,
          values: selectedDays,
        });
      },
      deep: true,
    },

    values: {
      handler(value) {
        if (value.values) {
          this.ruleCondition = value.values;
          this.fillInputs();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    fillInputs() {
      this.days.forEach((day) => {
        day.selected = this.ruleCondition.some(
          (input) => input.stringValue === day.value
        );
      });
    },
  },
};
</script>
