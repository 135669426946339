<template>
  <v-data-table
    :headers="headers"
    :footer-props="footerProps"
    :items="items"
    :hide-default-footer="items.length < 5"
  />
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary";
import { getTranslationByAgroupmentType } from "../utils/get-translation-by-agroupment-type";

export default {
  data() {
    return {
      headers: [
        {
          text: this.$i18n.t("TXT_PRODUCT"),
          align: "center",
          sortable: true,
          value: "description",
          width: "400px",
        },
        {
          text: this.$i18n.t("TXT_MAXIMUM_PRICE"),
          value: "max",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_UPPER_QUARTILE"),
          value: "q3",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_MEDIAN"),
          value: "median",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_LOWER_QUARTILE"),
          value: "q1",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_MIN_PRICE"),
          value: "min",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_AVERAGE_PRICE"),
          value: "avg",
          align: "center",
          sortable: true,
        },
        {
          text: this.$i18n.t("TXT_MOST_FREQUENT_PRICE"),
          value: "mostFrequently",
          align: "center",
          sortable: true,
        },
      ],

      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
    };
  },

  computed: {
    items() {
      const bruteDatas =
        this.$store.state.ProductComparisionStore.preparedDatasForBoxPlot;

      const formatedDatas = bruteDatas.map((row) => ({
        description: row.description,
        min: formatMonetary(row.data.min),
        q1: formatMonetary(row.data.q1),
        median: formatMonetary(row.data.median),
        q3: formatMonetary(row.data.q3),
        max: formatMonetary(row.data.max),
        avg: formatMonetary(row.data.avg),
        mostFrequently: formatMonetary(row.data.mostFrequently),
      }));

      return formatedDatas ?? [];
    },
    groupBy() {
      return this.$store.state.ProductComparisionStore.groupBy;
    },
  },

  watch: {
    groupBy: {
      handler(value) {
        this.headers[0].text = getTranslationByAgroupmentType(value);
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {},
};
</script>