<template>
  <v-card class="pa-4" elevation="4">
    <h4>
      {{
        `${$t(
          "TXT_PRICE_DRIVER_COST"
        )} ${this.product.tipoPrecificacao.toUpperCase()}`
      }}
    </h4>
    <div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th style="width: 30.75%; border-top-right-radius: 10px">
              {{ this.product.nome }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td style="border-top-left-radius: 10px" class="item">Dose</td>
            <td>
              <div>
                <span>{{ formatVal(this.product.dose, 2) }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td rowspan="2" class="currency">BRL</td>
            <td class="item">{{ $t("TXT_PRICE") }} kg/l</td>
            <td>{{ setCurrencyUPL(this.product.precoQuilo, false) }}</td>
          </tr>
          <tr>
            <td class="item">{{ $t("TXT_COST_HECTARE") }}</td>
            <td>{{ setCurrencyUPL(this.product.precoCustoHectare, false) }}</td>
          </tr>
          <tr>
            <td rowspan="2" class="currency">USD</td>
            <td class="item">{{ $t("TXT_PRICE") }} kg/l</td>
            <td>{{ calcDolar(this.product.precoQuilo) }}</td>
          </tr>
          <tr>
            <td class="item">{{ $t("TXT_COST_HECTARE") }}</td>
            <td>{{ calcDolar(this.product.precoCustoHectare) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["product"],
  data() {
    return {
      itemsUfBrandSemCap: [],
      isEdit: true,
    };
  },
  computed: {
    valorDolar() {
      return localStorage.getItem("valorDolar");
    },
  },
  methods: {
    calculateCapInt(val1, val2) {
      return val1 / val2 - 1;
    },
    calcDolar(val) {
      return this.setCurrencyUPL(val / this.valorDolar, true);
    },
    calcDolarWithoutString(val) {
      return (val / this.valorDolar).toFixed(2);
    },
    formatVal(val, decimalPlaces) {
      return val.toFixed(decimalPlaces).toString().replace(".", ",");
    },
    calcPercentage(val, val2) {
      return ((val / val2 - 1) * -100).toFixed(0).toString().replace(".", ",");
    },
    setCurrencyUPL(value, useDollar) {
      if (value != null) {
        value = value.toString().replace(",", ".");

        let [integerPart, decimalPart] = value.split(".");

        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        decimalPart = (decimalPart || "").padEnd(2, "0").substring(0, 2); // Garante que tenha dois dígitos

        const currency = useDollar ? "$" : "R$";
        return `${currency} ${integerPart},${decimalPart}`;
      } else {
        return "-";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;

  thead {
    tr th:first-child {
      background-color: transparent;
      border: 1px solid transparent;
    }

    tr th:nth-child(2) {
      background-color: transparent;
      border: 1px solid transparent;
    }

    tr th:nth-child(3) {
      border-top-left-radius: 10px;
    }

    tr th:nth-child(6) {
      border-top-right-radius: 10px;
    }

    tr th {
      background-color: #3c5ca7;
      color: white;
      border: 2px solid white;
      padding: 5px;
    }
  }

  td {
    padding: 8px;
    border: 2px solid white;
    text-align: center;
  }

  tbody {
    td {
      background-color: #f5f5f5;
    }

    .currency {
      writing-mode: vertical-rl;
      background-color: #3c5ca7;
      color: white;
      font-weight: bold;
      width: 8px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .item {
      background-color: #cccccc;
      font-weight: bold;
    }

    .item:first-child {
      background-color: #cccccc;
      font-weight: bold;
    }
  }
}

.hidden-cell {
  background-color: transparent;
  border: 1px solid transparent;
}
</style>