<template>
  <v-card :disabled="isEditing || deleteLoading" class="pa-4 pt-0">
    <v-row no-gutters justify="space-between" align="center" class="mb-4 pt-4">
      <h4>{{ $t("TXT_RESOURCE_GROUP").toUpperCase() }}</h4>
      <v-icon
        @click="$emit('addGroup')"
        :color="$prdStyles('color-success')"
        size="30"
        >mdi-plus-box</v-icon
      >
    </v-row>

    <v-row no-gutters class="mt-4">
      <v-col cols="12" md="6">
        <Prd-search
          v-model="search"
          :delay="0"
          :disabled="disable || isLoading"
          :searchLabel="$t('TXT_SEARCH_BY_USER_NAME')"
        />
      </v-col>
    </v-row>

    <Prd-loading-circular v-if="isLoading" class="mt-4" />

    <div v-else class="main-list">
      <div
        v-for="group in filteredGroups"
        :key="group.idResourceGroup"
        class="group-item"
      >
        <v-checkbox hide-details class="mt-0 pa-0"></v-checkbox>
        <span>{{ group.name }}</span>
        <v-spacer></v-spacer>
        <v-icon
          :disabled="disable"
          :color="$prdStyles('color-primary')"
          size="30px"
          @click="editGroup(group)"
          >mdi-pencil-box</v-icon
        >

        <Delete-group-modal
          :group="group"
          :isLoading="deleteLoading"
          @deleteGroup="deleteGroup"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
import PrdSearch from "@/components/common/prd-search.vue";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import service from "@/service/company-config/resource-group-management.js";
const Service = new service();
import { mapActions } from "vuex";
import DeleteGroupModal from "./delete-group-modal.vue";

export default {
  props: ["disable", "tab", "isEditing"],
  components: { PrdSearch, PrdLoadingCircular, DeleteGroupModal },
  data() {
    return {
      search: "",
      deleteLoading: false,
      filteredGroups: [],
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    groups() {
      return this.$store?.state?.companyConfigStore?.groups ?? [];
    },

    isLoading() {
      return this.$store.state.companyConfigStore.groupLoading;
    },
  },

  watch: {
    search(newValue) {
      let filteredList = [];
      filteredList = this.groups.filter((el) => {
        return el.name.toLowerCase().includes(newValue.toLowerCase());
      });
      this.filteredGroups = filteredList;
    },

    groups(newGroups) {
      this.filteredGroups = { ...newGroups };
    },
  },

  methods: {
    ...mapActions(["GET_GROUPS"]),

    editGroup(group) {
      this.$emit("editGroup", group);
      this.$emit("addGroup");
    },

    async deleteGroup(group) {
      this.deleteLoading = true;
      try {
        await Service.deleteGroup(this.idCompany, group.idResourceGroup);
        this.GET_GROUPS(this.idCompany);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_SUCCESS_EXCLUDE_RESOURCE_GROUP"),
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_EXCLUDE_RESOURCE_GROUP"),
          type: "error",
        });
      }
      this.deleteLoading = false;
    },
  },

  created() {
    this.GET_GROUPS(this.idCompany);
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  opacity: 0.4;
}
.main-list {
  margin-top: 16px;
  height: 250px;
  max-height: 250px;
  overflow-y: scroll;

  .group-item {
    display: flex;
    align-items: center;
    background-color: $neutral-color-high-light;
    border-top-left-radius: 5px;
    padding: 8px 6px;
    margin: 8px 0px;
  }
}
</style>