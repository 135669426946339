<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-data-table
        id="percentage-table"
        :headers="headers"
        :items="items"
        hide-default-footer
        :no-data-text="$t('TXT_NO_DATA_AVAILABLE')"
      >
        <template v-slot:[`item.seller`]="{ item }">
          <td class="pa-0 text-center font-weight-bold sales-share">
            <span>{{ item.seller }}</span>
          </td>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["product"],
  data() {
    return {
      headers: null,
      items: null,
    };
  },

  methods: {
    returnToInitialState() {
      this.headers = [
        {
          text: `${this.$i18n.t("TXT_SELLER")}`,
          align: "center",
          value: "seller",
        },
      ];

      this.items = [
        {
          seller: this.$t("TXT_SALES_SHARE"),
        },
      ];
    },
    buildBodyToHeader(letter) {
      return {
        text: this.$i18n.t("TXT_SELLER") + " " + letter,
        align: "center",
        value: "seller" + letter,
      };
    },
    getLettersByQuantity(quantity = 0) {
      const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
      const numLetters = alphabet.length;
      const result = [];

      if (quantity <= 0) {
        return result;
      } else {
        for (let i = 0; i < quantity; i++) {
          if (i < numLetters) {
            result.push(alphabet[i]);
          } else {
            const firstIndex = Math.floor((i - numLetters) / numLetters);
            const secondIndex = (i - numLetters) % numLetters;
            const firstLetter = alphabet[firstIndex];
            const secondLetter = alphabet[secondIndex];
            result.push(`${firstLetter}${secondLetter}`);
          }
        }

        return result;
      }
    },
    insertNewValues(itemsTable) {
      let allItems = JSON.parse(JSON.stringify(itemsTable));
      this.returnToInitialState();
      allItems.sort(
        (a, b) => b.percentageMarketShare - a.percentageMarketShare
      );
      const quantity = allItems.length;
      const alphabet = this.getLettersByQuantity(quantity);

      alphabet.forEach((element, index) => {
        this.headers.push(this.buildBodyToHeader(element));
        this.items[0][`seller${element}`] = `${allItems[
          index
        ].percentageMarketShare.toFixed(4)} %`;
      });
    },
  },
  watch: {
    product: {
      handler(value) {
        if (value != undefined) {
          this.insertNewValues(value.maskedSellersResults);
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss">
#percentage-table {
  .v-data-table-header tr th:first-child {
    span {
      color: white !important;
    }
    background-color: $brand-color-primary-pure !important;
    border-top-left-radius: 0.8rem !important;
    border-bottom: 0.15rem solid white !important;
  }
  .v-data-table-header tr th:last-child {
    border-top-right-radius: 0.8rem !important;
  }
  table tbody tr td:last-child {
    border-bottom-right-radius: 0.8rem !important;
  }
  .sales-share {
    color: white;
    background-color: $brand-color-primary-medium;
    border-bottom-left-radius: 0.8rem;
  }
}
</style>