<template>
  <div class="my-6">
    <h4 class="mb-6">
      {{ this.$i18n.t("TXT_PRODUCTS_UPPERCASE") }} ({{ totalRecords }})
    </h4>
    <div class="main-info">
      <Semaphore-filter :isLoading="isLoading" />
      <div class="mx-8">
        <Pricing-scenario :products="products" />
      </div>

      <div class="d-flex align-end">
        <Prd-combo-box
          v-model="selectedOrder"
          :items="orderItems"
          :title="$t('TXT_ORDERING')"
          :placeholder="$t('TXT_ALPHABETICAL')"
          :disabled="isLoading || !products.length"
          @change="setOrder"
          class="mr-2"
        />
        <Prd-combo-box
          v-model="selectedItems"
          :items="itemsPerPage"
          :title="$t('TXT_RESULTS_BY_PAGE')"
          :disabled="isLoading || !products.length"
          @change="setItemsPerPage"
        />
      </div>
    </div>

    <v-row
      v-if="products.length <= 0 && !isLoading"
      no-gutters
      justify="center"
      class="mt-10"
    >
      <h2>{{ $t("TXT_NO_DATA_AVAILABLE") }}</h2>
    </v-row>
  </div>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import PricingScenario from "./components/pricing-scenario.vue";
import SemaphoreFilter from "./components/semaphore-filter.vue";
export default {
  props: ["totalRecords", "isLoading"],
  components: { PrdComboBox, PricingScenario, SemaphoreFilter },
  data() {
    return {
      itemsPerPage: [5, 10, 20, 50, 100],
      selectedItems: 10,
      selectedOrder: { text: this.$i18n.t("TXT_ALPHABETICAL"), value: 0 },
      orderItems: [
        { text: this.$i18n.t("TXT_ALPHABETICAL"), value: 0 },
        { text: this.$i18n.t("TXT_MARGIN"), value: 1 },
        { text: this.$i18n.t("TXT_PROFIT"), value: 2 },
        { text: this.$i18n.t("TXT_REVENUE"), value: 3 },
      ],
    };
  },

  computed: {
    products() {
      return this.$store?.getters?.productList?.records ?? [];
    },
  },

  methods: {
    setItemsPerPage() {
      this.$store.commit("setItemsPerPage", this.selectedItems);
    },
    setOrder() {
      this.$store.commit("setOrder", this.selectedOrder?.value ?? 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-info {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
</style>