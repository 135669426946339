import { render, staticRenderFns } from "./updated-license-main.vue?vue&type=template&id=3daa22b0"
import script from "./updated-license-main.vue?vue&type=script&lang=js"
export * from "./updated-license-main.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports