import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class Filters extends ServiceBase {

    async getPreFilters(idCompany) {
        return await axiosInstance.get(API_URL + `api/V2/Enterprise/PriceGroups/Filter?idCompany=${idCompany}&preSelect=true&showInApriori=false&showInPricing=true`);
    }

    async getFilters(idCompany, idPriceGroup) {
        return await axiosInstance.get(API_URL + `api/v2/Enterprise/PriceProjection/PriceProjectionFilter?idCompany=${idCompany}&idPriceGroup=${idPriceGroup}&isSimulation=false`);
    }

    async calculateRules(idCompany, idEnterprisePriceGroups) {
        return await axiosInstance.get(API_URL + `api/v2/Enterprise/PriceProjection/CalculateRules?idCompany=${idCompany}&idEnterprisePriceGroups=${idEnterprisePriceGroups}&isManual=false`,);
    }

    async getPricingList(request) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/PriceGroups/List", request);
    }

    async getProductList(request) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/Pricing/List", request);
    }
    
    async sendToERP(request) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/PriceGroups/Pulished", request);
    }


}
