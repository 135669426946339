import { render, staticRenderFns } from "./combobox-pricing.vue?vue&type=template&id=186e7144&scoped=true"
import script from "./combobox-pricing.vue?vue&type=script&lang=js"
export * from "./combobox-pricing.vue?vue&type=script&lang=js"
import style0 from "./combobox-pricing.vue?vue&type=style&index=0&id=186e7144&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "186e7144",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCombobox,VIcon,VRow})
