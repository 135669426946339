<template>
  <v-card flat>
    <v-row no-gutters align="center">
      <h4 class="mr-2">{{ $t("TXT_AGROUPMENT_MICRO_LIST") }}</h4>
      <v-icon
        :color="$prdStyles('color-success')"
        :disabled="itsDisabled"
        @click="$emit('addAgroupmentMicro')"
        >mdi-plus-box</v-icon
      >
    </v-row>
    <div class="scrollable-div pr-1">
      <agroupment-micro-unit
        v-for="(agroupmentMicro, index) in list"
        :key="index"
        :index="index"
        :itsDisabled="itsDisabled"
        :agroupmentMicro="agroupmentMicro"
        :allAgroupmentsFiltered="allAgroupmentsFiltered"
        :currentList="list"
        @removeAgroupmentMicro="$emit('removeAgroupmentMicro', index)"
        @updateAgroupmentMicro="$emit('updateAgroupmentMicro', index, $event)"
        @hasDuplicatedNamesMicroFlag="
          $emit('hasDuplicatedNamesMicroFlag', $event)
        "
        @hasDuplicatedGrupoClientesFlag="
          $emit('hasDuplicatedGrupoClientesFlag', $event)
        "
        class="mb-1"
      />
    </div>
  </v-card>
</template>

<script>
import agroupmentMicroUnit from "./agroupment-micro-unit.vue";
export default {
  components: { agroupmentMicroUnit },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    itsDisabled: {
      type: Boolean,
      default: false,
    },
    allAgroupmentsFiltered: { type: Array, default: () => [] },
  },
};
</script>

<style scoped>
.scrollable-div {
  max-height: 300px;
  overflow: auto;
}
</style>