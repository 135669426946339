<template>
  <div>
    <v-row
      v-for="(type, index) in types"
      :key="index"
      no-gutters
      align="center"
    >
      <v-icon :color="setColors(index)" class="mr-1">mdi-circle-medium</v-icon>
      <p class="text-captalize ma-0">{{ type }}</p>
    </v-row>
  </div>
</template>

<script>
import { colors, names } from "../utils/type-color-names";
export default {
  computed: {
    mapPoints() {
      return this.$store?.getters?.getMapPoints ?? [];
    },
    types() {
      if (!Array.isArray(this.mapPoints)) return [];

      const types = this.mapPoints.map(
        (item) => item.type ?? this.$i18n.t("TXT_UNKNOW")
      );

      return Array.from(new Set(types));
    },
  },

  methods: {
    setColors(index) {
      return colors[index];
    },
    setName(type) {
      return names[type] ?? type;
    },
  },
};
</script>