<template>
  <div>
    <v-row no-gutters class="my-4">
      <v-col cols="12" md="3">
        <Prd-combo-box
          v-model="model.RoundTo"
          :items="roundItems"
          :title="$t('TXT_ROUND')"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-checkbox
        v-model="model.UseRange"
        :label="$t('TXT_USE_RANGE')"
        dense
        hide-details
        class="ma-0 pa-0 mr-4"
      ></v-checkbox>
      <v-col cols="12" md="3" class="mr-4"
        ><Prd-text-field
          v-model="model.MinRange"
          :disabled="!model.UseRange"
          :filled="!model.UseRange"
          :title="$t('TXT_MIN_RANGE')"
          :type="'number'"
          hide-spin-buttons
      /></v-col>
      <v-col cols="12" md="3" class="mr-4"
        ><Prd-text-field
          v-model="model.MaxRange"
          :disabled="!model.UseRange"
          :filled="!model.UseRange"
          :title="$t('TXT_MAX_RANGE')"
          :type="'number'"
          hide-spin-buttons
      /></v-col>
    </v-row>
  </div>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";

import PrdComboBox from "@/components/common/prd-comboBox.vue";
export default {
  props: ["index", "values", "idRule"],
  components: { PrdTextField, PrdComboBox },
  data() {
    return {
      model: {
        UseRange: false,
        MaxRange: null,
        MinRange: null,
        RoundTo: null,
      },
      roundItems: [
        {
          text: this.$i18n.t("TXT_ROUND_HIGHEST"),
          value: 0,
        },
        {
          text: this.$i18n.t("TXT_ROUND_NEAREST"),
          value: 1,
        },
        {
          text: this.$i18n.t("TXT_ROUND_LOWEST"),
          value: 2,
        },
      ],
    };
  },
  watch: {
    model: {
      handler(value) {
        this.updateConditions(value);
      },
      deep: true,
    },
    values: {
      handler(value) {
        if (value.values?.length > 0) this.setInputs(value.values);
      },
      immediate: true,
    },
  },
  methods: {
    handlerBehavior(inputs) {
      if (!inputs.UseRange) {
        this.model.MinRange = null;
        this.model.MaxRange = null;
      }
    },
    updateConditions(inputs) {
      this.handlerBehavior(inputs);
      let formatedValue = [
        {
          stringValue: "UseRange",
          decimalValue: 0,
          booleanValue: inputs.UseRange ? true : false,
        },
        {
          stringValue: "MinRange",
          decimalValue: inputs.MinRange ? parseFloat(inputs.MinRange) : null,
          booleanValue: false,
        },
        {
          stringValue: "MaxRange",
          decimalValue: inputs.MaxRange ? parseFloat(inputs.MaxRange) : null,
          booleanValue: false,
        },
        {
          stringValue: "RoundTo",
          decimalValue: inputs.RoundTo.value,
          booleanValue: false,
        },
      ];
      this.$store.dispatch("updateConditions", {
        idRule: this.idRule,
        key: "updateConditionsDefault",
        conditionType: 9,
        index: this.index,
        values: formatedValue,
      });
    },
    setInputs(conditionList) {
      const UseRange = conditionList.find(
        (condition) => condition.stringValue == "UseRange"
      ).booleanValue;
      this.model.UseRange = UseRange;

      const MinRange = conditionList.find(
        (condition) => condition.stringValue == "MinRange"
      ).decimalValue;
      this.model.MinRange = MinRange;

      const MaxRange = conditionList.find(
        (condition) => condition.stringValue == "MaxRange"
      ).decimalValue;
      this.model.MaxRange = MaxRange;

      const RoundTo = conditionList.find(
        (condition) => condition.stringValue == "RoundTo"
      ).decimalValue;
      const RoundToItem = this.roundItems.find((item) => item.value == RoundTo);
      this.model.RoundTo = RoundToItem;
    },
  },
};
</script>

<style>
</style>