<template>
  <v-dialog persistent max-width="600px" v-model="isOpen">
    <v-card flat class="pa-4">
      <h3 class="text-center mb-4">
        {{ $t("TXT_UPDATE_DELIBERATION_REFERENCE") }}
      </h3>
      <span v-if="oldName && newName" class="font-weight-bold"
        >{{ oldName }}
        <v-icon :color="$prdStyles('color-primary')"
          >mdi-arrow-right-bold-outline</v-icon
        >
        {{ newName }}</span
      >
      <br />
      <span style="color: red">* {{ $t("TXT_SELECT_REFERENCE_ALERT") }}</span>
      <br />
      <span>{{ $t("TXT_REALLY_WANT_CHANGE") }}</span>
      <v-row no-gutters justify="end">
        <prd-btn
          @click="$emit('resetDeliberation')"
          :title="$t('TXT_CANCEL')"
          :outlined="true"
          class="mr-2"
        />
        <prd-btn
          @click="$emit('saveNewDeliberation')"
          :title="$t('TXT_UPDATE')"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  components: { prdBtn },
  data() {
    return {
      isOpen: false,
      oldName: null,
      newName: null,
    };
  },
  methods: {
    open(oldName, newName) {
      this.oldName = oldName;
      this.newName = newName;
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      this.oldName = null;
      this.newName = null;
    },
  },
};
</script>