<template>
  <v-dialog v-model="modalOpen" :width="width" persistent>
    <v-card class="pa-4">
      <v-row no-gutters justify="center">
        <div class="header">
          <v-icon :color="iconColor" large>{{ icon }} </v-icon>
          <h4>{{ title }}</h4>
          <span>
            {{ titleDescription }}
          </span>
        </div>
      </v-row>

      <div class="content-bg">
        <slot name="content"></slot>
      </div>

      <v-row no-gutters justify="center" class="text-center mb-4">
        <span>{{ description }}</span>
      </v-row>

      <v-row
        v-if="warningMessage"
        no-gutters
        justify="center"
        class="text-center mb-4"
      >
        <span class="warning-message">{{ warningMessage }}</span>
      </v-row>

      <v-row no-gutters justify="end">
        <Prd-btn
          v-show="buttonTitle1 ? true : false"
          :title="buttonTitle1 ? buttonTitle1 : ''"
          :outlined="true"
          :color="buttonColor1"
          @click="$emit('actionButton1')"
          class="mr-4"
        />
        <Prd-btn
          :title="buttonTitle2"
          :color="buttonColor2"
          @click="$emit('actionButton2')"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  components: { PrdBtn },
  props: {
    controlModal: {
      type: Boolean,
      default: false,
      required: true,
    },
    width: {
      type: Number,
      default: 700,
    },
    title: {
      type: String,
    },
    titleDescription: {
      type: String,
    },
    description: {
      type: String,
    },
    warningMessage: {
      type: String,
    },
    icon: {
      type: String,
    },
    iconColor: {
      type: String,
      default: "#3c5ca7",
    },
    buttonTitle1: {
      type: String,
    },
    buttonTitle2: {
      type: String,
    },
    buttonColor1: {
      type: String,
      default: "#3c5ca7",
    },
    buttonColor2: {
      type: String,
      default: "#3c5ca7",
    },
  },
  data() {
    return {
      modalOpen: false,
    };
  },
  watch: {
    controlModal: {
      handler(value) {
        this.modalOpen = value;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.content-bg {
  background-color: $neutral-color-high-light;
  margin: 12px 0;
  padding: 8px;
}

.warning-message {
  color: #b00020;
}
</style>