<template >
  <div v-click-outside="hideStatusIfClickOutside">
    <span v-if="hideProgression">
      {{ $i18n.t("TXT_NO_IMPORTATION_FOUNDED") }}
    </span>

    <v-stepper :value="currentStep" v-else flat>
      <v-stepper-header>
        <div
          v-for="(step, index) in stepList"
          :key="index"
          class="d-flex align-center step-sizes"
        >
          <v-stepper-step
            :complete="currentStep > step.number"
            :rules="[() => checkError(step.number)]"
            :step="step.number"
            :class="verifyPadding(step.number)"
            complete-icon="mdi-chevron-down"
          >
            {{ $i18n.t(step.text) }}
            <small class="d-flex align-center">
              <span class="mr-2">{{ $i18n.t(checkMessage(step.number)) }}</span>
              <span
                v-show="step.number == 4 && stepPercent"
                class="font-weight-bold"
                >{{ formatPercentage(stepPercent) }}</span
              >
              <Prd-tooltip
                v-if="false"
                :icon="
                  stepStatus == 3
                    ? 'mdi-alert-outline'
                    : 'mdi-information-outline'
                "
                :text="replaceStringWithBr(stepMessage)"
                :colorIcon="stepStatus == 3 ? '#EC4C37' : '#3c5ca7'"
            /></small>
          </v-stepper-step>

          <div
            v-show="step.number != stepList.length"
            class="divider mx-2"
          ></div>
        </div>
      </v-stepper-header>
    </v-stepper>
  </div>
</template>

<script>
import ClickOutside from "@/utils/clickOutside.js";
import PrdTooltip from "@/components/common/prd-tooltip.vue";
import { formatPercentage } from "@/utils/format-percentage";
export default {
  components: { PrdTooltip },
  directives: {
    "click-outside": ClickOutside,
  },
  props: [
    "showStatus",
    "currentStep",
    "stepStatus",
    "stepMessage",
    "hideProgression",
    "stepList",
    "stepPercent",
  ],

  methods: {
    formatPercentage,
    checkError(stepNumber) {
      return !(stepNumber == this.currentStep && this.stepStatus == 3);
    },

    checkMessage(stepNumber) {
      if (stepNumber == this.currentStep) {
        if (this.stepStatus == 0) return "TXT_PENDING";
        if (this.stepStatus == 1) return "TXT_IMPORTATIONS_STATUS_RUNNING";
        if (this.stepStatus == 2) return "TXT_FINISHED";
        if (this.stepStatus == 3) return "TXT_ERROR";
      }
    },

    verifyPadding(stepNumber) {
      return stepNumber == 1
        ? "pa-0 pl-4"
        : stepNumber == this.stepList.length
        ? "pa-0 pr-4"
        : "pa-0";
    },

    hideStatusIfClickOutside(classList) {
      if (
        classList.includes("v-btn__content") ||
        classList.includes("v-btn") ||
        classList.includes("v-icon")
      )
        return;
      else this.$emit("closeStatus");
    },

    replaceStringWithBr(text) {
      return text.replace(/\n/g, "<br>");
    },
  },
};
</script>

<style lang="scss" scoped>
.step-sizes {
  font-size: 12px !important;
}

.divider {
  width: 15px;
  height: 1px;
  background-color: $brand-color-primary-pure;
  align-self: center;
  justify-self: center;
}
</style>