<template>
  <v-container fluid>
    <titleBreadCrumbsComponent
      :title="`${$t('TXT_PRODUCT_MIX_UPPERCASE')} - APRIORI`"
      :hasTooltip="false"
    />
    <simulation-card
      @simulationData="simulationDataFunction"
      :showButtonSaveSimulation="showButtonSaveSimulationBoolean"
      :idEnterpriseAprioriResultGroup="idEnterpriseAprioriResultGroupLocal"
      :isLoading="isLoading"
    />
    <table-card
      @setLoading="isLoading = $event"
      :dataSimulation="simulationData"
      @showButtonSaveSimulation="showButtonSaveSimulation"
      @getIdEnterpriseAprioriResultGroup="getIdEnterpriseAprioriResultGroup"
    />
  </v-container>
</template>

<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import simulationCard from "./components/simulation-card.vue";
import tableCard from "./components/tables/table-card.vue";
export default {
  name: "mix-products-apriori",
  components: { titleBreadCrumbsComponent, simulationCard, tableCard },
  data() {
    return {
      simulationData: null,
      showButtonSaveSimulationBoolean: false,
      isLoading: false,
      idEnterpriseAprioriResultGroupLocal: null,
    };
  },
  methods: {
    simulationDataFunction(type, data) {
      this.simulationData = data;
      this.simulationData.type = type;
    },

    showButtonSaveSimulation(data) {
      this.showButtonSaveSimulationBoolean = data;
    },
    getIdEnterpriseAprioriResultGroup(data) {
      this.idEnterpriseAprioriResultGroupLocal = data;
    },
  },

};
</script>