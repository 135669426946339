<template>
  <v-card class="pa-2" min-height="145">
    <h3 class="mb-1">{{ $t("TXT_APPROVED_PRECIFICATION").toUpperCase() }}</h3>
    <v-row
      v-if="itsLoading"
      no-gutters
      align-content="center"
      justify="center"
      style="height: 80px"
    >
      <Prd-loading-msg :text="loadingText" />
    </v-row>
    <v-row no-gutters v-else>
      <v-col cols="12">
        <p class="font-weight-bold ma-0">
          {{ $t("TXT_LAST_APPROVATION") }}
        </p>

        <p class="ma-0 overflow-ellipsis">
          <span class="mr-1 font-weight-bold">{{ $t("TXT_STORE") }}:</span>
          {{ lastGroup ? lastGroup.affiliate : "-" }}
        </p>

        <v-row no-gutters justify="space-between">
          <v-col>
            <p class="ma-0 overflow-ellipsis">
              <span class="mr-1 font-weight-bold">{{ $t("TXT_USER") }}:</span>
              {{ lastGroup ? lastGroup.userName : "-" }}
            </p>

            <p class="ma-0 overflow-ellipsis">
              <span class="mr-1 font-weight-bold">{{ $t("TXT_DATE") }}:</span>
              {{ lastGroup ? formatDate(lastGroup.date) : "-" }}
            </p>
          </v-col>

          <v-col align-self="end">
            <v-row no-gutters justify="end">
              <Report-modal-main
                :type="'approvation'"
                :selectedAffiliate="selectedAffiliate"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import PrdLoadingMsg from "@/components/common/prd-loading-msg.vue";
import Home20Service from "@/service/home20";
import { formatDate } from "@/utils/prd-format-date";
import ReportModalMain from "../geral-vision/components/chart-adoption-ia/extract-report-filters/report-modal-main.vue";
export default {
  components: { PrdLoadingMsg, ReportModalMain },
  props: {
    selectedAffiliate: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      lastGroup: {},
      itsLoading: false,
      service: new Home20Service(),
    };
  },

  computed: {
    loadingText() {
      return `${this.$i18n.t("TXT_CARRYING")} ${this.$i18n.t(
        "TXT_LAST_APPROVATION"
      )}`;
    },

    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    disbledDownloadReportButton() {
      return this.lastGroup == null;
    },
  },

  watch: {
    idCompany: {
      handler(value) {
        if (value) this.getLastApprovedPrecification();
      },
      immediate: true,
    },
  },

  methods: {
    formatDate,
    async getLastApprovedPrecification() {
      this.itsLoading = true;
      try {
        const response = await this.service.getLastApprovedPrecification(
          this.idCompany
        );
        this.lastGroup = response.data.result;
      } catch (error) {
        console.log(error);
      } finally {
        this.itsLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
