<template>
  <div>
    <v-icon @click="isModalOpen = true" color="#1D8527">mdi-plus-box</v-icon>

    <v-dialog :max-width="1000" v-model="isModalOpen" persistent>
      <v-card class="pa-4" id="insert-product-panel">
        <h4 class="mb-6">{{ $t("TXT_ADD_PRODUCTS").toUpperCase() }}</h4>
        <v-tabs
          color="black"
          slider-color="#EC4C37"
          background-color="transparent"
          height="25px"
          class="pa-0"
        >
          <v-tab>
            <span class="font-weight-bold">{{ $t("TXT_NEW_PRODUCT") }}</span>
          </v-tab>
          <v-tab> </v-tab>
        </v-tabs>

        <!-- PRODUCT NAME -->
        <v-row no-gutters class="mt-6 mb-4">
          <v-col>
            <label>{{ $t("TXT_PRODUCT_NAME") }}</label>
            <v-text-field
              v-model="productDescription"
              outlined
              dense
              hide-details
              class="mr-4"
            ></v-text-field>
          </v-col>
          <v-col>
            <label>{{ $t("TXT_PRODUCT_LINK") }}</label>
            <v-text-field
              v-model="productLink"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <div style="display: flex; gap: 1rem" class="mb-6">
          <!-- SELLER-->
          <div>
            <label>{{ $t("TXT_SELLER") }}</label>
            <v-text-field
              v-model="seller"
              outlined
              dense
              hide-details
            ></v-text-field>
          </div>

          <!-- PRICE -->
          <div>
            <label>{{ $t("TXT_PRICE") }}</label>
            <v-text-field
              v-model="price"
              prefix="R$"
              placeholder="Ex: 28,44"
              type="number"
              hide-spin-buttons
              outlined
              dense
              hide-details
            ></v-text-field>
          </div>

          <!-- INSTALLSMENT -->
          <div>
            <label>{{ $t("TXT_INSTALLMENTS") }}</label>
            <v-text-field
              v-model="installsment"
              type="number"
              hide-spin-buttons
              outlined
              dense
              hide-details
            ></v-text-field>
          </div>

          <!-- DATA PICKER -->
          <div>
            <label>{{ $t("TXT_COLECT_DATE") }}</label>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              ref="menu"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  v-bind="attrs"
                  v-on="on"
                  :label="$t('TXT_SELECT_PERIOD')"
                  :placeholder="$t('TXT_SELECT_PERIOD')"
                  hide-details
                  outlined
                  single-line
                  dense
                  readonly
                  class="input-datapicker"
                >
                  <template v-slot:append>
                    <v-icon class="append-calendar" color="white" small>
                      mdi-calendar</v-icon
                    >
                  </template>
                </v-text-field>
              </template>
              <!-- TODO - properly use user's locale -->
              <v-date-picker
                v-model="date"
                no-title
                scrollable
                :locale="localeForDatePicker"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="error"
                  @click="menu = false"
                  v-t="'TXT_CANCEL'"
                ></v-btn>
                <v-btn
                  color="primary"
                  @click="$refs.menu.save(date)"
                  v-t="'TXT_OK'"
                ></v-btn>
              </v-date-picker>
            </v-menu>
          </div>
        </div>

        <v-row no-gutters align="center" justify="end">
          <prd-btn
            :title="$t('TXT_CANCEL')"
            :outlined="true"
            @click="cancelChanges"
            class="mr-4"
          />
          <prd-btn
            :title="$t('TXT_ADD_PRODUCTS')"
            :disabled="
              !productDescription ||
              !productLink ||
              !seller ||
              !price ||
              installsment < 1
            "
            @click="addProduct"
          />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  components: { prdBtn },
  props: ["productName"],
  data() {
    return {
      isModalOpen: false,
      menu: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      productDescription: this.productName.productName,
      productLink: null,
      price: null,
      installsment: 1,
      seller: null,
    };
  },
  computed: {
    localeForDatePicker() {
      return localStorage.getItem("lang") ?? "pt-BR";
    },
  },

  methods: {
    addProduct() {
      let productobj = {};

      productobj = {
        descricao: this.productDescription,
        productLink: this.productLink,
        price: parseFloat(this.price),
        installment: this.installsment,
        seller: this.seller,
        refDate: this.date,
      };

      this.$emit("addProduct", productobj);

      this.productDescription = this.productName.productName;
      this.productLink = null;
      this.price = null;
      this.installsment = 1;
      this.seller = null;
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);

      this.isModalOpen = false;
    },

    cancelChanges() {
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.productDescription = this.productName.productName;
      this.productLink = null;
      this.price = null;
      this.installsment = 1;
      this.seller = null;

      this.isModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.append-pencil {
  background: #3c5ca7;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.append-minus {
  background: red;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.input-datapicker .v-input__app end-inner {
  margin-top: 0px !important;
}

.append-calendar {
  height: 40px;
  width: 40px;
  background: #3c5ca7;
  margin-right: -11px;
  border-radius: 0px 4px 4px 0px;
  color: white;
}
</style>