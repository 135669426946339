import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class ChurnAnalysis extends ServiceBase {

    async getCustomers(request) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/Churn/List", request);
    }

    async getFilters(idCompany) {
        return await axiosInstance.get(API_URL + "api/v2/Enterprise/Churn/Filters", { params: { idCompany: idCompany } });
    }

    async getCompanyFilters(idCompany) {
        return await axiosInstance.get(API_URL + "api/v2/Enterprise/Churn/ListFilters", { params: { idCompany: idCompany } });
    }

    async extractReport(idCompany) {
        return await axiosInstance.get(API_URL + "api/v2/Enterprise/Churn/ListExport", { params: { idCompany: idCompany } });
    }

    async getSimulation(request) {
        return await axiosInstance.post(API_URL + "api/v2/Enterprise/Churn/Simulate", request);
    }
}