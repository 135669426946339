<template>
  <Section-card
    class="my-4"
    :title="$t('TXT_MANAGEMENT_LICENSES')"
    :disabled="isLoading"
  >
    <template slot="content">
      <v-row no-gutters class="mt-4">
        <v-col cols="6" class="pr-2">
          <License-list
            :isLinkedCard="false"
            :licenseList="licenseList"
            @handlerLicense="handlerLicense"
          />
        </v-col>
        <v-col cols="6" class="pl-2">
          <License-list
            :isLinkedCard="true"
            :licenseList="licenseList"
            @handlerLicense="handlerLicense"
          />
        </v-col>
      </v-row>
      <v-row no-gutters justify="end" class="mt-6 gap-6">
        <Modal-action
          :disabledActivatorButton="isLoading"
          :titleActivatorButton="$t('TXT_CANCEL')"
          :useActivatorButtonMargin="false"
          :outlinedActivatorButton="true"
          :icon="'mdi-close-circle-outline'"
          :iconColor="'red'"
          :modalTitle="$t('TXT_CANCEL_EDIT_LICENSES')"
          :question="$t('TXT_MODAL_CANCEL_EDIT_LICENSES_QUESTION')"
          :alertMessage="$t('TXT_MODAL_CANCEL_EDIT_LICENSES_ALERT_MESSAGE')"
          :confirmButtonText="$t('TXT_CONFIRM')"
          @handleConfirmAction="returnToOriginalState"
        >
          <template slot="content">
            <Updated-license-main :updatedList="updatedList" />
          </template>
        </Modal-action>

        <Modal-action
          :disabledActivatorButton="isLoading"
          :titleActivatorButton="$t('TXT_CONFIRM')"
          :useActivatorButtonMargin="false"
          :icon="'mdi-check-circle-outline'"
          :iconColor="'green'"
          :modalTitle="$t('TXT_CONFIRM_EDIT_LICENSES')"
          :question="$t('TXT_MODAL_CONFIRM_EDIT_LICENSES_QUESTION')"
          :alertMessage="$t('TXT_MODAL_CONFIRM_EDIT_LICENSES_ALERT_MESSAGE')"
          :confirmButtonText="$t('TXT_CONFIRM')"
          @handleConfirmAction="updateLicenses"
        >
          <template slot="content">
            <Updated-license-main :updatedList="updatedList" />
          </template>
        </Modal-action>
      </v-row>
    </template>
  </Section-card>
</template>
  

<script>
import GeneralSystemCongisService from "@/service/general-system-configs/index";
import SectionCard from "../../../../common-use-components/section-card.vue";
import LicenseList from "./components/license-list/license-list-main.vue";
import ModalAction from "@/components/common/modal-action.vue";
import UpdatedLicenseMain from "./components/updated-license/updated-license-main.vue";
export default {
  components: { SectionCard, LicenseList, ModalAction, UpdatedLicenseMain },
  props: {
    selectedCompany: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      service: new GeneralSystemCongisService(),
      licenseList: [],
      licenseListByCompany: [],
    };
  },

  computed: {
    updatedList() {
      return this.licenseList.filter(
        (license) => license.isLinked != license.originalIsLinked
      );
    },
  },

  watch: {
    selectedCompany: {
      async handler(value) {
        if (value) {
          this.resetList();
          await this.getLicenseList();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    async getLicenseList() {
      this.isLoading = true;

      try {
        const response = await this.service.getLicenseList();
        this.licenseList = response.data.map((license) => {
          return { ...license, isLinked: false, originalIsLinked: false };
        });
        await this.getLicenseListByCompany(this.selectedCompany.idEmpresa);
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_GET_LICENSES_ERROR"),
          type: "error",
        });
      }

      this.isLoading = false;
    },

    async getLicenseListByCompany(idEmpresa) {
      this.isLoading = true;
      let message, type;

      try {
        const response = await this.service.getLicenseListByCompany(idEmpresa);
        this.licenseListByCompany = response.data;
        this.prepareLicenseList()
      } catch (error) {
        console.log(error);
        message = this.$i18n.t("TXT_GET_LICENSES_BY_COMPANY_ERROR");
        type = "error";
      } finally {
        this.isLoading = false;
        this.$store.commit("snackbarV2/set", { message, type });
      }

    },

    resetList() {
      this.licenseList = this.licenseList
        .filter((license) => license.canShowToSales !== false)
        .map((license) => ({
          ...license,
          isLinked: false,
          originalIsLinked: false,
        }));
    },

    returnToOriginalState() {
      this.licenseList = this.licenseList.map((license) => ({
        ...license,
        isLinked: license.originalIsLinked,
      }));
    },

    updateToNewState() {
      this.licenseList = this.licenseList.map((license) => ({
        ...license,
        originalIsLinked: license.isLinked,
      }));
    },

    prepareLicenseList() {
      this.licenseListByCompany.forEach((license) => {
        if (!license.canShowToSales) {
          license.isLinked = true;
          license.originalIsLinked = true;
          this.licenseList.push(license);
        } else {
          const id = license.idLicenca;
          this.setIsLinked(true, id);
        }
      });
    },

    setIsLinked(isFirstTime, id) {
      const foundedLicense = this.licenseList.find(
        (license) => license.idLicenca == id
      );
      if (foundedLicense) {
        foundedLicense.isLinked = !foundedLicense.isLinked;
        if (isFirstTime) {
          foundedLicense.originalIsLinked = !foundedLicense.originalIsLinked;
        }
      }
    },

    handlerLicense(license) {
      const id = license.idLicenca;
      this.setIsLinked(false, id);
    },

    prepareRequest() {
      let body = {
        idEmpresa: this.selectedCompany.idEmpresa,
        licencas: [],
      };
      this.licenseList.forEach((license) => {
        const { originalIsLinked, isLinked, ...rest } = license;
        let status = false;
        if (originalIsLinked && isLinked) {
          body.licencas.push({ ...rest, status });
        } else if (!originalIsLinked && isLinked) {
          status = true;
          body.licencas.push({ ...rest, status });
        }
      });
      return body;
    },

    async updateLicenses() {
      this.isLoading = true;
      let message, type;
      const request = this.prepareRequest();
      try {
        await this.service.updateLicenses(request);
        message = this.$i18n.t("TXT_UPDATED_LICENSES_SUCCESS");
        type = "success";
        this.updateToNewState();
      } catch (error) {
        console.log(error);
        message = this.$i18n.t("TXT_UPDATED_LICENSES_ERROR");
        type = "error";
      } finally {
        this.isLoading = false;
        this.$store.commit("snackbarV2/set", { message, type });
      }
    },
  },
};
</script>