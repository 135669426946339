<template>
  <div>
    <div class="text-area mt-4">
      <v-textarea
        v-model="text"
        :disabled="isLoading || showConfirmationRules"
        :filled="isLoading || showConfirmationRules"
        :maxlength="2000"
        :placeholder="isLoading ? '' : $i18n.t('TXT_TYPE_YOUR_RULE')"
        :class="isLoading || showConfirmationRules ? '' : 'pa-2'"
        @keydown="handleKeydown"
        flat
        hide-details
        no-resize
        counter
      ></v-textarea>

      <!-- ICON ACTIONS -->
      <v-row
        no-gutters
        align="center"
        justify="space-between"
        :class="[showConfirmationRules ? 'disableIcons' : '', 'px-2 py-1']"
      >
        <div>
          <span class="text-opacity">{{ text.length }}/2000</span>
        </div>
        <div>
          <!-- <v-icon :disabled="isLoading" class="mr-2"
            >mdi-microphone-outline</v-icon
          > -->
          <!-- <v-icon :disabled="isLoading" class="mr-2">mdi-paperclip</v-icon> -->
          <v-icon :disabled="isLoading" @click="SEND_MESSAGE">
            mdi-send-variant-outline
          </v-icon>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    text: "",
  }),

  computed: {
    userData() {
      return this.$store?.getters?.userData ?? null;
    },

    isLoading() {
      return this.$store?.state?.copilotStore?.copilotChatLoading ?? false;
    },

    showConfirmationRules() {
      return this.$store?.getters?.showConfirmationRules ?? false;
    },
  },

  methods: {
    SEND_MESSAGE() {
      if (!this.text || this.text == "") return;

      let message = {
        author: this.userData.username,
        idCompany: this.userData.idCompany.toString(),
        messages: [
          {
            type: "text",
            message: this.text,
          },
        ],
      };

      this.$store.dispatch("SEND_MESSAGE", message);
      this.text = "";
    },

    handleKeydown(event) {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        this.SEND_MESSAGE();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-area {
  border: 0.5px solid rgba(0, 0, 0, 0.247);
  border-radius: 5px;
  background-color: #fff;
}

.text-opacity {
  opacity: 0.8;
}

.disableIcons {
  pointer-events: none;
  background-color: #f5f5f5;
}
</style>