<template>
  <v-expansion-panels flat class="pa-0">
    <v-expansion-panel>
      <v-expansion-panel-header class="pa-4">
        <h4>{{ $t("TXT_REGISTER_NEW_USER").toUpperCase() }}</h4>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <v-row no-gutters align="center">
          <!-- EMAIL -->
          <v-col cols="12" md="4" class="mr-4">
            <label>E-mail</label>
            <v-text-field
              v-model="email"
              :disabled="isLoading"
              outlined
              dense
              hide-details
              placeholder="email@email.com"
            >
            </v-text-field>
          </v-col>

          <!-- NAME -->
          <v-col v-if="userNotRegistered" cols="12" md="4" class="mr-4">
            <label>{{ $t("TXT_NAME") }}</label>
            <v-text-field
              v-model="name"
              :disabled="isLoading"
              outlined
              dense
              hide-details
              :placeholder="$t('TXT_INSERT_NAME')"
            >
            </v-text-field>
          </v-col>

          <!-- TEL -->
          <v-col v-if="userNotRegistered" cols="12" md="2" class="mr-4">
            <label>{{ $t("TXT_PHONE") }}</label>
            <v-text-field
              v-model="phone"
              :disabled="isLoading"
              type="number"
              hide-spin-buttons
              outlined
              dense
              hide-details
              placeholder="(xx) xxxxx-xxxx"
            >
            </v-text-field>
          </v-col>

          <!-- ACCESS GROUP -->
          <v-col class="mr-4">
            <Prd-combo-box
              v-if="false"
              v-model="accessGroup"
              :items="accessGroupItems"
              :title="$t('TXT_ACCESS_GROUP')"
              :placeholder="$t('TXT_SELECT')"
              :disabled="isLoading || userNotRegistered"
            />
          </v-col>

          <!-- ACCESS -->
          <v-col>
            <Prd-combo-box
              v-if="false"
              v-model="access"
              :items="accessItems"
              :title="$t('TXT_ACCESS_PLURAL')"
              :placeholder="$t('TXT_SELECT')"
              :disabled="isLoading"
            />
          </v-col>
        </v-row>

        <v-row no-gutters justify="space-between" align="center" class="mt-6">
          <Prd-loading-msg v-if="isLoading" :text="loadingMessage" />
          <v-spacer></v-spacer>
          <div>
            <Prd-btn
              :title="$t('TXT_CANCEL')"
              :outlined="true"
              :disabled="isLoading"
              class="mr-4"
            />
            <Prd-btn
              v-if="!userNotRegistered"
              :title="$t('TXT_REGISTER')"
              :disabled="!email || email.length <= 0 || isLoading"
              :loading="isLoading"
              @click="registerUser"
            />
            <Prd-btn
              v-else
              :title="$t('TXT_CREATE_USER')"
              :disabled="disableCreateUser"
              :loading="isLoading"
              @click="createUser"
            />
          </div>
        </v-row>
        <v-divider class="mt-6"></v-divider>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import service from "@/service/company-config/user-management.js";
import PrdLoadingMsg from "@/components/common/prd-loading-msg.vue";
const Service = new service();
export default {
  components: { PrdComboBox, PrdBtn, PrdLoadingMsg },
  data() {
    return {
      accessItems: [],
      accessGroupItems: [],

      name: null,
      email: null,
      phone: null,
      access: [],
      accessGroup: [],
      userNotRegistered: false,

      loadingMessage: "",
      isLoading: false,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    disableCreateUser() {
      if (!this.email || !this.name || !this.phone) return true;

      return false;
    },
  },

  methods: {
    async registerUser() {
      this.isLoading = true;
      this.$emit("createUserLoading", true);

      try {
        this.loadingMessage = this.$i18n.t("TXT_CHECKING_USER");
        let user = await Service.getUser(this.email);

        let request = {
          idEmpresa: this.idCompany,
          usuario: user.data,
        };

        this.loadingMessage = this.$i18n.t('TXT_REGISTERING_USER');
        await Service.linkUserToCompany(request);

        this.$emit("updateUserList", true);
        this.resetField();
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_REGISTER_NEW_USER_SUCCESS"),
          type: "success",
        });
      } catch (error) {
        this.userNotRegistered = true;
        console.error("Error registering user:", error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_NOT_FOUND_USER_REGISTER"),
          type: "info",
        });
      }

      this.isLoading = false;
      this.loadingMessage = "";
      this.$emit("createUserLoading", false);
    },

    async createUser() {
      this.isLoading = true;
      this.loadingMessage = this.$i18n.t('TXT_REGISTERING_USER');

      this.$emit("createUserLoading", true);

      try {
        let request = {
          idEmpresa: this.idCompany,
          usuario: {
            email: this.email,
            telefone: this.phone,
            nome: this.name,
            lang: "pt-br",
          },
        };

        await Service.linkUserToCompany(request);
        this.$emit("updateUserList", true);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_REGISTER_NEW_USER_SUCCESS"),
          type: "success",
        });
      } catch (error) {
        console.error("Error creating user:", error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_CREATE_USER"),
          type: "error",
        });
      }

      this.resetField();
      this.isLoading = false;
      this.$emit("createUserLoading", false);
    },

    resetField() {
      this.email = null;
      this.phone = null;
      this.name = null;
      this.access = [];
      this.accessGroup = [];
      this.userNotRegistered = false;
      this.loadingMessage = "";
    },
  },
};
</script>

<style>
</style>