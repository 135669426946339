<template>
  <modal-action
    :useExternalActivatorButton="true"
    :externalIsOpen="isOpen"
    @handlerIsOpen="isOpen = $event"
    :icon="'mdi-alert-outline'"
    :iconColor="'yellow'"
    :modalTitle="$t('TXT_ADMIN_ACCESS')"
    :question="question"
    :alertMessage="alertMessage"
    :confirmButtonText="$t('TXT_CONFIRM')"
    @handleConfirmAction="makeIsOwnsUser"
  >
    <template slot="externalActivatorButton">
      <v-progress-circular
        indeterminate
        :color="$prdStyles('color-primary')"
        v-show="isLoading"
      />
      <v-icon
        v-show="!isLoading"
        :disabled="user.generalIsLoading"
        :class="`backgroundAdminIcon ${
          !user.isProprietario || user.generalIsLoading ? 'des' : ''
        }actived ${user.isProprietario ? 'notCursorMouse' : ''}`"
        @click="!user.isProprietario ? (isOpen = true) : null"
        >mdi-shield-account-outline</v-icon
      >
    </template>
    <template slot="content">
      <v-col cols="12">
        <v-row no-gutters class="gap-1">
          <span>{{ $t("TXT_USER_NAME") }}:</span>
          <span class="font-weight-bold">{{ user.nome }}</span>
        </v-row>

        <v-row no-gutters class="gap-1">
          <span>{{ $t("TXT_ADMIN_ACCESS") }}:</span>
          <span class="font-weight-bold ml-1">{{
            !user.isProprietario ? "Sim" : "Não"
          }}</span>
          <v-icon
            :class="`backgroundAdminIcon ${
              user.isProprietario ? 'des' : ''
            }actived`"
            >mdi-shield-account-outline</v-icon
          >
        </v-row>
      </v-col>
    </template>
  </modal-action>
</template>

<script>
import ModalAction from "@/components/common/modal-action.vue";
import GeneralSystemConfigsService from "@/service/general-system-configs";
export default {
  components: { ModalAction },
  props: {
    user: { type: Object },
    idCompany: { type: Number },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      service: new GeneralSystemConfigsService(),
    };
  },
  computed: {
    question() {
      return this.user.isProprietario
        ? this.$i18n.t("TXT_MODAL_ADMIN_ACESS_QUESTION_REMOVE")
        : this.$i18n.t("TXT_MODAL_ADMIN_ACESS_QUESTION_BECOME");
    },

    alertMessage() {
      return this.user.isProprietario
        ? this.$i18n.t("TXT_MODAL_ADMIN_ACESS_ALERT_MESSAGE_REMOVE")
        : this.$i18n.t("TXT_MODAL_ADMIN_ACESS_ALERT_MESSAGE_BECOME");
    },
  },
  watch: {
    isLoading: {
      handler(value) {
        this.$emit("isLoading", value);
      },
    },
  },
  methods: {
    createRequest() {
      return {
        idCompany: this.idCompany,
        email: this.user.email,
      };
    },

    async makeIsOwnsUser() {
      this.isLoading = true;
      const request = this.createRequest();
      let message,
        type = null;
      try {
        await this.service.makeIsOwnsUser(request);
        message = this.$i18n.t("TXT_MAKE_USER_ADMIN_SUCCESS");
        type = "success";
        this.$emit("invertIsProprietario", this.user);
      } catch (error) {
        console.log(error);
        message = this.$i18n.t("TXT_MAKE_USER_ADMIN_ERROR");
        type = "error";
      } finally {
        this.isLoading = false;
        this.$store.commit("snackbarV2/set", { message, type });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.backgroundAdminIcon {
  color: white;
  border-radius: 4px;
}
.actived {
  background-color: $feedback-color-helper-pure;
}
.desactived {
  background-color: $neutral-color-high-dark;
}
.notCursorMouse {
  cursor: default !important;
}
</style>