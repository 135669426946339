<template>
  <v-expansion-panel-content>
    <Handler-rule-datas
      :idRule="idRule"
      :ruleForFieldArea="ruleForFieldArea"
      :loadingRules="loadingRules"
      :loadingSavingChanges="loadingSavingChanges"
      :description="groupRules.description"
      :isAllProducts="groupRules.isAllProducts"
      :numberOrder="groupRules.numberOrder"
      :resetFieldsFlag="resetFieldsFlag"
      @updateDescription="groupRules.description = $event"
      @updateIsAllProducts="groupRules.isAllProducts = $event"
      @updateElasticity="elasticity = $event"
      @updateFilters="groupRules.filters = $event"
      @updateCurve="curve = $event"
      @updateResetFieldsFlag="resetFieldsFlag = $event"
      @saveRules="saveRules"
      @closePanel="closePanel"
    />
  </v-expansion-panel-content>
</template>

<script>
import HandlerRuleDatas from "../../../../../handler-rule-datas/handler-rule-datas.vue";
import service from "@/service/pricing-rules/create-rules.js";
const Service = new service();

export default {
  props: {
    idRule: {
      type: Number,
      default: 0,
    },
    itsOpen: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { HandlerRuleDatas },
  data() {
    return {
      loadingRules: false,
      loadingSavingChanges: false,
      groupRules: null,
      resetFieldsFlag: false,
      ruleForFieldArea: null,
      elasticity: 0,
      curve: "ABC"
    };
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  watch: {
    itsOpen: {
      handler(value) {
        if (value && !this.ruleForFieldArea) this.getRule(this.idRule);
      },
      immediate: true,
      deep: true,
    },
    item: {
      handler(value) {
        this.groupRules = value;
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    closePanel() {
      this.groupRules.reset();
      this.$emit("closePanel");
    },

    async getRule(idRule) {
      this.loadingRules = true;
      try {
        let response = await Service.getRule(this.idCompany, idRule);
        this.fillFields(response?.data?.answer ?? null);
        this.ruleForFieldArea = response?.data?.answer ?? null;
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_SEARCH_DATA_RULE_ERROR"),
          type: "error",
        });
      } finally {
        this.loadingRules = false;
      }
    },

    fillFields(rule) {
      this.groupRules.originalObj = structuredClone(rule);
      this.groupRules.idRule = rule.idRule;
      this.groupRules.description = rule.description;
      this.groupRules.numberOrder = rule.numberOrder;
      this.groupRules.isAllProducts = rule.isAllProducts;
      this.groupRules.conditions = rule.conditions;
    },

    async saveRules() {
      this.loadingSavingChanges = true;
      let request = this.groupRules.buildRequest(this.elasticity, this.curve);
      try {
        await Service.saveRule(request);
        this.groupRules.updateOriginalObject();
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_SAVED_RULES_SUCCESS"),
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_SAVED_RULES_ERROR"),
          type: "error",
        });
      }

      this.loadingSavingChanges = false;
    },
  },
};
</script>