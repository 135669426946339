var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4",attrs:{"disabled":_vm.isLoading,"id":"churn-table"}},[_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-spacer'),_c('prd-btn',{attrs:{"title":_vm.$t('TXT_EXTRACT_REPPORT'),"outlined":true,"leftIcon":true,"smallIcon":true,"icon":'mdi-tray-arrow-up'},on:{"click":_vm.extractReport}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.churnData,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pa-0 text-center"},[_c('div',{class:_vm.setBorder(item.probabilityStayingAlive)},[_vm._v(" "+_vm._s(item.client)+" ")])])]}},{key:"item.probabilityStayingAlive",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pa-0 text-center",class:_vm.setBackground(item.probabilityStayingAlive)},[_vm._v(" "+_vm._s(item.probabilityStayingAlive)+" % ")])]}},{key:"item.monetaryValue",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s((_vm.currency + " " + (item.monetaryValue)))+" ")])]}},{key:"header.client",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-box w-180 px-3"},[_c('div',[_vm._v(_vm._s(header.text))])])]}},{key:"header.frequency",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-box w-100 px-3"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('tooltip-informations',{attrs:{"rightIcon":true,"title":'Tooltip'}})],1)]}},{key:"header.recency",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-box w-100 px-3"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('tooltip-informations',{attrs:{"rightIcon":true,"title":'Tooltip'}})],1)]}},{key:"header.time",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-box w-100 px-3"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('tooltip-informations',{attrs:{"rightIcon":true,"title":'Tooltip'}})],1)]}},{key:"header.monetaryValue",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-box w-100 px-3"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('tooltip-informations',{attrs:{"rightIcon":true,"title":'Tooltip'}})],1)]}},{key:"header.purchaseForecast30",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-box w-180 px-3"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('tooltip-informations',{attrs:{"rightIcon":true,"title":'Tooltip'}})],1)]}},{key:"header.purchaseForecast60",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-box w-180 px-3"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('tooltip-informations',{attrs:{"rightIcon":true,"title":'Tooltip'}})],1)]}},{key:"header.purchaseForecast90",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-box w-180 px-3"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('tooltip-informations',{attrs:{"rightIcon":true,"title":'Tooltip'}})],1)]}},{key:"header.purchaseForecast120",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-box w-180 px-3"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('tooltip-informations',{attrs:{"rightIcon":true,"title":'Tooltip'}})],1)]}},{key:"header.probabilityStayingAlive",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"header-box w-180 px-3"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('tooltip-informations',{attrs:{"rightIcon":true,"title":'Tooltip'}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }