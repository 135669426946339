<template>
  <v-card class="pa-4" id="currentPrice" flat>
    <v-row no-gutters align="center" justify="space-between" class="mb-6">
      <div class="d-flex align-center gap-4">
        <Prd-Date-Picker
          :singleSelect="true"
          :hasTooltip="false"
          :externalDisabled="isLoading || featureTableLoading"
          @onInput="featureRefDate = $event"
        />

        <Prd-btn
          :title="'Feature table'"
          :icon="'mdi-download-outline'"
          :smallIcon="true"
          :leftIcon="true"
          :disabled="isLoading || featureTableLoading || !featureRefDate"
          :loading="featureTableLoading"
          @click="runFeatureTable"
          outlined
          class="mr-4 mt-7"
        />
      </div>
      <Prd-btn
        :title="'Exportar'"
        :icon="'mdi-download-outline'"
        :smallIcon="true"
        :leftIcon="true"
        :disabled="isLoading || !acelenList.length"
        @click="downloadData(acelenList, 'Preço_vigente')"
        class="mt-7"
      />
    </v-row>
    <v-data-table
      :headers="headers"
      :items="acelenList"
      :loading="isLoading"
      :footer-props="footerProps"
      :loading-text="'Carregando dados'"
      :no-data-text="$t('TXT_NO_DATA_FOUND_DISPLAY')"
      :no-results-text="$t('TXT_NO_DATA_FOUND_DISPLAY')"
      :items-per-page="10"
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ momentFormatDate(item.date, false) }}
      </template>

      <template v-slot:[`item.price`]="{ item }">
        {{ formatMonetary(item.price) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { downloadData } from "@/utils/downloadData.js";
import { momentFormatDate } from "@/utils/prd-format-date";
import { formatMonetary } from "@/utils/format-toMonetary";
import { downloadPDF } from "@/utils/downloadData";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import acelenService from "@/service/acelen/index";
import PrdDatePicker from "@/components/common/prd-date-picker.vue";
const Service = new acelenService();

export default {
  components: { PrdBtn, PrdDatePicker },

  data() {
    return {
      headers: [
        {
          text: "Data",
          align: "center",
          value: "date",
        },
        {
          text: "Combustivel",
          align: "center",
          value: "fuel",
        },
        {
          text: "Mobilidade",
          align: "center",
          value: "modality",
        },
        {
          text: "Local",
          align: "center",
          value: "local",
        },
        {
          text: "Valor vigente (R$)",
          align: "center",
          value: "price",
        },
      ],
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },

      isLoading: false,
      featureTableLoading: false,
      featureRefDate: false,
      acelenList: [],
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    momentFormatDate,
    formatMonetary,
    downloadData,
    downloadPDF,

    async getListAcelen() {
      this.$emit("setLoading", true);
      this.isLoading = true;

      try {
        let response = await Service.getListAcelen();
        this.acelenList = response?.data ?? [];
        this.acelenList.forEach((el) => {
          el.price = el.price / 1000;
        });
        this.$emit("onListChange", this.acelenList);
      } catch (error) {
        this.$handleError(error, "Erro ao buscar tabela de preço vigente");
      }

      this.isLoading = false;
      this.$emit("setLoading", false);
    },

    async runFeatureTable() {
      this.featureTableLoading = true;
      try {
        let response = await Service.getFeatureTable(
          this.idCompany,
          this.featureRefDate
        );

        this.downloadPDF(response.data, "featureTable");
      } catch (error) {
        this.$handleError(error, "Erro ao gerar feature table!");
      }
      this.featureTableLoading = false;
    },
  },

  created() {
    this.getListAcelen();
  },
};
</script>
