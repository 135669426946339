<template>
  <div class="store-chip">
    <span>{{ name }}</span>
  </div>
</template>

<script>
export default {
  props: { name: { type: String, require: true, default: "-" } },
};
</script>

<style lang="scss" scoped>
.store-chip {
  width: fit-content;
  height: fit-content;
  background-color: $neutral-color-high-medium;
  font-weight: bold;
  padding: 6px;
  border-radius: 8px;
  font-size: 14px;
}
</style>
