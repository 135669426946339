<template>
  <v-row no-gutters align="center" justify="center" class="border-row pa-2">
    <v-icon class="mr-4" size="28" :color="$prdStyles('color-warning')"
      >mdi-alert-outline</v-icon
    >
    <span>{{ $t("TXT_DELETE_AGROUPMENT_ALERT") }}</span>
  </v-row>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.border-row {
  border: 1px solid $feedback-color-warning-pure;
  border-radius: 3px;
  color: $feedback-color-warning-pure !important;
}
</style>