<template>
  <v-row no-gutters align="center" justify="space-between" class="gap-4">
    <v-col cols="6">
      <prd-text-field
        v-model="localAgroupmentMicro.nome"
        :title="$t('TXT_NAME')"
        :disabled="itsDisabled"
        :rules="[notHasDuplicatedNamesRule]"
      />
    </v-col>
    <v-col>
      <prd-text-field
        v-model="localAgroupmentMicro.grupoClientes"
        :title="$t('TXT_CLIENT_GROUP')"
        :disabled="itsDisabled"
        :rules="[notHasDuplicatedGrupoClientesRule]"
      />
    </v-col>
    <v-col>
      <prd-text-field
        v-model="localAgroupmentMicro.variacao"
        :title="$t('TXT_VARIATION')"
        :type="'number'"
        :hide-spin-buttons="true"
        :suffix="'%'"
        :disabled="itsDisabled"
      />
    </v-col>
    <v-icon
      v-if="index > 0"
      class="mt-7"
      size="28"
      :color="$prdStyles('color-error')"
      :disabled="itsDisabled"
      @click="$emit('removeAgroupmentMicro')"
      >mdi-trash-can-outline</v-icon
    >
  </v-row>
</template>

<script>
import prdTextField from "@/components/common/prd-text-field.vue";
export default {
  components: { prdTextField },
  props: {
    index: { type: Number, default: null },
    itsDisabled: {
      type: Boolean,
      default: false,
    },
    agroupmentMicro: {
      type: Object,
      default: () => {},
    },
    allAgroupmentsFiltered: { type: Array, default: () => [] },
    currentList: { type: Array, default: () => [] },
  },

  data() {
    return {
      localAgroupmentMicro: {
        id: null,
        nome: null,
        grupoClientes: null,
        variacao: null,
      },
    };
  },

  computed: {
    hasDuplicatedNames() {
      const hasDuplicatedNamesMicroFlag = this.checkDuplication(
        "nome",
        "agMicro"
      );
      this.$emit("hasDuplicatedNamesMicroFlag", hasDuplicatedNamesMicroFlag);

      return hasDuplicatedNamesMicroFlag;
    },

    hasDuplicatedGrupoClientes() {
      const hasDuplicatedGrupoClientesFlag = this.checkDuplication(
        "grupoClientes",
        "gpCliente"
      );
      this.$emit(
        "hasDuplicatedGrupoClientesFlag",
        hasDuplicatedGrupoClientesFlag
      );

      return hasDuplicatedGrupoClientesFlag;
    },
  },

  watch: {
    localAgroupmentMicro: {
      handler(value) {
        this.$emit("updateAgroupmentMicro", value);
      },
      deep: true,
    },
    agroupmentMicro: {
      handler(value) {
        this.localAgroupmentMicro.id = value?.id ?? null;
        this.localAgroupmentMicro.nome = value?.nome ?? null;
        this.localAgroupmentMicro.grupoClientes = value?.grupoClientes ?? null;
        this.localAgroupmentMicro.variacao = value?.variacao ?? null;
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    notHasDuplicatedNamesRule() {
      return !this.hasDuplicatedNames || this.$i18n.t("TXT_UTILIZED_NAME");
    },
    notHasDuplicatedGrupoClientesRule() {
      return (
        !this.hasDuplicatedGrupoClientes ||
        this.$i18n.t("TXT_UTILIZED_CLIENT_GROUP")
      );
    },
    checkDuplication(targetKey, externalKey) {
      const target = this.localAgroupmentMicro[targetKey]?.toLowerCase();

      if (!target) return false;

      const externalFlag = new Set(
        this.allAgroupmentsFiltered.map((ag) => ag[externalKey].toLowerCase())
      ).has(target);

      const internalFlag = new Set(
        this.currentList
          .map((ag) => ag[targetKey].toLowerCase())
          .filter((name, index) => index != this.index)
      ).has(target);

      return externalFlag || internalFlag;
    },
  },
};
</script>