var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4"},[_c('v-row',{attrs:{"no-gutters":"","justify":"space-between","align":"center"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("TXT_OPEN_TICKET")))]),_c('Prd-btn',{staticClass:"mr-4",attrs:{"title":_vm.$t('BTN_FREQUENT_QUESTIONS'),"outlined":true,"icon":'mdi-help-circle-outline',"leftIcon":true},on:{"click":function($event){return _vm.$router.push({
          name: 'faqs',
          params: {
            data: {
              previousPagePath: '/main/support',
            },
          },
        })}}})],1),_c('v-row',{staticClass:"mt-6 mb-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('Prd-text-field',{staticClass:"mr-4",attrs:{"title":_vm.$t('LBL_TICKET_TITLE'),"placeholder":_vm.$t('PLH_ENTER_TICKET_TITLE')},model:{value:(_vm.ticketTitle),callback:function ($$v) {_vm.ticketTitle=$$v},expression:"ticketTitle"}})],1),_c('v-col',[_c('Prd-combo-box',{staticClass:"mr-4",attrs:{"items":_vm.subjectItems,"disabled":_vm.subjectItems.length == 0 || _vm.subjectLoading,"loading":_vm.subjectLoading,"title":_vm.$t('LBL_SUBJECT'),"placeholder":_vm.$t('PLH_SELECT'),"itemText":'translatedDescription',"itemValue":'idSupport_TicketSubject'},model:{value:(_vm.selectedSubject),callback:function ($$v) {_vm.selectedSubject=$$v},expression:"selectedSubject"}})],1),_c('v-col',[_c('Prd-combo-box',{attrs:{"items":_vm.reasonItems,"disabled":!_vm.selectedSubject ||
          _vm.selectedSubject.value == 2 ||
          _vm.subjectLoading ||
          _vm.reasonItems.length == 0,"title":_vm.$t('LBL_REASON'),"placeholder":_vm.$t('PLH_SELECT'),"itemText":'translatedDescription',"itemValue":'idSupport_TicketSubject'},model:{value:(_vm.selectedReason),callback:function ($$v) {_vm.selectedReason=$$v},expression:"selectedReason"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('label',[_vm._v(_vm._s(_vm.$t("LBL_NEW_MESSAGE")))]),_c('v-textarea',{staticClass:"mt-2",attrs:{"placeholder":_vm.$t('PLH_WRITE_YOUR_MESSAGE'),"outlined":"","no-resize":"","hide-details":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1),_c('v-row',{staticClass:"mt-6",attrs:{"no-gutters":"","justify":"end"}},[_c('Prd-btn',{staticClass:"mr-4",attrs:{"title":_vm.$t('BTN_CANCEL'),"outlined":true},on:{"click":_vm.cleanInputs}}),_c('Confirm-ticket-modal',{attrs:{"ticketTitle":_vm.ticketTitle,"selectedSubject":_vm.selectedSubject,"selectedReason":_vm.selectedReason,"message":_vm.message},on:{"showTicketList":function($event){return _vm.$emit('showTicketList')}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }