<template>
  <div style="width: 100%">
    <span class="font-weight-bold">{{ $t("TXT_LICENSES") }}</span>
    <license-list :isLinkedList="true" :list="updatedList" class="my-6" />
    <license-list :isLinkedList="false" :list="updatedList" />
  </div>
</template>

<script>
import licenseList from "./components/license-list.vue";
export default {
  components: { licenseList },
  props: {
    updatedList: {
      type: Array,
    },
  },
};
</script>