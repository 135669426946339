<template>
  <div>
    <v-badge
      overlap
      :content="disregardedProducts.length"
      :color="$prdStyles('color-primary')"
      :value="disregardedProducts.length == 0 ? false : true"
    >
      <prd-btn
        :disabled="loading"
        :title="$t('TXT_SEE_DISREGARD')"
        :outlined="true"
        @click="isModalOpen = true"
      />
    </v-badge>

    <v-dialog v-model="isModalOpen" persistent>
      <v-card class="pa-4">
        <h4 class="mb-4">{{ $t("TXT_DISREGARD_PRODUCTS") }}</h4>
        <v-data-table
          :headers="headers"
          :items="disregardedProducts"
          :items-per-page="5"
        >
          <!-- product name -->
          <template v-slot:[`item.product_name`]="{ item }">
            <td class="table-product-name">
              <div class="copy-box">
                <v-icon
                  @click.stop="copyFunction(item.product_name)"
                  :color="$prdStyles('color-primary')"
                  left
                  small
                  class="copy-button"
                  >mdi-content-copy</v-icon
                >
                <a :href="item.product_link" target="_blank">
                  {{ item.product_name }}</a
                >
              </div>
            </td>
          </template>

          <!-- Price -->
          <template v-slot:[`item.price`]="{ item }">
            {{ setCurrency(item.price) }}
          </template>

          <!-- Installsment -->
          <template v-slot:[`item.quantity`]="{ item }">
            {{ item.quantity ? item.quantity + "x" : "-" }}
          </template>

          <!-- Indice deflator -->
          <template v-slot:[`item.deflated_value`]="{ item }">
            {{ setCurrency(item.deflated_value) }}
          </template>

          <!-- Crawler date -->
          <template v-slot:[`item.crawler_date`]="{ item }">
            <td class="text-center">
              {{
                item.crawler_date
                  ? item.crawler_date
                      .slice(0, 10)
                      .replace("-", "/")
                      .replace("-", "/")
                  : null
              }}
            </td>
          </template>

          <!-- tax ST -->
          <template v-slot:[`item.price_tax_st`]="{ item }">
            <td class="text-center">
              {{ item.price_tax_st ? item.price_tax_st : "-" }}
            </td>
          </template>

          <!-- Disregard -->
          <template v-slot:[`item.disregarded`]="{ item }">
            <td class="text-center">
              <v-icon
                v-if="!item.markToDisregard"
                :color="$prdStyles('color-primary')"
                :disabled="isLoading"
                @click="setItem(item)"
                >mdi-checkbox-outline</v-icon
              >
              <v-icon
                v-if="item.markToDisregard"
                :color="$prdStyles('color-primary')"
                :disabled="isLoading"
                @click="setItem(item)"
                >mdi-checkbox-marked</v-icon
              >
            </td>
          </template>
        </v-data-table>
        <v-row no-gutters justify="end" class="mt-2">
          <prd-btn
            :title="$t('TXT_CANCEL')"
            :disabled="isLoading"
            :outlined="true"
            @click="isModalOpen = false"
            class="mr-4"
          />
          <prd-btn
            :title="$t('TXT_SAVE')"
            :disabled="isLoading"
            :loading="isLoading"
            @click="setDisregard"
            class="mr-4"
          />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import { setCurrency } from "@/utils/format-currency";
import service from "@/service/predimonitor-V2/index.js";
const Service = new service();
export default {
  props: ["disregardedProducts", "loading"],
  components: { prdBtn },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      headers: [
        {
          text: this.$i18n.t("TXT_PRODUCT"),
          align: "center",
          sortable: true,
          value: "product_name",
          width: "300px",
        },
        {
          text: this.$i18n.t("TXT_STORE"),
          align: "center",
          sortable: true,
          value: "crawler_name",
        },
        {
          text: this.$i18n.t("TXT_SELLER"),
          align: "center",
          sortable: true,
          value: "seller_name",
        },
        {
          text: this.$i18n.t("TXT_PRICE"),
          align: "center",
          sortable: true,
          value: "price",
          width: "120px",
        },
        {
          text: this.$i18n.t("TXT_INSTALLMENTS"),
          align: "center",
          sortable: true,
          value: "quantity",
        },
        {
          text: `${this.$i18n.t("TXT_VALUE")} ST`,
          align: "center",
          sortable: true,
          value: "price_tax_st",
        },
        {
          text: this.$i18n.t("TXT_DEFLATOR_INDEX"),
          align: "center",
          sortable: true,
          value: "deflated_value",
          width: "120px",
        },
        {
          text: this.$i18n.t("TXT_PAYMENT"),
          align: "center",
          sortable: true,
          value: "price_payment_type",
        },
        {
          text: this.$i18n.t("TXT_COLECTION"),
          align: "center",
          sortable: true,
          value: "crawler_date",
        },
        {
          text: this.$i18n.t("TXT_DISREGARD_2"),
          align: "center",
          sortable: true,
          value: "disregarded",
        },
      ],
    };
  },
  computed: {
    idMonitoringItem() {
      return this.disregardedProducts[0]?.idMonitoringItem ?? null;
    },
  },
  methods: {
    setCurrency,

    setDisregard() {
      this.isLoading = true;

      let request = [];

      this.disregardedProducts.forEach((el) => {
        if (!el.markToDisregard) {
          request.push({
            disregarded: false,
            idMonitoringItem: this.idMonitoringItem,
            productLink: el.product_link,
            productSeller: el.seller_name,
          });
        }
      });

      Service.disregardItem(request)
        .then(() => {
          this.disregardedProducts.forEach((el) => {
            if (!el.markToDisregard) {
              el.disregarded = false;
            }
          });

          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_SUCCESS_UPDATE_PRODUCTS"),
            type: "success",
          });
          this.isModalOpen = false;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao desconsiderar", error);
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_ERROR_UPDATE_PRODUCTS"),
            type: "error",
          });
          this.isLoading = false;
        });
    },

    setItem(item) {
      this.disregardedProducts.forEach((el) => {
        if (
          el.crawler_name == item.crawler_name &&
          el.product_link == item.product_link &&
          el.product_name == item.product_name &&
          el.seller_name == item.seller_name
        ) {
          el.markToDisregard = !el.markToDisregard;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-product-name {
  max-width: 350px !important;
  padding: 0.8rem !important;
}
.copy-box {
  max-height: 40px !important;
  overflow: hidden !important;
}
</style>