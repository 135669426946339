 import i18n from "@/i18n";

 export const handlerResponseCode = (commit, code) => {
  if (!code || code == 200) return
  const getErrorStatus = () => ({
    message: i18n.t("TXT_FAIL_SEARCH_PRODUCT"),
    type: "error",
  })

  const getNotFoundStatus = () => ({
    message: i18n.t("TXT_NO_DATA_AVAILABLE"),
    type: "info",
  })

  const message = {
    500: getErrorStatus(),
    400: getErrorStatus(),
    404: getNotFoundStatus(),
    204: getNotFoundStatus(),
  }

  commit("snackbarV2/set", message[code])
}