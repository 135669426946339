<template>
  <v-card class="pa-4" elevation="4">
    <v-row no-gutters class="gap-2" align="center">
      <h4>{{ $t("TXT_PREMISES") }}</h4>
      <tooltip-informations :title="$t('TXT_PREMISES_TOOLTIP')" />
    </v-row>

    <v-row no-gutters>
      <v-col>
        <label>{{ $t("TXT_PRECIFICATION_METHODOLOGY") }}</label>
        <v-text-field
          readonly
          filled
          outlined
          dense
          class="mt-2"
          v-model="metodologiaPrecificacao"
        />
      </v-col>
      <v-col v-if="metodologiaPrecificacao == 'Custo'" class="ml-4">
        <label>{{ $t("TXT_CONTRIBUTION_MARGIN") }} (%)</label>
        <v-text-field
          readonly
          filled
          outlined
          dense
          class="mt-2"
          v-model="margemContribuicao"
        />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col v-if="product.capturas.length > 0">
        <label>{{ $t("TXT_POSITIONING") }}</label>
        <v-text-field
          readonly
          filled
          outlined
          dense
          class="mt-2 mr-4"
          v-model="posicionamento"
        />
      </v-col>
      <v-col>
        <label>{{ $t("TXT_PRICE_CORRIDOR") }} (%)</label>
        <v-text-field
          readonly
          filled
          outlined
          dense
          class="mt-2 mr-4"
          v-model="corredorPrecos"
        />
      </v-col>
      <v-col>
        <label>{{ $t("TXT_CHANNEL_COMPENSATION") }} (%)</label>
        <v-text-field
          readonly
          filled
          outlined
          dense
          class="mt-2"
          v-model="remuneracaoCanal"
        />
      </v-col>
    </v-row>

    <premissa-input-table v-bind:id="product.id" v-bind:product="product" />
  </v-card>
</template>

<script>
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";
import premissaInputTable from "./premissa-input-table.vue";
export default {
  components: { premissaInputTable, TooltipInformations },
  props: ["product"],
  data() {
    return {
      metodologiaPrecificacao: "",
      posicionamento: "",
      corredorPrecos: "",
      remuneracaoCanal: "",
      margemContribuicao: "",
    };
  },
  beforeMount() {
    if (this.product != null) {
      if (this.product.tipoPrecificacao != "Concorrente") {
        this.metodologiaPrecificacao = this.product.tipoPrecificacao;
      } else {
        this.metodologiaPrecificacao = this.product.estrategiaPrecificacao;
      }
      this.posicionamento = this.product.posicionamento;
      this.remuneracaoCanal = this.product.remuneracaoPorCanal;
      this.corredorPrecos = this.product.corredorPreco;
      this.margemContribuicao = this.product.margemContribuicao;
    }
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  flex-direction: column;
}
</style>