<template>
    <v-tooltip
        max-width="470px"
        color="#F5F5F5"
        style="pointer-events: all"
        right
    >
        <template v-slot:activator="{ on, attrs }">
            <v-icon
                style="cursor: pointer; pointer-events: all"
                v-bind="attrs"
                v-on="on"
                color="#6792F8"
                right
                small
            >
            mdi-information-outline</v-icon
        >
        </template>
        <span
        style="color: #000"
        v-html="$t(text)"
        ></span>
    </v-tooltip>
</template>

<script>
export default {
    name:'PRDToolTip',
    props:{
        text: String
    }
}
</script>