var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":_vm.items == [] || _vm.items.length == 0,"footer-props":_vm.footerProps,"no-data-text":_vm.$t('TXT_NO_DATA_AVAILABLE')},scopedSlots:_vm._u([(_vm.isCostPredimonitor)?{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"clickable",on:{"click":function($event){return _vm.setSelectedState(item.state)}}},[_vm._v(_vm._s(item.state ? item.state : _vm.$t("TXT_UNKNOW")))])]}}:{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.state ? item.state.toUpperCase() : _vm.$t("TXT_UNKNOW"))+" ")]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.city ? item.city.toUpperCase() : _vm.$t("TXT_UNKNOW"))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMonetary(item.price))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }