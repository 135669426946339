import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class SupportService extends ServiceBase {

    async getSubjects() {
        return await axiosInstance.get(API_URL + `api/V2/Support_Ticket/Subjects`)
    }

    async getTickets(idCompany, page, recordsPerPage) {
        /**
         * @param {Number} recordsPerPage
         * @param {Number} idCompany
         * @param {Number} page
         */
        return await axiosInstance.get(API_URL + `api/V2/Support_Ticket/List?idCompany${idCompany}&page=${page}&recordsPerPage=${recordsPerPage}`)
    }

    async getTicketDetails(idCompany, idTicket) {
        /**
         * @param {idTicket} recordsPerPage
         * @param {Number} idCompany
         */
        return await axiosInstance.get(API_URL + `api/V2/Support_Ticket/?idCompany=${idCompany}&idSupport_Ticket=${idTicket}`)
    }

    async createTicket(request) {
        /**
         * @param {Number} idCompany
         * @param {Number} idMotive
         * @param {Number} idSubject
         * @param {String} message
         * @param {String} title
         */
        return await axiosInstance.post(API_URL + `api/V2/Support_Ticket`, request)
    }

    async closeTicket(request) {
        /**
         * @param {Number} idCompany
         * @param {Number} idSupport_Ticket
         * @param {String} note
         */
        return await axiosInstance.put(API_URL + `api/V2/Support_Ticket`, request)
    }

    async insertMessage(request) {
        /**
         * @param {Number} idCompany
         * @param {Number} idSupport_Ticket
         * @param {String} message

         */
        return await axiosInstance.post(API_URL + `api/V2/Support_Ticket/Message`, request)
    }

}