<template>
  <div id="main-div">
    <div class="product_info">
      <span class="font-weight-bold">{{ monitoringTag.description }}</span>

      <div>
        <span class="font-size-14 mr-10 font-weight-bold">
          {{ this.$i18n.t("TXT_CODE") }}: {{ monitoringTag.getCode }}</span
        >
        <v-icon
          @click="isEdit = true"
          v-show="!isEdit"
          :color="$prdStyles('color-primary')"
          >mdi-pencil-box</v-icon
        >

        <v-icon
          @click="isEditClose"
          v-show="isEdit"
          :color="$prdStyles('color-warning')"
          >mdi-close-box-outline</v-icon
        >
        <v-icon @click="confirmEditItems" v-show="isEdit" color="#1D8527"
          >mdi-checkbox-marked</v-icon
        >
      </div>
    </div>

    <div class="product_cards">
      <!-- EXTERNAL SEARCH -->
      <div class="pa-4 card_info width">
        <v-row no-gutters>
          <h5 class="mr-2">{{ this.$i18n.t("TXT_NAME_AND_CHAR") }}</h5>
          <tooltip-informations v-if="false" :title="'teste'" />
        </v-row>

        <Not-found-item-message
          :text="$t('TXT_NAME_FEATURES_MISSING')"
          v-if="showNameFeaturesMissing"
        />

        <div v-for="(tag, index) in monitoringTag.configs" :key="index">
          <div
            v-if="!tag.isExternalSearch && !isEdit && !tag.isDeleted"
            class="flex"
          >
            <div class="product_description">
              {{ tag.description }}
            </div>
            <v-icon v-show="isEdit" :color="$prdStyles('color-warning')" small
              >mdi-delete-outline</v-icon
            >
          </div>

          <div
            v-if="!tag.isExternalSearch && isEdit && !tag.isDeleted"
            class="flex mt-2"
          >
            <v-text-field
              v-model="tag.description"
              dense
              outlined
              hide-details
            ></v-text-field>

            <v-icon
              @click="deleteMonitoringTag(monitoringTag.configs, tag, index)"
              v-show="isEdit"
              :color="$prdStyles('color-warning')"
              small
              >mdi-delete-outline</v-icon
            >
          </div>
        </div>

        <div v-show="isEdit" class="ma-2 ml-0">
          <v-icon @click="addMonitoringTag(false)" color="#1D8527"
            >mdi-plus-box</v-icon
          >
        </div>
      </div>

      <!-- INTERNAL SEARCH -->
      <div class="pa-4 card_info width">
        <v-row no-gutters>
          <h5 class="mr-2">{{ this.$i18n.t("TXT_NAME_AND_MARKETPLACE") }}</h5>
          <tooltip-informations v-if="false" :title="'teste'" />
        </v-row>

        <Not-found-item-message
          :text="$t('TXT_NAME_MARKETPLACE_MISSING')"
          v-if="showNameMarketplaceMissing"
        />

        <div v-for="(tag, index) in monitoringTag.configs" :key="index">
          <div
            v-if="tag.isExternalSearch && !isEdit && !tag.isDeleted"
            class="flex"
          >
            <div class="product_description">
              {{ tag.description }}
            </div>
            <v-icon v-show="isEdit" :color="$prdStyles('color-warning')" small
              >mdi-delete-outline</v-icon
            >
          </div>

          <div
            v-if="tag.isExternalSearch && isEdit && !tag.isDeleted"
            class="flex mt-2"
          >
            <v-text-field
              v-model="tag.description"
              dense
              outlined
              hide-details
            ></v-text-field>

            <v-icon
              @click="deleteMonitoringTag(monitoringTag.configs, tag, index)"
              v-show="isEdit"
              :color="$prdStyles('color-warning')"
              small
              >mdi-delete-outline</v-icon
            >
          </div>
        </div>
        <div class="ma-2 ml-0">
          <v-icon
            @click="addMonitoringTag(true)"
            v-show="isEdit"
            color="#1D8527"
            >mdi-plus-box</v-icon
          >
        </div>
      </div>

      <!-- PRICE RANGE -->
      <div class="pa-4 width">
        <v-row no-gutters>
          <h5 class="mr-2">{{ this.$i18n.t("TXT_PRICE_RANGE") }}</h5>
          <tooltip-informations v-if="false" :title="'teste'" />
        </v-row>

        <v-row no-gutters align="center" class="mt-4">
          <p class="pa-0 mr-3">{{ this.$i18n.t("TXT_ACTIVE_DESACTIVE") }}</p>
          <v-switch
            v-model="monitoringTag.usePriceRange"
            :color="$prdStyles('color-primary')"
            :disabled="!isEdit"
            dense
            inset
            flat
            class="mt-0"
          ></v-switch>
        </v-row>

        <div class="flex">
          <div>
            <label>{{ this.$i18n.t("TXT_MIN_PRICE") }}</label>
            <v-text-field
              v-model="monitoringTag.lowerPriceRange"
              :readonly="!isEdit"
              type="number"
              outlined
              dense
              class="mr-4"
              hide-details="auto"
              :prefix="currency"
              :placeholder="monitoringTag.lowerPriceRange == null ? '-' : ''"
              :filled="monitoringTag.lowerPriceRange == null"
            ></v-text-field>
          </div>
          <div>
            <label>{{ this.$i18n.t("TXT_MAX_PRICE") }}</label>
            <v-text-field
              v-model="monitoringTag.upperPriceRange"
              :readonly="!isEdit"
              type="number"
              outlined
              dense
              hide-details="auto"
              :prefix="currency"
              :placeholder="monitoringTag.upperPriceRange == null ? '-' : ''"
              :filled="monitoringTag.upperPriceRange == null"
            ></v-text-field>
          </div>
        </div>
      </div>
    </div>

    <div class="product_cards">
      <!-- REQUIRED WORDS -->
      <div class="pa-4 card_info width">
        <v-row no-gutters>
          <h5 class="mr-2">{{ this.$i18n.t("TXT_REQUIRED") }}</h5>
          <tooltip-informations v-if="false" :title="'teste'" />
        </v-row>
        <Not-found-item-message
          :text="$t('TXT_REQUIRED_WORDS_MISSING')"
          v-if="showRequiredWordsMissing"
        />
        <div v-if="monitoringTag.requiredWords && !isEdit" class="flex">
          <div class="product_description">
            {{ monitoringTag.requiredWords }}
          </div>
        </div>

        <div v-if="isEdit" class="flex mt-2">
          <v-text-field
            v-model="monitoringTag.requiredWords"
            dense
            outlined
            hide-details
          ></v-text-field>
        </div>
      </div>

      <!-- RESTRICTED WORDS -->
      <div class="pa-4 card_info width">
        <v-row no-gutters>
          <h5 class="mr-2">{{ this.$i18n.t("TXT_RESTRICTED") }}</h5>
          <tooltip-informations v-if="false" :title="'teste'" />
        </v-row>

        <Not-found-item-message
          :text="$t('TXT_RESTRICTED_WORDS_MISSING')"
          v-if="showRestrictedWordsMissing"
        />
        <div v-if="!isEdit" class="flex">
          <div v-if="monitoringTag.restrictedWords" class="product_description">
            {{ monitoringTag.restrictedWords }}
          </div>
        </div>

        <div v-if="isEdit" class="flex mt-2">
          <v-text-field
            v-model="monitoringTag.restrictedWords"
            dense
            outlined
            hide-details
          ></v-text-field>
        </div>
      </div>

      <!-- GROUP -->
      <div class="pa-4 width">
        <h5 class="mr-2">{{ this.$i18n.t("TXT_GROUP") }}</h5>
        <Not-found-item-message
          :text="$t('TXT_GROUP_ABSENT')"
          v-if="showGroupAbsent"
        />

        <div v-if="!isEdit && groups" class="product_description">
          <span>{{ groups }} </span>
        </div>
        <v-combobox
          v-if="isEdit && groups"
          v-model="selectedGroup"
          :items="groupList"
          :loading="groupListLoading"
          :disabled="groupListLoading || groupList.length == 0 || !isEdit"
          outlined
          dense
          class="mt-2"
          hide-details
          append-icon="mdi-chevron-down"
          item-text="description"
          :placeholder="groups"
          @change="isChangeGroup = true"
        >
        </v-combobox>
      </div>
    </div>
  </div>
</template>

<script>
import tooltipInformations from "@/Design_System/common/tooltip-informations.vue";
import service from "@/service/tags-config/index.js";
import NotFoundItemMessage from "./not-found-item-message.vue";

export default {
  components: { tooltipInformations, NotFoundItemMessage },
  props: ["product"],
  data() {
    return {
      service: new service(),
      isEdit: false,
      productCopy: [],
      groupList: [],
      groupListLoading: false,
      selectedGroup: null,
      isChangeGroup: false,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    currency() {
      return localStorage.getItem("currency") || "R$";
    },

    monitoringTag() {
      if (!this.product) return [];
      return this.product;
    },

    groups() {
      return this.monitoringTag?.groups[0]?.description ?? null;
    },
    showNameFeaturesMissing() {
      return (
        this.monitoringTag?.configs[0]?.description == null && !this.isEdit
      );
    },
    showNameMarketplaceMissing() {
      return (
        this.monitoringTag?.configs[0]?.isExternalSearch == null && !this.isEdit
      );
    },
    showRequiredWordsMissing() {
      return this.monitoringTag.requiredWords == null && !this.isEdit;
    },
    showRestrictedWordsMissing() {
      return this.monitoringTag.restrictedWords == null && !this.isEdit;
    },
    showGroupAbsent() {
      return this.groups == null && !this.isEdit;
    },
  },

  methods: {
    isEditClose() {
      this.isEdit = false;
      this.isChange = false;
      this.selectedGroup = null;
      this.$emit("callGet");
    },

    confirmEditItems() {
      this.$emit("setLoading", true);

      let request = this.buildRequest();

      this.isChangeGroup = false;
      this.selectedGroup = null;

      this.service
        .editMonitoringItems(request)
        .then((res) => {
          if (res.status != 500) {
            this.isEdit = false;
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_TAGS_ATT_SUCCESS"),
              type: "success",
            });
          } else {
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_TAGS_ATT_FAIL"),
              type: "error",
            });
          }
          this.$emit("setLoading", false);
        })
        .catch((error) => {
          console.log(error);
          this.$emit("setLoading", false);
        });
    },

    addMonitoringTag(externalType) {
      this.monitoringTag.configs.push({
        isExternalSearch: externalType,
        idMonitoringItem_Config: 0,
        isDeleted: false,
        description: "",
        ignoreNull: true,
        lifeTimeMonitoring: 0,
        crawlers: [
          {
            idCrawler: 0,
          },
        ],
      });
    },

    deleteMonitoringTag(monitoringArray, tag, index) {
      if (tag.idMonitoringItem_Config == 0) {
        monitoringArray.splice(index, 1);
      } else {
        tag.isDeleted = true;
      }
    },

    buildRequest() {
      let request = {
        idCompany: this.idCompany,
        monitoringItems: [this.monitoringTag],
        ignoreNull: true,
      };

      let upperPrice = request.monitoringItems[0].upperPriceRange;
      let lowerPrice = request.monitoringItems[0].lowerPriceRange;

      if (
        typeof upperPrice === "string" &&
        (upperPrice.includes(",") || upperPrice.includes("."))
      ) {
        upperPrice = upperPrice.replace(",", ".");
      }

      if (
        typeof lowerPrice === "string" &&
        (lowerPrice.includes(",") || lowerPrice.includes("."))
      ) {
        lowerPrice = lowerPrice.replace(",", ".");
      }

      request.monitoringItems[0].upperPriceRange = parseFloat(upperPrice);
      request.monitoringItems[0].lowerPriceRange = parseFloat(lowerPrice);

      if (this.verifyEmpty(request.monitoringItems[0].requiredWords)) {
        request.monitoringItems[0].requiredWords = null;
      }

      if (this.verifyEmpty(request.monitoringItems[0].restrictedWords)) {
        request.monitoringItems[0].restrictedWords = null;
      }

      request.monitoringItems[0].IsEditGroup = this.isChangeGroup;

      if (request.monitoringItems[0].IsEditGroup) {
        let newListGroups = [];

        let group = {
          description: this.selectedGroup.description,
          idMonitoringGroup: this.selectedGroup.idMonitoringGroup,
          idCompany: this.idCompany,
        };

        newListGroups.push(group);

        request.monitoringItems[0].groups = newListGroups;
      }

      request.monitoringItems[0].configs =
        request.monitoringItems[0].configs.filter((el) => {
          return el.description != "";
        });

      return request;
    },

    verifyEmpty(str) {
      return /^\s*$/.test(str);
    },

    getProductGroupList() {
      this.groupListLoading = true;
      this.service
        .getProductGroupList(this.idCompany)
        .then((res) => {
          this.groupList = res.data.result;
          this.groupListLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao gerar getProductGroupList", error);
          this.groupListLoading = false;
        });
    },
  },

  mounted() {
    this.getProductGroupList();
  },
};
</script>

<style lang="scss" scoped>
#main-div {
  .product_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $neutral-color-high-medium;
    padding: 8px;
    border-radius: 4px 4px 0px 0px;
    margin-top: 16px;
  }

  .product_cards {
    display: flex;
    gap: 16px;
    background-color: $neutral-color-high-light;
    padding: 16px;

    div {
      background-color: $neutral-color-high-pure;

      .product_description {
        background-color: $neutral-color-high-light;
        padding: 8px;
        margin: 8px;
        border-radius: 4px;
        width: 100%;
      }
    }
  }

  .card_info {
    width: 52% !important;
  }

  .width {
    width: 33%;
  }
}
</style>