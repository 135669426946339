import axios from "axios";
import store from "@/store";

const API_URL = process.env.VUE_APP_API
const API_URL_UPL = process.env.VUE_APP_UPL_URL
const API_URL_HOME_20 = process.env.VUE_APP_UPL_HOME
const API_URL_DATA_INSIGHT = process.env.VUE_APP_DATA_INSIGHT
const API_URL_PREDIMONITOR = process.env.VUE_APP_PREDIMONITOR_URL
const API_URL_RULEMANAGER = process.env.VUE_APP_RULEMANAGER

const api_v1 = "api/v1/";
const axiosInstance = axios.create({
  baseURL: API_URL,
});

const axiosRaw = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use(function (config) {
  const auth = store.getters.userData;
  if (auth) config.headers["Authorization"] = "Bearer " + auth.token;
  return config;
});



axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      let userData = {
        email: null,
        hasUser: false,
        rememberLogin: false,
        token: null,
        adminRoles: [],
        idCompany: null,
        id: null,
        username: null,
        phone: null,
      };

      store.dispatch("setUser", userData);
      window.location.href = "/login/sign-out";
    }
    return Promise.reject(error);
  }
);

export { axiosRaw, api_v1, API_URL, API_URL_UPL, API_URL_HOME_20, API_URL_DATA_INSIGHT, API_URL_PREDIMONITOR, API_URL_RULEMANAGER };

export default axiosInstance;
