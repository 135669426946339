<template>
  <v-row no-gutters align="center" class="mb-1">
    <v-col cols="3">
      <v-row
        no-gutters
        align="center"
        justify="center"
        class="font-weight-bold"
      >
        {{ $t("TXT_MIN_2") }}
      </v-row>
    </v-col>
    <v-col cols="3">
      <v-row
        no-gutters
        align="center"
        justify="center"
        class="font-weight-bold"
      >
        {{ $t("TXT_MAX_2") }}
      </v-row>
    </v-col>
    <v-col cols="3">
      <v-row
        no-gutters
        align="center"
        justify="center"
        class="font-weight-bold"
      >
        {{ $t("TXT_VALUE") }}
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>