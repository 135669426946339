<template>
  <div class="mt-4">
    <v-row no-gutters align="center" class="gap-4">
      <Prd-combo-box
        v-model="model.TypeReference"
        :title="$t('TXT_REFERENCE')"
        :items="TypeReferenceItems"
      />
      <Prd-text-field
        v-model="model.Range"
        :title="firstField"
        type="number"
        hide-spin-buttons
        min="0"
      />
      <Prd-text-field
        v-model="model.Range2"
        v-if="model.TypeReference.value == 3 || model.TypeReference.value == 4"
        :title="secondField"
        type="number"
        hide-spin-buttons
        min="0"
      />
      <Prd-combo-box
        v-model="model.AdjustMargin"
        :title="$t('TXT_ADJUSTABLE_TO_RANGE')"
        :items="AdjustMarginItems"
      />
    </v-row>
  </div>
</template>

<script>
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import PrdTextField from "@/components/common/prd-text-field.vue";
export default {
  props: ["index", "values", "idRule"],
  components: { PrdComboBox, PrdTextField },
  data() {
    return {
      TypeReferenceItems: [
        { text: this.$i18n.t("TXT_BIGGER_THAN"), value: 0 },
        { text: this.$i18n.t("TXT_LESS_THAN"), value: 1 },
        { text: this.$i18n.t("TXT_EQUAL_TO"), value: 2 },
        { text: this.$i18n.t("TXT_MAJOR_MINOR"), value: 3 },
        { text: this.$i18n.t("TXT_MINOR_MAJOR"), value: 4 },
      ],
      AdjustMarginItems: [
        { text: this.$i18n.t("TXT_YES"), value: true },
        { text: this.$i18n.t("TXT_NO"), value: false },
      ],
      model: {
        TypeReference: { text: this.$i18n.t("TXT_BIGGER_THAN"), value: 0 },
        AdjustMargin: { text: this.$i18n.t("TXT_NO"), value: false },
        Range: null,
        Range2: null,
      },
      fieldNames: [
        this.$i18n.t("TXT_BIGGER_THAN"),
        this.$i18n.t("TXT_LESS_THAN"),
        this.$i18n.t("TXT_EQUAL_TO"),
      ],
    };
  },
  computed: {
    firstField() {
      const index = this.model.TypeReference.value;
      return index <= 2
        ? this.fieldNames[index]
        : index == 3
        ? this.fieldNames[0]
        : index == 4
        ? this.fieldNames[1]
        : "";
    },
    secondField() {
      const index = this.model.TypeReference.value;
      return index == 3
        ? this.fieldNames[1]
        : index == 4
        ? this.fieldNames[0]
        : "";
    },
  },
  watch: {
    model: {
      handler(value) {
        this.updateConditions(value);
      },
      deep: true,
    },
    values: {
      handler(value) {
        if (value.values?.length > 0) this.setInputs(value.values);
      },
      immediate: true,
    },
  },
  methods: {
    updateConditions(inputs) {
      let formatedValue = [
        {
          stringValue: "TypeReference",
          decimalValue: inputs.TypeReference.value,
          booleanValue: false,
        },
        {
          stringValue: "AdjustMargin",
          decimalValue: 0,
          booleanValue: inputs.AdjustMargin.value,
        },
        {
          stringValue: "Range",
          decimalValue: parseFloat(inputs.Range) ?? 0,
          booleanValue: false,
        },
        {
          stringValue: "Range2",
          decimalValue: parseFloat(inputs.Range2) ?? 0,
          booleanValue: false,
        },
      ];
      this.$store.dispatch("updateConditions", {
        idRule: this.idRule,
        key: "updateConditionsDefault",
        conditionType: 10,
        index: this.index,
        values: formatedValue,
      });
    },
    setInputs(conditionList) {
      const TypeReference = conditionList.find(
        (condition) => condition.stringValue == "TypeReference"
      ).decimalValue;
      const TypeReferenceItem = this.TypeReferenceItems.find(
        (item) => item.value == TypeReference
      );
      this.model.TypeReference = TypeReferenceItem;

      const AdjustMargin = conditionList.find(
        (condition) => condition.stringValue == "AdjustMargin"
      ).booleanValue;
      const AdjustMarginItem = this.AdjustMarginItems.find(
        (item) => item.value == AdjustMargin
      );
      this.model.AdjustMargin = AdjustMarginItem;

      const Range = conditionList.find(
        (condition) => condition.stringValue == "Range"
      ).decimalValue;
      this.model.Range = Range;

      const Range2 = conditionList.find(
        (condition) => condition.stringValue == "Range2"
      ).decimalValue;
      this.model.Range2 = Range2;
    },
  },
};
</script>