<template>
  <v-card
    class="pa-4 pt-0"
    :disabled="priceCardLoading"
    :loading="priceCardLoading"
  >
    <h4 class="pt-4">{{ this.$i18n.t("TXT_TITLE_MODAL_PMD") }}</h4>
    <div class="product-info">
      <h4>{{ productName }}</h4>
      <div class="pr-5 my-flex">
        <div class="flex font-weight-bold">
          <v-icon color="#37B352" left small>mdi-arrow-up-bold-outline</v-icon>
          {{ productMaxPrice | setToIsoString }}
        </div>
        <div class="flex font-weight-bold">
          <v-icon color="#3C5CA7" left small>mdi-minus-circle-outline</v-icon>
          {{ productAvgPrice | setToIsoString }}
        </div>
        <div class="flex font-weight-bold">
          <v-icon color="#B00020" left small
            >mdi-arrow-down-bold-outline</v-icon
          >
          {{ productMinPrice | setToIsoString }}
        </div>
      </div>
    </div>

    <div class="card-space">
      <variation-card
        :title="this.$i18n.t('TXT_TITLE_MODAL_CARD_PRECO_MAX')"
        :icon="'mdi-arrow-up-bold-outline'"
        :tooltipInfo="this.$i18n.t('TXT_MAX_PRICE_INFO')"
        :resourceGroup="resourceGroup"
        :userList="userList"
        :cardAlert="maxPrice"
        :type="'maxPrice'"
        ref="maxPrice-card"
        @setValues="maxPrice = $event"
      />
      <variation-card
        :title="this.$i18n.t('TXT_TITLE_MODAL_CARD_PRECO_MD')"
        :icon="'mdi-minus-circle-outline'"
        :tooltipInfo="this.$i18n.t('TXT_AVG_PRICE_INFO')"
        :resourceGroup="resourceGroup"
        :userList="userList"
        :cardAlert="avgPrice"
        :type="'avgPrice'"
        ref="avgPrice-card"
        @setValues="avgPrice = $event"
      />
      <variation-card
        :title="this.$i18n.t('TXT_TITLE_MODAL_CARD_PRECO_MIN')"
        :icon="'mdi-arrow-down-bold-outline'"
        :tooltipInfo="this.$i18n.t('TXT_MIN_PRICE_INFO')"
        :resourceGroup="resourceGroup"
        :userList="userList"
        :cardAlert="minPrice"
        :type="'minPrice'"
        ref="minPrice-card"
        @setValues="minPrice = $event"
      />
    </div>
    <!-- CARD ACTIONS -->
    <v-card-actions class="pa-0 mt-6">
      <v-spacer></v-spacer>
      <prd-btn
        @click="cancelChanges"
        :outlined="true"
        :title="this.$i18n.t('TXT_CANCEL')"
        class="mr-4"
      />
      <prd-btn @click="saveChanges" :title="this.$i18n.t('TXT_SAVE_CHANGES')" />
    </v-card-actions>
  </v-card>
</template>

<script>
import PrdBtn from "../../../../../../../Design_System/common/prd-btn.vue";
import VariationCard from "./components/variation-card.vue";
import PredimonitorService from "@/service/predimonitor/price-alert-service";

export default {
  components: { PrdBtn, VariationCard },
  props: [
    "product",
    "priceAlertDisable",
    "resourceGroup",
    "userList",
    "priceCardLoading",
    "listaDeAlertas",
  ],
  data() {
    return {
      predimonitorService: new PredimonitorService(),

      maxPrice: null,
      avgPrice: null,
      minPrice: null,
      isLoading: false,
    };
  },

  computed: {
    productName() {
      if (!this.product) return null;

      return this.product.productName;
    },
    productMaxPrice() {
      if (!this.product) return null;

      return this.product.maxPrice;
    },
    productAvgPrice() {
      if (!this.product) return null;

      return this.product.avgPrice;
    },
    productMinPrice() {
      if (!this.product) return null;

      return this.product.minPrice;
    },
  },

  watch: {
    priceCardLoading() {
      if (this.priceCardLoading == true) {
        this.$emit("closeModal");
      }
    },
  },

  methods: {
    setAlertCardValues(alertList) {
      alertList.forEach((el) => {
        switch (el.monitoringType) {
          case 1:
            this.maxPrice = el;
            break;
          case 2:
            this.avgPrice = el;

            break;
          case 3:
            this.minPrice = el;

            break;
          default:
            break;
        }
      });
    },

    saveChanges() {
      let data = [];
      data.push(this.maxPrice);
      data.push(this.minPrice);
      data.push(this.avgPrice);

      let requests = data.filter((el) => el).map((el) => this.buildRequest(el));

      Promise.all(
        requests.map((request) =>
          this.predimonitorService.savePriceAlert(request)
        )
      )
        .then((responses) => {
          if (responses.every((response) => response.status !== 500)) {
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_ITEM_SAVE"),
              type: "success",
            });
          } else {
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_ITEM_SAVE_PROBLEM"),
              type: "error",
            });
          }
          this.$emit("seila");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    cancelChanges() {
      this.$emit("closeModal");
    },

    buildRequest(el) {
      let request = {};
      let resourceGroup = [];

      request = {
        idMonitoringItemPriceAlert: el.idMonitoringItemPriceAlert
          ? el.idMonitoringItemPriceAlert
          : 0,
        idMonitoringItem: this.product.idMonitoringItem,
        productLink: null,
        productSeller: null,
        variationType: el.variationType.value,
        monitoringType: this.setMonitoringType(el.monitoringType),
        increaseValue: parseFloat(el.increaseValue),
        decreaseValue: parseFloat(el.decreaseValue),
        isParentItem: true,
        userOrResouceGroup: [],
        isDeleted: el.isDeleted,
      };

      el.users?.forEach((item) => {
        let teste = {};
        teste = {
          idUser: item.idResourceGroup ? null : item.idUsuario,
          idResourceGroup: item.idResourceGroup ? item.idResourceGroup : null,
          idMonitoringItemPriceAlert: el.idMonitoringItemPriceAlert
            ? el.idMonitoringItemPriceAlert
            : 0,
          idMonitoringItemPriceAlertUsers: item.idMonitoringItemPriceAlertUsers
            ? item.idMonitoringItemPriceAlertUsers
            : 0,
          isDeleted: item.isDeleted,
        };

        resourceGroup.push(teste);
      });

      request.userOrResouceGroup = resourceGroup;
      return request;
    },

    setMonitoringType(item) {
      switch (item) {
        case "maxPrice":
          return 1;
        case "avgPrice":
          return 2;
        case "minPrice":
          return 3;
        default:
          return null;
      }
    },

    deepCopy(obj) {
      let newObj = {};
      newObj = Object.assign({}, obj);
      newObj.configs = [];
      obj.configs.forEach((el) => {
        newObj.configs.push(Object.assign({}, el));
      });
      return newObj;
    },
    setDataToCard() {},
  },

  filters: {
    setToIsoString: function (value) {
      if (!value) return "$ 0,00";

      let formatedValue = value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatedValue;
    },
  },

  created() {
    if (this.listaDeAlertas) {
      this.setAlertCardValues(this.listaDeAlertas);
    }
  },
};
</script>

<style lang="scss" scoped>
.product-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 8px;
  margin: 16px 0px;
}

.card-space {
  display: flex;
  gap: 8px;
}

.my-flex {
  display: flex;
  align-items: center;
  gap: 1rem;
}
</style>