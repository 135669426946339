<template>
  <v-expansion-panel-content>
    <v-divider></v-divider>
    <div :class="setElasticityHeight(useNewElasticity)">
      <div class="first-column">
        <!-- PRODUCT INFO -->
        <product-info
          :infoProjection="infoProjection"
          :infoProjectionLoading="infoProjectionLoading"
        />

        <!-- ELASTICITY AREA -->
        <v-card flat class="pa-4">
          <v-row no-gutters align="center" justify="space-between">
            <h4 class="mb-4">ELASTICIDADE</h4>
            <v-switch
              v-if="isPredifyAdmin"
              v-model="useNewElasticity"
              class="mt-0 pa-0"
            ></v-switch>
          </v-row>

          <elasticity-card
            v-if="!useNewElasticity"
            :elasticity="elasticity"
            :elasticityLoading="elasticityLoading"
            class="elasticity"
          />

          <new-elasticity-card
            v-if="useNewElasticity"
            :elasticity="elasticity"
            :elasticityLoading="elasticityLoading"
          />
        </v-card>
      </div>

      <!-- MARKET RESULT -->
      <div class="table-column">
        <marketplace-card
          :marketResult="marketResult"
          :marketResultLoading="marketResultLoading"
        />
      </div>

      <div class="table-column">
        <!-- STATE -->
        <state-data
          :tableComparison="tableComparison"
          :tableComparisonLoading="tableComparisonLoading"
        />
      </div>
    </div>

    <div style="display: flex; gap: 8px">
      <similarity-card
        :tableSimilarity="tableSimilarity"
        :tableSimilarityLoading="tableSimilarityLoading"
        :idEnterprisePricesProjection="product.idEnterprisePricesProjection"
        @callSimiliarityTable="getSimiliarityTable"
        style="width: 50%"
      />
      <reply-pricing
        style="width: 50%"
        :tableReply="tableReply"
        :tableReplyLoading="tableReplyLoading"
        :idPriceGroup="product.idEnterprisePriceGroups"
        :idPriceProjection="product.idEnterprisePricesProjection"
        :headerPricing="headerPricing"
        @callReplyTable="getTableReply"
      />
    </div>
  </v-expansion-panel-content>
</template>

<script>
import ElasticityCard from "./components/elasticity-card.vue";
import NewElasticityCard from "./components/new-elasticity-card.vue";
import MarketplaceCard from "./components/marketplace-card.vue";
import ProductInfo from "./components/product-info.vue";
import ReplyPricing from "./components/reply-pricing.vue";
import SimilarityCard from "./components/similarity-card.vue";
import StateData from "./components/state-data.vue";
import { mapGetters, mapMutations } from "vuex";

import service from "@/service/new-ai-pricing/panel.js";
const Service = new service();
export default {
  props: [
    "idEnterprisePriceGroups",
    "product",
    "setProductContent",
    "headerPricing",
    "isPanelOpen",
  ],
  components: {
    ProductInfo,
    ElasticityCard,
    MarketplaceCard,
    StateData,
    SimilarityCard,
    ReplyPricing,
    NewElasticityCard,
  },
  data() {
    return {
      elasticity: null,
      marketResult: [],
      infoProjection: [],
      tableComparison: [],
      tableSimilarity: [],
      tableReply: [],

      elasticityLoading: false,
      marketResultLoading: false,
      infoProjectionLoading: false,
      tableComparisonLoading: false,
      tableSimilarityLoading: false,
      tableReplyLoading: false,

      useNewElasticity: false,
    };
  },
  watch: {
    setProductContent() {
      if (this.setProductContent) {
        this.init();
        this.$emit("setProductContent");
      }
    },

     replicateItems: {
      async handler(value) {
        const foundedItem = value.find((id) => {
          return this.product.idEnterprisePricesProjection == id;
        });

        if (foundedItem) {
          await this.getTableReply();
          this.removeTeste(foundedItem)
        }
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters(["replicateItems"]),

    isPredifyAdmin() {
      return this.$store.getters.isPredifyAdmin;
    },
  },

  methods: {
    ...mapMutations(["removeTeste"]),

    getElasticity() {
      this.elasticityLoading = true;
      Service.getElasticity(
        this.product.idEnterprisePriceGroups,
        this.product.idEnterprisePricesProjection
      )
        .then((res) => {
          this.elasticity = res.data.result;
          this.elasticityLoading = false;
        })
        .catch((error) => {
          console.log("Erro no buscar elasticidade", error);
          this.elasticityLoading = false;
        });
    },

    getMarketResult() {
      this.marketResultLoading = true;

      Service.getMarketResult(this.product.idEnterprisePricesProjection)
        .then((res) => {
          this.marketResult = res.data.result;
          this.marketResultLoading = false;
        })
        .catch((error) => {
          console.log("erro buscar market result", error);
          this.marketResultLoading = false;
        });
    },

    getInfoProjection() {
      this.infoProjectionLoading = true;

      Service.getInfoProjection(this.product.idEnterprisePricesProjection)
        .then((res) => {
          this.infoProjection = res.data.result.listGeneric;
          this.infoProjectionLoading = false;
        })
        .catch((error) => {
          console.log("erro buscar info projection", error);
          this.infoProjectionLoading = false;
        });
    },

    getTableComparison() {
      this.tableComparisonLoading = true;
      Service.getTableComparison(
        this.product.idEnterprisePriceGroups,
        this.product.idEnterprisePricesProjection
      )
        .then((res) => {
          this.tableComparison = res.data.result;
          this.tableComparison.sort((a, b) => {
            const nameA = a.destiny.toUpperCase();
            const nameB = b.destiny.toUpperCase();

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            return 0;
          });
          this.tableComparisonLoading = false;
        })
        .catch((error) => {
          console.log("erro buscar tablecomparison", error);
          this.tableComparisonLoading = false;
        });
    },

    getSimiliarityTable() {
      this.tableSimilarityLoading = true;
      Service.getSimiliarityTable(this.product.idEnterprisePricesProjection)
        .then((res) => {
          this.tableSimilarity = res.data.result;
          this.tableSimilarityLoading = false;
        })
        .catch((error) => {
          console.log("Erro tabela de similiaridade", error);
          this.tableSimilarityLoading = false;
        });
    },

    getTableReply() {
      this.tableReplyLoading = true;
      Service.getTableReply(this.product.idEnterprisePricesProjection)
        .then((res) => {
          this.tableReply = res.data.result;
          this.tableReplyLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao pegar replicação", error);
          this.tableReplyLoading = false;
        });
    },

    init() {
      this.getElasticity();
      this.getMarketResult();
      this.getInfoProjection();
      this.getTableComparison();
      this.getSimiliarityTable();
      this.getTableReply();
    },

    setElasticityHeight(value) {
      if (!value) return "product-main min-height";
      else return "product-main max-height";
    },
  },

  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.product-main {
  display: flex;
  gap: 8px;
}
.first-column {
  display: flex;
  flex-direction: column;

  gap: 8px;
  width: 29%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.table-column {
  width: 36.5%;
  overflow: scroll;
  height: 100%;
  background-color: white;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.min-height {
  height: 550px;
}
.max-height {
  height: 900px;
}
</style>
