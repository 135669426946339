<template>
  <v-container fluid>
    <titleBreadCrumbs
      :title="$t('TXT_PAYMENT_HISTORY').toUpperCase()"
      :hasTooltip="false"
    />
    <v-card class="pa-4">
      <v-row no-gutters class="mb-4">
        <v-col cols="auto">
          <Prd-combo-box
            :title="$t('TXT_STATUS')"
            :items="enumStatus"
            :placeholder="$t('TXT_ALL')"
            @input="filterItems"
          />
        </v-col>
      </v-row>
      <Prd-loading-circular v-if="isLoading" />
      <v-data-table
        v-else
        :headers="headers"
        :items="filteredItems"
        :hide-default-footer="filteredItems.length <= 5"
        :footer-props="footerProps"
        :no-data-text="$t('TXT_NO_DATA_AVAILABLE')"
      >
        <template v-slot:[`item.dataSolicitacaoPagamento`]="{ item }">
          {{
            formatDate(new Date(item.dataSolicitacaoPagamento), "dd/MM/yyyy")
          }}
        </template>
        <template v-slot:[`item.dataPagamentoEfetivacao`]="{ item }">
          {{ formatDate(new Date(item.dataPagamentoEfetivacao), "dd/MM/yyyy") }}
        </template>
        <template v-slot:[`item.dataPagamentoExpiracao`]="{ item }">
          {{ formatDate(new Date(item.dataPagamentoExpiracao), "dd/MM/yyyy") }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          {{ setStatus(item.status) }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import HeaderService from "@/service/header/index.js";
import { formatDate } from "@/utils/format-date.js";
import titleBreadCrumbs from "@/Design_System/common/title.vue";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";

export default {
  components: {
    titleBreadCrumbs,
    PrdLoadingCircular,
    PrdComboBox,
  },
  data() {
    return {
      service: new HeaderService(),
      isLoading: false,
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
      headers: [
        {
          text: this.$i18n.t("TXT_PLAN"),
          value: "plano",
          align: "center",
        },
        {
          text: this.$i18n.t("TXT_DATE_REQUEST"),
          value: "dataSolicitacaoPagamento",
          align: "center",
        },
        {
          text: this.$i18n.t("TXT_PAYMENT_DATE"),
          value: "dataPagamentoEfetivacao",
          align: "center",
        },
        {
          text: this.$i18n.t("TXT_ACCESS_TO"),
          value: "dataPagamentoExpiracao",
          align: "center",
        },
        {
          text: this.$i18n.t("TXT_STATUS"),
          value: "status",
          align: "center",
        },
      ],
      enumStatus: [
        this.$i18n.t("TXT_WAITING"),
        this.$i18n.t("TXT_COMUNICATION_ERROR"),
        this.$i18n.t("TXT_GATEWAY_ERROR"),
        this.$i18n.t("TXT_PAYMENT_EFFECTED"),
        this.$i18n.t("TXT_SUSPENDED"),
        this.$i18n.t("TXT_EXPIRED_2"),
        this.$i18n.t("TXT_MAKE_PAYMENT"),
        this.$i18n.t("TXT_PROCESSING"),
        this.$i18n.t("TXT_PAYMENT_ERROR"),
        this.$i18n.t("TXT_UNSUBSCRIBING"),
        this.$i18n.t("TXT_ALL"),
      ],
      items: [],
      filteredItems: [],
    };
  },
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },
  methods: {
    formatDate,
    getAllPayments() {
      this.isLoading = true;
      this.service
        .GetAllPayments(this.idCompany)
        .then((res) => {
          if (res.status == 200) {
            this.items = res.data.pagamentos;
            this.filteredItems = res.data.pagamentos;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setStatus(number) {
      return this.enumStatus[number];
    },
    filterItems(item) {
      let filteredArray = JSON.parse(JSON.stringify(this.items));
      if (item != this.$i18n.t("TXT_ALL")) {
        const index = this.enumStatus.findIndex((status) => status == item);
        if (index != -1)
          filteredArray = filteredArray.filter((item) => item.status == index);
      }
      this.filteredItems = [];
      this.filteredItems = filteredArray;
    },
  },
  mounted() {
    if (this.idCompany) this.getAllPayments();
  },
};
</script>