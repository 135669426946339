<template>
  <v-card style="width: 33%" class="pa-4">
    <v-row no-gutters>
      <v-icon :color="setIconColor(icon)" left small>{{ icon }}</v-icon>
      <h4>{{ title }}</h4>
      <tooltip-informations :title="tooltipInfo" :rightIcon="true" />
    </v-row>
    <!-- SWITCH -->
    <v-row no-gutters align="center" class="mt-4">
      <p class="pa-0 mr-3 font-weight-bold">
        {{ this.$i18n.t("TXT_ACTIVE_DESACTIVE") }}
      </p>
      <v-switch
        v-model="isNotDeleted"
        :color="$prdStyles('color-primary')"
        dense
        inset
        flat
        class="mt-0"
      ></v-switch>
    </v-row>

    <div v-if="!data.isDeleted">
      <!-- VARIATION TYPE -->
      <v-row no-gutters class="mb-4">
        <v-col>
          <prd-combo-box
            v-model="data.variationType"
            :items="variationTypeItems"
            :title="this.$i18n.t('TXT_SELECT_VARIATION')"
          />
        </v-col>
      </v-row>

      <v-row no-gutters class="mb-4">
        <!-- INCREASE-->
        <v-col class="mr-4">
          <label>{{
            data.variationType.value == 1
              ? this.$i18n.t("TXT_PERCENTAGE_INCREASE")
              : this.$i18n.t("TXT_VALUE_INCREASE")
          }}</label>

          <v-text-field
            v-model="data.increaseValue"
            :prefix="data.variationType.value == 1 ? '%' : '$'"
            type="number"
            :rules="numberRules"
            @keydown="blockUserType"
            dense
            outlined
            hide-details="auto"
            hide-spin-buttons
            class="mt-1"
          >
          </v-text-field>
        </v-col>

        <!-- DECREASE-->
        <v-col>
          <label>{{
            data.variationType.value == 1
              ? this.$i18n.t("TXT_PERCENTAGE_DROP")
              : this.$i18n.t("TXT_VALUE_DROP")
          }}</label>
          <v-text-field
            v-model="data.decreaseValue"
            :prefix="data.variationType.value == 1 ? '%' : '$'"
            type="number"
            :rules="numberRules"
            @keydown="blockUserType"
            dense
            outlined
            hide-details="auto"
            hide-spin-buttons
            class="mt-1"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <!-- RESOURCE  GROUP -->
      <v-row no-gutters class="pa-0 ma-0">
        <v-col>
          <label>
            {{ this.$i18n.t("TXT_SELECT_USER_GROUP") }}
          </label>
          <v-combobox
            v-model="data.users"
            :items="resourceGroupArray"
            outlined
            dense
            append-icon="mdi-chevron-down"
            hide-details
            multiple
            deletable-chips
          >
            <template v-slot:[`item`]="{ item }">
              <v-icon left color="#FFC700">{{
                !item.idResourceGroup
                  ? "mdi-account-outline"
                  : "mdi-account-group-outline"
              }}</v-icon>
              {{ item.name }}
            </template>

            <template v-slot:[`selection`]="{ item, index }">
              <v-chip
                v-if="!item.isDeleted"
                :key="
                  item.idResourceGroup ? item.idResourceGroup : item.idUsuario
                "
                label
                close
                @click:close="removeProduct(item, index)"
              >
                <v-icon left color="#FFC700">
                  {{
                    !item.idResourceGroup
                      ? "mdi-account-outline"
                      : "mdi-account-group-outline"
                  }}
                </v-icon>
                {{ item.name }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import tooltipInformations from "@/Design_System/common/tooltip-informations.vue";
import prdComboBox from "../../../../../../../../Design_System/common/prd-comboBox.vue";

export default {
  props: [
    "title",
    "icon",
    "tooltipInfo",
    "resourceGroup",
    "type",
    "userList",
    "cardAlert",
  ],
  components: { prdComboBox, tooltipInformations },
  data() {
    return {
      variationTypeItems: [
        {
          text: this.$i18n.t("TXT_PERCENTAGE"),
          value: 1,
        },
        {
          text: this.$i18n.t("TXT_ABSOLUTE_VALUE"),
          value: 0,
        },
      ],

      data: {
        increaseValue: null,
        decreaseValue: null,
        variationType: {
          text: this.$i18n.t("TXT_ABSOLUTE_VALUE"),
          value: 0,
        },
        users: [],
        monitoringType: this.type,
        isDeleted: true,
      },
      numberRules: [
        (v) => v?.length <= 6 || this.$i18n.t("TXT_MAX_ALLOWED_CHARACTERS"),
      ],
    };
  },

  computed: {
    isNotDeleted: {
      get() {
        return !this.data.isDeleted;
      },
      set(value) {
        this.data.isDeleted = !value;
      },
    },

    resourceGroupArray() {
      if (!this.resourceGroup || !this.userList) return [];
      let data = [];

      let newUserList = this.userList.map((obj) => {
        return {
          name: obj.nome,
          idUsuario: obj.idUsuario,
          isDeleted: false,
          idUser: null,
          idMonitoringItemPriceAlert: null,
          idMonitoringItemPriceAlertUsers: null,
        };
      });

      let newGroupList = this.resourceGroup.map((obj) => {
        return {
          idResourceGroup: obj.idResourceGroup,
          name: obj.name,
          isDeleted: false,
          idUser: null,
          idMonitoringItemPriceAlert: null,
          idMonitoringItemPriceAlertUsers: null,
        };
      });

      newGroupList.forEach((el) => {
        data.push(el);
      });

      newUserList.forEach((el) => {
        data.push(el);
      });
      return data;
    },
  },

  watch: {
    cardAlert: {
      handler(newValue) {
        if (newValue) this.updateData(newValue);
      },
      immediate: true,
    },
    isNotDeleted(newValue) {
      this.data.isDeleted = !newValue;
    },

    data: {
      handler(newValue) {
        this.$emit("setValues", newValue);
      },
      deep: true,
    },
  },

  methods: {
    setIconColor(icon) {
      switch (icon) {
        case "mdi-arrow-up-bold-outline":
          return "#56a667";

        case "mdi-minus-circle-outline":
          return "#3c5ca7";

        case "mdi-arrow-down-bold-outline":
          return "#b00020";
      }
    },

    updateData(newValue) {
      if (newValue) {
        this.data = newValue;
        this.data.variationType = this.setVariationType(newValue.variationType);
        this.data.monitoringType = this.type;
        this.isNotDeleted = true;

        this.data.users = newValue.users?.map((user) => {
          let name = "";
          if (user.idUser) {
            const matchingResourceGroup = this.resourceGroupArray.find(
              (rg) => rg.idUsuario === user.idUser
            );
            if (matchingResourceGroup) {
              name = matchingResourceGroup.name;
            }
          } else if (user.idResourceGroup) {
            const matchingResourceGroup = this.resourceGroupArray.find(
              (rg) => rg.idResourceGroup === user.idResourceGroup
            );
            if (matchingResourceGroup) {
              name = matchingResourceGroup.name;
            }
          }

          return {
            idResourceGroup: user.idResourceGroup,
            idUser: user.idUser,
            name: name,
            idMonitoringItemPriceAlert: user.idMonitoringItemPriceAlert,
            idMonitoringItemPriceAlertUsers:
              user.idMonitoringItemPriceAlertUsers,
            isDeleted: false,
          };
        });
      }
    },

    setVariationType(type) {
      let data = {
        text:
          type == 1
            ? this.$i18n.t("TXT_PERCENTAGE")
            : this.$i18n.t("TXT_ABSOLUTE_VALUE"),
        value: type == 1 ? 1 : 0,
      };

      return data;
    },

    removeProduct(item, index) {
      if (
        !item.idMonitoringItemPriceAlert &&
        !item.idMonitoringItemPriceAlertUsers
      ) {
        if (index >= 0) this.data.users.splice(index, 1);
      } else {
        item.isDeleted = true;
      }
    },

    blockUserType(event) {
      if (
        this.data.increaseValue != null &&
        this.data.increaseValue.length >= 7 &&
        event.key !== "Backspace"
      ) {
        event.preventDefault();
      } else if (
        this.data.decreaseValue != null &&
        this.data.decreaseValue.length >= 7 &&
        event.key !== "Backspace"
      ) {
        event.preventDefault();
      }
    },
  },
};
</script>


<style lang="scss" scoped>
</style>