<template>
  <div class="airport-sign my-1">
    <p v-if="variaveisTributacao != null" id="showVariaveisTributacao">
      <span class="font-weight-bold mr-1"> {{ $t("TXT_FREIGHT") }}: </span>
      <span class="font-weight-bold" style="color: #3c5ca7; margin-right: 15%">
        {{ variaveisTributacao.frete }} %
      </span>

      <span class="font-weight-bold mr-1"> {{ $t("TXT_DOLLAR") }}: </span>
      <span class="font-weight-bold" style="color: #3c5ca7; margin-right: 15%">
        {{ setCurrencyV3(variaveisTributacao.dolar) }}
      </span>

      <span class="font-weight-bold mr-1"> {{ $t("TXT_REAL_TAX") }}: </span>
      <span class="font-weight-bold" style="color: #3c5ca7; margin-right: 15%">
        {{ variaveisTributacao.jurosBRL }} %
      </span>

      <span class="font-weight-bold mr-1"> {{ $t("TXT_DOLLAR_TAX") }}: </span>
      <span class="font-weight-bold" style="color: #3c5ca7; margin-right: 15%">
        {{ variaveisTributacao.jurosUSD }} %
      </span>

      <span class="font-weight-bold mr-1"> {{ $t("TXT_TAX") }}: </span>
      <span class="font-weight-bold" style="color: #3c5ca7; margin-right: 15%">
        {{ variaveisTributacao.imposto }} %
      </span>

      <span class="font-weight-bold mr-1"> {{ $t("TXT_PERIOD") }}: </span>
      <span class="font-weight-bold" style="color: #3c5ca7; margin-right: 30%">
        {{ `${variaveisTributacao.periodoMedio} ${$t("TXT_MONTHS")}` }}
      </span>
    </p>
  </div>
</template>

<script>
import { setCurrencyV3 } from "@/utils/format-currency-v3";
export default {
  props: ["variaveisTributacao"],
  methods: {
    setCurrencyV3,
  },
};
</script>
<style lang="scss" scoped>
.airport-sign {
  width: 100%;
  height: 25px;
  overflow: hidden;
  position: relative;
  color: black;
}

#showVariaveisTributacao {
  position: absolute;
  white-space: nowrap;
  font-size: 15px;
  animation: marquee 25s linear infinite;

  &:hover {
    animation-play-state: paused;
  }
}

@keyframes marquee {
  0% {
    left: 100%;
  }

  100% {
    left: -100%;
  }
}
</style>