<template>
  <v-container fluid>
    <v-row no-gutters align="center">
      <TitleBreadCrumbsComponent
        :breadCrumbs="breadcrumbs"
        :title="$t('TXT_TAG_CONFIGURATION_UPPERCASE')"
        :showTooltip="false"
        :hasTooltip="false"
      />
      <v-spacer></v-spacer>
      <Prd-btn
        :icon="'mdi-arrow-left'"
        :leftIcon="true"
        :title="$t('TXT_BACK')"
        @click="backPredimonitor"
        class="mt-14"
      />
    </v-row>

    <v-card
      :class="`pa-4 ${isLoading ? 'pt-0' : ''}`"
      :loading="isLoading"
      :disabled="isLoading"
    >
      <v-row
        no-gutters
        align="center"
        justify="space-between"
        :class="`${isLoading ? 'mt-4' : ''}`"
      >
        <h3>TAGS</h3>
        <v-col cols="auto">
          <v-row no-gutters class="gap-4">
            <Importation-status
              :disableTagImport="disableTagImport"
              ref="importationStatus"
            />

            <Prd-btn
              :leftIcon="true"
              :icon="'mdi-download-outline'"
              :title="$t('TXT_EXPORT')"
              :loading="disableTag"
              :disabled="disableTag || disableTagImport || isLoading"
              @click="exportTags"
            />

            <Prd-btn
              :leftIcon="true"
              :icon="'mdi-upload-outline'"
              :title="$t('TXT_TO_IMPORT')"
              :disabled="disableTagImport || disableTag || isLoading"
              :loading="disableTagImport"
              @click="$refs.fileInput.click()"
            />
            <input
              ref="fileInput"
              type="file"
              @change="importFile"
              style="display: none"
            />
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters justify="space-between" align="end" class="my-6">
        <Prd-search
          v-model="search"
          :searchLabel="$t('TXT_SEARCH_TAGS')"
          :searchPlaceholder="$t('TXT_SEARCH')"
          :disabled="products.length == 0"
        />

        <Prd-combo-box
          v-model="recordsPerPage"
          :title="$t('TXT_RESULTS_BY_PAGE')"
          :disabled="products.length == 0"
          :placeholder="$t('TXT_SELECT')"
          :items="itemsPerPage"
          class="paginationSize"
        />
      </v-row>

      <Product-card
        v-for="(product, index) in products"
        :key="index"
        :product="product"
        @callGet="getMonitoringTags"
        @setLoading="isLoading = $event"
      />

      <Prd-pagination
        v-model="currentPage"
        v-show="products.length != 0"
        :totalRegisters="totalRecords"
        :length="totalPages"
        :showingRegistersLen="numberOfRecords"
        :resultLabel="$t('TXT_PAGINATION')"
        :pageLabel="$t('TXT_PAGE_LABEL')"
        class="mt-4"
      />
      <Errors-modal ref="ErrorsModal" />
    </v-card>
  </v-container>
</template>

<script>
import TitleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import PrdSearch from "@/Design_System/common/prd-search.vue";
import ProductCard from "./components/product-card.vue";
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import PrdPagination from "@/Design_System/common/prd-pagination.vue";
import service from "@/service/tags-config/index.js";
import PrdBtn from "../../../Design_System/common/prd-btn.vue";
import ErrorsModal from "../../../components/common/errors-modal.vue";
import ImportationStatus from "@/views/main/tags-config/components/importation-tag-status/importation-status-main.vue";

export default {
  name: "tags-config",
  components: {
    TitleBreadCrumbsComponent,
    PrdSearch,
    ProductCard,
    PrdComboBox,
    PrdPagination,
    PrdBtn,
    ErrorsModal,
    ImportationStatus,
  },

  data() {
    return {
      service: new service(),

      itemsPerPage: [5, 10, 15, 20],

      isLoading: false,
      search: "",
      disableTagImport: false,
      disableTag: false,

      // Pagination vars
      recordsPerPage: 10,
      currentPage: 1,
      totalRecords: null,
      totalPages: null,
      numberOfRecords: null,

      file: null,
      products: [],
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    breadcrumbs() {
      return [
        {
          text: "Predimonitor",
          href:
            this.idCompany == 2652
              ? "/main/predimonitor-V2"
              : "/main/predimonitor",
        },
        {
          text: this.$i18n.t("TXT_TAG_CONFIGURATION"),
          href: "/main/tags-config",
        },
      ];
    },
  },

  watch: {
    currentPage() {
      this.getMonitoringTags();
    },

    search() {
      this.getMonitoringTags();
    },

    recordsPerPage() {
      this.getMonitoringTags();
    },
  },

  methods: {
    hasResource(value) {
      return this.$store.getters.hasResource(value);
    },

    getMonitoringTags() {
      this.isLoading = true;

      let request = {
        includeLeadInfo: true,
        includeConfigInfo: true,
        monitoringItemCreateStartDate: null,
        monitoringItemCreateEndDate: null,
        onlyForSearch: false,
        onlyWithLead: false,
        filters: {
          description: this.search,
        },
        page: this.currentPage,
        recordsPerPage: this.recordsPerPage,
        idCompany: this.idCompany,
      };

      this.service
        .getMonitoringItems(request)
        .then((res) => {
          if (res.status != 500) {
            let response = res.data.result;
            this.products = response.records;

            this.products.forEach((el) => {
              el.configs = el.configs ?? [];
              el.groups = el.groups ?? [];
            });

            this.totalRecords = response.totalRecords;
            this.recordsPerPage = response.recordsPerPage;
            this.currentPage = response.currentPage;
            this.totalPages = response.totalPages;
            this.numberOfRecords = response.numberOfRecords;
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          } else {
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_ERROR_GET_TAGS"),
              type: "error",
            });
          }

          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    backPredimonitor() {
      if (this.hasResource("predimonitor.v2")) {
        this.$router.push("/main/predimonitor-V2");
      } else this.$router.push("/main/predimonitor");
    },

    exportTags() {
      this.disableTag = true;
      this.service
        .exportTags(this.idCompany, localStorage.getItem("lang"))
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "exportTags");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          this.disableTag = false;
        })
        .catch((error) => {
          console.log("Erro ao exportar tags", error);
          this.disableTag = false;
        });
    },

    handleImportFile() {
      this.$refs.fileInput.click();
    },

    async importFile(event) {
      try {
        this.disableTagImport = true;

        const file = event.target.files[0];

        if (!this.checkFileType(file)) {
          this.disableTagImport = false;
          this.$refs.fileInput.value = null;

          return;
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("idCompany", this.idCompany);
        formData.append("language", localStorage.getItem("lang"));

        const response = await this.service.importTags(formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response?.data?.success) {
          this.$store.commit("snackbarV2/set", {
            type: "success",
            message: this.$i18n.t("TXT_IMPORT_TAGS_SUCCESS"),
          });

          this.getMonitoringTags();
          this.$refs.importationStatus.openAndGetStatus();
        } else {
          this.handleImportError(null);
        }
      } catch (error) {
        this.handleImportError(error);
      }

      this.disableTagImport = false;
      this.$refs.fileInput.value = null;
    },

    handleImportError(error) {
      if (error?.response?.status === 400 || error?.response?.status === 415) {
        this.handlerErrors(error);
      } else {
        this.handleErrorsWithMessage(this.$i18n.t("TXT_IMPORT_TAGS_ERROR"));
      }
    },

    handlerErrors(error) {
      this.$refs.ErrorsModal.openModal(
        error?.response?.message ?? error?.response?.data?.message ?? null
      );
    },

    handleErrorsWithMessage(error) {
      this.$refs.ErrorsModal.openModal(error);
    },

    checkFileType(file) {
      if (!file) return false;
      const acceptedType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const fileType = file.type;
      const result = acceptedType == fileType;
      if (!result) {
        this.handleErrorsWithMessage(
          this.$i18n.t("TXT_INVALID_FILE_TYPE", { fileType: "'.xlsx'" })
        );
      }

      return result;
    },
  },

  mounted() {
    this.getMonitoringTags();
    document.title = `PREDIFY | ${this.$i18n.t("TXT_TAG_CONFIGURATION")}`;
  },
};
</script>
<style scoped>
.searchSize {
  width: 38%;
}
</style>
