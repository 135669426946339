var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.items,"hide-default-footer":"","id":"competitiveness-index-table"},scopedSlots:_vm._u([{key:"item.actualPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.actualPrice ? (_vm.currency + " " + (Number(item.actualPrice) .toFixed(2) .replace(".", ","))) : "-")+" ")]}},{key:"item.competitorPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.competitorPrice ? (_vm.currency + " " + (Number(item.competitorPrice) .toFixed(2) .replace(".", ","))) : "-")+" ")]}},{key:"item.competitivenessIndex",fn:function(ref){
var item = ref.item;
return [_c('td',{class:("text-center font-weight-bold " + (item.competitivenessIndex <= 85 || item.competitivenessIndex > 115
          ? 'red'
          : '') + "\n      " + ((item.competitivenessIndex > 85 && item.competitivenessIndex <= 95) ||
        (item.competitivenessIndex > 105 && item.competitivenessIndex <= 115)
          ? 'yellow'
          : '') + "\n      " + (item.competitivenessIndex > 95 && item.competitivenessIndex <= 105
          ? 'green'
          : '') + "\n      ")},[_vm._v(" "+_vm._s(!isNaN(item.competitivenessIndex) ? item.competitivenessIndex.replace(".", ",") + " %" : "-")+" ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }