<template>
  <v-container fluid>
    <v-row no-gutters justify="space-between" align="center">
      <Title-bread-crumbs :title="title.toUpperCase()" :hasTooltip="false" />
      <Prd-btn
        :leftIcon="true"
        :icon="'mdi-arrow-left'"
        :title="$t('TXT_BACK')"
        @click="cleanProduct"
      />
    </v-row>
    <Loading-information
      v-if="isLoading || isLoadingSaveChanges"
      :text="isLoadingSaveChanges ? $t('TXT_SAVING') : $t('TXT_CARRYING')"
    />
    <Type-precification
      class="mb-4"
      :isDisabled="isLoading"
      :productForEdition="productForEdition"
      @setPrecificationType="setPrecificationType"
    />
    <General-inputs
      v-show="productForEdition ? productForEdition.tipoPrecificacao : false"
      class="mb-4"
      :isLoading="isLoading"
      :mqStatDate="mqStatDate"
      :dateGoal="dateGoal"
      :replacementCostDate="replacementCostDate"
      :productsUPL="editedProduct ? productsUPL : uniqueProducts"
      :productForEdition="productForEdition"
      @setGeneralInputs="setGeneralInputs"
    />
    <Especifications-main
      v-show="productForEdition && productForEdition.nome != null"
      class="mb-4"
      :isDisabled="
        isLoading ||
        !productForEdition ||
        !productForEdition.nome ||
        !productForEdition.tipoPrecificacao
      "
      :precificationType="precificationType"
      :externalProducts="externalProducts"
      :internalProducts="internalProducts"
      :productsUPL="productsUPL"
      :productForEdition="productForEdition"
      @setCaptures="setCaptures"
    />
    <Actions-main
      v-show="productForEdition ? productForEdition.tipoPrecificacao : false"
      :isDisabled="disableActions"
      :product="productForEdition"
      :originalProduct="editedProduct"
      :productsUPL="productsUPL"
      @cleanProduct="cleanProduct"
    />
  </v-container>
</template>

<script>
import TitleBreadCrumbs from "@/Design_System/common/title.vue";
import GeneralInputs from "./components/general-inputs.vue";
import TypePrecification from "./components/type-precification.vue";
import EspecificationsMain from "./components/especifications/especifications-main.vue";
import LoadingInformation from "./components/loading-information.vue";
import ServiceUPL from "@/service/upl/index";
import ActionsMain from "./components/actions/actions-main.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";

export default {
  components: {
    TitleBreadCrumbs,
    GeneralInputs,
    TypePrecification,
    EspecificationsMain,
    LoadingInformation,
    ActionsMain,
    PrdBtn,
  },
  props: { editedProduct: { type: Object } },
  data() {
    return {
      productForEdition: {},
      isLoading: false,
      isLoadingSaveChanges: false,
      emptyProduct: null,
      service: new ServiceUPL(),
      externalProducts: [],
      internalProducts: [],
      productsUPL: [],
      replacementCost: [],
      uniqueProducts: [],
    };
  },
  computed: {
    mqStatDate() {
      return this.externalProducts[0]?.dataInsercao;
    },
    dateGoal() {
      return this.internalProducts[0]?.dataInsercao;
    },
    replacementCostDate() {
      return this.replacementCost[0]?.dataInsercao;
    },
    precificationType() {
      return this.productForEdition?.tipoPrecificacao ?? null;
    },
    title() {
      return this.editedProduct
        ? this.$i18n.t("TXT_EDIT_PRODUCT").replace("o", "")
        : this.$i18n.t("TXT_ADD_NEW_PRODUCT");
    },
    breadCrumbs() {
      return [{ text: this.title }];
    },
    disableActions() {
      return (
        this.isLoading ||
        !this.productForEdition ||
        !this.productForEdition?.nome ||
        !this.productForEdition?.tipoPrecificacao
      );
    },
  },
  methods: {
    async getAllInformation() {
      this.isLoading = true;
      try {
        await this.service.getExternalFiles().then((res) => {
          this.externalProducts = res.data;
        });
        await this.service.getInternalFiles().then((res) => {
          this.internalProducts = res.data;
        });
        await this.service.getProducts().then((res) => {
          this.productsUPL = res.data.map((product) => {
            return { ...product, text: product.nome };
          });
        });
        await this.service.getCustoReposicao().then((res) => {
          this.replacementCost = res.data.map((product) => {
            return { ...product, text: product.brand };
          });
        });
        await this.service.getEmptyProduct().then((res) => {
          res.data.sobre = {};
          res.data.sobre.classificacao = null;
          res.data.sobre.culturaDriver = null;
          if (!this.editedProduct) this.productForEdition = res.data;
          this.emptyProduct = res.data;
        });
        this.prepareUniqueProducts();
      } catch (error) {
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_INFORMATIONS"),
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
    prepareUniqueProducts() {
      this.uniqueProducts = [];
      this.replacementCost.forEach((product) => {
        const isExistsProduct = this.productsUPL.find(
          (x) => x.nome.toLowerCase() == product.brand.toLowerCase()
        );
        if (!isExistsProduct) this.uniqueProducts.push(product);
      });
    },
    setGeneralInputs(generalInput) {
      this.productForEdition.nome = generalInput.nome;

      this.productForEdition.dose = parseFloat(generalInput.dose);

      this.productForEdition.posicionamento = parseFloat(
        generalInput.posicionamento
      );

      this.productForEdition.remuneracaoPorCanal = parseFloat(
        generalInput.remuneracaoPorCanal
      );

      this.productForEdition.corredorPreco = parseFloat(
        generalInput.corredorPreco
      );

      this.productForEdition.margemContribuicao = parseFloat(
        generalInput.margemContribuicao
      );

      this.productForEdition.descricaoUnificada =
        generalInput.descricaoUnificada;

      this.productForEdition.unitCogs = parseFloat(generalInput.unitCogs);

      this.productForEdition.sobre.classificacao =
        generalInput?.sobre?.classificacao ?? null;

      this.productForEdition.sobre.culturaDriver =
        generalInput?.sobre?.culturaDriver ?? null;
    },
    setPrecificationType(type) {
      this.productForEdition.tipoPrecificacao = type;
    },
    setCaptures(captures) {
      this.productForEdition.capturas = captures;
    },
    cleanProduct() {
      this.$emit("closeNewEditProduct");
      this.productForEdition = JSON.parse(JSON.stringify(this.emptyProduct));
    },
  },
  watch: {
    editedProduct: {
      handler(value) {
        this.productForEdition = JSON.parse(JSON.stringify(value));
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.getAllInformation();
  },
};
</script>