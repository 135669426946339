<template>
  <div>
    <v-row
      no-gutters
      justify="space-between"
      class="mb-6"
      v-if="useSearchField || useItemsPerPage || userOthersFields"
    >
      <v-col cols="auto" v-if="useSearchField">
        <Prd-search
          :searchLabel="titleSearchField"
          @input="searchTerm = $event"
          :useDelay="useDelayInSearchField"
          :delay="delayTimeInSearchField"
        />
      </v-col>

      <v-col cols="auto" v-if="useItemsPerPageField || userOthersFields">
        <v-row no-gutters class="gap-6">
          <slot name="othersFields" v-if="userOthersFields" />
          <Prd-combo-box
            v-model="pagination.currentAmountShowItems"
            @input="pagination.currentAmountShowItems = $event"
            :title="titleItemsPerPageField"
            :items="localListItemsPerPageField"
            :disabled="
              filteredItems.length <= localListItemsPerPageField[0].value
            "
          />
        </v-row>
      </v-col>
    </v-row>
    <Legend-indicators :indicators="indicators" v-if="indicators.length > 0" />
    <v-data-table
      v-bind="$attrs"
      hide-default-footer
      :headers="headers"
      :items="filteredItems"
      :items-per-page="pagination.currentAmountShowItems.value"
      :page="pagination.currentPage"
      :no-data-text="$t('TXT_NO_DATA_FOUND_DISPLAY')"
      :no-results-text="$t('TXT_NO_DATA_FOUND_DISPLAY')"
      @click:row="emitClieckedRow"
    >
      <template v-slot:[`item.${getfirstColumn()}`]="data">
        <td class="px-0">
          <div :class="`text-center px-1 ${getBorderByIndicators(data.item)}`">
            {{ data.item[getfirstColumn()] }}
          </div>
        </td>
      </template>
      <template v-for="col in parentScopedSlots" v-slot:[col]="data">
        <slot :name="col" v-bind="data" />
      </template>
    </v-data-table>
    <Prd-pagination
      class="mt-10"
      :totalItems="filteredItems.length"
      :currentAmountShowItems="pagination.currentAmountShowItems.value"
      @currentPage="pagination.currentPage = $event"
    />
  </div>
</template>

<script>
import PrdComboBox from "../prd-comboBox.vue";
import PrdPagination from "../prd-pagination.vue";
import PrdSearch from "../prd-search.vue";
import LegendIndicators from "./components/legend-indicators.vue";
export default {
  components: { PrdPagination, PrdSearch, PrdComboBox, LegendIndicators },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    useSearchField: {
      type: Boolean,
      default: false,
    },
    useDelayInSearchField: {
      type: Boolean,
      default: false,
    },
    delayTimeInSearchField: {
      type: Number,
      default: 500,
    },
    fieldsForSearch: {
      type: Array,
      default: () => ["all"],
    },
    titleSearchField: {
      type: String,
    },
    useItemsPerPageField: {
      type: Boolean,
      default: false,
    },
    titleItemsPerPageField: {
      type: String,
      default: () => {
        this.$i18n.t("TXT_ITEMS_PER_PAGE");
      },
    },
    listItemsPerPageField: {
      type: Array,
    },
    userOthersFields: {
      type: Boolean,
      default: false,
    },
    indicators: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchTerm: null,
      pagination: {
        currentPage: 1,
        currentAmountShowItems: { text: "5", value: 5 },
      },
      filteredItems: [],
    };
  },
  computed: {
    localListItemsPerPageField() {
      if (!this.listItemsPerPageField)
        return [
          { text: "5", value: 5 },
          { text: "10", value: 10 },
          { text: "25", value: 25 },
          { text: "50", value: 50 },
          { text: "100", value: 100 },
          { text: this.$i18n.t("TXT_ALL"), value: this.filteredItems.length },
        ];
      return this.listItemsPerPageField;
    },
    parentScopedSlots() {
      if (this.$scopedSlots) {
        return Object.keys(this.$scopedSlots);
      }
      return [];
    },
  },
  methods: {
    emitClieckedRow(data) {
      this.$emit('clickedItem',data)
    },
    getFieldsForSearch() {
      let fields = [];
      if (this.fieldsForSearch[0] == "all")
        fields = this.headers.map((header) => {
          return header.value;
        });
      else fields = this.fieldsForSearch;
      return fields;
    },
    runSearch(list, term) {
      const fields = this.getFieldsForSearch();
      const filteredArray = list.filter((item) => {
        for (const index in fields) {
          const fieldName = fields[index];
          const stringValue = item[fieldName].toString().toLowerCase();
          if (stringValue.includes(term.toLowerCase())) return true;
        }
        return false;
      });
      return filteredArray;
    },
    handlerSearchField() {
      this.filteredItems = JSON.parse(JSON.stringify(this.items));
      if (this.searchTerm)
        this.filteredItems = this.runSearch(this.items, this.searchTerm);
    },

    getfirstColumn() {
      return this.headers[0].value;
    },

    getBorderByIndicators(item) {
      const ind = this.indicators.find(
        (ind) => ind.activatorValue === item[ind.referenceField]
      );
      if (ind) return `generalBorder ${ind.color}Border`;
      else return "";
    },
  },
  watch: {
    items: {
      handler() {
        this.handlerSearchField();
      },
      immediate: true,
      deep: true,
    },
    searchTerm: {
      handler() {
        this.handlerSearchField();
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.generalBorder {
  border-left: 6px solid;
  width: 100%;
}
.yellowBorder {
  border-left-color: $feedback-color-helper-pure;
}
.redBorder {
  border-left-color: $feedback-color-warning-pure;
}
.greenBorder {
  border-left-color: $feedback-color-success-pure;
}
.blueBorder {
  border-left-color: $brand-color-primary-pure;
}
</style>