var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.localItems,"hide-default-footer":_vm.localItems == [] || _vm.localItems.length == 0,"footer-props":_vm.footerProps,"no-data-text":_vm.$t('TXT_NO_DATA_AVAILABLE')},scopedSlots:_vm._u([{key:"item.percentLMUSD",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.percentLMUSD + " %")+" ")]}},{key:"item.percentLMBRL",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.percentLMBRL + " %")+" ")]}},{key:"item.unitPriceBRLOnePage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.unitPriceBRLOnePage, false))+" ")]}},{key:"item.unitPriceUSDOnePage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.unitPriceUSDOnePage, true))+" ")]}},{key:"item.iaPriceBRL",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.iaPriceBRL, false))+" ")]}},{key:"item.iaPriceUSD",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.iaPriceUSD, true))+" ")]}},{key:"item.unitPriceListUSD",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(!item.isEditUSD)?_c('div',[_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.unitPriceListUSD, true))+" "),(_vm.isAdminUpl)?_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary'),"right":""},on:{"click":function($event){item.isEditUSD = true}}},[_vm._v(" mdi-pencil-box")]):_vm._e()],1):_c('div',{staticClass:"my-flex"},[_c('v-text-field',{staticStyle:{"max-width":"120px"},attrs:{"loading":item.isLoading,"disabled":item.isLoading,"outlined":"","dense":"","hide-details":""},model:{value:(item.unitPriceListUSD),callback:function ($$v) {_vm.$set(item, "unitPriceListUSD", $$v)},expression:"item.unitPriceListUSD"}}),_c('v-icon',{attrs:{"disabled":item.isLoading,"color":_vm.$prdStyles('color-success')},on:{"click":function($event){return _vm.editUnitPriceDeliberation('USD', item)}}},[_vm._v(" mdi-checkbox-outline ")]),_c('v-icon',{attrs:{"disabled":item.isLoading,"color":_vm.$prdStyles('color-error')},on:{"click":function($event){return _vm.closeEditModeDeliberation('USD', item)}}},[_vm._v(" mdi-close-box ")])],1)])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("TXT_RULECONDITION_LAST_PRICE"))+": "+_vm._s(_vm.setCurrencyUPL(item.lastPriceUSD, true))+" ")])]}},{key:"item.unitPriceListBRL",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(!item.isEditBRL)?_c('div',[_vm._v(" "+_vm._s(_vm.setCurrencyUPL(item.unitPriceListBRL, false))+" "),(_vm.isAdminUpl)?_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary'),"right":""},on:{"click":function($event){item.isEditBRL = true}}},[_vm._v(" mdi-pencil-box")]):_vm._e()],1):_c('div',{staticClass:"my-flex"},[_c('v-text-field',{staticStyle:{"max-width":"120px"},attrs:{"loading":item.isLoading,"disabled":item.isLoading,"outlined":"","dense":"","hide-details":""},model:{value:(item.unitPriceListBRL),callback:function ($$v) {_vm.$set(item, "unitPriceListBRL", $$v)},expression:"item.unitPriceListBRL"}}),_c('v-icon',{attrs:{"disabled":item.isLoading,"color":_vm.$prdStyles('color-success')},on:{"click":function($event){return _vm.editUnitPriceDeliberation('BRL', item)}}},[_vm._v(" mdi-checkbox-outline ")]),_c('v-icon',{attrs:{"disabled":item.isLoading,"color":_vm.$prdStyles('color-error')},on:{"click":function($event){return _vm.closeEditModeDeliberation('BRL', item)}}},[_vm._v(" mdi-close-box ")])],1)])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("TXT_RULECONDITION_LAST_PRICE"))+": "+_vm._s(_vm.setCurrencyUPL(item.lastPriceBRL, true))+" ")])]}},{key:"item.coments",fn:function(ref){
var item = ref.item;
return [(!item.isEditComments)?_c('div',[_vm._v(" "+_vm._s(item.coments)+" "),(_vm.isAdminUpl)?_c('v-icon',{attrs:{"color":_vm.$prdStyles('color-primary'),"right":""},on:{"click":function($event){item.isEditComments = true}}},[_vm._v(" mdi-pencil-box")]):_vm._e()],1):_c('div',{staticClass:"my-flex"},[_c('v-text-field',{attrs:{"loading":item.isLoading,"disabled":item.isLoading,"outlined":"","dense":"","hide-details":""},model:{value:(item.coments),callback:function ($$v) {_vm.$set(item, "coments", $$v)},expression:"item.coments"}}),_c('v-icon',{attrs:{"disabled":item.isLoading,"color":_vm.$prdStyles('color-success')},on:{"click":function($event){return _vm.editUnitPriceDeliberation('Comments', item)}}},[_vm._v(" mdi-checkbox-outline ")]),_c('v-icon',{attrs:{"disabled":item.isLoading,"color":_vm.$prdStyles('color-error')},on:{"click":function($event){return _vm.closeEditModeDeliberation('Comments', item)}}},[_vm._v(" mdi-close-box ")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }