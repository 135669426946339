<template>
  <v-card class="pa-4">
    <v-row no-gutters class="mb-4" align="center" justify="space-between">
      <h4>{{ $i18n.t("TXT_ELASTICITY").toUpperCase() }}</h4>
      <div class="font-size-12">
        <span class="elasticityType" v-show="elasticityType"
          >{{ $t("TXT_TOOLTIP_ELASTICITY_TYPE_TITLE") }}:
          <span class="font-weight-bold">{{ elasticityType }}</span
          ><tooltip-informations
            :rightIcon="true"
            :title="$t('TXT_TOOLTIP_ELASTICITY_TYPE')"
        /></span>
        <span v-show="false">
          {{ $t("TXT_INDEX") }}:
          <span class="font-weight-bold">{{ elasticityIndex }}</span>
          <tooltip-informations
            :rightIcon="true"
            :title="$t('TXT_TOOLTIP_ELASTICITY_INDEX')"
          />
        </span>
      </div>
    </v-row>
    <Prd-loading-circular v-if="isLoading" class="div-centered" />

    <div class="var" v-else>
      <div class="var-item">
        <div class="var-title">
          <p v-t="'TXT_AVERAGE_PRICE'" class="ma-0 pa-0"></p>
          <Tooltip-informations
            :title="$t('TXT_AVERAGE_DEMAND')"
            class="mt-2 ml-2"
          />
        </div>
        <v-skeleton-loader
          v-if="loadingSkeleton"
          type="button"
          :height="40"
          class="mb-2"
        />
        <div v-else class="var-box pa-2">
          {{ formatMonetary(averagePrice) }}
        </div>
      </div>
      <div class="var-item">
        <div class="var-title">
          <p v-t="'TXT_ELASTICITY_MEAN_QUANTITY'" class="ma-0 pa-0"></p>
          <Tooltip-informations
            :title="$t('TXT_AVERAGE_DEMAND')"
            class="mt-2 ml-2"
          />
        </div>
        <v-skeleton-loader
          v-if="loadingSkeleton"
          type="button"
          :height="40"
          class="mb-2"
        />
        <div v-else class="var-box pa-2">
          {{ formatDecimals(averageDemand) }}
        </div>
      </div>
      <div class="var-item">
        <div class="var-title">
          <p v-t="'TXT_DEMAND_PRICE'" class="ma-0 pa-0"></p>
        </div>
        <v-skeleton-loader
          v-if="loadingSkeleton"
          type="button"
          :height="40"
          class="mb-2"
        />
        <div v-else class="var-box pa-2">{{ formatMonetary(priceDemand) }}</div>
      </div>
      <div class="var-item">
        <div class="var-title">
          <p v-t="'TXT_PREDICTED_DEMAND'" class="ma-0 pa-0"></p>
        </div>
        <v-skeleton-loader
          v-if="loadingSkeleton"
          type="button"
          :height="40"
          class="mb-2"
        />
        <div v-else class="var-box pa-2">
          {{ formatDecimals(calculatedDemand) }}
        </div>
      </div>
      <div class="var-item">
        <div class="var-title">
          <p v-t="'TXT_DEMAND_VARIABILITY'" class="ma-0 pa-0"></p>
        </div>

        <v-skeleton-loader
          v-if="loadingSkeleton"
          type="button"
          :height="40"
          class="mb-2"
        />
        <div v-else class="var-box pa-2">
          {{ formatPercentage(demandVariation) }}
        </div>
      </div>
      <div class="var-item">
        <div class="var-title">
          <p v-t="'TXT_SALLES_VARIATION'" class="ma-0 pa-0"></p>
        </div>
        <v-skeleton-loader
          v-if="loadingSkeleton"
          type="button"
          :height="40"
          class="mb-2"
        />
        <div v-else class="var-box pa-2">
          {{ formatMonetary(salesVariation) }}
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
import service from "@/service/pricer/product-list.js";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import TooltipInformations from "@/Design_System/common/tooltip-informations.vue";
import { formatMonetary } from "@/utils/format-toMonetary";
import { formatDecimals } from "@/utils/format-decimals";
import {formatPercentage} from "@/utils/format-percentage.js"
const Service = new service();
export default {
  components: { PrdLoadingCircular, TooltipInformations },
  props: ["product", "idEnterprisePricesProjection"],
  data: () => ({
    isLoading: false,
    loadingSkeleton: true,
    data: [],
  }),

  computed: {
    averagePrice() {
      return this.data?.averagePrice ?? null;
    },
    averageDemand() {
      return this.data?.averageDemand;
    },
    priceDemand() {
      return this.data?.ajustedPrice ?? null;
    },
    calculatedDemand() {
      return this.data?.ajusted_Price_Demand ?? null;
    },
    demandVariation() {
      if (!this.calculatedDemand || !this.averageDemand) return "-";
      return (this.calculatedDemand / this.averageDemand - 1) * 100 ?? null;
    },
    salesVariation() {
      if (
        !this.averagePrice ||
        !this.priceDemand ||
        !this.averageDemand ||
        !this.calculatedDemand
      )
        return "-";

      return (
        this.priceDemand * this.calculatedDemand -
        this.averagePrice * this.averageDemand  ?? null
      );
    },
    elasticityType() {
      if (!this.data?.elastity) return "-";

      if (this.data.elastity) {
        if (this.data.elastity < 1 && this.data.elastity > -1)
          return this.$i18n.t("TXT_INELASTIC");
        else return this.$i18n.t("TXT_ELASTIC");
      } else {
        return null;
      }
    },

    elasticityIndex() {
      return this.data?.elasticity ?? "-";
    },
  },
  methods: {
    formatMonetary,
    formatDecimals,
    formatPercentage,
    async getElasticity(isFromOptimizedPrice) {
      if (!isFromOptimizedPrice) this.isLoading = true;
      else this.loadingSkeleton = true;

      try {
        let response = await Service.getElasticity(
          this.idEnterprisePricesProjection
        );

        this.data = response?.data?.result ?? null;
        this.$emit("setPriceToHeader", this.data?.ajustedPrice);
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR"),
          type: "error",
        });
        this.data = null;
      }

      this.loadingSkeleton = false;
      this.isLoading = false;
    },
  },

  created() {
    this.getElasticity();
  },
};
</script>
<style lang="scss" scoped>
p {
  font-size: 12px;
}
.elasticityType {
  font-size: 12px;
}
.div-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.var {
  display: flex;
  gap: 0.5rem;
}
.var-item {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.var-title {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.var-box {
  border: 0.5px solid rgba(0, 0, 0, 0.459);
  border-radius: 5px;
  height: 40px;
}

.card-subtitle {
  font-size: 16px;
  font-weight: bolder;
}

.border-prices-box {
  flex-grow: 1;
}

.bordered {
  border: 1px solid $neutral-color-low-light;
}

.red-value {
  color: $feedback-color-warning-pure;
}

.green-value {
  color: $feedback-color-success-pure;
}

.elasticity {
  justify-content: flex-start;
}
</style>
