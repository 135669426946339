<template>
  <v-row no-gutters>
    <v-col cols="12" class="mr-6">
      <fieldset class="pa-3 personalizedFieldset">
        <legend class="font-weight-bold px-2">{{ title }}</legend>
        <v-row no-gutters align="center" justify="center" class="mb-6 gap-4">
          <span>
            {{ $t("TXT_LAST_IMPORTATION") }}:
            <span class="font-weight-bold">{{ localLastImportation }}</span>
          </span>
          <v-progress-circular
            v-if="isLoadingDownloadLast"
            :color="$prdStyles('color-primary')"
            indeterminate
          />
          <v-icon
            v-else
            :color="$prdStyles('color-primary')"
            :disabled="localLastImportation == '-'"
            @click="$emit('downloadLastFile')"
            size="32"
          >
            mdi-content-save-outline
          </v-icon>
        </v-row>
        <v-row no-gutters justify="space-between">
          <prd-btn
            @click="$emit('download')"
            :title="$t('TXT_TEMPLATE')"
            :leftIcon="true"
            :outlined="true"
            :icon="'mdi-download-outline'"
            :disabled="isLoadingDownload || !useTemplate"
            :loading="isLoadingDownload"
          />

          <prd-btn
            @click="$refs.fileInputReposicao.click()"
            :leftIcon="true"
            :icon="'mdi-upload-outline'"
            :title="$t('TXT_TO_IMPORT')"
            :disabled="isLoadingUpload"
            :loading="isLoadingUpload"
          />
          <input
            ref="fileInputReposicao"
            type="file"
            style="display: none"
            @change="$emit('upload', $event)"
          />
        </v-row>
      </fieldset>
    </v-col>
  </v-row>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  props: [
    "title",
    "lastImportation",
    "isLoadingUpload",
    "isLoadingDownload",
    "isLoadingDownloadLast",
    "useTemplate",
  ],
  computed: {
    localLastImportation() {
      return this.lastImportation ?? "-";
    },
  },
  components: { prdBtn },
};
</script>

<style lang="scss" scoped>
.personalizedFieldset {
  border: 2px solid $neutral-color-high-medium;
  border-radius: 4px;
}
</style>