
import { formatDecimals } from "@/utils/format-decimals"
import { formatMonetary } from "@/utils/format-toMonetary";
import { formatPercentage } from "@/utils/format-percentage";
import i18n from "@/i18n";

const setFormat = (tab, value, seriesName) => {
    if (seriesName == i18n.t("TXT_ROYALT_ADDITIONAL") || seriesName == i18n.t("TXT_GOAL")) {
        return formatMonetary(value)
    }
    if (tab == 0) {
        return formatDecimals(value)
    } else if (tab == 1) {
        return formatDecimals(value)
    } else if (tab == 2) {
        return formatMonetary(value)
    } else if (tab == 3) {
        return formatPercentage(value * 100)
    } else if (tab == 4) {
        return formatMonetary(value)
    }
}

/**
 * 
 * @param {Number} tab Tab selected
 * @param {Array} data Graph value
 * @param {Array} yAxis x-axis array
 * @param {Array} categories Legend bellow the Graph
 * @param {String} year
 */

const chartData = (tab, data, yAxis, categories, year) => {
    return {
        tooltip: {
            formatter: function () {
                return ` ${this.series.name}: ${setFormat(tab, this.y, this.series.name)}  `
            }
        },

        title: {
            text: "",
        },

        xAxis: {
            categories: categories,
            crosshair: true,
            title: {
                text: year,
            },
        },

        yAxis: yAxis,

        series: data,

        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return this.y ? setFormat(tab, this.y, this.series.name) : null
                    },
                },
            },
        },
        exporting: {
            enabled: false
        },
        credits: {
            enabled: false
        }
    }
}
export default chartData;