<template>
  <div>
    <Prd-btn
      @click="$refs.fileInput.click()"
      :title="$t('TXT_TO_IMPORT')"
      :outlined="true"
      :icon="'mdi-upload-outline'"
      :smallIcon="true"
      :leftIcon="true"
      :loading="isLoading"
    />
    <input
      id="fileInput"
      ref="fileInput"
      type="file"
      v-show="false"
      @change="handleFileChange($event)"
    />
    <Errors-Modal ref="errorsModal" :title="$t('TXT_FAIL_IMPORT_PRODUCTS')" />
  </div>
</template>

<script>
import ErrorsModal from "@/components/common/errors-modal.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import { checkFileType } from "@/utils/check-file-type";
import Home20Service from "@/service/home20";
export default {
  components: { PrdBtn, ErrorsModal },
  data() {
    return { isLoading: false, service: new Home20Service() };
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];

      const isValidFile = checkFileType(["xlsx"], file);
      if (!isValidFile) {
        const error = this.$i18n.t("TXT_INVALID_FILE_TYPE", {
          fileType: "'.xlsx'",
        });
        this.$refs.errorsModal.openModal(error);
      } else this.sendFile(file);

      this.$refs.fileInput.value = null;
    },

    async sendFile(file) {
      this.isLoading = true;
      const request = new FormData();
      request.append("file", file);
      try {
        const idCompany = this.$store.getters.userData.idCompany;
        await this.service.importFilePromotionalCalendar(idCompany, request);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_SUCCESS_IMPORT_PRODUCTS"),
          type: "success",
        });
      } catch (error) {
        let message = {};
        message.errors = error.response.data.message;
        this.$refs.errorsModal.openModal(message);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
