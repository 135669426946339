<template>
  <v-expansion-panel-header color="#f5f5f5" width="100%" class="pa-0 px-4">
    <div class="item">
      <div class="flex-center">
        <v-icon class="menu-drag handle mr-4">mdi-menu</v-icon>
        <v-switch
          v-model="rule.isActive"
          @click.stop="switchRule(rule)"
          dense
          flat
          class="mr-4"
          :disabled="isLoading || disableRulesActions"
        ></v-switch>
        <span class="font-weight-bold mr-6"
          >{{ index + 1 }} - {{ rule.description }}</span
        >

        <Prd-loading-circular v-if="isLoading" />
      </div>

      <div class="flex-center">
        <div
          class="rules-status mr-8"
          :class="rule.isActive ? 'active-color' : 'inactive-color'"
        >
          {{ status }}
        </div>
        <v-icon
          @click.stop="duplicateRule"
          :disabled="isLoading || disableRulesActions"
          color="#6792F8"
          class="mr-1"
          size="26px"
        >
          mdi-plus-box-multiple
        </v-icon>
        <Delete-rule-group-modal
          :externalLoading="isLoading"
          :idRule="item.idRule"
        />
      </div>
    </div>
  </v-expansion-panel-header>
</template>

<script>
import service from "@/service/pricing-rules/index.js";
import DeleteRuleGroupModal from "./components/delete-rule-group-modal.vue";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
const Service = new service();

export default {
  components: { DeleteRuleGroupModal, PrdLoadingCircular },
  props: {
    item: {
      type: Object,
      default: () => ({
        idRule: null,
        isSimulation: false,
        description: null,
        isActive: false,
        numberOrder: null,
      }),
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      rule: null,
      isLoading: false,
    };
  },

  computed: {
    status() {
      if (this.rule.isActive) return this.$i18n.t("TXT_ACTIVE");
      return this.$i18n.t("TXT_INACTIVE");
    },
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    disableRulesActions() {
      return this.$store?.getters?.disableRulesActions;
    },
  },

  watch: {
    item: {
      handler(value) {
        this.rule = value;
      },
      immediate: true,
    },
    isLoading: {
      handler(value) {
        this.$emit("updateIsLoading", value);
      },
      immediate: true,
    },
  },
  methods: {
    async switchRule() {
      this.$emit("setLoading", true);
      this.isLoading = true;
      try {
        let response = await Service.switchRule(
          this.idCompany,
          this.rule.idRule
        );

        if (response.status == 200) {
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_UPDATED_RULES_SUCCESS"),
            type: "success",
          });
        }
      } catch (error) {
        console.log(error);
        this.rule.isActive = !this.rule.isActive;
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_UPDATED_RULES_ERROR"),
          type: "error",
        });
      }
      this.isLoading = false;
      this.$emit("setLoading", false);
    },

    async duplicateRule() {
      this.$emit("setLoading", true);
      this.isLoading = true;
      const idRule = this.item.idRule;
      try {
        await Service.cloneRule(this.idCompany, idRule);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_DUPLICATED_RULES_SUCCESS"),
          type: "success",
        });
        this.$store.dispatch("getRules");
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_DUPLICATED_RULES_ERROR"),
          type: "error",
        });
      }
      this.$emit("setLoading", false);
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .menu-drag {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  .rules-status {
    display: flex;
    width: 70px;
    padding: 4px 22px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    color: white;
    border-radius: 4px;
  }

  .active-color {
    background-color: #1d8527;
  }

  .inactive-color {
    background-color: #bab8b8;
  }
}
</style>
