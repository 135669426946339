<template>
  <highcharts :options="chartOptions" />
</template>

<script>
import chartData from "./chart-options.js";
export default {
  props: ["tab", "data"],
  data: () => ({
    chartOptions: {},
  }),

  watch: {
    data: {
      handler(value) {
        let chartData = this.formatChartResponse(value, this.tab);
        let translatedCategorie = this.translateCategories(
          value?.categories ?? []
        );
        this.chartOptions = this.chartDataOption(
          chartData,
          this.tab,
          translatedCategorie
        );
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },

    VEM() {
      return this.idCompany == 3026;
    },

    vemManager() {
      return this.$store?.getters?.vemManager ?? false;
    },
  },

  methods: {
    chartDataOption(data, tab, categories) {
      let yAxis = this.resolveYAxis(tab);

      return chartData(
        tab,
        data,
        yAxis,
        categories,
        this.$i18n.t("TXT_MONTH_YEAR")
      );
    },

    formatChartResponse(responseData, tab) {
      if (!responseData) return [];
      let chartData = [];

      if (tab == 0) {
        chartData.push({
          name: this.$i18n.t("TXT_ANTERIOR_ADOPTION"),
          data: responseData?.adoptions?.data,
          type: "column",
          color: "#EC4C37",
        });

        chartData.push({
          name: this.$i18n.t("TXT_PROJECTED_ADOPTION"),
          data: responseData?.notAdoptions?.data,
          type: "column",
          color: "#3c5ca7",
        });

        chartData.push({
          name: this.$i18n.t("TXT_REALIZED_ADOPTION"),
          data: responseData?.pricingCount?.data,
          type: "column",
          color: "#9510AC",
        });

        if (this.VEM && this.vemManager) {
          chartData.push(
            this.getRoyaltySerieDefault(responseData?.royalties?.data)
          );

          chartData.push(this.getGoalSerieDefault(responseData?.goal?.data));
        }
      } else if (tab == 1) {
        chartData.push(
          this.getLineSerieDefaultV2(
            this.$i18n.t("TXT_ANTERIOR_DEMAND"),
            responseData?.previousDemand?.data,
            "#EC4C37"
          )
        );

        chartData.push(
          this.getLineSerieDefaultV2(
            this.$i18n.t("TXT_PROJECTED_DEMAND"),
            responseData?.projectedDemand?.data,
            "#3c5ca7"
          )
        );

        chartData.push(
          this.getLineSerieDefaultV2(
            this.$i18n.t("TXT_REALIZED_DEMAND"),
            responseData?.realizedDemand?.data,
            "#9510AC"
          )
        );
      } else if (tab == 2) {
        chartData.push(
          this.getLineSerieDefaultV2(
            this.$i18n.t("TXT_ANTERIOR_RECIPE"),
            responseData?.previousRevenue?.data,
            "#EC4C37"
          )
        );

        chartData.push(
          this.getLineSerieDefaultV2(
            this.$i18n.t("TXT_PROJECTED_RECIPE"),
            responseData?.projectedRevenue?.data,
            "#3c5ca7"
          )
        );

        chartData.push(
          this.getLineSerieDefaultV2(
            this.$i18n.t("TXT_REALIZED_RECIPE"),
            responseData?.realizedRevenue?.data,
            "#9510AC"
          )
        );

        if (this.VEM) {
          chartData.push(
            this.getRoyaltySerieDefault(responseData?.royalties?.data)
          );

          chartData.push(this.getGoalSerieDefault(responseData?.goal?.data));
        }
      } else if (tab == 3) {
        chartData.push(
          this.getLineSerieDefaultV2(
            this.$i18n.t("TXT_ANTERIOR_MARGIN"),
            responseData?.previousMargin?.data,
            "#EC4C37"
          )
        );

        chartData.push(
          this.getLineSerieDefaultV2(
            this.$i18n.t("TXT_PROJECTED_MARGIN"),
            responseData?.projectedMargin?.data,
            "#3c5ca7"
          )
        );

        chartData.push(
          this.getLineSerieDefaultV2(
            this.$i18n.t("TXT_REALIZED_MARGIN"),
            responseData?.realizedMargin?.data,
            "#9510AC"
          )
        );
      } else if (tab == 4) {
        chartData.push(
          this.getLineSerieDefaultV2(
            this.$i18n.t("TXT_ANTERIOR_PROFIT"),
            responseData?.previousProfit?.data,
            "#EC4C37"
          )
        );

        chartData.push(
          this.getLineSerieDefaultV2(
            this.$i18n.t("TXT_PROJECTED_PROFIT"),
            responseData?.projectedProfit?.data,
            "#3c5ca7"
          )
        );

        chartData.push(
          this.getLineSerieDefaultV2(
            this.$i18n.t("TXT_REALIZED_PROFIT"),
            responseData?.realizedProfit?.data,
            "#9510AC"
          )
        );
      }

      return chartData;
    },
    getLineSerieDefaultV2(name, data, color) {
      if (!data || data.length === 0) {
        data = [];
      }
      let zones = [];
      let previous = data[0];
      let filteredData = [];
      let lastfilledValue = null;
      data.forEach((current, index) => {
        if (current != null) lastfilledValue = current;
        filteredData.push(current != null ? current : previous);
        let body = { value: index, dashStyle: "Dash" };
        if (current && current != previous) {
          body.dashStyle = "Solid";
        }
        zones.push(body);
        previous = current ?? lastfilledValue;
      });

      return {
        name: name,
        data: filteredData,
        type: "line",
        color: color,
        zones: zones,
        zoneAxis: "x",
      };
    },
    getLineSerieDefault(name, data, color) {
      return {
        name: name,
        data: data ?? [],
        type: "line",
        color: color,
        zones: [
          {
            value: this.getStartingAmountOfZero(data ?? []),
            dashStyle: "Dash",
          },
          {
            dashStyle: "Solid",
          },
        ],
        zoneAxis: "x",
      };
    },
    getStartingAmountOfZero(array) {
      let count = 0;

      for (let i = 0; i < array.length; i++) {
        const nextIndex = i + 1;
        if (array[i] == 0 && array[nextIndex] == 0) count++;
        else break;
      }

      return count;
    },
    getRoyaltySerieDefault(data) {
      return {
        name: this.$i18n.t("TXT_ROYALT_ADDITIONAL"),
        type: "spline",
        dataLabels: {
          enabled: false,
        },
        color: "#12CED4",
        data: data ?? [],
        yAxis: 1,
      };
    },
    getGoalSerieDefault(data) {
      return {
        name: this.$i18n.t("TXT_GOAL"),
        type: "line",
        dataLabels: {
          enabled: false,
        },
        color: "#F2BB13",
        data: data ?? [],
        yAxis: 1,
      };
    },

    translateCategories(categories) {
      return categories.map(
        (category) => `${this.$i18n.t(category.month)} / ${category.year}`
      );
    },

    resolveYAxis(tab) {
      let yAxis = [];

      const chartTypes = [
        this.$i18n.t("TXT_UNIT"),
        this.$i18n.t("TXT_UNIT"),
        `${this.$i18n.t("TXT_RECIPE")} (R$)`,
        `${this.$i18n.t("TXT_MARGIN")} (%)`,
      ];

      yAxis.push({
        title: {
          text: chartTypes[tab],
        },
        labels: {
          formatter: function () {
            return tab == 3 ? (this.value * 100).toFixed(2) : this.value;
          },
        },
      });

      if (tab == 0 || tab == 2) {
        yAxis.push({
          title: {
            text:
              tab == 0
                ? `${this.$i18n.t("TXT_RECIPE")} (R$)`
                : this.$i18n.t("TXT_UNIT"),
          },
          opposite: true,
        });
      }

      return yAxis;
    },
  },
};
</script>