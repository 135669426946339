<template>
  <v-container fluid>
    <v-row no-gutters align="end" justify="space-between">
      <titleBreadCrumbs
        :title="'PREDIMONITOR'"
        :hasTooltip="false"
        :showTooltip="false"
      />
      <div class="mb-4">
        <router-link to="/main/crawler-execute">
          <prd-btn
            :title="`${$t('TXT_EXECUTE')} crawler`"
            :outlined="true"
            class="mr-4"
          />
        </router-link>

        <router-link to="/main/tags-config">
          <prd-btn :title="$t('TXT_TAG_CONFIGURATION')" :outlined="true" />
        </router-link>
      </div>
    </v-row>

    <search-main
      @searchProducts="formatDataToTable($event)"
      @setLoading="setLoading"
      @setLoadingGroup="isLoadingGroup = $event"
      @productsLoading="productsLoading = $event"
      @cleanProducts="products = []"
      @dateRange="getDateInterval"
    />

    <table-result
      :products="products"
      :isLoading="isLoading"
      :isLoadingGroup="isLoadingGroup"
      :totalProductsLoading="totalProductsLoading"
      :productsLoading="productsLoading"
      :dateRange="dateRange"
      :refreshLoading="refreshLoading"
      @refreshProduct="refreshProducts"
      ref="table-result"
      class="mt-4"
    />
  </v-container>
</template>

<script>
import titleBreadCrumbs from "@/Design_System/common/title.vue";
import SearchMain from "./components/search-main.vue";
import TableResult from "./components/table-result.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import moment from "moment";

import service from "@/service/predimonitor-V2/index.js";
const Service = new service();
export default {
  name: "predimonitor-V2",
  components: { titleBreadCrumbs, SearchMain, TableResult, PrdBtn },
  data() {
    return {
      products: [],
      dateRange: null,
      isLoading: false,
      isLoadingGroup: false,
      refreshLoading: false,
      totalProductsLoading: null,
      productsLoading: null,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  methods: {
    formatDataToTable(values) {
      if (!values) {
        this.products = [];
        return;
      }
      values.forEach((el) => {
        let formatedTable = this.setDataToTable(el);

        let formatedProduct = {
          tableData: formatedTable,
          productName: el.predify_product_description,
          idMonitoringItem: el.idMonitoringItem,
          hasError: el.hasError,
          isLoading: false,
        };

        this.products.push(formatedProduct);
      });
    },

    formatDataToTableTESTE(values) {
      if (!values) {
        this.products = [];
        return;
      }

      let product = [];
      values.forEach((el) => {
        let formatedTable = this.setDataToTable(el);

        let formatedProduct = {
          tableData: formatedTable,
          productName: el.predify_product_description,
          idMonitoringItem: el.idMonitoringItem,
        };

        product.push(formatedProduct);
      });

      return product[0];
    },

    setDataToTable(product) {
      let filteredData = [];
      product.results.forEach((item) => {
        const {
          crawler_date,
          crawler_name,
          product_link,
          product_name,
          sellers,
          isManual,
          idMonitoringItemProductManualResult,
        } = item;

        sellers.forEach((seller) => {
          const { seller_name, prices, disregarded } = seller;

          prices.forEach((priceItem) => {
            const { price, price_payment_type, installments, price_tax_st } =
              priceItem;
            filteredData.push({
              crawler_date,
              crawler_name,
              product_link,
              product_name,
              seller_name,
              isManual,
              idMonitoringItemProductManualResult,
              price: price || 0,
              price_payment_type: price_payment_type,
              disregarded,
              markToDisregard: !disregarded ? false : true,
              idMonitoringItem: product.idMonitoringItem,
              on_request: priceItem.on_request,
            });

            if (installments && installments.length > 0) {
              installments.forEach((installment) => {
                const {
                  quantity,
                  deflated_value,
                  price: installmentPrice,
                  price_currency,
                } = installment;

                filteredData.push({
                  crawler_date,
                  crawler_name,
                  product_link,
                  product_name,
                  seller_name,
                  price: installment.total_price,
                  isManual,
                  idMonitoringItemProductManualResult,
                  quantity,
                  deflated_value,
                  installmentPrice,
                  price_currency,
                  price_tax_st,
                  disregarded,
                  markToDisregard: !disregarded ? false : true,
                  idMonitoringItem: product.idMonitoringItem,
                  on_request: priceItem.on_request,
                });
              });
            }
          });
        });
      });

      return filteredData;
    },

    refreshProducts(idMonitoringItem) {
      this.products.forEach((el) => {
        if (el.idMonitoringItem === idMonitoringItem) {
          this.$set(el, "isLoading", true);
        }
      });

      let request = {
        end_date: this.pushOneMoreDay(
          this.dateRange[this.dateRange.length - 1]
        ),
        idCompany: this.idCompany,
        idMonitoringGroup: 0,
        idMonitoringItem: idMonitoringItem,
        includeIgnored: true,
        search_type: 1, //1- produto 3- grupo
        start_date: this.dateRange[0],
      };

      Service.searchV3(request)
        .then((res) => {
          let response = res.data.answer.products[0];
          let newProduct = this.formatDataToTableTESTE([response]);

          this.products.forEach((el, index) => {
            if (el.idMonitoringItem === idMonitoringItem) {
              this.$set(this.products, index, newProduct);
            }
          });

          this.refreshLoading = false;
          this.products.forEach((el) => {
            if (el.idMonitoringItem === idMonitoringItem) {
              this.$set(el, "isLoading", false);
            }
          });
        })
        .catch((error) => {
          console.log(error);
          this.refreshLoading = false;
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_UPDATED_PRODUCT_ERROR_2"),
            type: "error",
          });

          this.products.forEach((el) => {
            if (el.idMonitoringItem === idMonitoringItem) {
              this.$set(el, "isLoading", false);
            }
          });
        });
    },

    getDateInterval(dateStart, dateEnd) {
      let startDate = moment(dateStart);
      let endDate = moment(dateEnd);

      let dateArray = [];

      dateArray.push(startDate.format("YYYY-MM-DD"));

      while (startDate.isBefore(endDate)) {
        startDate.add(1, "days");
        dateArray.push(startDate.format("YYYY-MM-DD"));
      }
      this.dateRange = dateArray;
    },

    setLoading(loading, totalProducts) {
      this.isLoading = loading;
      this.totalProductsLoading = totalProducts;
    },

    pushOneMoreDay(dataString) {
      const data = new Date(dataString);
      data.setDate(data.getDate() + 2);
      const ano = data.getFullYear();
      const mes = String(data.getMonth() + 1).padStart(2, "0");
      const dia = String(data.getDate()).padStart(2, "0");
      return `${ano}-${mes}-${dia}`;
    },
  },
};
</script>