<template>
  <v-card class="pa-4" height="100%">
    <h4>{{ licenseType.toUpperCase() }}</h4>
    <Prd-search
      class="my-6"
      :searchLabel="$t('TXT_SEARCH_BY_LICENSE_NAME')"
      @input="search = $event"
    />
    <div class="licenseList pr-1">
      <License-item
        v-for="license in localLicenseList"
        :key="license.idLicenca"
        :licenseData="license"
        :isLinkedLicense="isLinkedCard"
        @handlerLicense="$emit('handlerLicense', $event)"
        class="mb-1"
      />
    </div>
  </v-card>
</template>

<script>
import PrdSearch from "@/components/common/prd-search.vue";
import LicenseItem from "./components/license-item.vue";
export default {
  components: { PrdSearch, LicenseItem },
  props: {
    isLinkedCard: {
      type: Boolean,
    },
    licenseList: {
      type: Array,
    },
  },
  data() {
    return {
      localLicenseList: [],
      search: null,
    };
  },
  computed: {
    licenseType() {
      return this.isLinkedCard
        ? this.$i18n.t("TXT_ALLOCATED_LICENSES")
        : this.$i18n.t("TXT_NOT_ALLOCATED_LICENSES");
    },
  },
  watch: {
    licenseList: {
      handler(value) {
        this.localLicenseList = value.filter(
          (license) => license.isLinked == this.isLinkedCard
        );
        this.searchLicense(this.search);
      },
      deep: true,
      immediate: true,
    },
    search: {
      handler(value) {
        this.searchLicense(value);
      },
    },
  },
  methods: {
    searchLicense(name) {
      this.localLicenseList = this.licenseList.filter(
        (license) => license.isLinked == this.isLinkedCard
      );
      if (name)
        this.localLicenseList = this.localLicenseList.filter((license) =>
          license.nome.toLowerCase().includes(name.toLowerCase())
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.licenseList {
  overflow: auto;
  max-height: 220px;
}
</style>