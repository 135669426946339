<template>
  <v-row no-gutters align="end" class="group-box">
    <v-col v-if="configs.title" cols="auto" class="mr-2">
      <span>{{ configs.title }} :</span>
    </v-col>
    <v-col cols="auto">
      <v-tabs
        color="black"
        :slider-color="$prdStyles('color-secundary')"
        height="25px"
        v-model="tab"
        :center-active="configs.centerActive"
        :show-arrows="configs.showArrows"
      >
        <v-tab
          class="black-border"
          v-for="(tab, index) in configs.tabs"
          :key="index"
        >
          <v-row no-gutters align="center" justify="center">
            <span class="capitalize">{{ tab.name }}</span>
            <Prd-tooltip v-if="tab.tooltip" :text="tab.tooltip" />
          </v-row>
        </v-tab>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import PrdTooltip from "@/components/common/prd-tooltip.vue";
export default {
  props: { configs: { type: Object, required: true } },
  components: {
    PrdTooltip,
  },
  data() {
    return { tab: 0 };
  },
  watch: {
    tab: {
      handler(value) {
        this.$emit("changedTab", value);
      },
    },
    immediate: true,
  },
};
</script>

<style lang="scss" scoped>
.black-border {
  border-bottom: 1px solid #00000048;
}
.group-box {
  font-size: 14px;
  font-weight: 500;
}
.capitalize {
  text-transform: capitalize;
}
.v-tab {
  font-size: 14px !important;
  text-transform: inherit;
  font-weight: 600;
}
</style>
