
import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class PermissionManagement extends ServiceBase {
    async getGroups(idCompany) {
        return await axiosInstance.get(API_URL + `api/V2/Access/ResourceGroup/List?idCompany=${idCompany}`);
    }

    async linkUserToGroup(IdResourceGroup, idCompany) {
        return await axiosInstance.get(API_URL + `api/V2/Access/ResourceGroup/LinkUser?IdResourceGroup=${IdResourceGroup}&idCompany=${idCompany}`);
    }

    async allowAccessUser(request) {
        return await axiosInstance.put(API_URL + `api/V2/Access/ResourceGroup/LinkUser`, request);
    }

    async getUsers(idCompany) {
        return await axiosInstance.get(API_URL + `api/Usuario/GetusuariosByIdEmpresa?idEmpresa=${idCompany}`);
    }
}
