<template>
  <!-- PML -->
  <div class="content-box">
    <span>{{ title }} </span>

    <v-skeleton-loader v-if="loading" type="card" height="22px" />

    <span v-if="!loading && formater" :class="[destaqColor ? destaqColor : '']">
      {{ formater(value) }}
    </span>

    <span
      v-if="!loading && !formater"
      :class="[destaqColor ? destaqColor : '']"
      >{{ value }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true,
    },
    value: {
      require: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    formater: {
      type: Function,
      default: null,
    },
    borderColor: {
      type: String,
      default: null,
    },
  },

  computed: {
    destaqColor() {
      if (!this.borderColor) return null;
      else return this.borderColor;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-box {
  span:first-child {
    font-size: 12px;
    margin-bottom: 8px !important;
  }

  span:nth-child(2) {
    font-size: 16px;
    font-weight: bold;
    color: $brand-color-primary-pure;
  }
}

.card-color-black {
  color: $brand-color-primary-pure;
  background-color: $brand-color-primary-light;
  padding: 4px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  background-color: $neutral-color-low-medium;
  color: black;
  font-weight: 400;
}

.card-color-info {
  color: $brand-color-primary-pure;
  background-color: $brand-color-primary-light;
  padding: 4px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  background-color: $brand-color-primary-light;
  color: black;
  font-weight: 400;
}
.card-color-success {
  color: $brand-color-primary-pure;
  background-color: $brand-color-primary-light;
  padding: 4px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  background-color: $feedback-color-success-light;
  color: black;
  font-weight: 400;
}
.card-color-error {
  color: $brand-color-primary-pure;
  background-color: $brand-color-primary-light;
  padding: 4px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  background-color: $feedback-color-warning-light;
  color: black;
  font-weight: 400;
}
.card-color-warning {
  color: $brand-color-primary-pure;
  background-color: $brand-color-primary-light;
  padding: 4px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  background-color: $feedback-color-helper-light;
  color: black;
  font-weight: 400;
}
</style>