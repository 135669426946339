<template>
  <v-container fluid>
    <v-row no-gutters align="center" justify="space-between">
      <titleBreadCrumbsComponent
        :title="$t('TXT_DINAMIC_PRICING_RULE').toUpperCase()"
        :breadCrumbs="breadCrumbs"
        :hasTooltip="false"
      />
      <router-link to="/main/pricing-rules">
        <prd-btn
          :title="$t('TXT_BACK')"
          :icon="'mdi-arrow-left'"
          :leftIcon="true"
          @click="resetFieldsFlag = !resetFieldsFlag"
        />
      </router-link>
    </v-row>
    <v-card
      class="pa-4 pt-0"
      :loading="loadingSavingChanges"
      :disabled="loadingSavingChanges"
    >
      <Handler-rule-datas
        :idRule="0"
        :ruleForFieldArea="groupRules"
        :loadingSavingChanges="loadingSavingChanges"
        :description="groupRules.description"
        :isAllProducts="groupRules.isAllProducts"
        :numberOrder="groupRules.numberOrder"
        :resetFieldsFlag="resetFieldsFlag"
        @updateDescription="groupRules.description = $event"
        @updateIsAllProducts="groupRules.isAllProducts = $event"
        @updateElasticity="elasticity = $event"
        @updateCurve="curve = $event"
        @updateFilters="groupRules.filters = $event"
        @updateResetFieldsFlag="resetFieldsFlag = $event"
        @saveRules="saveRules"
      />
    </v-card>
  </v-container>
</template>

<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import service from "@/service/pricing-rules/create-rules.js";
const Service = new service();
import HandlerRuleDatas from "./handler-rule-datas/handler-rule-datas.vue";
import GroupRules from "../../../utils/GroupRules";
export default {
  name: "create-pricing-rule",
  components: {
    titleBreadCrumbsComponent,
    PrdBtn,
    HandlerRuleDatas,
  },
  data() {
    return {
      breadCrumbs: [
        {
          text: this.$i18n.t("TXT_MARKET_INTELLIGENCE"),
        },
        {
          text: "Predimonitor",
        },
      ],
      loadingSavingChanges: false,
      resetFieldsFlag: false,
      groupRules: null,
      elasticity: 0,
      curve: "ABC",
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    textButton() {
      return this.idRule
        ? this.$i18n.t("TXT_SAVE_EDITIONS")
        : this.$i18n.t("TXT_ADD_RULE");
    },
  },

  methods: {
    async saveRules() {
      this.loadingSavingChanges = true;
      let request = this.groupRules.buildRequest(this.elasticity, this.curve);
      try {
        await Service.saveRule(request);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_SAVED_RULES_SUCCESS"),
          type: "success",
        });
        this.$router.push("/main/pricing-rules");
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_SAVED_RULES_ERROR"),
          type: "error",
        });
      }

      this.loadingSavingChanges = false;
    },
  },

  async created() {
    this.$store.commit("removeGroupRules", { idRule: 0 });
    this.groupRules = new GroupRules(0, "", true, 0);
    this.$store.commit("insertGroupRules", this.groupRules);
  },

  beforeDestroy() {
    this.$store.commit("removeGroupRules", { idRule: 0 });
  },
};
</script>
