<template>
  <div>
    <v-tabs
      :slider-color="$prdStyles('color-secundary')"
      :slider-size="5"
      color="black"
      height="40px"
      class="border-destaq mb-6"
    >
      <v-tab class="pa-0">
        {{ $t('TXT_MESSAGE_HISTORY') }} ({{ ticketMessages.messages.length }})
      </v-tab>
    </v-tabs>

    <div class="message-list">
      <div
        v-for="message in ticketMessages.messages"
        :key="message.idSupport_Ticket"
        class="message"
      >
        <div class="message-user admin">
          <span>{{ message.owner_name }}</span
          ><span>{{ formatDate(message.creation_date) }}</span>
        </div>
        <div class="message-text">
          <span v-html="message.formatedMessage"></span>
        </div>
      </div>
    </div>

    <v-row no-gutters justify="end">
      <Close-ticket-modal
        v-if="ticket.status != 3 &&  isPredifyAdmin"
        :ticket="ticket"
        @showTicketList="$emit('showTicketList')"
      />
    </v-row>
  </div>
</template>

<script>
import { formatDate } from "@/utils/prd-format-date.js";
import CloseTicketModal from "./close-ticket-modal.vue";
export default {
  props: ["ticketMessages", "ticket"],
  components: { CloseTicketModal },
  computed: {
    isPredifyAdmin() {
      return this.$store.getters.isPredifyAdmin;
    },
  },
  methods: {
    formatDate,
  },
};
</script>

<style lang="scss" scoped>
.border-destaq {
  border-bottom: 0.5px solid rgba(59, 58, 58, 0.185);
  pointer-events: none;
}
.v-tab {
  text-transform: capitalize !important;
  font-weight: bold;
}

.message-list {
  .message {
    border-radius: 5px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    margin-bottom: 12px;
    .message-user {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      padding: 10px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .message-text {
      background-color: #fff;
      padding: 6px 16px;
    }
  }
}

.admin {
  background-color: #f5f5f5;
}

.user {
  background-color: #ccc;
}
</style>