<template>
  <v-tooltip  :disabled="!tooltipText" top color="#f5f5f5">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-icon v-if="icon" small left :color="iconColor">{{ icon }}</v-icon>
        <span>{{ formatMonetary(price) }}</span>
      </div>
    </template>
    <span v-html="tooltipTextFormated" />
  </v-tooltip>
</template>

<script>
import { formatMonetary } from "@/utils/format-toMonetary.js";
export default {
  props: {
    icon: { type: String, default: "" },
    iconColor: {
      type: String,
      default: function () {
        return this.$prdStyles("color-primary");
      },
    },
    price: { type: Number, default: null },
    tooltipText: { type: String, default: "" },
  },
  methods: {
    formatMonetary,
  },
  computed: {
    tooltipTextFormated() {
      return this.tooltipText.startsWith("TXT_")
        ? this.$i18n.t(this.tooltipText)
        : this.tooltipText;
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  color: black;
  font-size: 12px;
}
</style>
