<template>
  <v-col v-if="idCompany" cols="auto">
    <v-row no-gutters align="start" class="ml-4">
      <h3>{{ idCompany }} - {{ name }}</h3>
    </v-row>
  </v-col>
</template>

<script>
export default {
  computed: {
    name() {
      return this.$store.getters.userData.companyName;
    },
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },
};
</script>