<template>
  <v-card class="pa-4">
    <v-row
      no-gutters
      justify="space-between"
      align="center"
      v-if="!isLoading && hasValuesForGraphic"
      class="mb-6"
    >
      <h4>
        {{ $t("TXT_COMPETITIVENESS").toUpperCase() }} BR MANIA x
        {{ selectedCompetitorType }}
      </h4>
      <Export-chart-main :nameStore="nameStore" :lastRequest="lastRequest" />
    </v-row>
    <Filters-main
      @getGraphicIC="getGraphicIC"
      @selectedCompetitorType="selectedCompetitorType = $event"
      :isLoading="isLoading"
    />
    <v-row no-gutters class="mt-6">
      <v-col cols="12" align="center">
        <v-progress-circular
          indeterminate
          color="#3C5CA7"
          size="50"
          v-if="isLoading"
        />
        <average-chart
          :dataGraphic="dataGraphic"
          v-if="!isLoading && hasValuesForGraphic"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
// IMPORTS HIGHCHARTS
import FiltersMain from "./filters-main.vue";
import ExportChartMain from "./export-chart/export-chart-main.vue";
import AverageChart from "./average-chart.vue";
import Service from "@/service/home20/index";
export default {
  components: { FiltersMain, ExportChartMain, AverageChart },
  props: ["listCategories", "stateByIdPriceGroup", "nameStore"],
  data() {
    return {
      selectedCompetitorType: null,
      dataGraphic: [],
      service: new Service(),
      isLoading: false,
      lastRequest: null,
    };
  },

  computed: {
    hasValuesForGraphic() {
      return this.dataGraphic.length > 0;
    },
  },

  methods: {
    getGraphicIC(data) {
      this.lastRequest = data;
      this.isLoading = true;
      this.service
        .GetGraficoIC(data)
        .then((res) => {
          if (res.status == 200) {
            this.dataGraphic = res.data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>