<template>
  <div>
    <category-chart :category="dataGraphic.category" />
    <div class="widthChart">
      <Average-chart :dataGraphic="dataGraphic.dataList" />
    </div>
  </div>
</template>

<script>
import AverageChart from "../../../../average-chart.vue";
import CategoryChart from "./category-chart.vue";

export default {
  components: { AverageChart, CategoryChart },
  props: ["dataGraphic"],
};
</script>

<style lang="scss" scoped>
.widthChart {
  // width: 720px;
  width: 1050px;
}
</style>