<template>
  <v-dialog
    v-model="isOpen"
    max-width="710"
    :persistent="isLoadingSaveChanges || isLoadingData"
  >
    <template v-slot:activator="{ on, attrs }">
      <Prd-btn
        v-bind="attrs"
        v-on="on"
        :title="$t('TXT_PACKAGING')"
        :outlined="true"
        :disabled="externalDisable"
      />
    </template>
    <v-card class="pa-4">
      <h3 class="mb-6">{{ $t("TXT_PACKAGING_MODAL_TITLE") }}</h3>
      <Prd-loading-msg
        v-if="isLoadingData"
        :text="`${this.$i18n.t('TXT_LOADING_PRODUCT_DATA')}: ${nome}`"
      />
      <div v-else>
        <v-row no-gutters class="mb-4 gap-4">
          <v-col cols="auto">
            <p class="mb-2">{{ $t("TXT_PRODUCT") }}</p>
            <p class="font-weight-bold">{{ nome }}</p>
          </v-col>
          <Prd-combo-box
            v-model="selected"
            :title="$t('TXT_PACKING')"
            :disabled="isLoadingSaveChanges || isLoadingData"
            :items="packs"
            :itemText="'descricaoUnificada'"
            @change="formatFactor"
          />

          <v-col cols="4">
            <div v-if="selected.embalagemPrincipal">
              <p class="mb-2">{{ $t("TXT_CONVERSION_FACTOR") }}</p>
              <p class="font-weight-bold">{{ $t("TXT_MAIN_PACKING") }}</p>
            </div>
            <Prd-text-field
              v-else
              v-model="selected.fatorConversao"
              :title="$t('TXT_CONVERSION_FACTOR')"
              :disabled="
                isLoadingSaveChanges ||
                isLoadingData ||
                selected.embalagemPrincipal
              "
              clearable
              @blur="formatFactor"
              @focus="formatFactor"
              @input="formatFactor"
            />
          </v-col>
        </v-row>

        <v-row no-gutters justify="end" class="gap-4">
          <Prd-btn
            :outlined="true"
            :title="$t('TXT_CANCEL')"
            :disabled="isLoadingSaveChanges || isLoadingData"
            @click="isOpen = false"
          />
          <Prd-btn
            :title="$t('TXT_SAVE')"
            :disabled="isLoadingData || !hasChanges"
            :loading="isLoadingSaveChanges"
            @click="saveChanges"
          />
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import PrdTextField from "@/components/common/prd-text-field.vue";
import UPLService from "@/service/upl";
import PrdLoadingMsg from "@/components/common/prd-loading-msg.vue";
export default {
  components: { PrdBtn, PrdComboBox, PrdTextField, PrdLoadingMsg },
  props: {
    externalDisable: {
      type: Boolean,
      default: false,
    },
    product: {
      typr: Object,
      default: () => ({ nome: "-" }),
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      isLoadingSaveChanges: false,
      isLoadingData: false,
      service: new UPLService(),
      selected: {
        id: null,
        descricaoUnificada: null,
        fatorConversao: null,
        embalagemPrincipal: false,
      },
      packs: [],
      originalPacks: [],
    };
  },
  computed: {
    nome() {
      return this.product?.nome ?? "-";
    },
    hasChanges() {
      const extractString = (array) =>
        JSON.stringify(
          array.map((pack) => String(pack?.fatorConversao).replace(" %", ""))
        );
      return extractString(this.packs) != extractString(this.originalPacks);
    },
  },
  watch: {
    isOpen: {
      handler(value) {
        if (value) this.getPackaging();
        else {
          this.selected = {
            id: null,
            descricaoUnificada: null,
            fatorConversao: null,
            embalagemPrincipal: false,
          };
          this.packs = [];
          this.originalPacks = [];
        }
      },
    },
  },
  methods: {
    formatFactor() {
      if (this.selected.fatorConversao == null) return;
      this.selected.fatorConversao = `${String(this.selected.fatorConversao)
        .replace(/[^0-9.-]/g, "")
        .replace(/\./g, ",")} %`;
    },

    async getPackaging() {
      this.isLoadingData = true;

      try {
        const response = await this.service.getPackaging(this.nome);
        const mapResponse = response.data.map(
          ({ id, descricaoUnificada, fatorConversao, embalagemPrincipal }) => ({
            id,
            descricaoUnificada,
            fatorConversao,
            embalagemPrincipal,
          })
        );
        this.packs = mapResponse;
        this.originalPacks = structuredClone(mapResponse);
        this.selected = mapResponse.find((pack) => pack.embalagemPrincipal);
        this.selected.fatorConversao = null;
      } catch (error) {
        this.$handleError(
          error,
          `${this.$i18n.t("TXT_GET_PACK_ERROR")}: ${this.nome}`
        );
      } finally {
        this.isLoadingData = false;
      }
    },

    async saveChanges() {
      this.isLoadingSaveChanges = true;
      const requests = this.extractUpdatedPacks();
      try {
        await Promise.all(
          requests.map(({ id, fatorConversao }) =>
            this.service.updatePack(id, { fatorConversao })
          )
        );
        this.$store.commit("snackbarV2/set", {
          type: "success",
          message: this.$i18n.t("TXT_UPDATE_PACK_SUCCESS"),
        });
        this.isOpen = false;
      } catch (error) {
        this.$handleError(
          error,
          `${this.$i18n.t("TXT_UPDATE_PACK_ERROR")}: ${this.nome}`
        );
      } finally {
        this.isLoadingSaveChanges = false;
      }
    },

    extractUpdatedPacks() {
      const cleanValue = (value) => {
        return value
          ? parseFloat(String(value).replace(" %", "").replace(",", "."))
          : 0;
      };

      return this.packs
        .filter((pack) => !pack.embalagemPrincipal)
        .map((pack) => {
          const original = this.originalPacks.find(
            (orPack) => orPack.id == pack.id
          );

          if (
            original &&
            cleanValue(pack.fatorConversao) != original.fatorConversao
          )
            return {
              id: pack.id,
              fatorConversao: cleanValue(pack.fatorConversao),
            };
        })
        .filter((pack) => pack);
    },
  },
};
</script>