<template>
  <v-container fluid>
    <v-row no-gutters justify="space-between" align="center">
      <titleBreadCrumbs
        :title="$t('TXT_SUPPORT_UPPERCASE')"
        :breadCrumbs="breadCrumbs"
        :hasTooltip="false"
        :showTooltip="false"
      />

      <prd-btn
        v-if="showCreateTicket || ticketDetails"
        :title="$t('TXT_BACK')"
        :icon="'mdi-arrow-left'"
        :leftIcon="true"
        @click="backToMain"
      />
    </v-row>

    <v-card v-if="!showCreateTicket && !ticketDetails" class="pa-4">
      <v-row no-gutters justify="space-between" align="center">
        <h4>{{ $t("TXT_TICKET_SUPPORT_HISTORY") }}</h4>
        <div>
          <Prd-btn
            :title="$t('TXT_FREQUENT_QUESTIONS')"
            :outlined="true"
            :icon="'mdi-help-circle-outline'"
            :leftIcon="true"
            class="mr-4"
            @click="
              $router.push({
                name: 'faqs',
                params: { data: { previousPagePath: '/main/support' } },
              })
            "
          />
          <Prd-btn
            :title="$t('TXT_OPEN_TICKET')"
            :icon="'mdi-ticket-confirmation-outline'"
            :leftIcon="true"
            @click="showCreateTicket = true"
          />
        </div>
      </v-row>

      <Filters-main :isLoading="isLoading" v-if="false" />
      <Ticket-list
        @showTicketDetails="showTicketDetails"
        @setLoading="isLoading = $event"
        :isLoading="isLoading"
      />
    </v-card>

    <Create-ticket
      v-if="showCreateTicket"
      @showTicketList="showCreateTicket = false"
    />
    <Ticket-details
      v-if="ticketDetails"
      :ticket="ticket"
      @showTicketList="backToMain"
    />
  </v-container>
</template>


<script>
import titleBreadCrumbs from "@/Design_System/common/title.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import FiltersMain from "./components/ticket-list/filters-main.vue";
import TicketList from "./components/ticket-list/ticket-list.vue";
import CreateTicket from "./components/create-ticket/create-ticket.vue";
import TicketDetails from "./components/ticket-details/ticket-details.vue";

export default {
  name: "support-main",
  components: {
    titleBreadCrumbs,
    PrdBtn,
    FiltersMain,
    TicketList,
    CreateTicket,
    TicketDetails,
  },
  data() {
    return {
      breadCrumbs: [
        {
          text: this.$i18n.t("TXT_SUPPORT"),
        },
      ],
      showCreateTicket: false,
      ticketDetails: false,
      ticket: null,
      isLoading: true,
    };
  },
  computed: {
    isPredifyAdmin() {
      return this.$store.getters.isPredifyAdmin;
    },
  },

  methods: {
    showTicketDetails(ticket) {
      this.ticket = ticket;
      this.ticketDetails = true;
    },
    backToMain() {
      this.showCreateTicket = false;
      this.ticketDetails = false;
    },
  },
};
</script>

<style>
</style>