<template>
  <v-dialog persistent max-width="860px" v-model="localIsOpen">
    <v-card flat class="pa-4">
      <h3 class="mb-4">{{ $t("TXT_SAVE_NEW_DELIBERATION") }}</h3>

      <v-radio-group v-model="isNextMonth" row :disabled="isLoading">
        <v-radio
          :label="`${$t('TXT_SAVE_FOR_MONTH')} ${$t(
            'TXT_CURRENT'
          ).toLowerCase()}`"
          :value="false"
          :color="$prdStyles('color-primary')"
        />
        <v-radio
          :label="`${$t('TXT_SAVE_FOR_MONTH')} ${$t(
            'TXT_FOLLOWING'
          ).toLowerCase()}`"
          :value="true"
          :color="$prdStyles('color-primary')"
        />
      </v-radio-group>

      <v-row no-gutters justify="center" class="red-border mb-4">
        <v-col cols="12">
          <v-row no-gutters justify="center">
            <span>
              <v-icon :color="$prdStyles('color-warning')" class="mr-2"
                >mdi-alert-outline</v-icon
              >
              {{ $t("TXT_DELIBERATION_ALERT") }}
            </span>
          </v-row>
          <v-row no-gutters justify="center">
            <span class="red--text">
              {{ $t("TXT_ACTION_CANNOT_BE_UNDONE") }}
            </span>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters justify="end">
        <prd-btn
          :disabled="isLoading"
          @click="$emit('closeModal')"
          :title="'Cancelar'"
          :outlined="true"
          class="mr-2"
        />
        <prd-btn
          :disabled="isLoading"
          :loading="isLoading"
          @click="$emit('createNewDeliberation', isNextMonth)"
          :title="buttonTitle"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
export default {
  props: ["isLoading"],
  components: { prdBtn },
  data() {
    return {
      localIsOpen: true,
      isNextMonth: false,
    };
  },
  computed: {
    buttonTitle() {
      return this.isNextMonth
        ? `${this.$i18n.t("TXT_SAVE_FOR_MONTH")} ${this.$i18n
            .t("TXT_FOLLOWING")
            .toLowerCase()}`
        : `${this.$i18n.t("TXT_SAVE_FOR_MONTH")} ${this.$i18n
            .t("TXT_CURRENT")
            .toLowerCase()}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.red-border {
  border: 2px solid red;
  border-radius: 8px;
  padding: 8px;
}
</style>