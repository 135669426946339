<template>
  <div>
    <prd-btn
      :title="$i18n.t('TXT_IA_DAYS')"
      @click="isDialogOpen = true"
      :disabled="readOnly"
    />
    <v-dialog v-model="isDialogOpen" max-width="250" persistent>
      <v-card class="pa-4" flat id="new-action-dialog">
        <h4 class="text-center">{{ $t("TXT_VARIATION_VARIABLES") }}</h4>

        <v-row no-gutters class="mt-2 mb-4">
          <v-col>
            <label>{{ $t("TXT_DAY_HISTORY") }}</label>
            <v-text-field
              v-if="historicoIa"
              v-model="historicoIa.dias"
              :disabled="isLoading"
              dense
              outlined
              hide-details
              type="number"
              class="mt-1"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="gap-4" justify="center">
          <prd-btn
            @click="isDialogOpen = false"
            :title="$t('TXT_CANCEL')"
            :outlined="true"
            :disabled="isLoading"
          />
          <prd-btn
            :title="$t('TXT_SAVE')"
            @click="saveHistorico"
            :loading="isLoading"
            :disabled="isLoading"
          />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import ServiceUPL from "@/service/upl/index";

export default {
  props: ["readOnly"],
  components: { prdBtn },
  data() {
    return {
      isDialogOpen: false,
      isLoading: false,
      historicoIa: null,
      service: new ServiceUPL(),
    };
  },
  methods: {
    async saveHistorico() {
      this.isLoading = true;
      let message,
        type = null;
      await this.service
        .addHistoricoIaVariavel(this.historicoIa)
        .then((res) => {
          if (res.status == 201) {
            message = "Informação salva com sucesso !";
            type = "success";
            this.isDialogOpen = false;
          } else {
            message = "Problemas ao salvar a informação, tente novamente !";
            type = "info";
          }
        })
        .catch((error) => {
          console.log(error);
          message =
            "Erro ao salvar a informação, tente novamente, se o problema persistir, contate a PREDIFY !";
          type = "error";
        })
        .finally(() => {
          this.isLoading = false;
          this.$store.commit("snackbarV2/set", { message, type });
        });
    },
  },
  async beforeMount() {
    await this.service.getHistoricoIaVariavel().then((res) => {
      this.historicoIa = res.data;
    });
  },
};
</script>
