import { render, staticRenderFns } from "./chart-adoption-main.vue?vue&type=template&id=5249d83e&scoped=true"
import script from "./chart-adoption-main.vue?vue&type=script&lang=js"
export * from "./chart-adoption-main.vue?vue&type=script&lang=js"
import style0 from "./chart-adoption-main.vue?vue&type=style&index=0&id=5249d83e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5249d83e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VRow,VSpacer,VTabItem,VTabsItems})
