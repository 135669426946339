<template>
  <div>
    <p>{{ $t("TXT_INSERT_EMAIL") }}</p>
    <v-row no-gutters class="gap-4" align="end">
      <Prd-text-field
        v-model="email"
        :title="$t('TXT_EMAIL')"
        :placeholder="'email@email.com'"
        :loading="isLoading || isLoadingProps"
        :disabled="isLoading || isLoadingProps || isNotExistsEmail"
      />
      <v-icon
        :disabled="
          isLoading || isLoadingProps || isNotExistsEmail || !isEmailPattern
        "
        :color="$prdStyles('color-success')"
        size="42"
        @click="checkEmail"
        >mdi-checkbox-marked</v-icon
      >
    </v-row>
    <p class="redText mt-4" v-if="isNotExistsEmail">
      {{ $t("TXT_NO_EXISTS_EMAIL") }}
    </p>
  </div>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
import GeneralSystemCongisService from "@/service/general-system-configs/index";

export default {
  components: {
    PrdTextField,
  },
  props: {
    resetFields: {
      type: Boolean,
    },
    isLoadingProps: {
      type: Boolean,
    },
  },
  data() {
    return {
      email: null,
      isNotExistsEmail: false,
      isLoading: false,
      service: new GeneralSystemCongisService(),
    };
  },
  computed: {
    isEmailPattern() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(this.email);
    },
  },
  watch: {
    email: {
      handler(value) {
        this.$emit("email", value);
      },
      immediate: true,
    },
    isNotExistsEmail: {
      handler(value) {
        this.$emit("isNotExistsEmail", value);
      },
      immediate: true,
    },
    resetFields() {
      this.email = null;
      this.isNotExistsEmail = false;
    },
  },
  methods: {
    async checkEmail() {
      this.isLoading = true;
      let message, type;
      try {
        const response = await this.service.getUser(this.email);
        message = this.$i18n.t("TXT_LOCATE_USER_SUCCESS");
        type = "success";
        this.$emit("linkUserCompany", response.data);
      } catch (error) {
        if (error.response.status == 400) {
          message = this.$i18n.t("TXT_LOCATE_USER_PROBLEM");
          type = "info";
          this.isNotExistsEmail = true;
        } else {
          console.log(error);
          message = this.$i18n.t("TXT_LOCATE_USER_PROBLEM");
          type = "error";
        }
      } finally {
        this.isLoading = false;
        this.$store.commit("snackbarV2/set", { message, type });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.redText {
  color: $feedback-color-warning-pure;
}
</style>