import { render, staticRenderFns } from "./deliberation-table.vue?vue&type=template&id=4dd17058&scoped=true"
import script from "./deliberation-table.vue?vue&type=script&lang=js"
export * from "./deliberation-table.vue?vue&type=script&lang=js"
import style0 from "./deliberation-table.vue?vue&type=style&index=0&id=4dd17058&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dd17058",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VDataTable,VIcon,VTextField,VTooltip})
