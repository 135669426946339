import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";
import { makeServiceMethod } from "./smart-service";

export default class PredifutService extends ServiceBase {
  listEvents = makeServiceMethod(
    async (idCompany) => {
      const response = await axiosInstance.get(
        API_URL + `api/V2/Enterprise/Events/ListHistory?idCompany=${idCompany}`
      );
      return response.data;
    }
  );

  listPlaces = makeServiceMethod(
    /**
     * @param {number} idCompany
     * @returns { Promise<{
     * 	answer: {
     * 		idEnterpriseEventPlace: number,
     * 		name: string,
     * 		maximum_capacity: number,
     * 		sector: any,
     * 	}[],
     * 	answerType: number,
     * 	warningMessages: string[],
     * }> }
     */
    async (idCompany) => {
      const response = await axiosInstance.get(
        API_URL + `api/V2/Enterprise/Events/ListPlaces?idCompany=${idCompany}`
      );
      return response.data;
    }
  );

  listTickets = makeServiceMethod(async (idCompany) => {
    const response = await axiosInstance.get(
      API_URL + `api/V2/Enterprise/Events/ListTickets?idCompany=${idCompany}`
    );
    return response.data;
  });

  getHistoryCalculated = makeServiceMethod(
    /**
     * @param {number} idCompany
     * @param {number} idEnterpriseEventHistory
     * @returns { Promise<{
     * 	answer: {
     * 		idEnterpriseEventHistory: number,
     * 		min_price: number,
     * 		max_price: number,
     * 		audience: number,
     * 		expense: number,
     * 		profit: number,
     * 		place: any,
     * 		crowding: any,
     * 	},
     * 	answerType: number,
     * 	warningMessages: string[],
     * }> }
     */
    async (idCompany, idEnterpriseEventHistory) => {
      const response = await axiosInstance.get(
        API_URL +
        `api/V2/Enterprise/Events/GetHistoryCalculated?idCompany=${idCompany}&idEnterpriseEventHistory=${idEnterpriseEventHistory}`
      );
      return response.data;
    }
  );

  listCrowding = makeServiceMethod(
    /**
     * @param {number} idCompany
     * @param {number} idEnterpriseEventPlace
     * @returns { Promise<{
     * 	answer: {
     * 		idEnterpriseEventCrowding: number,
     * 		name: string,
     * 		capacity: number,
     * 		default_estimate_public: number,
     * 	}[],
     * 	answerType: number,
     * 	warningMessages: string[],
     * }> }
     */
    async (idCompany, idEnterpriseEventPlace) => {
      const response = await axiosInstance.get(
        API_URL +
        `api/V2/Enterprise/Events/ListCrowding?idCompany=${idCompany}&idEnterpriseEventPlace=${idEnterpriseEventPlace}`
      );
      return response.data;
    }
  );

  crowding = makeServiceMethod(
    /**
     * @param {number} idCompany
     * @param {number} idEnterpriseEventCrowding
     * @returns { Promise<{
     * 	answer: {
     * 		idEnterpriseEventCrowding: number,
     * 		name: string,
     * 		capacity: number,
     * 		default_estimate_public: number,
     * 		sector_release: {
     * 			idEnterpriseEventSectorRelease: number,
     * 			idEnterpriseEventSector: number,
     * 			tickets_selected: number[],
     * 		}[],
     * 	},
     * 	answerType: number,
     * 	warningMessages: string[],
     * }> }
     */
    async (idCompany, idEnterpriseEventCrowding) => {
      const response = await axiosInstance.get(
        API_URL +
        `api/V2/Enterprise/Events/Crowding?idCompany=${idCompany}&idEnterpriseEventCrowding=${idEnterpriseEventCrowding}`
      );
      return response.data;
    }
  );

  place = makeServiceMethod(
    /**
     * @param {number} idCompany
     * @param {number} idEnterpriseEventPlace
     * @returns { Promise<{
     * 	answer: {
     * 		idEnterpriseEventPlace: number,
     * 		name: string,
     * 		maximum_capacity: number,
     * 		sectors: {
     * 			idEnterpriseEventSector: number,
     * 			name: string,
     * 		}[],
     * 	},
     * 	answerType: number,
     * 	warningMessages: string[],
     * }> }
     */
    async (idCompany, idEnterpriseEventPlace) => {
      const response = await axiosInstance.get(
        API_URL +
        `api/V2/Enterprise/Events/Place?idCompany=${idCompany}&idEnterpriseEventPlace=${idEnterpriseEventPlace}`
      );
      return response.data;
    }
  );

  simulateGame = makeServiceMethod(
    /**
     * @param {Object} data
     * @returns { Promise<{
     *   income: number,
     *   tickets_result: {
     *     idEnterpriseEventTicket: number,
     *     total_member_espected: number,
     *     sectors: {
     *       idEnterpriseEventSector,
     *       ticket_price: number,
     *     }[],
     *   }[],
     * }> }
     */
    async (data) => {
      const response = await axiosInstance.post(
        API_URL + "api/V2/Enterprise/Events/Simulate",
        data
      );
      return response.data;
    }
  );

  async listSectors(idCompany, idEnterpriseEventPlace) {
    return await axiosInstance.get(API_URL + "api/v2/Enterprise/Events/ListSectors", {
      params: {
        idCompany: idCompany,
        idEnterpriseEventPlace: idEnterpriseEventPlace
      }
    });
  }
}
