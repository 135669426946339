<template>
  <v-card class="pa-4" style="position: relative">
    <Prd-loading-circular v-if="isLoading" class="div-centered" />

    <div>
      <div v-for="item in data" :key="item.propertyName">
        <p v-if="item.hideFieldName" class="title-product mb-4">
          {{ item.value }}
        </p>

        <p v-else>
          <span>{{ item.propertyName }} : {{ item.value }}</span>
        </p>
      </div>
    </div>
  </v-card>
</template>

<script>
import service from "@/service/pricer/product-list.js";
const Service = new service();
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import { formatMonetary } from "@/utils/format-toMonetary.js";

export default {
  components: { PrdLoadingCircular },
  props: ["idEnterprisePricesProjection"],
  data() {
    return {
      isLoading: false,
      data: [],
    };
  },
  methods: {
    formatMonetary,

    async getProductInfo() {
      this.isLoading = true;

      try {
        let response = await Service.getProductInfo(
          this.idEnterprisePricesProjection
        );
        this.data = response?.data?.result ?? [];
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_GET_ELASTICITY"),
          type: "error",
        });
        this.data = [];
      }

      this.isLoading = false;
    },
  },
  created() {
    this.getProductInfo();
  },
};
</script>

<style lang="scss" scoped>
.div-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
p {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.title-product {
  font-size: 18px;
  font-weight: bold;
}
</style>