<template>
  <div>
    <v-btn
      :color="$prdStyles('color-primary')"
      large
      icon
      rounded
      @click="isModalOpen = true"
      class="teste"
    >
      <v-icon>mdi-list-box</v-icon>
    </v-btn>

    <v-dialog :width="600" v-model="isModalOpen">
      <v-card class="pa-4" :disabled="erpLoading">
        <h4 class="mb-4">ENVIAR PRODUTOS PARA ERP</h4>

        <v-row v-if="erpListLoading" class="mt-10" no-gutters justify="center">
          <v-progress-circular
            indeterminate
            :color="$prdStyles('color-primary')"
          ></v-progress-circular>
        </v-row>

        <div v-if="!erpListLoading">
          <v-tabs v-model="tab">
            <v-tab>Prontos para envio</v-tab>
            <v-tab>Enviados</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <div class="erp-list">
                <v-data-table
                  :items="erpList"
                  :headers="headers"
                  :items-per-page="5"
                  :hide-default-footer="
                    erpList.length == 0 || erpList.length <= 5
                  "
                >
                  <!-- checkbox to send -->
                  <template v-slot:[`item.readyToSend`]="{ item }">
                    <td
                      style="
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <v-checkbox
                        v-model="item.readyToSend"
                        hide-details
                        dense
                        class="ma-0 pa-0 ml-2"
                      ></v-checkbox>
                    </td>
                  </template>
                </v-data-table>
              </div>

              <!-- <div class="erp-list">
                <v-row
                  v-if="erpList.length == 0"
                  class="mb-6"
                  no-gutters
                  justify="center"
                >
                  <h4>Nenhum produto selecionado</h4>
                </v-row>
                <div v-else class="list-item-box">
                  <div
                    v-for="(product, index) in erpList"
                    :key="index"
                    class="list-item"
                  >
                    <span class="font-weight-bold"
                      >Artigo {{ product.product }} -
                      {{ product.productName }}</span
                    >
                    <v-checkbox
                      hide-details
                      v-model="product.readyToSend"
                      class="mb-5"
                    ></v-checkbox>
                  </div>
                </div>
              </div> -->
            </v-tab-item>

            <v-tab-item>
              <div class="erp-list">
                <v-data-table
                  :items="erpListSended"
                  :headers="headersSended"
                  :items-per-page="5"
                  :hide-default-footer="
                    headersSended.length == 0 || erpList.length <= 5
                  "
                >
                  <!-- checkbox to send -->
                  <template v-slot:[`item.readyToSend`]="{ item }">
                    <td
                      style="
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <v-checkbox
                        v-model="item.readyToSend"
                        hide-details
                        dense
                        class="ma-0 pa-0 ml-2"
                      ></v-checkbox>
                    </td>
                  </template>
                </v-data-table>
              </div>

              <!-- <div class="erp-list">
                <v-row
                  v-if="erpListSended.length == 0"
                  class="mb-6"
                  no-gutters
                  justify="center"
                >
                  <h4>Nenhum produto selecionado</h4>
                </v-row>
                <div v-else class="list-item-box">
                  <div
                    v-for="(product, index) in erpListSended"
                    :key="index"
                    class="list-item"
                  >
                    <span class="font-weight-bold py-4"
                      >Artigo {{ product.product }} -
                      {{ product.productName }}</span
                    >
                  </div>
                </div>
              </div> -->
            </v-tab-item>
          </v-tabs-items>
        </div>

        <v-row no-gutters align="center" justify="end" class="mt-4">
          <prd-btn
            :title="'Cancelar'"
            :outlined="true"
            :disabled="erpLoading"
            @click="closeModal"
            class="mr-4"
          />
          <prd-btn
            :disabled="erpLoading || erpList.length == 0 || erpListLoading"
            :loading="erpLoading"
            :title="'Enviar para ERP'"
            @click="sendToERP"
          />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import headerService from "@/service/new-ai-pricing/header.js";
const HeaderService = new headerService();
export default {
  props: ["idPricegroup"],
  components: { prdBtn },
  data() {
    return {
      isModalOpen: false,
      erpLoading: false,
      erpListLoading: false,
      tab: 0,
      erpList: [],
      erpListSended: [],
      headers: [
        {
          text: "Artigo",
          value: "product",
          align: "center",
          sortable: true,
        },
        {
          text: "Nome do produto",
          value: "productName",
          align: "center",
          sortable: true,
        },
        {
          text: "Enviar",
          value: "readyToSend",
          align: "center",
          sortable: false,
        },
      ],
      headersSended: [
        {
          text: "Artigo",
          value: "product",
          align: "center",
          sortable: true,
        },
        {
          text: "Nome do produto",
          value: "productName",
          align: "center",
          sortable: true,
        },
      ],
    };
  },

  watch: {
    isModalOpen() {
      if (this.isModalOpen) {
        this.getErpList();
      } else {
        this.erpList = [];
        this.erpListSended = [];
      }
    },
  },

  methods: {
    // Function to get registered users in the ERP
    getErpList() {
      // start loading
      this.erpListLoading = true;

      // Go to the database to get the data
      HeaderService.getErpList(this.idPricegroup)
        .then((res) => {
          let response = res?.data?.result ?? [];
          // Applies the to send rule to display on the screen
          response.forEach((el) => {
            el.readyToSend = false;

            if (el.isPublished == true) {
              this.erpListSended.push(el);
            } else {
              this.erpList.push(el);
            }
          });

          // finish loading
          this.erpListLoading = false;
        })
        .catch((error) => {
          console.log(error);

          // finish loading
          this.erpListLoading = false;
        });
    },

    sendToERP() {
      this.erpLoading = true;

      let erpListToSend = [];

      this.erpList.forEach((el) => {
        if (el.readyToSend) {
          erpListToSend.push({
            idEnterprisePricesProjection: el.idEnterprisePriceProjection,
          });
        }
      });

      HeaderService.sendToERP(erpListToSend)
        .then(() => {
          this.$emit("attWorfklowERP");
          this.isModalOpen = false;
          this.erpLoading = false;
          this.erpList = [];
          this.erpListSended = [];
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_SUCCESS_SEND_ITEMS"),
            type: "success",
          });
        })
        .catch((error) => {
          console.log("Erro no sendERP", error);
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_ERROR_SEND_TO_ERP"),
            type: "error",
          });
          this.isModalOpen = false;
          this.erpLoading = false;
          this.erpList = [];
          this.erpListSended = [];
        });
    },

    closeModal() {
      this.erpList = [];
      this.erpListSended = [];

      this.isModalOpen = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.teste {
  position: fixed !important;
  bottom: 20px !important; /* Ajuste essa propriedade conforme necessário */
  right: 20px !important; /* Ajuste essa propriedade conforme necessário */
  z-index: 5000000;
}

.erp-list {
  max-height: 400px !important;
  overflow-y: scroll;
  margin-top: 12px;
}

.list-item-box {
  background-color: $neutral-color-high-light;
  margin-bottom: 8px;

  .list-item {
    padding: 10px;
    border-bottom: 1px solid #ccc;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.to-send {
  padding-top: 40px;
  padding-bottom: 40px;
}
</style>