<template>
  <v-card class="pa-4">
    <h3 class="mb-4">{{ $t("TXT_ALTER_PASSWORD") }}</h3>
    <v-divider />
    <div v-for="(field, index) in fieldList" :key="index" class="mt-4">
      <label>{{ field.text }}</label>
      <v-text-field
        v-model="field.value"
        :placeholder="field.text"
        :type="field.type"
        :append-icon="`mdi-eye${field.type == 'password' ? '' : '-off'}`"
        :disabled="isLoading"
        @click:append="
          field.type = field.type === 'password' ? 'text' : 'password'
        "
        dense
        outlined
        hide-details
        class="mt-1"
      />
    </div>
    <v-row no-gutters justify="end" class="my-4 gap-4">
      <prd-btn
        :title="$t('TXT_CLEAR')"
        :outlined="true"
        :disabled="isLoading"
        @click="resetForm"
      />
      <prd-btn
        :title="$t('TXT_ALTER_PASSWORD')"
        :disabled="!unlockConfirmButton || isLoading"
        :loading="isLoading"
        @click="updatePassword"
      />
    </v-row>
  </v-card>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import HeaderService from "@/service/header/index.js";
export default {
  components: { PrdBtn },
  data() {
    return {
      service: new HeaderService(),
      isLoading: false,
      fieldList: [
        {
          text: this.$i18n.t("TXT_CURRENT_PASSWORD"),
          value: null,
          type: "password",
        },
        {
          text: this.$i18n.t("TXT_NEW_PASSWORD"),
          value: null,
          type: "password",
        },
        {
          text: this.$i18n.t("TXT_CONFIRM_PASSWORD"),
          value: null,
          type: "password",
        },
      ],
    };
  },
  computed: {
    unlockConfirmButton() {
      const allValuesFilledIin =
        this.fieldList[0].value &&
        this.fieldList[1].value &&
        this.fieldList[2].value;
      const confirmPassword =
        this.fieldList[1].value == this.fieldList[2].value;
      return allValuesFilledIin && confirmPassword;
    },
    id() {
      return this.$store.getters.userData.id;
    },
  },
  methods: {
    resetForm() {
      this.fieldList.forEach((field) => {
        field.value = null;
        field.type = "password";
      });
    },
    createBodyForRequest(senha, novaSenha, confirmarSenha) {
      return { senha, novaSenha, confirmarSenha, id: this.id };
    },
    updatePassword() {
      this.isLoading = true;
      const request = this.createBodyForRequest(
        this.fieldList[0].value,
        this.fieldList[1].value,
        this.fieldList[2].value
      );
      this.service
        .UpdatePassword(request)
        .then((res) => {
          if (res.status == 200) {
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_CHANGE_PASSWORD_SUCCESS"),
              type: "success",
            });
            this.resetForm();
          }
        })
        .catch((error) => {
          if (error.response.status == 400) {
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_CHANGE_PASSWORD_INFO"),
              type: "info",
            });
          } else {
            this.$store.commit("snackbarV2/set", {
              message: this.$i18n.t("TXT_CHANGE_PASSWORD_ERROR"),
              type: "error",
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
