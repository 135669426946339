<template>
  <div v-if="isLoading" class="mt-2">
    <label class="font-weight-bold">
      {{ $i18n.t('TXT_COPILOT') }}
    </label>
    <div class="loading-bubble">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.loading-bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 25px;
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 5px;
}

.dot {
  width: 5px;
  height: 5px;
  margin: 0 2px;
  background-color: #000;
  border-radius: 50%;
  animation: blink 1.4s infinite both;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
</style>
