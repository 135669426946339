<template>
  <div>
    <h4>{{ $t("TXT_MARKET").toUpperCase() }}</h4>
    <v-row no-gutters align="end">
      <v-col class="pa-0 mt-4 mr-2">
        <label>Market share</label>
        <v-text-field
          hide-details
          outlined
          dense
          :filled="!isLoadingMarket"
          :disabled="isLoadingMarket"
          class="mt-2"
          v-model="marketShare"
          @click="setEdit('marketShare')"
        />
      </v-col>
      <v-col cols="auto">
        <v-row no-gutters class="gap-1" v-if="isEditMarket && !isLoadingMarket">
          <v-icon :color="$prdStyles('color-success')" @click="updateMarketData"
            >mdi-checkbox-marked</v-icon
          >
          <v-icon
            :color="$prdStyles('color-warning')"
            @click="resetValues('marketShare')"
            >mdi-delete-outline</v-icon
          >
        </v-row>
        <v-progress-circular
          :color="$prdStyles('color-primary')"
          indeterminate
          class="mb-1"
          v-if="isLoadingMarket"
        />
      </v-col>
    </v-row>

    <v-row no-gutters align="end">
      <v-col class="pa-0 mt-4 mr-2">
        <label>{{ $t("TXT_POTENTIAL_TREATED_AREA") }} (Mio/Ha)</label>
        <v-text-field
          hide-details
          outlined
          dense
          :filled="!isLoadingMarket"
          :disabled="isLoadingMarket"
          class="mt-2"
          v-model="potencialAreaTratada"
          @click="setEdit('potencial')"
        />
      </v-col>
      <v-col cols="auto">
        <v-row
          no-gutters
          class="gap-1"
          v-if="isEditPotencial && !isLoadingMarket"
        >
          <v-icon :color="$prdStyles('color-success')" @click="updateMarketData"
            >mdi-checkbox-marked</v-icon
          >
          <v-icon
            :color="$prdStyles('color-warning')"
            @click="resetValues('potencial')"
            >mdi-delete-outline</v-icon
          >
        </v-row>
        <v-progress-circular
          :color="$prdStyles('color-primary')"
          indeterminate
          class="mb-1"
          v-if="isLoadingMarket"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UPLService from "@/service/upl";
export default {
  props: ["data", "idProduct"],
  data() {
    return {
      isLoadingMarket: false,
      isEditMarket: false,
      isEditPotencial: false,
      marketShare: null,
      potencialAreaTratada: null,
      marketShareOriginal: null,
      potencialAreaTratadaOriginal: null,
      service: new UPLService(),
    };
  },
  watch: {
    data: {
      handler(value) {
        this.marketShare = value?.marketShare ?? null;
        this.marketShareOriginal = value?.marketShare ?? null;
        this.potencialAreaTratada = value?.potencialAreaTratada ?? null;
        this.potencialAreaTratadaOriginal = value?.potencialAreaTratada ?? null;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setEdit(type) {
      if (type == "potencial" && !this.isEditPotencial)
        this.isEditPotencial = true;

      if (type == "marketShare" && !this.isEditMarket) this.isEditMarket = true;
    },

    resetValues(type) {
      if (type == "potencial") {
        this.isEditPotencial = false;
        this.potencialAreaTratada = this.potencialAreaTratadaOriginal;
      }

      if (type == "marketShare") {
        this.isEditMarket = false;
        this.marketShare = this.marketShareOriginal;
      }
    },

    setOriginalValues() {
      this.isEditPotencial = false;
      this.potencialAreaTratadaOriginal = this.potencialAreaTratada;
      this.isEditMarket = false;
      this.marketShareOriginal = this.marketShare;
    },

    async updateMarketData() {
      this.isLoadingMarket = true;
      try {
        const body = {
          marketShare: this.marketShare ?? "",
          potencialAreaTratada: this.potencialAreaTratada ?? "",
        };
        await this.service.updatMercadoProduct(this.idProduct, body);
        this.setOriginalValues();
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_UPDATED_DATA_SUCCESS"),
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_UPDATED_DATA_ERROR"),
          type: "error",
        });
      }

      this.isLoadingMarket = false;
    },
  },
};
</script>