import moment from "moment";

export function formatDate(dataISO, withMinutes) {
  if (!dataISO) return "-";

  var data = new Date(dataISO);

  var dia = data.getDate().toString().padStart(2, "0");
  var mes = (data.getMonth() + 1).toString().padStart(2, "0"); // Janeiro é 0!
  var ano = data.getFullYear();
  var hora = data.getHours().toString().padStart(2, "0");
  var minutos = data.getMinutes().toString().padStart(2, "0");

  if (!withMinutes) return `${dia}/${mes}/${ano}`;

  return `${dia}/${mes}/${ano} - ${hora}:${minutos}`;
}

export function momentFormatDate(date) {
  return moment.utc(date).format("DD/MM/YYYY");
}
