<template>
  <div>
    <p>
      {{ $t("TXT_EMAIL") }}:
      <span class="font-weight-bold ml-1">{{ user.email }}</span>
    </p>
    <p>
      {{ $t("TXT_USER_NAME") }}:
      <span class="font-weight-bold ml-1">{{ name }}</span>
    </p>
    <p>
      {{ $t("TXT_PHONE") }}:
      <span class="font-weight-bold ml-1">{{ setMaskPhone(phone) }}</span>
    </p>
    <p>
      {{ $t("TXT_LANGUAGE") }}:
      <span class="font-weight-bold ml-1">{{ getLanguage(language) }}</span>
    </p>
    <v-row no-gutters align="center" class="gap-1 mb-4">
      <span>{{ $t("TXT_ADMIN_ACCESS") }}:</span>
      <span class="font-weight-bold ml-1">
        {{ adminAccess == true ? "Sim" : "Não" }}
      </span>
      <v-icon
        color="white"
        :class="`adminIcon ${!adminAccess ? 'disabledIcon' : ''}`"
        >mdi-shield-account-outline</v-icon
      >
    </v-row>
    <v-row no-gutters>
      <span>{{ $t("TXT_REGIONAL_ACCESS") }}:</span>
      <v-col
        cols="auto"
        class="px-1"
        v-for="(access, index) in adminRoles"
        :key="index"
      >
        <v-row no-gutters align="center">
          <span class="font-weight-bold mr-2">
            {{ getNameRole(access.value ? access.value : access) }}
          </span>
          <img
            :src="setToCountryFlag(access.value ? access.value : access)"
            class="imageFlag"
          />
          <span v-if="index < adminRoles.length - 1" class="ml-4">|</span>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
    },
  },
  computed: {
    name() {
      return this.user.name ?? this.user.nome;
    },
    phone() {
      return this.user.phone ?? this.user.telefone;
    },
    language() {
      return this.user.lang ?? this.user.language.text;
    },
    adminAccess() {
      return this.user.adminAccess ?? this.user.isProprietario;
    },
    adminRoles() {
      const list = this.user.adminRoles ?? this.user.regionalAccess;
      const filteredList = list.filter(
        (level) => level == "1000" || level == "1001" || level == "1002"
      );
      return filteredList;
    },
  },
  methods: {
    setToCountryFlag(value) {
      switch (value) {
        case "1000":
          return require("../../../../../../../../../../../public/images/icons/flags/br.svg");
        case "1001":
          return require("../../../../../../../../../../../public/images/icons/flags/us.svg");
        case "1002":
          return require("../../../../../../../../../../../public/images/icons/flags/ue.svg");
        default:
          return "";
      }
    },
    setMaskPhone(phone) {
      let onlyNumbers = phone.replace(/\D/g, "");

      let newFormat =
        "(" +
        onlyNumbers.substring(0, 2) +
        ") " +
        onlyNumbers.substring(2, 7) +
        "-" +
        onlyNumbers.substring(7, 11);

      return newFormat;
    },
    getNameRole(level) {
      const regionalAccessList = {
        1000: this.$i18n.t("TXT_BRAZIL"),
        1001: this.$i18n.t("TXT_UNITED_STATES"),
        1002: this.$i18n.t("TXT_EUROPE"),
      };
      return regionalAccessList[level];
    },
    getLanguage(langKey) {
      if (langKey != "pt-br" && langKey != "en-us") return langKey;
      const languageList = {
        "pt-br": this.$i18n.t("TXT_PORTUGUESE"),
        "en-us": this.$i18n.t("TXT_ENGLISH"),
      };
      return languageList[langKey];
    },
  },
};
</script>

<style lang="scss" scoped>
.imageFlag {
  width: 24px;
  border-radius: 5px;
}
.adminIcon {
  background-color: $feedback-color-helper-pure;
  border-radius: 5px;
  padding-top: 0.5px;
}
.disabledIcon {
  background-color: $neutral-color-low-light !important;
}
</style>