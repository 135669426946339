<template>
  <v-row no-gutters class="gap-4">
    <prd-combo-box
      :disabled="initialList.length == 0"
      :title="$t('TXT_PRODUCT')"
      :useTooltipInformation="false"
      :items="productList"
      v-model="selectedProduct"
    />
    <prd-combo-box
      :title="$t('TXT_PRICES_PRATICED')"
      :useTooltipInformation="false"
      :items="priceList"
      :disabled="disabledSelectPrice || initialList.length == 0"
      v-model="selectedPrice"
    />
  </v-row>
</template>

<script>
import prdComboBox from "@/Design_System/common/prd-comboBox.vue";
export default {
  props: ["initialList"],
  components: { prdComboBox },
  data() {
    return {
      selectedProduct: null,
      initialProduct: { text: this.$i18n.t("TXT_ALL_PRODUCTS"), value: "all" },
      initialPrice: { text: this.$i18n.t("TXT_ALL_PRICES"), value: "all" },
      productList: [{ text: this.$i18n.t("TXT_ALL_PRODUCTS"), value: "all" }],
      selectedPrice: null,
      priceList: [
        { text: this.$i18n.t("TXT_ALL_PRICES"), value: "all" },
        { text: this.$i18n.t("TXT_PRICES_ABOVE"), value: "upper" },
        { text: this.$i18n.t("TXT_PRICES_BELOW"), value: "lower" },
        { text: this.$i18n.t("TXT_PRICES_MATCHED"), value: "equals" },
      ],
      disabledSelectPrice: false,
    };
  },
  methods: {
    prepareFilters() {
      this.selectedProduct = this.initialProduct;
      this.selectedPrice = this.initialPrice;
      this.productList = [this.initialProduct];
      const allProducts = this.initialList.map((product) => {
        return { text: product.description, value: product.ean };
      });
      this.productList.push(...allProducts);
    },
    filterList() {
      let filteredList = JSON.parse(JSON.stringify(this.initialList));

      if (
        this.selectedProduct.value != "all" &&
        this.selectedProduct.value != null
      ) {
        filteredList = filteredList.filter(
          (product) => product.ean == this.selectedProduct.value
        );
        this.disabledSelectPrice = true;
        this.selectedPrice = this.initialPrice;
      } else {
        this.disabledSelectPrice = false;
      }

      if (
        this.selectedPrice.value != "all" &&
        this.selectedPrice.value != null
      ) {
        const type = this.selectedPrice.value;
        switch (type) {
          case "equals":
            filteredList = filteredList.filter(
              (product) => product.lastPrice == product.fixedPrice
            );
            break;
          case "upper":
            filteredList = filteredList.filter(
              (product) => product.lastPrice > product.fixedPrice
            );
            break;
          case "lower":
            filteredList = filteredList.filter(
              (product) => product.lastPrice < product.fixedPrice
            );
            break;
        }
      }
      this.$emit("filteredList", filteredList);
    },
  },

  watch: {
    initialList: {
      handler() {
        this.prepareFilters();
        this.filterList();
      },
      immediate: true,
    },
    selectedProduct() {
      this.filterList();
    },
    selectedPrice() {
      this.filterList();
    },
  },
};
</script>