<template>
  <v-dialog persistent v-model="isModalOpen" :max-width="600">
    <v-card class="pa-4">
      <h4>PRODUTOS COM DIVÊRGENCIA</h4>
      <p class="mt-4 mb-8">
        Essa precificação apresenta produtos que possuem caráter inelástico. A
        aplicação e alteração deste preço não causará aumento de receita e
        poderá causar prejuízo.
      </p>

      <p v-show="invalidProductsList != 0" class="mt-4 mb-2">
        Os seguintes produtos apresentam divêrgencias:
        <span class="font-weight-bold">
          ({{ invalidProductsList.length }})
        </span>
      </p>

      <span class="font-weight-bold" v-if="invalidProductsList.length == 0"
        >Não foram encontrados resultados</span
      >

      <div v-else style="max-height: 300px; overflow-y: scroll">
        <div
          v-for="(invalidProduct, index) in invalidProductsList"
          :key="index"
          class="main-products"
        >
          <span class="font-weight-bold">{{ invalidProduct.description }}</span>
          <span v-show="invalidProduct.isInelastic" class="card-destaq"
            >Inelástico</span
          >
        </div>
      </div>

      <v-row no-gutters justify="end" class="mt-6">
        <prd-btn
          :title="'Cancelar'"
          :disabled="isLoading"
          :outlined="true"
          @click="closeModal"
          class="mr-4"
        />
        <prd-btn
          :title="'Concordar'"
          :disabled="isLoading"
          :loading="isLoading"
          @click="termsAccepted"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import searchService from "@/service/new-ai-pricing/search-filters.js";

const SearchService = new searchService();
export default {
  components: { prdBtn },
  props: ["invalidProductsModal", "invalidProductsList", "idPriceGroup"],
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
    };
  },
  watch: {
    invalidProductsModal() {
      this.isModalOpen = this.invalidProductsModal;
    },
  },
  methods: {
    termsAccepted() {
      this.isLoading = true;
      SearchService.termsAccepted(this.idPriceGroup.value)
        .then(() => {
          this.$emit("acceptedTerms");
          this.$emit("closeModal");
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("Erro ao aceitar termos", error);
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_ERROR_ACCEPT_TERMS"),
            type: "error",
          });
          this.isLoading = false;
        });
    },

    closeModal() {
      this.$emit("disagreeTerms");
    },
  },
};
</script>

<style lang="scss" scoped>
.main-products {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #b00020;
  border-radius: 10px;
  padding: 0.5rem;

  margin-bottom: 1rem;
}
.card-destaq {
  padding: 4px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  background-color: $feedback-color-warning-light;
  color: black;
  font-weight: 400;
}
</style>