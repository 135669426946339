<template>
  <div>
    <v-row no-gutters justify="center" class="mt-4">
      <DiscountMatrixTable
        v-if="subtitle == '' && name == 'DISCOUNT MATRIX'"
        :name="name"
        :subtitle="subtitle"
        :headers="headers"
        :items="items"
        @updateOriginalItemsModified="
          $emit('updateOriginalItemsModified', $event)
        "
        @updateMarketingVariationMatrix="
          $emit('updateMarketingVariationMatrix', $event)
        "
        @setBadgeDiscountMatrix="$emit('setBadgeDiscountMatrix', $event)"
      />
      <Marketing-variation-matrix-table
        v-else-if="subtitle == '' && name == 'MARKETING VARIATION MATRIX'"
        :name="name"
        :subtitle="subtitle"
        :headers="headers"
        :items="items"
        @updateAllOriginalItems="$emit('updateAllOriginalItems', $event)"
        @setBadgeRegionalizationMatrix="
          $emit('setBadgeRegionalizationMatrix', $event)
        "
      />

      <TargetMarketSegmentationMain
        v-else-if="
          subtitle == 'AGRUPAMENTOS' && name == 'SEGMENTAÇÃO DE MERCADO'
        "
        :name="name"
        :subtitle="subtitle"
        :headers="headers"
        :items="items"
        @refreshTargetMarketSegmentationTable="
          $emit('refreshTargetMarketSegmentationTable')
        "
        @updateOriginalData="$emit('updateOriginalData', $event)"
      />

      <v-col v-else>
        <h4>{{ name }}</h4>
        <span class="mb-4">{{ subtitle }}</span>

        <Deliberation-table
          v-if="
            subtitle == 'FUTURE VALUE | CANAL GERAL' && name == 'DELIBERATION'
          "
          :headers="headers"
          :localItems="localItems"
          :items="items"
          :setCurrencyUPL="setCurrencyUPL"
          :isAdminUpl="isAdminUpl"
          @hasNewValues="$emit('hasNewValues', $event)"
          @updateOriginalData="updateOriginalData"
        />

        <Info-table
          v-else-if="subtitle == '' && name == 'INFO'"
          :headers="headers"
          :localItems="localItems"
          :items="items"
          :setCurrencyUPL="setCurrencyUPL"
          :isAdminUpl="isAdminUpl"
          @updateOriginalData="updateOriginalData"
        />

        <Last-month-weighted-table
          v-else-if="
            subtitle == 'FUTURE VALUE | CG & KD/VD' &&
            name == 'LAST MONTH WHEIGHTED PRICE LIST & PERFORMANCE'
          "
          :headers="headers"
          :localItems="localItems"
          :setCurrencyUPL="setCurrencyUPL"
        />

        <Other-tables
          v-else
          :headers="headers"
          :localItems="localItems"
          :setCurrencyUPL="setCurrencyUPL"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UPLService from "@/service/upl";
import DiscountMatrixTable from "./components/discount-matrix-table.vue";
import MarketingVariationMatrixTable from "./components/marketing-variation-matrix-table.vue";
import TargetMarketSegmentationMain from "./components/target-market-segmentation/target-market-segmentation-main.vue";
import DeliberationTable from "./components/deliberation-table.vue";
import InfoTable from "./components/info-table.vue";
import OtherTables from "./components/other-tables.vue";
import LastMonthWeightedTable from "./components/last-month-weighted-table.vue";
export default {
  props: ["headers", "items", "name", "subtitle"],
  components: {
    DiscountMatrixTable,
    MarketingVariationMatrixTable,
    TargetMarketSegmentationMain,
    DeliberationTable,
    InfoTable,
    OtherTables,
    LastMonthWeightedTable,
  },
  data() {
    return {
      service: new UPLService(),
      localItems: null,
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
    };
  },

  computed: {
    isAdminUpl() {
      return Boolean(
        this.$store.getters.Acesso_E_Edicao_UPL_Geral ||
          this.$store.getters.isPredifyAdmin
      );
    },
  },

  methods: {
    updateOriginalData(indexItem, newData) {
      let body = { indexItem, newData };
      this.$emit("updateOriginalData", body);
    },

    setCurrencyUPL(value, useDollar) {
      if (value != null) {
        value = value.toString().replace(",", ".");

        let [integerPart, decimalPart] = value.split(".");

        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        decimalPart = (decimalPart || "").padEnd(2, "0").substring(0, 2);

        const currency = useDollar ? "$" : "R$";
        return `${currency} ${integerPart},${decimalPart}`;
      } else return "-";
    },
    vertifyProperties(list) {
      const firstProperty = "percentLMUSD";
      const secondProperty = "percentLMBRL";

      for (const object of list) {
        if (!(firstProperty in object) || !(secondProperty in object)) {
          return false;
        }
      }
      return true;
    },
    extractValue(string) {
      return string ? parseFloat(string.replace("%", "")) : 0;
    },
    formatValue(list) {
      let formatedList = null;
      formatedList = list.map((item) => {
        return {
          ...item,
          percentLMUSD: this.extractValue(item.percentLMUSD),
          percentLMBRL: this.extractValue(item.percentLMBRL),
        };
      });
      this.localItems = formatedList;
    },
  },

  watch: {
    items: {
      handler(value) {
        this.localItems = JSON.parse(JSON.stringify(value));
        const isCorrectArray = this.vertifyProperties(this.localItems);
        if (isCorrectArray) this.formatValue(this.items);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

