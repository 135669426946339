<template>
  <v-row no-gutters class="gap-4">
    <Prd-combo-box
      v-for="(filter, index) in filters"
      :key="index"
      v-show="filter.show"
      v-model="filter.selected"
      :items="filter.items"
      :title="filter.title"
      :disabled="filter.items.length == 0 || iaPriceLoading || isLoadingFather"
      :clearable="filter.useClearable"
      @click:clearable="filter.selected = null"
    />
  </v-row>
</template>

<script>
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import UPLService from "@/service/upl";
export default {
  props: {
    iaPriceLoading: { type: Boolean, default: false },
    isLoadingFather: { type: Boolean, default: false },
    isAdminUpl: { type: Boolean, default: false },
  },
  components: {
    PrdComboBox,
  },
  data() {
    return {
      service: new UPLService(),

      oldSelectedDeliberation: null,

      filters: [
        {
          key: "statusPortfolioType",
          show: true,
          title: "Status Portfólio",
          selected: null,
          items: [],
          useClearable: true,
        },
        {
          key: "deliberation",
          show: this.isAdminUpl,
          title: this.$i18n.t("TXT_REFERENCE"),
          selected: null,
          items: [],
          useClearable: false,
        },
        {
          key: "brands",
          show: true,
          title: this.$i18n.t("TXT_BRAND_UPL"),
          selected: null,
          items: [],
          useClearable: true,
        },
        {
          key: "segments",
          show: true,
          title: this.$i18n.t("TXT_SEGMENT_UPL"),
          selected: null,
          items: [],
          useClearable: true,
        },
        {
          key: "precificationType",
          show: true,
          title: this.$i18n.t("TXT_PRECIFICATION_METHODOLOGY"),
          selected: null,
          items: [],
          useClearable: true,
        },
      ],
    };
  },

  computed: {
    deliberationFilterSelection() {
      return this.filters.find((filter) => filter.key == "deliberation")
        .selected;
    },
    statusPortfolioFilterSelection() {
      return this.filters.find((filter) => filter.key == "statusPortfolioType").selected;
    },
  },

  watch: {
    deliberationFilterSelection: {
      handler(newValue, oldValue) {
        if (newValue && oldValue && this.oldSelectedDeliberation == null) {
          this.$emit(
            "openSelectDeliberationModal",
            oldValue.nome,
            newValue.nome
          );
          this.oldSelectedDeliberation = oldValue;
        }
      },
    },
    statusPortfolioFilterSelection: {
      handler(value) {
        this.getBrandSegmentPrecificationType(value);
      },
    },
  },

  methods: {
    getFilters() {
      return {
        deliberationId:
          this.filters.find((filter) => filter.key == "deliberation").selected
            ?.id ?? null,
        brand:
          this.filters.find((filter) => filter.key == "brands").selected ??
          null,
        segment:
          this.filters.find((filter) => filter.key == "segments").selected ??
          null,
        precificationType:
          this.filters.find((filter) => filter.key == "precificationType")
            .selected ?? null,
        statusPortfolio:
          this.filters.find((filter) => filter.key == "statusPortfolioType")
            .selected ?? null,
      };
    },
    resetFiltersForNewDeliberation() {
      this.filters.forEach((filter) => {
        if (filter.key != "deliberation") filter.selected = null;
      });
      this.oldSelectedDeliberation = null;
    },
    resetFiltersForResetDeliberation() {
      const filter = this.filters.find(
        (filter) => filter.key == "deliberation"
      );
      filter.selected = JSON.parse(
        JSON.stringify(this.oldSelectedDeliberation)
      );
      setTimeout(() => {
        this.oldSelectedDeliberation = null;
      }, 100);
    },
    async getFiltersValues() {
      this.$emit("setLoading", true);
      try {
        await this.service.getDeliberationApp().then((res) => {
          const filter = this.filters.find(
            (filter) => filter.key == "deliberation"
          );
          filter.selected = res.data[0];
          filter.items = res.data;
        });

        await this.getBrandSegmentPrecificationType(null);

        this.$emit("genereteTable");
      } catch (error) {
        this.$handleError(error, this.$i18n.t("TXT_ERROR_FIND_FILTERS"));
        this.$emit("setLoading", false);
      }
    },
    async getBrandSegmentPrecificationType(type) {
      await this.service.getFilters(type ?? null).then((res) => {
        const keys = this.filters
          .map((filter) => filter.key)
          .filter((key) => key != "deliberation");

        keys.forEach((key) => {
          const filter = this.filters.find((filter) => filter.key == key);
          if (filter) {
            filter.items = res.data[key];
          }
        });
      });
    },
  },

  mounted() {
    this.getFiltersValues();
  },
};
</script>