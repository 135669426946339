<template>
  <div>
    <v-row no-gutters class="mb-4">
      <h4 class="mr-4">{{ $t("TXT_LIMITS") }}:</h4>
      <h4 class="mr-1">{{ $t("TXT_INFERIOR") }}:</h4>
      <span class="mr-4">
        {{ inferiorLimit + " %" }}
      </span>
      <h4 class="mr-1">{{ $t("TXT_SUPERIOR") }}:</h4>
      <span>
        {{ superiorLimit + " %" }}
      </span>
    </v-row>
    <v-data-table
      v-if="filteredList"
      :headers="headers"
      :items="filteredList"
      :hide-default-footer="filteredList == [] || filteredList.length == 0"
      :footer-props="footerProps"
      :no-data-text="$t('TXT_NO_DATA_AVAILABLE')"
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ item.date | formatDate }}
      </template>
      <template v-slot:[`item.margin`]="{ item }">
        {{ formatPercentage(item.margin) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatPercentage } from "@/utils/format-percentage";
export default {
  props: ["allData", "superiorLimit", "inferiorLimit"],
  data() {
    return {
      headers: [
        {
          text: this.$i18n.t("TXT_DATE"),
          align: "center",
          sortable: true,
          value: "date",
        },
        {
          text: this.$i18n.t("TXT_CATEGORY"),
          align: "center",
          sortable: true,
          value: "category",
        },
        {
          text: `Sub-${this.$i18n.t("TXT_CATEGORY")}`,
          align: "center",
          sortable: true,
          value: "subCategory",
        },
        {
          text: this.$i18n.t("TXT_PRODUCT_DESCRIPTION"),
          align: "center",
          sortable: true,
          value: "description",
        },
        {
          text: this.$i18n.t("TXT_MARGIN"),
          align: "center",
          sortable: true,
          value: "margin",
        },
      ],
      footerProps: {
        itemsPerPageText: `${this.$i18n.t("TXT_ROWS_PER_PAGE")} :`,
        itemsPerPageAllText: this.$i18n.t("TXT_ALL"),
      },
      filteredList: [],
    };
  },
  filters: {
    formatDate(value) {
      const splitedDateOnT = value.split("T");
      const splitedDateOnDash = splitedDateOnT[0].split("-");
      const formatedDate = `${splitedDateOnDash[2]}/${splitedDateOnDash[1]}/${splitedDateOnDash[0]}`;
      return formatedDate;
    },
  },
  methods: {
    formatPercentage,
    filterListByMargem(list) {
      this.filteredList = [];
      this.filteredList = list.filter(
        (item) =>
          item.margin >= this.superiorLimit || item.margin <= this.inferiorLimit
      );
    },
  },
  watch: {
    allData: {
      handler(value) {
        this.filterListByMargem(value);
      },
    },
  },
};
</script>
