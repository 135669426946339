<template>
  <v-card class="pa-4 mt-6">
    <v-row no-gutters align="center">
      <h4>{{ $i18n.t("TXT_ADOPTION_IA_IMPACT") }}</h4>
      <Prd-tooltip :text="'Texto do tooltip'" v-if="false" />
      <v-spacer></v-spacer>
      <Report-modal-main
        :selectedAffiliate="selectedAffiliate"
        :type="'impact'"
      />
    </v-row>
    <Tabs-main :externalDisable="disableTabs" @setTab="tab = $event" />

    <Prd-loading-circular v-if="isLoading" size="80" class="chart-loading" />

    <v-tabs-items v-else v-model="tab">
      <v-tab-item><Column-chart :data="adoption" :tab="tab" /></v-tab-item>
      <v-tab-item><Column-chart :data="demand" :tab="tab" /></v-tab-item>
      <v-tab-item><Column-chart :data="recipe" :tab="tab" /></v-tab-item>
      <v-tab-item><Column-chart :data="margin" :tab="tab" /></v-tab-item>
      <v-tab-item><Column-chart :data="grossProfit" :tab="tab" /></v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import PrdTooltip from "@/Design_System/common/prd-tooltip.vue";
import TabsMain from "./components/tabs-main.vue";
import ColumnChart from "./components/column-chart.vue";
import service from "@/service/home-vem/index.js";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
import ReportModalMain from "./extract-report-filters/report-modal-main.vue";
const Service = new service();
export default {
  props: ["selectedAffiliate", "getChartAdoptionDataFlag"],
  components: {
    PrdTooltip,
    TabsMain,
    ColumnChart,
    PrdLoadingCircular,
    ReportModalMain,
  },
  data: () => ({
    tab: 0,

    isLoading: false,

    adoption: [],
    demand: [],
    recipe: [],
    margin: [],
    grossProfit: [],
  }),

  watch: {
    tab: {
      handler(value) {
        this.resolveDataChart(value);
      },
    },
    getChartAdoptionDataFlag: {
      handler() {
        if (this.VEM) {
          this.cleanChartData();
          this.resolveDataChart(this.tab);
        }
      },
    },
  },

  computed: {
    idCompany() {
      return this.$store?.getters?.userData?.idCompany ?? null;
    },
    VEM() {
      return this.idCompany == 3026;
    },
    disableTabs() {
      return !this.selectedAffiliate;
    },
  },

  methods: {
    resolveDataChart(tab) {
      const chartTypes = ["adoption", "demand", "recipe", "margin", 'grossProfit'];
      this.getChartData(chartTypes[tab]);
    },

    async getChartData(type) {
      let hasData = this.verifyIfEmpty(type);
      if (hasData) return;

      let serviceMethod = this.verifyMethodType(type);
      const request = this.prepareRequest();
      try {
        this.isLoading = true;

        let response = await Service[serviceMethod](request);
        const data = response.data;
        this[type] = data;
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          type: "error",
          message: this.$i18n.t("TXT_DATA_ERROR"),
        });
        this[type] = [];
      }

      this.isLoading = false;
    },

    verifyIfEmpty(type) {
      const typeMapping = {
        adoption: this.adoption,
        demand: this.demand,
        recipe: this.recipe,
        margin: this.margin,
        grossProfit: this.grossProfit,
      };

      return typeMapping[type] && typeMapping[type].length !== 0;
    },

    verifyMethodType(type) {
      const serviceMethodMapping = {
        adoption: "getChartAdoption",
        demand: "getChartDemand",
        recipe: "getChartRecipe",
        margin: "getChartMargin",
        grossProfit: "getChartProfit",
      };

      return serviceMethodMapping[type] || null;
    },

    prepareRequest() {
      return {
        companyId: this.idCompany ?? null,
        AffiliateIds: this.selectedAffiliate
          ? [this.selectedAffiliate.value]
          : [],
      };
    },

    cleanChartData() {
      this.adoption = [];
      this.demand = [];
      this.recipe = [];
      this.margin = [];
      this.grossProfit = [];
    },
  },
};
</script>

<style scoped>
.chart-loading {
  margin-top: 10rem;
  margin-bottom: 10rem;
}
</style>

