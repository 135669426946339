<template>
  <v-card flat class="pa-4" id="marketplace-data">
    <h4 class="mb-4">MONITORAMENTO DE MERCADO</h4>

    <v-row v-if="marketResultLoading" class="mt-10" no-gutters justify="center">
      <v-progress-circular
        indeterminate
        :color="$prdStyles('color-primary')"
      ></v-progress-circular>
    </v-row>

    <v-data-table
      v-else
      :headers="headers"
      :items="marketResult"
      hide-default-footer
      :items-per-page="200000"
    >
      <template v-slot:[`header.crawlerName`]="{ header }">
        <div class="header-box w-30">
          <div>{{ header.text }}</div>
        </div>
      </template>

      <template v-slot:[`header.price`]="{ header }">
        <div class="header-box w-30">
          <div>{{ header.text }}</div>
        </div>
      </template>

      <template v-slot:[`header.quantityInstallments`]="{ header }">
        <div class="header-box w-30">
          <div>{{ header.text }}</div>
        </div>
      </template>

      <template v-slot:[`header.deflatedValue`]="{ header }">
        <div class="header-box w-30">
          <div>{{ header.text }}</div>
        </div>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        <div>
          <div>{{ setCurrencyFujioka(item.price) }}</div>
        </div>
      </template>

      <template v-slot:[`item.quantityInstallments`]="{ item }">
        <div>
          <div>
            {{ item.quantityInstallments ? item.quantityInstallments : "-" }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.deflatedValue`]="{ item }">
        <div>
          <div>{{ setCurrencyFujioka(item.deflatedValue) }}</div>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { setCurrencyFujioka } from "@/utils/format-currency-fujioka.js";
export default {
  props: ["marketResult", "marketResultLoading"],
  data() {
    return {
      headers: [
        {
          text: "Conc",
          align: "center",
          sortable: false,
          value: "crawlerName",
        },
        {
          text: "Preço",
          align: "center",
          sortable: false,
          value: "price",
          width: "100px",
        },
        {
          text: "Parc",
          align: "center",
          sortable: false,
          value: "quantityInstallments",
        },
        {
          text: "Preço def",
          align: "center",
          sortable: false,
          value: "deflatedValue",
        },
      ],
    };
  },
  methods: {
    setCurrencyFujioka,
  },
};
</script>

<style lang="scss">
#marketplace-data {
  tbody {
    tr {
      td {
        font-size: 12px !important;
      }
    }
  }
  .header-box {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: bold;
    div {
      text-align: center;
    }
  }

  th {
    font-weight: 600 !important;
    color: #000 !important;
    font-size: 11px !important;
    height: 40px !important;
  }
}
</style>