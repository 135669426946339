<template>
  <v-dialog v-model="isOpen" width="500" :persistent="isLoading">
    <template v-slot:activator="{ on, attrs }">
      <prd-btn
        :title="$t('TXT_TO_IMPORT')"
        :icon="'mdi-upload-outline'"
        :smallIcon="true"
        v-bind="attrs"
        v-on="on"
      />
    </template>

    <v-card class="pa-4">
      <h2>{{ $t("TXT_FILE_IMPORT") }}</h2>
      <div
        :class="['dashed', { cursorPointer: !isLoading }]"
        @dragenter="isDragging = true"
        @dragleave="isDragging = false"
        @drop.prevent="handlerFile"
        @dragover.prevent="onDragOver"
        @click="openFileInput"
      >
        <v-progress-circular
          v-if="isLoading"
          indeterminate
          :color="$prdStyles('color-primary')"
        />
        <div v-else class="center-div">
          <v-icon x-large class="mb-2">mdi-file-upload-outline</v-icon>
          <p class="ma-0">Arraste e solte seu arquivo aqui</p>
          <p class="ma-0 my-1">ou</p>
          <p class="font-weight-bold ma-0">clique para selecionar</p>
        </div>
      </div>
      <v-row no-gutters justify="end">
        <prd-btn
          :title="$t('TXT_CANCEL')"
          :outlined="true"
          :disabled="isLoading"
          @click="isOpen = false"
        />
      </v-row>
    </v-card>

    <input ref="fileInput" type="file" v-show="false" @change="handlerFile" />
  </v-dialog>
</template>

<script>
import prdBtn from "@/Design_System/common/prd-btn.vue";
import { checkFileType } from "@/utils/check-file-type";
import StoreManagementService from "@/service/company-config/store-management";
export default {
  components: { prdBtn },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      isDragging: false,
      service: new StoreManagementService(),
    };
  },

  methods: {
    onDragOver(event) {
      event.preventDefault();
      this.isDragging = true;
    },

    openFileInput() {
      if (this.isLoading) return;
      this.$refs.fileInput.click();
    },

    handlerFile(event) {
      const file = event?.dataTransfer?.files[0] ?? event?.target?.files[0];

      const isValidFile = checkFileType(["xlsx"], file);
      if (!isValidFile)
        this.$store.commit("snackbarV2/set", {
          type: "info",
          message: this.$i18n.t("TXT_INVALID_FILE_TYPE", { fileType: "XLSX" }),
        });
      else this.persistData(file);

      this.$refs.fileInput.value = null;
    },

    async persistData(file) {
      this.isLoading = true;
      try {
        const [idCompany, formData] = this.prepareRequest(file);

        await this.service.ImportFile(idCompany, formData);

        this.$store.commit("snackbarV2/set", {
          type: "success",
          message: this.$i18n.t("TXT_SUCCESS_UPDATE_STORES"),
        });

        this.syncChanges();

        this.isOpen = false;
      } catch (error) {
        this.hanleError(error);
      } finally {
        this.isLoading = false;
      }
    },

    prepareRequest(file) {
      const idCompany = this.$store.getters.userData.idCompany;

      let formData = new FormData();
      formData.append("file", file);

      return [idCompany, formData];
    },

    syncChanges() {
      this.$store.commit("removeAllSelectedUser");
      this.$store.commit("removeAllSelectedStore");
      this.$store.commit("clearChanges");

      this.$emit("refeshUsers");
    },

    hanleError(error) {
      const code = error.response.status;
      if (code == 400) {
        this.$store.commit("snackbarV2/set", {
          type: "info",
          message: this.$i18n.t("TXT_ERROR_UPDATE_STORES"),
          timeout: 10000,
        });
      } else this.$handleError(error, this.$i18n.t("TXT_IMPORT_FILE_ERROR"));
    },
  },
};
</script>

<style lang="scss" scoped>
.dashed {
  width: 100%;
  height: 280px;
  border: 2px dashed $neutral-color-high-dark;
  border-radius: 8px;
  margin: 12px 0px 12px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .v-icon,
  p {
    color: $neutral-color-high-dark !important;
  }
}
.cursorPointer {
  cursor: pointer;
}
.center-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
