<template>
  <v-row no-gutters>
    <v-col cols="6" class="pr-2">
      <premissas-input
        style="height: 100%"
        v-if="this.product != null"
        v-bind:key="productId"
        v-bind:product="this.product"
      />
    </v-col>
    <v-col cols="6" class="pl-2">
      <direcionadorPrecos v-bind:product="product" />
      <v-row no-gutters class="mt-4">
        <v-col cols="8" class="pr-2">
          <price-list v-bind:key="productId" v-bind:product="this.product" />
        </v-col>
        <v-col cols="4" class="pl-2">
          <card-info
            style="height: 275.65px"
            :title="$t('TXT_COMENTS')"
            :text="comentarios"
            :cardType="'comentario'"
            @newValueatt="
              $emit('newValueatt', { fieldName: 'comentario', value: $event })
            "
            :productId="productId"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CardInfo from "../card-info.vue";
import premissasInput from "./components/premissas-input.vue";
import PriceList from "./components/price-list.vue";
import DirecionadorPrecos from "./components/direcionador-precos/direcionador-precos-main.vue";
export default {
  components: { premissasInput, PriceList, CardInfo, DirecionadorPrecos },
  props: ["product"],
  computed: {
    productId() {
      return this.product?.id ?? null;
    },
    comentarios() {
      return this.product?.comentario ?? null;
    },
  },
};
</script>