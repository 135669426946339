<template>
  <v-app-bar app color="var(--very-light-gray)">
    <v-app-bar-nav-icon
      @click="showOrHideMenu()"
      v-show="$vuetify.breakpoint.smAndDown"
    >
    </v-app-bar-nav-icon>
    <company-name />
    <v-spacer></v-spacer>

    <template v-if="!isUnilever">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" plain v-bind="attrs" v-on="on" fab small>
            <v-icon>mdi-translate</v-icon>
          </v-btn>
        </template>
        <v-item-group v-model="lang">
          <v-container>
            <v-row v-for="item in supportedLanguages" :key="item.code">
              <v-item v-slot="{ active, toggle }" :value="item.code">
                <v-btn
                  :color="active ? 'primary' : ''"
                  block
                  elevation="0"
                  @click="toggle"
                  class="justify-start"
                >
                  <v-img
                    class="mr-2"
                    height="12px"
                    max-width="16px"
                    width="16px"
                    :src="`/images/icons/flags/${item.flag}.svg`"
                  />
                  {{ item.text }}
                </v-btn>
              </v-item>
            </v-row>
          </v-container>
        </v-item-group>
      </v-menu>
    </template>

    <template>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" plain v-bind="attrs" v-on="on" fab small>
            <v-icon>{{ setCurrencyIcon(currency) }}</v-icon>
          </v-btn>
        </template>

        <v-item-group v-model="currency">
          <v-container>
            <v-row v-for="item in currencyItems" :key="item.code">
              <v-item v-slot="{ active, toggle }" :value="item.code">
                <v-btn
                  :color="active ? 'primary' : ''"
                  block
                  elevation="0"
                  @click="toggle"
                  class="justify-start"
                >
                  {{ item.code }}
                  {{ item.text }}
                </v-btn>
              </v-item>
            </v-row>
          </v-container>
        </v-item-group>
      </v-menu>
    </template>

    <template>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" plain v-bind="attrs" v-on="on" fab small>
            <v-icon>mdi-account-outline</v-icon>
          </v-btn>
        </template>

        <v-item-group v-model="userAction">
          <v-container>
            <v-row v-for="(item, index) in userActionItems" :key="index">
              <v-item v-slot="{ active, toggle }" :value="item.to">
                <v-btn
                  :color="active ? 'primary' : ''"
                  @click="toggle"
                  block
                  elevation="0"
                  class="justify-start"
                >
                  <v-icon :color="active ? 'white' : 'primary'" class="mr-2">{{
                    item.icon
                  }}</v-icon>
                  {{ item.text }}
                </v-btn>
              </v-item>
            </v-row>
          </v-container>
        </v-item-group>
      </v-menu>
    </template>
    <terms-modal
      v-for="term in termsItems"
      :key="term.id"
      :title="term.title"
      :id="term.id"
      :isOpen="term.isOpen"
      @closeModal="term.isOpen = false"
    />
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
// import companyService from "@/service/company-service.js";
import companyName from "./components/company-name.vue";
import TermsModal from "./components/terms-modal.vue";
// const Service = new companyService();
export default {
  components: { companyName, TermsModal },
  name: "headerComponent",

  data: function () {
    const lang = localStorage.getItem("lang") || "en-US";
    const currency = localStorage.getItem("currency") || "R$";
    return {
      lang,
      currency,
      userAction: null,
      supportedLanguages: [
        { text: "English", code: "en-US", flag: "us" },
        { text: "Español", code: "es-ES", flag: "es" },
        { text: "Português", code: "pt-BR", flag: "br" },
      ],
      currencyItems: [
        { text: "Real", code: "R$", flag: "us", icon: "mdi-currency-brl" },
        { text: "Dolar", code: "$", flag: "es", icon: "mdi-currency-usd" },
        { text: "Euro", code: "€", flag: "br", icon: "mdi-currency-eur" },
        { text: "Libra", code: "£", flag: "br", icon: "mdi-currency-gbp" },
      ],
      userActionItems: [
        {
          text: this.$i18n.t("TXT_PROFILE"),
          icon: "mdi-account-outline",
          to: "profileMain",
        },
        {
          text: this.$i18n.t("TXT_PAYMENTS"),
          icon: "mdi-credit-card-outline",
          to: "paymentHistory",
        },
        {
          text: this.$i18n.t("TXT_USE_TERM"),
          icon: "mdi-card-text-outline",
          to: "useTerms",
        },
        {
          text: this.$i18n.t("TXT_PRIVACY"),
          icon: "mdi-card-text-outline",
          to: "privacyPolicy",
        },
        { text: "FAQS", icon: "mdi-help-box-outline", to: "faqs" },
        {
          text: this.$i18n.t("TXT_LOGOUT"),
          icon: "mdi-logout",
          to: "sign-out",
        },
      ],
      termsItems: [
        {
          isOpen: false,
          id: "useTerms",
          title: this.$i18n.t("TXT_USE_TERMS_TITLE"),
        },
        {
          isOpen: false,
          id: "privacyPolicy",
          title: this.$i18n.t("TXT_PRIVACY"),
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["hasUser"]),

    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    isUnilever() {
      return this.idCompany == 3111;
    },
  },

  watch: {
    lang(newLang) {
      localStorage.setItem("lang", newLang);
      window.location.reload();
    },

    currency(newCurrency) {
      localStorage.setItem("currency", newCurrency);
      window.location.reload();
    },

    userAction(action) {
      if (action == "useTerms") {
        this.termsItems[0].isOpen = true;
        return;
      }
      if (action == "privacyPolicy") {
        this.termsItems[1].isOpen = true;
        return;
      }
      this.$router.push({ name: action });
    },
  },

  methods: {
    showOrHideMenu() {
      this.$emit("showOrHideMenu");
    },

    setCurrencyIcon(code) {
      return this.currencyItems.find((currency) => currency.code == code).icon;
    },
  },
};
</script>