<template>
  <v-dialog persistent v-model="localIsOpen" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <slot name="externalActivatorButton" v-if="useExternalActivatorButton" />

      <prd-btn
        v-else
        :color="$prdStyles(`color-${localActivatorButtonColor}`)"
        :title="titleActivatorButton"
        :disabled="disabledActivatorButton"
        :outlined="outlinedActivatorButton"
        v-bind="attrs"
        v-on="on"
        :class="useActivatorButtonMargin ? 'mb-1' : ''"
      />
    </template>
    <v-card class="pa-4">
      <v-row no-gutters justify="end">
        <v-icon color="black" @click="localIsOpen = false">mdi-close</v-icon>
      </v-row>
      <v-row no-gutters justify="center" class="mb-4">
        <v-icon :class="`${iconColor}Icon`">{{ icon }}</v-icon>
      </v-row>
      <h3 class="text-center mb-6">{{ modalTitle.toUpperCase() }}</h3>
      <p class="text-center mb-6" v-html="question" />
      <v-row no-gutters class="mb-6 py-4 px-2 contentBg">
        <slot name="content" />
      </v-row>
      <p class="text-center mb-6" v-html="alertMessage" />
      <v-row no-gutters justify="end" class="gap-6">
        <Prd-btn
          :outlined="true"
          :color="$prdStyles(`color-${localButtonsColor}`)"
          :title="$t('TXT_CANCEL')"
          @click="localIsOpen = false"
        />
        <Prd-btn
          :outlined="false"
          :color="$prdStyles(`color-${localButtonsColor}`)"
          :title="confirmButtonText"
          :leftIcon="leftIcon"
          :icon="confirmButtonIcon"
          @click="confirmAction"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";

export default {
  components: { PrdBtn },
  props: {
    useExternalActivatorButton: { type: Boolean, default: false },
    externalIsOpen: { type: Boolean, default: false },
    titleActivatorButton: { type: String },
    disabledActivatorButton: { type: Boolean },
    useActivatorButtonMargin: { type: Boolean, default: true },
    outlinedActivatorButton: { type: Boolean, default: false },
    activatorButtonColor: { type: String, default: "primary" },
    icon: { type: String },
    iconColor: { type: String },
    modalTitle: { type: String },
    question: { type: String },
    alertMessage: { type: String },
    confirmButtonText: { type: String },
    confirmButtonIcon: { type: String },
    buttonsColor: { type: String },
  },
  data() {
    return { localIsOpen: false };
  },
  computed: {
    localButtonsColor() {
      return this.buttonsColor == "red"
        ? "warning"
        : this.buttonsColor == "green"
        ? "success"
        : "primary";
    },
    localActivatorButtonColor() {
      return this.activatorButtonColor == "red"
        ? "warning"
        : this.activatorButtonColor == "green"
        ? "success"
        : "primary";
    },
    leftIcon() {
      return this.confirmButtonIcon ? true : false;
    },
  },
  watch: {
    externalIsOpen: {
      handler(value) {
        this.localIsOpen = value;
      },
    },
    localIsOpen: {
      handler(value) {
        this.$emit("handlerIsOpen", value);
      },
    },
  },
  methods: {
    confirmAction() {
      this.$emit("handleConfirmAction");
      this.localIsOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.contentBg {
  border-radius: 4px;
  background-color: $neutral-color-high-light;
}
.redIcon {
  color: $feedback-color-warning-pure;
}
.greenIcon {
  color: $feedback-color-success-pure;
}
.yellowIcon {
  color: $feedback-color-helper-pure;
}
</style>