<template>
  <v-row no-gutters justify="space-between" class="mb-3">
    <v-col cols="12">
      <p
        v-for="(ind, index) in indicators"
        :key="index"
        :class="`generalBorder ${ind.color}Border pl-2`"
      >
        {{ ind.text }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    indicators: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.generalBorder {
  border-left: 6px solid;
}
.yellowBorder {
  border-left-color: $feedback-color-helper-pure;
}
.redBorder {
  border-left-color: $feedback-color-warning-pure;
}
.greenBorder {
  border-left-color: $feedback-color-success-pure;
}
.blueBorder {
  border-left-color: $brand-color-primary-pure;
}
</style>