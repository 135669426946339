import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class GeneralSystemConfigsService extends ServiceBase {
  async getAllCompanies() {
    return await axiosInstance.get(API_URL + "api/Empresa/GetEmpresaNome");
  }

  async getPlansList() {
    return await axiosInstance.get(API_URL + "api/v1/PlanoItem/GetPlansList");
  }

  async changeNewPlan(idCompany, idPlan) {
    return await axiosInstance.get(
      API_URL +
        `api/v1/Pagamento/ChangePlan?idCompany=${idCompany}&idPlan=${idPlan}`
    );
  }

  async changeDaysOfUse(request) {
    return await axiosInstance.post(
      API_URL + "api/v1/Pagamento/AdicionarDiasPagamento",
      request
    );
  }

  async getCompanyConfigurations(idEmpresa) {
    return await axiosInstance.get(
      API_URL + `api/Empresa/GetEmpresaConfiguracoes?idEmpresa=${idEmpresa}`
    );
  }

  async updateCompanyConfigurations(request) {
    return await axiosInstance.put(
      API_URL + "api/Empresa/UpdateEmpresaConfiguracoes",
      request
    );
  }

  async getLicenseList() {
    return await axiosInstance.get(API_URL + "api/v1/Licenca/GetListLicenca");
  }

  async getLicenseListByCompany(idEmpresa) {
    return await axiosInstance.get(
      API_URL + `api/v1/Licenca/GetListLicencaEmpresa?idEmpresa=${idEmpresa}`
    );
  }

  async getUser(email) {
    return await axiosInstance.get(
      API_URL + `api/Usuario/GetUsuario?email=${email}`
    );
  }

  async linkUserCompany(request) {
    return await axiosInstance.post(
      API_URL + "api/Usuario/VincularEmpresaUsuario",
      request
    );
  }

  async getUsersByCompany(idEmpresa) {
    return await axiosInstance.get(
      API_URL + `api/Usuario/GetusuariosByIdEmpresa?idEmpresa=${idEmpresa}`
    );
  }

  async makeIsOwnsUser(request) {
    return await axiosInstance.post(
      API_URL + "api/Usuario/MakeUsuarioProprietario",
      request
    );
  }

  async unlinkUserCompany(idEmpresa, idUsuario) {
    return await axiosInstance.delete(
      API_URL +
        `api/Usuario/DesvincularEmpresaUsuario?idEmpresa=${idEmpresa}&idUsuario=${idUsuario}`
    );
  }

  async updateUserData(request) {
    return await axiosInstance.put(API_URL + "api/Usuario", request);
  }

  async setAdminRole(request) {
    return await axiosInstance.post(
      API_URL + "api/Usuario/SetAdminRole",
      request
    );
  }

  async removeAdminRole(request) {
    return await axiosInstance.post(
      API_URL + "api/Usuario/RemoveAdminRole",
      request
    );
  }
  async updateLicenses(request) {
    return await axiosInstance.post(
      API_URL + "api/v1/Licenca/UpdateLicencasEmpresa",
      request
    );
  }
}
