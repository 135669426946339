<template>
  <v-row no-gutters align="center" justify="center" class="gap-4">
    <Prd-loading-circular v-if="item.loadingRegionalAcess" />
    <v-icon
      :color="$prdStyles('color-primary')"
      v-else-if="!item.loadingRegionalAcess && showIcon"
      >mdi-minus</v-icon
    >
    <div v-else>
      <v-btn
        icon
        @click="handlerRegionalAccess('1000')"
        :disabled="item.generalIsLoading || !item.inEditMode"
        v-if="setDisableFlag('1000', item) == '' || item.inEditMode"
      >
        <img
          :src="setToCountryFlag('1000')"
          :class="setDisableFlag('1000', item)"
          class="imageFlag"
        />
      </v-btn>
      <v-btn
        icon
        @click="handlerRegionalAccess('1001')"
        :disabled="item.generalIsLoading || !item.inEditMode"
        v-if="setDisableFlag('1001', item) == '' || item.inEditMode"
      >
        <img
          :src="setToCountryFlag('1001')"
          :class="setDisableFlag('1001', item)"
          class="imageFlag"
        />
      </v-btn>
      <v-btn
        icon
        @click="handlerRegionalAccess('1002')"
        :disabled="item.generalIsLoading || !item.inEditMode"
        v-if="setDisableFlag('1002', item) == '' || item.inEditMode"
      >
        <img
          :src="setToCountryFlag('1002')"
          :class="setDisableFlag('1002', item)"
          class="imageFlag"
        />
      </v-btn>
    </div>
  </v-row>
</template>

<script>
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
export default {
  components: { PrdLoadingCircular },
  props: {
    item: { type: Object },
  },
  computed: {
    showIcon() {
      return (
        !this.item.adminRoles.some((role) =>
          ["1000", "1001", "1002"].includes(role)
        ) && !this.item.inEditMode
      );
    },
  },

  methods: {
    setToCountryFlag(value) {
      switch (value) {
        case "1000":
          return require("../../../../../../../../../../../public/images/icons/flags/br.svg");
        case "1001":
          return require("../../../../../../../../../../../public/images/icons/flags/us.svg");
        case "1002":
          return require("../../../../../../../../../../../public/images/icons/flags/ue.svg");
        default:
          return "";
      }
    },
    setDisableFlag(level, user) {
      const checkLevel = user.adminRoles.find((access) => access === level);
      return !checkLevel ? "disbleFlag" : "";
    },
    handlerRegionalAccess(level) {
      this.$emit("handlerRegionalAccess", level);
    },
  },
};
</script>

<style lang="scss" scoped>
.imageFlag {
  width: 24px;
  border-radius: 5px;
}
.disbleFlag {
  opacity: 0.3;
}
</style>