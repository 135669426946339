<template>
  <v-card elevation="4" width="100%">
    <highcharts class="hc" :options="chartData"></highcharts>
  </v-card>
</template>

<script>
import ServiceUPL from "@/service/upl/index";
import { formatMonetary } from "@/utils/format-toMonetary";
export default {
  props: ["product"],
  data() {
    return {
      service: new ServiceUPL(),
      dados: [],
      series: [],
      chartData: {
        title: {
          text: `<b>${this.$i18n.t("TXT_PERFORMANCE_HISTORY_CHART_TITLE")}</b>`,
          align: "left",
        },
        subtitle: {
          text: `${this.$i18n.t("TXT_SOURCE")}: Win room`,
          align: "left",
        },

        xAxis: {
          categories: [],
          title: {
            text: this.$i18n.t("TXT_DATE"),
          },
        },
        yAxis: {
          title: {
            text: this.$i18n.t("TXT_COST"),
          },
        },
        tooltip: {
          formatter: function () {
            if (this.series.type === "spline") {
              return `${this.x}<br> <span style="color: ${this.series.color};">● </span> ${this.series.name}: <strong>${this.y}</strong>`;
            }

            return false;
          },
        },

        series: [],
      },
    };
  },
  beforeMount() {
    if (this.product != null) {
      this.service
        .getGraficoHistoricoDesempenho(this.product.nome)
        .then((res) => {
          this.dados = res.data;
          this.series.push({
            type: "column",
            name: "NetOrder",
            data: this.dados.netOrder,
            color: "#6792F8",
            dataLabels: {
              enabled: true,
              formatter: function () {
                return formatMonetary(this.y);
              },
            },
          });
          this.series.push({
            type: "column",
            name: "NetPrice",
            data: this.dados.netPrice,
            color: "#3C5CA7",
            dataLabels: {
              enabled: true,
              formatter: function () {
                return formatMonetary(this.y);
              },
            },
          });
          this.series.push({
            type: "spline",
            name: "Volume",
            data: this.dados.volume,
            color: "#EC4C37",
            marker: {
              lineWidth: 2,
            },
          });
          this.chartData.series = this.series;
          this.chartData.xAxis.categories = this.dados.meses;
          this.chartData.subtitle.text = this.$i18n.t(
            "TXT_SUBTITLE_CHART_UPL",
            {
              font: "Win room",
              date: this.dados.dataInsert,
            }
          );
        });
    }
  },
};
</script>