<template>
  <v-card
    class="pa-4 pt-0"
    :loading="linkUserLoading"
    :disabled="linkUserLoading"
  >
    <h4 class="mb-4 mt-4">{{ $t("TXT_USER_PERMISSION_GORUP") }}</h4>
    <span :class="{ editMode: !editMode }">{{
      $t("TXT_SELECT_USER_PERMISSION_GORUP")
    }}</span>

    <div v-show="editMode" class="group-name">
      <span>{{ groupName }}</span>
    </div>

    <v-row no-gutters class="mt-4">
      <v-col cols="12" md="6">
        <Prd-search
          v-model="search"
          :delay="0"
          :disabled="!editMode || isLoading"
        />
      </v-col>
    </v-row>

    <Prd-loading-circular v-if="isLoading" class="mt-4" />

    <div v-else class="main-list">
      <div
        v-for="user in filteredUsers"
        :key="user.idUsuario"
        :class="{ editMode: !editMode }"
        class="user-item"
      >
        <v-checkbox
          v-model="user.isLinked"
          v-show="editMode"
          hide-details
          class="pa-0 ma-0"
        ></v-checkbox>

        <span class="mr-1">{{ user.nome }}</span>
        <span> ({{ user.email }})</span>
      </div>
    </div>

    <v-row v-if="editMode" no-gutters justify="end" class="my-6">
      <Prd-btn
        :title="$t('TXT_CANCEL')"
        :outlined="true"
        :disabled="isLoading || linkUserLoading"
        @click="cancelChanges"
        class="mr-4"
      />
      <Prd-btn
        :title="$t('TXT_SAVE')"
        :loading="linkUserLoading"
        :disabled="isLoading || linkUserLoading"
        @click="allowPermissionToUsers"
      />
    </v-row>
  </v-card>
</template>

<script>
import PrdSearch from "@/components/common/prd-search.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import service from "@/service/company-config/permission-management.js";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
const Service = new service();
export default {
  props: ["editMode", "selectedGroup", "tab"],
  components: { PrdSearch, PrdBtn, PrdLoadingCircular },
  data() {
    return {
      search: "",
      isLoading: false,
      linkUserLoading: false,
      users: [],
      filteredUsers: [],
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
    groupName() {
      return this.selectedGroup?.name ?? "";
    },
  },

  watch: {
    tab: {
      handler(value) {
        if (value == 1) this.getUsers();
      },
      immediate: true,
    },

    selectedGroup: {
      handler() {
        this.linkUserToGroup();
      },

      deep: true,
    },

    search(newValue) {
      let filteredList = [];
      filteredList = this.users.filter((el) => {
        return (
          el.nome.toLowerCase().includes(newValue.toLowerCase()) ||
          el.email.toLowerCase().includes(newValue.toLowerCase())
        );
      });
      this.filteredUsers = filteredList;
    },
  },

  methods: {
    getUsers() {
      this.isLoading = true;
      Service.getUsers(this.idCompany)
        .then((res) => {
          let response = res?.data ?? [];

          response.forEach((el) => {
            el.isLinked = false;
          });

          this.users = response;
          this.filteredUsers = response;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    linkUserToGroup() {
      this.linkUserLoading = true;
      Service.linkUserToGroup(
        this.selectedGroup.idResourceGroup,
        this.idCompany
      )
        .then((res) => {
          this.setUserForGroup(res?.data?.answer?.emails ?? []);
          this.linkUserLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.linkUserLoading = false;
        });
    },

    allowPermissionToUsers() {
      this.linkUserLoading = true;
      let filteredUsers = this.users.filter((user) => user.isLinked);

      let request = {
        emails: filteredUsers.map((user) => user.email),
        idCompany: this.idCompany,
        idResourceGroup: this.selectedGroup.idResourceGroup,
      };

      Service.allowAccessUser(request)
        .then(() => {
          this.linkUserLoading = false;
          this.$emit("closeEditMode");
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_SUCCESS_TO_EDIT_USER", {
              user: this.$i18n.t("TXT_USERS").toLowerCase(),
            }),
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error);
          this.linkUserLoading = false;
          this.$emit("closeEditMode");
          this.$store.commit("snackbarV2/set", {
            message: this.$i18n.t("TXT_ERROR_TO_EDIT_USER", {
              user: this.$i18n.t("TXT_USERS").toLowerCase(),
            }),
            type: "error",
          });
        });
    },

    setUserForGroup(users) {
      users.forEach((user) => {
        this.users.forEach((el) => {
          if (user == el.email) {
            el.isLinked = true;
          }
        });
      });
    },

    cancelChanges() {
      this.search = "";
      this.$emit("closeEditMode");
    },
  },

  created() {
    this.getUsers();
  },
};
</script>

<style lang="scss" scoped>
.group-name {
  font-weight: bold;
  font-size: 16px;
  color: $brand-color-primary-pure;
  margin-top: 12px !important;
}
.editMode {
  opacity: 0.4;
}
.main-list {
  margin-top: 16px;
  max-height: 250px;
  overflow-y: scroll;

  .user-item {
    display: flex;

    align-items: center;
    background-color: $neutral-color-high-light;
    border-top-left-radius: 5px;
    padding: 8px 6px;
    margin: 8px 0px;
  }
}
</style>