<template>
  <v-container class="fill-height align-center justify-center" fluid>
    <span>{{ status }}</span>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import UserService from "@/service/user-service";
const userService = new UserService();

export default {
  name: "redirectView",
  data() {
    return {
      status: "Loading company information...",
    };
  },
  computed: {
    ...mapGetters(["hasUser", "userData"]),
  },
  methods: {
    getUserInformation() {
      this.status = "Coletando informações do usuário";

      userService
        .getUsuario(this.userData.email)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getUserInformation();
  },
};
</script>