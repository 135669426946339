import { render, staticRenderFns } from "./skeleton-loader.vue?vue&type=template&id=4e804341"
var script = {}


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VSkeletonLoader})
