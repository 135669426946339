<template>
  <v-dialog v-model="isOpen" :persistent="isLoading" max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <Prd-btn
        v-bind="attrs"
        v-on="on"
        :outlined="true"
        :title="$t('TXT_SAVE_FILTERS')"
      />
    </template>
    <v-card :disabled="isLoading" class="pa-4">
      <h4 class="mb-4">{{ $t("TXT_SAVE_FILTER") }}</h4>
      <p class="font-weight-bold">
        {{ $t("TXT_CHANGE_WAY_TO_SAVE_FILTERS") }}
      </p>

      <Prd-text-field
        v-model="name"
        :title="$t('TXT_CHANGE_NAME_FOR_FILTER')"
        class="mb-2"
      />
      <p class="alert">
        {{ $t("TXT_SAVE_FILTER_MODAL_ALERT") }}
      </p>

      <v-row no-gutters justify="end">
        <Prd-btn
          :outlined="true"
          :title="$t('TXT_CANCEL')"
          @click="isOpen = false"
          class="mr-4"
        />
        <Prd-btn
          :disabled="!name"
          :loading="isLoading"
          :title="$t('TXT_SAVE')"
          @click="saveFilters"
        />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import PrdTextField from "@/components/common/prd-text-field.vue";
import Service from "@/service/predimonitor/filtros-predmonitor-service";

export default {
  components: { PrdBtn, PrdTextField },
  props: { selections: { type: Object } },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      name: null,
      service: new Service(),
    };
  },
  watch: {
    isOpen: {
      handler(value) {
        if (!value) this.name = null;
      },
    },
  },
  methods: {
    async saveFilters() {
      this.isLoading = true;
      try {
        const request = this.getRequest();
        const response = await this.service.CUDFilters(request);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_SAVE_FILTERS_SUCCESS"),
          type: "success",
        });
        this.$emit("addNewSavedFilter", response?.data?.answer ?? null);
        this.isOpen = false;
      } catch (error) {
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_SAVE_FILTER"),
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
    getRequest() {
      return {
        idMonitoringPredimonitorFilter: 0,
        name: this.name,
        delete: false,
        idCompany: this.$store.getters.userData.idCompany,
        values: this.getValues(),
        monitoringItems: this.getMonitoringItems(),
        isCost: this.$route.query.type?.includes("cost") ?? false,
      };
    },
    getMonitoringItems() {
      return this.selections.products.map((product) =>
        this.bodyToMonitoringItems(product)
      );
    },
    bodyToMonitoringItems(product) {
      return {
        idPredimonitorFiltersMonitoringItem: 0,
        monitoringId: product.idMonitoringItem,
        description: product.productName,
        ean: null,
        isDeleted: false,
        actualPrice: null,
      };
    },
    getValues() {
      const valueTypes = {
        categories: 1,
        origins: 2,
        sellers: 10,
        brands: 3,
        years: 8,
        states: 7,
        hourMeter: { min: 13, max: 14 },
      };

      const keys = Object.keys(valueTypes);

      let values = [];

      keys.forEach((key) => {
        if (!this.selections[key]) return;

        if (key != "hourMeter")
          values.push(
            ...this.selections[key].map((selection) =>
              this.bodyToValues(selection, valueTypes[key])
            )
          );
        else {
          const internalKeys = Object.keys(valueTypes[key]);
          internalKeys.forEach((intKey) => {
            if (this.selections[key][intKey] > 0) {
              const type = valueTypes[key][intKey];
              values.push(
                this.bodyToValues(Number(this.selections[key][intKey]), type)
              );
            }
          });
        }
      });

      return values;
    },
    bodyToValues(value, valueType) {
      return {
        idMonitoringPredimonitorFilterValue: 0,
        value: value,
        valueType: valueType,
        delete: false,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  font-size: 12px;
  color: #b00020;
}
</style>