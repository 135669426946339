<template>
  <div class="sign-out">
    <div class="loading-out">
      <Prd-loading-circular :color="'#fff'" size="80" />
      <p class="mt-4 white--text">{{ $i18n.t("TXT_LEAVING") }}...</p>
    </div>
  </div>
</template>

<script>
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
export default {
  components: { PrdLoadingCircular },

  methods: {
    resetUser() {
      let userData = {
        hasUser: false,
        token: null,
        idCompany: 0,
        useRefreshToken: null,
        adminRoles: [],
        username: null,
        companyName: null,
        vemUserType: null,
        email: this.$store.getters.getRememberLogin ? this.$store.getters.getEmail: null,
        password: this.$store.getters.getRememberLogin ? this.$store.getters.getPassword: null,
        rememberLogin: this.$store.getters.getRememberLogin ?? false
      };

      this.$store.dispatch("setResources", []);
      this.$store.dispatch("setUser", userData);
      this.$store.dispatch("clearCompany");
      this.$router.push("/login");
    },
  },
  mounted() {
    this.resetUser();
  },
};
</script>

<style scoped lang="scss">
.sign-out {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #1e3363;

  .loading-out {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>