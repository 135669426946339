<template>
  <v-card class="pa-4">
    <h3 class="mb-4">{{ $t("TXT_USER_DATA") }}</h3>
    <v-divider />
    <h3 class="my-4">{{ username }}</h3>
    <v-divider />
    <v-row no-gutters align="center" class="my-4">
      <v-icon :color="$prdStyles('color-primary')" class="mr-2"
        >mdi-email</v-icon
      >
      <span>{{ email }}</span>
    </v-row>
    <v-row no-gutters align="center" class="mt-2 mb-4">
      <v-icon :color="$prdStyles('color-primary')" class="mr-2"
        >mdi-phone</v-icon
      >
      <span>{{ phone }}</span>
    </v-row>
  </v-card>
</template>

<script>
export default {
  computed: {
    username() {
      return this.$store.getters.userData.username;
    },
    email() {
      return this.$store.getters.userData.email;
    },
    phone() {
      return this.$store.getters.userData.phone;
    },
  },
};
</script>