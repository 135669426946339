import i18n from "@/i18n";
import StoreManagementService from "@/service/company-config/store-management";
const service = new StoreManagementService();
import { handleError } from "@/utils/handleError";
export default {
  state: {
    userList: [],
    storeList: [],
    changes: [],
    maxPagesForUserList: null,
    storeLoading: false,
    totalPages: null,
    lastSelectedUserId: null,
  },
  getters: {
    hasSelectedUser: (state) => state.userList.some((user) => user.isSelected),
    hasChanges: (state) =>
      state.changes.some(
        (user) => user.linkedStores.length > 0 || user.unlinkedStores.length > 0
      ),
    hasChangesByUserId: (state) => (id) => {
      const user = state.changes.find((user) => user.id == id);
      if (!user) return false;
      return user.linkedStores?.length > 0 || user.unlinkedStores?.length > 0;
    },
    getStoreNameByValue: (state) => (value) => {
      const store = state.storeList.find((store) => store.value == value);
      return store?.description ?? i18n.t("TXT_UNKNOWN_STORE", { value });
    },
    getPositionItems: (state) => {
      const list = [{ cargoId: null, cargo: i18n.t("TXT_ALL") }];

      const morePositions = state.userList
        .flatMap((user) => user.positions) 
        .map(({ position, positionId }) => ({
          cargo: position,
          cargoId: positionId,
        }))
        .filter(
          (position, index, self) =>
            position.cargoId &&
            index === self.findIndex((p) => p.cargoId === position.cargoId)
        );

      list.push(...morePositions);
      return list;
    },
    getStoreItems: (state) => {
      const list = [
        {
          value: null,
          description: i18n.t("TXT_ALL_STORE"),
        },
      ];

      list.push(...state.storeList);
      return list;
    },
    getChanges(state) {
      const copyListChanges = structuredClone(state.changes);
      return copyListChanges.map((currentUser) => {
        const userInUserList = state.userList.find(
          (userInUserList) => userInUserList.id == currentUser.id
        );
        const copyUser = structuredClone(userInUserList);
        const storeLinked =
          copyUser.filterPermissionValues.find(
            (filter) => filter.filter == "Filial"
          )?.values ?? [];

        if (currentUser.linkedStores.length > 0)
          storeLinked.push(
            ...currentUser.linkedStores.map((store) => store.value)
          );

        if (currentUser.unlinkedStores.length > 0)
          currentUser.unlinkedStores.forEach(({ value }) => {
            const index = storeLinked.findIndex((store) => store == value);
            if (index != -1) storeLinked.splice(index, 1);
          });

        return {
          userId: currentUser.id,
          request: storeLinked,
        };
      });
    },
  },
  mutations: {
    setUserList(state, list) {
      if (!list) return;
      state.userList.push(...list);
    },
    clearUserList(state) {
      state.userList = [];
    },
    toggleSelectedUser(state, id) {
      state.userList.forEach((user) => {
        user.isSelected = user.id == id;
      });
    },
    removeAllSelectedUser(state) {
      state.userList.forEach((item) => {
        item.isSelected = false;
      });
    },
    setStoreList(state, list) {
      state.storeList = list ?? [];
    },
    setSelectedStoreByUser(state, id) {
      const user = state.userList.find((user) => user.id == id);
      const linkedStores =
        user.filterPermissionValues.find((filter) => filter.filter == "Filial")
          ?.values ?? [];
      state.storeList.forEach((store) => {
        const foundedStore = linkedStores.find(
          (linkedStore) => linkedStore == store.value
        );
        if (foundedStore) store.isSelected = true;
        else store.isSelected = false;
      });
    },
    syncChanges(state, idUser) {
      const user = state.changes.find((user) => user.id == idUser);

      state.storeList.forEach((store) => {
        const storeInChangesLink = user.linkedStores.find(
          (storeInChange) => storeInChange.value == store.value
        );
        const storeInChangesUnink = user.unlinkedStores.find(
          (storeInChange) => storeInChange.value == store.value
        );

        if (store.isSelected && storeInChangesUnink != undefined)
          store.isSelected = false;
        else if (!store.isSelected && storeInChangesLink != undefined)
          store.isSelected = true;
      });
    },
    toggleSelectedStore(state, value) {
      const store = state.storeList.find((store) => store.value == value);
      store.isSelected = !store.isSelected;
    },
    removeAllSelectedStore(state) {
      state.storeList.forEach((item) => {
        item.isSelected = false;
      });
    },
    addUserInChangeArray(state, id) {
      const foundedUser = state.userList.find((user) => user.id == id);
      const indexThisUSerInChangeArray = state.changes.findIndex(
        (user) => user.id == foundedUser.id
      );

      if (indexThisUSerInChangeArray == -1)
        state.changes.push({
          id: foundedUser.id,
          name: foundedUser.name,
          linkedStores: [],
          unlinkedStores: [],
        });
    },
    clearChanges(state) {
      state.changes = [];
    },
    updateChanges(state, value) {
      const selectedUser = state.userList.find((user) => user.isSelected);
      const linkedStoreOfSelectedUSer =
        selectedUser.filterPermissionValues.find(
          (filter) => filter.filter == "Filial"
        )?.values ?? [];
      const clickedStore = state.storeList.find(
        (store) => store.value == value
      );
      const clickedIdStore = clickedStore.value;
      const userInChangeArray = state.changes.find(
        (user) => user.id == selectedUser.id
      );
      const clickedStoreIsLinked = linkedStoreOfSelectedUSer.some(
        (store) => store == clickedIdStore
      );

      if (clickedStoreIsLinked) {
        const indexClickedStoreInUnlinkedStores =
          userInChangeArray.unlinkedStores.findIndex(
            (store) => store.value == clickedIdStore
          );
        if (indexClickedStoreInUnlinkedStores == -1)
          userInChangeArray.unlinkedStores.push(clickedStore);
        else
          userInChangeArray.unlinkedStores.splice(
            indexClickedStoreInUnlinkedStores,
            1
          );
      } else {
        const indexClickedStoreInLinkedStores =
          userInChangeArray.linkedStores.findIndex(
            (store) => store.value == clickedIdStore
          );
        if (indexClickedStoreInLinkedStores == -1)
          userInChangeArray.linkedStores.push(clickedStore);
        else
          userInChangeArray.linkedStores.splice(
            indexClickedStoreInLinkedStores,
            1
          );
      }
    },
    fixChanges(state, changes) {
      changes.forEach((currentUser) => {
        const userInUserList = state.userList.find(
          (userInUserList) => userInUserList.id == currentUser.userId
        );

        let storeLinked = userInUserList.filterPermissionValues.find(
          (filter) => filter.filter == "Filial"
        );

        storeLinked.values = currentUser.request;
      });
    },
    toogleStoreLoading(state) {
      state.storeLoading = !state.storeLoading;
    },
    updateTotalPages(state, page) {
      state.totalPages = page;
    },
    setLastSelectedUserId(state) {
      const user = state.userList.find((user) => user.isSelected);
      state.lastSelectedUserId = user?.id ?? null;
    },
  },
  actions: {
    async getUserList({ commit, state }, request) {
      try {
        const response = await service.GetUserList(request);
        const list = response.data.data.map((user) => ({
          ...user,
          isSelected: false,
        }));

        commit("setUserList", list);

        if (state.lastSelectedUserId)
          commit("toggleSelectedUser", state.lastSelectedUserId);

        commit("updateTotalPages", response.data.pagination.totalPages);
      } catch (error) {
        handleError(error, "Erro ao buscar a lista de usuários");
      }
    },
    async getStoreList({ commit, rootGetters }) {
      try {
        commit("toogleStoreLoading");
        commit("setStoreList", null);
        const idCompany = rootGetters.userData.idCompany;
        const response = await service.GetStoreList(idCompany);
        const list = response.data.map((store) => ({
          ...store,
          description: store.description ?? store.value,
          isSelected: false,
        }));

        commit("setStoreList", list);
      } catch (error) {
        handleError(error, "Erro ao buscar a lista de lojas");
      } finally {
        commit("toogleStoreLoading");
      }
    },

    async updateStoreAcess({ rootGetters }, { userId, request }) {
      try {
        const idCompany = rootGetters.userData.idCompany;

        await service.UpdateStore(idCompany, userId, request);
      } catch (error) {
        handleError(
          error,
          `Erro ao atualizar permissões do usuário: ${userId}`
        );
      }
    },
  },
};
