<template>
  <div class="my-flex">
    <h4 v-if="messageInFront">{{ text }}</h4>
    <v-progress-circular
      indeterminate
      :color="$prdStyles('color-primary')"
    ></v-progress-circular>
    <h4 v-if="!messageInFront">{{ text }}</h4>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      require: true,
      default: "Carregando",
    },
    messageInFront: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  gap: 1rem;
  align-items: center;
}
</style>