import { render, staticRenderFns } from "./product-panel-content.vue?vue&type=template&id=7091a5e7&scoped=true"
import script from "./product-panel-content.vue?vue&type=script&lang=js"
export * from "./product-panel-content.vue?vue&type=script&lang=js"
import style0 from "./product-panel-content.vue?vue&type=style&index=0&id=7091a5e7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7091a5e7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCard,VDivider,VExpansionPanelContent,VRow,VSwitch})
