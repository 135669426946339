<template>
  <v-card class="pa-4">
    <h4 class="mb-6">{{ $t("TXT_TICKET_CONSULTATION") }}</h4>
    <Ticket-card :isDetails="true" :ticket="ticket" class="mb-6" />

    <label>{{ $t("LBL_NEW_MESSAGE") }}</label>
    <v-textarea
      v-model="message"
      :disabled="isLoading || messageLoading || ticket.status == 3"
      :loading="messageLoading"
      outlined
      hide-details
      no-resize
      class="mt-2"
    ></v-textarea>
    <v-row no-gutters justify="end" class="mt-4">
      <Prd-btn
        :title="$t('BTN_SEND_MESSAGE')"
        :disabled="isLoading || messageLoading || ticket.status == 3"
        :outlined="true"
        @click="insertMessage"
      />
    </v-row>

    <Prd-loading-msg v-if="isLoading" :text="$t('TXT_LOADING_HISTORY')" />
    <Message-history
      :ticketMessages="ticketMessages"
      :ticket="ticket"
      @showTicketList="$emit('showTicketList')"
      v-else
    />
  </v-card>
</template>

<script>
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import TicketCard from "../ticket-list/ticket-card.vue";
import MessageHistory from "./message-history.vue";
import PrdLoadingMsg from "@/components/common/prd-loading-msg.vue";
import service from "@/service/support/index.js";
const Service = new service();
export default {
  props: ["ticket"],
  components: {
    TicketCard,
    PrdBtn,
    MessageHistory,
    PrdLoadingMsg,
  },
  data() {
    return {
      message: null,
      ticketMessages: null,

      isLoading: false,
      messageLoading: false,
    };
  },

  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },

  watch: {
    ticket: {
      handler(value) {
        if (!value) return;

        this.getTicketMessages(value.idSupport_Ticket);
      },
      immediate: true,
    },
  },

  methods: {
    async getTicketMessages(idTicket) {
      this.isLoading = true;

      try {
        let response = await Service.getTicketDetails(this.idCompany, idTicket);
        this.ticketMessages = response?.data?.answer ?? null;
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_ERROR_GET_HISTORY_MESSAGE"),
          type: "error",
        });
      }

      this.isLoading = false;
    },

    async insertMessage() {
      this.messageLoading = true;

      let request = {
        idCompany: this.idCompany,
        idSupport_Ticket: this.ticket.idSupport_Ticket,
        message: this.message,
      };

      try {
        await Service.insertMessage(request);
        this.getTicketMessages(this.ticket.idSupport_Ticket);
        this.message = null;
        this.$store.commit("snackbarV2/set", {
          message: "Sucesso ao inserir mensagem",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: "Erro ao inserir mensagem",
          type: "error",
        });
      }

      this.messageLoading = false;
    },
  },
};
</script>

<style>
</style>