<template>
  <v-card class="pa-4" :disabled="isLoading" :loading="isLoading">
    <h3 class="mb-6">{{ $t("TXT_REGISTRED_USERS").toUpperCase() }}</h3>
    <Prd-datatable
      :headers="headers"
      :items="filteredItems"
      :useSearchField="true"
      :titleSearchField="$t('TXT_SEARCH_USER_BY_NAME_EMAIL')"
      :useItemsPerPageField="true"
      :titleItemsPerPageField="$t('TXT_USERS_PER_PAGE')"
      :fieldsForSearch="fieldsForSearch"
      :userOthersFields="true"
      :indicators="indicators"
    >
      <template slot="othersFields">
        <Prd-combo-box
          v-model="selectedAccess"
          :title="$t('TXT_ACCESS_PLURAL')"
          :placeholder="$t('TXT_SELECT')"
          :items="accessList"
          :clearable="true"
          :disabled="items.length <= 0"
        />
      </template>

      <template v-slot:[`item.nome`]="{ item }">
        <td class="px-0 text-center">
          <div :class="`px-1 ${getBorderByIndicators(item)}`">
            <Prd-text-field
              v-if="item.inEditMode"
              v-model="item.nome"
              :disabled="item.generalIsLoading"
              style="width: 100%"
            />
            <span v-else>{{ item.nome }} </span>
          </div>
        </td>
      </template>
      <template v-slot:[`item.telefone`]="{ item }">
        <div v-if="item.inEditMode">
          <Prd-text-field
            v-model="item.telefone"
            :disabled="item.generalIsLoading"
            :type="'number'"
            hide-spin-buttons
          />
        </div>
        <div v-else>
          <span>{{ setMaskPhone(item.telefone) }}</span>
        </div>
      </template>

      <template v-slot:[`item.isProprietario`]="{ item }">
        <Admin-access-user-modal
          v-if="item.inEditMode || item.isProprietario"
          :user="item"
          :idCompany="selectedCompany.idEmpresa"
          @isLoading="item.generalIsLoading = $event"
          @invertIsProprietario="invertIsProprietario"
        />
        <v-icon :color="$prdStyles('color-primary')" v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.adminRoles`]="{ item }">
        <Handler-regional-access
          :item="item"
          @handlerRegionalAccess="handlerRegionalAccess($event, item)"
        />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-row no-gutters justify="center" align="center">
          <div v-if="!item.inEditMode">
            <v-icon
              :disabled="item.generalIsLoading"
              :color="$prdStyles('color-primary')"
              class="mr-1"
              @click="item.inEditMode = !item.inEditMode"
              >mdi-pencil-box</v-icon
            >
            <Delete-user-modal
              :user="item"
              :idCompany="selectedCompany.idEmpresa"
              @isLoading="item.generalIsLoading = $event"
              @resetOriginalList="getUsersByCompany(selectedCompany.idEmpresa)"
            />
          </div>
          <div v-else>
            <Confirm-edit-user-modal
              class="mr-1"
              :user="item"
              :originalList="items"
              :idCompany="selectedCompany.idEmpresa"
              @isLoading="item.generalIsLoading = $event"
              @resetOriginalList="getUsersByCompany(selectedCompany.idEmpresa)"
            />
            <Cancel-edit-user-modal
              :user="item"
              @resetUpdatedDatas="resetUpdatedDatas"
            />
          </div>
        </v-row>
      </template>
    </Prd-datatable>
  </v-card>
</template>

<script>
import PrdDatatable from "@/components/common/prd-datatable/prd-datatable.vue";
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import PrdTextField from "@/components/common/prd-text-field.vue";
import DeleteUserModal from "./components/delete-user-modal.vue";
import ConfirmEditUserModal from "./components/confirm-edit-user-modal.vue";
import CancelEditUserModal from "./components/cancel-edit-user-modal.vue";
import AdminAccessUserModal from "./components/admin-access-user-modal.vue";
import HandlerRegionalAccess from "./components/handler-regional-access.vue";
import GeneralSystemConfigsService from "@/service/general-system-configs";
export default {
  components: {
    PrdDatatable,
    PrdComboBox,
    PrdTextField,
    DeleteUserModal,
    ConfirmEditUserModal,
    CancelEditUserModal,
    AdminAccessUserModal,
    HandlerRegionalAccess,
  },
  props: {
    selectedCompany: {
      type: Object,
    },
    getUsersFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        { text: this.$i18n.t("TXT_EMAIL"), align: "center", value: "email" },
        { text: this.$i18n.t("TXT_USER_NAME"), align: "center", value: "nome" },
        { text: this.$i18n.t("TXT_PHONE"), align: "center", value: "telefone" },
        {
          text: this.$i18n.t("TXT_ADMIN_ACCESS"),
          align: "center",
          value: "isProprietario",
        },
        {
          text: this.$i18n.t("TXT_REGIONAL_ACCESS"),
          align: "center",
          value: "adminRoles",
        },
        {
          text: this.$i18n.t("TXT_ACTIONS"),
          align: "center",
          value: "actions",
        },
      ],
      items: [],
      filteredItems: [],
      fieldsForSearch: ["nome", "email"],
      selectedAccess: null,
      accessList: [
        { text: this.$i18n.t("TXT_ADMIN"), value: true },
        {
          text:
            this.$i18n.t("TXT_NO") + this.$i18n.t("TXT_ADMIN").toLowerCase(),
          value: false,
        },
      ],
      indicators: [
        {
          text: this.$i18n.t("TXT_USER_ADMIN"),
          color: "yellow",
          referenceField: "isProprietario",
          activatorValue: true,
        },
      ],
      service: new GeneralSystemConfigsService(),
      isLoading: false,
    };
  },

  methods: {
    filterItems() {
      this.filteredItems = JSON.parse(JSON.stringify(this.items));
      if (this.selectedAccess)
        this.filteredItems = this.filteredItems.filter(
          (item) => item.isProprietario === this.selectedAccess.value
        );
    },

    getBorderByIndicators(item) {
      const ind = this.indicators.find(
        (ind) => ind.activatorValue === item[ind.referenceField]
      );
      if (ind) return `generalBorder ${ind.color}Border`;
      else return "";
    },
    invertIsProprietario(user) {
      this.items = this.items.map((item) => {
        return { ...item, isProprietario: item.idUsuario == user.idUsuario };
      });
      this.filteredItems = this.filteredItems.map((item) => {
        return { ...item, isProprietario: item.idUsuario == user.idUsuario };
      });
    },

    handlerRegionalAccess(level, user) {
      const checkLevel = user.adminRoles.find((access) => access === level);
      if (checkLevel) {
        user.adminRoles = user.adminRoles.filter((access) => access !== level);
      } else user.adminRoles.push(level);
    },

    async getUsersByCompany(idEmpresa) {
      this.isLoading = true;
      try {
        const response = await this.service.getUsersByCompany(idEmpresa);
        this.items = response.data.map((user) => {
          return {
            ...user,
            inEditMode: false,
            generalIsLoading: false,
            loadingRegionalAcess: false,
          };
        });
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: this.$i18n.t("TXT_GET_USERS_ERROR"),
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    resetUpdatedDatas(idUsuario) {
      const index = this.filteredItems.findIndex(
        (user) => user.idUsuario === idUsuario
      );
      let message,
        type = null;
      if (index !== -1) {
        const foundedOriginalData = this.items.find(
          (user) => user.idUsuario === idUsuario
        );
        this.filteredItems.splice(index, 1, foundedOriginalData);
        message = this.$i18n.t("TXT_REMOVE_UPDATE_SUCCESS");
        type = "success";
      } else {
        message = this.$i18n.t("TXT_REMOVE_UPDATE_ERROR");
        type = "error";
      }
      this.$store.commit("snackbarV2/set", { message, type });
    },

    setMaskPhone(phone) {
      let onlyNumbers = phone.replace(/\D/g, "");

      let newFormat =
        "(" +
        onlyNumbers.substring(0, 2) +
        ") " +
        onlyNumbers.substring(2, 7) +
        "-" +
        onlyNumbers.substring(7, 11);

      return newFormat;
    },
  },
  watch: {
    items: {
      handler() {
        this.filterItems();
      },
      immediate: true,
      deep: true,
    },
    selectedAccess: {
      handler() {
        this.filterItems();
      },
      immediate: true,
      deep: true,
    },
    selectedCompany: {
      handler(value) {
        this.getUsersByCompany(value.idEmpresa);
      },
      deep: true,
      immediate: true
    },
    getUsersFlag: {
      handler() {
        this.getUsersByCompany(this.selectedCompany.idEmpresa);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.generalBorder {
  border-left: 6px solid !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.yellowBorder {
  border-left-color: $feedback-color-helper-pure !important;
}
</style>