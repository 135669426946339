import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class PricingRules extends ServiceBase {
  async getRules(idCompany, page, recordsPerPage) {
    return await axiosInstance.get(
      API_URL +
      `api/v2/DynamicPricingRules/GetListPaginated?idCompany=${idCompany}&page=${page}&recordsPerPage=${recordsPerPage}`
    );
  }

  async swapRule(idCompany, idRule1, idRule2) {
    return await axiosInstance.get(
      API_URL +
      `api/v2/DynamicPricingRules/SwapRuleOrder?idCompany=${idCompany}&idRule1=${idRule1}&idRule2=${idRule2}`
    );
  }

  async setOrder(idCompany, idRule, orderToSet) {
    return await axiosInstance.get(
      API_URL +
      `api/v2/DynamicPricingRules/SetOrder?idCompany=${idCompany}&idRule=${idRule}&orderToSet=${orderToSet}`
    );
  }

  async switchRule(idCompany, idRule) {
    return await axiosInstance.get(
      API_URL +
      `api/v2/DynamicPricingRules/SwitchActivationState?idCompany=${idCompany}&idRule=${idRule}`
    );
  }

  async cloneRule(idCompany, idRule) {
    return await axiosInstance.get(
      API_URL +
      `api/v2/DynamicPricingRules/CloneRule?idCompany=${idCompany}&idRule=${idRule}`
    );
  }

  async getPriceRulesConfigurations(idCompany) {
    return await axiosInstance.get(
      API_URL + `api/v2/DynamicPricingRules/CompanyConfiguration?idCompany=${idCompany}`
    );
  }

  async importConditionRounding(file) {
    return await axiosInstance.post(
      API_URL + "api/v2/DynamicPricingRules/ImportConditionRounding",
      file
    );
  }

  async deleteRule(idCompany, idRule) {
    return await axiosInstance.delete(
      API_URL +
      `api/v2/DynamicPricingRules/Delete?idCompany=${idCompany}&idRule=${idRule}`
    );
  }

}
