import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class HeaderService extends ServiceBase {

  async GetFaqs() {
    return await axiosInstance.get(API_URL + "api/Sistema/GetFaqs");
  }

  async UpdatePassword(request) {
    return await axiosInstance.post(
      API_URL + "api/Usuario/AlterarSenha",
      request
    );
  }

  async GetAllPayments(idCompany) {
    return await axiosInstance.get(
      API_URL + `api/v1/Pagamento/GetByEmpresa?idEmpresa=${idCompany}`
    );
  }
  
}
