import styles from "../styles/_export.scss"

const Predify_Styles_Util = {

    install(Vue) {
        const GetStyle = function(name){
            return styles[name];
        }

        // 1. adicionar método ou propriedade global
        Vue.prdStyles = GetStyle;

        Vue.prototype.$prdStyles = GetStyle;
    }
}

export default Predify_Styles_Util;