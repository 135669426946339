<template>
  <v-container fluid>
    <titleBreadCrumbs
      :title="$t('TXT_MY_PROFILE')"
      :breadCrumbs="breadcrumbs"
      :hasTooltip="false"
    />
    <v-row no-gutters justify="center" align="start">
      <v-col cols="12" sm="6" class="pr-2">
        <data-card />
      </v-col>
      <v-col cols="12" sm="6" class="pl-2">
        <update-password-card />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import titleBreadCrumbs from "@/Design_System/common/title.vue";
import DataCard from "./components/data-card.vue";
import UpdatePasswordCard from "./components/update-password-card.vue";

export default {
  name: "ProfileMain",
  components: {
    titleBreadCrumbs,
    DataCard,
    UpdatePasswordCard,
  },
  data() {
    return {
      breadcrumbs: [
        { text: this.$i18n.t("TXT_USER") },
        { text: this.$i18n.t("TXT_PROFILE") },
      ],
    };
  },
};
</script>