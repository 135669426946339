<template>
  <v-card class="pa-4">
    <v-expansion-panels
      v-model="panel"
      accordion
      flat
      :disabled="externalLoading || noHasData"
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="pa-0">
          <v-row no-gutters align="center">
            <h4>{{ $t("TXT_PRODUCT_COMPARISON_UPPERCASE") }}</h4>
            <Prd-tooltip :text="$t('TXT_PRODUCT_COMPARISON_TOOLTIP')" />
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <Filter-area class="mb-6" />
          <Table-main v-if="showList" />
          <Map-main v-else />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import FilterArea from "./components/filter-area.vue";
import PrdTooltip from "@/components/common/prd-tooltip.vue";
import MapMain from "./components/map-main.vue";
import TableMain from "./components/table-main.vue";
export default {
  components: {
    FilterArea,
    PrdTooltip,
    MapMain,
    TableMain,
  },
  props: { externalLoading: { type: Boolean, default: false } },
  data() {
    return {
      panel: undefined,
    };
  },
  computed: {
    noHasData() {
      return (
        this.$store.state.ProductComparisionStore.predimonitorResults.length ==
        0
      );
    },
    showList() {
      const visualizationType =
        this.$store.state.ProductComparisionStore.visualization;
      return visualizationType == "list";
    },
    isLoading() {
      return this.$store.state.PredimonitorStore.isLoading;
    },
  },

  watch: {
    isLoading: {
      handler(value) {
        if (value) this.panel = undefined;
      },
      immediate: true,
    },
  },
};
</script>