export default {
  state: () => ({
    enterpriseEventHistory: null,
  }),
  mutations: {
    setEnterpriseEventHistory(state, enterpriseEventHistory) {
      state.enterpriseEventHistory = enterpriseEventHistory;
    },
  },
};
