import i18n from "@/i18n";
import service from "@/service/pricing-rules/index.js";
const Service = new service();
import { compareRuleList, changedRuleIds } from "./copilot/copilot-utils";
export default {
  state: () => ({
    rules: [
      {
        text: i18n.t("TXT_RESTRICT_BY_WEEKDAYS"),
        isActive: true,
        idCondition: 0,
        numberOrder: 0,
        conditionType: 0,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_USE_COMPETITOR_PRICE"),
        isActive: true,
        idCondition: 0,
        numberOrder: 1,
        conditionType: 4,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_INCREMENT_DECREMENT_PRICE"),
        isActive: true,
        idCondition: 0,
        numberOrder: 3,
        conditionType: 3,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_LIMIT_PRICE_BY_COMPETITOR"),
        isActive: true,
        idCondition: 0,
        numberOrder: 4,
        conditionType: 1,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_LAST_PRICE"),
        isActive: true,
        idCondition: 0,
        numberOrder: 1,
        conditionType: 10,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_ROUNDING_GENERIC"),
        isActive: true,
        idCondition: 0,
        numberOrder: 5,
        conditionType: 6,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_ROUNDING_09"),
        isActive: true,
        idCondition: 0,
        numberOrder: 6,
        conditionType: 7,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_ROUNDING_99"),
        isActive: true,
        idCondition: 0,
        numberOrder: 7,
        conditionType: 8,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_ROUNDING_50"),
        isActive: true,
        idCondition: 0,
        numberOrder: 8,
        conditionType: 9,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_CONTRIBUTION_MARGIN_LIMIT"),
        isActive: true,
        idCondition: 0,
        numberOrder: 9,
        conditionType: 2,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_ROUNDING_90"),
        isActive: true,
        idCondition: 0,
        numberOrder: 8,
        conditionType: 11,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_ROUNDING_10"),
        isActive: true,
        idCondition: 0,
        numberOrder: 8,
        conditionType: 12,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_ROUNDING_LAST_CENT"),
        isActive: true,
        idCondition: 0,
        numberOrder: 13,
        conditionType: 13,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_PROFIT_VARIATION_LOWER"),
        isActive: true,
        idCondition: 0,
        numberOrder: 14,
        conditionType: 14,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_COMPETITIVE_INDEX"),
        isActive: true,
        idCondition: 0,
        numberOrder: 15,
        conditionType: 15,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_ROUNDING_CENT"),
        isActive: true,
        idCondition: 0,
        numberOrder: 16,
        conditionType: 16,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_VARIATION_DEMAND"),
        isActive: true,
        idCondition: 0,
        numberOrder: 17,
        conditionType: 17,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_DEMAND_X_STOCK"),
        isActive: true,
        idCondition: 0,
        numberOrder: 18,
        conditionType: 18,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_PRICE_SALE_VARIATION"),
        isActive: true,
        idCondition: 0,
        numberOrder: 19,
        conditionType: 19,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_BILLING_VARIATION"),
        isActive: true,
        idCondition: 0,
        numberOrder: 20,
        conditionType: 20,
        showWithResource: "all",
      },

      {
        text: i18n.t("TXT_RULECONDITION_MINIMUMMARGINNETWORK"),
        isActive: true,
        idCondition: 0,
        numberOrder: 21,
        conditionType: 21,
        showWithResource: "rule.minimum.network.margin",
      },
      {
        text: i18n.t("TXT_RULECONDITION_PRICELIMIT"),
        isActive: true,
        idCondition: 0,
        numberOrder: 22,
        conditionType: 22,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_PRICEMULTIPLIER"),
        isActive: true,
        idCondition: 0,
        numberOrder: 23,
        conditionType: 23,
        showWithResource: "rule.pricemultiplier",
      },
      {
        text: i18n.t("TXT_RULECONDITION_STOCKMIN"),
        isActive: true,
        idCondition: 0,
        numberOrder: 24,
        conditionType: 24,
        showWithResource: "all",
      },
      {
        text: i18n.t("TXT_RULECONDITION_PRODUCTTURNOVERLIMIT"),
        isActive: true,
        idCondition: 0,
        numberOrder: 25,
        conditionType: 25,
        showWithResource: "rule.productturnoverlimit",
      },
      {
        text: i18n.t("TXT_RULECONDITION_FLAGSHIPPRODUCT"),
        isActive: true,
        idCondition: 0,
        numberOrder: 26,
        conditionType: 26,
        showWithResource: "rule.flagshipproduct",
      },
    ],
    ruleList: [],
    groupRules: [],
    sellerList: [],
    sellersListFlag: false,
    ruleListLoading: false,
    skeletonListLoading: false,
    changedIdRules: [],
    sellerListLoading: false,
    priceLimitRuleReferenceItems: [],
  }),

  getters: {
    getRules: (state) => {
      return state.rules;
    },
    getRuleNameByConditionType: (state) => (conditionType) => {
      const rules = state.rules;
      const foundedRule = rules.find(
        (rule) => rule.conditionType == conditionType
      );

      return foundedRule?.text ?? "-";
    },
    getConditionsByIdRule: (state) => (idRule) => {
      const group =
        state.groupRules.find((group) => group.idRule == idRule) ?? null;
      return group?.conditions ?? [];
    },
    getSellerList: (state) => {
      return state.sellerList;
    },
    getGroupRuleByIdRule: (state) => (idRule) => {
      return state.groupRules.find((group) => group.idRule == idRule) ?? null;
    },
    getNameAndConditionType: (state) => {
      return state.rules.map((rule) => ({
        text: rule.text,
        conditionType: rule.conditionType,
      }));
    },
    getSellersListFlag: (state) => {
      return state.sellersListFlag;
    },
    getPriceLimitRuleReferenceItems: (state) => {
      return state.priceLimitRuleReferenceItems;
    },
  },

  actions: {
    addRule({ getters }, { idRule, conditionType }) {
      const group = getters.getGroupRuleByIdRule(idRule);
      group.addRule(conditionType);
    },
    removeRule({ getters }, { idRule, index }) {
      const group = getters.getGroupRuleByIdRule(idRule);
      group.removeRule(index);
    },
    updateSequenceRules({ getters }, { idRule, updatedList }) {
      const group = getters.getGroupRuleByIdRule(idRule);
      group.updateSequenceRules(updatedList);
    },
    updateConditions(
      { getters },
      { idRule, key, conditionType, index, values }
    ) {
      const group = getters.getGroupRuleByIdRule(idRule);
      group[key](conditionType, index, values);
    },

    async getRules({ rootState, commit, state }, payload) {
      if (payload?.fromCopilot) state.skeletonListLoading = true;
      else state.ruleListLoading = true;

      let oldRuleList = state.ruleList;

      try {
        const idCompany = rootState?.userStore?.idCompany ?? null;
        let response = await Service.getRules(idCompany, 0, 10000);
        let newRuleList = response?.data?.answer?.answer ?? [];

        if (payload?.fromCopilot) {
          const newRules = compareRuleList(oldRuleList, newRuleList);
          const changedIds = changedRuleIds(newRules, payload.newOperations);
          commit("updateChangedRules", changedIds);
        }

        commit("insertRuleList", newRuleList);
      } catch (error) {
        console.log("erro ao buscar regras", error);
        commit(
          "snackbarV2/set",
          { message: "Erro ao buscar regras", type: "error" },
          { root: true }
        );
      }

      state.ruleListLoading = false;
      state.skeletonListLoading = false;
    },

    updateSequenceGroupRules({ commit }, updatedList) {
      commit("updateSequenceGroupRules", updatedList);
    },
    async getPriceRulesConfigurations({ commit, rootGetters }) {
      const idCompany = rootGetters.userData.idCompany;
      try {
        const response = await Service.getPriceRulesConfigurations(idCompany);
        commit("updatePriceLimitRuleReferenceItems", response.data);
      } catch (error) {
        console.log(
          i18n.t("TXT_ERROR_GET_PRICING_RULES_CONFIGURATIONS", { idCompany }),
          error
        );
        commit(
          "snackbarV2/set",
          {
            message: i18n.t("TXT_ERROR_GET_PRICING_RULES_CONFIGURATIONS", {
              idCompany,
            }),
            type: "error",
          },
          { root: true }
        );
      }
    },
  },

  mutations: {
    updateSellerList(state, newList) {
      state.sellerList = newList;
    },
    updateSellerListFlag(state) {
      state.sellersListFlag = !state.sellersListFlag;
    },
    insertGroupRules(state, newGroupRule) {
      state.groupRules.push(newGroupRule);
    },
    removeGroupRules(state, { idRule }) {
      const index = state.groupRules.findIndex((rule) => rule.idRule == idRule);
      if (index != -1) state.groupRules.splice(index, 1);
    },
    insertRuleList(state, payload) {
      state.ruleList = payload;
    },
    updateSellerListLoading(state, status) {
      state.sellerListLoading = status;
    },
    updateChangedRules(state, payload) {
      state.changedIdRules = payload;
    },
    updateSequenceGroupRules(state, updatedList) {
      state.ruleList = updatedList;
    },
    updatePriceLimitRuleReferenceItems(state, response) {
      const getBody = (item) => ({
        value: item.value,
        description: item.description.startsWith("TXT_")
          ? i18n.t(item.description)
          : item.description,
      });

      const items = response.result
        .filter((item) => item.configurationType == 0)
        .map((item) => getBody(item));

      state.priceLimitRuleReferenceItems = items ?? [];
    },
  },
};
