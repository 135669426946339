<template>
  <div class="d-flex flex-column">
    <text-field class="myGap" v-bind="$attrs" v-on:input="$emit('input', $event)">
      <template v-slot:prepend-inner>
        <slot name="prepend-inner"></slot>
      </template>
      <template v-slot:append>
        <slot name="append"></slot>
      </template>
      <template v-slot>
        <slot></slot>
      </template>
    </text-field>
    <div
      class="predify-blue mt-2 align-self-start"
      v-if="suggestionState === 'LOADING'"
    >
      <progress-component></progress-component>
    </div>
    <div class="predify-blue" v-else-if="suggestionState === 'DATA_READY'">
      <slot name="suggestion"></slot>
    </div>
    <div class="color-warning" v-else-if="suggestionState === 'ERROR'">
      <slot name="error"></slot>
    </div>
  </div>
</template>
<script>
import textField from "@/Design_System/common/text-field";
import progressComponent from "@/Design_System/common/progressComponent";

export default {
  components: {
    textField,
    progressComponent,
  },
  props: {
    suggestionState: {
      type: String,
      validator: (value) =>
        value === "LOADING" ||
        value === "DATA_READY" ||
        value === "ERROR" ||
        value === "NOT_CALLED",
    },
  },
  data: () => ({}),
};
</script>
<style lang="scss">
.predify-blue {
  color: $brand-color-primary-pure;
}
.color-warning {
  color: $feedback-color-warning-pure;
}
.myGap{
  gap: .5rem !important;
}
</style>
