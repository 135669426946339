<template>
  <div class="mt-8">
    <company-card
      v-for="company in companyList.list"
      :key="company.idEmpresa"
      :company="company"
      class="mb-4"
    />
  </div>
</template>

<script>
import companyCard from "./company-card.vue";

export default {
  props: ["companyList"],
  components: { companyCard },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
</style>