<template>
  <div>
    <v-row no-gutters v-for="(itemList, index) in reorganizedList" :key="index">
      <v-col cols="3" v-for="(item, index) in itemList" :key="index">
        <v-row
          no-gutters
          align="center"
          justify="center"
          class="bottomBorderPrd"
        >
          <Prd-text-field
            v-model="item.decimalValue"
            :type="'number'"
            hide-spin-buttons
            :min="0"
            style="width: 100px"
            @input="setValuesInOriginalList(item)"
          />
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
export default {
  components: { PrdTextField },
  props: ["list"],
  data() {
    return {
      reorganizedList: [],
      originalList: [],
    };
  },
  watch: {
    list: {
      handler(value) {
        this.originalList = value;
        this.runReorganizeList(value);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    runReorganizeList(list) {
      const newList = [];
      list.forEach((el) => {
        const index = el.stringValue.split("_")[0];
        if (!newList[index]) newList[index] = [];
        newList[index].push(el);
      });
      this.reorganizedList = newList;
    },
    setValuesInOriginalList(item) {
      const body = {
        id: item.idConditionValue,
        value: item.decimalValue,
      };
      this.$emit("updatePsycologicPriceConditions", body);
    },
  },
};
</script>

<style lang="scss" scoped>
.bottomBorderPrd {
  border-bottom: 1px solid $neutral-color-high-medium;
  padding-bottom: 8px;
  margin-bottom: 8px;
}
</style>