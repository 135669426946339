import axiosInstance, { API_URL_PREDIMONITOR } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class FiltersPredimonitorService extends ServiceBase {

  prefix = "api/DynamicFilter"

  async getFilters(idCompany, request) {
    return await axiosInstance.post(`${API_URL_PREDIMONITOR}${this.prefix}/GetFilter?companyId=${idCompany}`, request)
  }
  async getResults(request) {
    return await axiosInstance.post(`${API_URL_PREDIMONITOR}${this.prefix}/GetResult`, request)
  }
  async setCrawlerRotine(request) {
    return await axiosInstance.get(`${API_URL_PREDIMONITOR}${this.prefix}/GenerateCache`, { params: { companyId: request.idCompany, startDate: request.startDate, endDate: request.endDate, refDate: request.refDate, skip: request.skipItems, removeOlds: request.removeOlds, removeOutliers: request.removeOutliers } });
  }
}