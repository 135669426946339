<template>
  <div id="title">
    <v-row align="center" class="view-title"
      >{{ title }}
      <v-tooltip v-if="hasTooltip" class="tootip" right color="#f5f5f5">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="icon" right v-bind="attrs" v-on="on"> $info</v-icon>
        </template>
        <slot name="tooltip">
          <span style="color: black !important">{{ tooltipText }}</span>
        </slot>
      </v-tooltip>
    </v-row>
    <v-breadcrumbs :items="breadCrumbs" class="pa-0 mx-0 mt-0 breadcrumb">
      <template v-slot:divider>
        <v-icon>$chevronRight</v-icon>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: "titleBreadCrumbsComponent",
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      requiired: true,
    },
    breadCrumbs: {
      type: Array,
      required: false,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
    },
    hasTooltip: {
      type: Boolean,
      default: true
    }
  },
};
</script>

<style lang="scss" scoped>
#title * {
  font-family: "Ubuntu", sans-serif;
}

.v-application ul {
  padding: 0 !important;
}

.v-breadcrumbs {
  padding: 0 !important;
  opacity: 0.5;
}

.v-breadcrumbs__item,
.v-breadcrumbs li {
  font-size: $font-size-xxxs !important;
  color: $neutral-color-low-light !important;
}

.view-title {
  font-size: $font-size-md;
  font-weight: $font-weight-regular;
  color: $neutral-color-low-pure;
  margin: 24px 5px 0px 0px;
}

.breadcrumb {
  font-size: $font-size-xxxs;
  font-weight: $font-weight-regular;
  @extend .mb-xxs;
}

.icon {
  cursor: pointer;
  color: $brand-color-primary-medium;
  position: relative;
  font-size: $font-size-md;
  margin: 0px 10px 8px 5px;
  padding: 8px 0px;
}
</style>
