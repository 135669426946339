import axiosInstance, { API_URL } from "@/configs/axiosConfig.js";
import ServiceBase from "@/service/service-base";

export default class CompanySelect extends ServiceBase {

    async selectCompany(idCompany) {
        return await axiosInstance.get(API_URL + `api/Empresa/GetEmpresa?idEmpresa=${idCompany}`)
    }

    async getCompanyList() {
        return await axiosInstance.get(API_URL + "api/Empresa/GetEmpresasSelecionar")
    }

    async listCountries() {
        return await axiosInstance.get(API_URL + "api/V2/Region/ListCountries")
    }

    async createCompany(request) {
        /**
         * @param {string} IdParceiro
         * @param {number} idCountry
         * @param {number} setor
         * @param {string} idUsuario
         * @param {string} nomeRazaoSocial
         */
        return await axiosInstance.post(API_URL + "api/Empresa", request)
    }

    async getCompanyData(idCompany) {
        /**
         * @param {number} idCompany
         */
        return await axiosInstance.get(API_URL + `api/Empresa/GetEmpresa?idEmpresa=${idCompany}`)
    }
}