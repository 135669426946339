export function setCurrencyV3(value) {
    if (value === null || value === undefined || isNaN(value) || value == '-') return '-'

    let rounded = value.toFixed(2);//(Math.trunc(value * 100) / 100).toString();
    let [integerPart, decimalPart] = rounded.split('.');


    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return "R$" + ' ' + integerPart + ',' + decimalPart;
}