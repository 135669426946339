<template>
  <v-row no-gutters align="center">
    <span class="mr-4">
      <span class="font-weight-bold mr-1">{{$t('TXT_LAST_UPDATE_2')}}:</span>
      <span v-if="date && hour">
        {{ date }}
        {{ hour }}
        |
        <span v-if="setChangeLog">
          <span class="font-weight-bold">{{ setChangeLog }}</span>
          |
        </span>
      </span>
      <span v-else> - |</span>
      <span class="font-weight-bold mr-2">{{$t('TXT_RESPONSIBLE')}}:</span>
      {{ userName ? userName : "-" }}
    </span>
  </v-row>
</template>

<script>
export default {
  props: {
    date: { type: String },
    hour: { type: String },
    changeLog: { type: String },
    userName: { type: String },
    productName: { type: String },
  },
  computed: {
    setChangeLog() {
      return this.getTypePrecificationInChangeLog(this.changeLog);
    },
  },
  methods: {
    getTypePrecificationInChangeLog(changeLog) {
      const keyword = "Tipo de Precificação";
      const checkChangeLog = changeLog
        .toLowerCase()
        .includes("Tipo de Precificação".toLowerCase());
      if (!checkChangeLog) return "";
      const newChangeLog = changeLog
        .split(" | ")
        .find((log) => log.toLowerCase().includes(keyword.toLowerCase()));
      return newChangeLog;
    },
  },
};
</script>