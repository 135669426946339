var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4",attrs:{"id":"currentPrice","disabled":_vm.simulationLoading,"flat":""}},[_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":"","align":"center","justify":"end"}},[_c('Prd-btn',{staticClass:"mr-4",attrs:{"title":'Simular',"disabled":_vm.isLoading || _vm.simulationLoading,"loading":_vm.simulationLoading,"outlined":""},on:{"click":_vm.runSimulation}}),_c('Prd-btn',{attrs:{"title":'Exportar',"icon":'mdi-download-outline',"smallIcon":true,"leftIcon":true,"disabled":_vm.isLoading || !_vm.acelenSimulationList.length || _vm.simulationLoading},on:{"click":function($event){return _vm.downloadData(_vm.acelenSimulationList, 'simulação')}}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.acelenSimulationList,"loading":_vm.isLoading,"footer-props":_vm.footerProps,"disabled":_vm.simulationLoading,"loading-text":'Carregando dados',"no-data-text":_vm.$t('TXT_NO_DATA_FOUND_DISPLAY'),"no-results-text":_vm.$t('TXT_NO_DATA_FOUND_DISPLAY'),"items-per-page":10},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentFormatDate(item.date, false))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMonetary(item.price))+" ")]}},{key:"item.lowerLimit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMonetary(item.lowerLimit))+" ")]}},{key:"item.upperLimit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMonetary(item.upperLimit))+" ")]}},{key:"item.variation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPercentage(item.variation))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }