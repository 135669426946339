<template>
  <Standard-list-card
    :title="$t('TXT_USERS')"
    :description="$t('TXT_SELECT_USER_FOR_MANAGEMENT_STORE')"
    :isLoadingInitial="isLoadingInitial || storeLoading"
    :isLoadingMoreItems="isLoadingMoreItems"
    @loadMoreItems="loadMoreItems('isLoadingMoreItems', true)"
  >
    <template v-slot:search>
      <Prd-search
        v-model="searchTerm"
        :searchLabel="$t('TXT_TYPE_NAME_EMAIL_FOR_MANAGEMENT_STORE')"
        :delay="1000"
      />
    </template>
    <template v-slot:filters>
      <v-col cols="5" class="pr-2">
        <Prd-combo-box
          v-model="position"
          :title="$t('TXT_RESOURCE_GROUP')"
          :items="positionItems"
          :itemText="'cargo'"
        />
      </v-col>
      <v-col cols="7" class="pl-2 pr-5">
        <Prd-combo-box
          v-model="store"
          :title="`${$t('TXT_STORE')}/${$t('TXT_AFFILIATE')}`"
          :items="storeItems"
          :itemText="'description'"
        />
      </v-col>
    </template>

    <template v-slot:list>
      <User-card
        v-for="(user, index) in userList"
        :key="index"
        :userData="user"
        @click.native="toggleSelectedUser(user.id)"
      />
      <h3
        class="text-center"
        v-if="
          !isLoadingInitial &&
          !isLoadingMoreItems &&
          !storeLoading &&
          !userList.length
        "
      >
        {{ $t("TXT_NOT_FOUNDED_USERS") }}
      </h3>
    </template>
  </Standard-list-card>
</template>

<script>
import PrdSearch from "@/components/common/prd-search.vue";
import StandardListCard from "../common/standard-list-card.vue";
import PrdComboBox from "@/components/common/prd-comboBox.vue";
import UserCard from "./user-card.vue";
export default {
  components: { StandardListCard, PrdSearch, PrdComboBox, UserCard },
  data() {
    return {
      isLoadingInitial: false,
      isLoadingMoreItems: false,
      searchTerm: null,
      position: { cargoId: null, cargo: this.$i18n.t("TXT_ALL") },
      store: {
        value: null,
        description: this.$i18n.t("TXT_ALL_STORE"),
      },
      page: 1,
      hasInitial: true,
    };
  },

  computed: {
    userList() {
      return this.$store.state.storeManagementStore.userList;
    },
    storeList() {
      return this.$store.state.storeManagementStore.storeList;
    },
    storeLoading() {
      return this.$store.state.storeManagementStore.storeLoading;
    },
    totalPages() {
      return this.$store.state.storeManagementStore.totalPages;
    },
    positionItems() {
      return this.$store.getters.getPositionItems;
    },
    storeItems() {
      return this.$store.getters.getStoreItems;
    },
  },

  watch: {
    searchTerm() {
      this.getInitialPage();
    },
    position() {
      this.getInitialPage();
    },
    store() {
      this.getInitialPage();
    },
    storeList: {
      handler(value) {
        if (value.length > 0 && this.hasInitial) {
          this.hasInitial = false;
          this.resetsearch();
          this.loadMoreItems("isLoadingInitial");
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    toggleSelectedUser(id) {
      this.$store.commit("toggleSelectedUser", id);
      this.$store.commit("addUserInChangeArray", id);
      this.$store.commit("setSelectedStoreByUser", id);
      this.$store.commit("syncChanges", id);
    },
    resetsearch() {
      this.$store.commit("setLastSelectedUserId");
      this.$store.commit("clearUserList");
      this.$store.commit("updateTotalPages", null);
      this.page = 1;
    },
    async loadMoreItems(
      loadingType = "isLoadingMoreItems",
      updatePage = false
    ) {
      if (
        this[loadingType] ||
        (this.totalPages && this.page == this.totalPages)
      )
        return;

      if (updatePage) this.page++;

      this[loadingType] = true;

      const request = this.prepareRequest();
      await this.$store.dispatch("getUserList", request);

      this[loadingType] = false;
    },
    prepareRequest() {
      return {
        idCompany: this.$store.getters.userData.idCompany,
        page: this.page,
        pageSize: 10,
        searchTerm: this.searchTerm ?? null,
        affiliateId: this.store?.value ?? null,
        cargoId: this.position?.cargoId ?? null,
      };
    },
    getInitialPage() {
      this.resetsearch();
      this.loadMoreItems();
    },
  },
};
</script>
