<template>
  <div>
    <v-row
      no-gutters
      :class="['underline', 'mb-2', `${isLinkedList ? 'link' : 'unlink'}`]"
    >
      <p class="ma-0 font-weight-bold">{{ title }}</p>
    </v-row>
    <div class="wrap-conteiner">
      <Store-chip v-for="(name, index) in list" :key="index" :name="name" />
    </div>
  </div>
</template>

<script>
import StoreChip from "./store-chip.vue";
export default {
  components: { StoreChip },
  props: {
    isLinkedList: { type: Boolean, default: true },
    list: { type: Array, default: () => [] },
  },
  computed: {
    title() {
      const key = this.isLinkedList
        ? "TXT_WILL_LINKED_STORES"
        : "TXT_WILL_UNLINKED_STORES";

      return this.$i18n.t(key);
    },
  },
};
</script>

<style lang="scss" scoped>
.underline {
  border-bottom: 1px solid;
}
.link {
  border-bottom-color: $feedback-color-success-pure;
}
.unlink {
  border-bottom-color: $feedback-color-warning-pure;
}
.wrap-conteiner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
}
</style>
