<template>
  <v-row no-gutters style="gap: 16px">
    <v-col v-for="(filter, index) in filters" :key="index">
      <Prd-combo-box
        v-model="filter.value"
        :title="filter.title"
        :placeholder="filter.placeholder"
        :items="filter.items"
        :disabled="
          isLoading || index == 3
            ? disabledState
            : index > 0
            ? disabledFields
            : false
        "
        @input="setOperation(index)"
      />
    </v-col>
  </v-row>
</template>

<script>
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
export default {
  components: { PrdComboBox },
  props: ["isLoading"],
  data() {
    return {
      filters: [
        {
          title: this.$i18n.t("TXT_COMPETITOR_TYPE"),
          placeholder:
            this.$i18n.t("TXT_SELECT_A").replace("um(a)", "um") +
            this.$i18n.t("TXT_COMPETITOR_TYPE").toLowerCase() +
            "...",
          value: null,
          items: [
            {
              text: this.$i18n.t("TXT_CONVENIENCE"),
              value: "GAS STATION",
            },
            {
              text: this.$i18n.t("TXT_SUPER_SMALL"),
              value: "SMALL SUPERMARKET",
            },
          ],
        },
        {
          title: this.$i18n.t("TXT_CATEGORY"),
          placeholder:
            this.$i18n.t("TXT_SELECT_A").replace("um(a)", "uma") +
            this.$i18n.t("TXT_CATEGORY").toLowerCase() +
            "...",
          value: null,
          items: [
            { text: this.$i18n.t("TXT_ALL_2"), value: "TODAS" },
            { text: this.$i18n.t("TXT_ALCOHOL"), value: "BEBIDAS ALCOÓLICAS" },
            {
              text: this.$i18n.t("TXT_NON_ALCOHOLIC_BEVARAGES"),
              value: "BEBIDAS NÃO ALCOÓLICAS",
            },
            {
              text: this.$i18n.t("TXT_BISCUIT_SNACKS"),
              value: "BISCOITOS E SNACKS",
            },
            { text: "Bomboniere", value: "BOMBONIERE" },
            { text: this.$i18n.t("TXT_FROZEN"), value: "CONGELADOS" },
          ],
        },
        {
          title: this.$i18n.t("TXT_TIME_PERIOD"),
          placeholder: `${this.$i18n
            .t("TXT_SELECT_A")
            .replace("um(a)", "um")} ${this.$i18n.t("TXT_TIME_PERIOD")} ...`,
          value: null,
          items: [
            {
              text: "Semanal",
              value: "SEMANAL",
            },
            {
              text: "Mensal",
              value: "MENSAL",
            },
          ],
        },
        {
          title: "Estado",
          placeholder: `${this.$i18n
            .t("TXT_SELECT_A")
            .replace("um(a)", "uma")} ${this.$i18n.t("TXT_STATE")} ...`,
          value: null,
          items: [
            { text: "Brasil", value: "BRASIL" },
            { text: "Acre", value: "AC" },
            { text: "Alagoas", value: "AL" },
            { text: "Amapá", value: "AP" },
            { text: "Amazonas", value: "AM" },
            { text: "Bahia", value: "BA" },
            { text: "Ceará", value: "CE" },
            { text: "Distrito Federal", value: "DF" },
            { text: "Espírito Santo", value: "ES" },
            { text: "Goiás", value: "GO" },
            { text: "Maranhão", value: "MA" },
            { text: "Mato Grosso", value: "MT" },
            { text: "Mato Grosso do Sul", value: "MS" },
            { text: "Minas Gerais", value: "MG" },
            { text: "Pará", value: "PA" },
            { text: "Paraíba", value: "PB" },
            { text: "Paraná", value: "PR" },
            { text: "Pernambuco", value: "PE" },
            { text: "Piauí", value: "PI" },
            { text: "Rio de Janeiro", value: "RJ" },
            { text: "Rio Grande do Norte", value: "RN" },
            { text: "Rio Grande do Sul", value: "RS" },
            { text: "Rondônia", value: "RO" },
            { text: "Roraima", value: "RR" },
            { text: "Santa Catarina", value: "SC" },
            { text: "São Paulo", value: "SP" },
            { text: "Sergipe", value: "SE" },
            { text: "Tocantins", value: "TO" },
          ],
        },
        {
          title: this.$i18n.t("TXT_COORDINATOR"),
          placeholder: `${this.$i18n
            .t("TXT_SELECT_A")
            .replace("um(a)", "um")} ${this.$i18n.t("TXT_COORDINATOR")} ...`,
          value: null,
          items: [
            { text: this.$i18n.t("TXT_NO_COORDINATOR"), value: "all" },
            { text: "Ana Flávia", value: "ANA FLAVIA" },
            { text: "Flávia Guimarães", value: "FLAVIA GUIMARAES" },
            { text: "Vinicius Tyburski", value: "VINICIUS TYBURSKI" },
            { text: "Paulo Ferreira", value: "PAULO FERREIRA" },
          ],
        },
      ],
    };
  },
  computed: {
    disabledFields() {
      return !this.filters[0].value;
    },
    disabledState() {
      return this.filters[4].value && this.filters[4].value.value != "all"
        ? true
        : false || this.disabledFields;
    },
  },
  watch: {
    filters: {
      handler() {
        this.prepareDataToGetGraphic();
      },
      deep: true,
    },
  },
  methods: {
    setOperation(index) {
      if (index == 4 && this.filters[4].value.value != "all")
        this.filters[3].value = null;
      else if (index == 4 && this.filters[4].value.value == "all")
        this.filters[3].value = { text: "Brasil", value: "BRASIL" };
    },
    prepareDataToGetGraphic() {
      let body = {
        compType: this.filters[0].value?.value ?? "",
        category: this.filters[1].value?.value ?? "",
        period: this.filters[2].value?.value ?? "",
        state: this.filters[3].value?.value ?? "",
        coordinator:
          this.filters[4].value && this.filters[4].value.value != "all"
            ? this.filters[4].value.value
            : "",
      };
      if (
        body.compType &&
        body.category &&
        body.period &&
        (body.state || this.filters[4].value)
      ) {
        this.$emit(
          "selectedCompetitorType",
          this.filters[0].value.text.toUpperCase()
        );
        this.$emit("getGraphicIC", body);
      }
    },
  },
};
</script>