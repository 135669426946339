export function setCurrency(value, useSymbol) {
    if (typeof value !== "number" || Number.isNaN(value)) return '-';

    let currency = localStorage.getItem("currency");
    if (!currency) currency = "R$";

    let symbol = '';
    if (value > 0 && useSymbol) symbol = '+';

    const intValue = Math.floor(value * 100); 
    const formattedValue = (intValue / 100).toFixed(2); 

    return currency + ' ' + symbol + ' ' + formattedValue.replace('.', ','); 
}
