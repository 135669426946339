<template>
  <v-btn
    class="buttom"
    :class="outlined ? '' : 'white--text'"
    :disabled="disabled"
    :small="small"
    :color="color"
    :outlined="outlined"
    :loading="loading"
    @click="$emit('click', $event)"
  >
    <slot name="icon">
      <v-icon :color="iconColor" :left="leftIcon" :small="smallIcon">{{
        icon
      }}</v-icon>
    </slot>
    <slot name="title">
      {{ title }}
    </slot>
    <slot name="icon-after"></slot>
  </v-btn>
</template>

<script>
export default {
  name: "defaultButton",
  data() {
    return {};
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    icon: {
      type: String,
    },
    leftIcon: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
    },
    smallIcon: {
      type: Boolean,
    },
    color: {
      type: String,
      default: function () {
        return this.$prdStyles("color-primary");
      },
    },
    iconColor: {
      type: String,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.buttom {
  align-items: center;
  border-radius: 5px;
}
</style>