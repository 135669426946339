import { render, staticRenderFns } from "./store-chip.vue?vue&type=template&id=fbe41cba&scoped=true"
import script from "./store-chip.vue?vue&type=script&lang=js"
export * from "./store-chip.vue?vue&type=script&lang=js"
import style0 from "./store-chip.vue?vue&type=style&index=0&id=fbe41cba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbe41cba",
  null
  
)

export default component.exports