export default class LocalDataService {
  /**
   * This function verifies if a text as parameter is JSON format.
   *
   * @param {Any} text - Text sent be verified
   * @returns {Boolean} - Returns true if parameter is JSON or false if is not parameter
   */
  isJsonObject(text) {
    if (
      /^[\],:{}\s]*$/.test(
        text
          .replace(/\\["\\/bfnrtu]/g, "@")
          .replace(
            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g,
            "]"
          )
          .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * This function set a new item for local storage
   *
   * @param {String} key - key to be identified by the local storage
   * @param {any} value - value to be set to this new local storage key
   */
  set(key, value) {
    if (value) {
      const text = JSON.stringify(value);
      //   const encoded = btoa(unescape(encodeURIComponent(text)));
      //   localStorage.setItem(key, encoded);
      localStorage.setItem(key, text);
    }
  }

  /**
   * This function retrieves a local storage value by its key
   *
   * @param {String} key - Used to identify the local storage data
   * @returns {Any} - Returns decoded key value
   */
  get(key) {
    if (!key) return false;

    const text = JSON.parse(localStorage.getItem(key));

    // if (!text) return false;

    // const decode = decodeURI(escape(window.atob(text)));

    // if (this.isJsonObject(decode)) {
    //   return JSON.parse(decode);
    // }
    // return decode;
    return text;
  }

  remove(key) {
    localStorage.removeItem(key);
  }

  clearAll() {
    localStorage.clear();
  }
}
