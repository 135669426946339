<template>
  <div class="bg-agroupment-list-micro px-2 pt-2">
    <h4>{{ $t("TXT_AGROUPMENT_MICRO_LIST") }}</h4>
    <div class="scrollable-div">
      <v-row no-gutters v-for="micro in list" :key="micro.id" class="mb-2">
        <span class="font-weight-bold mr-1">{{ $t("TXT_NAME") }}:</span>
        <span>{{ micro.nome }}</span>
        <span class="font-weight-bold mx-2">|</span>
        <span class="font-weight-bold mr-1">{{ $t("TXT_CLIENT_GROUP") }}:</span>
        <span>{{ formatGrupoClientes(micro.grupoClientes) }}</span>
        <span class="font-weight-bold mx-2">|</span>
        <span class="font-weight-bold mr-1">{{ $t("TXT_VARIATION") }}:</span>
        <span>{{ `${micro.variacao} %` }}</span>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    formatGrupoClientes(value) {
      return value ?? "-";
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-agroupment-list-micro {
  background-color: $neutral-color-high-light !important;
  border-radius: 5px;
}
.scrollable-div {
  max-height: 250px;
  overflow: auto;
}
</style>