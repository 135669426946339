<template>
  <v-row no-gutters justify="space-between" align="center">
    <v-col cols="auto">
      <!-- <span class="blueText"
        >Mostrando {{ localCurrentAmountShowItems }} de
        {{ totalItems }} resultados</span
      > -->
      <span class="blueText">{{
        $t("TXT_PAGINATION")
          .replace("${result}", localCurrentAmountShowItems)
          .replace("${total}", totalItems)
      }}</span>
    </v-col>
    <v-col cols="auto">
      <v-pagination
        v-model="currentPage"
        :length="parseInt(localLength)"
        :total-visible="totalVisibleNumbers"
      />
    </v-col>
    <v-col cols="auto">
      <v-row no-gutters align="center">
        <span class="blueText mr-1">{{ $t("TXT_PAGINA") }}</span>
        <Prd-text-field
          @input="currentPage = parseInt($event)"
          v-model="currentPage"
          :type="'number'"
          :min="1"
          style="width: 75px"
          :disabled="localLength <= 1"
        />
        <span class="blueText ml-1"> {{ $t("TXT_OF") }} {{ localLength }}</span>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import PrdTextField from "@/components/common/prd-text-field.vue";
export default {
  components: { PrdTextField },
  props: {
    totalVisibleNumbers: {
      type: Number,
      default: 5,
    },
    totalItems: {
      type: Number,
      default: 1,
    },
    currentAmountShowItems: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    localLength() {
      const realQuantity = (
        parseInt(this.totalItems) / parseInt(this.currentAmountShowItems)
      ).toFixed(0);
      return realQuantity <= 0 ? 1 : realQuantity;
    },
    localCurrentAmountShowItems() {
      return this.currentAmountShowItems > this.totalItems
        ? this.totalItems
        : this.currentAmountShowItems;
    },
  },
  watch: {
    currentPage: {
      handler(value) {
        this.$emit("currentPage", value);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.blueText {
  color: $brand-color-primary-pure !important;
}
</style>