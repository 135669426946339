<template>
  <v-container fluid>
    <v-row>
      <h2 class="mb-4">{{ $t("TXT_NEXT_GAMES") }}</h2>
    </v-row>
    <v-row>
      <progress-component
        v-if="service.listEvents.isLoading"
      ></progress-component>
      <div
        class="grid-column-span-4 color-error"
        v-if="service.listEvents.state() === 'ERROR'"
      >
        {{ $t("TXT_ERROR_GET_GAMES") }}:
        {{ service.listEvents.error.message || service.listEvents.error }}
      </div>
      <div
        class="d-flex flex-wrap flex-row gap-6"
        v-if="
          service.listEvents.state() == 'DATA_READY' &&
          service.listEvents.data.answer &&
          service.listEvents.data.answer.length > 0
        "
      >
        <game-card
          v-for="event in service.listEvents.data.answer"
          :event="event"
          :key="event.idEnterpriseEventHistory"
        ></game-card>
      </div>
      <div v-else-if="service.listEvents.state() == 'DATA_READY'">
        {{ $t("TXT_NOT_FOUND_GAME") }}
      </div>
    </v-row>
  </v-container>
</template>
<script>
import progressComponent from "@/Design_System/common/progressComponent";
import gameCard from "./components/game-card";
import PredifutService from "@/service/predifut-service";

export default {
  components: {
    gameCard,
    progressComponent,
  },
  data() {
    return {
      service: new PredifutService(),
    };
  },
  methods: {},
  computed: {
    idCompany() {
      return this.$store.getters.userData.idCompany;
    },
  },
  async created() {
    const idCompany = this.idCompany;

    // If there is no idCompany, this is probably a bug
    if (!idCompany) throw new Error("Could not get idCompany from store");

    await this.service.listEvents.call(idCompany);
  },
};
</script>
<style lang="scss" scoped>
.gap-6 {
  column-gap: 24px;
  row-gap: 24px;
}
.color-error {
  color: $feedback-color-warning-pure;
}
</style>
