<template>
  <div>
    <v-icon
      :color="$prdStyles('color-warning')"
      size="30px"
      @click="isModalOpen = true"
      >mdi-delete-outline</v-icon
    >
    <v-dialog v-model="isModalOpen" :max-width="600" persistent>
      <v-card class="pa-4">
        <div class="my-flex">
          <v-icon :color="$prdStyles('color-warning')" size="35"
            >mdi-alert-outline</v-icon
          >
          <h4 class="my-4">
            {{ $t("TXT_EXCLUDE_GROUP_RESOURCE").toUpperCase() }}
          </h4>
          <span v-html="$t('TXT_EXCLUDE_GROUP_RESOURCE_QUESTION')" />
        </div>

        <div class="group">
          <span class="font-weight-bold">{{ group.name }}</span>
        </div>

        <span class="destaq-color">{{
          $t("TXT_EXCLUDE_GROUP_RESOURCE_ALERT")
        }}</span>
        <Prd-card-actions
          :outlinedTitle="$t('TXT_CANCEL')"
          :outlinedColor="$prdStyles('color-warning')"
          :title="$t('TXT_EXCLUDE')"
          :color="$prdStyles('color-warning')"
          :loading="isLoading"
          :isDisable="isLoading"
          @clickOutlined="isModalOpen = false"
          @click="deleteGroup"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PrdCardActions from "@/components/common/prd-card-actions.vue";

export default {
  components: { PrdCardActions },
  props: ["group", "isLoading"],
  data() {
    return {
      isModalOpen: false,
    };
  },
  methods: {
    deleteGroup() {
      this.$emit("deleteGroup", this.group);
    },
  },
};
</script>

<style lang="scss" scoped>
.my-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 16px;
}
.destaq-color {
  color: $feedback-color-warning-pure;
}

.user-info {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
.align-center {
  text-align: center;
}

.group {
  background-color: $neutral-color-high-light;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 12px;
}
</style>